const StatusDropdown = () => {
  return (
    <div className="dropdown-select">
      <select>
        <option value="" disabled selected>- Status -</option>
        <option>Start</option>
        <option>Active</option>
        <option>Done</option>
      </select>
    </div>
  );
};

export default StatusDropdown;