import * as Yup from "yup"
import Description from "../../components/Description"
import ImageSlideout from '../../assets/images/journal-1-slideout.jpg'
import Image2Slideout from '../../assets/images/journal-2-slideout.jpg'
import { VideoPlayer } from "../../components/VideoPlayer"
import { Image } from "../../components/Image"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"

const DEFAULT_VALUES = {
  isEdit: false,
  title: '',
  journal_description: '',
  journal_image_url: '',
  journal_1: '',
  journal_2: '',
  journal_3: '',
  journal_4: '',
  journal_5: '',
  topics: []
}

const OnboardingJournal = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const values = initialValues ? {
    ...initialValues,
    journal_description: initialValues?.description,
    journal_1: initialValues?.question_1,
    journal_2: initialValues?.question_2,
    journal_3: initialValues?.question_3,
    journal_4: initialValues?.question_4,
    journal_5: initialValues?.question_5,
    journal_image_url: initialValues?.image_url,
    topics: initialValues?.topics
  } : DEFAULT_VALUES

  const fields = [
    {
      component: "title",
      label: "Journal Title",
      isFieldRow: true,
      required: true
    },
    {
      component: "journal_description",
      label: "Journal Exercise/Description",
      isFieldRow: true,
      rows: 3,
      required: true
    },
    {
      component: "journal_1",
      label: "Question/Prompt 1",
      isFieldRow: true,
      required: true
    },
    {
      component: "journal_2",
      label: "Question/Prompt 2",
      isFieldRow: true
    },
    {
      component: "journal_3",
      label: "Question/Prompt 3",
      isFieldRow: true,
    },
    {
      component: "journal_4",
      label: "Question/Prompt 4",
      isFieldRow: true,
    },
    {
      component: "journal_5",
      label: "Question/Prompt 5",
      isFieldRow: true,
    },
    {
      component: "journal_image_url",
      label: "Journal Image (square image recommended)",
      isFieldRow: true,
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
      required: true,
    },
  ]

  const schema = {
    title: validations.str_req_70,
    journal_description: Yup.string().required('Required').max(65535, "Maximum 65,535 Characters"),
    journal_1: Yup.string().required('Required').max(65535, "Maximum 65,535 Characters"),
    journal_2: Yup.string().max(65535, "Maximum 65,535 Characters"),
    journal_3: Yup.string().max(65535, "Maximum 65,535 Characters"),
    journal_4: Yup.string().max(65535, "Maximum 65,535 Characters"),
    journal_5: Yup.string().max(65535, "Maximum 65,535 Characters"),
    topics: Yup.array().min(1, "Required"),
  };

  return {
    config: {
      label: "Onboarding - Journals",
      resourceEndpoint: "journals",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=XO4Zizizz9M" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please add 2 journaling exercises (you can add more later) with short descriptions, followed by journaling prompts/questions. Ask at least 1 question (their answers are saved in the App) for your App users (see images below). Try Pixabay.com or Unsplash.com for free commercial images 
          </Description>
        ) },
        { customComponent: (
          <div className="flex gap-4 justify-center">
            <div className="w-4/12 py-4">
              <Image
                src={ImageSlideout}
                alt="Journal"
              />
            </div>
            <div className="w-4/12 py-4">
              <Image
                src={Image2Slideout}
                alt="Journal"
              />
            </div>
          </div>
        ) },
        { customComponent: (
          <OnboardingContent
            title="Journals"
            defaultValues={values}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              const newPayload = {
                title: values?.title,
                description: values?.journal_description,
                image_url: values?.journal_image_url,
                question_1: values?.journal_1,
                question_2: values?.journal_2,
                question_3: values?.journal_3,
                question_4: values?.journal_4,
                question_5: values?.journal_5,
                topics: values?.topics
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingJournal;