const itemConfig = {
  label: "TrackaConnect",
  resourceEndpoint: "items",
  isNotContent: true,
  overrideTitle: "Card/Book",
  listMetadata: [
    "type",
    "title",
    "start_number",
    "end_number",
    "message_prompt",
    "download_url",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    {
      name: "type",
      label: "Item Type (Card, Book/Video)",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "title",
      label: "Name / Title",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "start_number",
      label: "Start Number",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "end_number",
      label: "End Number",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "video_url", label: "Video URL" },
    { name: "image_url", label: "Image URL" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "message_prompt", label: "Message Prompt" },
    {
      name: "download_url",
      label: "Download URL (Book)",
      conditions: [{ relativeField: "type", comparisonFunc: (relativeField, thisField)=>{
        return relativeField.value === "Book";
      } }],
    },
  ],
  formLayout: {
    columns: [
      ["type", "title", "start_number", "end_number", "video_url"],
      [
        "image_url",
        "action_bar_text",
        "action_bar_url",
        "message_prompt",
        "download_url",
      ],
    ],
  },
};

export default itemConfig
