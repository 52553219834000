import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteProfile } from "../services/api/profile";
import { GetAppResource } from "../services/api/app";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { CloneItem } from "../services/api/clone";

const ProfileList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, "profiles")
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Profiles", url: "/profiles" },
  ];

  //Unlock Code (formerly Tagging ID) , Notification Email (formerly Your Email) , Tagging Name ,Tag ,Tag Created for
  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "profile",
      label: "App Profile?",
      parser: (data) => (data.app_profile ? "Yes" : "No"),
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "email",
      label: "Email",
      parser: (data) => data.email,
      ellipsis: true,
    },
    {
      name: "title",
      label: "Title",
      parser: (data) => data.title,
      ellipsis: true,
    },
    {
      name: "business_name",
      label: "Business Name",
      parser: (data) => data.business_name,
      ellipsis: true,
    },
    {
      name: "directory",
      label: "Topic/ Directory Listing",
      parser: (data) => data?.directory?.name,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
          onCloneClick={cloneButtonHandler}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/profiles/new`)
  }

  const cloneButtonHandler  = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: 'profiles'
      }
    })
    const cloneData = await res.json()
    const cloneId = cloneData.data?.id

    loadData();
    history.push(`/profiles/${cloneId}`)
  };

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={handleAddButtonClick}
      >
        Add Profile
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/profiles/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteProfile(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Profile"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page links={links} title="Profiles" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "email", "title", "business_name"]}
      />
    </Page>
  );
};

export default ProfileList;
