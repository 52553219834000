const lessonConfig = {
  // label: 'Lesson',
  // resourceEndpoint: 'lessons',
  // listMetadata: ['sort_order',
  //                'active',
  //                'title',
  //                'featured',
  //                'topics',
  //                'tags',
  //                'actions'],
  // searchKeys: ['title'],
  // detailsMetadata: [
  //   { name: 'title', label: 'Lesson Title'},
  //   { name: 'description', label: 'Description', validations:
  //     [
  //       {type: 'required', params: ['Required']}
  //     ]
  //   },
  //   { name: 'image_url', label: 'Image URL'},
  //   { name: 'release_date', label: 'Release Date'},
  //   { name: 'action_bar_text', label: 'Call to Action Text'},
  //   { name: 'action_bar_url', label: 'Call to Action URL'},
  //   { name: 'sort_order', label: 'Sort Order'},
  //   { name: 'active', label: 'Active'},
  //   { name: 'featured', label: 'Featured'},
  //   { name: 'topics', label: 'Topics'},
  //   { name: 'tags', label: 'Tags'},
  //   { name: 'sections', label: 'Sections'}
  // ],
  subTables: {
    url_subtopics: {
      label: {
        plural: "Custom URLs",
        singular: "Custom URL",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        { name: "name", label: "Link Text", parser: (data) => data.name },
        { name: "url", label: "Link", parser: (data) => data.url },
        { name: "use_url_tag", label: "Use URL Tag", parser: (data) => (data.use_url_tag ? "Yes" : "No") },
      ],
      detailsMetadata: [
        {
          name: "name",
          label: "Link Text",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "url",
          label: "Link",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "use_url_tag",
          label: "Use URL Tag",
        },
      ],
    },
  },
};

export default lessonConfig
