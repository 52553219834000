import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  Select,
  TextArea,
  RichText,
} from "../components/EntryForm";
import Page from "../components/Page";
import { validations } from "../lib/form";

import { GetCourse, UpdateCourse } from "../services/api/course";
import { GetAppResource, AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import DataSelector from "../components/DataSelector";
import MediaUploader from "../components/MediaUploader";
import VersionizerWrapper from "../components/Versionizer";

import CourseModuleList from "./CourseModuleList";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";

const CourseDetail = ({ newCourse, match }) => {
  const vals = {
    title: "",
    course_category_id: null,
    creator: "",
    creator_image_url: "",
    course_thumbnail: "",
    description: "",
    web_description: "",
    email: "",
    cost: "",
    short_description: "",
    drip_system: false,
    video_url: "",
    image_url: "",
    instructions_text: "",
    web_instructions_text: "",
    instructions_image_url: "",
    instructions_video_url: "",
    welcome_video_url: "",
    enroll_text: "",
    enroll_url: "",
    complete_course_url: "",
    active: false,
    sort_order: 1,
    tags: [],
    modules: [],
    unlock_code: "",
    send_email: false,
    id: "",
    media_metadata: {},
    customized_confirmation_screen: false,
    confirmation: {
      headline: "",
      image_url: "",
      media_metadata: {},
      message: "",
      web_message: "",
      video_url: "",
    },
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [_isCourseCategoryLoading, setIsCourseCategoryLoading] =
    useState(false);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newCourse ? user.currentApp() : match.params.uuid;

  const history = useHistory();

  const loadData = () => {
    if (newCourse) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetCourse(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            title: res.data.title,
            course_category_id: res.data.category.id,
            creator: res.data.creator,
            creator_image_url: res.data.creator_image_url,
            course_thumbnail: res.data.course_thumbnail,
            description: res.data.description,
            web_description: res.data.web_description,
            email: res.data.email,
            cost: res.data.cost,
            short_description: res.data.short_description,
            drip_system: res.data.drip_system,
            video_url: res.data.video_url,
            image_url: res.data.image_url,
            instructions_text: res.data.instructions_text,
            web_instructions_text: res.data.web_instructions_text,
            instructions_image_url: res.data.instructions_image_url,
            instructions_video_url: res.data.instructions_video_url,
            welcome_video_url: res.data.welcome_video_url,
            enroll_text: res.data.enroll_text,
            enroll_url: res.data.enroll_url,
            complete_course_url: res.data.complete_course_url,
            active: res.data.active,
            sort_order: res.data.sort_order,
            tags: res.data.tags,
            modules: res.data.modules,
            unlock_code: res.data.unlock_code,
            send_email: res.data.send_email,
            id: res.data.id,
            customized_confirmation_screen:
              res.data.customized_confirmation_screen,
            confirmation: res.data.confirmation,
          });
          setMetadata(res.data.media_metadata);
          setCourseModules(res.data.modules);
          setIsLoading(false);
        });
    }

    setIsCourseCategoryLoading(true);
    GetAppResource(user, "course_categories")
      .then((res) => res.json())
      .then((res) => {
        setCourseCategories(
          res.data.map((category) => ({ id: category.id, name: category.name }))
        );
        setIsCourseCategoryLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newCourse ? "New" : "Edit";
  const currentUrl = newCourse ? "/courses/new" : `/courses/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Course List", url: "/courses" },
    { name: `${currentAction} Course`, url: currentUrl },
  ];

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          title: validations.str_req_60,
          course_category_id: Yup.string().nullable().required("Required"),
          creator: validations.str_req_60,
          creator_image_url: Yup.string().max(255, "Maximum 255 Characters"),
          course_thumbnail: Yup.string().max(255, "Maximum 255 Characters"),
          description: Yup.string().max(65535, "Maximum 65,535 Characters"),
          web_description: Yup.string().max(65535, "Maximum 65,535 Characters"),
          email: Yup.string()
            .email("Invalid email")
            .max(255, "Maximum 255 Characters"),
          cost: validations.str_notreq_10,
          short_description: validations.str_notreq_30,
          drip_system: Yup.boolean().nullable(),
          video_url: Yup.string().max(255, "Maximum 255 Characters"),
          image_url: Yup.string().max(255, "Maximum 255 Characters"),
          instructions_text: Yup.string().max(
            65535,
            "Maximum 65,535 Characters"
          ),
          web_instructions_text: Yup.string().max(
            65535,
            "Maximum 65,535 Characters"
          ),
          instructions_image_url: Yup.string().max(
            255,
            "Maximum 255 Characters"
          ),
          instructions_video_url: Yup.string().max(
            255,
            "Maximum 255 Characters"
          ),
          welcome_video_url: Yup.string().max(255, "Maximum 255 Characters"),
          enroll_text: validations.str_notreq_40,
          enroll_url: Yup.string()
            .url(
              "URL must begin with https:// or http:// e.g. https://actionera.com"
            )
            .max(255, "Maximum 255 Characters"),
          complete_course_url: Yup.string()
            .url(
              "URL must begin with https:// or http:// e.g. https://actionera.com"
            )
            .max(255, "Maximum 255 Characters"),
          active: Yup.boolean().nullable(),
          send_email: Yup.boolean().nullable(),
          sort_order: validations.sort_order,
        };

        if (!!values?.customized_confirmation_screen) {
          let confirmationSchema = {
            headline: Yup.string().max(255, "Maximum 255 Characters"),
            message: Yup.string().max(65535, "Maximum 65,535 Characters"),
            web_message: Yup.string().max(65535, "Maximum 65,535 Characters"),
            video_url: Yup.string().max(65535, "Maximum 65,535 Characters"),
            image_url: Yup.string().max(65535, "Maximum 65,535 Characters"),
          };

          schema.confirmation = Yup.object().shape(confirmationSchema);
        }

        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      let payload = {
        ...values,
        tag: { id: values.tag_id },
        sales: {
          video_url: values.sales_video_url,
          headline: values.sales_headline,
          description: values.sales_description,
        },
        enrolled: {
          video_url: values.enrolled_video_url,
          headline: values.enrolled_headline,
          description: values.enrolled_description,
        },
      };

      delete payload.tag_id;
      delete payload.unlock_code;
      delete payload.sales_video_url;
      delete payload.sales_headline;
      delete payload.sales_description;
      delete payload.enrolled_video_url;
      delete payload.enrolled_headline;
      delete payload.enrolled_description;
      delete payload.modules;
      delete payload.id;

      if (newCourse) {
        AddAppResource(uuid, "courses", user.idToken, { data: payload }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateCourse(uuid, user.idToken, { data: payload }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push(`/courses`),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Course`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL={`/courses`}
      >
        <Column>
          {!newCourse && (
            <Input
              name="unlock_code"
              type="text"
              label="Unlock Code"
              formik={formik}
              disabled={true}
            />
          )}
          <CheckBox
            name="send_email"
            label="Send Email notification when unlocked"
            formik={formik}
          />
          <Input name="email" type="text" label="Email" formik={formik} />
          <Select
            name="course_category_id"
            type="text"
            label="Category"
            formik={formik}
          >
            <option value={null} label="Select Category..." />
            {courseCategories.map((course_category) => (
              <option
                key={course_category.id}
                value={course_category.id}
                label={course_category.name}
              />
            ))}
          </Select>
          <Input name="title" type="text" label="Title" formik={formik} />
          <Input name="creator" type="text" label="Creator" formik={formik} />
          <MediaUploader
            name="creator_image_url"
            type="text"
            label="Creator Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <div>
            <MediaUploader
              name="course_thumbnail"
              type="text"
              label="Course Thumbnail"
              formik={formik}
              metadata={metadata}
              mediaType="image"
            />
            <VersionizerWrapper maxVersion={8} minVersion={8}>
              <p className="italic text-right" style={{ marginTop: -12, marginBottom: 20 }}>
                Available in app Platform Version 8.5 & up and WEB 
              </p>
            </VersionizerWrapper>
          </div>
          <RichText
            id="coursesDetail"
            name="description"
            quillName="web_description"
            type="text"
            label="Description"
            rows="21"
            formik={formik}
            contentType="coursesDetail"
          />
          <Input name="cost" type="text" label="Course Cost" formik={formik} />
          <Input
            name="short_description"
            type="text"
            label="Number of Module/Lessons"
            formik={formik}
          />
          <CheckBox name="drip_system" label="Drip System" formik={formik} />
          <div>
            <CheckBox
              formik={formik}
              name="customized_confirmation_screen"
              label="Customize Confirmation Screen"
            />
            <VersionizerWrapper maxVersion={8.5} minVersion={8}>
              <p className="italic" style={{ marginTop: -37, marginBottom: 20, marginLeft: 60 }}>
                Available in app Platform Version 9.0 & up and WEB 
              </p>
            </VersionizerWrapper>
          </div>
          {!!formik.values.customized_confirmation_screen && (
            <>
              <Input
                name="confirmation.headline"
                type="text"
                label="Headline"
                formik={formik}
              />
              <MediaUploader
                name="confirmation.video_url"
                type="text"
                label="Video"
                formik={formik}
                metadata={metadata}
                mediaType="video"
              />
              <MediaUploader
                name="confirmation.image_url"
                type="text"
                label="Image"
                formik={formik}
                metadata={metadata}
                mediaType="image"
              />
              <RichText
                id="courseCustomizeDetail"
                name="confirmation.message"
                quillName="confirmation.web_message"
                type="text"
                label="Message"
                rows="21"
                formik={formik}
                contentType="courseCustomizeDetail"
              />
            </>
          )}
        </Column>
        <Column>
          <MediaUploader
            name="video_url"
            type="text"
            label="Sales Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <RichText
            id="coursesInstructions"
            name="instructions_text"
            quillName="web_instructions_text"
            type="text"
            label="Course Instructions"
            rows="21"
            formik={formik}
            contentType="coursesInstructions"
          />
          <MediaUploader
            name="instructions_video_url"
            type="text"
            label="Course Instructions Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <MediaUploader
            name="instructions_image_url"
            type="text"
            label="Course Instructions Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <div>
            <MediaUploader
              name="welcome_video_url"
              type="text"
              label="Welcome Video URL"
              formik={formik}
              metadata={metadata}
              mediaType="video"
            />
            <VersionizerWrapper maxVersion={8} minVersion={8}>
              <p className="italic text-right" style={{ marginTop: -12, marginBottom: 12 }}>
                Available in app Platform Version 8.5 & up and WEB 
              </p>
            </VersionizerWrapper>
          </div>
          <Input
            name="enroll_text"
            type="text"
            label="Registration Text"
            formik={formik}
          />
          <Input
            name="enroll_url"
            type="text"
            label="Registration URL"
            placeholder="https://"
            formik={formik}
          />
          <Input
            name="complete_course_url"
            type="text"
            label="Complete Course URL"
            placeholder="https://"
            formik={formik}
          />
          <Input
            name="sort_order"
            type="number"
            label="Sort Order"
            formik={formik}
          />
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(options) => {
              return options.filter((a) => !a.is_expired);
            }}
          />
          <CheckBox name="active" label="Active" formik={formik} />
        </Column>
      </EntryForm>

      {!newCourse && (
        <CourseModuleList
          courseId={initialValues.id}
          moduleValues={courseModules}
          resetList={() => {
            loadData();
          }}
        />
      )}
    </Page>
  );
};

export default CourseDetail;
