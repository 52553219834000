import { FaEdit } from "react-icons/fa";
import { useDrawerProvider } from "../../providers/DrawerProvider";
import Button from "../Button";
import SlideOut from "../SlideOut";
import { useUserProvider } from "../../providers/UserProvider";

import { GetProfile } from "../../services/api/profile";
import { GetWelcomeScreen } from "../../services/api/welcome_screen";
import { GetTopic } from "../../services/api/topic";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { SUBMITTED } from "../OnboardingBuildAndSubmit/OnboardingBuildAndSubmit";
import { GetContent } from "../../services/api/content";
import { useMemo } from "react";

const OnboardingContentListItem = ({
  type,
  title,
  item
}) => {
  const { showDrawer } = useDrawerProvider()
  const { user } = useUserProvider()
  const { onboardingData } = useOnboardingProvider()

  const isEditAllowed = useMemo(() => user?.isSuper || onboardingData?.progress?.content?.status !== SUBMITTED,
    [user, onboardingData?.progress?.content?.status])

  const getItemData = () => {
    if (isEditAllowed) {
      switch (type) {
        case 'profiles':
          GetProfile(item.id, user.idToken)
            .then((res) => res.json())
            .then((res) => {
              showDrawer(
                <SlideOut
                  type={type}
                  data={res.data}
                />,
                title,
                "50vw",
                "text-center w-full"
              );
            });
            break;
        case 'welcome_screens':
          GetWelcomeScreen(item.id, user.idToken)
            .then((res) => res.json())
            .then((res) => {
              showDrawer(
                <SlideOut
                  type={type}
                  data={res.data}
                />,
                title,
                "50vw",
                "text-center w-full"
              );
            });
            break;
        case 'topics':
          GetTopic(item.id, user.idToken)
            .then((res) => res.json())
            .then((res) => {
              showDrawer(
                <SlideOut
                  type={type}
                  data={res.data}
                />,
                title,
                "50vw",
                "text-center w-full"
              );
            });
            break;
        default:
          GetContent(item.id, user.idToken)
          .then((res) => res.json())
          .then((res) => {
            showDrawer(
              <SlideOut
                type={type}
                data={res.data}
              />,
              title,
              "50vw",
              "text-center w-full"
            );
          });
      }
    }
  }

  return (
    <div className="text-aeblue-default rounded-md border px-4 font-bold py-5 flex flex-row justify-between items-center bg-white" style={{ borderColor: '#888888'}}>
      <p className="text-2xl text-aeblue-default truncate w-2/3" onClick={() => getItemData()}>
        <span className={`text-2xl ${isEditAllowed ? "hover:cursor-pointer hover:underline" : ""}`}>{type === "profiles" ? item?.name : item.title?.length ? item.title : item.name}</span>
      </p>

      {
        isEditAllowed ? (
          <div className="flex flex-row gap-4 items-center cursor-pointer">
            <Button className="underline text-aeblue-default" onClick={() => getItemData()}>
              <div className="flex gap-4 items-center justify-center">
                <span className="ttnk-AvenirNextBold text-15">Manage</span>
                <FaEdit className="h-10 w-10"/>
              </div>
            </Button>
          </div>
        ) : null
      }
    </div>
  )
}

export default OnboardingContentListItem