import axios from "axios";
import {
  callEndpoint,
  callEndpointWithPayload,
  callPublicEndpoint,
  callEndpointWithFormDataPayload,
} from "../baseApi";

export const GetAppResource = async (user, url) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${user.currentApp()}/${url}`,
    "GET",
    user.idToken
  );
};

export const AddAppResource = async (uuid, resource, idToken, payload) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}/${resource}`,
    "POST",
    idToken,
    payload
  );
};

export const CreateApp = async (user, payload) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/apps`,
    "POST",
    user.idToken,
    payload
  );
};

export const GetAllApps = async (idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps`,
    "GET",
    idToken
  );
};

export const GetPostAllCreatorApps = async (uuid, idToken, method = 'GET', payload) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/creators/${uuid}/apps`,
    method,
    idToken,
    payload
  );
};

export const GetDisabledApps = async (idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/disabled_apps`,
    "GET",
    idToken
  );
};

export const GetAllAvailableApps = async (idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/available_apps`,
    "GET",
    idToken
  );
};

export const GetApp = async (user) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${user.currentApp()}`,
    "GET",
    user.idToken
  );
};

export const GetAppById = async (id, user) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${id}`,
    "GET",
    user.idToken
  );
};

export const UpdateApp = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}`,
    "PATCH",
    idToken,
    payload
  );
};

export const DeleteApp = async (uuid, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${uuid}`,
    "DELETE",
    idToken
  );
};

export const GetTables = async (idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/tables_list`,
    "GET",
    idToken
  );
};

export const ImportTable = async (uuid, idToken, payload) => {
  const response = await callEndpointWithFormDataPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}/import`,
    "POST",
    idToken,
    payload
  );
  if (response?.ok) {
    return response
  }

  return await response.json()  
};

export const BulkImport = async (uuid, idToken, payload) => {
  const response = await callEndpointWithFormDataPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}/bulk_import`,
    "POST",
    idToken,
    payload
  );

  if (response?.ok) {
    return response
  }

  return await response.json()
};

export const ExportTable = async (uuid, idToken, tableName) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${uuid}/export?table=${tableName}`,
    "GET",
    idToken
  );
};

export const ExportTablev2 = async (uuid, idToken, tableName) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/apps/${uuid}/export?table=${tableName}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      timeout: 1000 * 60 * 10,
    }
  );
};

export const DisableApp = async (uuid, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${uuid}/disable`,
    "POST",
    idToken
  );
};

export const EnableApp = async (uuid, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${uuid}/enable`,
    "POST",
    idToken
  );
};

export const ResendSetupPassword = async (creatorUuid, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/creators/${creatorUuid}/resend_setup`,
    "POST",
    idToken
  );
};

export const FreeTrial = async (payload) => {
  return await callPublicEndpoint(
    `${process.env.REACT_APP_API}/apps/self_service`,
    "POST",
    {
      data: payload,
    }
  );
};
export const GetWebApp = async (username, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/web/${username}`,
    "GET",
    idToken
  );
};
export const UpdateAppDomains = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}/domains`,
    "POST",
    idToken,
    payload
  );
};

export const UpdateBusiness =  async (uuid, idToken, payload, childUuid) => {
  return await callEndpointWithPayload(
    `${process.env.REACT_APP_API}/apps/${uuid}/children/${childUuid}`,
    "PATCH",
    idToken,
    payload 
  );
};