const actionFormListingConfig = {
    label: "ACTIONFORM",
    resourceEndpoint: "actionform",
    listMetadata: [
    ],
    keepListMetadataOrder: true,
    searchKeys: ["name"],
    detailsMetadata: [
      { name: "form_type", label: "Form Type"},
      { name: "name", label: "Form Name"},
      { name: "headline", label: "Headline"},
      { name: "actionform_description", label: "Description"},
      { name: "tags", label: "Tag"},
      { name: "email", label: "Notification Email"},
      { name: "submit_link", label: "Submit Link"},
      { name: "active", label: "Active"},
      { name: "auto_approve", label: "Auto-Appoval of Submitted Forms" },
      { name: "enterprise", label: "Business Listing"},
      { name: "directories", label: "Which Directory?"},
      { name: "link_expiry_date", label: "Link Expiry Date"}
    ],
    formLayout: {
      columns: [
        [
          "form_type",
          "name",
          "headline",
          "actionform_description",
        ],
        [
          "email",
          "tags",
          "submit_link",
          "active",
          "auto_approve",
          "enterprise",
          "directories",
          "link_expiry_date",
          "custom_url",
        ],
      ],
    },
    subTables: {
      url_subtopics: {
        label: {
          plural: "Custom URLs",
          singular: "Custom URL",
        },
        listMetadata: [
          { name: "id", label: "", hide: true, parser: (data) => data.id },
          { name: "name", label: "Link Text", parser: (data) => data.name },
        ],
        detailsMetadata: [
          {
            name: "name",
            label: "Link Text",
            validations: [{ type: "required", params: ["Required"] }],
          },
        ],
      },
    },
  }
  
  export default actionFormListingConfig;
  