import { useState } from "react";
import DataTable from "../components/DataTable";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteProfile } from "../services/api/profile";
import { DeleteOffer } from "../services/api/offer";
import { DeleteTopic } from "../services/api/topic";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { mergeArray } from "../lib/arrayTools";

const SubmittedActionForms = ({
  type,
  submittedForms = [],
  searchKeys = [],
  fetchSubmittedForms,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = () => {
    try {
      setIsLoading(true);
      fetchSubmittedForms();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const profileMetadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => (data?.active === true ? "Yes" : "No"),
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "user_email",
      label: "Email Address",
      parser: (data) => data?.action_form_content_meta_data?.user_email,
    },
    {
      name: "title",
      label: "Title",
      parser: (data) => data.title,
      ellipsis: true,
    },
    {
      name: "business_name",
      label: "Business Name",
      parser: (data) => data.business_name,
      ellipsis: true,
    },
    {
      name: "directory",
      label: "Topic/ Directory Listing",
      parser: (data) => data?.directory?.name,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const listingMetadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => (data?.active === true ? "Yes" : "No"),
    },
    {
      name: "sort_order",
      label: "Sort Order",
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
    },
    {
      name: "user_email",
      label: "Email Address",
      parser: (data) => data?.action_form_content_meta_data?.user_email,
    },
    {
      name: "sub_topics",
      label: "Sub-Topics",
      width: 200,
      parser: (data) => (
        <span className="ttnk-tag">{data.sub_topics?.join(", ")}</span>
      ),
    },
    {
      name: "directories",
      label: "Which Directory?",
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.directories, "name")}</span>
      ),
    },
    {
      name: "tags",
      label: "Tags",
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.tags, "name")}</span>
      ),
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const offerMetadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => (data?.active === true ? "Yes" : "No"),
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data?.action_form_content_meta_data?.name,
    },
    {
      name: "unlock_code",
      label: "Unlock Code",
      parser: (data) => data.unlock_code,
    },
    {
      name: "title",
      label: "Title",
      parser: (data) => data.title,
    },
    {
      name: "user_email",
      label: "Email Address",
      parser: (data) => data?.action_form_content_meta_data?.user_email,
    },
    {
      name: "message",
      label: "Message",
      parser: (data) => data.message,
      ellipsis: true,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const getMetadata = () => {
    switch (type) {
      case "Profile":
        return profileMetadata;
      case "Topic":
        return listingMetadata;
      default:
        return offerMetadata;
    }
  };

  const updateButtonHandler = (id) => {
    history.push(`/${type.toLowerCase()}s/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    if (type === "Profile") {
      await DeleteProfile(id, user.idToken);
    } else if (type === "Offer") {
      await DeleteOffer(id, user.idToken);
    } else {
      await DeleteTopic(id, user.idToken);
    }

    fetchSubmittedForms();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel={type === "Topic" ? "Listing" : type}
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <div className="w-full bg-white p-5 mt-5">
      <div className="grid grid-flow-col grid-cols-2 gap-4 mt-5 mb-1">
        <h1 className="ttnk-page__title m-0">SUBMITTED ACTIONFORMS</h1>
      </div>
      <DataTable
        metadata={getMetadata()}
        dataLoader={loadData}
        data={{
          loading: isLoading,
          data: submittedForms,
          count: submittedForms?.length,
        }}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={searchKeys}
      />
    </div>
  );
};

export default SubmittedActionForms;
