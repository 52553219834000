import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EntryForm,
  Column,
  TinyEditor,
} from "../components/EntryForm";
import Page from "../components/Page";
import { useUserProvider } from "../providers/UserProvider";
import MediaUploader from "../components/MediaUploader";
import { GetApp, UpdateApp } from "../services/api/app";

const PortalHomeScreen = () => {
  const [initialValues, setInitialValues] = useState({});
  const { user, setUser } = useUserProvider();
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  useEffect(() => {
    const { enterprise_portal_image_url, enterprise_portal_description, media_metadata } =
      user?.app_data?.setup ?? {};
    setInitialValues({
      enterprise_portal_image_url: enterprise_portal_image_url,
      enterprise_portal_description: enterprise_portal_description,
      media_metadata: media_metadata
    });
  }, [user]);

  let links = [
    { name: "Home", url: "/" },
    { name: "Portal HomeScreen", url: `/portal_homescreen` },
  ];

  let schema = {
    enterprise_portal_description: Yup.string().required("Required"),
    enterprise_portal_image_url: Yup.string().required("Required"),
    media_metadata: Yup.object().optional(),
  };

  const getData = useCallback(() => {
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const app_setup = res.data.setup;
        let app_data = res.data;

        delete app_data.member_content_message;
        delete app_data.id;
        delete app_data.name;

        let tempUser = user;
        tempUser.app_data.setup = res.data.setup;
        setUser(tempUser);

        history.push('/')
   
      }).finally(() => {
        setIsLoading(false)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      setIsLoading(true)
      const payload = { setup: values };
      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(() => getData())
    },
  });

  return (
    <Page links={links} title={"Portal HomeScreen"} isLoading={isLoading}>
      <EntryForm
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/"
      >
        <Column>
          <MediaUploader
            name="enterprise_portal_image_url"
            type="text"
            label={"Logo"}
            formik={formik}
            mediaType="image"
          />
        </Column>
        <Column>
          <TinyEditor
            name="enterprise_portal_description"
            quillName="web_text"
            type="text"
            label={"Content"}
            formik={formik}
          />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default PortalHomeScreen;
