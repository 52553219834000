import { Breadcrumb } from "antd";
import { ChevronRightOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";

const BreadcrumbNavigation = ({ links }) => {
    
  return (
    <Breadcrumb className="flex ttnk-breadcrumb uppercase" separator={<ChevronRightOutlined/>}>
      {links.map((link, index) => ( 
        <Breadcrumb.Item key={index}>
          <Link to={link.url}>{link.name}</Link>
        </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  )
}

export default BreadcrumbNavigation;