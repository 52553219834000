import { useState } from "react";
import moment from "moment";
import { Radio } from "antd";

import { VideoPlayer } from "../components/VideoPlayer";
import { Image } from "../components/Image";
import { FileUpload } from "../components/FileUpload";
import BatteryGauge from "react-battery-gauge";
import Counter from "../components/Counter";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Description from "../components/Description";
import Accordion from "../components/accordion";
import DateSelector from "../components/DateSelector";
import StatusDropdown from "../components/StatusDropdown";
import { FaEdit } from "react-icons/fa";

import { useFormik } from "formik";
import { EntryForm, Column, Input, TextArea } from "../components/EntryForm";
import * as Yup from "yup";
import { validations } from "../lib/form";
import SlideOut from "../components/SlideOut";
import { useDrawerProvider } from "../providers/DrawerProvider";
import OnboardingContentList from "../components/onboarding/OnboardingContentList";

const Components = () => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    url: "",
    description: "",
    release_date: moment().utcOffset(0, true),
  });
  const [accInitialValues, setAccInitialValues] = useState({
    url: "",
    description: "",
  });

  let schema = {
    name: validations.str_req_50,
    email: validations.str_req_50,
    url: validations.url_req,
    description: validations.long_text_required,
    release_date: Yup.date().required("Required"),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      console.log({ values });
    },
  });

  let accSchema = {
    url: validations.url_req,
    description: validations.long_text_required,
  };

  const accFormik = useFormik({
    initialValues: accInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(accSchema),
    onSubmit: async (values) => {
      console.log({ values });
    },
  });

  const StepOneBody = () => {
    return (
      <div className="w-full">
        <EntryForm
          submitHandler={accFormik.handleSubmit}
          formik={accFormik}
          removeStyle={true}
          isShowButtonIcon={false} // set to False to apply style intended for Onboarding  button
        >
          <Column width="w-full">
            <Input name="url" type="text" label="URL" formik={accFormik} />
            <TextArea
              name="description"
              type="text"
              label="About App Text"
              formik={accFormik}
            />
          </Column>
        </EntryForm>
      </div>
    );
  };

  const StepTwoBody = () => {
    return (
      <div className="w-full">
        <p className="text-xl">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.
        </p>
        <p className="text-xl mt-10">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua.
        </p>
      </div>
    );
  };

  const [accordionList, setAccordionList] = useState([
    {
      id: 1,
      label: "Step One: 1",
      has_details: true,
      is_complete: true,
      editable: true,
      body: <StepOneBody />,
    },
    {
      id: 2,
      label: "Step Two: 2",
      has_details: true,
      is_complete: true,
      editable: true,
      body: <StepTwoBody />,
    },
    {
      id: 3,
      label: "Step Three: 3 (Not Editable)",
      has_details: false,
      is_complete: false,
      editable: false,
      body: "<p>Body of Step Three",
    },
  ]);

  const handleOnCheck = async (id, value) => {
    const newChecklists = accordionList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_complete: value,
        };
      }

      return item;
    });

    await setAccordionList(newChecklists);
  };

  const { showDrawer, closeDrawer } = useDrawerProvider();

  return (
    <table className="mt-36 mx-auto border border-solid w-2/3">
      <tbody>
        <tr className="border border-solid">
          <th>
            <p className="text-left">EntryForm</p>
          </th>
          <td className="border border-solid">
            <div className="w-10/12 h-4/12 mx-auto py-10">
              <EntryForm
                submitHandler={formik.handleSubmit}
                formik={formik}
                removeStyle={true}
                applyDefaultButtonStyle={false}
              >
                <Column width="w-full">
                  <Input name="name" type="text" label="Name" formik={formik} />
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    formik={formik}
                  />
                  <Input name="url" type="text" label="URL" formik={formik} />
                  <TextArea
                    name="description"
                    type="text"
                    label="About App Text"
                    formik={formik}
                  />
                  <DateSelector
                    name="release_date"
                    type="text"
                    label="Release Date"
                    formik={formik}
                  />
                </Column>
              </EntryForm>
            </div>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Video</p>
          </th>
          <td className="border border-solid">
            <div className="w-4/12 h-4/12 mx-auto">
              <VideoPlayer url="https://www.youtube.com/watch?v=XqZsoesa55w" />
            </div>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Image</p>
          </th>
          <td className="border border-solid">
            <Image
              src="https://www.digitalcitizen.life/wp-content/uploads/2020/10/screenshots_2.jpg"
              alt="Sample Screenshot"
              className="w-4/12"
            />
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Battery Status</p>
          </th>
          <td className="border border-solid">
            <div className="flex justify-center items-center">
              <BatteryGauge
                value={50}
                size={64}
                customization={{
                  batteryBody: {
                    strokeWidth: 2,
                    cornerRadius: 0,
                    strokeColor: "#2650A0",
                  },
                  batteryMeter: {
                    fill: "#2F91CF",
                    outerGap: 0,
                    interCellsGap: 0
                  },
                  batteryCap: {
                    strokeWidth: 0,
                  },
                  readingText: {
                    lightContrastColor: '#2650A0',
                    darkContrastColor: '#FFFFFF',
                    className: 'text-3xl ttnk-AvenirNextBold'
                  },
                }}
              />
            </div>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Counter</p>
          </th>
          <td className="border border-solid">
            <Counter
              className="text-center font-bold"
              currentCount="1"
              totalCount="3"
            />
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">
              Single line fields <br /> (for zip file)
            </p>
          </th>
          <td className="border border-solid">
            <FileUpload
            onChange={(media) => console.log(media)}
              isButton="true"
              buttonClassName="flex justify-center items-center border-4 border-aeblue border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue font-bold"
            />
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">
              Image Upload <br /> (single)
            </p>
          </th>
          <td className="border border-solid">
            <FileUpload fileType="image/png, image/jpeg, image/jpg" />
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Save Progress</p>
          </th>
          <td className="border border-solid">
            <Button className="underline text-lg text-aeblue-default">
              Save Progress
            </Button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Submit/ Assets button</p>
          </th>
          <td className="border border-solid">
            <div className="flex gap-2">
              <Button>SUBMIT</Button>
              <Button>SAVE</Button>
            </div>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Manage Button</p>
          </th>
          <td className="border border-solid">
          <Button className="underline text-aeblue-default">
            <div className="flex gap-4 items-center justify-center">
              <span className="ttnk-AvenirNextBold text-15">Manage</span>
              <FaEdit className="h-10 w-10"/>
            </div>
          </Button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Description title</p>
          </th>
          <td className="border border-solid">
            <Heading className="text-3xl">Description Title</Heading>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Description</p>
          </th>
          <td className="border border-solid">
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Description>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Radio Button</p>
          </th>
          <td className="border border-solid">
            <Radio.Group
              className="flex flex-row"
            >
              <Radio
                value="everyone"
              >
                Everyone
              </Radio>
              <Radio
                value="tag"
              >
                Tag
              </Radio>
            </Radio.Group>
          </td>
        </tr>
        <tr>
          <th>
            <p className="text-left">Status Dropdown</p>
          </th>
          <td className="border border-solid">
            <StatusDropdown />
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Accordion</p>
          </th>
          <td className="border border-solid">
            {accordionList.map((item) => (
              <Accordion
                key={item.id}
                item={item}
                value={item.is_complete ?? false}
                onChange={handleOnCheck}
              />
            ))}
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Slide out</p>
          </th>
          <td className="border border-solid p-4">
            <button
              className="ttnk-button mt-2"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="directories"
                    showSubmitBtn
                    handleSubmit={closeDrawer}
                  />,
                  "Directory",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Videos Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="videos"
              title="Videos"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="videos"
                  />,
                  "Videos",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Articles Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="articles"
              title="Articles"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="articles"
                  />,
                  "Articles",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Blogs Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="tips"
              title="Blogs"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="tips"
                  />,
                  "Blogs",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Journals Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="journals"
              title="Journals"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="journals"
                  />,
                  "Journals",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Topics Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="topics"
              title="Topics"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="topics"
                  />,
                  "Topics",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Home Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="welcome_screens"
              title="Home"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="welcome_screens"
                  />,
                  "Home",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Profile Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="profiles"
              title="Profile"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="profiles"
                  />,
                  "Profile",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Gallery Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="galleries"
              title="Gallery"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="galleries"
                  />,
                  "Gallery",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
        <tr className="border border-solid">
          <th>
            <p className="text-left">Q&A Slide Out</p>
          </th>
          <td className="border border-solid p-4">
            <OnboardingContentList
              type="questions"
              title="Q&A"
            />
            <button
              className="ttnk-button mt-4"
              type="button"
              onClick={() => {
                showDrawer(
                  <SlideOut
                    type="questions"
                  />,
                  "Q&A",
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Open slideout
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Components;
