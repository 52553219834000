import React from "react";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { GetApp, UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { SUBMITTED } from "../lib/onboarding_config/config";
import { VideoPlayer } from "./VideoPlayer";

const OnboardingDeveloperAccounts = (props) => {
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();

  const isAppStoreInfoCompleted =
    onboardingData?.progress?.store?.info &&
    onboardingData?.progress?.store?.listing &&
    onboardingData?.progress?.store?.support;

  const handleScheduleNow = () => {
    const _values = {
      onboarding: {
        progress: {
          store: {
            status: isAppStoreInfoCompleted
              ? SUBMITTED
              : onboardingData?.progress?.store?.status,
          },
          developer: {
            status: SUBMITTED,
          },
        },
      },
    };
    UpdateApp(user.currentApp(), user.idToken, {
      data: {
        ..._values,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        setOnboardingData(res.data.onboarding);
      });
  };

  return (
    <div>
      <div className="w-4/6 mx-auto">
        <VideoPlayer url="https://youtu.be/W7Mx4RGQG50" />
      </div>
      <div className="px-2 my-4 text-xl">
        <div className="text-xl">
          Great job making it this far as we are now on the last step of the
          "Launch Your App" wizard. We are going to help you create your Apple
          and Google Developer Accounts. These Accounts are essential to getting
          your app approved and into the app stores. This step is completed by
          scheduling a private Zoom call with a trained professional who will
          help you set up your Accounts.{" "}
          <u className="text-xl">
            Please watch the video above, then read and do everything below
            BEFORE you schedule that call.
          </u>
        </div>
        <div className="font-bold mt-2 mb-1 text-xl">WHAT TO EXPECT</div>
        <div className="text-xl">
          This Zoom call should only take 30-40 minutes of your time (but block
          out an hour, just in case). You will need to make a payment to Apple
          and a separate payment to Google to create these accounts. Pricing may
          fluctuate, so plan for approximately $125-$250 for both. You may need
          to provide information to verify your ID and address. You will need to
          acquire a D-U-N-S Number, learn how below.
        </div>
        <div className="font-bold mt-2 mb-1 text-xl">WHAT ARE DEVELOPER ACCOUNTS</div>
        <div className="text-xl">
          To have your app available for download on the Apple App Store and
          Google Play Store, you need Developer Accounts. This process can be
          complex and easy to do incorrectly, so we'll guide you through it on a
          Zoom call.
        </div>
        <div className="font-bold mt-2 mb-1 text-xl">
          “WHAT IF I HAVE DEVELOPER ACCOUNTS?”
        </div>
        <div className="text-xl">
          Even if you already have accounts, we need a Zoom call to confirm and
          gather information.{" "}
          <i className="text-xl">
            (If you have Accounts already, confirm you know your login details
            by reading below and then skip to the scheduling button at the
            bottom.)
          </i>
        </div>
        <div className="font-bold mt-2 mb-1 text-xl">
          “CAN I SET UP MY DEVELOPER ACCOUNTS BY MYSELF?”
        </div>
        <div className="text-xl">
          Yes! But if you do it incorrectly or run into trouble, we will not be
          able to help you navigate your way out of it. You will need to use the
          support resources made available by each of these major corporations
          to figure out how to create these accounts. Once created, schedule a
          Zoom call with us and we will help you set up your app in these
          Accounts.
        </div>
        <div className="font-bold mt-4 mb-3 text-xl">ACCOUNT SETUP DETAILS</div>
        <div className="font-bold mt-2 mb-1 text-xl">APPLE (for IOS) REQUIREMENTS:</div>
        <ul className="list-disc pl-5 text-xl">
          <li className="text-xl">
            <u className="text-xl">Payment</u>: ~$99/year. Paid to Apple during our call. Credit
            Cards usually work better than Debit and you may still need to
            contact your bank to get the payment to process. You can also use
            Apple Pay.
          </li>
          <li className="text-xl">
            <u className="text-xl">Apple ID</u>: Create one{" "}
            <a
              href="https://appleid.apple.com/"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-xl"
              style={{
                color: "blue",
              }}
            >
              HERE
            </a>{" "}
            if you don't have one.
          </li>
          <li className="text-xl">
            <span className="underline text-xl">Account Type</span><span className="text-xl">:We assist with
            Individual/Sole Proprietor Accounts only. We recommend against the
            Organization Type Account which offers little to no advantage and
            costs you more.</span>
          </li>
        </ul>
        <div className="font-bold mt-2 mb-1 text-xl">
          GOOGLE (for Android) REQUIREMENTS:
        </div>
        <ul className="list-disc pl-5 text-xl">
          <li className="text-xl">
            <u className="text-xl">Payment</u>: ~$25 (onetime payment).
          </li>
          <li className="text-xl">
            <u className="text-xl">Gmail</u>: You will need an email ending in …@gmail.com. You may
            also prefer to use an email not connected to any sensitive
            information (such as your bank accounts). If you do not have a
            Gmail, you can create one{" "}
            <a
              href="https://www.google.com/intl/en-US/gmail/about/"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
              style={{
                color: "blue",
              }}
            >
              HERE
            </a>
            .
          </li>
          <li className="text-xl">
            <u className="text-xl">D-U-N-S Number</u>: Google requires that you have a D-U-N-S
            Number to set up a Business Developer Account. You can learn more{" "}
            <a
              href="https://www.dnb.com/duns.html"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
              style={{
                color: "blue",
              }}
            >
              HERE
            </a>
            . You can begin the process of acquiring a D-U-N-S Number by reading
            this article{" "}
            <a
              href="https://actionera.freshdesk.com/support/solutions/articles/69000855833-how-to-set-up-an-android-organization-developer-account"
              target="_blank"
              rel="noreferrer"
              className="font-medium"
              style={{
                color: "blue",
              }}
            >
              HERE
            </a>
            . Go straight to, 'Obtaining a D-U-N-S Number.'
          </li>
          <li className="text-xl">
            <ul className="text-xl">
              <u className="text-xl">Account Type</u>: We assist with Business Developer Accounts
              only.
            </ul>
          </li>
        </ul>
        <div className="font-bold mt-4 mb-3 text-xl">PREPARATION</div>
        <div className="font-bold mt-2 mb-1 text-xl">DO THIS</div>
        <div className="text-xl">Confirm your Apple ID and Gmail login information.</div>
        <ul className="list-disc pl-5 text-xl">
          <li className="text-xl">
            Open an Incognito Window in your browser and copy the links below.
          </li>
          <li className="text-xl">
            Verify your Apple ID{" "}
            <a
              href="https://www.apple.com/"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-xl"
              style={{
                color: "blue",
              }}
            >
              Apple
            </a>
          </li>
          <li className="text-xl">
            Verify your Gmail login{" "}
            <a
              href="https://www.gmail.com/"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-xl"
              style={{
                color: "blue",
              }}
            >
              https://www.gmail.com/
            </a>
          </li>
          <li className="text-xl">
            Save your login details for our Zoom call{" "}
            <i className="text-xl">(do not email them to us).</i>
          </li>
        </ul>
        <div className="font-bold mt-2 mb-1 text-xl">HAVE THESE</div>
        <ul className="list-disc pl-5 text-xl">
          <li className="text-xl">The phone(s) connected to your Apple ID and Gmail Account</li>
          <li className="text-xl">A Credit Card (back-up card may be a good idea too)</li>
          <li className="text-xl">
            Valid Driver's License (photos of front and back with all 4-corners
            visible)
          </li>
          <li className="text-xl">Your Business D-U-N-S Number</li>
          <li className="text-xl">
            Proof of Address (utility bill, phone bill, bank statement, lease,
            or mortgage agreement within the last 60 days)
          </li>
        </ul>
        <div className="font-bold mt-2 mb-1 text-xl">FINAL NOTES</div>
        <ul className="list-disc pl-5">
          <li className="text-xl">
            Login Details – Don't change your login info until after your app is
            approved unless there's a security concern. Notify us if you do.
          </li>
          <li className="text-xl">
            Account Access – We can only access your accounts with your
            permission.
          </li>
          <li className="text-xl">
            Can’t get D-U-N-S Number – if you have followed our guide to
            acquiring one and are unable to do so, then please email:
            <a
              href="mailto:matt@actionera.com"
              className="font-medium text-xl"
              style={{
                color: "blue",
              }}
            >
              matt@actionera.com
            </a>
          </li>
        </ul>
        <div className="mt-2 mb-1 text-xl">Ready to Schedule a Meeting?</div>
        <div className="text-xl">
          If you've confirmed your Apple ID and Gmail information, you can
          schedule your Zoom call using the button below.
        </div>
      </div>
      <button
        className="ttnk-button float-right"
        onClick={() => {
          handleScheduleNow();
          window.open(
            "https://calendly.com/actioneraplatform/onboarding-call",
            "_blank"
          );
        }}
        disabled={onboardingData?.progress?.developer?.status === "SUBMITTED"}
      >
        Schedule Now
      </button>
    </div>
  );
};

export default OnboardingDeveloperAccounts;

export const DeveloperAccountsStatus = () => {
  const { onboardingData } = useOnboardingProvider();

  if (onboardingData?.progress?.developer?.status !== SUBMITTED) return null;

  return (
    <div
      className="ttnk-AvenirNextBold"
      style={{
        color: "#2F91CF",
      }}
    >
      {onboardingData?.progress?.developer?.status}
    </div>
  );
};
