import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";

import { Upload } from "antd";
import axios from "axios";
import UploadFile from "../assets/icons/upload-file.svg";
import { useUserProvider } from "../providers/UserProvider";

import { ReactComponent as DeleteIcon } from "../assets/icons/trash.svg";
import { useMemo } from "react";

const maxSize = 10000000;

const BulkPhotos = ({ formik, maxCountValue, isUploadOnly, label }) => {
  const maxCount = maxCountValue || 48;
  const uploadLabel = label || `Drag and drop files here (maximum ${maxCount} at a time) or click to browse your files. Each image must be less than 10MB.`

  const { Dragger } = Upload;

  const [fileList, setFileList] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploading, setUploading] = useState(false);
  const hasInvalidFile = useMemo(() => {
    return fileList.filter((a) => a.size > maxSize)?.length > 0;
  }, [fileList]);

  const { user } = useUserProvider();

  const uploadFiles = async () => {
    const fmData = new FormData();
    let uploadedFiles = [];
    let uploadStat = {};

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + user.idToken,
      },
    };

    setUploading(true);

    const updatedValues = cloneDeep(formik.values);
    let photos = updatedValues["photos"];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      fmData.append("type", "image");
      fmData.append("file", file);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/media`,
          fmData,
          config
        );

        const data = res.data.data;
        const payload = {
          image_url: `media:${data.id}`,
          sub_description: "",
          description: "",
          sort_order: 1,
          media_metadata: {},
        };

        payload.media_metadata[data.id] = {
          filename: data.filename,
          name: data.filename,
          id: data.id,
        };

        photos.push(payload);
        uploadStat[file.uid] = "uploaded";
      } catch (err) {
        const error = new Error("Some error");
        uploadStat[file.uid] = "error";
        setUploadStatus(uploadStat);
      }
    }

    formik.setFieldValue("photos", photos);
    formik.setFieldTouched("photos");

    setFileList([]);
    setUploading(false);
  };

  const pushPhotoData = (data) => {};

  const draggerProps = {
    accept: "image/jpg,image/jpeg,image/png,image/gif",
    name: "file",
    multiple: true,
    showUploadList: false,
    beforeUpload: (file, list) => {
      setFileList((oldArray) => [...fileList, ...list].slice(0, maxCount));
      return false;
    },
    maxCount: maxCount,
    disabled: uploading || fileList.length === maxCount,
  };

  const fileUploadDeleteHandler = (id) => {
    setFileList(fileList.filter((file) => file.uid !== id));
  };

  const draggerText = () => {
    let text =
      fileList.length >= maxCount
        ? "Maximum count reached. Please upload files first"
        : uploadLabel;
    text = uploading ? "Uploading photos. Please wait" : text;

    return text;
  };

  return (
    <div className="mb-5">
      {!isUploadOnly && <h2 className="font-bold mb-5 text-lg">Bulk Photo Upload</h2>}
      <Dragger {...draggerProps}>
        <p className="justify-center w-full flex">
          <img src={UploadFile} alt="Upload File" />
        </p>
        <p className="text-lg py-5">{draggerText()}</p>
      </Dragger>
      {formik.errors?.photos && <p className="text-red-600">{formik.errors.photos}</p>}

      {fileList.length > 0 && (
        <div className="mt-5">
          <h2 className="font-bold mb-5 text-lg">Files to Upload</h2>
          <ul>
            {fileList.map((file) => (
              <li key={file.uid} className="mt-2 flex">
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => fileUploadDeleteHandler(file.uid)}
                  disabled={uploading}
                >
                  <DeleteIcon className="mr-2 h-5 w-5 fill-current text-gray-400 trash hover:text-red-600" />
                </button>
                {file.name} {uploadStatus[file.uid]}{" "}
                <span className="text-red-600 ml-2">
                  {file.size > maxSize ? "Image must be less than 10MB" : ""}
                </span>
              </li>
            ))}
          </ul>
          <button
            className="ttnk-button mt-2"
            onClick={(e) => {
              uploadFiles();
              e.preventDefault();
            }}
            disabled={uploading || fileList.length <= 0 || hasInvalidFile}
          >
            {uploading ? "Uploading" : "Upload Now"}
          </button>
        </div>
      )}
    </div>
  );
};

export default BulkPhotos;
