import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { GetEvent, UpdateEvent } from "../services/api/events";
import { AddAppResource } from "../services/api/app";
import Page from "../components/Page";
import {
  Column,
  EntryForm,
  Input,
  Select,
  TextArea,
} from "../components/EntryForm";
import DateSelector from "../components/DateSelector";
import MediaUploader from "../components/MediaUploader";
import responseProcessor from "../lib/responseProcessor";
import { validations } from "../lib/form";
import DataSelector from "../components/DataSelector";

const EventCalendarDetail = ({ newEvent, match }) => {
  const [initialValues, setInitialValues] = useState({
    category: "",
    name: "",
    description: "",
    video_url: "",
    image_url: "",
    month: "",
    day_of_month: 1,
    time: "",
    location: "",
    expiration: moment().utcOffset(0, true),
    action_bar_text: "",
    action_bar_url: "",
    media_metadata: {},
    tags: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newEvent ? user.currentApp() : match.params.uuid;
  const history = useHistory();

  useEffect(() => {
    if (newEvent) {
      setInitialValues({
        category: "",
        name: "",
        description: "",
        video_url: "",
        image_url: "",
        month: "",
        day_of_month: 1,
        time: "",
        location: "",
        expiration: "",
        // expiration: moment().utcOffset(0, true),
        action_bar_text: "",
        action_bar_url: "",
        tags: [],
      });
    } else {
      setIsLoading(true);
      GetEvent(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            category: res.data.category,
            name: res.data.name,
            description: res.data.description,
            video_url: res.data.video_url,
            image_url: res.data.image_url,
            month: res.data.month,
            day_of_month: res.data.day_of_month,
            time: res.data.time,
            location: res.data.location,
            expiration: res.data.expiration,
            action_bar_text: res.data.action_bar_text,
            action_bar_url: res.data.action_bar_url,
            tags: res.data.tags,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newEvent ? "New" : "Edit";
  const currentUrl = newEvent ? "/events/new" : `/events/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Event Calendar", url: "/events" },
    { name: `${currentAction} Event`, url: currentUrl },
  ];

  let schema = {
    category: Yup.string().oneOf(["Live", "Online"]).required("Required"),
    name: validations.str_req_255,
    description: validations.str_multiline,
    video_url: validations.long_text,
    image_url: validations.long_text,
    month: Yup.string()
      .oneOf([
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ])
      .required("Required"),
    day_of_month: Yup.number()
      .typeError("Must be a number from 1 to 31")
      .integer()
      .min(1, "Minimum day of month: 1")
      .max(31, "Maximum day of month: 31")
      .required("Required"),
    time: validations.str_20,
    location: validations.str_20,
    expiration: Yup.date().required("Required"),
    action_bar_text: validations.str_notreq_50,
    action_bar_url: validations.str_255,
  };

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/events"),
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (newEvent) {
        AddAppResource(uuid, "events", user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateEvent(uuid, user.idToken, { data: values }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  return (
    <Page links={links} title={`${currentAction} Event`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/events"
      >
        <Column>
          <Select name="category" label="Type" formik={formik}>
            <option value={null} label="Select Type..." />
            <option value="Live" label="Live" />
            <option value="Online" label="Online" />
          </Select>
          <Input name="name" type="text" label="Event Name" formik={formik} />
          <TextArea name="description" label="Description" formik={formik} />
          <MediaUploader
            name="video_url"
            type="text"
            label="Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
          <Select name="month" label="Month" formik={formik}>
            <option value={null} label="Select Month..." />
            <option value="JAN" label="JAN" />
            <option value="FEB" label="FEB" />
            <option value="MAR" label="MAR" />
            <option value="APR" label="APR" />
            <option value="MAY" label="MAY" />
            <option value="JUN" label="JUN" />
            <option value="JUL" label="JUL" />
            <option value="AUG" label="AUG" />
            <option value="SEP" label="SEP" />
            <option value="OCT" label="OCT" />
            <option value="NOV" label="NOV" />
            <option value="DEC" label="DEC" />
          </Select>
        </Column>
        <Column>
          <Input
            name="day_of_month"
            type="number"
            min={1}
            max={31}
            label="Day of Month"
            formik={formik}
          />
          <Input name="time" type="text" label="Time" formik={formik} />
          <Input name="location" type="text" label="Location" formik={formik} />
          <DateSelector
            formik={formik}
            name="expiration"
            label="Event Listing Expires (Date)"
            onChange={(selectedDate) =>
              formik.setFieldValue("expiration", selectedDate || "")
            }
          />
          <Input
            name="action_bar_text"
            type="text"
            label="Call to Action Text"
            formik={formik}
          />
          <Input
            name="action_bar_url"
            type="text"
            label="Call to Action URL"
            formik={formik}
          />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default EventCalendarDetail;
