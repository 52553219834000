import React from 'react'
import { useHistory } from 'react-router-dom'

function AccountStatusSelectionItem({ summary, item, note }) {
  const history = useHistory();

  return (
    <div className='flex flex-col items-center justify-center gap-5 mb-12'>
        <div className="h-11">
            <div className='font-medium text-xl text-center uppercase' style={{
                fontWeight: 500
            }}>
                {item.label}
            </div>
            {item.note && <span className="block w-full text-center">{item.note}</span>}
        </div>
        <div style={{
            height: 100,
            cursor:'pointer'
        }}
            onClick={() => history.push(`/account_status/${item.id}`)}
        >
            {item.image}
        </div>
        <a className='font-bold text-blue-400 text-xl underline cursor-pointer' href={`/account_status/${item.id}`}>
            {summary?.[item.id] || 0}
        </a>
    </div>
  )
}

export default AccountStatusSelectionItem