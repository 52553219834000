import React from "react";

const PlatformDisclaimer = ({ customClasses = '' }) => {
  return (
    <div className={`font-semibold italic text-center text-aeblue text-lg ${String(customClasses)}`}>
      Available in app Platform Version 9.0 & up and WEB
    </div>
  );
};

export default PlatformDisclaimer