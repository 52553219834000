import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { mergeArray } from "../lib/arrayTools"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { DeleteCourse } from "../services/api/course"
import { GetAppResource } from "../services/api/app"

import DeleteItem from "../components/modal_contents/DeleteItem"

const CourseList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'courses')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        })
      })
  }

  const links = [
    { name: "Home", url: "/" },
    { name: 'Course List', url: '/courses' },
  ]

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),
    },
    {
      name: 'sort_order',
      label: 'Sort Order',
      parser: (data) => (data.sort_order),
      sorter: (firstData, secondData)=>{
        return firstData.sort_order - secondData.sort_order
      },
      defaultSortOrder: "ascend",
      sortDirections:["ascend", "descend"]
    },
    {
      name: 'unlock_code',
      label: 'Unlock Code',
      parser: (data) => (data.unlock_code),
    },
    {
      name: 'active',
      label: 'Active?',
      parser: (data) => (data.active ? 'Yes' : 'No')
    },
    {
      name: 'category',
      label: 'Category',
      parser: (data) => (data?.category?.name),
    },
    {
      name: 'title',
      label: 'Title',
      parser: (data) => (data.title),
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => (<span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>),
    },
    {
      name: 'modules',
      label: '# of Modules',
      parser: (data) => (data?.count?.modules),
    },
    {
      name: 'course_lessons',
      label: '# of Course Lessons',
      parser: (data) => (`${data?.count?.lessons} ( ${data?.count?.active_lessons || 0} active)`),
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} />),
    },
  ]

  const handleAddButtonClick = () => {
    history.push(`/courses/new`)
  }

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={handleAddButtonClick}>Add Course</button>
  }

  const updateButtonHandler = (id) => {
    history.push(`/courses/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteCourse(id, user.idToken)
    loadData()
    closeModal()
  }

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel='Course' onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  const Subtitle = () => (
    <h3 className="m-0 italic">To add or update a module in a course, click Update.</h3>
  )

  return (
    <Page links={links} title="Course List" titleComponent={AddButton()} extra={<Subtitle />}>
      <DataTable  metadata={metadata}
                  dataLoader={loadData}
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  searchKeys={['title', 'creator']} />
    </Page>
  )
}

export default CourseList;
