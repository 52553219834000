import { useCallback, useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { uniqBy, sortBy } from "lodash";
import { useUserProvider } from "../providers/UserProvider";
import { Select } from "../components/EntryForm";
import { GetAppResource, GetAllApps } from "../services/api/app";
import { isEmpty } from "lodash";
import { Work } from "@material-ui/icons";

const DataSelector = (props) => {
  const {
    name,
    label,
    labelClassName,
    placeholder = null,
    formik,
    resourceEndpoint,
    endpoint = "resource",
    dataSet,
    single,
    singleSelectOnly,
    keyOption,
    filteredOptions,
    inSubtable,
    withSearch,
    filterOptions,
    noMarginBottom = false,
    sortByName = false,
    setCurrentData,
    setCurrentSelected,
    setIsDataLoaded,
    onChange,
    disabled,
    withLegend,
    type,
    makeNullOnRemove = false,
    required = false
  } = props;
  let value = formik.values[name];
  const [data, setData] = useState({
    options: [
      // { name: "test", id: 1 },
      // { name: "foo", id: 2 },
    ],
    value: [],
    loading: false,
  });

  // Get values for dot separated fieldnames (ex. profile.name)
  if (name?.indexOf(".") > -1) {
    const paths = name.split(".");
    value = formik?.values?.[paths[0]]?.[paths[1]];
  }

  const selectHandler = (selectedList, selectedItem) => {
    const values = selectedList.map((item) => ({
      id: item.id,
      name: item?.name,
    }));

    if (typeof setCurrentSelected === "function") setCurrentSelected(values);

    if (single) {
      formik.setFieldValue(name, values.length ? selectedItem : {});
    } else {
      formik.setFieldValue(name, values);
    }
  };

  const removeHandler = (selectedList, selectedItem) => {
    const values = selectedList.map((item) => ({ id: item.id }));

    if (typeof setCurrentSelected === "function") setCurrentSelected(values);

    if (single) {
      formik.setFieldValue(name, values.length ? values[0] : makeNullOnRemove ? null : {});
    } else {
      formik.setFieldValue(name, values);
    }
  };

  const { user } = useUserProvider();

  const setParentStates = useCallback(
    (jsonData) => {
      if (typeof setCurrentData === "function") {
        setCurrentData(jsonData);
        if (typeof setIsDataLoaded === "function") setIsDataLoaded(true);
      }
    },
    [setCurrentData, setIsDataLoaded]
  );

  const fetchData = useCallback((endpoint) => {
    setData({ ...data, loading: true });
    const fetchDataCallback = (res) => res.json().then((jsonData) => {
        let allOptions = [];

        if (filterOptions) {
          allOptions = filterOptions(jsonData.data).map((datum) => ({
            name: datum.name ?? datum.title,
            id: datum.id,
            isEnterprise: datum?.enterprise,
          }));
        } else {
          allOptions = jsonData.data.map((datum) => ({
            name: datum.name ?? datum.title,
            id: datum.id,
            isEnterprise: datum?.enterprise,
          }));
        }

        if (keyOption) {
          allOptions = jsonData.data.map((datum) => ({
            name: datum.name || datum.title,
            id: datum[keyOption],
            isEnterprise: datum?.enterprise,
          }));
        }

        if (filteredOptions?.length && formik.values[name] === "") {
          const selectedIds = filteredOptions?.map((f) =>
            f[name]?.toLowerCase()
          );
          allOptions = allOptions?.filter((datum) => {
            if (selectedIds?.includes(datum?.id?.toLowerCase())) return;
            return datum;
          });
        }

        allOptions = sortByName
          ? sortBy(uniqBy(allOptions, "name"), "name")
          : sortBy(uniqBy(allOptions, "id"), "id");

        const currentValue =
          value &&
          allOptions?.filter((opt) => {
            const formikValues = Array.isArray(value) ? value : [value];
            return formikValues
              .map((val) => {
                return val && val.id ? val.id : null;
              })
              .includes(opt.id);
          });

        setData({
          loading: false,
          options: allOptions,
          value: currentValue,
        });

        if (typeof setCurrentSelected === "function" && currentValue)
          setCurrentSelected(currentValue);

        setParentStates(jsonData);
      });

    if (value && inSubtable) {
      const v = data?.options?.find((d) => d?.id === value);
      if (v && name?.indexOf(".") > -1) {
        const paths = name.split(".");
        formik.setFieldValue(`${paths[0]}.name`, v?.name);
      }
    }

    if (endpoint === 'resource') {
      GetAppResource(user, resourceEndpoint)
        .then(fetchDataCallback);
    } else if (endpoint === 'allApps') {
      GetAllApps(user.idToken)
        .then(fetchDataCallback);
    }
  }, [
    data,
    filterOptions,
    filteredOptions,
    formik,
    inSubtable,
    keyOption,
    name,
    resourceEndpoint,
    setCurrentSelected,
    setParentStates,
    sortByName,
    user,
    value,
  ]);

  const setDataForNoResourceEndpoint = useCallback(() => {
    const currentValue = single
      ? value
      : dataSet?.filter((opt) => value?.map((val) => val.id).includes(opt.id));
    setData({
      loading: false,
      options: dataSet,
      value: currentValue,
    });

    if (typeof setCurrentSelected === "function" && currentValue)
      setCurrentSelected(currentValue);
  }, [dataSet, single, value]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;
      if (resourceEndpoint) {
        fetchData(endpoint);
      } else {
        setDataForNoResourceEndpoint();
      }
    }

    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dataSet, filterOptions, resourceEndpoint]);

  return (
    <div className={`flex flex-col w-full ${noMarginBottom ? "" : "mb-5"} data-selector`}>
      <div className="flex items-center space-between w-full">
        {label ? (
          <label
            className={`text-lg mb-3 ${withLegend ? "w-1/3" : "w-full"} ${String(labelClassName)}`}
            htmlFor={name}
          >
            {label}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        ) : null}
        {withLegend ? (
          <div className="flex items-center justify-end gap-4 w-2/3">
            <div className="flex items-center gap-2 w-fit">
              <div className="w-4 h-4 ttnk-button-color" />
              <span className="w-fit">Regular Directory</span>
            </div>
            <div className="flex items-center gap-2 w-fit">
              <div className="w-4 h-4 bg-132850" />
              <span className="w-fit">Enterprise Directory</span>
            </div>
          </div>
        ) : null}
      </div>
      {singleSelectOnly ? (
        <>
          {withSearch ? (
            <>
              <Multiselect
                name={name}
                options={data.options}
                selectedValues={isEmpty(value) ? [] : [value]}
                onSelect={selectHandler}
                displayValue="name"
                placeholder={placeholder || `Select ${label}`}
                hidePlaceholder={isEmpty(placeholder)}
                loading={data.loading}
                loadingMessage={`Loading ${label}...`}
                avoidHighlightFirstOption={true}
                onRemove={removeHandler}
                disable={disabled}
                optionValueDecorator={(val) => {
                  const isEnterprise =
                    data?.options?.find((item) => item?.name === val)
                      ?.isEnterprise ?? false;

                  return (
                    <div
                      style={{
                        padding: "10px 10px",
                      }}
                      className={
                        isEnterprise && withLegend
                          ? "enterprise-option flex flex-row items-center gap-3"
                          : ""
                      }
                    >
                      {isEnterprise && withLegend ? (
                        <div className="flex flex-row items-center enterprise-container">
                          <Work
                            className="h-4 w-4 briefcase-icon"
                            style={{ height: 15, width: 15 }}
                          />

                          <span className="ml-1">{val}</span>
                        </div>
                      ) : (
                        <span className={withLegend ? "ml-6" : ""}>{val}</span>
                      )}
                    </div>
                  );
                }}
                selectedValueDecorator={(val) => {
                  const isEnterprise =
                    data?.options?.find((item) => item?.name === val)
                      ?.isEnterprise || false;
                      const isHidden = !formik.values.enterprise && isEnterprise;
                  return (
                    <div className={isHidden && name === 'directories' ? 'hidden' : isEnterprise ? "enterprise" : ""}>{val}</div>
                  );
                }}
              />

              {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-600">{formik.errors[name]}</div>
              )}
            </>
          ) : (
            <Select
              name={name}
              formik={formik}
              onChange={onChange}
              disabled={disabled}
            >
              <option value={null} label={`Select ${label}...`} />
              {data?.options?.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  label={option.title || option.name}
                />
              ))}
            </Select>
          )}
        </>
      ) : (
        <>
          <Multiselect
            name={name}
            options={data.options}
            selectedValues={data.value}
            onSelect={selectHandler}
            displayValue="name"
            placeholder={placeholder || `Select ${label ?? ""}`}
            hidePlaceholder={isEmpty(placeholder)}
            loading={data.loading}
            loadingMessage={`Loading ${label ?? ""}...`}
            avoidHighlightFirstOption={true}
            onRemove={removeHandler}
            disable={disabled}
            optionValueDecorator={(val) => {
              const isEnterprise =
                data?.options?.find((item) => item?.name === val)
                  ?.isEnterprise || false;

              return (
                <div
                  style={{
                    padding: "10px 10px",
                  }}
                  className={
                    ((isEnterprise && withLegend) || type === "enterprise")
                      ? "enterprise-option flex flex-row items-center gap-3"
                      : ""
                  }
                >
                  {isEnterprise && withLegend ? (
                    <div className="flex flex-row items-center enterprise-container">
                      <Work
                        className="h-4 w-4 briefcase-icon"
                        style={{ height: 15, width: 15 }}
                      />

                      <span className="ml-1">{val}</span>
                    </div>
                  ) : (
                    <span className={withLegend ? "ml-6" : ""}>{val}</span>
                  )}
                </div>
              );
            }}
            selectedValueDecorator={(val) => {
              const isEnterprise =
                data?.options?.find((item) => item?.name === val)
                  ?.isEnterprise || false;
                  const isHidden = !formik.values.enterprise && isEnterprise;
              return (
                <div className={isHidden && name === 'directories' ? 'hidden' : isEnterprise ? "enterprise" : ""}>{val}</div>
              );
            }}  
          />
          {formik.touched[name] && formik.errors[name] && (
            <div className="text-red-600">{formik.errors[name]}</div>
          )}
        </>
      )}
    </div>
  );
};

export default DataSelector;
