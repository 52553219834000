const questionConfig = {
  label: "Q&A",
  resourceEndpoint: "questions",
  listMetadata: [
    "sort_order",
    "title",
    "featured",
    "topics",
    "release_date",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title", "description", "author"],
  detailsMetadata: [
    {
      name: "title",
      label: "Question",
      validations: [
        {
          type: "max",
          params: [100, "Maximum 100 Characters"],
        },
      ],
    },
    {
      name: "description",
      label: "Answer",
    },
    {
      name: "web_description",
      label: "Answer",
    },
    {
      name: "author",
      label: "Asked By",
      validations: [
        {
          type: "max",
          params: [30, "Maximum 30 Characters"],
        },
      ],
    },
    {
      name: "send_to",
      label: "Send To",
      validations: [
        {
          type: "email",
          params: ["Invalid email"],
        },
        {
          type: "max",
          params: [255, "Maximum 255 Characters"],
        },
        {
          type: "required",
          params: ["Required"],
        },
      ],
    },
    { name: "audio_url", label: "Audio URL" },
    { name: "image_url", label: "Image URL" },
    { name: "release_date", label: "Release Date", format: "DD-MMM-YYYY" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "sort_order", label: "Sort Order" },
    { name: "featured", label: "Featured?" },
    { name: "topics", label: "Topics/Listings" },
    { name: "tags", label: "Tags" },
  ],
  formLayout: {
    columns: [
      ["title", "description", "author", "image_url", "audio_url", "send_to"],
      [
        "release_date",
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
      ],
    ],
  },
};

export default questionConfig;
