import { useCallback, useEffect, useState, Fragment } from "react";
import qs from 'qs';
import * as Yup from 'yup'
import { useFormik } from "formik";

import { useModalProvider } from "../providers/ModalProvider";

import { FreeTrial } from "../services/api/app";

import { Column, Input } from "../components/EntryForm";
import ErrorModal from "../components/modal_contents/ErrorModal";

import { validations } from "../lib/form";
import useLocalStorage, { useLocalState, useSetLocalState } from "../lib/useLocalStorage";

import Image from '../assets/images/Creator_Portal.png'
import LoadingLogo from '../assets/images/ae-icon-loading.png'
import Logo from '../assets/images/ae-lt.png'

const schema = {
	app_name: Yup.string().required("Required"),
	name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').max(50, 'Maximum 50 Characters').required('Required'),
	password: validations.password.required('Required')
}

const AccountSetupForm = ({ useThrivecart, type = null }) => {
	const { showModal } = useModalProvider()
	const [defaultLogo, setDefaultLogo] = useState('');
	const [defaultIcon, setDefaultIcon] = useState('');
	const [publicApp, setPublicApp] = useState({});

	const url = window.location.href;
  const parts = url.split('/');
	const parentID = parts[3];

	const setIsFreeTrialCreated = useSetLocalState('isFreeTrialCreated')
	const [isCreated, setIsCreated] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [hasInitialized, setHasInitialized] = useState(false)
	const actioneraApps = ['fQEsHpLpF2VogOtx0K5w2oSWrbhwFIzYk9uZy-YvpEY','jMFU8qHThahYKniODrpsEWpLUBROFJbtm3ZjskE7zL0'];
  // eslint-disable-next-line no-unused-vars
  const [currentSelectedApp, setCurrentSelectedApp] = useLocalStorage(
    "currentApp",
    null
  );

	const queryParameters = qs.parse(window.location.search.substring(1))

	let formType = type;
	const typeList = {
		pro: 'PRO',
		pro_plus: 'PRO+',
		free_account: 'FREE'
	}

	if(type) {
		formType = typeList[type];
	}

	useEffect(() => {
		const fetchDefaults = async () => {
			const response = await fetch('https://api.actionera.com/apps/web/actionera')
			const appData = await response.json()
			setDefaultLogo(appData.data.setup.header.logo.logo_url)
			setDefaultIcon(appData.data.setup.icon)
		}		
		const fetchAppData = async () => {
			const uuid = window.location.href.split('/')[3]
			const response = await fetch(`${process.env.REACT_APP_API}/apps/${uuid}`)
			const appData = await response.json()
			setPublicApp(appData.data)
			setHasInitialized(true)
		}
		fetchDefaults();
		fetchAppData()
	}, []);

	useEffect(() => {
		if(String(window.location.href).includes('app')) {
			// redirect to /
			window.location.href = '/';
		}
	}, []);


	const onSubmit = async (values) => {
		if(!queryParameters.thrivecart_hash && useThrivecart) return showModal(
			<ErrorModal status="INVALID_HASH"  />
		)

		let data = {
			type: formType,
			name: values.app_name,
			parent: parentID,
			creator: {
				email: values.email,
				name: values.name,
				password: values.password,
			},
			skip_thrivecart: !!!useThrivecart
		}

		if (useThrivecart) {
			data['thrivecart_hash'] = queryParameters.thrivecart_hash
			data['thrivecart'] = queryParameters?.thrivecart
		}

		setIsSaving(true) 
		FreeTrial(data)
			.then(() => {
				if(type === 'free_account' && actioneraApps.includes(parentID)) {
					window.location.href = 'https://actionera.com/upgrade'
				} else {
					setIsCreated(true)
				}
			})
			.catch(async (err) => {
				const res = await err.json()
				const error = res?.error || {};

				if(error?.status === 409) return showModal(
					<ErrorModal status={error?.status} customMessage={error?.detail}  />
				)
				
				showModal(
					<ErrorModal  />
				)
			})
			.finally(() => {
				setIsSaving(false)
			})
	}

	const handleLogin = useCallback(() => {
		setIsFreeTrialCreated(1)

		// redirect to /
		window.location.href = '/';
	}, [])

	const formik = useFormik({
		onSubmit,
		initialValues: {
			app_name: '',
			name: '',
			email: '',
			password: ''
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape(schema)
	});

	return (
		<div className="w-screen min-h-screen py-12 ttnk-free-trial-bg">
			<div className="px-12 md:px-20 container mx-auto">
				<h1 className="font-bold text-xl md:text-6xl leading-tight max-w-auto text-white mx-auto md:mx-0 text-center">The ULTIMATE Content, Membership, Event, <br/> and Course Platform</h1>
				<div className="relative flex flex-col-reverse xl:flex-row items-center justify-center md:justify-between mt-11">
					<div className="xl:max-w-xl mt-8 shrink-0 z-10 xl:w-screen">
						{isCreated ? (
							<div style={{height: 468}} className="bg-white p-5 mt-5 px-12 md:px-20 text-center w-full rounded-xl flex flex-col items-center justify-center gap-4">
								<div id="logo"><img src={ publicApp?.setup?.icon || defaultIcon } className="object-contain w-full max-w-48 h-48" alt={ publicApp?.setup?.header?.logo?.logo_text || 'ACTIONERA'}/></div>
								<div>
									<div className="text-2xl">Your app has been created.</div>
									<div className="text-2xl">Click <a onClick={handleLogin} className="underline decoration-solid text-blue-500 text-2xl">here</a> to login.</div>
								</div>
							</div>
						): (
              <Fragment>
                <form className="rounded-xl bg-white pt-px" onSubmit={formik.handleSubmit}>
									{hasInitialized && !isCreated && (
										<div id="logo" className="mt-5 mx-11 flex h-48 justify-center">
											<img
												src={ publicApp?.setup?.header?.logo?.logo_url || defaultLogo }
												className={`object-contain max-w-32`}
												alt={ publicApp?.setup?.header?.logo?.logo_text || 'ACTIONERA'}
											/>
										</div>
									)}
                  <Column width="full">
                    <div className="px-6 py-10">
                      <header className="font-bold text-3xl mb-4">Account Set-up Form</header>
                      <p className="text-2xl mb-8">Sign up and get in on the Action!</p>
                      <Input disabled={isSaving} name="app_name" type="text" label="App Name" formik={formik} />
                      <Input disabled={isSaving} name="name" type="text" label="Your Name" formik={formik} />
                      <Input disabled={isSaving} name="email" type="email" label="E-mail Address" formik={formik} />
                      <Input disabled={isSaving} name="password" type="password" label="Password" formik={formik} />
                      <button
                        disabled={isSaving || !formik.dirty}
                        className="drop-shadow-lg text-lg rounded-full py-3 disabled:cursor-not-allowed text-white bg-gradient-to-b disabled:from-gray-100 disabled:to-gray-100 hover:from-aeblue-dark hover:to-aeblue-mid from-aeblue-mid to-aeblue-dark disabled:text-gray-400 w-full mt-4 mb-8"
                        type="submit"
                      >
                        Submit
                      </button>
                      <div className="mb-8">
                        By continuing, you agree to our <a target="_blank" href="https://www.actionera.com/policy" className="underline decoration-solid text-blue-500" rel="noreferrer">Privacy Policy</a> and <a target="_blank" href="https://www.actionera.com/terms" className="underline decoration-solid text-primary-500 text-blue-500" rel="noreferrer">Terms {'&'} Conditions</a>
                      </div>
                    </div>
                  </Column>
                </form>
              </Fragment>
						)}
					</div>
					<div className="grow max-w-5xl relative right-0"><img src={ Image } className="object-contain  mr-8" alt="Trial"/></div>
				</div>
			</div>
		</div>
	)
}

export default AccountSetupForm

export const useFreeTrialLoginRedirect = () => {
	const [isFreeTrialCreated, setIsFreeTrialCreated] = useLocalState('isFreeTrialCreated')
	
	useEffect(() => {
		if(isFreeTrialCreated === 1) {
			// clean up
			setIsFreeTrialCreated(0)

			// auto redirect
			document.getElementById('login-btn')?.click()
		}
	}, [])
}