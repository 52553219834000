import { useEffect, useState } from "react";
import { EntryForm, Column, Input, Select } from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp, CreateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useHistory } from "react-router-dom";
import { GetAllAvailableApps } from "../services/api/app";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import * as Yup from "yup";

const AppSetup = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    template_app_uuid: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [fullList, setFullList] = useState([]);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    GetAllAvailableApps(user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setFullList(res.data);
      });
    setIsLoading(false);
  }, []);

  const appList = fullList.map((item) => ({
    id: item.id,
    name: item.name,
    initials: item.name
      .split(" ")
      .map((n) => n[0])
      .join(""),
  }));

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "New App", url: "/app/about_members" },
  ];

  let schema = {
    name: Yup.string().max(255, "Maximum 255 Characters").required("Required"),
    template_app_uuid: Yup.string()
      .max(255, "Maximum 255 Characters")
      .nullable(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      const payload = { data: values };

      setIsLoading(true);
      CreateApp(user, payload).then((response) =>
        responseProcessorProxy(response)
      );
    },
  });

  const responseProcessorProxy = (response) => {
    setIsLoading(false);
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push('/app/building')
    })
  }

  return (
    <Page links={links} title="ACCOUNT SETUP">
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
      >
        <Column>
          <div className="w-1/2">
            <div>
              <Input name="name" type="text" label="App Name" formik={formik} />
              <Select
                name="template_app_uuid"
                label="Template App"
                formik={formik}
              >
                <option value={null} label="Select App..." />
                {appList.map((app) => (
                  <option key={app.id} value={app.id} label={app.name} />
                ))}
              </Select>
            </div>
          </div>
        </Column>
        <Column></Column>
      </EntryForm>
    </Page>
  );
};

export default AppSetup;
