import Description from "../../components/Description"
import { VideoPlayer } from "../../components/VideoPlayer"
import { Image } from "../../components/Image"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import ImageSlideout from '../../assets/images/topic-slideout.jpg'
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import { ENTERPRISE } from "./config"

const DEFAULT_VALUES = {
  isEdit: false,
  topic: '',
  image_url: "",
  sort_order: 1,
  listing: false,
  directories: [],
  custom_video_text: "",
  video: {},
  custom_article_text: "",
  custom_lesson_text: "",
  custom_resource_text: "",
  custom_action_list_text: "",
  lesson: {},
  article: {},
  sub_topics: [],
  url_subtopics: [],
  tags: [],
  media_metadata: {},
  active: false,
  action_list: {},
  resource: {}
}

const OnboardingTip = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: "topic",
      label: "Topic Name",
      isFieldRow: true,
      required: true
    },
  ]

  const schema = {
    topic: validations.str_req_40,
  };

  const formatDefaultValues = () => {
    if (initialValues?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        id: initialValues?.id,
        topic: initialValues?.name,
        active: initialValues?.active
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  const handleFormatPayload = (values) => {
    const { isEdit, ...rest } = values

    const newPayload = {
      ...rest,
      name: values?.topic,
      active: initialValues?.id ? values?.active : true,
    }

    return newPayload
  }

  return {
    config: {
      label: "Onboarding - Topic",
      resourceEndpoint: "topics",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=vfupfPmNK0o" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Choose at least 3 Topics (ie. "Meditation", "Success", etc.) that all of your App content will fall under (you can add more later). Your content (videos, articles, questions, journals) will be found under these topics (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Topic"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Topic"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={handleFormatPayload}
          />
        ) },
      ]
    }
  }
}

export default OnboardingTip;