import React, { useEffect, useState } from "react";
import launched from "../assets/images/launched.png";
import infinity from "../assets/images/infinity.png";
import subscriber from "../assets/images/subscribe.png";
import progress from "../assets/images/progress.png";
import test from "../assets/images/test.png";
import web from "../assets/images/web.png";
import pending from "../assets/images/hour-glass.png";
import disabled from "../assets/images/disabled.png";
import enterprise from "../assets/images/enterprise.png";
import { GetAccountStatus } from "../services/api/account_status";
import { useUserProvider } from "../providers/UserProvider";
import AccountStatusSelectionItem from "./AccountStatusSelectionItem";

const statuses = [
  {
    label: "TEST",
    id: "test",
    image: (
      <img
        src={test}
        className="mx-auto"
        alt="test"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "INFINITY",
    id: "infinity",
    note: "(Biz+ Only)",
    image: (
      <img
        src={infinity}
        className="mx-auto"
        alt="infinity"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "SUBSCRIBER",
    id: "subscriber",
    note: "(Biz+ Only)",
    image: (
      <img
        src={subscriber}
        className="mx-auto"
        alt="subscriber"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "DISABLED",
    id: "disabled",
    image: (
      <img
        src={disabled}
        className="mx-auto"
        alt="disabled"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "IN PROGRESS",
    id: "in_progress",
    image: (
      <img
        src={progress}
        className="mx-auto"
        alt="in-progress"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "LAUNCHED",
    id: "launched",
    image: (
      <img
        src={launched}
        className="mx-auto"
        alt="launched"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "ENTERPRISE",
    id: "enterprise",
    image: (
      <img
        src={enterprise}
        className="mx-auto"
        alt="enterprise"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "Web",
    id: "web",
    note: "(Web Only)",
    image: (
      <img
        src={web}
        className="mx-auto"
        alt="web"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
  {
    label: "PENDING DESIGNATION",
    id: "pending_designation",
    image: (
      <img
        src={pending}
        className="mx-auto"
        alt="pending_designation"
        style={{ maxWidth: "100px" }}
      />
    ),
  },
];
export const AccountStatusSelection = () => {
  const { user } = useUserProvider();
  const [summary, setSummary] = useState({});

  useEffect(() => {
    GetAccountStatus(user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setSummary(res.data);
      });
  }, []);

  return (
    <div
      className="w-full"
      style={{
        padding: 100,
      }}
    >
      <div className="grid grid-cols-3 gap-[100px] justify-between gap-y-10">
        {statuses.map((status) => (
          <AccountStatusSelectionItem item={status} summary={summary} note={status.note} key={status.id} />
        ))}
      </div>
    </div>
  );
};
