import { useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { mergeArray } from "../lib/arrayTools"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { DeleteCourseModule } from "../services/api/course"
import { GetAppResource } from "../services/api/app"

import DeleteItem from "../components/modal_contents/DeleteItem"

const CourseModuleList = ({courseId, moduleValues, resetList}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    setData({
      loading: false,
      data: moduleValues,
      count: moduleValues.length
    })
  }

  useEffect(() => {
    loadData()
  }, [moduleValues])

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),
    },
    {
      name: 'sort_order',
      label: 'Sort Order',
      parser: (data) => (data.sort_order),
      sorter: (firstData, secondData)=>{
        return firstData.sort_order - secondData.sort_order
      },
      defaultSortOrder: "ascend",
      sortDirections:["ascend", "descend"],
      width: '12rem',
    },
    {
      name: 'name',
      label: 'Name',
      parser: (data) => (data.name),
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => data?.tags?.map(a => a.name)?.join(', '),
    },
    {
      name: 'course_lessons_count',
      label: '# of Course Lessons',
      parser: (data) => (`${data?.count?.lessons} ( ${data?.count?.active_lessons || 0} active)`),
    },
    {
      name: 'actions',
      label: 'Actions',
      width: '12rem',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} />),
    },
  ]

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={() => history.push(`/courses/${courseId}/modules/new`) }>Add Module</button>
  }

  const updateButtonHandler = (id) => {
    history.push(`/courses/${courseId}/modules/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteCourseModule(courseId, id, user.idToken)
    resetList()
    closeModal()
  }

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel='Course' onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  return (
    <div className="bg-white px-12 p-5 mt-5 ttnk-table-container">
      <div className="grid grid-flow-col grid-cols-2 gap-4 mt-5 mb-1">
        <h1 className="ttnk-page__title m-0">Course Modules</h1>
        <div className="flex justify-end">
          <AddButton />
        </div>
      </div>
      <h3 className="m-0 italic mb-12">To add or update a lesson in a module, click Update.</h3>
      <DataTable  metadata={metadata}
                  dataLoader={loadData}
                  contentType='courseModuleList'
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  searchKeys={['name', 'image_url']}
                  removeContainerStyle={true} />
    </div>
  )
}

export default CourseModuleList;
