import { IsWithinMinimumAndMaximumVersion } from '../lib/useVersionizer';
import { useUserProvider } from '../providers/UserProvider';
import { Redirect } from 'react-router-dom';

const RequireAccess = ({
  children,
  requiredAccess,
  minVersion = null,
  maxVersion = null,
  hasMetOtherChecker = undefined,
}) => {
  const { user } = useUserProvider();

  const checkAccess = (type) => {
    switch (type) {
      case 'super':
        return !user.isSuper;
      case 'premium_plus':
        return (
          !user.isSuper &&
          user.app_data.setup.premium_lite &&
          !user.app_data.setup.premium_plus
        );
      case 'push_notif_keys':
        return (
          !user.app_data.about.push_notification_app_id ||
          !user.app_data.about.push_notification_app_key
        );
      case 'versionizer':
        return (
          !IsWithinMinimumAndMaximumVersion(minVersion, maxVersion) ||
          (typeof hasMetOtherChecker !== 'undefined'
            ? hasMetOtherChecker === false
            : false)
        );
      default:
        return false;
    }
  };

  let rejected = false;
  requiredAccess.forEach((type) => {
    if (!rejected) {
      rejected = checkAccess(type);
    }
  });
  return rejected ? <Redirect to="/" /> : children;
};

export default RequireAccess;
