import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { mergeArray } from "../lib/arrayTools"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { DeleteWelcomeScreen } from "../services/api/welcome_screen"
import { GetAppResource } from "../services/api/app"

import DeleteItem from "../components/modal_contents/DeleteItem"
import { CloneItem } from "../services/api/clone";

const WelcomeScreenList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'welcome_screens')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length
        })
      })
  }

  const links = [
    { name: "Home", url: "/" },
    { name: "Home Screens", url: "/welcome_screens" },
  ]

  //Unlock Code (formerly Tagging ID) , Notification Email (formerly Your Email) , Tagging Name ,Tag ,Tag Created for
  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),

    },
    {
      name: 'active',
      label: 'Active',
      parser: (data) => data.active ? 'Yes' : 'No',
    },
    {
      name: 'title',
      label: 'Title',
      parser: (data) => (data.title || data.web.headline),
      ellipsis: true,
    },
    {
      name: 'target_platform',
      label: "Target Platform",
      parser: (data) => (<span className="capitalize">{data.target_platform}</span>),
      ellipsis: true,
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => (<span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>),
      ellipsis: true,
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} onCloneClick={cloneButtonHandler} />),
    },
  ]

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={() => history.push('/welcome_screens/new') }>Add Home Screen</button>
  }

  const updateButtonHandler = (id) => {
    history.push(`/welcome_screens/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteWelcomeScreen(id, user.idToken)
    loadData()
    closeModal()
  }

  const cloneButtonHandler  = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: 'welcome_screens'
      }
    })
    const cloneData = await res.json()
    const cloneId = cloneData.data?.id

    loadData();
    history.push(`/welcome_screens/${cloneId}`)
  };

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel="Home Screen" onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  return (
    <Page links={links} title="Home Screens" titleComponent={AddButton()}>
      <DataTable  metadata={metadata}
                  dataLoader={loadData}
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  searchKeys={['title', 'whats_new_headline', 'whats_new_description']}/>
    </Page>
  )
}

export default WelcomeScreenList;
