import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EntryForm, Column, Input, CheckBox } from "../components/EntryForm";
import Page from "../components/Page";
import {
  GetCreator,
  UpdateCreator,
  DeleteCreator,
} from "../services/api/creator";

import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import contentConfig from "../lib/content_config/config";

import DeleteItem from "../components/modal_contents/DeleteItem";
import responseProcessor from "../lib/responseProcessor";

const CreatorDetail = ({ newCreator, match }) => {
  const contentType = "creators";

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    is_super: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newCreator ? user.currentApp() : match.params.uuid;
  const history = useHistory();
  const subTables = contentConfig(contentType).subTables;

  useEffect(() => {
    if (newCreator) {
      setInitialValues({
        name: "",
        email: "",
        password: "",
        is_super: false,
      });
    } else {
      setIsLoading(true);
      GetCreator(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            name: res.data.name,
            email: res.data.email,
            password: res.data.password,
            is_super: res.data.is_super,
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newCreator ? "New" : "Edit";
  const currentUrl = newCreator ? "/creators/new" : `/creators/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Creators", url: "/creators" },
    { name: `${currentAction} Creator`, url: currentUrl },
  ];

  let schema = {
    name: Yup.string().required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .max(50, "Maximum 50 Characters")
      .required("Required"),
    // password: Yup.string(),
  };

  // if (newCreator) {
  //   schema["password"] = Yup.string().required("Required");
  // }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if(isSubmitting) return // prevents saving multiple creator
      setIsSubmitting(true)

      let newValues = values;

      let response;
      
      if (newCreator) {
        response = await AddAppResource(uuid, "creators", user.idToken, {
          data: newValues,
        })
      } else {
        response = await UpdateCreator(uuid, user.idToken, { data: newValues })
      }

      responseProcessorProxy(response)
      setIsSubmitting(false)
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/creators"),
    });
  };

  if (!user.app_data.setup.url_tags) {
    delete subTables?.url_tags;
  }

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="creator"
        onDelete={deleteCreatorHandler}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  const deleteCreatorHandler = async () => {
    await DeleteCreator(uuid, user.idToken);
    closeModal();
    history.push("/creators");
  };

  const additionalActions = () => {
    return (
      !newCreator &&
      user.isSuper && (
        <button
          className="ttnk-danger-button ml-5"
          onClick={(e) => {
            showDeleteModal();
            e.preventDefault();
          }}
        >
          Delete Creator
        </button>
      )
    );
  };

  return (
    <Page links={links} title={`${currentAction} Creator`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/creators"
        additionalActions={additionalActions()}
      >
        <Column>
          <Input name="email" type="email" label="Email" formik={formik} />
          {
            user.isSuper && <CheckBox
              name="is_super"
              label="Super Admin"
              formik={formik}
            />
          }
        </Column>
        <Column>
          <Input name="name" type="text" label="Name" formik={formik} />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default CreatorDetail;
