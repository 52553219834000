import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg";
import { ReactComponent as CloneIcon } from "../assets/icons/clone.svg";
import CachedIcon from "@material-ui/icons/Cached";
import { useState } from "react";

const TableActions = ({
  onUpdateClick,
  onDeleteClick,
  onCloneClick,
  item_id,
  item_index,
  deleteIcon,
  deleteClass,
  onResendClick,
  noActions,
  updateBtnLabel = "Update",
}) => {
  const [isResending, setIsResending] = useState(false);

  const DeleteIcon = deleteIcon || TrashIcon;

  const handleResend = (event) => {
    setIsResending(true);
    onResendClick((err, response) => {
      if (!err) return;

      setIsResending(false);

      // disables the button
      event.target.disabled = true;
    });
  };

  if (noActions)
    return <span className="flex items-center">No Actions Allowed</span>;

  return (
    <span className="flex items-center">
      {onUpdateClick && (
        <button
          type="button"
          className="flex items-center border py-1 px-4 rounded-full ttnk-border-color update-button ttnk-button-color ttnk-icon text-white"
          onClick={() => onUpdateClick(item_id, item_index)}
        >
          {updateBtnLabel === "Resend" ? (
            <CachedIcon style={{ marginRight: 4 }} />
          ) : (
            <PencilIcon className="h-4 w-4 mr-2 fill-current pencil" />
          )}
          {updateBtnLabel}
        </button>
      )}
      {onCloneClick && (
        <button type="button" onClick={() => onCloneClick(item_id, item_index)}>
          <CloneIcon className="h-5 w-5 fill-current text-gray-400 trash ml-2 hover:text-aeblue" />{" "}
        </button>
      )}
      {onDeleteClick && (
        <button
          type="button"
          className="ml-2"
          onClick={() => onDeleteClick(item_id, item_index)}
        >
          <DeleteIcon
            className={
              deleteClass ||
              "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"
            }
          />
        </button>
      )}
      {!!onResendClick && (
        <button
          disabled={isResending}
          onClick={handleResend}
          className="ttnk-button ml-2"
        >
          Resend
        </button>
      )}
    </span>
  );
};

export default TableActions;
