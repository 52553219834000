import React from "react";
import { timezoneNames } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";
import { UpdateMember } from "../services/api/member";
import { useUserProvider } from "../providers/UserProvider";
import { useAuth } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";

export const TimezoneSelect = () => {
  const { user, setUser } = useUserProvider();
  const { authService } = useAuth();

  const timezones = timezoneNames()
    .filter((l) => l.includes("/"))
    .sort((a, b) => a.localeCompare(b));

  return (
    <div className="w-fit px-2">
      <select
        value={
          user?.data?.timezone ??
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        onMouseLeave={(e) => {
          e.stopPropagation()
        }}
        className="border border-gray-300 text-lg p-2 rounded-lg w-full bg-transparent"
        onChange={async (e) => {
          await UpdateMember(user.data.sub, user, {
            data: {
              timezone: e.target.value,
            },
          });

          const res = await authService.fetchToken(
            authService.getAuthTokens().refresh_token,
            true
          );
          const data = jwtDecode(res.id_token);

          setUser((prev) => ({
            ...prev,
            data,
          }));
        }}
      >
        {timezones.map((zone, i) => (
          <option key={zone} value={zone} className="text-black" onMouseLeave={(e) => e.stopPropagation()}>
            {zone}
          </option>
        ))}
      </select>
    </div>
  );
};
