import * as React from 'react';
import ReactPlayer from 'react-player/lazy';
import useWindowSize from './hooks/useWindowSize';

export const VideoPlayer = ({
  url,
  playbackRate = 1,
  controls = true,
  wrapperClass
}) => {
  const { width } = useWindowSize();

  return (
    <div className={`w-full ${String(wrapperClass)}`}>
      <div
        style={{
          position: 'relative',
          padding: (width ?? 1056) <= 1056 ? '28.982%' : '27.226%',
        }}
      >
        <ReactPlayer
          className="absolute top-0 left-0 bg-black rounded-2xl overflow-hidden"
          width="100%"
          height="100%"
          url={url}
          controls={controls}
          playbackRate={playbackRate}
          config={{
            file: {
              forceVideo: true,
              forceAudio: true,
            },
          }}
        />
      </div>
    </div>
  );
};
