const galleryConfig = {
  label: "Gallery",
  resourceEndpoint: "galleries",
  listMetadata: [
    "sort_order",
    "active",
    "title",
    "featured",
    "topics",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    {
      name: "title",
      label: "Gallery Name",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "description",
      label: "Description",
      validations: [
        { type: "required", params: ["Required"] },
        { type: "max", params: [250, "Maximum 250 Characters"] },
      ],
    },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    {
      name: "sort_order",
      label: "Sort Order",
    },
    { name: "featured", label: "Featured?" },
    { name: "active", label: "Active" },
    { name: "topics", label: "Topics/Listings" },
    { name: "tags", label: "Tags" },
    { name: "photos", label: "Photos" },
  ],
  formLayout: {
    columns: [
      ["title", "description", "action_bar_text", "action_bar_url"],
      ["featured", "topics", "sort_order", "tags", "active"],
    ],
  },
  subTables: {
    photos: {
      label: {
        plural: "photos",
        singular: "photo",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "description",
          label: "Description",
          parser: (data) => data.description,
          ellipsis: true,
        },
        {
          name: "image_url",
          label: "URL",
          parser: (data) => {
            if (
              /^media:[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
                data.image_url
              )
            ) {
              const uuid = data.image_url.match(
                /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
              )[0];
              return data.media_metadata
                ? data.media_metadata[uuid]?.filename
                : "";
            } else {
              return data.image_url;
            }
          },
        },
      ],
      detailsMetadata: [
        {
          name: "sort_order",
          label: "Sort Order",
        },
        {
          name: "description",
          label: "Description",
        },
        {
          name: "image_url",
          label: "URL",
          validations: [{ type: "required", params: ["Required"] }],
        },
        { name: "media_metadata", label: "media_metadata" },
      ],
    },
  },
};

export default galleryConfig;
