import React from "react";
import { Button } from "../pages/Records";
import { useModalProvider } from "../providers/ModalProvider";

const OnboardingAssistanceModal = () => {
  const { closeModal } = useModalProvider();
  return (
    <div
      style={{
        width: 400,
      }}
    >
      <div
        className="absolute top-5 right-5 cursor-pointer text-2xl"
        onClick={closeModal}
      >
        x
      </div>
      <div className="font-bold text-center mb-2">Contact Us</div>
      <div className="mb-4">
        Email us at{" "}
        <span
          style={{ color: "#2750a0" }}
          className="underline font-bold cursor-pointer"
          onClick={() => {
            window.location.href =
              "mailto:support@actionera.com?subject=Onboarding%20Question%20/%20Concern";
          }}
        >
          support@actionera.com
        </span>{" "}
        for any questions or concerns. Please include your:
      </div>
      <ul className="list-disc ml-6 mb-4">
        <li>Name</li>
        <li>Email</li>
        <li>App Name</li>
        <li>Phone Number</li>
        <li>Screen captures, if applicable</li>
      </ul>
      <div className="flex flex-row items-center justify-center">
        <Button onClick={closeModal} hideIcon>
          EXIT →
        </Button>
      </div>
    </div>
  );
};

export default OnboardingAssistanceModal;
