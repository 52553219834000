import { useEffect, useState } from "react";
import { EntryForm, Column, Input, TextArea, RichText } from '../components/EntryForm'
import Page from '../components/Page'

import { AddAppResource, GetAppResource } from '../services/api/app'
import { UpdateTrackInfo } from '../services/api/track_info'
import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"
import { useLoaderProvider } from '../providers/LoaderProvider'

import MediaUploader from '../components/MediaUploader'
import { validations } from "../lib/form"

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from 'formik'

import * as Yup from 'yup'

const TrackNowScreen = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    title: "",
    video_url: "",
    image_url: "",
    description: "",
    web_description: "",
    action_bar_text: "",
    action_bar_url: "",
    platform_version: "",
    push_notification_app_id: "",
    push_notification_app_key: "",
    media_metadata: {}
  })

  const [metadata, setMetadata] = useState({})
  const [tracknowUUID, setTracknowUUID] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [saved, setSaved] = useState(false)

  const { user } = useUserProvider()

  const { showModal } = useModalProvider()

  const { doSetShowLoadingModal } = useLoaderProvider()

  useEffect(() => {
    setIsLoading(true)
    GetAppResource(user, 'track_info').then(res => res.json()).then(res_list => {
      const res = res_list.data[0]

      if (res) {
        setInitialValues({
          title: res.title,
          video_url: res.video_url,
          image_url: res.image_url,
          description: res.description,
          web_description: res.web_description,
          action_bar_text: res.action_bar_text,
          action_bar_url: res.action_bar_url,
          instructions_text: res.instructions_text,
          instructions_video_url: res.instructions_video_url,
        })

        setTracknowUUID(res.id)
        setMetadata(res.media_metadata)
      }

      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    doSetShowLoadingModal(isLoading)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Unlock/Track Screen", url: "/track_now_screen" },
  ]

  let schema = {
    title: validations.str_req_60,
    description: validations.string,
    web_description: validations.string,
    video_url: validations.str_notreq_255,
    image_url: validations.str_notreq_255,
    action_bar_text: validations.str_notreq_50,
    action_bar_url: validations.str_notreq_255,
    instructions_text: validations.str_blob,
    instructions_video_url: validations.str_notreq_255,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (tracknowUUID){
        UpdateTrackInfo(tracknowUUID, user.idToken, { data: values })
          .then(response => responseProcessor({
            response,
            showModal,
            onSuccess,
          }))
      } else {
        AddAppResource(user.currentApp(), 'track_info', user.idToken, { data: values })
          .then(response => responseProcessor({
            response,
            showModal,
            onSuccess,
          }))
      }
    },
  })

  const onSuccess = () => {
    setSaved(true)
    setIsLoading(true)
    GetAppResource(user, 'track_info').then(res => res.json()).then(res_list => {
      const res = res_list.data[0]

      setInitialValues({
        title: res.title,
        video_url: res.video_url,
        image_url: res.image_url,
        description: res.description,
        web_description: res.web_description,
        action_bar_text: res.action_bar_text,
        action_bar_url: res.action_bar_url,
        instructions_text: res.instructions_text,
        instructions_video_url: res.instructions_video_url,
      })

      setTracknowUUID(res.id)
      setMetadata(res.media_metadata)
      setIsLoading(false)
    })
  }

  const SavedLabel = () => (<span className="text-green-400">Saved</span>)

  return (
    <Page links={links} title="Unlock/Track Screen">
      <EntryForm isLoading={isLoading} submitHandler={ formik.handleSubmit } formik={formik} savedLabel={(saved && !formik.dirty) && SavedLabel()}>
        <Column>
          <Input name='title' type='text' label='Title' formik={formik} />
          <RichText
            id="welcomeScreenDescription"
            name='description'
            quillName='web_description'
            type='text'
            label='Description'
            rows='21'
            formik={formik}
            contentType="welcomeScreenDescription"
          />
          <MediaUploader name='video_url' type='text' label='Video URL' formik={formik} metadata={ metadata } mediaType="video" />
          <MediaUploader name='image_url' type='text' label='Image URL' formik={formik} metadata={ metadata } mediaType="image" />
        </Column>
        <Column>
          <Input name='action_bar_text' type='text' label='Call to Action Text' formik={formik} />
          <Input name='action_bar_url' type='text' label='Call to Action URL' formik={formik} />
          <TextArea name='instructions_text' type='text' label='TRACKACONNECT Instructions Text' formik={formik} />
          <MediaUploader name='instructions_video_url' type='text' label='TRACKACONNECT Video URL' formik={formik} metadata={ metadata } mediaType="video" />
        </Column>
      </EntryForm>
    </Page>
  )
}

export default TrackNowScreen;
