import Description from "../../components/Description"
import { Image } from "../../components/Image"
import { VideoPlayer } from "../../components/VideoPlayer"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import moment from "moment"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import * as Yup from "yup"

const DEFAULT_VALUES = {
  isEdit: false,
  title: "",
  author: "",
  video_description: "",
  article_image_url: "",
  release_date: moment().utcOffset(0, true).format("YYYY-MM-DD"),
  topics: []
}

const OnboardingProfile = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: "title",
      label: "Article Title",
      isFieldRow: true,
      required: true,
    },
    {
      component: "author",
      label: "Article Author",
      isFieldRow: true,
      required: true,
    },
    {
      component: "video_description",
      label: "Article Content",
      isFieldRow: true,
      required: true,
      rows: 21,
      placeholder: "NOTE: Stick to shorter articles (ie. 1-3 pages)"
    },
    {
      component: "article_image_url",
      label: "Article Image (square  images recommended)",
      isFieldRow: true,
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
      required: true,
    },
  ]

  const schema = {
    title: validations.str_req_100,
    author: validations.str_req_35,
    video_description: validations.long_text_required,
    topics: Yup.array().min(1, "Required"),
  };

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        video_description: initialValues?.description,
        article_image_url: initialValues?.image_url,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Articles",
      resourceEndpoint: "articles",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=OdKlb47l9fQ" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please add 5 (you can add more later) articles by you or others, with permission and if attributed properly. Try Pixabay.com or Unsplash.com for free commercial images (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src="https://ae-api.techtank.ca/media/dd692c40-56db-4608-ba53-2f88a169d466"
            alt="Video Screenshot"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Article"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              const { isEdit, ...rest } = values

              const newPayload = {
                ...rest,
                description: values?.video_description,
                image_url: values?.article_image_url,
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingProfile;