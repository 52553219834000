import { useEffect, useState } from "react";
import { EntryForm, Column, Input, CheckBox } from "../components/EntryForm";
import Page from "../components/Page";

import { GetDirectory, UpdateDirectory } from "../services/api/directory";
import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import DataSelector from "../components/DataSelector";
import MediaUploader from "../components/MediaUploader";
import { validations } from "../lib/form";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";

const DirectoryDetail = ({ newDirectory, match }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    sub_line: "",
    image_url: "",
    sort_order: 1,
    tags: [],
    media_metadata: {},
  });

  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newDirectory ? user.currentApp() : match.params.uuid;

  const history = useHistory();

  useEffect(() => {
    if (newDirectory) {
      setInitialValues({
        name: "",
        sub_line: "",
        image_url: "",
        enterprise: false,
        sort_order: 1,
        tags: [],
      });
    } else {
      setIsLoading(true);
      GetDirectory(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            name: res.data.name,
            sub_line: res.data.sub_line,
            image_url: res.data.image_url,
            sort_order: res.data.sort_order,
            tags: res.data.tags,
            enterprise: res.data.enterprise
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newDirectory ? "New" : "Edit";
  const currentUrl = newDirectory ? "/directories/new" : `/directories/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Directory List", url: "/directories" },
    { name: `${currentAction} Directory`, url: currentUrl },
  ];

  let schema = {
    name: validations.str_req_25,
    sub_line: validations.str_notreq_30,
    image_url: Yup.string().max(255, "Maximum 255 Characters"),
    sort_order: validations.sort_order,
    enterprise: Yup.boolean().nullable(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (newDirectory) {
        AddAppResource(uuid, "directories", user.idToken, {
          data: values,
        }).then((response) => responseProcessorProxy(response));
      } else {
        UpdateDirectory(uuid, user.idToken, { data: values }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/directories"),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Directory`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/directories"
      >
        <Column>
          <Input
            name="name"
            type="text"
            label="Directory Name"
            formik={formik}
          />
          <Input name="sub_line" type="text" label="Sub-Line" formik={formik} />
          {(user?.app_data?.setup?.enterprise || user?.app_data?.reseller_of?.length > 0) && (
            <div className="flex">
              <span className="w-full mr-8">
                <CheckBox name="enterprise" label="Business Directory" formik={formik} />
              </span>
            </div>
          )}
        </Column>
        <Column>
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <div className="flex">
            <span className="flex w-1/3">
              <Input
                name="sort_order"
                type="number"
                label="Sort Order"
                formik={formik}
              />
            </span>
          </div>
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default DirectoryDetail;
