import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetContent = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/content/${uuid}`, 'GET', idToken)
}

export const UpdateContent = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/content/${uuid}`, 'PATCH', idToken, payload)
}

export const UpdateOnboardingContent = async (uuid, idToken, payload) => {
  const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/content/${uuid}?timezone=${timezone}&addsubtopics=true`, 'PATCH', idToken, payload)
}

export const DeleteContent = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/content/${uuid}`, 'DELETE', idToken)
}
