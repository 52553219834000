import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetTrack = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/tracks/${uuid}`, 'GET', idToken)
}

export const UpdateTrack = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/tracks/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteTrack = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/tracks/${uuid}`, 'DELETE', idToken)
}
