import { useState, useEffect } from "react";
import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import Search from "../assets/icons/search.svg";
import Fuse from "fuse.js";
import { GetAllAvailableApps, GetApp } from "../services/api/app";
import { Link } from "react-router-dom";
import { setAppData } from "../App";
import useLocalStorage, { useSetLocalState } from "../lib/useLocalStorage";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import OnboardingWizardSwitcher from "../components/OnboardingWizardSwitcher";

const AppSelector = () => {
  const [searchValue, setSearchValue] = useState();
  const [_isLoading, setIsLoading] = useState(false);
  const [fullList, setFullList] = useState([]);
  const { user, setUser, shouldFetchPNData, setShouldFetchPNData } =
    useUserProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { setOnboardingData } = useOnboardingProvider();
  // eslint-disable-next-line no-unused-vars
  const [currentApp, setCurrentApp] = useLocalStorage("currentApp", null);
  const [isAppChanged, setIsAppChanged] = useState(false);

  const setLiteUnlockCode = useSetLocalState("lite");

  useEffect(() => {
    setIsLoading(true);
    GetAllAvailableApps(user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setFullList(res.data);
      });
    setIsLoading(false);
  }, []);

  const searchList = fullList.map((item) => ({
    id: item.id,
    name: item.name,
    lite: item.premium_lite,
    plus: item.premium_plus,
    isPending: item.is_pending,
    initials: item.name
      .split(" ")
      .map((n) => n[0])
      .join(""),
  }));

  let appList = searchList;

  if (searchValue) {
    const searcher = new Fuse(searchList, {
      keys: ["name", "initials"],
      threshold: 0.3,
    });

    appList = searcher.search(searchValue).map((d) => d.item);
  }

  const appSelectHandler = async (selectedApp) => {
    setCurrentApp(selectedApp);
    if (selectedApp.id !== user.currentApp()) {
      doSetShowLoadingModal(true);
      const app = selectedApp;
      const currentApp = () => app.id;

      const _user = { ...user, app, currentApp };

      try {
        if (app.lite) {
          const res = await GetApp(_user, _user.idToken);
          const jsonRes = await res.json();
          const app_setup = jsonRes.data.setup;
          if (app_setup?.premium_lite && app_setup?.lite_unlock_code) {
            setLiteUnlockCode(app_setup.lite_unlock_code);
          }
        } else setLiteUnlockCode("");
      } catch {
        setLiteUnlockCode("");
      }

      setUser({ ...user, app, currentApp });

      setAppData({
        idToken: user.idToken,
        data: user.data,
        isSuper: user.isSuper,
        currentApp,
        app,
        setUser,
        postCallback: () => {
          doSetShowLoadingModal(false);
        },
        setIsAppChanged,
        isAppChanged,
        setOnboardingData
      });
    }
  };

  useEffect(() => {
    if (isAppChanged) {
      setShouldFetchPNData(true);
    }
  }, [isAppChanged]);

  const AppItem = ({ app }) => {
    let appItemClasses = "flex flex-col w-full border-b";

    const borderColor =
      user.currentApp() === app.id
        ? " border-132850 filter bg-132850 text-white"
        : " ttnk-heading-text-color border-gray-300 cursor-pointer";
    appItemClasses = appItemClasses + borderColor;

    return (
      <li
        key={app.id}
        onClick={() => {
          appSelectHandler(app);
        }}
        className={appItemClasses}
      >
        <div className="name text-2xl font-bold font-bold flex-grow my-4 px-8 flex">
          {app.name}
          {app.lite && (
            <div className="ml-2">
              <span className="bg-red-400 font-normal py-1 px-2 text-white uppercase text-sm rounded-full">
                lite
              </span>
              {app.plus && (
                <span className="ml-2 bg-yellow-400 font-normal py-1 px-2 text-white uppercase text-sm rounded-full">
                  premium
                </span>
              )}
            </div>
          )}
          {app.isPending && (
            <div className="ml-2">
              <span className="bg-gray-200 font-normal py-1 px-2 text-gray-600 uppercase text-sm rounded-full">
                STILL BUILDING..
              </span>
            </div>
          )}
        </div>
      </li>
    );
  };

  return (
    <div className="ttnk-main-bg bg-no-repeat bg-fixed bg-contain bg-repeat bg-bottom bg-center bg-aeblue-light ttnk-container h-full pb-8">
      <div
        id="inner-content"
        className="flex flex-col h-full justify-center items-center container mx-auto"
      >
        <div id="header" className="pt-24 w-full">
          <OnboardingWizardSwitcher />
          <h1 className="text-5xl ttnk-heading-text-color font-header font-bold text-center">
            Which app will you be using today?
          </h1>
          <div className="border-2 border-gray-300 flex rounded-full flex justify-center items-center bg-white mt-16 w-full">
            <input
              id="search"
              name="search"
              type="text"
              className="text-2xl rounded-full focus:outline-none w-full pl-8 my-2"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder="Search..."
            />
            <span className="text-red-200">
              <img src={Search} className="w-6 h-6 mr-8" alt="Search Icon" />
            </span>
          </div>
        </div>
        {user?.isSuper && (
          <Link
            to="/app/new"
            className="border-2 border-gray-300 bg-gray-50 ttnk-heading-text-color mt-5 w-full text-center py-4 text-2xl rounded-fullAE"
          >
            Create New App
          </Link>
        )}
        <div
          id="app_list"
          className="flex-grow mt-8 overflow-y-auto border-2 rounded-lg border-132850 w-full bg-white"
        >
          <ul className="w-full justify-items-center">
            {appList.map((app) => (
              <AppItem key={app.id} app={app} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppSelector;
