const articleConfig = {
  label: 'Article',
  resourceEndpoint: 'articles',
  listMetadata: ['sort_order',
                 'title',
                 'featured',
                 'topics',
                 'release_date',
                 'tags',
                 'actions'],
  searchKeys: ['title'],
  keepListMetadataOrder: true,
  detailsMetadata: [
    { name: 'title', label: 'Title', validations: [{ type: "max", params: [100, "Maximum 100 Characters"] }],
    },
    {
      name: 'author',
      label: 'Author',
    },
    { name: 'description', label: 'Content' },
    { name: 'web_description', label: 'Content' },
    { name: 'audio_url', label: 'Audio URL'},
    { name: 'image_url', label: 'Image URL'},
    { name: 'release_date', label: 'Release Date'},
    { name: 'action_bar_text', label: 'Call to Action Text', validations: [{ type: "max", params: [50, "Maximum 50 Characters"] }]},
    { name: 'action_bar_url', label: 'Call to Action URL'},
    { name: 'sort_order', label: 'Sort Order'},
    { name: 'featured', label: 'Featured ?'},
    { name: 'topics', label: 'Topics/Listings'},
    { name: 'tags', label: 'Tags'}
  ],
  formLayout: {
    columns: [
      ["title", "image_url", "audio_url", "author", "description"],
      [
        "release_date",
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
      ],
    ],
  },
}

export default articleConfig
