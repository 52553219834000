import SecuredApp from './SecuredApp';

import {
  AuthProvider,
  AuthService,
} from 'react-oauth2-pkce'
import useLocalStorage from './lib/useLocalStorage';

const checkForCode = (location) => {
  const split = location.toString().split('?')
  if (split.length < 2) {
    return null
  }
  const pairs = split[1].split('&')
  for (const pair of pairs) {
    const [key, value] = pair.split('=')
    if (key === 'code') {
      return decodeURIComponent(value || '')
    }
  }
  return null
}

if(window.localStorage.getItem('auth') && checkForCode(window.location)) {
  window.location = '/'
}

const authService = new AuthService({
  clientId: process.env.REACT_APP_CLIENT_ID || 'igwAyUldGYTy_fM-gxgzm--4BxekBj8d2qIgDQUE8yc',
  location: window.location,
  provider: process.env.REACT_APP_PROVIDER || 'http://10.10.10.48:4000/oauth',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
  scopes: ['public', 'profile', 'openid', 'name', 'email'],
  autoRefresh: true,
});

function SecuredAppWrapper() {
  const [optinPathVal, setOptinPathVal] = useLocalStorage('optin-path', null)
  const [authUserVal, setAuthUserVal] = useLocalStorage('auth', null)

  const optinPath = optinPathVal ? `${window.location.origin}${optinPathVal}` : null
  const authUser = authUserVal || null
  
  if (authUser && !window.location.pathname.includes('/oi-') && optinPath) {
    window.location.href = optinPath
  }
  return (
    <AuthProvider authService={authService} >
      <SecuredApp />
    </AuthProvider>
  );
}

export default SecuredAppWrapper;
