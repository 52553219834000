import { useState, useEffect } from "react"
import * as Yup from "yup";
import { useUserProvider } from "../../providers/UserProvider"
import { GetApp } from "../../services/api/app"
import Description from "../../components/Description"
import { VideoPlayer } from "../../components/VideoPlayer"
import ImageSlideout from '../../assets/images/onboarding-support.jpg'
import { Image } from "../../components/Image"
import { validations } from "../form"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { SUBMITTED } from "./config";

const OnboardingSupport = ({ handleSubmit }) => {
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const username = user.app_data.username;
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [initialValues, setInitialValues] = useState({
    app_name: "",
    short_app_description: "",
    full_app_description: "",
    primary_category: "",
    secondary_category: "",
    search_keywords: "",
    in_app_name: "",
    app_icon_name: "",
    support_contact: "Use my current website",
    support_contact_page: "",
    companion_website: "",
    support_email: "",
    privacy_policy: "Use my current website",
    privacy_policy_page: "",
    privacy_policy_document: "",
    i_understand: false,
  });

  useEffect(() => {
    GetApp(user, user.idToken).then((res) => res.json()).then((res) => {
      const app_store = res?.data?.about?.store;
      setIsReadOnly(res?.data?.onboarding?.progress?.store?.status === SUBMITTED && !user?.isSuper);
      setInitialValues({
        short_app_description: app_store?.short_app_description,
        full_app_description: app_store?.full_app_description,
        primary_category: app_store?.primary_category,
        secondary_category: app_store?.secondary_category,
        search_keywords: app_store?.search_keywords,
        in_app_name: app_store?.in_app_name,
        app_icon_name: app_store?.app_icon_name,
        support_contact: app_store?.support_contact ?? 'Use my current website',
        support_contact_page: app_store?.support_contact_page,
        companion_website: `${process.env.REACT_APP_WEB_FQDN}/${username}/about`,
        support_email: app_store?.support_email,
        privacy_policy: app_store?.privacy_policy ?? 'Use my current website',
        privacy_policy_page: app_store?.privacy_policy_page,
        privacy_policy_document: app_store?.privacy_policy_document,
        media_metadata: res?.data?.about?.media_metadata ?? {},
        i_understand: app_store?.i_understand
      });
    })
  }, [onboardingData])

  const fields = [
    {
      component: "support_contact",
      label: "Support Contact",
      required: true,
      disabled: isReadOnly
    },
    {
      component: "support_contact_page",
      label: "Support Contact Page",
      required: true,
      placeholder: "Your website’s Contact page (add a link here)",
      disabled: isReadOnly
    },
    {
      component: "companion_website",
      label: "Companion website Profile link",
      isFieldRow: true
    },
    {
      component: "support_email",
      label: "Support Email",
      required: true,
      placeholder: "Email address your App users can contact for support",
      disabled: isReadOnly
    },
    {
      component: "privacy_policy",
      label: "Privacy Policy",
      required: true,
      isFieldRow: true,
      disabled: isReadOnly
    },
    {
      component: "privacy_policy_page",
      label: "Privacy Policy Page",
      isFieldRow: true,
      required: true,
      disabled: isReadOnly
    },
    {
      component: "privacy_policy_document",
      label: "Upload Edited Policy",
      isFieldRow: true,
      required: true,
      disabled: isReadOnly
    },
    {
      component: "privacy_policy_note",
    }
  ]

  const schema = {
    support_contact: validations.str_req_blob,
    support_contact_page: Yup.string().when('support_contact', {
      is: 'Use my current website',
      then: validations.website,
      otherwise: Yup.string()
    }),
    support_email: validations.email,
    privacy_policy: validations.str_req_blob,
    privacy_policy_page: Yup.string().when('privacy_policy', {
      is: 'Use my current website',
      then: validations.website,
      otherwise: Yup.string()
    }),
    privacy_policy_document: Yup.string().when('privacy_policy', {
      is: "Add this to my 'Companion Website'",
      then: Yup.string().required("Required"),
      otherwise: Yup.string()
    })
  };

  return {
    config: {
      label: "Onboarding App Store - Support",
      columnsMetadata: [
        { customComponent: (
          <div className="w-4/5 mx-auto mt-5">
            <VideoPlayer url="https://www.youtube.com/watch?v=I9eJXjZAoLk" />
          </div>
        ) },
        { customComponent: (
          <Description wrapperClass="py-4 mt-5" className="text-lg">
            This is the info that will display in your App Store listing (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Listing"
            className="w-6/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Support"
            defaultValues={initialValues}
            schema={schema}
            fields={fields}
            isReadOnly={isReadOnly}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              const newPayload = {
                about: {
                  store: {
                    short_app_description: values?.short_app_description,
                    full_app_description: values?.full_app_description,
                    primary_category: values?.primary_category,
                    secondary_category: values?.secondary_category,
                    search_keywords: values?.search_keywords,
                    in_app_name: values?.in_app_name,
                    app_icon_name: values?.app_icon_name,
                    support_contact: values?.support_contact,
                    support_contact_page: values?.support_contact_page,
                    support_email: values?.support_email,
                    privacy_policy: values?.privacy_policy,
                    privacy_policy_page: values?.privacy_policy_page,
                    privacy_policy_document: values?.privacy_policy_document
                  }
                },
                onboarding: {
                  ...onboardingData,
                  progress: {
                    ...onboardingData?.progress,
                    store: {
                      status: 'In Progress'
                    }
                  }
                }
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingSupport;