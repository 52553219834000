import React, { useState } from "react"
import { useModalProvider } from "../../../providers/ModalProvider";
import { Checkbox } from "antd";
import { UpdateApp } from "../../../services/api/app";
import responseProcessor from "../../../lib/responseProcessor";
import { VideoPlayer } from "../../VideoPlayer";
import { ENTERPRISE } from "../../../lib/onboarding_config/config";

const FirstLoginModal = ({
  user,
  onboardingData
}) => {
  const { closeModal, showModal } = useModalProvider();
  const [isChecked, setIsChecked] = useState(false);

  const handleOnClose = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;

    sessionStorage.setItem("first_login_prompted", "true")

    const payload = {
      onboarding: {
        ...onboardingData,
        confetti: isChecked,
      }
    }

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => closeModal(),
        })
    );
  };

  return (
    <div style={{ width: '50vw' }}>
      <div className="font-bold text-center text-lg">
      Congratulations! Play Welcome Video Below
      </div>
      <div className="mx-auto mb-4 text-center">
        <VideoPlayer
          url={onboardingData?.type === ENTERPRISE ? "https://youtu.be/OzlAcnVbLFc" : "https://www.youtube.com/watch?v=PYMgDuDwpeg"}
          wrapperClass="py-4"
          width={1056}
          isObjectFitCover
        />
      </div>

      <div className="flex align-center justify-center">
        <Checkbox
          name="disable-popup"
          checked={isChecked}
          onChange={(event) => {
            setIsChecked(event.target.checked)
          }}
        />
        <label className={`text-lg mb-3 ml-3`} htmlFor="disable-popup">
          I want to see this explainer video again the next time I login
        </label>
      </div>

      <div className='flex justify-center'>
      <button onClick={handleOnClose} className="ttnk-button">
        Close
      </button>
      </div>
    </div>
  );
}

export default FirstLoginModal