const versionizerConfig = {
  podcasts: {
    minVersion: 9,
    component: "not_available",
    callbackFn: () => {},
  },
  documents: {
    minVersion: 8.5,
    component: "platform_disclaimer",
    callbackFn: () => {},
  },
};

export default versionizerConfig;
