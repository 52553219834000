import React from 'react';
import { IsWithinMinimumAndMaximumVersion } from '../lib/useVersionizer';

const VersionizerWrapper = ({ minVersion, maxVersion, children }) => {
  if (IsWithinMinimumAndMaximumVersion(minVersion, maxVersion)) {
    return children;
  }

  return <></>;
};

export default VersionizerWrapper;
