import { useEffect, useState } from "react";
import { EntryForm, Column, Input } from "../components/EntryForm";
import Page from "../components/Page";
import {
  GetCourseCategory,
  UpdateCourseCategory,
} from "../services/api/courseCategory";
import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import DataSelector from "../components/DataSelector";
import MediaUploader from "../components/MediaUploader";
import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";

const CourseCategoryDetail = ({ newCategory, match }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    sub_line: "",
    image_url: "",
    sort_order: 1,
    tags: [],
    media_metadata: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newCategory ? user.currentApp() : match.params.uuid;
  const history = useHistory();

  useEffect(() => {
    if (newCategory) {
      setInitialValues({
        name: "",
        sub_line: "",
        image_url: "",
        sort_order: 1,
        tags: [],
      });
    } else {
      setIsLoading(true);
      GetCourseCategory(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            name: res.data.name,
            sub_line: res.data.sub_line,
            image_url: res.data.image_url,
            sort_order: res.data.sort_order,
            tags: res.data.tags,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newCategory ? "New" : "Edit";
  const currentUrl = newCategory
    ? "/course_categories/new"
    : `/course_categories/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Course Category List", url: "/course_categories" },
    { name: `${currentAction} Course Category`, url: currentUrl },
  ];

  let schema = {
    name: validations.str_req_25,
    sub_line: validations.str_notreq_30,
    image_url: validations.long_text,
    sort_order: validations.sort_order,
  };

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/course_categories"),
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (newCategory) {
        AddAppResource(uuid, "course_categories", user.idToken, {
          data: values,
        }).then((response) => responseProcessorProxy(response));
      } else {
        UpdateCourseCategory(uuid, user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      }
    },
  });

  return (
    <Page links={links} title={`${currentAction} Course Category`}>
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
        cancelURL="/course_categories"
      >
        <Column>
          <Input
            name="name"
            type="text"
            label="Category Name"
            formik={formik}
          />
          <Input name="sub_line" type="text" label="Sub-Line" formik={formik} />
        </Column>
        <Column>
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <div className="flex">
            <span className="flex w-1/3">
              <Input
                name="sort_order"
                type="number"
                label="Sort Order"
                formik={formik}
              />
            </span>
          </div>
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default CourseCategoryDetail;
