import React, { useEffect, useMemo } from "react";

const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      let value;

      try {
        value = item ? JSON.parse(item) : initialValue;
      } catch {
        value = initialValue;
      }

      return value;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

// Design reference:
// https://recoiljs.org/docs/api-reference/core/useRecoilState

const chr4 = () => Math.random().toString(16).slice(-4);
const uuid = () =>
  `${chr4()}${chr4()}-${chr4()}-${chr4()}-${chr4()}-${chr4()}${chr4()}${chr4()}`;

// Subscribes to a readable state

export const useLocalValue = (key, initialValue) => {
  const id = useMemo(uuid);

  const [storedValue, setStoredValue] = React.useState(() => {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue

    let value;

    try {
      value = item ? JSON.parse(item) : initialValue || undefined;
    } catch {
      value = initialValue || undefined;
    }

    return value;
  });

  useEffect(() => {
    if (!window._localStorage) window._localStorage = {};
    if (!window._localStorage[key]) window._localStorage[key] = {};
    window._localStorage[key][id] = (newValue) => setStoredValue(newValue);

    return () => {
      delete window?._localStorage?.[key]?.[id];
    };
  }, []);

  return storedValue;
};

// Returns a setter function for updating the value of readble state.

export const useSetLocalState = (key) => {
  return (newValue) => {
    if (window?._localStorage?.[key])
      Object.keys(window?._localStorage?.[key]).forEach((_id) => {
        window?._localStorage?.[key]?.[_id]?.(newValue);
        window.localStorage.setItem(key, JSON.stringify(newValue));
      });
    else {
      window.localStorage.setItem(key, JSON.stringify(newValue));
    }
  };
};

// Returns a tuple where the first element is the value of state and the
// second element is a setter function that will update the value of the given state when called.

export const useLocalState = (key, initialValue) => {
  const value = useLocalValue(key, initialValue);
  const setValue = useSetLocalState(key);

  return [value, setValue];
};

export default useLocalStorage;
