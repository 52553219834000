import React, { useState } from 'react'

const PushNotificationModalTab = () => {
  const [currentTab, setCurrentTab] = useState("push")

  return (
    <>
      <div className='p-8'>
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li>
            <button
              type="button"
              className={`h-full inline-block p-4 rounded-t-md ${currentTab === 'push' ? 'active text-white bg-lighterBlue dark:bg-lighterBlue dark:text-white' : 'border-r border-t border-l border-lighterBlue text-lighterBlue hover:text-gray-600 hover:bg-gray-50 dark:blue-800 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
              onClick={() => setCurrentTab('push')}>
              Push Notification
            </button>
          </li>
          {/* <li>
            <button
              type="button"
              className={`h-full inline-block p-4 rounded-t-md ${currentTab === 'in-app' ? 'active text-white bg-lighterBlue dark:bg-lighterBlue dark:text-white' : 'border-r border-t border-l border-lighterBlue text-lighterBlue hover:text-gray-600 hover:bg-gray-50 dark:blue-800 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
              onClick={() => setCurrentTab('in-app')}>
              In-App Notification
            </button>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default PushNotificationModalTab
