import React, { Fragment } from 'react';
import onboardingConfig from '../lib/onboarding_config/config';
import { AddAppResource, UpdateApp } from '../services/api/app';
import { useUserProvider } from '../providers/UserProvider';
import { UpdateOnboardingContent } from '../services/api/content';
import { useModalProvider } from '../providers/ModalProvider';
import responseProcessor from '../lib/responseProcessor';
import { getContentType } from '../lib/onboarding_config/config';

import { UpdateProfile } from '../services/api/profile';
import { UpdateWelcomeScreen } from '../services/api/welcome_screen';
import { UpdateTopic } from '../services/api/topic';
import { APP_BUILT, APP_DESIGNED, FINAL_INFO } from './OnboardingBuildAndSubmit/OnboardingBuildAndSubmit';

const SlideOut = ({
  type,
  showSubmitBtn,
  handleSubmit,
  data
}) => {
  const { user } = useUserProvider()
  const { showModal } = useModalProvider()
  const isNewContent = !data?.id

  const handleUpdate = ({ id, idToken, payload, updateFunction, successCallbackFn, errorCallBackFn }) => {
    updateFunction(id, idToken, { data: payload })
      .then((response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => {
            if (typeof successCallbackFn === 'function') successCallbackFn();
          },
          onError: errorCallBackFn
        })
      )
      .catch((error) => {
        // Handle errors if needed
        console.error('Error:', error);
      });
  }

  const Adder = (uuid, user, payload) => {
    return AddAppResource(
      uuid,
      getContentType(type),
      user.idToken,
      { data: payload }
    )
  }

  const handleSaveContent = ({
    payload,
    successCallbackFn,
    errorCallBackFn
  }) => {
    if ([APP_DESIGNED, APP_BUILT, FINAL_INFO].includes(type)) {
      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess: successCallbackFn,
            onError: errorCallBackFn
          })
      );
    } else if (isNewContent) {
      if (type === 'topics' && Array.isArray(payload)) {
        const promises = payload.map((item) => {
          return Adder(user.currentApp(), user, item);
        });
        
        return Promise.all(promises)
          .then((responses) => {
            responses.forEach((response) => {
              responseProcessor({
                response,
                showModal,
                onSuccess: () => {
                  if (typeof successCallbackFn === 'function') successCallbackFn();
                },
                onError: errorCallBackFn
              });
            });
          })
          .catch((error) => {
            // Handle errors if needed
            console.error('Error:', error);
          });
      }

      if (type === 'listing' || type === 'info' || type === 'support') {
        return handleUpdate({
          id: user?.app?.id,
          idToken: user.idToken,
          payload: payload,
          updateFunction: UpdateApp,
          successCallbackFn: successCallbackFn,
          errorCallBackFn
        })
      }

      Adder(user.currentApp(), user, payload).then((response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => {
            if (typeof successCallbackFn === 'function') successCallbackFn()
          },
          onError: errorCallBackFn
        })
      );
    } else {
      switch (type) {
        case 'profiles':
          return handleUpdate({
            id: data?.id,
            idToken: user.idToken,
            payload: payload,
            updateFunction: UpdateProfile,
            successCallbackFn: successCallbackFn,
            errorCallBackFn
          })
        case 'welcome_screens':
          return handleUpdate({
            id: data?.id,
            idToken: user.idToken,
            payload: payload,
            updateFunction: UpdateWelcomeScreen,
            successCallbackFn: successCallbackFn,
            errorCallBackFn
          })
        case 'topics':
          return handleUpdate({
            id: data?.id,
            idToken: user.idToken,
            payload: payload,
            updateFunction: UpdateTopic,
            successCallbackFn: successCallbackFn,
            errorCallBackFn
          })
        default:
          return handleUpdate({
            id: data?.id,
            idToken: user.idToken,
            payload: payload,
            updateFunction: UpdateOnboardingContent,
            successCallbackFn: successCallbackFn,
            errorCallBackFn
          })
      }
    }
  }

  return (
    <div className='flex flex-col justify-between items-center h-full w-full px-8'>
      <div className={`w-full flex-col ${showSubmitBtn ? "" : "pb-24"}`}>
        {
          onboardingConfig(type, { handleSubmit: handleSaveContent, initialValues: data })
            .columnsMetadata.map((col, colIndex) => {
              return (
                <Fragment key={String(`${colIndex}-item-component`)}>
                  {col.customComponent}
                </Fragment>
              )
          })
        }
      </div>

      {
        showSubmitBtn ? (
          <div className='w-full flex flex-row justify-end items-center pb-24 pt-2'>
            <button className='ttnk-button mt-2' type='submit' onClick={handleSubmit}> Submit </button>
          </div>
        ) : null
      }
    </div>
  )
}

export default SlideOut