import { useAuth } from 'react-oauth2-pkce'
import Logo from '../assets/images/ae-logo-dk.png'
import LoadingAELogo from '../assets/images/ae-icon-loading.png'
import { useEffect } from 'react';

const LoginPending = () => {
  const { authService } = useAuth();

  const login = async () => {
    sessionStorage.setItem("first_login_prompted", "false");
    setTimeout(() => authService.authorize(), 700)
  }; 
  const logout = async () => {
    sessionStorage.setItem("first_login_prompted", "false");
    authService.logout();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
      login();
    }, 15000)
  
    return () => {
      clearTimeout(timer);
    }
  }, [])

  return(
    <div className="h-screen w-full bg-red-200 flex flex-col">
      <div id="Header" className="bg-132850 w-full flex-grow-0 flex py-10 items-center justify-between">
        <div id="app-info" className="mx-12 flex items-center">
          <div id="logo"><img src={Logo} className="object-contain w-64 h-16 mr-8" alt="ACTIONERA logo"/></div>
        </div>
      </div>
      <div id="content" className="ttnk-main-bg from-aeblue-light to-gray-200 flex flex-row flex-grow">
        <div className="flex flex-col w-full justify-center items-center">
          <div className="relative w-52 h-52 mb-12">
            <div className="w-full h-full flex items-center justify-center">
               <img src={LoadingAELogo} className="object-contain w-20" alt="ACTIONERA logo"/>
             </div>
            <div className="absolute loader ease-linear rounded-full border-t-8 border-gray-200 h-52 w-52 top-0" />
          </div>
          <h1 className="ttnk-heading-text-color text-5xl font-black">Launching ACTIONERA</h1>
          <p className="text-lg font-semibold ttnk-heading-text-color m-1 text-center">
            You will be redirected to the Log in page automatically after 15 seconds. <br />
            If page does not redirect after 15 seconds, click {" "}
            <a className="text-aeblue-mid text-lg font-semibold" onClick={() => { logout(); login(); }}>here</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LoginPending
