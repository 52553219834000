import { Radio } from "antd";
import React from "react";

const When = ({
  formik,
  hasNoLinkTo
}) => {

  return (
    <div className={`flex flex-row items-center mb-5`}>
      <span className="font-bold w-20">When:</span>

      <Radio.Group
        onChange={(e) => {
          formik.setFieldValue("send_at", e.target.value);
        }}
        value={formik.values.send_at}
        className="flex flex-row"
        disabled={hasNoLinkTo}
      >
        <Radio value="now">Now</Radio>
        {/* <Radio value="timezone">
          Day/Time [ZONE]
        </Radio> */}
      </Radio.Group>
    </div>
  )
}

export default When