import { message } from 'antd'
import ErrorModal from '../components/modal_contents/ErrorModal'

const responseProcessor = async ({response, onSuccess, onError, showModal, toastMessage}) => {
  const jsonData = response?.status === 204 ? {} : await response.json();

  if (response.ok) {
    onSuccess(jsonData)
    if (toastMessage) {
      message.success(toastMessage);
    }
  } else {
    if (typeof onError === 'function') onError()
    showModal(<ErrorModal status={response.status} data={jsonData}/>)
  }
}

export default responseProcessor