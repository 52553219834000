import { EntryForm } from "./EntryForm";
import { getFieldComponent } from "../lib/form";
import SimpleTable from "./SimpleTable";
import { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDrawerProvider } from "../providers/DrawerProvider";

const UploadSummary = ({
  title,
  fields,
  schema,
  defaultValues,
  handleSubmit
}) => {
  const [savedData, setSavedData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  });
  const [initialValues, setInitialValues] = useState(defaultValues);
  const { closeDrawer } = useDrawerProvider();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (!values?.isEdit) {
        let newData = [...savedData?.data, {
          ...values,
          key: savedData?.count + 1
        }]

        setSavedData({
          ...savedData,
          loading: false,
          data: newData,
          count: newData?.length
        });
      } else {
        let newData = savedData?.data?.map((data) => {
          if (data?.key === values?.key) {
            return {
              ...values,
              isEdit: false
            }
          }

          return data
        })

        setSavedData({
          ...savedData,
          data: newData
        })
      }

      if (typeof handleSubmit === 'function') {
        handleSubmit(values, () => {
          formik.resetForm();
          setInitialValues(defaultValues);
          closeDrawer();
        });
      }
    },
  });

  const handleEdit = (editValues) => {
    setInitialValues(editValues)
  }

  const handleDelete = (key) => {
    setSavedData({
      ...savedData,
      data: savedData?.data?.filter((data) => data?.key !== key)
    })
  }
  
  return (
    <div>
      <div className="w-full py-4">
        <p className="uppercase font-bold text-lg border-b-[#888888] border-b-2 w-full mb-2 pb-2">
          {title}
        </p>
        <div className="py-4 w-full">
          <EntryForm
            submitHandler={formik.handleSubmit}
            formik={formik}
            isFullRow
            floatSubmitBtnRight
            submitLabel="Save"
          >
            {
              fields?.map((field, index) => {
                return (
                  <Fragment key={String(index)}>
                    {
                      getFieldComponent(field?.component, {
                        label: field?.label,
                        formik,
                        placeholder: field?.placeholder,
                        isFieldRow: field?.isFieldRow
                      })
                    }
                  </Fragment>
                )
              })
            }
          </EntryForm>
        </div>

        { savedData?.data?.length ? (
           <SimpleTable
            savedData={savedData}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ) : null }
      </div>
    </div>
  );
};

export default UploadSummary;
