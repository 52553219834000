import Hero from "../assets/images/ae-logo-light-nameonly.png";
import { ReactComponent as Close } from "../assets/icons/cross-sign.svg";
import InnerHTML from "dangerously-set-html-content";
import { useEffect, useState } from "react";

import { useModalProvider } from "../providers/ModalProvider";
import { GetWhatsNewList } from "../services/api/whats_new";

import { useUserProvider } from "../providers/UserProvider";
import { GetAppById } from "../services/api/app";
import OnboardingWizardSwitcher from "../components/OnboardingWizardSwitcher";
import Services from '../assets/images/marketplace.png';

const unescapeHTML = (escapedHTML) =>
  escapedHTML
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&");

const Home = () => {
  const labelClass = "font-header font-bold mt-5";
  const { showModal, closeModal } = useModalProvider();

  const [whatsNew, setWhatsNew] = useState({});
  const [whatsNewModalData, setWhatsNewModalData] = useState({});
  const [initialId, setInitialId] = useState(null);
  const { user } = useUserProvider();

  const [logo, setLogo] = useState(null);
  const [description, setDescription] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const maxBodyLength = 200;

  const WhatsNewModal = ({ data, initId, closeHandler }) => {
    const [modalId, setModalId] = useState(initId);

    const cleanEscapeHtml = unescapeHTML(data[modalId].body);

    return (
      <div className="rounded">
        <div
          style={{ margin: "-20px -20px 0px -20px" }}
          className="bg-132850 px-36 py-16 relative"
        >
          <img src={Hero} className="mx-auto" alt="What's New Modal Hero" />
          <span className="text-white absolute top-0 right-0 mr-5 mt-5">
            <button
              onClick={() => {
                closeHandler();
              }}
            >
              <Close
                className="w-5 h-5 text-white fill-current"
                onClick={() => {
                  closeHandler();
                }}
              />
            </button>
          </span>
        </div>
        <div className="">
          <h1 className="mt-12 font-header font-bold text-5xl text-gray-800">
            What's New!
          </h1>
          <select
            id="whats_new_select"
            name="whats_new_select"
            className="border border-gray-300 text-lg p-2 rounded-lg w-full"
            onChange={(e) => {
              setModalId(e.target.value);
            }}
            value={modalId}
          >
            {whatsNew.map((entry) => (
              <option
                key={entry.id}
                value={entry.id}
                label={`${new Date(entry.publish_date).toLocaleDateString(
                  "en-us",
                  { year: "numeric", month: "long", day: "numeric" }
                )} - ${entry.subject}`}
              />
            ))}
          </select>
          <div
            style={{ width: "56rem", height: "24rem" }}
            className="mt-8 overflow-auto whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: cleanEscapeHtml }}
          />
        </div>
      </div>
    );
  };

  const getLogo = (uploadedLogo) => {
    return uploadedLogo || user?.app_data?.portal?.logo_url;
  };

  const logoUrl = getLogo(logo);

  const WhatsNewBanner = ({ entry }) => {
    const cleanEscapeHtml = unescapeHTML(entry.body);
    const full_body = cleanEscapeHtml
      .replace(/<br[^>]*>/gi, "\n")
      .replace(/<p>(.*)<\/p>/g, "$1\n")
      .replace(/(<([^>]+)>)/gi, "");

    return (
      <div
        className={`flex mb-8 px-8 py-5 text-white rounded-xl ${
          logoUrl ? "bg-white" : "bg-132850"
        } items-center justify-center`}
      >
        <div className={`${logoUrl ? "w-1/6" : "w-1/3 py-8" }`}>
          <img src={logoUrl || Hero} className="mx-auto" alt="What's New" />
        </div>
        {/* <div className="flex flex-col justify-center">
          <h1 className="text-white text-5xl font-header font-bold">
            What's New!
          </h1>
          <h2 className="text-white text-2xl mb-8">
            {new Date(entry.publish_date).toLocaleDateString("en-us", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            - {entry.subject}
          </h2>
          <div className="text-xl whitespace-pre-line">
            {full_body.length > maxBodyLength
              ? full_body.slice(0, maxBodyLength) + "..."
              : full_body}
            <button
              className="text-blue-400 hover:text-blue-300 ml-2"
              onClick={() => {
                showModal(
                  <WhatsNewModal
                    data={whatsNewModalData}
                    initId={initialId}
                    closeHandler={closeModal}
                  />
                );
              }}
            >
              Read more
            </button>
          </div>
        </div> */}
      </div>
    );
  };

  useEffect(() => {
    GetWhatsNewList(user)
      .then((res) => res.json())
      .then((jsonData) => {
        const values = jsonData.data
          .filter(
            (entry) =>
              entry.is_published && new Date(entry.publish_date) < new Date()
          )
          .sort((a, b) =>
            a.publish_date < b.publish_date
              ? 1
              : b.publish_date < a.publish_date
              ? -1
              : 0
          );
        setWhatsNew(values);
        setInitialId(values[0].id);
        setWhatsNewModalData(
          values.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
          }, {})
        );
      });
  }, []);

  useEffect(() => {
    setLogo(user?.app_data?.portal?.image_url)
    setDescription(user?.app_data?.portal?.description)
    setIsLoading(false);
  }, [user]);

  const body0108 = `<p class="text-center"><span style="font-size: 30px; "><strong>Welcome to The ACTIONERA Content Management Portal</strong></span></p>
  <p><span style="font-size: 22px; ">This portal gives you the ability to control and run your ACTIONERA App and/or Website and manage everything connected to you account. Below you will find information, links and support options you can use to leverage the ACTIONERA platform to its fullest potential.</span></p>
  <p>&nbsp;</p>
  <table class="text-center" style="border-collapse: collapse; width: 100.749%; height: 155.188px; border-width: 0px;" border="1"><colgroup><col style="width: 33.3832%;"><col style="width: 33.3832%;"><col style="width: 33.3832%;"></colgroup>
  <tbody>
  <tr>
  <td style="border-width: 0px; padding: 4px;" align="center"><span style=""><strong><span style="font-size: 22px;">BILLING</span></strong></span></td>
  <td style="border-width: 0px; padding: 4px;" align="center"><span style=""><strong><span style="font-size: 22px;">VIDEOS / SUPPORT</span></strong></span></td>
  <td style="border-width: 0px; padding: 4px;" align="center"><span style=""><strong><span style="font-size: 22px;">SERVICES</span></strong></span></td>
  </tr>
  <tr style="height: 19.5938px;">
  <td style="border-width: 0px; padding: 4px; height: 19.5938px;" align="center"><a rel="noopener" href="https://actionera.chargebeeportal.com/portal/v2/login?forward=portal_main" target="_blank"><span style=""><img src="https://api.actionera.com/media/d1215a08-8d25-4578-8334-afb961c2c033" alt="" width="150" height="150"></span></a></td>
  <td style="border-width: 0px; padding: 4px; height: 19.5938px;" align="center"><a rel="noopener" href="http://actionera.freshdesk.com" target="_blank"><span style=""><img src="https://api.actionera.com/media/38493afb-662f-48b6-9ac8-2020a3e50bb1" alt="" width="150" height="150"></span></a></td>
  <td style="border-width: 0px; padding: 4px; height: 19.5938px;" align="center"><a rel="noopener" href="https://actionera.com/experts" target="_blank"><span style=""><img class="text-center" src=${Services} alt="" width="150" height="150"></span></a></td>
  </tr>
  </tbody>
  </table>
  <p class="text-center">&nbsp;</p>
  <p class="text-center"><span style="font-size: 24px;  ">Click Icons Above to Access</span></p>
  <p style="font-size: 24px; ">
  <strong>Everything you need to know about Actionera:</strong>
</p>
<p style="font-size: 24px;  ">You can find the entire documentation on how to get started and use different features of Actionera on our knowledge base here: <a href="https://actionera.freshdesk.com" target="_blank">https://actionera.freshdesk.com</a>
</p>
<p style="font-size: 24px;  ">
  <strong>Weekly Live Support Calls:</strong>
</p>
<ul>
  <li style="font-size: 24px;  ">
    <strong>Marketing Portal Q&amp;A:</strong>
  </li>
</ul>
<p style="font-size: 24px;  ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every Tuesday at 9am PST / Noon EST on <a target="_blank" href="http://Actionerazoom.com">http://Actionerazoom.com</a>
</p>
<ul>
  <li style="font-size: 24px;  ">
    <strong>Creator Portal Q&amp;A: </strong>
  </li>
</ul>
<p style="font-size: 24px;  ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every Thursday at 4pm PST / 7pm EST on <a target="_blank" href="http://Actionerazoom.com">http://Actionerazoom.com</a>
</p>
<p style="font-size: 24px;  ">
  <strong>Exclusive Education for Actionera Clients:</strong>
</p>
<p style="font-size: 24px;  ">Unlock exclusive training for Actionera clients on different aspects of marketing and tech, as well as get access to the recording of weekly support calls by following the instructions on this page: <a href="https://actionera.com/app-owners-access" target="_blank">https://actionera.com/app-owners-access</a>
</p>
<p><span style="font-size: 24px; "><strong>Download The ACTIONERA App For Push Notification Updates</strong></span></p>
  <p><span style="font-size: 22px; ">We recommend you download the <strong>ACTIONERA App</strong> (use QR code below) and add yourself to our <strong>ACTIONERA Client Push List</strong> so we can update you via notifications.&nbsp; </span></p>
  <p>&nbsp;</p>
  <p class="flex justify-center items-center"><span style="font-size: 24px;  font-family: 'Open Sans', sans-serif;"><a href="https://qr.io/r/4nTYAh" target="_blank"><img src="https://qr-codes-svg.s3.amazonaws.com/4nTYAh.svg?1673139807648" alt="" width="150" height="175"></a></span></p>
  <p class="text-center" style="font-size: 24px; ">You can also click the QR code above to Access</p>
<p style="font-size: 24px;  ">
  <strong>ACTIONERA Affiliate Program</strong>
</p>
<p style="font-size: 24px;  ">ACTIONERA offers an amazing affiliate program. To register for our affiliate program and be notified of any upcoming promotions you can get in on, click the link below. <a href="https://partner.actionera.com/affiliates/" target="_blank">Register here for ACTIONERA Affiliate Program</a>
</p>
<p style="font-size: 24px;  ">
  <strong>ACTIONERA Client Apps and Websites - </strong>
  <a href="http://appsofexperts.com" target="_blank">http://appsofexperts.com</a>
</p>
<p style="font-size: 24px;  ">
  <strong>Other Links</strong> - <a href="https://actionera.freshdesk.com/support/solutions/articles/69000827005-actionera-links" target="_blank">Click Here to View Entire List</a>
</p>

`;

  return (
    <div className="ttnk-main-bg h-full overflow-y-auto pb-5">
      <div className="pt-24 container mx-auto font-header">
        <OnboardingWizardSwitcher className="pb-4" />
        
        {whatsNew && Object.keys(whatsNew).length !== 0 && (
          <WhatsNewBanner entry={whatsNew[0]} />
        )}
        <div className="bg-white p-8 rounded-xl">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
            {
              description  ? 
              <InnerHTML
                html={description }
                className={description ? "inner-html text-black text-lg text-justify w-full mx-auto pt-8 mb-6 ttci-break-word whitespace-pre-wrap": ''}
              />: <div className="body0108" dangerouslySetInnerHTML={{__html: body0108}}/>
            }
            </>
          )}
          {/* <h1 className="font-header font-bold text-3xl text-aeblue-dark">
            Welcome To Your Actionera Content Portal
          </h1>
          <div className="mb-3"></div>
          <p>
            This Content Creator Portal manages your content for the Actionera
            product you are signed up for. This includes the "branded" Actionera
            Premium Plus App, the Actionera "LITE" App, and the Actionera Web
            App. You may have one or all three of these services, and if you do,
            this one account will manage them all.
          </p>
          <h2 className="font-header font-bold text-2xl my-5">
            What's New and Updates
          </h2>
          <p>
            Above is our "<span className={labelClass}>What's New</span>
            "&nbsp;update area, where we post updates on all three services in
            addition to sending them out as emails. The subject of the update
            message will have in front of it [Plus], [Lite], [Web], or [ALL] so
            you know if it applies to the option you are signed up for. 
          </p>
          <div className="mb-3"></div>
          <p>
            Click the "<i>Read More / Previous Messages</i>" link to read that
            update and access previously sent messages. Typically, these
            messages are also sent out as emails and inside the Actionera app
            under the Member Content area in the{" "}
            <span className="font-bold">Updates/Messages</span> section. This
            helps ensure you don't miss any important updates.
          </p>
          <h2 className="font-header font-bold text-2xl my-5">
            Understanding and Using Your Content Portal
          </h2>
          <p>
            We've created a “Support Folder” to provide documents and
            information you may need for your account. Reference these documents
            for more details on the product you are using or support you may
            need.
          </p>
          <p>
            Click{" "}
            <a
              className="text-blue-400"
              onClick={() =>
                window.open(
                  "https://drive.google.com/drive/folders/1Ybxxdrx6aehUj-xPECtD7knf-mk-NdZd?usp=sharing",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              Here
            </a>{" "}
            for this Support Folder.
          </p>
          <div className="mb-3"></div>
          <p>
            We've also created a playlist of training videos showing you how to
            use this portal and a button to access the playlist on each page you
            use to add content. You can access these videos by going to this
            address:{" "}
            <a
              className="text-blue-400"
              onClick={() =>
                window.open(
                  "http://actionerahowto.com/",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              http://ActioneraHowTo.com
            </a>
          </p>
          <div className="mb-3"></div>
          <p>
            New videos will be added to this playlist as we provide more
            detailed training. You can also access our marketing and support
            videos by going to these two addresses: Special Training and Support
            – 
            <a
              className="text-blue-400"
              onClick={() =>
                window.open(
                  "http://actioneravideos.com/",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              http://ActioneraVideos.com
            </a>{" "}
            and{" "}
            <a
              className="text-blue-400"
              onClick={() =>
                window.open(
                  "http://1kbootcamp.com/",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              http://1kbootcamp.com
            </a>
          </p>
          <h2 className="font-header font-bold text-2xl my-5">
            Need More Support?
          </h2>
          <p>
            You can email support@actionera.com or go to our support ticket
            website to submit a ticket for support - Click{" "}
            <a
              className="text-blue-400"
              onClick={() =>
                window.open(
                  "http://actionera.freshdesk.com",
                  "_blank",
                  "toolbar=0,location=0,menubar=0"
                )
              }
            >
              Here
            </a>
          </p>
          <div className="mb-3"></div>
          <p>
            We also offer weekly support calls for Plus, Lite and Premium.  The
            info for these calls were provided to you after you signed up.
          </p>
          <div className="mb-3"></div>
          <p>
            Welcome to the Ultimate Expert Platform to share your content, build
            your list, monetize your message and impact people's lives.  Let us
            know how we can support you.
          </p>
          <h2 className="font-header font-bold text-2xl my-5">
            IMPORTANT NOTE: Content Guidelines and Restrictions
          </h2>
          <p>
            Actionera is a technology provided to experts and businesses to
            share their expertise and message with people across different
            powerful platforms.  We have strict rules about the type of content,
            topics and subjects that can and cannot be shared through our
            technologies & platforms and a violation of any of these rules will
            result in a pause to your account to fix the issue or cancellation
            of your account altogether. 
          </p>
          <div className="mb-3"></div>
          <p>
            We will provide you with the terms and agreement soon and will post
            a link to them here. If you have any questions about what can or
            cannot be shared in our community platform, or via Apple and Android
            terms, please email our support email.   Thank you for keeping our
            community positive and compliant so everyone has a good experience
            with Actionera.
          </p> */}

          {/* <div className="mb-3"></div>
          <p>
            Thank you for keeping our community positive and compliant so
            everyone has a good experience with Actionera.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
