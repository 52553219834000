import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { setAppData } from '../App';
import DataTable from '../components/DataTable';
import Page from '../components/Page';
import useLocalStorage from '../lib/useLocalStorage';
import { useLoaderProvider } from '../providers/LoaderProvider';
import { useUserProvider } from '../providers/UserProvider';
import { GetEnterpriseApps } from '../services/api/onboarding';
import { useOnboardingProvider } from '../providers/OnboardingProvider';
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";

const OnboardingTracker = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const { user, setUser } = useUserProvider();
  const [currentApp, setCurrentApp] = useLocalStorage('currentApp', null);
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { setOnboardingData } = useOnboardingProvider()
  const deleteClass =
  "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetEnterpriseApps(user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData?.data,
          count: jsonData?.data?.length || 0,
        });
      });
  };

  const handleGoToWizard = async (appData) => {
    const selectedApp = {
      id: appData.id,
      initials: appData.name,
      name: appData.name,
      biz_plus: appData.biz_plus,
      isPending: appData.is_pending,
    };
    setCurrentApp(selectedApp);
    // if (selectedApp.id !== user.currentApp()) {
    doSetShowLoadingModal(true);
    const app = selectedApp;
    const currentApp = () => appData.id;

    setUser({ ...user, app, currentApp });

    setAppData({
      idToken: user.idToken,
      data: user.data,
      isSuper: user.isSuper,
      currentApp,
      app,
      setUser,
      postCallback: () => {
        doSetShowLoadingModal(false);
        history.push('/onboarding');
      },
      setOnboardingData
    });
    // }
  };

  const links = [
    { name: 'Home', url: '/' },
    { name: 'Onboarding Tracker', url: '/onboarding_tracker' },
  ];

  const metadata = [
    {
      name: 'name',
      label: 'App Name',
      parser: (data) => data?.name,
    },
    {
      name: 'creator_name',
      label: 'Creator Name',
      parser: (data) => data?.creator?.length > 1 ? data?.creator[0]?.name : data?.creator?.name,
    },
    {
      name: 'design',
      label: 'App Design',
      parser: (data) => {
        if(data?.onboarding?.progress?.design?.status === 'Submitted')
            return (
              <div className='flex justify-center'>
                <CheckIcon
                  className={deleteClass || "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"}
                />
              </div>      
            );
      }
    },
    {
      name: 'content',
      label: 'Upload Content',
      parser: (data) => {
        if(data?.onboarding?.progress?.content?.status === 'Submitted')
            return (
              <div className='flex justify-center'>
                <CheckIcon className={deleteClass || "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"} />
              </div>
            );
      }
    },
    {
      name: 'store',
      label: 'App Store Info',
      parser: (data) => {
        if(data?.onboarding?.progress?.store?.status === 'Submitted')
            return (
              <div className='flex justify-center'>
                <CheckIcon
                  className={deleteClass || "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"}
                />
              </div>    
            );
      }
    },
    {
      name: 'developer',
      label: 'Developer Accounts',
      parser: (data) => {
        if(data?.onboarding?.progress?.developer?.status === 'Submitted')
            return (
              <div className='flex justify-center'>
                <CheckIcon
                  className={deleteClass || "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"}
                />
              </div>    
            );
      }
    },
    {
      name: 'build_and_submit',
      label: 'Build and Submit',
      parser: (data) => {
        if(data?.onboarding?.progress?.build_and_submit?.status === 'Saved')
            return (
              <div className='flex justify-center'>
                <CheckIcon
                  className={deleteClass || "h-5 w-5 fill-current text-gray-400 trash hover:text-red-600"}
                />
              </div>    
            );
      }
    }, 
    {
      name: 'actions',
      label: '',
      parser: (data) =>
        data?.onboarding?.progress?.build_and_submit?.status !== 'Saved' ? (
          <button
              onClick={() => handleGoToWizard(data)}
              className="h-8 border border-black rounded-full items-center justify-center flex"
              style={{
                  width: 100,
              }}
          >
            Go to Wizard
          </button>
        ) : null
    },
  ];

  return (
    <Page links={links} title="ONBOARDING TRACKER">
      <div className="bg-white p-5 mt-5 ttnk-table-container">
        <div className="flex flex-row items-center">
          <div
            className="text-xxl uppercase"
            style={{
              fontSize: 22,
            }}
          >
            BIZ+
          </div>
        </div>
        <DataTable
          metadata={metadata}
          dataLoader={loadData}
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={['name']}
          threshold={0.1}
        />
      </div>
    </Page>
  );
};

export default OnboardingTracker;
