import React from 'react'
import { AccountStatusSelection } from '../components/AccountStatusSelection';
import Page from '../components/Page'



export const AccountStatus = () => {
    const links = [
        { name: "Home", url: "/" },
        { name: "Account Status", url: "/account_status" },
      ];

  return (
    <Page links={links} title="Account Status">
        <div className='bg-white p-5 mt-5 ttnk-table-container'>
            <div className='p-[50px]'>
                <div className='flex flex-row justify-center items-center'>
                    <AccountStatusSelection />
                </div>
            </div>
        </div>
  </Page>
  )
}
