const tipConfig = {
  label: "ActionBlog",
  resourceEndpoint: "tips",
  listMetadata: [
    "sort_order",
    "active",
    "title",
    "featured",
    "release_date",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "title", label: "Title" },
    { name: "description", label: "Description" },
    { name: "web_description", label: "Description" },
    { name: "video_url", label: "Video URL" },
    { name: "audio_url", label: "Audio URL" },
    { name: "image_url", label: "Image URL" },
    { name: "release_date", label: "Release Date" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "sort_order", label: "Sort Order" },
    { name: "active", label: "Active" },
    { name: "featured", label: "Featured?" },
    { name: "tags", label: "Tags" },
    { name: "topics", label: "Topics/Listing" },

  ],
  formLayout: {
    columns: [
      [
        "title",
        "description",
        "video_url",
        "image_url",
        "audio_url",
        "release_date",
      ],
      [
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
        "active",
      ],
    ],
  },
};

export default tipConfig;
