import { useEffect, useMemo, useState } from 'react';
import { setAppData } from '../App';
import DataTable from '../components/DataTable';
import Page from '../components/Page';
import useLocalStorage, { useSetLocalState } from '../lib/useLocalStorage';
import { useLoaderProvider } from '../providers/LoaderProvider';
import { useUserProvider } from '../providers/UserProvider';
import { useModalProvider } from '../providers/ModalProvider';
import { GetAccountListByType } from '../services/api/account_status';
import { GetApp, EnableApp } from '../services/api/app';
import { useOnboardingProvider } from '../providers/OnboardingProvider';
import TableActions from '../components/TableActions';
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import DeleteItem from "../components/modal_contents/DeleteItem";

const ActionStatusList = ({ match }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const { user, setUser } = useUserProvider();
  const [currentApp, setCurrentApp] = useLocalStorage('currentApp', null);
  const { showModal, closeModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { setOnboardingData } = useOnboardingProvider()
  const setLiteUnlockCode = useSetLocalState('lite');
  const deleteClass =
  "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";
  const title = useMemo(() => {
    switch (match.params.type) {
      case 'goplus':
        return 'Go+';
      case 'in_progress':
        return 'In Progress';
      case 'pending_designation':
          return 'Pending Designation';
      case 'for_review':
        return 'For Review';
      case 'free':
        return 'FREE';
      case 'biz_plus':
          return 'BIZ+';
      case 'disabled':
          return 'Disabled';
      default:
      return match.params.type;
    }
  }, [match.params.type]);

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAccountListByType(match.params.type, user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData?.data,
          count: jsonData?.data?.length || 0,
        });
      });
  };

  const handleGoToApp = async (app) => {
    const selectedApp = {
      id: app.id,
      initials: app.app_name,
      name: app.app_name,
      plus: app.goplus,
      lite: app.goplus,
      biz_plus: app.biz_plus,
      isPending: app.is_pending,
    };
    setCurrentApp(selectedApp);
    if (selectedApp.id !== user.currentApp()) {
      doSetShowLoadingModal(true);
      const app = selectedApp;
      const currentApp = () => app.id;

      const _user = { ...user, app, currentApp };

      try {
        if (app.lite) {
          const res = await GetApp(_user, _user.idToken);
          const jsonRes = await res.json();
          const app_setup = jsonRes.data.setup;
          if (app_setup?.premium_lite && app_setup?.lite_unlock_code) {
            setLiteUnlockCode(app_setup.lite_unlock_code);
          }
        } else setLiteUnlockCode('');
      } catch {
        setLiteUnlockCode('');
      }

      setUser({ ...user, app, currentApp });

      setAppData({
        idToken: user.idToken,
        data: user.data,
        isSuper: user.isSuper,
        currentApp,
        app,
        setUser,
        postCallback: () => {
          doSetShowLoadingModal(false);
        },
        setOnboardingData
      });
    }
  };

  const unlinkButtonHandler = async (id) => {
    await EnableApp(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="app"
        onDelete={unlinkButtonHandler}
        onClose={closeModal}
        deleteAction="enable"
        delBtnLabel="Enable"
      />
    );
  };

  const links = [
    { name: 'Home', url: '/' },
    { name: 'Account Status', url: '/account_status' },
  ];

  const metadata = [
    {
      name: 'app_name',
      label: 'App Name',
      parser: (data) => data.app_name,
    },
    {
      name: 'account_type',
      label: 'Account Type',
      parser: (data) => {
        return (
          data.account_type?.charAt(0)?.toUpperCase() +
          data.account_type?.slice(1)
        );
      },
    },
    {
      name: 'platform_version',
      label: 'Platform Version',
      parser: (data) => data.platform_version,
    },
    {
      name: 'creator_name',
      label: 'Creator Name/s',
      parser: (data) => data.creators.map((a) => a.name).join(', '),
    },
    {
      name: 'creator_email',
      label: 'Creator Email/s',
      parser: (data) => data.creators.map((a) => a.email).join(', '),
    },
    {
      name: 'web_username',
      label: 'Web Username',
      parser: (data) => data.web_username,
    },
    {
      name: 'app_status',
      label: `${title === 'Pending Designation' ? 'Former' : ''} App Status ${title === 'Pending Designation' ? '(eg. WEB)' : ''}`,
      parser: (data) => (
        <div>
          {data.goplus && title === 'Pending Designation' && <div>GO+</div>}
          {data.premium_app && title === 'Pending Designation' && <div>Premium</div>}
          {data.web && (title !== 'infinity' && title !== 'subscriber') && <div>WEB</div>}
          {data.biz_plus && (title !== 'Pending Designation' && title !== 'web') && <div>BIZ+</div>}
        </div>
      ),
    },
    {
      name: 'actions',
      label: '',
      parser: (data) => (
        <button
          onClick={() => handleGoToApp(data)}
          className="h-8 border border-black rounded-full items-center justify-center flex"
          style={{
            width: 100,
          }}
        >
          Go to App
        </button>
        )
    },
  ];

  return (
    <Page links={links} title="ACCOUNT STATUS">
      <div className="bg-white p-5 mt-5 ttnk-table-container">
        <div className="flex flex-row items-center">
          <div
            className="text-xxl uppercase"
            style={{
              fontSize: 22,
            }}
          >
            {title}
          </div>
          {title === 'For Review' && (
            <div className="mt-1.5 ml-1.5">Test: Off | Account Type: None</div>
          )}
          {title === 'FREE' && <div className="mt-1.5 ml-1.5">Test: Off</div>}
          {title === 'Pending Designation' && (
            <div className="mt-1.5 ml-1.5">
              Formerly Non-Premium apps, WEB only, Not Yet Opted in to AE app
            </div>
          )}
          {match.params.type === 'disabled' && (
            <div className="mt-1.5 ml-1.5">
              BIZ+ Only
            </div>
          )}
        </div>
        <DataTable
          metadata={metadata}
          dataLoader={loadData}
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={['app_name']}
          threshold={0.1}
        />
      </div>
    </Page>
  );
};

export default ActionStatusList;

