import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"

import { useUserProvider } from "../providers/UserProvider";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { useModalProvider } from "../providers/ModalProvider";
import DeleteItem from "../components/modal_contents/DeleteItem";
import PushNotificationModal from "../components/modal_contents/PushNotificationModal/PushNotificationModal";
import { GetPushNotificationList } from "../services/api/push_notification";
import moment from "moment";
import { GetMobileAppDownloads, GetMobilePushSubscribers } from "../services/api/mobile_configs";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";

const PushSubscribersList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);
  const [pnData, setCurrentPnData] = useState(null);
  const [isRefreshed, setIsRefreshed] = useState(false)

  const { showModal, closeModal } = useModalProvider();

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  });

  const { user } = useUserProvider();

  const isPNAllowed = useMemo(() => user?.app_data?.from_apps?.length > 0 || (user?.app_data?.fcm_key?.length > 0 && user?.app_data?.fcm_project_id?.length > 0), [user])

  const loadData = useCallback(async() => {
    setData({ ...data, loading: true });
    GetMobilePushSubscribers(user.currentApp(), user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        });
      });
  }, [data, user])

  const links = [
    { name: "Home", url: "/" },
    { name: "Push Notifications", url: "/push-notifications" },
    { name: "Push Subscribers", url: "/push-subscribers" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "user",
      label: "User Email",
      parser: (data) => data?.user?.email,
      ellipsis: true,
    },
    {
      name: "token",
      label: "Token",
      parser: (data) => data?.token,
      ellipsis: true,
    },
    {
      name: "device",
      label: "Device Description",
      parser: (data) => data?.device,
      ellipsis: true,
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => data?.active === true ? "Yes" : "No",
      ellipsis: true,
    },
    {
      name: "associated",
      label: "Associated?",
      parser: (data) => data?.associated === true ? "Yes" : "No",
      ellipsis: true,
    },
  ];

  return (
    <>
      <Page links={links} title="PUSH SUBSCRIBERS">
        <DataTable
          metadata={metadata}
          dataLoader={loadData}
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={["token", "device_description", "active", "associated", "user"]}
        />
      </Page>

      {
        isPNAllowed && IsWithinMinimumVersion(9.0) ? (
          <PushNotificationModal
            shouldShow={shouldShow}
            setShouldShow={setShouldShow}
            pnData={pnData}
            setCurrentPnData={setCurrentPnData}
          />
        ) : null
      }
    </>
  );
};

export default PushSubscribersList;
