const tagConfig = {
  label: 'Tag',
  resourceEndpoint: 'tags',
  listMetadata: ['sort_order',
                 'title',
                 'featured',
                 'topics',
                 'tags',
                 'actions'],
  searchKeys: ['title'],
  detailsMetadata: [
    {name: 'name', label: 'Name'},
    {name: 'code', label: 'Code'},
    {name: 'email', label: 'Notification Email'},
    {name: 'notes', label: 'Notes'},
    {name: 'category', label: 'Category'},
  ],
}

export default tagConfig