const productConfig = {
  label: 'Product',
  resourceEndpoint: 'products',
  listMetadata: ['sort_order',
                 'title',
                 'featured',
                 'topics',
                 'release_date',
                 'tags',
                 'actions'
                ],
  searchKeys: ['title'],
  keepListMetadataOrder: true,
  detailsMetadata: [
    { name: 'title', label: 'Product Name'},
    { name: 'description', label: 'Description' },
    { name: 'web_description', label: 'Description' },
    { name: 'video_url', label: 'Bottom Video URL'},
    { name: 'image_url', label: 'Bottom Image URL'},
    { name: 'release_date', label: 'Release Date', format: "DD-MMM-YYYY"},
    { name: 'review_video_url', label: 'Top Video URL'},
    { name: 'review_image_url', label: 'Top Image URL'},
    { name: 'listing_image_url', label: 'Thumbnail Image URL'},
    { name: 'price', label: 'Price'},
    { name: 'action_bar_text', label: 'Call to Action Text'},
    { name: 'action_bar_url', label: 'Call to Action URL'},
    { name: 'sort_order', label: 'Sort Order'},
    { name: 'featured', label: 'Featured?'},
    { name: 'topics', label: 'Topics/Listings'},
    { name: 'tags', label: 'Tags'}
  ],
  formLayout: {
    columns: [
      ["title", "description", "price", "listing_image_url", "review_image_url", "review_video_url", "image_url"],
      [
        "video_url",
        "release_date",
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
      ],
    ],
  }
}

export default productConfig
