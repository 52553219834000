import React, { useState } from "react";
import PropTypes from "prop-types";
import AELogo from "../assets/images/AE_Logo.png";
import { Input } from "../components/EntryForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useUserProvider } from "../providers/UserProvider";
import responseProcessor from "../lib/responseProcessor";
import { useModalProvider } from "../providers/ModalProvider";
import { useHistory } from "react-router-dom";
import { UpdateCreator } from "../services/api/creator";
import { validations } from "../lib/form";

const ChangePassword = (props) => {
  const history = useHistory();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { showModal } = useModalProvider();
  const { user } = useUserProvider();
  const [initialValues, setInitialValues] = useState({
    password: "",
    confirm: "",
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        const schema = {
          password: validations.password.required("Required")
        }
        
        schema["confirm"] = Yup.string()
          .matches(values.password, {
            message: "Password does not match.",
          })
          .required("Required");

        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      doSetShowLoadingModal(true);
      const { idToken } = user;
      const userId = user.data.sub

      try {
        const response = await UpdateCreator(userId, idToken, {
          data: {
            password: values.password,
          },
        });
        console.log("response", response);
        doSetShowLoadingModal(false);
        responseProcessor({
          response,
          showModal,
          onSuccess: () => history.push("/"),
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  const links = [
    { name: "Home", url: "/" },
    { name: "Change Password", url: "/change_password" },
  ];

  return (
    <div className="w-full h-full grid grid-cols-3">
      <div className="form-wrapper col-start-2 flex flex-col justify-center space-y-4">
        <img
          src={AELogo}
          className="mx-auto"
          alt=""
          style={{ maxWidth: "350px" }}
        />
        <form onSubmit={formik.handleSubmit}>
          <Input
            name="password"
            type="password"
            label="New Password"
            formik={formik}
          />
          <Input
            name="confirm"
            type="password"
            label="Confirm New Password"
            formik={formik}
          />
          <button
            disabled={!formik.dirty}
            className="ttnk-button mx-auto text-xl"
            type="submit"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

ChangePassword.propTypes = {};

export default ChangePassword;
