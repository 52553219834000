import { useEffect, useMemo, useState } from "react";
import { useUserProvider } from "./providers/UserProvider";
import { useAuth } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import RequireAccess from "./components/RequireAccess";
import AppLayout from "./components/AppLayout";
import Home from "./pages/Home";
import AppSelector from "./pages/AppSelector";
import About from "./pages/About";
import AboutApp from "./pages/AboutApp";
import PushNotification from "./pages/PushNotification";

import WelcomeScreenList from "./pages/WelcomeScreenList";
import WelcomeScreenDetail from "./pages/WelcomeScreenDetail";

import NewApp from "./pages/NewApp";

import WhatsNewList from "./pages/WhatsNewList";
import WhatsNewDetail from "./pages/WhatsNewDetail";
import BusinessList from "./pages/BusinessList";

import AboutAppMembers from "./pages/AboutAppMembers";

import AppSetup from "./pages/AppSetup";

import ProfilesList from "./pages/ProfilesList";
import ProfilesDetail from "./pages/ProfilesDetail";

import MemberList from "./pages/MemberList";
import MemberDetail from "./pages/MemberDetail";

import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";

import CreatorDetail from "./pages/CreatorDetail";

import TagList from "./pages/TagList";
import TagDetail from "./pages/TagDetail";

import DirectoryList from "./pages/DirectoryList";
import DirectoryDetail from "./pages/DirectoryDetail";

import TopicList from "./pages/TopicList";
import TopicDetail from "./pages/TopicDetail";
import AppInfo from "./pages/AppInfo";

import VirtualEventList from "./pages/VirtualEventList";
import VirtualEventDetail from "./pages/VirtualEventDetail";

import ContentList from "./pages/ContentList";
import ContentDetail from "./pages/ContentDetail";

import CourseCategoriesList from "./pages/CourseCategoriesList";
import CourseCategoryDetail from "./pages/CourseCategoryDetail";

import CourseList from "./pages/CourseList";
import CourseDetail from "./pages/CourseDetail";

import CourseModuleDetail from "./pages/CourseModuleDetail";

import OffersList from "./pages/OffersList";
import OffersDetail from "./pages/OffersDetail";

import CourseLessonList from "./pages/CourseLessonList";
import CourseLessonDetail from "./pages/CourseLessonDetail";

import EventCalendarList from "./pages/EventCalendarList";
import EventCalendarDetail from "./pages/EventCalendarDetail";

import TrackDetails from "./pages/TrackDetails";
import TrackList from "./pages/TrackList";

import TrackNowScreen from "./pages/TrackNowScreen";

import MessagesDetails from "./pages/MessagesDetails";
import MessagesList from "./pages/MessagesList";

import UnlinkMedia from "./pages/UnlinkMedia";
import RemovedApps from "./pages/RemovedApps";

import { GetApp, GetAllAvailableApps } from "./services/api/app";

import { useLoaderProvider } from "./providers/LoaderProvider";
import useLocalStorage from "./lib/useLocalStorage";
import ChangePassword from "./pages/ChangePassword";
import AppBuilding from "./pages/AppBuilding";
import SuperUserList from "./pages/SuperUserList";
import SuperUserDetails from "./pages/SuperUserDetails";
import Records from "./pages/Records";
import WebApp from "./pages/WebApp";
import { useModalProvider } from "./providers/ModalProvider";
import { InvalidAccountTypeError } from "./pages/Login";
import MenuSetup from "./pages/MenuSetup";
import DomainSetup from "./pages/DomainSetup";
import ActionFormsDetail from "./pages/ActionFormDetail";
import { AccountStatus } from "./pages/AccountStatus";
import AccountStatusList from "./pages/AccountStatusList";
import GlobalFieldsDetail from "./pages/GlobalFieldsDetail";
import GlobalFieldsList from "./pages/GlobalFieldsList";
import OnboardingTracker from "./pages/OnboardingTracker";
import ActionFormList from "./pages/ActionFormList";
import PushNotificationList from "./pages/PushNotificationList";
import AppDownloadsList from "./pages/AppDownloadsList";
import PushSubscribersList from "./pages/PushSubscribersList";
import Components from "./pages/Components";
import Onboarding from "./pages/Onboarding";
import OnboardingChangePassword from "./pages/OnboardingChangePassword";
import { useOnboardingProvider } from "./providers/OnboardingProvider";
import BusinessDetail from "./pages/BusinessDetail";
import PortalHomeScreen from "./pages/PortalHomeScreen";
import CreatorList from "./pages/CreatorList";
import FirstLoginModal from "./components/modal_contents/FirstLoginModal/FirstLoginModal";
import { LAUNCHED } from "./lib/onboarding_config/config";
import GoToOnboarding from "./pages/GoToOnboarding";

export const setAppData = ({
  idToken,
  data,
  isSuper,
  currentApp,
  app,
  setUser,
  setOnboardingData,
  postCallback,
  setIsAppChanged,
  isAppChanged,
}) => {
  GetApp({ idToken, currentApp })
    .then((res) => res.json())
    .then((res) => {
      let app_data = {};

      app_data = res.data;

      delete app_data.member_content_message;
      delete app_data.id;
      delete app_data.name;

      setUser({ idToken, data, isSuper, currentApp, app, app_data });
      if (typeof setOnboardingData === "function")
        setOnboardingData(res?.data?.onboarding);
      postCallback();
    })
    .finally(() => {
      if (typeof setIsAppChanged === "function" && isAppChanged === false)
        setIsAppChanged(true);
    });
};

function App() {
  const { authService } = useAuth();
  const { user, setUser } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { setOnboardingData, onboardingData } = useOnboardingProvider();
  const isOnboardingPage = window.location.pathname.includes("/onboarding");
  const onboardingStatus = user?.app_data?.onboarding?.status;


  // eslint-disable-next-line no-unused-vars
  const [currentSelectedApp, setCurrentSelectedApp] = useLocalStorage(
    "currentApp",
    null
  );

  const isPNAllowed = useMemo(
    () =>
      user?.app_data?.from_apps?.length > 0 ||
      (user?.app_data?.fcm_key?.length > 0 &&
        user?.app_data?.fcm_project_id?.length > 0),
    [user]
  );

  useEffect(() => {
    // Redirect Directives
    // Return early if user is super
    if (user?.isSuper) {
      return
    }

    // Normal forced redirect checking
    if (typeof onboardingData?.wizard !== "undefined") {
      // if app setup wizard toggle is on and user is not on onboarding page, redirect to onboarding
      if (onboardingData?.wizard && !isOnboardingPage) {
        return window.location.href = "/onboarding";
      }

      // if app setup wizard toggle is off and user is on onboarding page, redirect to home
      if ((!onboardingData?.wizard && isOnboardingPage)) {
        return window.location.href = "/";
      }
    }
  }, [isOnboardingPage, onboardingData?.redirect, onboardingStatus, onboardingData?.wizard, user?.isSuper]);

  useEffect(() => {
    doSetShowLoadingModal(true);
    const idToken = authService.getAuthTokens().id_token;
    const data = jwtDecode(idToken);

    GetAllAvailableApps(idToken)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length === 0) {
          doSetShowLoadingModal(false);
          return showModal(<InvalidAccountTypeError />);
        }

        const app =
          currentSelectedApp &&
          res.data.some((a) => a.id === currentSelectedApp.id)
            ? currentSelectedApp
            : res.data[0];
        const currentApp = () => app.id;
        const isSuper = data.is_super;

        if (idToken !== undefined) {
          setAppData({
            idToken,
            data,
            isSuper,
            currentApp,
            app,
            setUser,
            setOnboardingData,
            postCallback: () => {
              doSetShowLoadingModal(false);
            },
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFirstLoginModalPrompted = sessionStorage.getItem("first_login_prompted") === "true";
  const isLoggedIn = useMemo(() => authService.isAuthenticated() && !authService.isPending(), [authService]);
  const isLaunched = useMemo(() => onboardingStatus === LAUNCHED, [onboardingStatus])

  const shouldShowFirstLoginModal = useMemo(() => {
    return isLoggedIn && !user?.isSuper && isLaunched && !isOnboardingPage &&
      onboardingData?.confetti === true && !isFirstLoginModalPrompted
  }, [
    isLoggedIn,
    isLaunched,
    isOnboardingPage,
    user?.isSuper,
    isFirstLoginModalPrompted,
    onboardingData?.confetti
  ])

  useEffect(() => {
    let isMounted = true

    if (isMounted && shouldShowFirstLoginModal) {
      isMounted = false;

      setTimeout(() => {
        showModal(<FirstLoginModal onboardingData={onboardingData} user={user} />)
      }, 500)
    }

    return () => {
      isMounted = true
    }
  }, [shouldShowFirstLoginModal])

  const app = () => (
    <Router>
      <AppLayout>
        <Switch>
          <Route path="/_/components">
            <Components />
          </Route>
     
          <Route path="/gotoonboarding/:uuid" render={(props) => {
            return <GoToOnboarding {...props}/>
          }}
          />

                  <Route path="/onboarding/change_password">
                <OnboardingChangePassword />
              </Route>

              <Route path="/onboarding">
                <Onboarding />
              </Route>
              <Route
                path="/creators/new"
                render={(props) => {
                  return <CreatorDetail newCreator={true} />;
                }}
              />
              <Route
                path="/creators/:uuid"
                render={(props) => {
                  return <CreatorDetail {...props} />;
                }}
              />
              <Route path="/creators">
                <CreatorList />
              </Route>

              <Route path="/about">
                <About />
              </Route>
              <Route path="/change_application">
                <AppSelector />
              </Route>
              <Route path="/change_password">
                <ChangePassword />
              </Route>
              <Route path="/app/about">
                <RequireAccess requiredAccess={["super"]}>
                  <AboutApp />
                </RequireAccess>
              </Route>

              <Route path="/push_notifications/new">
                <RequireAccess
                  requiredAccess={[
                    "premium_plus",
                    "push_notif_keys",
                    "versionizer",
                  ]}
                  maxVersion={8.5}
                  hasMetOtherChecker={isPNAllowed}
                >
                  <PushNotification />
                </RequireAccess>
              </Route>

              <Route path="/app/new">
                <RequireAccess requiredAccess={["super"]}>
                  <NewApp />
                </RequireAccess>
              </Route>

              <Route path="/app/setup">
                <RequireAccess requiredAccess={["super"]}>
                  <AppSetup />
                </RequireAccess>
              </Route>
              <Route path="/app/building">
                <RequireAccess requiredAccess={["super"]}>
                  <AppBuilding />
                </RequireAccess>
              </Route>

              <Route path="/app/domains">
                <RequireAccess requiredAccess={["super"]}>
                  <DomainSetup />
                </RequireAccess>
              </Route>

              <Route
                path="/welcome_screens/new"
                render={(props) => {
                  return <WelcomeScreenDetail newWelcomeScreen={true} />;
                }}
              />
              <Route path="/portal_homescreen">
                <PortalHomeScreen />
              </Route>
              <Route
                path="/welcome_screens/:uuid"
                render={(props) => {
                  return <WelcomeScreenDetail {...props} />;
                }}
              />
              <Route path="/welcome_screens">
                <WelcomeScreenList />
              </Route>

              <Route path="/app/about_members">
                <RequireAccess requiredAccess={["premium_plus"]}>
                  <AboutAppMembers />
                </RequireAccess>
              </Route>

              <Route
                path="/profiles/new"
                render={(props) => {
                  return <ProfilesDetail newProfile={true} />;
                }}
              />
              <Route
                path="/profiles/:uuid"
                render={(props) => {
                  return <ProfilesDetail {...props} />;
                }}
              />
              <Route path="/profiles">
                <ProfilesList />
              </Route>

              <Route
                path="/podcasts/new"
                render={(props) => {
                  return (
                    <RequireAccess
                      requiredAccess={["versionizer"]}
                      minVersion={9}
                      hasMetOtherChecker={
                        user?.app_data?.setup?.podcasts === true
                      }
                    >
                      <ContentDetail
                        isContent={true}
                        contentType="podcasts"
                        newContent={true}
                      />
                    </RequireAccess>
                  );
                }}
              />
              <Route path="/podcasts/:uuid">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={9}
                  hasMetOtherChecker={user?.app_data?.setup?.podcasts === true}
                >
                  <ContentDetail isContent={true} contentType="podcasts" />
                </RequireAccess>
              </Route>

              <Route path="/podcasts">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={9}
                  hasMetOtherChecker={user?.app_data?.setup?.podcasts === true}
                >
                  <ContentList contentType="podcasts" />
                </RequireAccess>
              </Route>

              <Route
                path="/documents/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="documents"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/documents/:uuid">
                <ContentDetail isContent={true} contentType="documents" />
              </Route>

              <Route path="/documents">
                <ContentList contentType="documents" />
              </Route>

              <Route
                path="/members/new"
                render={(props) => {
                  return <MemberDetail newUser={true} />;
                }}
              />
              <Route
                path="/members/:uuid"
                render={(props) => {
                  return <MemberDetail {...props} />;
                }}
              />
              <Route path="/members">
                <MemberList />
              </Route>
              <Route
                path="/business/:uuid"
                render={(props) => {
                  return <BusinessDetail {...props} />;
                }}
              />
              <Route path="/business">
                <BusinessList />
              </Route>

              <Route
                path="/tags/new"
                render={(props) => {
                  return <TagDetail newTag={true} />;
                }}
              />
              <Route
                path="/tags/:uuid"
                render={(props) => {
                  return <TagDetail {...props} />;
                }}
              />
              <Route path="/tags">
                <TagList />
              </Route>

              <Route
                path="/directories/new"
                render={(props) => {
                  return <DirectoryDetail newDirectory={true} />;
                }}
              />
              <Route
                path="/directories/:uuid"
                render={(props) => {
                  return <DirectoryDetail {...props} />;
                }}
              />
              <Route path="/directories">
                <DirectoryList />
              </Route>

              <Route
                path="/videos/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="videos"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/videos/:uuid">
                <ContentDetail isContent={true} contentType="videos" />
              </Route>
              <Route path="/videos">
                <ContentList contentType="videos" />
              </Route>

              <Route
                path="/articles/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="articles"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/articles/:uuid">
                <ContentDetail isContent={true} contentType="articles" />
              </Route>
              <Route path="/articles">
                <ContentList contentType="articles" />
              </Route>

              <Route
                path="/audios/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="audios"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/audios/:uuid">
                <ContentDetail isContent={true} contentType="audios" />
              </Route>
              <Route path="/audios">
                <ContentList contentType="audios" />
              </Route>

              <Route
                path="/galleries/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="galleries"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/galleries/:uuid">
                <ContentDetail isContent={true} contentType="galleries" />
              </Route>
              <Route path="/galleries">
                <ContentList contentType="galleries" />
              </Route>

              <Route
                path="/journals/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="journals"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/journals/:uuid">
                <ContentDetail isContent={true} contentType="journals" />
              </Route>
              <Route path="/journals">
                <ContentList contentType="journals" />
              </Route>

              <Route
                path="/lessons/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="lessons"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/lessons/:uuid">
                <ContentDetail isContent={true} contentType="lessons" />
              </Route>
              <Route path="/lessons">
                <ContentList contentType="lessons" />
              </Route>

              <Route
                path="/offers/new"
                render={(props) => {
                  return <OffersDetail newOffer={true} />;
                }}
              />
              <Route
                path="/offers/:uuid"
                render={(props) => {
                  return <OffersDetail {...props} />;
                }}
              />
              <Route path="/offers">
                <OffersList />
              </Route>

              <Route
                path="/products/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="products"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/products/:uuid">
                <ContentDetail isContent={true} contentType="products" />
              </Route>
              <Route path="/products">
                <ContentList contentType="products" />
              </Route>

              <Route
                path="/resources/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="resources"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/resources/:uuid">
                <ContentDetail isContent={true} contentType="resources" />
              </Route>
              <Route path="/resources">
                <ContentList contentType="resources" />
              </Route>

              <Route
                path="/questions/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="questions"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/questions/:uuid">
                <ContentDetail isContent={true} contentType="questions" />
              </Route>
              <Route path="/questions">
                <ContentList contentType="questions" />
              </Route>

              <Route
                path="/summits/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={false}
                      contentType="summits"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/summits/:uuid">
                <ContentDetail isContent={false} contentType="summits" />
              </Route>
              <Route path="/summits">
                <ContentList isContent={false} contentType="summits" />
              </Route>

              <Route
                path="/items/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={false}
                      contentType="items"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/items/:uuid">
                <ContentDetail isContent={false} contentType="items" />
              </Route>
              <Route path="/items">
                <ContentList contentType="items" />
              </Route>

              <Route path="/track_now_screen">
                <TrackNowScreen />
              </Route>

              <Route
                path="/tips/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="tips"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/tips/:uuid">
                <ContentDetail isContent={true} contentType="tips" />
              </Route>
              <Route path="/tips">
                <ContentList contentType="tips" />
              </Route>

              <Route
                path="/topics/new"
                render={(props) => {
                  return <TopicDetail newTopic={true} />;
                }}
              />
              <Route
                path="/topics/:uuid"
                render={(props) => {
                  return <TopicDetail {...props} />;
                }}
              />
              <Route path="/topics">
                <TopicList />
              </Route>

              <Route
                path="/virtual_events/new"
                render={(props) => {
                  return <VirtualEventDetail newVirtualEvent={true} />;
                }}
              />
              <Route
                path="/virtual_events/:uuid"
                render={(props) => {
                  return <VirtualEventDetail {...props} />;
                }}
              />
              <Route path="/virtual_events">
                <VirtualEventList />
              </Route>

              <Route
                path="/events/new"
                render={(props) => {
                  return <EventCalendarDetail newEvent={true} />;
                }}
              />
              <Route
                path="/events/:uuid"
                render={(props) => {
                  return <EventCalendarDetail {...props} />;
                }}
              />
              <Route path="/events">
                <EventCalendarList />
              </Route>

              <Route
                path="/course_categories/new"
                render={(props) => {
                  return <CourseCategoryDetail newCategory={true} />;
                }}
              />
              <Route
                path="/course_categories/:uuid"
                render={(props) => {
                  return <CourseCategoryDetail {...props} />;
                }}
              />
              <Route path="/course_categories">
                <CourseCategoriesList />
              </Route>

              <Route
                path="/courses/:course_uuid/modules/:module_uuid/lessons/new"
                render={(props) => {
                  return (
                    <CourseLessonDetail newCourseLesson={true} {...props} />
                  );
                }}
              />
              <Route
                path="/courses/:course_uuid/modules/:module_uuid/lessons/:uuid"
                render={(props) => {
                  return <CourseLessonDetail {...props} />;
                }}
              />

              <Route
                path="/courses/:course_uuid/modules/new"
                render={(props) => {
                  return <CourseModuleDetail newModule={true} {...props} />;
                }}
              />

              <Route
                path="/courses/:course_uuid/modules/:uuid"
                render={(props) => {
                  return <CourseModuleDetail {...props} />;
                }}
              />

              <Route
                path="/courses/new"
                render={(props) => {
                  return <CourseDetail newCourse={true} />;
                }}
              />
              <Route
                path="/courses/:uuid"
                render={(props) => {
                  return <CourseDetail {...props} />;
                }}
              />

              <Route path="/courses">
                <CourseList />
              </Route>

              <Route
                path="/tracks/:uuid"
                render={(props) => {
                  return <TrackDetails {...props} />;
                }}
              />
              <Route path="/tracks">
                <TrackList />
              </Route>

              <Route
                path="/whats_new/new"
                render={(props) => {
                  return (
                    <RequireAccess requiredAccess={["super"]}>
                      <WhatsNewDetail newWhatsNew={true} />
                    </RequireAccess>
                  );
                }}
              />
              <Route
                path="/whats_new/:uuid"
                render={(props) => {
                  return (
                    <RequireAccess requiredAccess={["super"]}>
                      <WhatsNewDetail {...props} />
                    </RequireAccess>
                  );
                }}
              />
              <Route path="/whats_new">
                <RequireAccess requiredAccess={["super"]}>
                  <WhatsNewList />
                </RequireAccess>
              </Route>

              <Route
                path="/messages/new"
                render={(props) => {
                  return (
                    <ContentDetail
                      isContent={true}
                      contentType="messages"
                      newContent={true}
                    />
                  );
                }}
              />
              <Route path="/messages/:uuid">
                <ContentDetail isContent={true} contentType="messages" />
              </Route>
              <Route path="/messages">
                <ContentList contentType="messages" />
              </Route>

              <Route
                path="/users/:uuid"
                render={(props) => {
                  return <UserDetail {...props} />;
                }}
              />
              <Route path="/users">
                <UserList />
              </Route>

              <Route
                path="/super-users/:uuid"
                render={(props) => {
                  return <SuperUserDetails {...props} />;
                }}
              />

              <Route path="/super-users">
                <SuperUserList />
              </Route>
              <Route path="/import-export">
                <Records />
              </Route>

              <Route path="/webapp">
                <WebApp />
              </Route>

              <Route
                path="/action-lists/new"
                render={(props) => {
                  return (
                    <RequireAccess
                      requiredAccess={["versionizer"]}
                      minVersion={8.5}
                    >
                      <ContentDetail
                        isContent={true}
                        contentType="action-lists"
                        newContent={true}
                      />
                    </RequireAccess>
                  );
                }}
              />
              <Route path="/action-lists/:uuid">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={8.5}
                >
                  <ContentDetail isContent={true} contentType="action-lists" />
                </RequireAccess>
              </Route>
              <Route path="/action-lists">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={8.5}
                >
                  <ContentList contentType="action-lists" />
                </RequireAccess>
              </Route>

              <Route path="/menu-setup">
                <MenuSetup />
              </Route>
              <Route path="/app-info">
                <AppInfo />
              </Route>

              <Route path="/unlink-media">
                <UnlinkMedia />
              </Route>

              <Route path="/removed_apps">
                <RemovedApps />
              </Route>

              <Route path="/action-forms/new">
                <ActionFormsDetail newContent={true} />
              </Route>

              <Route path="/action-forms/:uuid">
                <ActionFormsDetail newContent={false} />
              </Route>

              <Route path="/action-forms">
                <ActionFormList />
              </Route>

              <Route
                path="/account_status/:type"
                render={(props) => {
                  return <AccountStatusList {...props} />;
                }}
              />

              <Route path="/account_status">
                <AccountStatus />
              </Route>

              <Route
                path="/global-fields/new"
                render={(props) => {
                  return <GlobalFieldsDetail {...props} newFields={true} />;
                }}
              />

              <Route
                path="/global-fields/:uuid"
                render={(props) => {
                  return <GlobalFieldsDetail {...props} newFields={false} />;
                }}
              />

              <Route
                path="/global-fields"
                render={(props) => {
                  return <GlobalFieldsList />;
                }}
              />

              <Route path="/onboarding_tracker">
                <OnboardingTracker />
              </Route>

              <Route path="/push-notifications">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={9.0}
                  hasMetOtherChecker={isPNAllowed}
                >
                  <PushNotificationList />
                </RequireAccess>
              </Route>

              <Route path="/app-downloads">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={9.0}
                  hasMetOtherChecker={isPNAllowed}
                >
                  <AppDownloadsList />
                </RequireAccess>
              </Route>

              <Route path="/push-subscribers">
                <RequireAccess
                  requiredAccess={["versionizer"]}
                  minVersion={9.0}
                  hasMetOtherChecker={isPNAllowed}
                >
                  <PushSubscribersList />
                </RequireAccess>
              </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </AppLayout>
    </Router>
  );

  if (Object.keys(user).length === 0) {
    return <div />;
  } else {
    return app();
  }
}

export default App;
