const audioConfig = {
  label: 'Audio',
  resourceEndpoint: 'audios',
  listMetadata: ['sort_order',
                 'title',
                 'featured',
                 'topics',
                 'release_date',
                 'tags',
                 'actions'],
  searchKeys: ['title', 'author'],
  keepListMetadataOrder: true,
  detailsMetadata: [
    { name: 'title', label: 'Title'},
    { name: 'author', label: 'Author', validations:
      [
        {type: 'required', params: ['Required']}
      ]
    },
    { name: 'description', label: 'Description' },
    { name: 'web_description', label: 'Description' },
    { name: 'audio_url', label: 'Audio URL', validations:
      [
        {type: 'required', params: ['Required']}
      ]
    },
    { name: 'image_url', label: 'Image URL'},
    { name: 'release_date', label: 'Release Date', format: "DD-MMM-YYYY"},
    { name: 'action_bar_text', label: 'Call to Action Text'},
    { name: 'action_bar_url', label: 'Call to Action URL'},
    { name: 'sort_order', label: 'Sort Order'},
    { name: 'featured', label: 'Featured'},
    { name: 'topics', label: 'Topics/Listings'},
    { name: 'tags', label: 'Tags'}
  ],
  formLayout: {
    columns: [
      ["title", "author", "description", "image_url", "audio_url", "release_date",],
      [
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
      ],
    ],
  },
}

export default audioConfig
