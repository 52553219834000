import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetCreator = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/creators/${uuid}`, 'GET', idToken)
}

export const UpdateCreator = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/creators/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteCreator = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/creators/${uuid}`, 'DELETE', idToken)
}

export const UnlinkCreator = async (user, uuid) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${user.currentApp()}/creators/${uuid}`, 'DELETE', user.idToken)
}

export const ResendCreatorSetupPassword = async (creatorUuid, idToken, appUuid) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${appUuid}/creators/${creatorUuid}/resend_setup`,
    "POST",
    idToken
  );
};