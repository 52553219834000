import React from "react";

const WebNotAvailable = ({ customClasses = '' }) => {
  return (
    <div className={`font-semibold italic text-center text-aeblue text-lg ${String(customClasses)}`}>
      NOT AVAILABLE IN WEB
    </div>
  );
};

export default WebNotAvailable