import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { mergeArray } from "../lib/arrayTools";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { GetWhatsNewList, DeleteWhatsNew } from "../services/api/whats_new";

import DeleteItem from "../components/modal_contents/DeleteItem";
import moment from "moment";

const WhatsNewList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetWhatsNewList(user)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "What's New", url: "/whats_new" },
  ];

  //Unlock Code (formerly Tagging ID) , Notification Email (formerly Your Email) , Tagging Name ,Tag ,Tag Created for
  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "publish_date",
      label: "Publish Date",

      parser: (data) => moment(data.publish_date).format("DD-MMM-yyyy"),
      ellipsis: true,
    },
    {
      name: "is_published",
      label: "Published?",
      parser: (data) => (data.is_published ? "Yes" : "No"),
      ellipsis: true,
    },
    {
      name: "subject",
      label: "Subject",
      parser: (data) => data.subject,
      ellipsis: true,
    },
    {
      name: "body",
      label: "Body",
      parser: (data) => data.body.replace(/(<([^>]+)>)/gi, ""),
      ellipsis: true,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={() => history.push("/whats_new/new")}
      >
        Add New Entry
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/whats_new/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteWhatsNew(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="What's New"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page links={links} title="What's New" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["subject"]}
      />
    </Page>
  );
};

export default WhatsNewList;
