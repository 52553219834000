import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  TextArea,
} from "../components/EntryForm";
import Page from "../components/Page";

import { GetOffer, UpdateOffer } from "../services/api/offer";
import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import MediaUploader from "../components/MediaUploader";
import { validations } from "../lib/form";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";

const OfferDetail = ({ newOffer, match }) => {
  const vals = {
    title: "",
    message: "",
    video_url: "",
    image_url: "",
    unlock_code: "",
    action_bar_text: "",
    action_bar_url: "",
    email: "",
    send_email: false,
    media_metadata: {},
    active: false,
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newOffer ? user.currentApp() : match.params.uuid;

  const history = useHistory();

  useEffect(() => {
    if (newOffer) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetOffer(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            title: res.data.title,
            message: res.data.message,
            video_url: res.data.video_url,
            image_url: res.data.image_url,
            unlock_code: res.data.unlock_code,
            action_bar_text: res.data.action_bar_text,
            action_bar_url: res.data.action_bar_url,
            email: res.data.email,
            send_email: res.data.send_email,
            active: res.data.active,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newOffer ? "New" : "Edit";
  const currentUrl = newOffer ? "/offers/new" : `/offers/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Special Offer Codes", url: "/offers" },
    { name: `${currentAction} Special Offer Code`, url: currentUrl },
  ];

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          title: validations.str_req_60,
          message: Yup.string().required("Required"),
          video_url: validations.str_255,
          image_url: validations.str_255,
          action_bar_text: validations.str_notreq_50,
          action_bar_url: validations.str_255,
          email: Yup.string()
            .email("Invalid email")
            .max(255, "Maximum 255 Characters"),
          send_email: Yup.boolean().nullable(),
        };
        if (values?.send_email) {
          schema.email = Yup.string()
            .email("Invalid email")
            .max(255, "Maximum 255 Characters")
            .required("Required");
        }
        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      delete values.unlock_code;

      if (newOffer) {
        AddAppResource(uuid, "offers", user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateOffer(uuid, user.idToken, { data: values }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/offers"),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Special Offer Code`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/offers"
      >
        <Column>
          <Input name="title" type="text" label="Title" formik={formik} />
          {!newOffer && (
            <Input
              name="unlock_code"
              type="text"
              label="Unlock Code"
              formik={formik}
              disabled
            />
          )}
          <TextArea
            name="message"
            type="text"
            label="Message"
            formik={formik}
          />
          <MediaUploader
            name="video_url"
            type="text"
            label="Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
        </Column>
        <Column>
          <Input
            name="action_bar_text"
            type="text"
            label="Call to Action Text"
            formik={formik}
          />
          <Input
            name="action_bar_url"
            type="text"
            label="Call to Action URL"
            formik={formik}
          />
          <Input
            name="email"
            type="email"
            label="Notification Email"
            formik={formik}
          />
          <div className="flex">
            <span className="flex w-2/3">
              <CheckBox name="send_email" label="Send Email" formik={formik} />
            </span>
          </div>
          <CheckBox name="active" label="Active" formik={formik} />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default OfferDetail;
