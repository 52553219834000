const resourceConfig = {
  label: "Resource",
  resourceEndpoint: "resources",
  listMetadata: [
    "sort_order",
    "title",
    "featured",
    "topics",
    "release_date",
    "tags",
    "actions",
  ],
  searchKeys: ["title"],
  keepListMetadataOrder: true,
  detailsMetadata: [
    { name: "title", label: "Title" },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "web_description",
      label: "Description",
    },
    { name: "video_url", label: "Video URL" },
    { name: "image_url", label: "Image URL" },
    { name: "release_date", label: "Release Date", format: "DD-MMM-yyyy" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "sort_order", label: "Sort Order" },
    { name: "featured", label: "Featured?" },
    { name: "topics", label: "Topics/Listings" },
    { name: "tags", label: "Tags" },
  ],
  formLayout: {
    columns: [
      [
        "title",
        "description",
        "video_url",
        "image_url",
        "release_date",
        "action_bar_text",
      ],
      ["action_bar_url", "featured", "topics", "sort_order", "tags"],
    ],
  },
};

export default resourceConfig;
