import { Column, EntryForm, Input, TextArea, } from "../components/EntryForm";
import Page from "../components/Page";
import { useFormik } from "formik";
import MediaUploader from "../components/MediaUploader";
import { useCallback, useMemo } from "react";
import Multiselect from "multiselect-react-dropdown";
import { ChromePicker } from "react-color";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { GetApp, UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import * as Yup from "yup";
import { validations } from "../lib/form";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useModalProvider } from "../providers/ModalProvider";
import ErrorModal from "../components/modal_contents/ErrorModal";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "antd/es/tooltip";
import copy from "../assets/images/copy.png";

const DEFAULT_FONT = "Segoe UI";

const links = [
  { name: "Home", url: "/" },
  { name: "Web Setup", url: "/webapp" },
];

const headerFonts = [
  { name: "Segoe UI", value: "Segoe UI" },
  { name: "Merriweather", value: "Merriweather" },
  { name: "Delius", value: "Delius" },
  { name: "Montserrat", value: "Montserrat" },
  { name: "Oswald", value: "Oswald" },
  { name: "Playfair Display", value: "Playfair Display" },
  { name: "Great Vibes", value: "Great Vibes" },
  { name: "Verdana", value: "Verdana" },
  { name: "Aleo", value: "Aleo" },
  { name: "Nunito", value: "Nunito" },
  { name: "Raleway", value: "Raleway" },
];

const schema = {
  username: validations.username,
  logo_text: validations.str_18,
  setup: Yup.object().shape({
    header: Yup.object().shape({
      tagline: Yup.string().max(100, "Maximum 100 Characters")
    })
  })
};

const bodyFonts = [
  { name: "Segoe UI", value: "Segoe UI" },
  { name: "Aleo", value: "Aleo" },
  { name: "Nunito", value: "Nunito" },
  { name: "Raleway", value: "Raleway" },
  { name: "Verdana", value: "Verdana" },
];

const WebApp = () => {
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { user, setUser } = useUserProvider();
  const username = user.app_data.username;
  // const webURL = `${process.env.REACT_APP_WEB_FQDN}/${username}`;
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState(null);

  const [initialValues, setInitialValues] = useState({
    username: "",
    domains: "",
    setup: {
      body_tag: "",
      head_tag: "",
      icon: "",
      colors: {
        dark: "#333",
        medium: "#666",
        light: "#ccc",
      },
      fonts: {
        header: DEFAULT_FONT,
        body: DEFAULT_FONT,
      },
      header: {
        tagline: "",
        logo: {
          logo_url: "",
          logo_text: "",
        },
      },
    },
    // MediaUploader bug unable to handle nested object
    // Workaround on save move logo_text field to header.logo.logo_text
    logo_text: "",
    logo_url: "",
  });

  const url = initialValues.domains.length > 0 ? initialValues.domains[0] : `${process.env.REACT_APP_WEB_FQDN}/`;
  const domain = url.endsWith("/") ? url : `${url}/`;
  const completeURL = user?.app_data?.domains?.length >= 1 ? user?.app_data?.domains[0] : `${domain}${username}`;

  const handleSubmit = useCallback(
    (values) => {
      setIsSaved(false);
      setIsLoading(true);
      const _values = { ...values };
      _values.setup.header.logo.logo_text = values.logo_text;
      _values.setup.header.logo.logo_url = values.logo_url;
      delete _values.logo_text;
      delete _values.logo_url;

      const payload = {
        data: _values,
      };

      // submit
      UpdateApp(user.currentApp(), user.idToken, payload)
        .then((res) => {
          if (res.ok) {
            setIsSaved(true);
            return res.json();
          } else {
            showModal(<ErrorModal status={res.status} />);
          }
        })
        .then((res) => {
          if (user.app_data.username !== res.data.username) {
            setUser({
              ...user,
              app_data: {
                ...user.app_data,
                username: res.data.username,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [user, setUser]
  );


  const handleGetData = useCallback(() => {
    if (username) {
      setIsLoading(true);
      GetApp(user)
        .then((res) => res.json())
        .then((res) => {
          delete res.data.member_content_message;

          setInitialValues({
            ...res.data,
            logo_text: res?.data?.setup?.header?.logo?.logo_text,
            logo_url: res?.data?.setup?.header?.logo?.logo_url,
            setup: {
              ...res.data.setup,
              head_tag: res.data.setup.head_tag || '',
              body_tag: res.data.setup.body_tag || '',
            }
          });

          setMetadata(res.data.setup.media_metadata);
        })
        .finally(() => setIsLoading(false));
    }
  }, [user]);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
  });

  const selectedHeaderFont = formik.values.setup.fonts.header
    ? [{ name: formik.values.setup.fonts.header }]
    : [];
  const handleSelectHeaderFont = (selected) => {
    formik.setFieldValue(
      "setup.fonts.header",
      selected[selected.length - 1].name
    );
  };

  const selectedBodyFont = formik.values.setup.fonts.body
    ? [{ name: formik.values.setup.fonts.body }]
    : [];
  const handleSelectBodyFont = (selected) => {
    formik.setFieldValue(
      "setup.fonts.body",
      selected[selected.length - 1].name
    );
  };

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const SavedLabel = useMemo(
    () => <span className="text-green-400">Saved</span>,
    []
  );

  // adds classname to font list
  useEffect(() => {
    const lists = document.querySelectorAll(
      "#webAppFontStyling .optionContainer"
    );
    lists.forEach((el) => {
      const options = el.children;
      for (let i = 0; i < options.length; i++) {
        const option = options[i];

        const fontName = option.textContent;
        const pascalCasedFont = fontName.split(" ").join("");

        option.classList.add(pascalCasedFont);
      }
    });
  }, []);

  return (
    <div className="webapp">
      <Page links={links}>
        <div className="pb-10">
          <EntryForm
            isLoading={isLoading}
            formik={formik}
            submitHandler={formik.handleSubmit}
            submitLabel="Save"
            savedLabel={isSaved ? SavedLabel : null}
          >
            <Column>
              <div>
                <div className="flex w-full gap-1">
                  <div className="flex flex-col justify-between">
                    <label className="text-lg whitespace-nowrap mb-5 mr-1.5">My Custom URL</label>
                    <label className="flex items-center text-lg mr-0.5 whitespace-nowrap h-11 mb-5">
                      {domain}
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    {username && (
                      <div className="flex mb-5">
                        <a className="text-blue-400 text-lg underline" href={completeURL} target="_blank" rel="noreferrer">
                          {completeURL}
                        </a>
                        <Tooltip title={<div>Copy</div>}>
                          <img
                            src={copy}
                            className="h-5 mt-1 ml-2 w-5 cursor-pointer hover:scale-125"
                            alt="copy"
                            onClick={() =>
                              navigator.clipboard.writeText(completeURL)
                            }
                          />
                        </Tooltip>
                      </div>
                    )}
                    <div className="flex flex-col w-full ttnk-custom-url-field">
                      <Input
                        name="username"
                        type="text"
                        formik={formik}
                        wrapperClassName="w-full flex ttnk-flex-row"
                        inputClassName="w-full"
                        disabled={!user?.isSuper}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <MediaUploader
                name="setup.icon"
                type="text"
                label="Icon Image"
                formik={formik}
                mediaType="image"
                metadata={metadata}
              />
              <div style={{ marginTop: -12 }} className="text-right mb-4">
                <i>
                  This will also serve as your website's favicon. Best size is
                  96 x 96 px
                </i>
              </div>
              <MediaUploader
                name="logo_url"
                type="text"
                label="Logo"
                formik={formik}
                mediaType="image"
                metadata={metadata}
              />
              <div style={{ marginTop: -12 }} className="text-right mb-4">
                <i>
                  If your logo has your brand name, place it here. Best size is
                  256w x 96h
                </i>
              </div>
              <Input
                name="logo_text"
                type="text"
                label="Text alternative to Logo"
                formik={formik}
              />
              <div style={{ marginTop: -12 }} className="text-right mb-4">
                <i>Type your brand name here if you don't upload a logo</i>
              </div>
              <Input
                name="setup.header.tagline"
                type="text"
                label="Tagline"
                formik={formik}
              />
              <div style={{ marginTop: -12 }} className="text-right mb-4">
                <i>
                  A short description of what people will find in this site
                  (7-10 words)
                </i>
              </div>
              <TextArea name="setup.head_tag" type="text" label="Head Tag" formik={formik} />
              <TextArea name="setup.body_tag" type="text" label="Body Tag" formik={formik} />
            </Column>
            <Column>
              <section id="webAppFontStyling">
                <header className="mb-4 text-lg">Font Styling</header>
                <label className="text-lg mb-3 block">Headers</label>
                <Multiselect
                  // singleSelect
                  options={headerFonts}
                  selectedValues={selectedHeaderFont}
                  onSelect={handleSelectHeaderFont}
                  displayValue="name"
                  placeholder="Select Header font"
                  onRemove={() =>
                    handleSelectHeaderFont([{ name: DEFAULT_FONT }])
                  }
                  closeOnSelect
                />
                <label className="text-lg my-3 block">Body</label>
                <Multiselect
                  // singleSelect
                  closeOnSelect
                  options={bodyFonts}
                  selectedValues={selectedBodyFont}
                  onSelect={handleSelectBodyFont}
                  displayValue="name"
                  placeholder="Select Body font"
                  onRemove={() =>
                    handleSelectBodyFont([{ name: DEFAULT_FONT }])
                  }
                />
              </section>
              <section className="mt-8">
                <header className="mb-4 text-lg">Website Color Scheme</header>
                <p className="text-lg">
                  <strong>Light Color:</strong> Used behind body text in
                  headers, footers and content areas.
                  <br />
                  Black text must be readable on top of this color
                </p>
                <div className="mt-2">
                  <ColorPicker
                    color={formik.values.setup.colors.light}
                    onChange={(color) => {
                      formik.setFieldValue("setup.colors.light", color.hex);
                    }}
                    readableIn="black"
                  />
                </div>
                <p className="text-lg">
                  <strong>Medium Color:</strong> Used as a background color for
                  button and other accents.
                  <br />
                  White text must be readable on top of this color
                </p>
                <div className="mt-2">
                  <ColorPicker
                    color={formik.values.setup.colors.medium}
                    onChange={(color) => {
                      formik.setFieldValue("setup.colors.medium", color.hex);
                    }}
                  />
                </div>
                <p className="text-lg">
                  <strong>Dark Color:</strong> Used for text/menu links and
                  banner backgrounds.
                  <br />
                  White text must be readable on top of this color
                </p>
                <div className="mt-2">
                  <ColorPicker
                    color={formik.values.setup.colors.dark}
                    onChange={(color) => {
                      formik.setFieldValue("setup.colors.dark", color.hex);
                    }}
                    pickerContainerStyle={{ marginTop: -215 }}
                  />
                </div>
              </section>
            </Column>
          </EntryForm>
        </div>
      </Page>
    </div>
  );
};

export default WebApp;

export function ColorPicker({
  color,
  onChange,
  pickerContainerStyle,
  readableIn = "white",
  readOnly = false
}) {
  const containerRef = useRef(null);
  const [_color, setColor] = useState(() => color);
  const [showPicker, setShowPicker] = useState(false);

  const handleChangeComplete = useCallback(
    (color) => {
      setColor(color);
      onChange?.(color);
    },
    [onChange]
  );

  useEffect(() => {
    function handleClickOutside(event) {
      const el = containerRef.current;
      if (!el?.contains?.(event.target)) {
        setShowPicker(false);
      }
    }

    if (showPicker) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => window.removeEventListener("click", handleClickOutside);
  }, [showPicker, containerRef]);

  return (
    <>
      <div className="relative">
        {showPicker && (
          <div
            style={pickerContainerStyle || {}}
            ref={containerRef}
            className="absolute ml-12"
          >
            <ChromePicker
              color={_color}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        )}
      </div>
      <button
      disabled={readOnly}
        onClick={() => setShowPicker((prev) => !prev)}
        style={{ width: 28, height: 28, background: color }}
        className="rounded-full hover:opacity-60 border-gray-200 border shadow-sm"
        form="#"
      >
        <span className="opacity-80">
          <EditIcon htmlColor={readableIn} fontSize="small" />
        </span>
      </button>
    </>
  );
}
