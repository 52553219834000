import { useEffect, useState } from "react";
import moment from 'moment'
import { EntryForm,
         Column,
         Input,
         CheckBox,
         TextArea} from '../components/EntryForm'
import Page from '../components/Page'

import { GetWhatsNew, UpdateWhatsNew, AddWhatsNew } from '../services/api/whats_new'
import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"
import { useLoaderProvider } from '../providers/LoaderProvider'
import DateSelector from '../components/DateSelector'

import { useFormik } from 'formik'

import { useHistory } from 'react-router-dom'

import * as Yup from 'yup'
import responseProcessor from "../lib/responseProcessor";

const WhatsNewDetail = ({ newWhatsNew, match }) => {
  const vals = {
    publish_date: moment().utcOffset(0, true),
    is_published: '',
    subject: '',
    body: '',
  }

  const [initialValues, setInitialValues] = useState(vals)

  const [isLoading, setIsLoading] = useState(false)

  const { user } = useUserProvider()

  const { showModal } = useModalProvider()

  const { doSetShowLoadingModal } = useLoaderProvider()

  const uuid = newWhatsNew ? user.currentApp() : match.params.uuid

  const history = useHistory()

  useEffect(() => {
    if (newWhatsNew) {
      setInitialValues(vals)
    } else {
      setIsLoading(true)
      GetWhatsNew(uuid, user.idToken).then(res => res.json()).then(res => {
        setInitialValues({
          publish_date: res.data.publish_date,
          is_published: res.data.is_published,
          subject: res.data.subject,
          body: res.data.body,
        })
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    doSetShowLoadingModal(isLoading)
  }, [isLoading])

  const currentAction = newWhatsNew ? "New" : "Edit"
  const currentUrl = newWhatsNew ? "/whats_new/new" :`/whats_new/${uuid}`

  let links = [
    { name: "Home", url: "/" },
    { name: "What's New", url: "/whats_new" },
    { name: `${currentAction} Entry`, url: currentUrl },
  ]

  let schema = {
    is_published: Yup.boolean().nullable(),
    publish_date: Yup.date().required('Required'),
    subject: Yup.string().max(30, 'Maximum 30 Characters').required('Required'),
    body: Yup.string().nullable().required('Required'),
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (newWhatsNew){
        AddWhatsNew(user.idToken, { data: values })
          .then(response => responseProcessorProxy(response))
      } else {
        UpdateWhatsNew(uuid, user.idToken, { data: values })
          .then(response => responseProcessorProxy(response))
      }
    },
  })

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push('/whats_new')
    })
  }

  return (
    <Page links={links} title={`${currentAction} What's New Entry`}>
      <EntryForm isLoading={isLoading} formik={formik} submitHandler={ formik.handleSubmit } cancelURL="/whats_new" >
        <Column>
          <CheckBox name='is_published' label='Published' formik={formik} />
          <DateSelector
            name="publish_date"
            type="text"
            label='Publish Date'
            formik={formik}
          />
          <Input name='subject' type='input' label='Subject' formik={formik} />
        </Column>
        <Column>
          <TextArea name='body' label='Body' type="text" formik={formik} rows='21'  />
        </Column>
      </EntryForm>
    </Page>
  )
}

export default WhatsNewDetail;
