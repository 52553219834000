import { Fragment } from 'react'

const ConfirmModal = ({
  itemId,
  itemLabel,
  onConfirm,
  onClose,
  confirmAction,
  confirmBtnLabel
}) => {

  const label = confirmAction || 'confirm'
  const btnLabel = confirmBtnLabel || 'Confirm'

  return(
    <Fragment>
      <div>Are you sure you want to {label} this {itemLabel}?</div>
      <div className="flex mt-6">
        <div className="ml-auto mr-4">
          <button className="ttnk-button add-button" onClick={() => onConfirm(itemId)}>{ btnLabel }</button>
        </div>
        <div className="mr-auto">
          <button className="ttnk-button add-button" onClick={() => onClose()}>Cancel</button>
        </div>
      </div>
    </Fragment>
  )
}

export default ConfirmModal
