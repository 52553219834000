import { Radio } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { Upload, message } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import UploadFile from '../assets/icons/upload-file.svg';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { useUserProvider } from '../providers/UserProvider';
import { useLoaderProvider } from '../providers/LoaderProvider';
import VersionizerWrapper from './Versionizer';

const MediaUploader = ({
  name,
  type,
  label,
  formik,
  prefix,
  disabled,
  metadata,
  mediaType,
  doSetLoading,
  labelClassName,
  placeholder,
  maxSize = null,
  isFromPNWidget = false,
  urlPlaceholder = '',
  isUrlOnly = false,
  isUploadOnly = false,
  required = false,
  isErrorMessageBelowField = false,
  description = 'Video must be less than 3 GB',
  isDownloadable = false
}) => {
  const imageMaxSize = maxSize || 10000000;

  let value = formik?.values[name];
  const newMetadata =
    (isEmpty(metadata) ? formik?.values?.media_metadata : metadata) || {};

  const defaultFileData = useMemo(() => {
    return {
      id: null,
      name: null,
    };
  }, []);

  const [isUrl, setIsUrl] = useState(!isUploadOnly);
  const [fileData, setFileData] = useState(defaultFileData);
  const { Dragger } = Upload;
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const { doSetShowLoadingModal } = useLoaderProvider();
  const [isFileSizeExceed, setIsFileSizeExceed] = useState(false);

  const { user } = useUserProvider();

  const setup = user.app_data.setup;
  const url = window.location.href;

  const acceptedTypes = {
    audio: 'audio/mp3,audio/wav,audio/mpeg',
    video: '.mov,.mp4,.wmv,avi',
    image: 'image/jpg,image/jpeg,image/png,image/gif',
    file: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/txt, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
  };

  // Get values for dot separated fieldnames (ex. profile.name)
  if (name?.indexOf('.') > -1) {
    const paths = name.split('.');
    value = formik?.values?.[paths[0]]?.[paths[1]];
  }

  useEffect(() => {
    if (
      value &&
      /^media:[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        value
      )
    ) {
      const uuid = value.match(
        /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      )[0];

      setIsUrl(false);

      if (!fileData.id) {
        if (newMetadata && newMetadata[uuid]) {
          setFileData({
            id: newMetadata[uuid].id,
            name: newMetadata[uuid].filename,
          });
        } else {
          setFileData(defaultFileData);
        }
      }
    }
  }, [value, newMetadata]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && fileData?.id && value === '') {
      isMounted = false;
      setFileData(defaultFileData);
    }

    return () => {
      isMounted = true;
    };
  }, [fileData, value, defaultFileData]);

  const resetFileData = (e) => {
    if(e.target.tagName !== 'BUTTON') {
      formik.setFieldValue(name, '');
      formik.setFieldTouched(name);
      setFileData(defaultFileData);
    }
  };

  const lowerCaseExtension = (filename)=> {
    const parts = filename.split('.');
    if (parts.length > 1) {
    const extension = parts.pop().toLowerCase();
      return parts.join('.') + '.' + extension;
    }
    return filename;
  }

  const uploadOverride = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + user.idToken,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append('type', mediaType === 'pdf' ? 'file' : mediaType);
    fmData.append('file', file, lowerCaseExtension(file.name));
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/media`,
        fmData,
        config
      );

      const data = res.data.data;

      formik.setFieldValue(name, 'media:' + data.id);
      formik.setFieldTouched(name);

      const fd = { id: data.id, name: data.filename, filename: data.filename };
      setFileData(fd);

      const newMeta = { ...newMetadata, [data?.id]: fd };
      formik.setFieldValue('media_metadata', newMeta);

      onSuccess('Ok');
      setLoading(false);
    } catch (err) {
      const error = new Error('Some error');
      onError({ err });
      setLoading(false);
    }
  };

  useEffect(() => {
    doSetShowLoadingModal(loading);
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const draggerProps = {
    accept: acceptedTypes[mediaType],
    name: 'file',
    multiple: false,
    customRequest: uploadOverride,
    showUploadList: false,
    maxCount: 1,
    disabled,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload(file) {
      // only validate video files
      if (
        acceptedTypes[mediaType] === acceptedTypes.image &&
        !isFileSizeExceed
      ) {
        if (file.size > imageMaxSize) {
          setIsFileSizeExceed(true);
          return false;
        }
      }

      if (
        acceptedTypes[mediaType] === acceptedTypes.video &&
        !isFileSizeExceed
      ) {
        if (file.size > 3_221_225_472) {
          setIsFileSizeExceed(true);
          return false;
        }
      }

      setIsFileSizeExceed(false);
      return true;
    },
  };

  const getNestedFieldValue = (errors, arrayFields) => {
    if (arrayFields.length > 1) {
      const arrayFieldsCopy = arrayFields;
      const field = arrayFieldsCopy[0];
      arrayFieldsCopy.splice(0, 1);
      return getNestedFieldValue(errors[field], arrayFieldsCopy);
    }
    return errors && errors[arrayFields[0]];
  };

  const handleDownloadFile = async (id) => {
    const path = newMetadata[id]?.url;
    const filename = newMetadata[id]?.filename;
    const response = await fetch(path);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Release the reference to the blob
    URL.revokeObjectURL(url);
  };

  return (
    <div
      className={`flex mb-5 ttnk-form-input ttnk-form-input-${type} ${isUrlOnly ? "flex-row" : "flex-col"}`}
    >
      <div
        className={`flex ${
          isFromPNWidget ? 'justify-start gap-4' : 'justify-between'
        } ${isUrlOnly ? "w-1/3" : ""}`}
      >
        <label
          className={`text-lg mb-3 ${labelClassName ?? ''}`}
          htmlFor={name}
        >
          {label}
          {required && label && <span style={{ color: "red" }}>*</span>}
        </label>
        {
          isUrlOnly ? null : (
            <div className={isUploadOnly ? 'hidden' : ''}>
              <Radio.Group
                onChange={(e) => {
                  setIsUrl(e.target.value);
                }}
                value={isUrl}
                className={isFromPNWidget ? 'flex flex-row-reverse' : ''}
              >
                <Radio value={true} disabled={!!fileData.id || disabled}>
                  URL
                </Radio>
                <Radio value={false} disabled={disabled}>
                  Upload
                </Radio>
              </Radio.Group>
            </div>
          )
        }
      </div>
      {isUrl ? (
        <div className={isUrlOnly ? "w-2/3" : ""}>
          <div className="flex">
            {!!prefix && (
              <span className="ttnk-form-input-prefix">{prefix}</span>
            )}
            <input
              id={name}
              name={name}
              type={type}
              disabled={disabled}
              placeholder={placeholder}
              className="border border-gray-300 text-lg p-2 rounded-lg"
              onChange={formik.handleChange}
              value={value}
            />
          </div>
          {setup?.actionclips &&
            url.includes('videos') &&
            name === 'video_url' &&
            formik.values.actionclip && (
              <VersionizerWrapper minVersion={9}>
                <div className="italic mt-1 text-right">
                  <i>
                    If this is an ActionClip, only an mp4 video file is allowed
                  </i>
                </div>
              </VersionizerWrapper>
            )}

          {isErrorMessageBelowField && getNestedFieldValue(formik.touched, name.split('.')) &&
            getNestedFieldValue(formik.errors, name.split('.')) && (
              <div className="text-red-600">
                {getNestedFieldValue(formik.errors, name.split('.'))}
              </div>
            )}
        </div>
      ) : (
        <div>
          {!fileData.id && (
            <Dragger {...draggerProps}>
              <p className="justify-center w-full flex">
                <img src={UploadFile} alt="Upload File" />
              </p>
              <p className="text-lg py-5">
                Drag and drop files here or click to browse your files
              </p>
            </Dragger>
          )}
          {!!fileData.id && (
            <div className={`flex mt-2 w-full ${isDownloadable ? "justify-between" : ""}`}>
              <input
                id={name}
                name={name}
                type="hidden"
                value={formik.values[name]}
              />
              {
                isDownloadable && newMetadata?.[fileData?.id] ? (
                  <button
                    className="text-lg hover:text-aeblue"
                    onClick={() => handleDownloadFile(fileData.id)}
                  >
                    {fileData.name}
                  </button>
                ) : (
                  <span className="w-full text-lg">Filename: {fileData.name}</span>
                )
              }
              <button
                className="ml-2"
                onClick={resetFileData}
                disabled={disabled}
              >
                <TrashIcon
                  className={`h-5 w-5 fill-current text-gray-400 trash ${
                    !disabled && 'hover:text-red-600'
                  }`}
                />
              </button>
            </div>
          )}
        </div>
      )}
      {acceptedTypes[mediaType] === acceptedTypes.video && !isUrl && (
        <div className="flex flex-row items-center justify-between">
          <div className="italic mt-1">{description ?? 'Video must be less than 3 GB'}</div>
          {setup?.actionclips &&
            url.includes('videos') &&
            formik.values.actionclip && (
              <VersionizerWrapper minVersion={9}>
                <div className="italic mt-1">
                  <i>
                    If this is an ActionClip, only an mp4 video file is allowed
                  </i>
                </div>
              </VersionizerWrapper>
            )}
        </div>
      )}
      { !isErrorMessageBelowField && getNestedFieldValue(formik.touched, name.split('.')) &&
        getNestedFieldValue(formik.errors, name.split('.')) && (
          <div className="text-red-600">
            {getNestedFieldValue(formik.errors, name.split('.'))}
          </div>
        )}
      {acceptedTypes[mediaType] === acceptedTypes.image && isFileSizeExceed && (
        <div className="text-red-600">
          Must be less than 10MB
        </div>
      )}
      {acceptedTypes[mediaType] === acceptedTypes.video && isFileSizeExceed && (
        <div className="text-red-600">
          Maximum file size exceeded. Video must be less than 3 GB
        </div>
      )}
    </div>
  );
};

export default MediaUploader;
