import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { mergeArray } from "../lib/arrayTools";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteUser, ListSuperUsers } from "../services/api/user";

import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";

import DeleteItem from "../components/modal_contents/DeleteItem";

const SuperUserList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();


  const loadData = async () => {
    setData({ ...data, loading: true });
    ListSuperUsers(user)
      .then((res) => res.json())
      .then((jsonData) => {
        const filteredUsers = [];
        jsonData.data?.forEach((_) => {
          if (filteredUsers.findIndex((a) => a.id === _.id) === -1 && _.id) {
            filteredUsers.push(_);
          }
        });

        setData({
          loading: false,
          data: filteredUsers,
          count: filteredUsers.length,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Super Users", url: "/super-users" },
  ];

  const deleteClass =
    "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

  const AppList = ({ apps }) => (
    <table>
      {apps.map((app) => (
        <tr>
          <td>{app.name}</td>
          <td>{app.role}</td>
        </tr>
      ))}
    </table>
  );

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "email",
      label: "Email",
      parser: (data) => data.email,
    },
    {
      name: "app",
      label: "App/Role",
      parser: (data) => <AppList apps={data.apps} />,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          deleteIcon={TrashIcon}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
          deleteClass={deleteClass}
        />
      ),
    },
  ];

  const updateButtonHandler = (id) => {
    history.push(`/super-users/${id}`);
  };

  const unlinkButtonHandler = async (id) => {
    await DeleteUser(user, id);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="user"
        onDelete={unlinkButtonHandler}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  return (
    <Page links={links} title="Super Users">
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "email"]}
      />
    </Page>
  );
};

export default SuperUserList;
