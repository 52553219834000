import React, { useState } from "react";
import PropTypes from "prop-types";

import Page from "../components/Page";
import DataTable from "../components/DataTable";
import TableActions from "../components/TableActions";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";

import DeleteItem from "../components/modal_contents/DeleteItem";

import { DeleteUnlinkedMedia, ListUnlinkMedia } from '../services/api/unlink_media';

const links = [
  { name: "Home", url: "/" },
  { name: "Unlinked Media", url: "/unlink-media" },
];

const deleteClass =
  "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

const UnlinkMedia = (props) => {
  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const loadData = async () => {
    setData({ ...data, loading: true });
    ListUnlinkMedia(user)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "filename",
      label: "File Name",
      parser: (data) => data.filename,
    },
    {
      name: "type",
      label: "Media Type",
      parser: (data) => data.type,
      ellipsis: true,
    },
    {
      name: "filesize",
      label: "File Size",
      parser: (data) => data.size,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          deleteIcon={TrashIcon}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
          deleteClass={deleteClass}
        />
      ),
    },
  ];

  const unlinkButtonHandler = async (id) => {
    await DeleteUnlinkedMedia(user, id);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="media"
        onDelete={unlinkButtonHandler}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  return (
    <Page links={links} title="Unlinked Media">
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["filename"]}
      />
    </Page>
  );
};

UnlinkMedia.propTypes = {};

export default UnlinkMedia;
