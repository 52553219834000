import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
} from "../components/EntryForm";
import Page from "../components/Page";
import MediaUploader from "../components/MediaUploader";
import { GetTopic, UpdateTopic } from "../services/api/topic";
import { GetAppResource, AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useDuplicateValidation, validations } from "../lib/form";
import DataSelector from "../components/DataSelector";
import SubTable from "../components/SubTable";
import contentConfig from "../lib/content_config/config";
import responseProcessor from "../lib/responseProcessor";
import isEmpty from "lodash/isEmpty";
import VersionizerWrapper from "../components/Versionizer";

const TopicDetail = ({ newTopic, match }) => {
  const contentType = "topics";
  const subTables = contentConfig(contentType).subTables;
  const vals = {
    name: "",
    image_url: "",
    sort_order: 1,
    listing: false,
    directories: [],
    custom_video_text: "",
    video: { id: null },
    custom_article_text: "",
    custom_lesson_text: "",
    custom_resource_text: "",
    custom_action_list_text: "",
    lesson: { id: null },
    article: { id: null },
    sub_topics: [],
    url_subtopics: [],
    tags: [],
    media_metadata: {},
    active: false,
    enterprise: false,
    enterprise_app: null
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [videoValues, setVideoValues] = useState([]);
  const [articleValues, setArticleValues] = useState([]);
  const [lessons, setLessons] = useState([]);
  // const [isLessonsLoading, setIsLessonsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [_isVideoLoading, setIsVideoLoading] = useState(false);
  // const [_isArticleLoading, setIsArticleLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newTopic ? user.currentApp() : match.params.uuid;
  const history = useHistory();
  const showBusiness = (user?.app_data?.setup?.enterprise 
    || (Array.isArray(user?.app_data?.reseller_of) && user?.app_data?.reseller_of?.length > 0))

  useEffect(() => {
    if (newTopic) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetTopic(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          const custom_video = res?.data?.video
            ? {
                name: res?.data?.video?.title,
                id: res?.data?.video?.id,
              }
            : null;
          const custom_article = res?.data?.article
            ? {
                name: res?.data?.article?.title,
                id: res?.data?.article?.id,
              }
            : null;

          const custom_lesson = res?.data?.lesson
            ? {
                name: res?.data?.lesson?.title,
                id: res?.data?.lesson?.id,
              }
            : null;
          const custom_action_list = res?.data?.action_list
            ? {
                name: res?.data?.action_list?.title,
                id: res?.data?.action_list?.id,
              }
            : null;
          const custom_resource = res?.data?.resource
            ? {
                name: res?.data?.resource?.title,
                id: res?.data?.resource?.id,
              }
            : null;

          setInitialValues({
            active: res?.data?.active,
            name: res?.data?.name,
            image_url: res?.data?.image_url,
            sort_order: res?.data?.sort_order,
            listing: res?.data?.listing,
            directories: res?.data?.directories,
            custom_video_text: res?.data?.custom_video_text,
            custom_lesson: custom_lesson,
            custom_lesson_text: res?.data?.custom_lesson_text,
            custom_video: custom_video,
            custom_article_text: res?.data?.custom_article_text,
            custom_action_list: custom_action_list,
            custom_action_list_text: res?.data?.custom_action_list_text,
            custom_resource: custom_resource,
            custom_resource_text: res?.data?.custom_resource_text,
            custom_article: custom_article,
            sub_topics: [...new Set(res.data.sub_topics.map((st) => ({ id: st })))],
            url_subtopics: res.data.url_subtopics,
            tags: res.data.tags,
            enterprise: res.data?.enterprise,
            enterprise_app: res.data?.enterprise_app
          });
          setMetadata(res?.data?.media_metadata);
          setIsLoading(false);
        });
    }

    GetAppResource(user, "content/videos")
      .then((res) => res.json())
      .then((res) => {
        setVideoValues(
          res.data.map((video) => ({ id: video.id, title: video.title }))
        );
        // setIsVideoLoading(false);
      });

    GetAppResource(user, "content/articles")
      .then((res) => res.json())
      .then((res) => {
        setArticleValues(
          res.data.map((article) => ({ id: article.id, title: article.title }))
        );
        // setIsArticleLoading(false);
      });

    GetAppResource(user, "content/lessons")
      .then((res) => res.json())
      .then((res) => {
        setLessons(
          res.data.map((article) => ({ id: article.id, title: article.title }))
        );
        // setIsLessonsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newTopic ? "New" : "Edit";
  const currentUrl = newTopic ? "/topics/new" : `/topics/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Topics/Directory Listings", url: "/topics" },
    { name: `${currentAction} Topic / Listing`, url: currentUrl },
  ];

  const validate = useDuplicateValidation(["name"], "url_subtopics");

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => validate(values, formik),
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          name: validations.str_req_40,
          image_url: Yup.string().max(255, 'Maximum 255 Characters'),
          sort_order: validations.sort_order,
          listing: Yup.boolean().nullable(),
          custom_video: Yup.object({
            id: Yup.string().nullable(),
          }).nullable(),
          custom_article: Yup.object({
            id: Yup.string().nullable(),
          }).nullable(),
          custom_lesson: Yup.object({
            id: Yup.string().nullable(),
          }).nullable(),
          custom_action_list: Yup.object({
            id: Yup.string().nullable(),
          }).nullable(),
          custom_resource: Yup.object({
            id: Yup.string().nullable(),
          }).nullable(),
          enterprise: Yup.boolean().nullable(),
          enterprise_app: Yup.object({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
          }).nullable(),
        };
        if (values.custom_video?.id) {
          schema.custom_video_text = validations.str_req_25;
        }
        if (values.custom_article?.id) {
          schema.custom_article_text = validations.str_req_25;
        }
        if (values.custom_lesson?.id) {
          schema.custom_lesson_text = validations.str_req_25;
        }
        if (values.custom_action_list?.id) {
          schema.custom_action_list_text = validations.str_req_25;
        }
        if (values.custom_resource?.id) {
          schema.custom_resource_text = validations.str_req_25;
        }
        if (values?.listing) {
          schema.directories = Yup.array()
            .min(1, "Required")
            .required("Required")
            .nullable();
        }
        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      let payload = {
        ...values,
        sub_topics: values.sub_topics.map((st) => st.id),
        custom_action_list_text: values.custom_action_list_text,
        custom_resource_text: values.custom_resource_text,
      };

      if (values.custom_video?.id) {
        payload = { ...payload, video: { id: values.custom_video?.id } };
      } else {
        payload = { ...payload, video: {} };
      }
      if (values.custom_article?.id) {
        payload = { ...payload, article: { id: values.custom_article?.id } };
      } else {
        payload = { ...payload, article: {} };
      }
      if (values.custom_lesson?.id) {
        payload = { ...payload, lesson: { id: values.custom_lesson?.id } };
      } else {
        payload = { ...payload, lesson: {} };
      }
      if (values.custom_action_list?.id) {
        payload = {
          ...payload,
          action_list: { id: values.custom_action_list?.id },
        };
      } else {
        payload = {
          ...payload,
          action_list: {},
        };
      }
      if (values.custom_resource?.id) {
        payload = { ...payload, resource: { id: values.custom_resource?.id } };
      } else {
        payload = { ...payload, resource: {} };
      }

      delete payload.custom_article;
      delete payload.custom_video;
      delete payload.custom_lesson;
      delete payload.custom_action_list;
      delete payload.custom_resource;

      if (newTopic) {
        AddAppResource(uuid, "topics", user.idToken, { data: payload }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateTopic(uuid, user.idToken, { data: payload }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/topics"),
    });
  };

  const subTopics = user.app_data.setup.available_sub_topics;

  if (!user.app_data.setup.url_tags) {
    subTables.url_subtopics.listMetadata =
      subTables.url_subtopics.listMetadata.filter(
        (i) => i.name !== "use_url_tag"
      );
    subTables.url_subtopics.detailsMetadata =
      subTables.url_subtopics.detailsMetadata.filter(
        (i) => i.name !== "use_url_tag"
      );
  }

  return (
    <Page links={links} title={`${currentAction} Topic / Listing`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/topics"
      >
        <Column>
          <Input name="name" type="text" label="Name" formik={formik} />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <DataSelector
            name="sub_topics"
            formik={formik}
            label="Sub Topics"
            dataSet={subTopics}
          />
          <VersionizerWrapper minVersion={8.5}>
            <VersionizerWrapper minVersion={8} maxVersion={8.5}>
              <div
                className="italic text-sm text-right font-medium"
                style={{
                  marginTop:
                    parseFloat(user.app_data.about.platform_version) >= 8.5
                      ? 0
                      : -12,
                }}
              >
                Blog Posts and Documents available in app Platform Version 9.0 &
                up and WEB
              </div>
            </VersionizerWrapper>
          </VersionizerWrapper>
          <CheckBox name="listing" label="Directory Listing" formik={formik} />
          {formik.values?.listing && (
            <DataSelector
              name="directories"
              formik={formik}
              label="Which Directory?"
              resourceEndpoint="directories"
              withLegend={(user?.app_data?.setup?.enterprise || user?.app_data?.setup?.reseller) && formik.values.enterprise}
              filterOptions={((!user?.app_data?.setup?.enterprise && !user?.app_data?.setup?.reseller) || !formik.values.enterprise) ? (values) => values.filter((a) => !a.enterprise): (values) => values}
            />
          )}
          <CheckBox name="active" label="Active" formik={formik} />
          <div className="flex-1 w-full">
            <Input
              name="sort_order"
              type="number"
              label="Sort Order"
              formik={formik}
            />
          </div>
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
        </Column>
        <Column>
          <div className="font-bold mb-4">App Only- Featured Content</div>
          <DataSelector
            name="custom_video"
            formik={formik}
            label="Video"
            dataSet={videoValues.map((a) => ({
              id: a.id,
              name: a.title,
            }))}
            single
            singleSelectOnly
            withSearch
          />
          {!isEmpty(formik.values.custom_video) && (
            <Input
              name="custom_video_text"
              type="text"
              label="Custom Video Text"
              formik={formik}
            />
          )}
          <DataSelector
            name="custom_article"
            formik={formik}
            label="Article"
            dataSet={articleValues.map((a) => ({
              id: a.id,
              name: a.title,
            }))}
            single
            singleSelectOnly
            withSearch
          />
          {!isEmpty(formik.values.custom_article) && (
            <Input
              name="custom_article_text"
              type="text"
              label="Custom Article Text"
              formik={formik}
            />
          )}
          <DataSelector
            name="custom_lesson"
            formik={formik}
            label="Lesson"
            dataSet={lessons.map((a) => ({
              id: a.id,
              name: a.title,
            }))}
            single
            singleSelectOnly
            withSearch
          />
          {!isEmpty(formik.values.custom_lesson) && (
            <Input
              name="custom_lesson_text"
              type="text"
              label="Custom Lesson Text"
              formik={formik}
            />
          )}
          <VersionizerWrapper minVersion={9}>
            <>
              <DataSelector
                name="custom_action_list"
                formik={formik}
                label="Action List"
                resourceEndpoint="content/action_lists"
                single
                singleSelectOnly
                withSearch
                keyOption="id"
              />
              {!isEmpty(formik.values.custom_action_list) && (
                <Input
                  name="custom_action_list_text"
                  type="text"
                  label="Custom Action List Text"
                  formik={formik}
                />
              )}
              <DataSelector
                name="custom_resource"
                keyOption="id"
                formik={formik}
                label="Resource"
                resourceEndpoint="content/resources"
                single
                singleSelectOnly
                withSearch
              />
              {!isEmpty(formik.values.custom_resource) && (
                <Input
                  name="custom_resource_text"
                  type="text"
                  label="Custom Resource Text"
                  formik={formik}
                />
              )}
              {showBusiness ? (
                  <>
                    <CheckBox name="enterprise" label="Business Listing" formik={formik} />
                    {
                      formik.values?.enterprise ? (
                        <DataSelector
                          name="enterprise_app"
                          formik={formik}
                          label="Which Business?"
                          resourceEndpoint="children"
                          filterOptions={(options) => {
                            return options.filter((a) => a.active );
                          }}
                          single  
                          singleSelectOnly
                          withSearch
                        />
                      ) : null
                    }
                  </>
                ) : null
              }
            </>
          </VersionizerWrapper>
        </Column>

        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={contentConfig(contentType)}
                  subContentType={key}
                  labels={subTables[key].label}
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={contentType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}
      </EntryForm>
    </Page>
  );
};

export default TopicDetail;
