const articleConfig = {
  label: "ActionJournal",
  resourceEndpoint: "journals",
  listMetadata: [
    "sort_order",
    "title",
    "featured",
    "topics",
    "release_date",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title", "description"],
  detailsMetadata: [
    {
      name: "title",
      label: "Title",
      validations: [{ type: "max", params: [70, "Maximum 70 Characters"] }],
    },
    { name: "description", label: "Description" },
    { name: "audio_url", label: "Audio URL" },
    { name: "image_url", label: "Image URL" },
    {
      name: "question_1",
      label: "Journal 1",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "question_2", label: "Journal 2" },
    { name: "question_3", label: "Journal 3" },
    { name: "question_4", label: "Journal 4" },
    { name: "question_5", label: "Journal 5" },
    { name: "release_date", label: "Release Date" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "sort_order", label: "Sort Order" },
    { name: "featured", label: "Featured?" },
    { name: "topics", label: "Topics/Listings" },
    { name: "tags", label: "Tags" },
  ],
  formLayout: {
    columns: [
      [
        "title",
        "image_url",
        "audio_url",
        "description",
        "question_1",
        "question_2",
        "question_3",
        "question_4",
        "question_5",
      ],
      [
        "release_date",
        "action_bar_text",
        "action_bar_url",
        "featured",
        "topics",
        "sort_order",
        "tags",
      ],
    ],
  },
};

export default articleConfig;
