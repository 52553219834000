const documentsConfig = {
  label: "Document",
  resourceEndpoint: "documents",
  listMetadata: [
    "sort_order",
    "title",
    "topics",
    "release_date",
    "tags",
    "document_url",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "title", label: "Name"},
    { name: "image_url", label: "Image URL"},
    { name: "document_url", label: "Media URL"},
    { name: "description", label: "Description" },
    { name: "topics", label: "Topics/Listings"},
    { name: "tags", label: "Tags"},
    { name: "release_date", label: "Release Date", format: "DD-MMM-YYYY"},
    { name: "sort_order", label: "Sort Order"},
  ],
  formLayout: {
    columns: [
      [
        "title",
        "image_url",
        "document_url",
        "description",
      ],
      [
        "topics",
        "tags",
        "release_date",
        "sort_order",
      ],
    ],
  },
}

export default documentsConfig;
