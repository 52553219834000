import React, { useEffect, useState } from "react";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { useUserProvider } from "../../providers/UserProvider";
import { useModalProvider } from "../../providers/ModalProvider";
import { useLoaderProvider } from "../../providers/LoaderProvider";
import { formatDataByKey } from "./OnboardingBuildAndSubmit";
import { UpdateApp } from "../../services/api/app";
import responseProcessor from "../../lib/responseProcessor";

const BuildAndSubmitStatusDropdown = ({
  name,
  options,
  style = { width: 120 }
}) => {
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    setCurrentValue(onboardingData?.[formatDataByKey[name]])
  }, [onboardingData, name])

  const handleOnChange = (evt) => {
    const { value } = evt.target

    const payload = {
      onboarding: {
        ...onboardingData,
        [formatDataByKey[name]]: value
      }
    }

    doSetShowLoadingModal(true);

    if (value) {
      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess: () => {
              setOnboardingData(payload?.onboarding);
              setCurrentValue(value);
              doSetShowLoadingModal(false);
            }
          })
      );
    }
  }

  return (
    <div className="flex flex-row flex-end mb-5 dropdown-select" style={style}>
      <select name={name} value={currentValue} onChange={handleOnChange}>
        {
          options.map((option) => (
            <option key={option.value} value={option.value} label={option.label} disabled={!option.value} />
          ))
        }
      </select>
    </div>
  )
}

export default BuildAndSubmitStatusDropdown;