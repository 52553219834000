import { useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { mergeArray } from "../lib/arrayTools";

import { DeleteCourseLesson } from "../services/api/course_lesson";

import DeleteItem from "../components/modal_contents/DeleteItem";

const CourseLessonList = ({ courseId, moduleId, lessonValues, resetList }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    setData({
      loading: false,
      data: lessonValues,
      count: lessonValues.length,
    });
  };

  useEffect(() => {
    loadData();
  }, [lessonValues]);

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "sort_order",
      label: "Sort Order",
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      name: "drip_sequence",
      label: "Drip Sequence",
      parser: (data) => data.drip_sequence,
    },
    {
      name: "title",
      label: "Title",
      parser: (data) => data.title,
    },
    {
      name: "description",
      label: "Description",
      parser: (data) => data.description,
      ellipsis: true,
    },
    {
      name: "release_date",
      label: "Release Date",
      parser: (data) => {
        const momentDate = moment(data.release_date);
        if (momentDate.isValid()) {
          return momentDate.format("DD-MMM-YYYY");
        }
        return "";
      },
    },
    {
      name: "tags",
      label: "Tags",
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.tags, "name")}</span>
      ),
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={() =>
          history.push(`/courses/${courseId}/modules/${moduleId}/lessons/new`)
        }
      >
        Add Course Lesson
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/courses/${courseId}/modules/${moduleId}/lessons/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteCourseLesson(id, user.idToken);
    resetList();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Course Lesson"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <div className="bg-white px-12 p-5 mt-5 ttnk-table-container">
      <div className="grid grid-flow-col grid-cols-2 gap-4 mt-5 mb-12">
        <h1 className="ttnk-page__title m-0">Course Lessons</h1>
        <div className="flex justify-end">
          <AddButton />
        </div>
      </div>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        contentType="courseLessonList"
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["title", "creator"]}
        removeContainerStyle={true}
      />
    </div>
  );
};

export default CourseLessonList;
