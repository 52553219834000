import { useCallback, useEffect, useState } from "react";
import {
  EntryForm,
  Input,
  CheckBox,
  InputReadonly,
} from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp, GetAllAvailableApps, UpdateApp, DisableApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import * as Yup from "yup";
import useLocalStorage, { useSetLocalState } from "../lib/useLocalStorage";
import { Radio } from "antd";
import { Select } from "../components/EntryForm";
import DataSelector from "../components/DataSelector";
import VersionizerWrapper from "../components/Versionizer";
import moment from "moment";
import OnboardingWizardConfirmationModal from "../components/modal_contents/OnboardingWizardConfirmationModal";
import DeleteItem from "../components/modal_contents/DeleteItem";

const defaultLimits = {
  action_lists: {
    key: "action_lists",
    label: "ActionLists",
    limit: 1,
  },
  courses: {
    key: "courses",
    label: "Courses",
    limit: 1,
  },
  directories: {
    key: "directories",
    label: "Directories",
    limit: 3,
  },
  topics: {
    key: "topics",
    label: "Topics",
    limit: 8,
  },
  tags: {
    key: "tags",
    label: "Tags",
    limit: 4,
  },
  tips: {
    key: "tips",
    label: "ACTIONBLOG",
    limit: 2,
  },
  videos: {
    key: "videos",
    label: "Videos",
    limit: 1,
  },
  articles: {
    key: "articles",
    label: "Articles",
    limit: 2,
  },
  audios: {
    key: "audios",
    label: "Audio",
    limit: 2,
  },
  lessons: {
    key: "lessons",
    label: "Lessons",
    limit: 2,
  },
  journals: {
    key: "journals",
    label: "Journals",
    limit: 2,
  },
  questions: {
    key: "questions",
    label: "Q&A",
    limit: 2,
  },
  galleries: {
    key: "galleries",
    label: "Galleries",
    limit: 2,
  },
  resources: {
    key: "resources",
    label: "Resources",
    limit: 2,
  },
  products: {
    key: "products",
    label: "Products",
    limit: 2,
  },
  profiles: {
    key: "profiles",
    label: "Profiles",
    limit: 2,
  },
  offers: {
    key: "offers",
    label: "Unlock Code Messages",
    limit: 2,
  },
  documents: {
    key: "documents",
    label: "Documents",
    limit: 2,
  },
  action_forms: {
    key: "action_forms",
    label: "Action Forms",
    limit: 1,
  },
};

const AppSetup = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    url_tags: false,
    virtual_events: false,
    virtual_events_label: "",
    summits: false,
    summits_label: "",
    test: false,
    premium_lite: false,
    premium_plus: false,
    lite_unlock_code: "",
    web: false,
    enterprise: false,
    biz_plus: false,
    app_status: null,
    free: false,
    review: false,
    podcasts: false,
    podcasts_label: "",
    aboutApp: {},
    name: "",
    app_type: "",
    fcm_project_id: "",
    fcm_key: "",
    push_notification_app_id: "",
    push_notification_app_key: "",
    parents: null,
  });

  const setLiteUnlockCode = useSetLocalState("lite");

  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [aboutApp, setAboutApp] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hideNotificationId, setHideNotificationId] = useState(false);
  // const [savedAccountType, setSavedAccountType] = useState('')

  const { user, setUser, setShouldFetchPNData } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();

  const [currentSelectedApp, setCurrentSelectedApp] = useLocalStorage(
    "currentApp",
    null
  );

  const getData = useCallback((shouldUpdate = false) => {
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const app_setup = res.data.setup;
        const app_data = res.data;
        const app_about = res.data.about;

        setLiteUnlockCode(app_setup.lite_unlock_code);
        setHideNotificationId(
          app_setup?.free && !!app_about.push_notification_app_id
        );
        setAboutApp(res.data.about);

        delete app_data.member_content_message;

        const isNotCreatorOverride = app_data.onboarding.switched === null
        const superAdminOverrideSetting = app_setup.onboarding_wizard

        setInitialValues({
          url_tags: app_setup.url_tags,
          virtual_events: app_setup.virtual_events,
          virtual_events_label: app_setup.virtual_events_label,
          summits: app_setup.summits,
          summits_label: app_setup.summits_label,
          test: app_setup?.test || false,
          premium_lite: app_setup.premium_lite,
          premium_plus: app_setup.premium_plus,
          lite_unlock_code: app_setup.lite_unlock_code,
          enterprise: app_setup.enterprise,
          web: app_setup.web,
          free: app_setup.free || false,
          review: app_setup.review || false,
          podcasts: app_setup.podcasts || false,
          podcasts_label: app_setup.podcasts_label,
          account_type: app_setup.account_type,
          app_status:
            app_setup.biz_plus === false ? "Disabled" : app_setup.app_status,
          biz_plus: app_setup.biz_plus || false,
          parents: app_data.parents,
          orphan: app_data.orphan,
          app_id: app_data.id,
          app_token: app_data.app_token,
          name: app_data.name,
          fcm_project_id: app_data.fcm_project_id,
          fcm_key: app_data.fcm_key,
          onboarding_wizard: isNotCreatorOverride ? superAdminOverrideSetting : !app_data.onboarding.switched,
          limit: {
            action_lists:
              app_setup?.limit?.action_lists ??
              defaultLimits.action_lists.limit,
            courses: app_setup?.limit?.courses ?? defaultLimits.courses.limit,
            directories:
              app_setup?.limit?.directories ?? defaultLimits.directories.limit,
            topics: app_setup?.limit?.topics ?? defaultLimits.topics.limit,
            tags: app_setup?.limit?.tags ?? defaultLimits.tags.limit,
            tips: app_setup?.limit?.tips ?? defaultLimits.tips.limit,
            videos: app_setup?.limit?.videos ?? defaultLimits.videos.limit,
            articles:
              app_setup?.limit?.articles ?? defaultLimits.articles.limit,
            audios: app_setup?.limit?.audios ?? defaultLimits.audios.limit,
            lessons: app_setup?.limit?.lessons ?? defaultLimits.lessons.limit,
            journals:
              app_setup?.limit?.journals ?? defaultLimits.journals.limit,
            questions:
              app_setup?.limit?.questions ?? defaultLimits.questions.limit,
            galleries:
              app_setup?.limit?.galleries ?? defaultLimits.galleries.limit,
            resources:
              app_setup?.limit?.resources ?? defaultLimits.resources.limit,
            products:
              app_setup?.limit?.products ?? defaultLimits.products.limit,
            profiles:
              app_setup?.limit?.profiles ?? defaultLimits.profiles.limit,
            offers: app_setup?.limit?.offers ?? defaultLimits.offers.limit,
            documents:
              app_setup?.limit?.documents ?? defaultLimits.documents.limit,
            action_forms:
              app_setup?.limit?.action_forms ??
              defaultLimits.action_forms.limit,
          },
          aboutApp: {
            platform_version: app_about.platform_version || "8.5",
            delete_url: app_about.delete_url,
            push_notification_app_id: app_about.push_notification_app_id,
            push_notification_app_key: app_about.push_notification_app_key,
          },
        });

        setUser((prev) => {
          return { ...prev, app_data };
        });

        if (shouldUpdate) {
          const newUser = {
            ...user,
            app_data: {
              ...app_data,
              setup: app_setup,
            },
          };

          setUser(newUser);
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsDataLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let links = [
    { name: "Home", url: "/" },
    { name: "ACCOUNT SETUP", url: "/app/setup" },
  ];

  let schema = {
    url_tags: Yup.boolean().nullable(),
    virtual_events: Yup.boolean().nullable(),
    virtual_events_label: validations.str_notreq_20,
    summits: Yup.boolean().nullable(),
    summits_label: validations.str_notreq_20,
    premium_lite: Yup.boolean().nullable(),
    premium_plus: Yup.boolean().nullable(),
    test: Yup.boolean().nullable(),
    enterprise: Yup.boolean().nullable(),
    web: Yup.boolean().nullable(),
    free: Yup.boolean().nullable(),
    review: Yup.boolean().nullable(),
    podcasts: Yup.boolean().nullable(),
    biz_plus: Yup.boolean().nullable(),
    orphan: Yup.boolean().nullable(),
    onboarding_wizard: Yup.boolean().nullable(),
    podcasts_label: validations.str_notreq_20,
    account_type: validations.str_notreq_20,
    app_status: validations.str_notreq_20,
    name: validations.str_req_50,
    parents: Yup.object({
      id: Yup.string().nullable(),
      name: Yup.string().nullable(),
    }).nullable(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      delete values.lite_unlock_code;
      const payload = { setup: values, parents: values.parents };
      payload.fcm_project_id = values.fcm_project_id;
      payload.fcm_key = values.fcm_key;
      payload.name = values.name;
      payload.about = {
        ...aboutApp,
        platform_version: values.aboutApp.platform_version,
        delete_url: values.aboutApp.delete_url,
        push_notification_app_id: values.aboutApp.push_notification_app_id,
        push_notification_app_key: values.aboutApp.push_notification_app_key,
      };

      // If superadmin toggle is different from creator override, allow superadmin to override
      if (values.onboarding_wizard === user.app_data.onboarding.switched) {
        payload.setup.onboarding_wizard = values.onboarding_wizard
      }

      // Don't modify account_type when Test is enabled
      if (values.test) {
        delete payload.setup.account_type;
      }

      if (values.enterprise === true) payload.parents = [];

      if (values.biz_plus === false) {
        delete payload.setup.app_status;
      }

      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess,
          }).then(() => {
            setShouldFetchPNData(true);
            user.app_data.setup.test = values.test;
            user.app_data.setup.free = values.free;
            user.app_data.setup.biz_plus = values.biz_plus;
            user.app_data.setup.podcasts = values.podcasts;
          })
      );
    },
  });


  const onSuccess = () => {
    setSaved(true);
    setIsLoading(true);
    getData(true);
  };

  const SavedLabel = () => <span className="text-green-400">Saved</span>;

  const setToAvailableApp = () => {
    GetAllAvailableApps(user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const app = res.data[0];
        setCurrentSelectedApp(app);

        GetApp({ idToken: user.idToken, currentApp: () => app.id })
          .then((res) => res.json())
          .then((res) => {
            let app_data = {};

            app_data = res.data;

            delete app_data.member_content_message;
            delete app_data.id;
            delete app_data.name;

            setUser((prev) => ({ ...prev, app, app_data }));
          });
      });
  };

  const showDeleteModal = useCallback(async () => {
    showModal(
      <DeleteItem
        itemId={user.currentApp()}
        itemLabel={`app: ${user?.app?.name}`}
        deleteAction="remove"
        delBtnLabel="Remove"
        onDelete={async (appId) => {
          setIsLoading(true);
          const response = await DisableApp(appId, user.idToken);
          responseProcessor({
            response,
            showModal,
            onSuccess: () => {
              setIsLoading(false);
              setCurrentSelectedApp(null);
              setToAvailableApp();
              window.location.replace(`/change_application`);
            },
          });
        }}
        onClose={closeModal}
      />
    );
    setIsLoading(false);
  }, [user, setCurrentSelectedApp]);

  return (
    <Page links={links} title="ACCOUNT SETUP">
      <div className="flex flex-row items-center justify-end">
        <div>
          <span>Created on: </span>
          {moment(user?.app_data?.created_at).format("DD-MMM-YYYY")}
        </div>
      </div>
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        deleteHandler={showDeleteModal}
        deleteLabel="Remove App"
        deleteBtnType="alert"
        formik={formik}
        savedLabel={saved && !formik.dirty && SavedLabel()}
      >
        <div className="w-full grid grid-cols-2">
          <div className="inline-flex grid-cols-2 w-full">
            <div className="flex mb-2">
              <span className="w-32 text-lg">Account Type</span>
              <Radio.Group
                onChange={(e) => {
                  formik.setFieldValue("account_type", e.target.value);
                }}
                value={formik.values.account_type}
                className="flex flex-col"
              >
                <Radio value="test">Test</Radio>
                <Radio value="infinity">Infinity</Radio>
                <Radio value="subscriber">Subscriber</Radio>
              </Radio.Group>
            </div>
            <div className="flex flex-1 justify-between grid-cols-3 pl-9 pr-14">
              <div className="flex">
                <Select
                  name="aboutApp.platform_version"
                  label="Platform Version"
                  formik={formik}
                >
                  <option value="8.0">8.0</option>
                  <option value="8.5">8.5</option>
                  <option value="9.0">9.0</option>
                </Select>
              </div>
              <div>
                <CheckBox
                  name="review"
                  label="App Review"
                  formik={formik}
                  onChange={(checked) => {
                    formik.setFieldValue("review", checked);
                  }}
                />
              </div>
              <div className="flex">
                <CheckBox
                  name="url_tags"
                  label="Use URL Tags"
                  formik={formik}
                />
              </div>
            </div>
          </div>
          <div>
            <Input
              name="name"
              type="text"
              label="Name of App"
              formik={formik}
            />
          </div>
        </div>
        <div className="w-full grid grid-cols-2">
          <div>
            <div className="flex mb-2">
              <span className="w-24 mr-8">
                <CheckBox
                  name="biz_plus"
                  label="Biz+"
                  formik={formik}
                  onChange={(checked) => {
                    if (!checked) {
                      formik.setFieldValue("app_status", "Disabled");
                      formik.setFieldValue("enterprise", false);
                    } else {
                      formik.setFieldValue(
                        "app_status",
                        checked ? "In Progress" : user.app_data.setup.app_status
                      );
                    }
                    formik.setFieldValue("biz_plus", checked);
                  }}
                />
              </span>
              <div className="mt-10">
                <Radio.Group
                  onChange={(e) => {
                    formik.setFieldValue("app_status", e.target.value);
                  }}
                  className="flex flex-col"
                  value={formik.values.app_status}
                >
                  <Radio value="Disabled" disabled={!formik.values.biz_plus}>
                    Disabled
                  </Radio>
                  <Radio value="In Progress" disabled={!formik.values.biz_plus}>
                    In Progress
                  </Radio>
                  <Radio value="Launched" disabled={!formik.values.biz_plus}>
                    Launched
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            {formik.values.biz_plus && (
              <div className="flex">
                <span className="w-32 mr-8">
                  <CheckBox
                    name="enterprise"
                    label="Enterprise"
                    formik={formik}
                  />
                </span>
              </div>
            )}
            {!formik?.values?.enterprise && isDataLoaded && (
              <div className="flex pr-14">
                <DataSelector
                  name="parents"
                  formik={formik}
                  label="Which Parent App?"
                  resourceEndpoint="children"
                  endpoint="allApps"
                  type="enterprise"
                  filterOptions={(options) => {
                    return options.filter((a) => (a.enterprise || a.reseller) && a.id !== user.app.id);
                  }}
                />
              </div>
            )}
            <div className="flex">
              <span className="mr-8">
                <CheckBox
                  name="onboarding_wizard"
                  label="Onboarding Wizard"
                  subLabel={ user.app_data.onboarding.switched ? <span className="text-red-600 pl-2 italic text-sm">
                    Creator has overridden this wizard
                  </span> : <></>}
                  formik={formik}
                  onChange={(checked) => {
                    showModal(<OnboardingWizardConfirmationModal
                      handleOnCancel={() => formik.setFieldValue("onboarding_wizard", user.app_data.onboarding.wizard)}
                      isToggledOn={checked}
                    />)
                  }}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="mb-2.5">
              <InputReadonly name="app_id" label="App ID" formik={formik} />
            </div>
            {!hideNotificationId ? (
              <VersionizerWrapper maxVersion={8.5} minVersion={8}>
                <Input
                  name="aboutApp.push_notification_app_id"
                  type="text"
                  label="Push Notification App ID"
                  formik={formik}
                />
                <Input
                  name="aboutApp.push_notification_app_key"
                  type="text"
                  label="Push Notification App Key"
                  formik={formik}
                />
              </VersionizerWrapper>
            ) : null}
            <VersionizerWrapper minVersion={9}>
              <div>
                <InputReadonly
                  name="app_token"
                  label="App Token"
                  formik={formik}
                  tooltip="to be used for mobile app-authenticated requests"
                />
              </div>
              <div>
                <Input
                  name="fcm_project_id"
                  type="text"
                  label="FCM Project ID"
                  formik={formik}
                />
              </div>
              <div>
                <Input
                  name="fcm_key"
                  type="text"
                  label="FCM Key"
                  formik={formik}
                />
              </div>
            </VersionizerWrapper>
            <div>
              <InputReadonly
                name="aboutApp.delete_url"
                label="Delete App URL"
                formik={formik}
                tooltip="to be used for Google Play’s app account deletion requirements"
              />
            </div>
          </div>
        </div>
      </EntryForm>
    </Page>
  );
};

export default AppSetup;
