import React from "react"
import { useModalProvider } from "../../providers/ModalProvider";

const OnboardingWizardConfirmationModal = ({
  handleOnCancel,
  isToggledOn = false
}) => {
  const { closeModal } = useModalProvider();

  const onConfirm = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;
    closeModal()
  };

  const onCancel = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;

    handleOnCancel();
    closeModal()
  };

  return (
    <div>
      <div style={{ width: 400 }} className="mb-4 text-center">
        {
          isToggledOn ? (
            <p>
              Toggling this ON will update the Onboarding Status to <strong><em>In Progress</em></strong> and will force the user back to the Onboarding page.
            </p>
          ) : (
            <p> Toggling this OFF will allow the creator to access the full Creator Portal </p>
          )
        }

        <p className="pt-4"> Are you sure? </p>
      </div>
      <div className='flex justify-center gap-6 pt-6'>
        <button onClick={onConfirm} className="ttnk-button">
          Confirm
        </button>
        <button onClick={onCancel} className="ttnk-button ">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default OnboardingWizardConfirmationModal