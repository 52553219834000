import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "../../EntryForm";
import { contentList } from "./contentList";
import DataSelector from "../../DataSelector";
import { useUserProvider } from "../../../providers/UserProvider";

const ContentDropdowns = ({
  formik,
  isDataLoaded,
  content,
  fetchSendTo
}) => {
  const [formContent, setFormContent] = useState(() => formik?.values?.form_content ?? null)
  const [isContentChanged, setIsContentChanged] = useState(false)
  const { user } = useUserProvider();
  
  const options = useMemo(() => {
    if (!user?.app_data?.setup?.virtual_events && parseFloat(user?.app_data?.about?.platform_version) >= 9) {
      return content.filter(a => a.value !== "virtualevent");
    }

    return content;
  }, [content, user]);

  const onContentListChange = (e) => {
    try {
      // get Content Label
      var index = e.nativeEvent.target.selectedIndex;
      setFormContent(e.nativeEvent.target[index].text)
    } catch (error) {
      // console.log({ error })
    }
  };

  const resourceEndpoint = useMemo(() => {
    const content = contentList.find(item => item.name === formContent)

    return content?.resourceEndpoint
  }, [formContent])

  const handleContentChange = (e) => {
    formik.setFieldValue("send_to_tags", [])
    setIsContentChanged(true)
  }

  const handleFetchSendTo = useCallback(() => {
    if (formik.values.form_content_uuid) {
      fetchSendTo(formik.values)
      setIsContentChanged(false)
    }
  }, [formik.values.form_content_uuid])

  useEffect(() => {
    if (isContentChanged) {
      handleFetchSendTo()
    }
  }, [isContentChanged])

  if (!isDataLoaded) return null

  return (
    <>
      <div className="w-1/2 pr-8">
        <Select
          name="form_content_type"
          label=""
          formik={formik}
          onChange={onContentListChange}
        >
          <option value="">- Content -</option>
          {options?.map((item, index) => (
            <option
              key={`content-${String(index)}`}
              value={item.value}
            >
              {item.name}
            </option>
          ))}
        </Select>
      </div>

      {
        resourceEndpoint ? (
          <div className="w-1/2">
            <DataSelector
              name="form_content_uuid"
              label=""
              formik={formik}
              resourceEndpoint={resourceEndpoint}
              singleSelectOnly
              noBottomMargin
              sortByName={true}
              onChange={handleContentChange}
            />
          </div>
        ) : (
          <div className="w-1/2">
            <Select name="form_content_uuid" label="" formik={formik}>
              <option value={null}>- Content Title -</option>
            </Select>
          </div>
        )
      }
    </>
  );
};

export default ContentDropdowns;
