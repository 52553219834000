import * as React from 'react'
import { useContext, useState } from 'react'

export const UserDataContext = React.createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [shouldFetchList, setShouldFetchList] = useState(false)
  const [shouldFetchPNData, setShouldFetchPNData] = useState(false)

  return(
    <UserDataContext.Provider value={ {
      user,
      setUser,
      shouldFetchList,
      setShouldFetchList,
      shouldFetchPNData,
      setShouldFetchPNData
    } }>
      {children}
    </UserDataContext.Provider>
  )
}

export const useUserProvider = () => {
  const context = useContext(UserDataContext)

  if (context === undefined) {
    throw new Error('Invalid use of useDataContext')
  }

  return context
}
