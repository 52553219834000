import { useState } from 'react';
import { faChevronDown, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionHeader from './AccordionHeader';
import AccordionBody from './AccordionBody';
import { useOnboardingProvider } from '../../providers/OnboardingProvider';
import { GET_STARTED, LAUNCHED, SAVED, SUBMITTED } from '../../lib/onboarding_config/config';

const CollapsedNode = ({
  title,
  hasDetails,
  isExpanded,
  isSubAccordion,
  status,
  hasEditLabel,
  isCompleted = false,
  isDisabled = false,
  setIsExpanded
}) => {
  const { onboardingData } = useOnboardingProvider()

  const isShowEdit = () => {
    const preCondition = hasEditLabel && !isExpanded

    switch(title) {
      case "App Design":
      case "Essentials":
        return preCondition && ![SAVED, SUBMITTED, GET_STARTED].includes(onboardingData?.progress?.design?.status)
      case "App Store Info":
        return preCondition && ![SAVED, SUBMITTED, GET_STARTED].includes(onboardingData?.progress?.store?.status)
      case "Home/Profile":
        return preCondition && ![SAVED, SUBMITTED, GET_STARTED].includes(onboardingData?.progress?.home_profile?.status)
      case "Build and Submit (admin)":
        return preCondition && ![SAVED, SUBMITTED, GET_STARTED, LAUNCHED].includes(onboardingData?.progress?.build_and_submit?.status)
      default:
        return preCondition && onboardingData?.progress?.content?.overall?.percentage < 100 && onboardingData?.progress?.content?.overall?.percentage > 0
    }
  }

  return (
    <div className="w-full flex justify-between items-center">
      <span className={`line-clamp-2 text-xl font-bold ${isDisabled ? "text-darkGray" : ""} ${isCompleted ? "text-aeblue-default" : ""} ${isSubAccordion ? 'text-2xl' : 'text-3xl'}`}>
        {title}
        {
          isShowEdit() ? (
            <button className='text-aeblue-default font-bold pl-2'>
              (edit)
            </button>
          ) : null
        }
      </span>
      <div className="flex items-center gap-3">
        <div>{status}</div>
        <div role="presentation" className={`cursor-pointer ${hasDetails && !isDisabled ? "visible" : "hidden"}`}>
          <FontAwesomeIcon
            icon={isSubAccordion ? faCaretDown : faChevronDown}
            size={isSubAccordion ? 'xl' : '2xl'}
            color="#2F91CF"
            className={`transition-all duration-500 ${
              isExpanded ? 'is-acc-open' : ''
            }`}
          />
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ item, value, isSubAccordion, onChange, index, indexLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleChange = async (val) => {
    await onChange(item.id, val);
  };

  return (
    <AccordionHeader
      id={item.id}
      isExpandable={item?.has_details && !item?.is_disabled}
      isSubAccordion={isSubAccordion}
      isChecked={value}
      setIsChecked={handleChange}
      index={index}
      indexLength={indexLength}
      collapsedNode={
        <CollapsedNode
          title={item.label}
          hasDetails={item?.has_details ?? false}
          isExpanded={isExpanded}
          status={item.status}
          isSubAccordion={isSubAccordion}
          isCompleted={item.is_complete ?? false}
          isDisabled={item?.is_disabled ?? false}
          hasEditLabel={item.hasEditLabel}
          setIsExpanded={setIsExpanded}
        />
      }
      expandedNode={
        <AccordionBody
          data={item}
          isSubAccordion={isSubAccordion}
          children={item.body}
          isExpandable={item?.has_details ?? false}
          index={index}
          indexLength={indexLength}
        />
      }
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!item?.editable || item?.is_disabled}
    />
  );
};

export default Accordion;
