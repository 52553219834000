/* eslint-disable react-hooks/exhaustive-deps */
import { Upload, Radio } from "antd";
import Page from "../components/Page";
import UploadFile from "../assets/icons/upload-file.svg";
import { Column } from "../components/EntryForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { ExportTablev2, GetTables, ImportTable, BulkImport } from "../services/api/app";
import { useLocalValue, useSetLocalState } from "../lib/useLocalStorage";
import ErrorModal from "../components/modal_contents/ErrorModal";

const { Dragger } = Upload;

const links = [
  { name: "Home", url: "/" },
  { name: "Import/Export", url: "/import-export" },
];

const Records = () => {
  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();

  const setTables = useSetLocalState("tables");
  const [action, setAction] = useState("IMPORT");
  const [file, setFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [exportTable, setExportTable] = useState([]);

  const tableName = file?.name.split(".")[0];

	const [bulkImportFile, setBulkImport] = useState(null)
	const [isBulkImporting, setIsBulkImporting] = useState(false)
  const [importError, setImportError] = useState(null)
	const bulkImportName = bulkImportFile?.name?.split('.')[0]

	useEffect(async () => {
		const req = await GetTables(user.idToken)
		const res = await req.json();
		const tables = res.data;
		setTables(tables)
    setImportError(null)

    return () => {
      setImportError(null)
    }
	}, [])

  const handleExport = useCallback(async () => {
    const exportTableValue = exportTable[0].value;
    if (!exportTableValue) return;

    setIsExporting(true);

    let request;

    try {
      request = await ExportTablev2(
        user.currentApp(),
        user.idToken,
        exportTableValue
      );
    } catch (err) {
      if (err?.message?.includes("timeout")) {
        showModal(
          <RequestTimeout
            onClick={() => {
              closeModal();
              setIsExporting(false);
            }}
          />
        );
      }
      throw err;
    }

    const csvContent = request.data;

    setIsExporting(false);

    if (request.status !== 200) {
      showModal(<ErrorModal status={request.status} />);
      return;
    }

    const blob = new Blob([csvContent], { type: "text/csv" });
    const encodedUri = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${exportTableValue}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [user, exportTable]);

  const handleOnSelectExportTable = useCallback((selected = []) => {
    if (!selected?.[0]?.value) {
      setExportTable([]);
      return;
    }
    setExportTable(selected);
  }, []);

  const ImportingModal = useMemo(
    () => (
      <div>
        <p>
          The file is now being imported. You will receive an email when
          importing is complete.
        </p>
        <div className="flex gap-6 w-full items-center justify-center items-center mt-4">
          <Button onClick={closeModal}>Ok</Button>
        </div>
      </div>
    ),
    [closeModal]
  );

  const closeCurrentModal = () => {
    setImportError(null)
    closeModal()
  }

  const ImportingModalError = useMemo(
    () => (
      <div>
        <p>
          { importError }
        </p>
        <div className="flex gap-6 w-full justify-center items-center mt-4">
          <Button onClick={closeCurrentModal}>Close</Button>
        </div>
      </div>
    ),
    [closeModal, importError]
  );

  const handleImport = useCallback(async () => {
    setIsImporting(true);
    const response = await ImportTable(user.currentApp(), user.idToken, {
      file: file.originFileObj,
      table: tableName,
    });
    setImportError(response?.error?.detail)
    setIsImporting(false);
    
    if (response?.status === 200 || response?.ok) {
      showModal(ImportingModal)
    }    

    setFile(null);
    document
      .querySelectorAll('[title="Remove file"]')
      .forEach((el) => el?.click());
  }, [showModal, file]);

	const handleBulkImport = useCallback(async () => {
		setIsBulkImporting(true)

		const response = await BulkImport(user.currentApp(), user.idToken, {
			file: bulkImportFile.originFileObj,
			table: bulkImportName
		})

    setImportError(response?.error?.detail)
		setIsBulkImporting(false)

    if (response?.status === 200 || response?.ok) {
      showModal(ImportingModal)
    }

		setBulkImport(null)
		document.querySelectorAll('[title="Remove file"]').forEach(el => el?.click())
	}, [showModal, bulkImportFile])

  useEffect(() => {
    if (importError) {
      showModal(ImportingModalError)
    }
  }, [importError])

  return (
    <Page links={links} title="Import/Export">
      <div className="bg-white p-5 mt-5 ttnk-table-container">
        <div className="flex flex-row flex-wrap">
          <Column>
            <Radio.Group
              disabled={isExporting || isImporting || isBulkImporting}
              value={action}
              onChange={(e) => {
                setAction(e.target.value)
                setFile(null)
                setExportTable([])
                setBulkImport([])
              }}
            >
              <div className="mb-2 font-bold font-header text-xl">
                What do you want to do?
              </div>
              <div className="flex flex-col gap-2">
                <Radio value="IMPORT">Import</Radio>
                
                <Radio value="EXPORT">
                  Export
                </Radio>

                <Radio value="BULK_IMPORT">
                  Bulk Import
                </Radio>
              </div>
				</Radio.Group>
				</Column>
				<Column>
					{action === "IMPORT" && (
						<ImportForm
							tableName={tableName} 
							setFile={setFile} 
							handleImport={handleImport}
						/>
					)}
					{action === "EXPORT" && (
						<ExportForm 
							isExporting={isExporting}
							exportTable={exportTable}
							handleExport={handleExport} 
							onSelect={handleOnSelectExportTable} 
						/>
					)}
					{action === "BULK_IMPORT" && (
						<BulkImportForm
							tableName={bulkImportName} 
							setBulkImport={setBulkImport} 
							handleImport={handleBulkImport}
						/>
					)}
				</Column>
			</div>
		</div>
		<Notes action={action} />
    </Page>
  );
};

function Notes(props) {
  // action: 'IMPORT' | 'EXPORT' | 'BULK_IMPORT'
  const { action } = props;

  return (
    <div className="px-40 my-20 italic">
      <header>Important Notes:</header>
      {action === "IMPORT" && (
        <>
          <li className="list-none">
            1. Media files that were directly uploaded in this portal will be
            marked as “N/A” in your export. A direct upload means you used the
            drag-and-drop or file selector feature when you uploaded the media
            file. If you import a exported file where you edited an entry marked
            as “N/A”, the system will disregard the edit. To make changes the to
            the media file you have directly uploaded to this portal, go to the
            Update/ Edit Page of said file.
          </li>
          <li className="list-none">
            2. When importing files, all fields must not exceed the maximum
            number of characters allowed by the creator portal
          </li>
          <li className="list-none">
            3. Profile Name in SummitSpeaker can only be edited in the creator
            portal, not through Import
          </li>
          <li className="list-none">
            4. To be able to make changes and import CourseLessonUser, user
            email and course lesson title must exist
          </li>
        </>
      )}

      {action === "EXPORT" && (
        <>
          <li className="list-none">
            1. Blacklisted members will not be included in the export.
          </li>
          <li className="list-none">
            2. Media files that were directly uploaded in this portal will be
            marked as “N/A” in your export. A direct upload means you used the
            drag-and-drop or file selector feature when you uploaded the media
            file. If you import an exported file where you edited an entry
            marked as “N/A”, the system will disregard the edit. To make changes
            the to the media file you have directly uploaded to this portal, go
            to the Update/ Edit Page of said file.
          </li>
        </>
      )}

      {action === "BULK_IMPORT" && (
        <>
          <li className="list-none">
            1. Media files that were directly uploaded in this portal will be
            marked as “N/A” in your export. A direct upload means you used the
            drag-and-drop or file selector feature when you uploaded the media
            file. If you import a exported file where you edited an entry marked
            as “N/A”, the system will disregard the edit. To make changes the to
            the media file you have directly uploaded to this portal, go to the
            Update/ Edit Page of said file.
          </li>
          <li className="list-none">
            2. When importing files, all fields must not exceed the maximum
            number of characters allowed by the creator portal
          </li>
          <li className="list-none">
            3. Profile Name in SummitSpeaker can only be edited in the creator
            portal, not through Import
          </li>
          <li className="list-none">
            4. To be able to make changes and import CourseLessonUser, user
            email and course lesson title must exist
          </li>
        </>
      )}
    </div>
  );
}

function ImportForm(props) {
  const { tableName, setFile, handleImport, isImporting } = props;

  const { showModal, closeModal } = useModalProvider();
  const [fileList, setFileList] = useState([]);
  const tables = useLocalValue("tables");

  const handleChangeFile = useCallback(
    (event) => {
      // validate file name
      const filename = event?.file?.name;

      // checks if filename has pattern of '(n)' - n is a number
      if (filename.match(/\([\d]\)/g)) {
        return showModal(<ErrorModal status="INVALID_FILE_NAME" />);
      }

      // checks if file name is in the table list
      const tempFileName = filename.split(".");
      const filenameWithoutExtension = tempFileName?.[0] || "";
      const fileExtension = tempFileName.pop();

      if (!(tables || [])?.includes(filenameWithoutExtension)) {
        return showModal(<ErrorModal status="INVALID_TABLE" />);
      }

      if (event.fileList.length === 0) {
        setFileList([]);
        return;
      }

      // validate type
      // Add CSV for Windows with MS Excel installed https://christianwood.net/posts/csv-file-upload-validation/
      const validCSVFile = event?.file?.type?.includes("csv") || (fileExtension === 'csv' && event?.file?.type?.includes("application/vnd.ms-excel"))
      if (!validCSVFile) {
        showModal(<ErrorModal status="INVALID_FILE_TYPE" />);
        setFile(null);

        document
          .querySelectorAll('[title="Remove file"]')
          .forEach((el) => el?.click());
        return;
      }

      const { file } = event;
      const fileList =
        event.fileList?.map((fileList) => ({ ...fileList, status: "done" })) ||
        [];

      setFileList(fileList);
      setFile(file);
    },
    [setFile]
  );

  const handleConfirm = useCallback(() => {
    closeModal();
    handleImport();
  }, [handleImport, closeModal]);

  const ImportConfirmation = useMemo(
    () => (
      <div>
        <p>
          You are about to import {tableName}.csv to{" "}
          <strong>{tableName}</strong> table. Please confirm to continue
        </p>
        <div className="flex gap-6 items-center justify-center w-full mt-4">
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button
            onClick={closeModal}
            hideIcon
          >
            Cancel
          </Button>
        </div>
      </div>
    ),
    [tableName, handleConfirm, closeModal]
  );

  const handeClickImport = useCallback(() => {
    showModal(ImportConfirmation);
  }, [showModal, ImportConfirmation]);

  return (
    <div>
      <header className="font-bold font-header text-xl">Import</header>
      <div className="my-4">
        <Dragger
          fileList={fileList}
          multiple={false}
          name="file"
          maxCount={1}
          onChange={handleChangeFile}
          accept="text/csv"
          onRemove={() => setFile(null)}
        >
          <p className="justify-center w-full flex">
            <img src={UploadFile} alt="Upload File" />
          </p>
          <p className="text-lg py-5">
            Drag and drop files here or click to browse your files
          </p>
        </Dragger>
      </div>
      <Button disabled={!tableName} onClick={handeClickImport} type="submit">
        Import
      </Button>
    </div>
  );
}

function BulkImportForm(props) {
	const { tableName, setBulkImport, handleImport } = props

	const { showModal, closeModal } = useModalProvider()
	const [fileList, setFileList] = useState([])
	
	const handleChangeFile = useCallback((event) => {
		if(event.fileList.length === 0) {
			setFileList([])
			return;
		}

		// validate type
		if(!event?.file?.type?.includes('zip')) {
			showModal(<ErrorModal status="INVALID_FILE_TYPE" />)	
			setBulkImport(null)

			document.querySelectorAll('[title="Remove file"]').forEach(el => el?.click())
			return 
		}

		const { file } = event
		const fileList = event.fileList?.map(fileList => ({ ...fileList, status: "done" })) || []

		setFileList(fileList)
		setBulkImport(file)
	}, [setBulkImport])

	const handleConfirm = useCallback(() => {
		closeModal()
		handleImport()
	}, [handleImport, closeModal])

	const ImportConfirmation = useMemo(() => (
		<div>
			<p>You are about to import {tableName}.zip. Please confirm to continue</p>
			<div className="flex gap-6 items-center justify-center w-full mt-4">
				<Button onClick={handleConfirm}>Confirm</Button>
				<Button onClick={closeModal} hideIcon>Cancel</Button>
			</div>
		</div>
	), [tableName, handleConfirm, closeModal])
	
	const handeClickImport = useCallback(() => {
		showModal(ImportConfirmation)
	}, [showModal, ImportConfirmation])
	
	return (
		<div>
			<header className="font-bold font-header text-xl">Import</header>
			<div className="my-4 mb-0">
				<Dragger
					fileList={fileList}
					multiple={false}
					name="file"
					maxCount={1}
					onChange={handleChangeFile}
					accept="zip"
					onRemove={() => setBulkImport(null)}
				>
					<p className="justify-center w-full flex">
						<img src={UploadFile} alt="Upload File" />
					</p>
					<p className="text-lg py-5">
						Drag and drop files here or click to browse your files
					</p>
				</Dragger>
				<p className="my-2 flex items-center justify-end w-full text-red-500 font-bold">IMPORTANT: Use this feature ONLY if you are provided a ZIP file to upload from ACTIONERA</p>
			</div>
			<Button disabled={!tableName} onClick={handeClickImport} type="submit">
				Import
			</Button>

			
		</div>
	)
}

function ExportForm(props) {
  const { onSelect, exportTable, handleExport, isExporting } = props;

  const tables = useLocalValue("tables");

  return (
    <div>
      <header className="font-bold font-header text-xl">Export</header>
      <div className="my-4">
        <Multiselect
          singleSelect
          displayValue="name"
          selectedValues={exportTable}
          placeholder="Select Table"
          onSelect={onSelect}
          options={[
            { name: "Select Table", value: null },
            ...(tables || []).map((tablename) => ({
              name: tablename,
              id: tablename,
              value: tablename,
            })),
          ]}
        />
      </div>
      <div className="flex items-center mt-4">
        <Button
          onClick={handleExport}
          disabled={!exportTable.length || isExporting}
          type="submit"
        >
          Export
        </Button>
        {!!isExporting && (
          <div className="text-red-500 ml-4 font-bold">
            Exporting. This may take a few minutes.
          </div>
        )}
      </div>
    </div>
  );
}

export function Button(props) {
  return (
    <button className="ttnk-button" {...props}>
      {!props.hideIcon && (
        <svg
          height="14px"
          width="14px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -46 417.81333 417"
          className="mr-2"
        >
          <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
        </svg>
      )}
      {props.children}
    </button>
  );
}

function RequestTimeout(props) {
  return (
    <div>
      <div className="mb-4">Request Timed Out. Pls try again shortly</div>
      <button className="ttnk-button" onClick={() => props?.onClick()}>
        Dismiss
      </button>
    </div>
  );
}

export default Records;
