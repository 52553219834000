

import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetActionFormList = async (appId, idToken) => {
    return await callEndpoint(`${process.env.REACT_APP_API}/apps/${appId}/action_forms`, 'GET', idToken)
  }

export const GetAccountListByType = async (type, idToken) => {
    return await callEndpoint(`${process.env.REACT_APP_API}/accounts/${type}`, 'GET', idToken)
}

export const DeleteActionForm = async (user, uuid) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/action_forms/${uuid}`, 'DELETE', user.idToken)
}

export const GetActionForm = async (uuid, idToken) => {
    return await callEndpoint(`${process.env.REACT_APP_API}/action_forms/${uuid}`, 'GET', idToken)
}

export const UpdateActionForm = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/action_forms/${uuid}`, 'PATCH', idToken, payload)
}

export const GetSubmittedForms = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/action_forms/${uuid}/action_form_contents`, 'GET', idToken)
}
