import { useEffect, useState } from "react";
import { EntryForm, Column, Input, TextArea } from '../components/EntryForm'
import Page from '../components/Page'

import { GetApp, UpdateApp } from '../services/api/app'
import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"
import { useLoaderProvider } from '../providers/LoaderProvider'
import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from 'formik'

import * as Yup from 'yup'

const AboutAppMembers = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    member_description: "",
    member_action_bar_text: "",
    member_action_bar_url: "",
    register_description: "",
    register_action_bar_text: "",
    register_action_bar_url: ""
  })

  const [isLoading, setIsLoading] = useState(false)
  const [saved, setSaved] = useState(false)

  const { user } = useUserProvider()

  const { showModal } = useModalProvider()

  const { doSetShowLoadingModal } = useLoaderProvider()

  useEffect(() => {
    setIsLoading(true)
    GetApp(user, user.idToken).then(res => res.json()).then(res => {
      const mcm = res.data.member_content_message
      setInitialValues({
        member_description: mcm.member_description,
        member_action_bar_text: mcm.member_action_bar_text,
        member_action_bar_url: mcm.member_action_bar_url,
        register_description: mcm.register_description,
        register_action_bar_text: mcm.register_action_bar_text,
        register_action_bar_url: mcm.register_action_bar_url
      })
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    doSetShowLoadingModal(isLoading)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Member Screens", url: "/app/about_members" },
  ]

  let schema = {
    member_description: Yup.string().nullable().required('Required'),
    member_action_bar_text: validations.str_req_50,
    member_action_bar_url: Yup.string().nullable().max(255, 'Maximum 255 Characters').required('Required'),
    register_description: Yup.string().nullable().required('Required'),
    register_action_bar_text: validations.str_req_50,
    register_action_bar_url: Yup.string().nullable().max(255, 'Maximum 255 Characters').required('Required'),
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      const payload = { member_content_message: values }
      UpdateApp(user.currentApp(), user.idToken, { data: payload })
        .then(response => responseProcessor({
          response,
          showModal,
          onSuccess,
        }))
    },
  })

  const onSuccess = () => {
    setSaved(true)
    setIsLoading(true)
    GetApp(user, user.idToken).then(res => res.json()).then(res => {
      const mcm = res.data.member_content_message

      setInitialValues({
        member_description: mcm.member_description,
        member_action_bar_text: mcm.member_action_bar_text,
        member_action_bar_url: mcm.member_action_bar_url,
        register_description: mcm.register_description,
        register_action_bar_text: mcm.register_action_bar_text,
        register_action_bar_url: mcm.register_action_bar_url
      })
      setIsLoading(false)
    })
  }

  const SavedLabel = () => (<span className="text-green-400">Saved</span>)

  return (
    <Page links={links} title="Member Screens">
      <EntryForm isLoading={isLoading} submitHandler={ formik.handleSubmit } formik={formik} savedLabel={(saved && !formik.dirty) && SavedLabel()}>
        <Column>
          <TextArea name='member_description' type='text' label='Member Content Message' formik={formik} />
          <Input name='member_action_bar_text' type='text' label='Member Call to Action Text' formik={formik} />
          <Input name='member_action_bar_url' type='text' label='Member Call to Action URL' formik={formik} />
        </Column>
        <Column>
          <TextArea name='register_description' type='text' label='Login/ Register Screen Message' formik={formik} />
          <Input name='register_action_bar_text' type='text' label='About Call to Action Text' formik={formik} />
          <Input name='register_action_bar_url' type='text' label='About Call to Action URL' formik={formik} />
        </Column>
      </EntryForm>
    </Page>
  )
}

export default AboutAppMembers;
