import React, { useMemo, useState } from "react";
import OnboardingHeader from "./OnboardingHeader";
import { useModalProvider } from "../providers/ModalProvider";
import OnboardingAssistanceModal from "./OnboardingAssistanceModal";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { ENTERPRISE } from "../lib/onboarding_config/config";
import OnboardingWizardSwitcher from "./OnboardingWizardSwitcher";
import { useUserProvider } from "../providers/UserProvider";
import { VideoPlayer } from "./VideoPlayer";
import AppInfoModal from "./AppInfoModal";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import { ReactComponent as RocketIcon } from "../assets/icons/rocket-solid.svg";

const progress = [
  { label: "DESIGN", field: "app_designed", completedValue: "done" },
  { label: "REVIEW", field: "content_reviewed", completedValue: "done" },
  { label: "BUILD", field: "app_built", completedValue: "done" },
  { label: "SUBMIT", field: "submitted", completedValue: "both" },
  { label: "LIVE!", field: "liveStatus", completedValue: "launched" },
];

const OnboardingWrapper = ({ children }) => {
  const { showModal } = useModalProvider();
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const [showInfoModal, setShowInfoModal] = useState(false);

  const appLogo = useMemo(() => {
    return user?.app_data?.portal?.logo_url ?? "";
  }, [user?.app_data?.portal?.logo_url]);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#dde7f2",
      }}
    >
      <OnboardingHeader />

      <OnboardingWizardSwitcher className="container mx-auto pt-8" />

      <div
        className="bg-white mx-auto rounded-t-3xl rounded-b-3xl mt-10 container"
        style={{
          minHeight: "70vh",
        }}
      >
        {onboardingData?.type !== ENTERPRISE && (
          <div
            style={{
              padding: "0px 10vw",
              background: "#F2F8FF",
              borderBottom: "2px solid #DDE7F2",
            }}
            className="rounded-t-3xl"
          >
            <div className="flex flex-row items-center mx-auto justify-center ">
              <div
                className="text-xl font-medium flex flex-row items-center justify-end gap-3 pr-4"
                style={{
                  background: "#F2F8FF",
                  height: 64,
                  borderRight: "2px solid #DDE7F2",
                }}
              >
                Progress{" "}
                <div className="group">
                  <span
                    style={{
                      width: 16,
                      height: 16,
                      background: "#3091cf",
                      color: "white",
                    }}
                    className="flex flex-row items-center justify-center rounded-full"
                  >
                    ?
                  </span>
                  <div
                    className="hidden group-hover:block absolute bg-white text-black p-4 rounded-lg shadow-lg"
                    style={{
                      zIndex: 9999,
                      maxWidth: 300,
                    }}
                  >
                    The App Launch Progress bar tells you at a glance what stage
                    of completion your app is at. As you complete the forms
                    below, ACTIONERA will work with you and work independently
                    to complete these stages of your app launch.
                    <a
                      href="https://actionera.freshdesk.com/support/solutions/articles/69000833230-the-onboarding-process-roadmap"
                      className="ml-1 underline text-blue-700"
                      target="_blank"
                      rel="noreferrer"
                    >
                      More Info
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-white flex flex-row items-center justify-center w-full">
                {progress.map((item) => {
                  const isLive = item.label === "LIVE!";

                  let isCompleted = false;
                  if (isLive) {
                    isCompleted =
                      onboardingData?.progress?.build_and_submit?.status?.toLowerCase() ===
                      item.completedValue;
                  } else {
                    isCompleted =
                      onboardingData[item.field]?.toLowerCase() ===
                      item.completedValue;
                  }

                  const className = `tracking-wide ${
                    isCompleted ? "progress-completed" : ""
                  }`;

                  return (
                    <div className="pointer-container" key={item.label}>
                      <div id="pointer" className={className}>
                        <div
                          className={`flex text-2xl gap-2.5 items-center ${
                            isCompleted ? "ttnk-AvenirNextBold" : ""
                          }`}
                        >
                          {item.label}
                          {isCompleted && !isLive && (
                            <CheckIcon className="h-10 w-10 fill-current text-white" />
                          )}
                          {isCompleted && isLive && (
                            <RocketIcon className="h-10 w-10 fill-current text-white" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="text-xl font-medium flex flex-row items-center justify-end gap-2 pr-0.5 cursor-pointer"
                style={{
                  color: "#3091ce",
                  fontWeight: "bold",
                  height: 64,
                  width: 150,
                  textDecoration: "underline",
                }}
                onClick={() => setShowInfoModal(true)}
              >
                App Info.
              </div>
            </div>
          </div>
        )}
        {onboardingData?.type !== ENTERPRISE && (
          <AppInfoModal
            open={showInfoModal}
            handleClose={() => setShowInfoModal(false)}
          />
        )}
        {/* Accordion Container */}
        <div
          style={{
            padding: "0px 10vw",
          }}
        >
          {appLogo?.length > 0 && onboardingData?.type === ENTERPRISE && (
            <div className="mx-auto pt-12">
              <img
                src={appLogo}
                style={{ width: "auto", height: 200 }}
                alt="hero"
                className="mx-auto cursor-pointer"
                onClick={() =>
                  window.open("https://www.actionera.com/", "_blank")
                }
              />
            </div>
          )}
          <div
            className="font-bold pt-12 mx-auto text-center"
            style={{ fontSize: 30 }}
          >
            Welcome to the Launch Your App Wizard
          </div>
          <div
            className="mx-auto mt-8"
            style={{
              width: "100%",
              borderRadius: 25,
              backgroundColor: "#888888",
            }}
          >
            <VideoPlayer url={onboardingData?.type === ENTERPRISE ? "https://www.youtube.com/watch?v=j52iRLCQ2mg" : "https://youtu.be/83JGQW1my-o"} />
          </div>
          {onboardingData?.type === ENTERPRISE ? (
            <>
              <div
                className="font-bold mt-12"
                style={{
                  fontSize: 24,
                }}
              >
                YOUR APP LAUNCH STEPS
              </div>
              <div
                className="mt-4 font-medium"
                style={{
                  fontSize: 22,
                }}
              >
                Welcome to the "Launch Your App" wizard. This tool is designed
                to assist you in setting up your app by helping you establish
                essential design and branding elements, and by guiding you to
                provide an initial amount of content. This step is crucial to
                activate your app and make it visible to users. Please note that
                this wizard covers only about 10% of the features and
                functionalities available in an Actionera app. Completing this
                process is your first step towards gaining full access to our
                comprehensive content portal, which will enable you to fully
                utilize the platform for your business. Be sure to watch the
                accompanying videos, which will show you how to quickly get your
                app up and running.
              </div>
            </>
          ) : (
            <>
              <div
                className="font-bold mt-12"
                style={{
                  fontSize: 24,
                }}
              >
                THE APP LAUNCH PROCESS
              </div>
              <div
                className="mt-4 font-medium"
                style={{
                  fontSize: 22,
                }}
              >
                You are interacting with the "Launch Your App" wizard. This tool
                is designed to assist you in setting up your app by helping you
                establish essential design and branding elements, and by guiding
                you to provide an initial amount of content. This area is
                crucial for activating your mobile app and making it visible to
                app users.
                <br />
                <br />
                The Creator Portal is the online interface you are currently
                using that will allow you to build and modify your app at will.
                By completing the “Launch Your App” wizard, you unlock the next
                level of capabilities within the Creator Portal. This will
                enable you to fully utilize the platform for your business.
                <br />
                <br />
                Be sure to watch the above and accompanying videos as you
                interact with the following sections. This will help you to
                quickly get your mobile app up and running.
              </div>
              <div
                className="font-bold mt-12"
                style={{
                  fontSize: 24,
                }}
              >
                EXPEDITE YOUR APP LAUNCH
              </div>
              <div
                className="mt-4 font-medium"
                style={{
                  fontSize: 22,
                }}
              >
                (If you already have a D-U-N-S number, skip this section)
                <br />
                <br />
                There is one key thing that can delay the launch of your app by
                several days or even weeks, which is why we want to address that
                immediately.
                <br />
                <br />
                During the Developer Accounts step (not yet unlocked), you will
                be asked for your D-U-N-S number. This is a piece of vital
                information that is essential to setting up your Developer
                Accounts. You can learn more about what a D-U-N-S number is by
                clicking{" "}
                <a
                  className="mt-4 font-medium"
                  style={{
                    fontSize: 22,
                    color: "blue",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dnb.com/duns.html"
                >
                  HERE
                </a>
                .
                <br />
                <br />
                If you don't currently have one for your business, we teach you
                how to get one in this article{" "}
                <a
                  className="mt-4 font-medium"
                  style={{
                    fontSize: 22,
                    color: "blue",
                  }}
                  href="https://actionera.freshdesk.com/support/solutions/articles/69000855833-how-to-set-up-an-android-organization-developer-account"
                  target="_blank"
                  rel="noreferrer"
                >
                  HERE
                </a>
                . Go straight to the, 'Obtaining a D-U-N-S Number' section to
                fast track this process.
              </div>
              <div
                className="font-bold mt-12"
                style={{
                  fontSize: 24,
                }}
              >
                GETTING STARTED
              </div>
              <div
                className="mt-4 font-medium"
                style={{
                  fontSize: 22,
                }}
              >
                In the "Launch Your App" wizard there are a several key steps
                that you will progress the launch of your mobile app. Some of
                the steps are hidden and will not unlock until you complete the
                current steps available to you. Your first step is in the APP
                DESIGN section below. Click on this section to get started.
                <br />
                <br />
                Once you are in that section and you begin to explore adding
                your information in, you may notice a pop-out slider on your
                right-hand side. This slider will provide you with additional
                support and clarification information. It may include text,
                images, links and/or video to assist you with completing this
                Wizard.{" "}
                <i
                  className="mt-4 font-medium"
                  style={{
                    fontSize: 22,
                  }}
                >
                  Please note that the videos were recorded featuring a
                  different information gathering technology, but the vast
                  majority of what is discussed in the videos still applies in
                  this Wizard. Also, we will be updating the videos to match as
                  soon as possible.
                </i>
                <br />
                <br />
                Let's dive in and get that App Launched!
              </div>
              <div
                className="font-bold mt-12"
                style={{
                  fontSize: 24,
                }}
              >
                SUPPORT
              </div>
              <div
                className="mt-4 font-medium"
                style={{
                  fontSize: 22,
                }}
              >
                If you find yourself needing guidance at any point while going
                through this wizard, please send us an email detailing what part
                you are stuck or confused on:{" "}
                <a
                  href="mailto:matt@actionera.com"
                  className="mt-4 font-medium"
                  style={{
                    fontSize: 22,
                    color: "blue",
                  }}
                >
                  matt@actionera.com
                </a>
              </div>
            </>
          )}

          {children}
        </div>
        <div>
          <div
            className="text-center mt-8 pb-8 underline flex flex-row items-center justify-center gap-2 cursor-pointer"
            style={{ fontSize: 18, fontWeight: "bold", color: "#3091cf" }}
            onClick={() => showModal(<OnboardingAssistanceModal />)}
          >
            Contact Us for Changes/Assistance
          </div>
        </div>
      </div>

      <div style={{ fontSize: 12 }} className="font-medium text-center py-8">
        © 2023+ by{" "}
        <span
          style={{ color: "#2750a0" }}
          className="underline font-bold cursor-pointer"
          onClick={() => window.open("https://www.actionera.com/app", "_blank")}
        >
          ACTIONERA{" "}
        </span>
        , all rights reserved.
      </div>
      <div></div>
    </div>
  );
};

export default OnboardingWrapper;
