import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment';
import { EntryForm, Column, Input, CheckBox } from '../components/EntryForm'
import Page from '../components/Page'
import { GetUser, UpdateUser, DeleteUser } from '../services/api/user'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"
import { useLoaderProvider } from '../providers/LoaderProvider'
import ErrorModal from '../components/modal_contents/ErrorModal'
import { validations } from "../lib/form";

const UserDetail = ({ match }) => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    password: '',
    is_super: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUserProvider()
  const { showModal, closeModal } = useModalProvider()
  const { doSetShowLoadingModal } = useLoaderProvider()
  const uuid = match.params.uuid
  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    GetUser(uuid, user.idToken).then(res => res.json()).then(res => {
      setInitialValues({
        name: res.data.name,
        email: res.data.email,
        password: res.data.password,
        is_super: res.data.is_super
      })
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    doSetShowLoadingModal(isLoading)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Manage Users", url: "/users" },
    { name: "Edit User", url: `/users/${uuid}` },
  ]
  
  let schema = {
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').max(50, 'Maximum 50 Characters').required('Required'),
    password: validations.password
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      let newValues = values;

      UpdateUser(uuid, user.idToken, { data: newValues })
        .then(response => responseProcessor(response))
    },
  })

  const responseProcessor = (res) => {
    if (res.ok) {
      history.push('/users')
    } else {
      showModal(<ErrorModal errorCode={res.status}/>)
    }
  }

  return (
    <Page links={links} title={"Edit User"}>
      <EntryForm isLoading={isLoading} formik={formik} submitHandler={formik.handleSubmit} cancelURL="/users">
        <Column>
          <Input name="email" type="email" label="Email" formik={formik} />
          <div>
            <Input
              name="password"
              type="password"
              label="Password"
              formik={formik}
            />
          </div>
        </Column>
        <Column>
          <Input name="name" type="text" label="Name" formik={formik} />
          <CheckBox name="is_super" label="Super User" formik={formik} />
        </Column>
      </EntryForm>
    </Page>
  );
}

export default UserDetail;
