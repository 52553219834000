import { useEffect, useState } from 'react';
import DataTable from '../components/DataTable';
import Page from '../components/Page';
import TableActions from '../components/TableActions';
import { mergeArray } from '../lib/arrayTools';
import { useHistory } from 'react-router-dom';

import { useUserProvider } from '../providers/UserProvider';
import { useModalProvider } from '../providers/ModalProvider';

import { DeleteTopic } from '../services/api/topic';
import { GetAppResource } from '../services/api/app';

import DeleteItem from '../components/modal_contents/DeleteItem';
import { IsWithinMinimumAndMaximumVersion, IsWithinMaximumVersion } from '../lib/useVersionizer';

const TopicList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {},
  });
  const [directories, setDirectories] = useState([]);

  const history = useHistory();

  const { user } = useUserProvider();
  const isEnterpriseApp = (user?.app_data?.setup?.enterprise || user?.app_data?.setup?.reseller);

  const { showModal, closeModal } = useModalProvider();
  const ver85below = IsWithinMinimumAndMaximumVersion(8, 8.5);

  useEffect(() => {
    loadDirectories();
  }, []);

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, 'topics')
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data,
        });
      });
  };

  const loadDirectories = async () => {
    GetAppResource(user, 'directories')
      .then((res) => res.json())
      .then(({ data: jsonData }) => {
        setDirectories(jsonData);
      });
  }

  const links = [
    { name: 'Home', url: '/' },
    { name: 'Topics/Directory Listings', url: '/topics' },
  ];

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => data.id,
    },
    {
      name: 'sort_order',
      label: 'Sort Order',
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
    },
    {
      name: 'name',
      label: 'Name',
      parser: (data) => data.name,
    },
    {
      name: 'sub_topics',
      label: 'Sub-Topics',
      width: 200,
      parser: (data) => (
        <span className="ttnk-tag">
          {data.sub_topics
            .join(', ')
            .replaceAll('Tips', 'Blog Posts')}
        </span>
      ),
    },
    {
      name: 'directories',
      label: 'Which Directory?',
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(isEnterpriseApp && data.enterprise ? data.directories : data.directories.filter((item) => !item.enterprise), 'name')}</span>
      ),
    },
    {
      name: 'tags',
      label: 'Tags',
      width: 300,
      parser: (data) => (
        <span className="ttnk-tag break-all" >{mergeArray(data.tags, 'name')}</span>
      ),
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/topics/new`);
  };

  const AddButton = () => {
    return (
      <button className="ttnk-button add-button" onClick={handleAddButtonClick}>
        Add Topic / Listing
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/topics/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteTopic(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Topic"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page
      links={links}
      title={'Topics/Directory Listings'}
      titleComponent={AddButton()}
    >
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={['name', 'sub_line']}
      />
    </Page>
  );
};

export default TopicList;
