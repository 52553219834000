import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { EntryForm, Column, Input, CheckBox } from "../components/EntryForm";
import Page from "../components/Page";
import { GetMember, UpdateMember, DeleteMember } from "../services/api/member";

import { AddAppResource, GetAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import DataSelector from "../components/DataSelector";
import SubTable from "../components/SubTable";
import contentConfig from "../lib/content_config/config";

import DeleteItem from "../components/modal_contents/DeleteItem";
import responseProcessor from "../lib/responseProcessor";
import { useDuplicateValidation, validations } from "../lib/form";
import ErrorModal from "../components/modal_contents/ErrorModal";

const MemberDetail = ({ newUser, match }) => {
  const contentType = "members";
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    password: "",
    blacklisted: false,
    tags: [],
    url_tags: [],
    courses: [],
    send_email: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newUser ? user.currentApp() : match.params.uuid;
  const history = useHistory();
  const subTables = contentConfig(contentType).subTables;

  const url_tags_decoder = (tags) => {
    if (tags.length === 0) {
      return [];
    } else {
      const tags_list = tags.split(",");
      return tags_list.map((tag) => {
        const pair = tag.split(":");
        return { url_tag: pair[0], link: atob(pair[1]) };
      });
    }
  };

  const url_tags_encoder = (tags) => {
    return tags.map((tag) => `${tag.url_tag}:${btoa(tag.link)}`).join(",");
  };

  useEffect(() => {
    if (newUser) {
      setInitialValues({
        name: "",
        email: "",
        password: "",
        blacklisted: false,
        courses: [],
        tags: [],
        url_tags: [],
      });
    } else {
      setIsLoading(true);
      GetMember(uuid, user)
        .then((res) => res.json())
        .then((res) => {
          const newCourses = res.data.courses?.map((c) => {
            return {
              course_name: c?.title,
              description: c?.sub_description,
              enroll_date: c?.enroll_date,
            };
          });

          setInitialValues({
            name: res.data.name,
            email: res.data.email,
            password: res.data.password,
            blacklisted: res.data.blacklisted,
            tags: res.data.tags,
            url_tags: res.data.url_tags,
            courses: newCourses,
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetAppResource(user, "courses")
      .then((res) => res.json())
      .then((jsonData) => setCourses(jsonData?.data));
  }, []);

  const currentAction = newUser ? "New" : "Edit";
  const currentUrl = newUser ? "/members/new" : `/members/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Member List", url: "/members" },
    { name: `${currentAction} Member`, url: currentUrl },
  ];

  const validate = useDuplicateValidation(["url_tag"], "url_tags");

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => validate(values, formik),
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          name: validations.str_req_255,
          email: validations.str_req_255.email("Invalid email"),
        };
        if (newUser) {
          schema["password"] = validations.str_req_255;
        }
        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      let newValues = values;

      if (values?.courses?.length) {
        const newCourses = values?.courses?.map((c) => {
          const course = courses?.find((co) => co?.title === c?.course_name);
          return {
            id: course?.id,
            sub_description: c?.description,
            enroll_date: moment(c?.enroll_date).format("YYYY-MM-DD"),
          };
        });

        newValues = { ...values, courses: newCourses };
      }

      if (newUser) {
        AddAppResource(uuid, "members", user.idToken, { data: newValues }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateMember(uuid, user, { data: newValues }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/members"),
    });
  };

  if (!user.app_data.setup.url_tags) {
    delete subTables.url_tags;
  }

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="member"
        onDelete={deleteMemberHandler}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  const deleteMemberHandler = async () => {
    await DeleteMember(uuid, user.idToken);
    closeModal();
    history.push("/members");
  };

  return (
    <Page links={links} title={`${currentAction} Member`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/members"
      >
        <Column>
          <Input
            name="email"
            disabled={!newUser && !user.isSuper}
            type="email"
            label="Email"
            formik={formik}
          />
          <Input
            name="name"
            disabled={!newUser && !user.isSuper}
            type="text"
            label="Name"
            formik={formik}
          />
        </Column>
        <Column>
          {(newUser || (user.isSuper && !newUser)) && (
            <Input
              name="password"
              type="password"
              label="Password"
              formik={formik}
            />
          )}
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
          {/* <CheckBox name="send_email" label="Send Email" formik={formik} /> */}
          <CheckBox
            name="blacklisted"
            label="Blacklist/Remove"
            formik={formik}
          />
          <span className="text-gray-400">
            If you blacklist or remove a user, they will no longer have member
            access to your app
          </span>
        </Column>
        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={contentConfig(contentType)}
                  subContentType={key}
                  labels={subTables[key].label}
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={contentType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}
      </EntryForm>
    </Page>
  );
};

export default MemberDetail;
