import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteOffer } from "../services/api/offer";
import { GetAppResource } from "../services/api/app";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { CloneItem } from "../services/api/clone";

const OfferList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {},
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, "offers")
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Special Offer Codes", url: "/offers" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "unlock_code",
      label: "Unlock Code",
      parser: (data) => data.unlock_code,
    },
    {
      name: "title",
      label: "Title",
      parser: (data) => data.title,
    },
    {
      name: "message",
      label: "Message",
      parser: (data) => data.message,
      ellipsis: true,
    },
    {
      name: "email",
      label: "Notification Email",
      parser: (data) => data.email,
    },
    {
      name: "send_email",
      label: "Send Email",
      parser: (data) => `${data.send_email}`,
    },

    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          onCloneClick={cloneButtonHandler}
          item_id={data.id}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/offers/new`);
  };

  const cloneButtonHandler = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: "offers",
      },
    });
    const cloneData = await res.json();
    const cloneId = cloneData.data?.id;

    loadData();
    history.push(`/offers/${cloneId}`);
  };

  const AddButton = () => {
    return (
      <button className="ttnk-button add-button" onClick={handleAddButtonClick}>
        Add Special Offer Code
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/offers/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteOffer(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Offer"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page
      links={links}
      title="Special Offer Codes"
      titleComponent={AddButton()}
    >
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["title", "message"]}
      />
    </Page>
  );
};

export default OfferList;
