import { callEndpoint } from "../baseApi";

export const ListUnlinkMedia = async (user) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/unlinked_media`,
    "GET",
    user.idToken
  );
};

export const DeleteUnlinkedMedia = async (user, uuid) => {
  try {
    const res = await callEndpoint(`${process.env.REACT_APP_API}/media/${uuid}`, 'DELETE', user.idToken)
    return res;
  } catch (error) {
    console.log(error);
  }
}