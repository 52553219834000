import { useCallback, useEffect, useState, Fragment } from "react";
import { useFormik } from "formik";
import { useAuth } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";

import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import useLocalStorage, { useLocalState } from "../lib/useLocalStorage";
import { GetPostAllCreatorApps, GetAllAvailableApps } from "../services/api/app";

import { Column } from "../components/EntryForm";
import CustomCheckbox from "../components/CustomCheckbox";
import { setAppData } from "../App";

import Logo from '../assets/images/ae-lt.png'
import Image from '../assets/images/Creator_Portal.png'

const OptInForm = ({ type = null }) => {
	const { showModal } = useModalProvider()
	const { user, setUser } = useUserProvider()
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { authService } = useAuth();
	const currentSelectedApp = useLocalState('currentApp')[0]
	const [optinPath, setOptinPath] = useLocalStorage("optin-path", null);
	
	const [isSaving, setIsSaving] = useState(false)
	const [hasNoAppSelected, setHasNoAppSelected] = useState(true)
  const [appList, setAppList] = useState([]);
	const [hasInitialized, setHasInitialized] = useState(false)

	useEffect(() => {
    const idToken = authService.getAuthTokens().id_token;
		if (!idToken) {
			setHasInitialized(true)
			showModal(<HandleLogin />)
			return;
		}

    doSetShowLoadingModal(true);
    const data = jwtDecode(idToken);
		if (optinPath) localStorage.removeItem('optin-path')

		GetAllAvailableApps(authService.getAuthTokens().id_token)
      .then((res) => res.json())
      .then((res) => {
        if (res.data.length === 0) return;

        const app =
          currentSelectedApp &&
          res.data.some((a) => a.id === currentSelectedApp.id)
            ? currentSelectedApp
            : res.data[0];
        const currentApp = () => app.id;
        const isSuper = data.is_super;

        if (idToken !== undefined) {
          setAppData({
            idToken,
            data,
            isSuper,
            currentApp,
            app,
            setUser,
            postCallback: () => {
              doSetShowLoadingModal(false);
            },
          });
        }
      })
			.finally(() => {
				setTimeout(() => {
					setHasInitialized(true)
				}, 500)
			});
  }, []);

	useEffect(() => {
		if (user?.data?.sub) getCreatorApps();
	}, [user])

	useEffect(() => {
		if (appList?.length > 0) {
			setHasNoAppSelected(!appList.some((item) => item.isChecked))
		}
	}, [appList])
	
	const handleVisitWebsite = useCallback(() => {
		window.open('https://www.actionera.com/homepage')
	}, [])

	const getCreatorApps = () => {
		GetPostAllCreatorApps(user.data.sub, authService.getAuthTokens().id_token)
		.then((res) => res.json())
		.then((res) => {
			if (res.data?.length > 0) {
				setAppList(
					res.data
						.filter((item) => item.migrated === false)
						.map((item) => {
							return {
								id: item.id,
								label: item.name,
								isChecked: false
							}
						})
				);
			}
		});
	}

  const handleClick = (index, value) => {
    const newAppList = [...appList];
    newAppList[index] = {
      id: newAppList[index].id,
      label: newAppList[index].label,
      isChecked: value
    };

    setAppList(newAppList);
  };
	
	const onSubmit = async () => {
		if (!appList.some((item) => item.isChecked)) return;
		const optinTypeList = {
			proaccount: 'PRO',
			proplusaccount: 'PRO+',
			freeaccount: 'FREE'
		}

		const selectedApp = appList.filter((item) => item.isChecked).map((item) => {
			return { id: item.id }
		});
		
		setIsSaving(true)
		GetPostAllCreatorApps(
			user.data.sub,
			authService.getAuthTokens().id_token, 
			'POST',
			{
				data: {
					app_type: optinTypeList[type],
					apps: selectedApp
				}
			})
			.then((res) => res.json())
			.then(() => {
				getCreatorApps();
				setIsSaving(false);
				setHasNoAppSelected([])
				return showModal(<AfterSelectionPrecoess/>)
			})
			.finally(() => setIsSaving(false));
	}

	function HandleLogin() {
		const handleOnClick = async (e) => {
			setOptinPath(window.location.pathname)
			window.location.href = '/';
		};
	
		return (
			<div>
				<div style={{ width: 400 }} className="mb-6 text-center">
					You need to log in as a creator to view and fill-in this form.
				</div>
				<div className='flex justify-center'>
				<button onClick={handleOnClick} className="ttnk-button">
					Login
				</button>
				</div>
			</div>
		);
	}

	function AfterSelectionPrecoess() {
		const handleOnClick = async (e) => {
			window.location.href = "/"
		};
	
		return (
			<div>
				<div style={{ width: 420 }} className="mb-6 text-center">
					You have now been opted in to your preferred Enterprise as one of its businesses under its app.
				</div>
				<div className='flex justify-center'>
				<button onClick={handleOnClick} className="ttnk-button">
					Close
				</button>
				</div>
			</div>
		);
	}

	const isNotLoggedIn = !user.data && hasInitialized

	const formik = useFormik({
		initialValues: [],
		onSubmit,
		enableReinitialize: true,
	});
    
	return (
		<div className="w-screen pt-12 ttnk-free-trial-bg">
			<div className="px-12 md:px-20 container mx-auto">
				<div className="mb-12 md:mb-20 flex justify-between flex-col items-center md:flex-row gap-12">
					<div id="logo"><img src={ Logo } className="object-contain w-64 h-16" alt="ACTIONERA logo"/></div>
					<button onClick={handleVisitWebsite} className="border-none bg-transparent p-0 m-0 outline-none w-full md:w-auto max-w-xl md:max-w-none">
						<div className="text-white uppercase font-bold py-3 px-8 border rounded-full">Visit our website</div>
					</button>
				</div>
				<h1 className="font-bold text-xl md:text-6xl leading-tight max-w-auto xl:max-w-3xl text-white mx-auto md:mx-0 text-center md:text-left">The ULTIMATE Content, Membership, Event, and Course Platform</h1>
				<div className="relative flex flex-col-reverse xl:flex-row items-center justify-center md:justify-between mt-11">
					<div className="xl:max-w-xl mt-8 shrink-0 z-10 xl:w-screen">
						<Fragment>
							<form className="rounded-xl bg-white" onSubmit={formik.handleSubmit}>
								<Column width="full">
									<div className="px-6 py-10">
										<header className="font-bold text-3xl mb-4">Opt-In Set-up Form</header>
										<p className="text-2xl mb-8">Get in on the Action!</p>
										{user?.data && (
											<>
												{
													appList?.length > 0 && appList.map(({id, label, isChecked}, index) => {
														return <CustomCheckbox key={id} id={id} label={label} isChecked={isChecked} onClick={() => handleClick(index, !isChecked)} />
													})
												}
												<button
													disabled={isSaving || hasNoAppSelected}
													className="drop-shadow-lg text-lg rounded-full py-3 disabled:cursor-not-allowed text-white bg-gradient-to-b disabled:from-gray-100 disabled:to-gray-100 hover:from-aeblue-dark hover:to-aeblue-mid from-aeblue-mid to-aeblue-dark disabled:text-gray-400 w-full mt-4 mb-8"
													type="submit"
												>
													Submit
												</button>
											</>
										)}
										{isNotLoggedIn && (
											<div className="h-96 w-full bg-gradient-to-b from-aeblue-light to-aeblue-dark mb-8"/>
										)}
										<div className="mb-8">
											By continuing, you agree to our <a target="_blank" href="https://www.actionera.com/policy" className="underline decoration-solid text-blue-500" rel="noreferrer">Privacy Policy</a> and <a target="_blank" href="https://www.actionera.com/terms" className="underline decoration-solid text-primary-500 text-blue-500" rel="noreferrer">Terms {'&'} Conditions</a>
										</div>
									</div>
								</Column>
							</form>
						</Fragment>
					</div>
					<div className="grow max-w-5xl relative right-0"><img src={ Image } className="object-contain  mr-8" alt="Trial"/></div>
				</div>
			</div>
			<div className="text-center text-white py-8 mt-12 md:mt-40 px-20">
				<div className="container mx-auto">
					<div className="uppercase text-2xl font-bold">Need assistance?
						<a className="text-aeblue text-2xl" href="https://www.actionera.com/contact" target="_blank" rel="noreferrer" > Email us</a>
					</div>
					<div className="mt-8 pt-8">
						<a className="text-aeblue underline" href="https://www.actionera.com/contact" target="_blank" rel="noreferrer" >ACTIONERA</a>
						&nbsp;© {new Date().getFullYear()} All Rights Reserved
					</div>
				</div>
			</div>
		</div>
	)
}

export default OptInForm