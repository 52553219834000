const courseLessonConfig = {
  subTables: {
    sections: {
      label: {
        plural: "Sections",
        singular: "Section",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "sub_description",
          label: "Description",
          parser: (data) => data.sub_description,
        },
        {
          name: "headline",
          label: "Headline",
          parser: (data) => data.headline,
          ellipsis: true,
        },
        { name: "title", label: "Section Title", parser: (data) => data.title },
      ],
      detailsMetadata: [
        {
          name: "sub_description",
          label: "Description",
          validations: [{ type: "required", params: ["Required"] }],
          field: "text",
          order: 1,
        },
        {
          name: "sort_order",
          label: "Sort Order",
          validations: [],
          order: 2,
        },
        { name: "headline", label: "Headline", order: 3 },
        { name: "title", label: "Section Title", order: 4 },
        { name: "image_url", label: "Image URL", order: 5 },
        {
          name: "text",
          label: "Section Text",
          field: "richtext",
          quillName: "web_text",
          order: 6,
        },
        { name: "video_url", label: "Video URL", order: 7 },
        { name: "audio_url", label: "Audio URL", order: 8 },
        { name: "question_1", label: "Journal 1", order: 9 },
        { name: "question_2", label: "Journal 2", order: 10 },
        { name: "question_3", label: "Journal 3", order: 11 },
        { name: "question_4", label: "Journal 4", order: 12 },
        { name: "question_5", label: "Journal 5", order: 13 },
        {
          name: "document_description",
          label: "Document Link Text",
          order: 14,
          field: "text",
          validations: [
            { type: "required", params: ["Required"] },
            {
              type: "max",
              params: [50, "Maximum 50 Characters"],
            },
          ],
        },
        {
          name: "document_section_url",
          label: "Document URL",
          order: 15,
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "link_description",
          label: "Link Text",
          order: 16,
          field: "text",
          validations: [
            { type: "required", params: ["Required"] },
            {
              type: "max",
              params: [50, "Maximum 50 Characters"],
            },
          ],
        },
        {
          name: "link_url",
          label: "Link URL",
          order: 17,
          validations: [{ type: "required", params: ["Required"] }],
          field: "text",
        },
        { name: "question", label: "Question", order: 18 },
        { name: "answer", label: "Answer", order: 19 },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
      customFields: true,
    },
  },
};

export default courseLessonConfig;
