import React from "react"
import { FileUpload } from "./FileUpload";
import UploadIcon from "../assets/icons/upload.png";

const FileUploader = ({
  label,
  name,
  required = false,
  errorMessage,
  fileTypes,
  acceptedTypes,
  formik,
  value,
  uploadType = "file",
  showLabel = true,
  isButton = false,
  shouldShowErrorMessages = true,
  successCallback,
  onRemove,
  showButton = true,
}) => {

  return (
    <>
      <div className={`flex flex-row w-full ${showLabel ? "mb-5 justify-between" : "justify-end"}`}>
        {
          showLabel ? (
            <div className="w-1/3">
              {label} {required && label && <span className="text-red-500">*</span>}
            </div>
          ) : null
        }
        <div className={`${showLabel ? "w-2/3" : "add-item-btn"}`}>
          <FileUpload
            onError={(err) => formik.setFieldError(name, err)}
            error={shouldShowErrorMessages ? formik.errors[name] : undefined}
            errorMessage={errorMessage}
            isButton={false}
            buttonClassName=""
            fileType={fileTypes}
            uploadType={uploadType}
            acceptedTypes={acceptedTypes}
            onChange={(val, metadata) => {
              formik.setFieldValue(name, val);
              formik.setFieldTouched(name);

              if (val === "" && typeof onRemove === "function") onRemove()
            }}
            value={value}
            metaData={formik.values.media_metadata}
            successCallback={successCallback}
            onRemove={onRemove}
          >
            {
              isButton ? (
                showButton ? (
                  <button
                    // eslint-disable-next-line no-octal-escape
                    className="flex justify-center items-center border-4 border-aeblue border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue-default font-bold hover:bg-aeblue-default hover:text-white"
                    type="button"
                  >
                    <p className="ml-1 file-upload-btn">Add Item</p>
                  </button>
                ) : <div />
              ) : (
                <div className="p-6 flex flex-row justify-between items-center bg-white">
                  <div>Choose File(s)</div>
                  <div>
                    <div
                      className="p-4 rounded-full"
                      style={{
                        background: "#ebebeb",
                      }}
                    >
                      <img
                        src={UploadIcon}
                        alt="Upload File"
                        className="w-3 h-3"
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </FileUpload>
        </div>
      </div>
    </>
  )
}

export default FileUploader