import {
  Input,
  TextArea,
  CheckBox,
  Select,
  RichText,
  CheckboxGroup,
  TinyEditor,
  Tickbox,
  getNestedFieldStatus
} from "../components/EntryForm";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash.svg";
import MediaUploader from "../components/MediaUploader";
import DataSelector from "../components/DataSelector";
import DateSelector from "../components/DateSelector";
import { useModalProvider } from "../providers/ModalProvider";
import ErrorModal from "../components/modal_contents/ErrorModal";
import moment from "moment";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import DocumentUploader from "../components/DocumentUploader";
import VersionizerWrapper from "../components/Versionizer";
import { Radio, Checkbox } from "antd";
import BulkPhotos from "../components/BulkPhotos";
import Description from "../components/Description";
import { FileUpload } from "../components/FileUpload";
import UploadIcon from "../assets/icons/upload.png";
import FileUploader from "../components/FileUploader";
import { DONE } from "./onboarding_config/config";

const disabledDate = (current) => {
  return current && current < moment().endOf("day");
};

export const validations = {
  str_255: Yup.string().max(255, "Maximum 255 Characters"),
  str_blob: Yup.string().max(65535, "Maximum 65,535 Characters"),
  str_req_blob: Yup.string().transform((value) => (value ? value.trim() : value)).max(65535, "Maximum 65,535 Characters").required('Required'),
  str_req_10: Yup.string()
    .max(10, "Maximum 10 Characters")
    .required("Required"),
  str_req_11: Yup.string()
    .max(11, "Maximum 11 Characters")
    .required("Required"),
  str_notreq_10: Yup.string().max(10, "Maximum 10 Characters"),
  str_notreq_20: Yup.string().max(20, "Maximum 20 Characters"),
  str_multiline: Yup.string(),
  str_18: Yup.string().max(18, "Maximum 18 Characters"),
  str_20: Yup.string().max(20, "Maximum 20 Characters"),
  str_req_20: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(20, "Maximum 20 Characters")
    .required("Required"),
  str_req_25: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(25, "Maximum 25 Characters")
    .required("Required"),
  str_notreq_25: Yup.string().max(25, "Maximum 25 Characters"),
  str_req_30: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(30, "Maximum 30 Characters")
    .required("Required"),
  str_notreq_30: Yup.string().max(30, "Maximum 30 Characters"),
  str_req_35: Yup.string().transform((value) => (value ? value.trim() : value)).max(35, "Maximum 35 Characters").required("Required"),
  str_notreq_35: Yup.string().max(35, "Maximum 35 Characters"),
  str_notreq_40: Yup.string().max(40, "Maximum 40 Characters"),
  str_req_40: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(40, "Maximum 40 Characters")
    .required("Required"),
  str_req_45: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(45, "Maximum 45 Characters")
    .required("Required"),
  str_notreq_50: Yup.string().max(50, "Maximum 50 Characters"),
  str_req_50: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(50, "Maximum 50 Characters")
    .required("Required"),
  str_req_60: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(60, "Maximum 60 Characters")
    .required("Required"),
  str_notreq_60: Yup.string().max(60, "Maximum 60 Characters"),
  str_req_70: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(70, "Maximum 70 Characters")
    .required("Required"),
  str_notreq_75: Yup.string().max(75, "Maximum 75 Characters"),
  str_req_100: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(100, "Maximum 100 Characters")
    .required("Required"),
  str_req_255: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(255, "Maximum 255 Characters")
    .required("Required"),
  str_req_blob: Yup.string().required("Required"),

  short_text: Yup.string().max(30, "Maximum 30 Characters"),
  short_text_required: Yup.string()
    .transform((value) => (value ? value.trim() : value))
    .max(30, "Maximum 30 Characters")
    .required("Required"),
  long_text: Yup.string().max(255, "Maximum 255 Characters"),
  long_text_required: Yup.string().transform((value) => (value ? value.trim() : value)).required("Required"),
  bool: Yup.boolean().nullable(),
  sort_order: Yup.number()
    .typeError("Must be a number")
    .integer()
    .min(0, "Minimum Sort Order: 0")
    .max(500, "Maximum Sort Order: 500"),
  drip_sequence: Yup.number()
    .typeError("Must be a number")
    .integer()
    .min(0, "Minimum Sort Order: 0")
    .max(100, "Maximum Sort Order: 100"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(20, "Maximum 20 characters")
    .matches(/(?=.*[A-Z])/, {
      message: "Must contain 1 uppercase",
    })
    .matches(/([^A-Za-z0-9])/, {
      message:
        "Must contain 1 special character. Only the following special characters are allowed: -+_!@#$%^&*,.?</:",
    })
    .matches(/^[A-Za-z0-9\+\-\_\!\@\#\$\%\^\&\*\,\.\?\<\/\:]/, {
      message:
        "Invalid special character. Only the following special characters are allowed: -+_!@#$%^&*,.?</:",
    }),
  username: Yup.string() //
    .max(30, "Maximum 30 characters")
    .matches(/^\S*$/, {
      message: "No space",
    })
    .required("Required"),
  url: Yup.string().url("Please enter a valid URL"),
  url_req: Yup.string().url("Please enter a valid URL").required("Required"),
  long_url: Yup.string()
    .url("Please enter a valid URL")
    .max(255, "Maximum 255 Characters"),
  long_url_required: Yup.string()
    .url("Please enter a valid URL")
    .max(255, "Maximum 255 Characters")
    .required("Required"),
  email: Yup.string()
    .email('Enter a valid Email Address eg: yourname@gmail.com')
    .max(255, "Maximum 255 Characters")
    .required('Required'),
  website: Yup.string()
    .matches(
      /^(https?:\/\/)?([^\s]+\.[^\s]+)/,
      'Enter a valid website. (eg: www.domain.com)'
    )
    .max(255, "Maximum 255 characters")
    .required('Required')
};

const customFields = (field, props) => {
  const fields = {
    text: <Input {...props} />,
    textarea: <TextArea {...props} type="text" />,
    checkbox: <CheckBox {...props} />,
    richtext: <RichText {...props} type="text" rows={21} />,
    tickbox: <Tickbox {...props} />
  };
  return fields[field];
};

export const getState = (key) => {
  const strVal = localStorage.getItem(key);
  return strVal ? JSON.parse(strVal) : "";
};

export const setState = (key, value) => {
  if (value === undefined) return localStorage.removeItem(key);

  const val = JSON.stringify(value);
  localStorage.setItem(key, val);
};

export const hash = (str) => {
  const seed = 0;
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;

  String(str)
    .split("")
    .forEach((char) => {
      const ch = str.charCodeAt(char);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    });

  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const categoryList = [
  'Games',
  'Business',
  'Education',
  'Lifestyle',
  'Entertainment',
  'Utilities',
  'Travel',
  'Health & Fitness',
  'Food & Drink',
  'Book',
  'Productivity',
  'Music',
  'Finance',
  'Photo & Video',
  'Sports',
  'Reference',
  'Social Networking',
  'News',
  'Medical',
  'Shopping'
]

export function useDuplicateValidation(
  keys = [],
  valuesKey = "sections",
  isShowModal = true
) {
  const { showModal } = useModalProvider();

  // clean up
  useEffect(() => {
    if (getState("formValuesRef")) localStorage.removeItem("formValuesRef");
    if (getState("payloadRef")) localStorage.removeItem("payloadRef");

    return () => {
      localStorage.removeItem("formValuesRef");
      localStorage.removeItem("payloadRef");
      localStorage.removeItem("formFieldRef");
    };
  }, []);

  const handleDuplicate = useCallback(async (key, values, formik) => {
    const formValueRef = getState("formValuesRef");
    const collections = values[valuesKey] || [];

    const collectionsValue = collections.map((s) =>
      String(s[key]).toLowerCase()
    );
    const collectionsSetValue = new Set(collectionsValue);

    if (collectionsSetValue.size !== collectionsValue.length) {
      let _collections = [...collections];

      // identify what row is updated
      const payloadRef = getState("payloadRef");

      // remove duplicate field in form
      if (!payloadRef && payloadRef !== 0) _collections.pop();
      else {
        // retain old state if value already existed
        _collections[payloadRef] = formValueRef[valuesKey][payloadRef];
      }

      formik.setFieldTouched(valuesKey, false, false);
      formik.setFieldValue(valuesKey, _collections, false);

      throw new Error("DUPLICATE");
    }
  }, []);

  return (values, formik) => {
    const formValueRef = getState("formValuesRef");
    const formFieldRef = getState("formFieldRef");

    // for validating of multiple fields
    if (formFieldRef !== valuesKey) return;

    // create reference to prev form value
    if (!formValueRef)
      localStorage.setItem(
        "formValuesRef",
        JSON.stringify(formik.initialValues)
      );

    // delays the execution in call stack to get updated payloadRef
    setTimeout(() => {
      Promise.all(keys.map((key) => handleDuplicate(key, values, formik)))
        .then(() => {
          // update reference to prev form value
          // upon no duplicate validation
          localStorage.setItem("formValuesRef", JSON.stringify(formik.values));
        })
        .catch((e) => {
          if (isShowModal) {
            showModal(<ErrorModal status="DUPLICATE" />);
            return;
          }
        });
    });
  };
}

export const getFieldComponent = (
  name,
  {
    label,
    formik,
    selectedValues,
    formField,
    metadata,
    contentType,
    format,
    width,
    placeholder,
    disabled = false,
    user,
    isFieldRow = false,
    rows,
    required = false,
    isEnterprise = false,
    maxSize,
    isInlineNotReverse = false,
    ...fieldProps
  },
  quillName = name,
  customComponent = undefined
) => {
  const platformVersion = parseFloat(user?.app_data?.about?.platform_version);
  const disableVideoURL =
    formik?.values?.type === "Card" && contentType === "items";

  const {  error: errorLogo } = getNestedFieldStatus(
    "privacy_policy_document",
    formik
  );

  const pathname = window.location.pathname;

  if (formField) {
    const field = customFields(formField, {
      label,
      formik,
      name,
      placeholder,
      isFieldRow,
      required,
      rows,
      isInlineNotReverse,
      ...fieldProps
    });
    return field;
  }

  const fieldMap = {
    title: <Input name="title" type="text" label={label} formik={formik} isFieldRow={isFieldRow} placeholder={placeholder} required={required} />,
    host_name: (
      <Input name="host_name" type="text" label={label} formik={formik} />
    ),
    type: (
      <Select name="type" label={label} formik={formik}>
        <option value="">- Select {label} -</option>
        <option value="Card">Card</option>
        <option value="Book">Book/Video</option>
      </Select>
    ),
    tagged_visible: (
      <CheckBox
        name="tagged_visible"
        label={label}
        formik={formik}
        disabled={disabled}
      />
    ),
    email: <Input name="email" type="email" label={label} formik={formik} isFieldRow={isFieldRow} placeholder={placeholder} required={required} />,
    "profile.id": (
      <DataSelector
        single
        singleSelectOnly
        inSubtable
        name="profile.id"
        formik={formik}
        label={label}
        resourceEndpoint="profiles"
      />
    ),
    "free_gift.url": (
      <Input name="free_gift.url" type="text" label={label} formik={formik} />
    ),
    "free_gift.image_url": (
      <MediaUploader
        name="free_gift.image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
      />
    ),
    "free_gift.description": (
      <TextArea
        name="free_gift.description"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    "vip_gift.url": (
      <Input name="vip_gift.url" type="text" label={label} formik={formik} />
    ),
    "vip_gift.registration_url": (
      <Input
        name="vip_gift.registration_url"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    "vip_gift.image_url": (
      <MediaUploader
        name="vip_gift.image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
      />
    ),
    "vip_gift.description": (
      <TextArea name="vip_gift.description" label={label} formik={formik} />
    ),
    message_prompt: (
      <TextArea
        name="message_prompt"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    download_url: (
      <Input name="download_url" type="text" label={label} formik={formik} />
    ),
    start_number: (
      <Input name="start_number" type="number" label={label} formik={formik} />
    ),
    end_number: (
      <Input name="end_number" type="number" label={label} formik={formik} />
    ),
    text:
      label === "Item Text" ? (
        <Input name={name} type="text" label={label} formik={formik} />
      ) : (
        <TextArea name="text" type="text" label={label} formik={formik} />
      ),
    web_text: (
      <RichText
        name="text"
        quillName="web_text"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    info: <Input name="info" type="text" label={label} formik={formik} />,
    name: <Input name="name" type="text" label={label} formik={formik} placeholder={placeholder} isFieldRow={isFieldRow} required={required} />,
    question: (
      <TextArea name="question" type="text" label={label} formik={formik} isFieldRow={isFieldRow} placeholder={placeholder} required={required} />
    ),
    headline: (
      <TextArea name="headline" type="text" label={label} formik={formik} />
    ),
    answer: (
      <TextArea name="answer" type="text" label={label} formik={formik} isFieldRow={isFieldRow} placeholder={placeholder} required={required} />
    ),
    url: <Input name="url" type="text" label={label} formik={formik} />,
    url_tag: <Input name="url_tag" type="text" label={label} formik={formik} />,
    use_url_tag: <CheckBox name="use_url_tag" label={label} formik={formik} />,
    link: <Input name="link" type="text" label={label} formik={formik} />,
    video_url: (
      <>
        <MediaUploader
          name="video_url"
          type="text"
          label={label}
          formik={formik}
          metadata={metadata}
          mediaType="video"
          disabled={disableVideoURL}
        />
      </>
    ),
    host_image_url: (
      <MediaUploader
        name="host_image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
      />
    ),
    content_screen_video_url: (
      <MediaUploader
        name="content_screen_video_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="video"
      />
    ),
    image_url: (
      <MediaUploader
        name="image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
        placeholder={placeholder}
        required={required}
        maxSize={maxSize}
      />
    ),
    audio_url: (
      <MediaUploader
        name="audio_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="audio"
      />
    ),
    document_section_url: (
      <div>
        <MediaUploader
          name="document_section_url"
          type="text"
          label={label}
          formik={formik}
          metadata={metadata}
          mediaType={platformVersion >= 8.5 ? "file" : "pdf"}
        />
        <div
          className="text-sm italic mb-3"
          style={{
            marginTop: -12,
          }}
        >
          {platformVersion >= 9.0
            ? "The ff. formats are accepted (pdf, doc, docx, txt, ppt, pptx, xls, xlsx)"
            : "The ff. formats are accepted: pdf, doc, docx, txt, ppt, pptx, xls, xlsx; however it will only display for apps on Platform Version 9.0 & up and WEB"}
        </div>
      </div>
    ),
    access_url: (
      <Input name="access_url" type="text" label={label} formik={formik} />
    ),
    description: (
      <RichText
        id={`description-${nanoid()}`}
        name="description"
        quillName={quillName}
        type="text"
        label={label}
        rows={rows || 21}
        formik={formik}
        contentType={contentType}
        placeholder={placeholder}
        isFieldRow={isFieldRow}
      />
    ),
    sub_description: (
      <Input name="sub_description" type="text" label={label} formik={formik} />
    ),
    notes: <TextArea name="notes" type="text" label={label} formik={formik} />,
    message: (
      <TextArea name="message" type="text" label={label} formik={formik} />
    ),
    video_description: (
      <TextArea
        name="video_description"
        type="text"
        label={label}
        formik={formik}
        rows={rows}
        isFieldRow={isFieldRow}
        placeholder={placeholder}
        required={required}
      />
    ),
    journal_description: (
      <TextArea
        name="journal_description"
        type="text"
        label={label}
        formik={formik}
        isFieldRow={isFieldRow}
        required={required}
      />
    ),
    review_video_url: (
      <MediaUploader
        name="review_video_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="video"
      />
    ),
    review_image_url: (
      <MediaUploader
        name="review_image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
      />
    ),
    listing_image_url: (
      <MediaUploader
        name="listing_image_url"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="image"
      />
    ),
    send_to: (
      <Input name="send_to" type="email" label={label} formik={formik} />
    ),
    question_1: (
      <TextArea name="question_1" type="text" label={label} formik={formik} />
    ),
    question_2: (
      <TextArea name="question_2" type="text" label={label} formik={formik} />
    ),
    question_3: (
      <TextArea name="question_3" type="text" label={label} formik={formik} />
    ),
    question_4: (
      <TextArea name="question_4" type="text" label={label} formik={formik} />
    ),
    question_5: (
      <TextArea name="question_5" type="text" label={label} formik={formik} />
    ),
    journal_1: (
      <TextArea name="journal_1" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />
    ),
    journal_2: (
      <TextArea name="journal_2" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />
    ),
    journal_3: (
      <TextArea name="journal_3" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />
    ),
    journal_4: (
      <TextArea name="journal_4" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />
    ),
    journal_5: (
      <TextArea name="journal_5" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />
    ),
    journal_image_url: (
      <div className="flex">
        <div className="w-1/3">
          <p className="text-lg">{label}</p>
        </div>
        <div className="w-2/3">
          <MediaUploader
            name={name}
            type="text"
            formik={formik}
            metadata={metadata}
            mediaType="image"
            isUploadOnly
          />
        </div>
      </div>
    ),
    topics: (
      <>
        {pathname.includes("tips") || pathname.includes("action-lists") ? (
          <VersionizerWrapper minVersion={9}>
            <DataSelector
              name="topics"
              formik={formik}
              label="Topics/Listings"
              resourceEndpoint="topics"
              sortByName="true"
            />

            {pathname.includes("action-lists") ? (
              <div className="italic mt-1 mb-2 -mt-4 text-right">
                Coming soon in WEB
              </div>
            ) : null}
          </VersionizerWrapper>
        ) : (
          <DataSelector
            name="topics"
            formik={formik}
            label="Topics/Listings"
            resourceEndpoint="topics"
            sortByName="true"
            required={required}
          />
        )}
      </>
    ),
    author: <Input name="author" type="text" label={label} formik={formik} isFieldRow={isFieldRow} required={required} />,
    price: <Input name="price" type="text" label="Price" formik={formik} />,
    release_date: (
      <DateSelector
        name="release_date"
        type="text"
        label={label}
        formik={formik}
        format={format}
      />
    ),
    start_date: (
      <DateSelector
        name="start_date"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    end_date: (
      <DateSelector name="end_date" type="text" label={label} formik={formik} />
    ),
    enroll_date: (
      <DateSelector
        name="enroll_date"
        type="text"
        label={label}
        formik={formik}
        format={format}
      />
    ),
    action_bar_text: (
      <Input name="action_bar_text" type="text" label={label} formik={formik} />
    ),
    action_bar_text_actionclip: (
      <div className="italic mt-1 text-right">
        <i>Will not display in ActionClip, only on regular video</i>
      </div>
    ),
    action_bar_url: (
      <Input name="action_bar_url" type="text" label={label} formik={formik} />
    ),
    sort_order: (
      <Input name="sort_order" type="number" label={label} formik={formik} />
    ),
    active: (
      <CheckBox name="active" label={label} formik={formik} width={width} />
    ),
    auto_approve: (
      <div className="flex flex-col pt-2">
        <label className={`${width && "flex"} text-lg mb-3`} htmlFor="auto_approve">
          {label}
        </label>
        <div className="flex flex-row items-center gap-10">
          <CheckBox name="auto_approve_new" label='New submissions' formik={formik} width={width} disabled={disabled} isInline />
          <CheckBox name="auto_approve_edit" label='Edits to submissions' formik={formik} width={width} disabled={disabled} isInline />
        </div>
      </div>
    ),
    featured: (
      <CheckBox name="featured" label={label} formik={formik} width={width} />
    ),
    tags: (
      <DataSelector
        name="tags"
        formik={formik}
        label="Tags"
        resourceEndpoint="tags?category=Content"
        filterOptions={(values) => values.filter((a) => !a.is_expired)}
      />
    ),
    summit_tag: (
      <DataSelector
        single
        singleSelectOnly
        name="summit_tag.id"
        formik={formik}
        label="Summit Tag"
        resourceEndpoint="tags?category=Summit"
        filterOptions={(options) => {
          return options.filter((a) => !a.is_expired);
        }}
      />
    ),
    vip_tag: (
      <DataSelector
        single
        singleSelectOnly
        name="vip_tag.id"
        formik={formik}
        label="VIP Tag"
        resourceEndpoint="tags?category=Content"
        filterOptions={(options) => {
          return options.filter((a) => !a.is_expired);
        }}
      />
    ),
    course_name: (
      <DataSelector
        single
        singleSelectOnly
        keyOption="title"
        name="course_name"
        formik={formik}
        label="Course Name"
        resourceEndpoint="courses"
        filteredOptions={selectedValues}
      />
    ),
    cost: <Input name="cost" type="text" label={label} formik={formik} />,
    frequency: (
      <Select name={name} label={label} formik={formik} required={true}>
        <option value="">- Select {label} -</option>
        <option value="Once">Once</option>
        <option value="Daily">Daily</option>
        <option value="Weekly">Weekly</option>
        <option value="Custom">Custom</option>
      </Select>
    ),
    custom_frequency: (
      <div className="flex flex-col mb-5">
        <label className="text-lg mb-3">{label}</label>
        <CheckboxGroup
          formik={formik}
          name={name}
          children={["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]}
        />
      </div>
    ),
    journal_list: <CheckBox name={name} label={label} formik={formik} />,
    short_description: (
      <Input
        name="short_description"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    has_details: <CheckBox name={name} label={label} formik={formik} />,
    drawer_sub_description: (
      <Input name="description" type="text" label={label} formik={formik} />
    ),
    rss_url: (
      <Input
        name={name}
        type="text"
        label={label}
        formik={formik}
        placeholder={placeholder}
      />
    ),
    web_url: (
      <Input
        name={name}
        type="text"
        label={label}
        formik={formik}
        placeholder={placeholder}
        isFieldRow={isFieldRow}
      />
    ),
    document_url: (
      <>
        <DocumentUploader
          name={name}
          label={label}
          formik={formik}
          acceptedTypes={".doc,.docx,.txt,.pdf,.ppt,.pptx,.zip,.xlsx,.xls"}
          metadata={metadata}
        />
        <div style={{ marginTop: 0 }} className="text-right mb-4">
          <i>
            {contentType === "documents" && platformVersion >= 9.0
              ? "The ff. formats are accepted (pdf, doc, docx, txt, ppt, pptx, xls, xlsx)"
              : "The ff. formats are accepted: pdf, doc, docx, txt, ppt, pptx, xls, xlsx; however it will only display for apps on Platform Version 9.0 & up and WEB"}
          </i>
        </div>
      </>
    ),
    form_type: (
      <Select
        name="form_type"
        label={label}
        formik={formik}
        disabled={disabled}
      >
        <option value="">- Select {label} -</option>
        <option value="Topic">Listing</option>
        <option value="Profile">Profile</option>
        <option value="Offer">Special Offer</option>
      </Select>
    ),
    actionform_description: (
      <TinyEditor
        name="actionform_description"
        type="text"
        label={label}
        formik={formik}
      />
    ),
    submit_link: (
      <Input name="submit_link" type="text" label={label} formik={formik} />
    ),
    link_expiry_date: (
      <>
        <DateSelector
          name="link_expiry_date"
          type="text"
          label={label}
          formik={formik}
          format={format}
        />
        {pathname.includes("action-forms") && (
          <div style={{ marginTop: -12 }} className="text-right mb-4">
            <i>
              Default is 90 days. You can extend or shorten this in the above
              field
            </i>
          </div>
        )}
      </>
    ),
    directories: (
      <DataSelector
        name="directories"
        formik={formik}
        label="Which Directory?"
        resourceEndpoint="directories"
        withLegend={isEnterprise}
        filterOptions={(!isEnterprise) ? (values) => values.filter((a) => !a.enterprise): (values) => values}
      />
    ),
    actionclip: (
      <>
        <VersionizerWrapper minVersion={9}>
          <CheckBox name="actionclip" label={label} formik={formik} />
        </VersionizerWrapper>
      </>
    ),
    actionclip_description: (
      <>
        <VersionizerWrapper minVersion={9}>
          <Input
            name="actionclip_description"
            type="text"
            label={label}
            formik={formik}
          />
        </VersionizerWrapper>
      </>
    ),
    directory_type: (
      <div className="flex flex-row w-full justify-between">
        <label
          className={`text-lg mb-5 w-1/3`}
          htmlFor={name}
        >
          {label}
        </label>
        <Radio.Group
          formik={formik}
          className="flex flex-row w-2/3"
          disabled={disabled}
          value={formik?.values?.directory_type ?? ""}
        >
          <Radio
            value="experts"
            disabled={disabled}
          >
            Experts
          </Radio>
          <Radio
            value="resources"
            disabled={disabled}
          >
            Resources
          </Radio>
        </Radio.Group>
      </div>
    ),
    "youtube_or_vimeo": (
      <MediaUploader
        name="youtube_or_vimeo"
        type="text"
        label={label}
        formik={formik}
        metadata={metadata}
        mediaType="video"
        disabled={disableVideoURL}
        placeholder={placeholder}
        required={required}
        isUrlOnly
        isErrorMessageBelowField
      />
    ),
    gallery_description: customFields('textarea', { label, formik, name, placeholder, isFieldRow, rows, required }),
    photos: (
      <div className="w-full flex flex-row mt-5">
        <div className="w-1/3">
          <p className="text-lg">
            Gallery Image
            {required && <span style={{ color: "red" }}> *</span>}
          </p>
        </div>
        <div className="my-4 w-2/3">
          <BulkPhotos formik={formik} isUploadOnly/>

          {formik?.values?.photos?.length > 0 && (
            <div className="mt-5">
              <h2 className="font-bold text-lg">Uploaded</h2>

              {formik.values.photos.map((photo) => {
                return Object.values(photo.media_metadata).map((value) => {
                  return (
                    <li key={value.id} className="mt-2 flex">
                      <button
                        type="button"
                        className="ml-2"
                        onClick={() => {
                          formik.setFieldValue('photos', formik?.values.photos.filter((photoVal) => photoVal.id !== photo.id))
                        }}
                      >
                        <DeleteIcon className="mr-2 h-5 w-5 fill-current text-gray-400 trash hover:text-red-600" />
                      </button>
                      {value.filename}
                    </li>
                  )
                })
              })}
            </div>
          )}
        </div>
      </div>
    ),
    will_use_business_name: (
      <div className="flex justify-end mb-5">
        <div className="my-4 w-2/3">
          <Checkbox 
            id={name}
            name={name}
            onChange={(event) => {
              formik.setFieldValue('will_use_business_name', event.target.checked)

              if (!event.target.checked) formik.setFieldValue('profile_name', "")
            }}
            checked={formik.values.will_use_business_name}
          />
          <label htmlFor={name} className="text-lg ml-2"> {label}</label>
        </div>
      </div>
    ),
    profile_image_url: (
      <div className="flex">
        <div className="w-1/3">
          <p className="text-lg">{label}</p>
        </div>
        <div className="w-2/3">
          <MediaUploader
            name={name}
            type="text"
            formik={formik}
            metadata={metadata}
            mediaType="image"
            isUploadOnly
          />
        </div>
      </div>
    ),
    onboarding_profile_image_url: (
      <div className="flex">
        <div className="w-1/3">
          <p className="text-lg">{label} {required && <span style={{ color: "red" }}>*</span>}</p>
        </div>
        <div className="w-2/3">
          <MediaUploader
            name={name}
            type="text"
            formik={formik}
            metadata={metadata}
            mediaType="image"
            isUploadOnly
          />
        </div>
      </div>
    ),
    business_name: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    profile_name: customFields('text', { label, formik, name, placeholder, isFieldRow, required, disabled: formik.values.will_use_business_name }),
    bio: (
      <div className="mt-6">
        {customFields('textarea', { label, formik, name, placeholder, isFieldRow, rows, required })}
      </div>
    ),
    facebook_url: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    twitter_url: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    instagram_url: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    linkedin_url: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    youtube_url: customFields('text', { label, formik, name, placeholder, isFieldRow }),
    welcome_headline: (
      <Input
        name={name}
        type="text"
        label={label}
        formik={formik}
        placeholder={placeholder}
        isFieldRow={isFieldRow}
      />
    ),
    welcome_media_type: (
      <>
        <div className="flex flex-row w-full justify-between mb-5">
          <label
            className={`text-lg mb-5 w-1/3`}
            htmlFor={name}
          >
            {label}
            {required && label !== "Welcome Image or Video" && <span style={{ color: "red" }}> *</span>}
          </label>
          <div className="w-2/3">
            <Radio.Group
              name={name}
              className="flex flex-row"
              formik={formik}
              disabled={disabled}
              onChange={formik.handleChange}
              value={formik?.values?.welcome_media_type}
            >
              <Radio
                className="text-base mr-10"
                value="image"
                disabled={disabled}
              >
                Welcome Image
              </Radio>
              <Radio
                className="text-base"
                value="video"
                disabled={disabled}
              >
                Welcome Video (Link)
              </Radio>
            </Radio.Group>
            <p className="text-sm mt-4 text-gray-400">*You can display EITHER a Welcome Image OR Video</p>
          </div>
        </div>
        {formik.values.welcome_media_type === 'image' && (
          <div className="flex">
            <div className="w-1/3">
              <p className="text-lg">Welcome/Banner Image {required && <span style={{ color: "red" }}> *</span>}</p>
            </div>
            <div className="w-2/3">
              <MediaUploader
                name="image_url"
                type="text"
                formik={formik}
                metadata={metadata}
                mediaType="image"
                isUploadOnly
              />
              <div className="text-sm mt-2 mb-5">
                Try <a href="https://pixabay.com" target="_blank" rel="noreferrer">Pixabay.com</a> or 
                <a href="https://unsplash.com"> Unsplash.com </a> 
                for free commercial images.
              </div>
            </div>
          </div>
        )}
        {formik.values.welcome_media_type === 'video' && (
          <MediaUploader
            name="video_url"
            type="text"
            label="Welcome Video (Link)"
            formik={formik}
            metadata={metadata}
            placeholder="Welcome/ Explainer video (public unlisted YouTube/ Vimeo links ONLY)"
            mediaType="video"
            isUrlOnly
            required={required}
            isErrorMessageBelowField
          />
        )}
      </>
    ),
    welcome_message: (
      <TextArea name={name} type="text" label={label} formik={formik} placeholder={placeholder} rows={4} isFieldRow={isFieldRow} required={required} />
    ),
    topic: (
      <>
        <Input
          name={name}
          type="text"
          label={label}
          formik={formik}
          isFieldRow={isFieldRow}
          placeholder={placeholder}
          required={required}
        />
        <div className="flex">
          <div className="w-1/3">
            <p className="text-lg">Topic Icon</p>
          </div>
          <div className="w-2/3">
            <MediaUploader
              name="image_url"
              type="text"
              formik={formik}
              metadata={metadata}
              mediaType="image"
              isUploadOnly
            />
            <div className="text-sm mt-2 mb-5">
              Try <a className="text-sm" href="https://flaticon.com">Flaticon.com</a> or 
              <a className="text-sm" href="https://pixabay.com" target="_blank" rel="noreferrer"> Pixabay.com </a> 
              for free commercial images.
            </div>
          </div>
        </div>
      </>
    ),
    app_name: (
      <Input
        name="app_name"
        type="text"
        label={label}
        formik={formik}
        isFieldRow={isFieldRow}
        placeholder={placeholder}
        disabled={true}
        required={required}
      />
    ),
    in_app_name: (
      <>
        <div className="flex">
          <div className="w-1/3">
            <p className="text-lg">
              In-App Name <span style={{ color: "red" }}> *</span>
            </p>
          </div>
          <div className="w-2/3">
            <Input
              name="in_app_name"
              type="text"
              label={label}
              formik={formik}
              isFieldRow={isFieldRow}
              placeholder={placeholder}
              disabled={disabled}
            />
            <Description className="w-full text-xs text-gray-400 mb-10 text-left">
              Usually the same name as the App Store name (left image, above)
            </Description>
          </div>
        </div>
      </>
    ),
    app_icon_name: (
      <>
        <div className="flex">
          <div className="w-1/3">
            <p className="text-lg">
              App Icon Name <span style={{ color: "red" }}> *</span>
            </p>
          </div>
          <div className="w-2/3">
            <Input
              name="app_icon_name"
              type="text"
              label={label}
              formik={formik}
              isFieldRow={isFieldRow}
              placeholder={placeholder}
              disabled={disabled}
            />
            <Description className="w-full text-xs text-gray-400 mb-10 text-left">
              11 characters only, with spaces (right image, above)
            </Description>
          </div>
        </div>
      </>
    ),
    short_app_description: (
      <Input
        name="short_app_description"
        type="text" label={label}
        formik={formik}
        isFieldRow={isFieldRow}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
      />
    ),
    full_app_description: (
      <TextArea
        name="full_app_description"
        type="text"
        label={label}
        formik={formik}
        placeholder={placeholder}
        isFieldRow={isFieldRow}
        required={required}
        disabled={disabled}
      />
    ),
    primary_category: [
      <Select
        name="primary_category"
        label={label}
        formik={formik}
        disabled={disabled}
        isFieldRow={isFieldRow}
        required={required}
        defaultValue=""
      >
        <option value="" disabled>
          Main store category for your App (recommend 'Education' of 'Lifestyle')
        </option>
        {categoryList.map(category => (
          <option key={Math.random()} value={category}>{category}</option>
        ))}
      </Select>
    ],
    secondary_category: [
      <Select
        name="secondary_category"
        label={label}
        formik={formik}
        disabled={disabled}
        isFieldRow={isFieldRow}
        required={required}
        defaultValue=""
      >
        <option value="" disabled>Second Store category for your app</option>
        {categoryList.map(category => (
          <option key={Math.random()} value={category}>{category}</option>
        ))}
      </Select>
    ],
    search_keywords: (
      <>
        <div className="flex">
          <div className="w-1/3">
            <p className="text-lg">
              App Search Keywords <span style={{ color: "red" }}> *</span>
            </p>
          </div>
          <div className="w-2/3">
            <TextArea
              name="search_keywords"
              className="w-full"
              type="text"
              label={label}
              formik={formik}
              placeholder={placeholder}
              isFieldRow={isFieldRow}
              disabled={disabled}
            />
            <Description className="text-xs text-gray-400 mb-10">
              Don’t include both plurals & singulars; don’t add category names or include your App name again;
              avoid unauthorized trademarks, competitor’s app names, celebrity names & offensive words
            </Description>
          </div>
        </div>
      </>
    ),
    search_keywords_note: (
      <div
        className="flex flex-row items-center gap-1 p-2 mt-3 mb-5"
        style={{
          background: "#f5f5f5",
          border: "1px solid #cccccc",
        }}
      >
        <p>
           <span className="font-bold">NOTE:</span> You can get ideas for keywords by researching your competitors (
           <a
              className="font-bold whitespace-nowrap"
              style={{
                color: "#4D7C0F"
              }}
              href="https://www.adjust.com/blog/7-quick-tips-to-optimize-your-app-store-keywords/"
              target="_blank"
              rel="noreferrer"
            >
              about App Keywords
          </a>
          ).
        </p>
      </div>
    ),
    support_contact: (
      <div className="flex flex-row w-full mb-5">
        <label
          className={`text-lg mb-5 w-1/3`}
          htmlFor={name}
        >
          {label} {required && <span style={{ color: "red" }}> *</span>}
        </label>
        <div className="w-2/3">
          <Radio.Group
            name={name}
            formik={formik}
            className="flex flex-col w-2/3"
            disabled={disabled}
            onChange={event => {
              formik.setFieldValue("support_contact", event.target.value);
            }}
            value={formik?.values?.support_contact}
          >
            <Radio
              value="Use my current website"
              disabled={disabled}
            >
              Use my current website 
            </Radio>
            <Radio
              value="Use my 'Companion Website' profile"
              disabled={disabled}
            >
              Use my 'Companion Website' profile
            </Radio>
          </Radio.Group>
          <Description className="w-full text-xs text-gray-400 mt-3 text-left">
            We build your Companion profile as part of your content
          </Description>
          {!formik.values['support_contact']&& (
            <div className="text-red-600">{formik.errors['support_contact']}</div>
          )}
        </div>
      </div>
    ),
    support_contact_page: (
      <>
        {formik?.values?.support_contact === "Use my current website" && (
          <div className="flex flex-row w-full mb-5">
            <label
              className={`text-lg mb-5 w-1/3`}
              htmlFor={name}
            >
              {label} {required && <span style={{ color: "red" }}> *</span>}
            </label>
            <div className="w-2/3">
                <div>
                  <Input
                      name="support_contact_page"
                      type="text"
                      formik={formik}
                      isFieldRow={isFieldRow}
                      placeholder={placeholder}
                      disabled={disabled}
                    />
                </div>
                <Description className="w-full text-xs text-gray-400 text-left">
                  For users who have questions or feedback regarding your App
                </Description>
              </div>
          </div>
        )}
      </>
    ),
    companion_website: (
      <>
        {formik?.values?.support_contact === "Use my 'Companion Website' profile" && (
          <Input
            name="companion_website"
            type="text"
            label={label}
            formik={formik}
            isFieldRow={isFieldRow}
            placeholder={placeholder}
            disabled={true}
            required={required}
          />
        )}
      </>
    ),
    privacy_policy: (
      <div className="flex flex-row w-full mb-5">
        <label
          className={`text-lg mb-5 w-1/3`}
          htmlFor={name}
        >
          {label} {required && <span style={{ color: "red" }}> *</span>}
        </label>
        <div className="flex flex-col w-2/3">
          <Radio.Group
            name={name}
            formik={formik}
            className="flex flex-col"
            disabled={disabled}
            onChange={event => {
              formik.setFieldValue("privacy_policy", event.target.value);
              formik.setFieldTouched("privacy_policy");
            }}
            value={formik?.values?.privacy_policy}
          >
            <Radio
              value="Use my current website"
              disabled={disabled}
            >
              Use my current website 
            </Radio>
            <Radio
              value="Add this to my 'Companion Website'"
              disabled={disabled}
            >
              Add this to my 'Companion Website'
            </Radio>
          </Radio.Group>
          {!formik.values['privacy_policy'] && (
            <div className="text-red-600">{formik.errors['privacy_policy']}</div>
          )}
        </div>
      </div>
    ),
    privacy_policy_page: (
      <div className="mb-5">
        {formik?.values?.privacy_policy === "Use my current website" && (
          <div>
            <Input
                name="privacy_policy_page"
                type="text"
                label={label}
                formik={formik}
                isFieldRow={isFieldRow}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
              />
          </div>
        )}
      </div>
    ),
    privacy_policy_document: (
      <>
        {formik?.values?.privacy_policy === "Add this to my 'Companion Website'" && (
          <div className="flex flex-row w-full mb-5">
            <div className="w-1/3">
              {label} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="w-2/3">
              <FileUpload
                onError={(err) => formik.setFieldError("privacy_policy_document", err)}
                error={errorLogo}
                errorMessage={
                  "Only doc docx rtf txt odt formats are allowed"
                }
                isButton={false}
                buttonClassName=""
                uploadType="file"
                disabled={disabled}
                acceptedTypes=
                  "text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf, text/rtf, application/vnd.oasis.opendocument.text"
                fileType=
                  "text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf, text/rtf, application/vnd.oasis.opendocument.text"
                onChange={(val, metadata) => {
                  formik.setFieldValue("privacy_policy_document", val);
                  formik.setFieldTouched("privacy_policy_document");
                  if(metadata){
                    formik.setFieldValue('media_metadata', {
                      ...formik.values.media_metadata,
                      [metadata.id]: metadata
                    })
                  }
                }}
                metaData={formik.values.media_metadata}
                value={formik.values.privacy_policy_document}
              >
                <div className="p-6 flex flex-row justify-between items-center bg-white">
                  <div>Choose File(s)</div>
                  <div>
                    <div
                      className="p-4 rounded-full"
                      style={{
                        background: "#ebebeb",
                      }}
                    >
                      <img
                        src={UploadIcon}
                        alt="Upload File"
                        className="w-3 h-3"
                      />
                    </div>
                  </div>
                </div>
              </FileUpload>
            </div>
          </div>
        )}
      </>
    ),
    privacy_policy_note: (
      <div
        className="flex flex-row items-center gap-1 p-2 mt-3 mb-5"
        style={{
          background: "#f5f5f5",
          border: "1px solid #cccccc",
        }}
      >
        <a
          className="font-bold whitespace-nowrap"
          href="https://drive.google.com/drive/folders/1Z1IDbnEBDN4F0tlHaIkD2hi9eZkae2IV"
          target="_blank"
          rel="noreferrer"
        >
          Download Privacy Policy&darr;:
        </a>
        <p>
          (Download the document, then change the&nbsp;
          <span style={{color: '#FF0000', fontWeight: 'bold'}}>RED</span> text in this document to your App's info).
        </p>
      </div>
    ),
    support_email: (
      <div className="flex flex-row w-full mb-5">
        <label
          className={`text-lg mb-5 w-1/3`}
          htmlFor={name}
        >
          {label} {required && <span style={{ color: "red" }}> *</span>}
        </label>
        <div className="w-2/3">
          <Input
            name="support_email"
            type="text"
            formik={formik}
            isFieldRow={isFieldRow}
            placeholder={placeholder}
            disabled={disabled}
          />
          <Description className="w-full text-xs text-gray-400 text-left">
            This can also be am email “forward”, or a free Gmail/other account
          </Description>
          </div>
      </div>
    ),
    enterprise: (
      user?.app_data?.setup?.enterprise && (
        <CheckBox name="enterprise" label={label} formik={formik} />
      )
    ),
    article_image_url: (
      <div className="flex">
        <div className="w-1/3">
          <p className="text-lg pr-2">{label}</p>
        </div>
        <div className="w-2/3">
          <MediaUploader
            name={name}
            type="text"
            formik={formik}
            metadata={metadata}
            mediaType="image"
            isUploadOnly
          />
        </div>
      </div>
    ),
    app_designed: (
      <div className="flex flex-row space-between mb-5">
        <div className="w-1/3">
          <p className="text-lg pr-2">{label}</p>
        </div>
        <div className="dropdown-select w-2/3">
          <select name={name} value={formik.values[name]} onChange={(evt) => formik.setFieldValue(name, evt.target.value)}>
            <option value="get_started" label="Get Started" />
            <option value="mockups_sent" label="Mockups Sent ✓" />
            <option value="approval" label="WAIT: Approval" />
            <option value="logo" label="WAIT: Logo" />
            <option value="reply" label="WAIT: Reply" />
            <option value="client" label="WAIT: Client" />
            <option value="meeting" label="WAIT: Meeting" />
            <option value="payment" label="WAIT: Payment" />
            <option value="hold" label="HOLD" />
            <option value={DONE} label="DONE ✓" />
          </select>
        </div>
      </div>
    ),
    app_designed_due_date: (
      <DateSelector
        name={name}
        type="text"
        label={label}
        formik={formik}
        isFieldRow
      />
    ),
    app_designed_graphics_file: (
      <FileUploader
        label={label}
        name={name}
        required={required}
        errorMessage="You can only upload a .zip file"
        acceptedTypes=".zip"
        fileTypes="application/zip, application/x-zip-compressed, application/octet-stream"
        formik={formik}
        value={formik.values[name]}
      />
    ),
    app_built: (
      <div className="flex flex-row space-between mb-5">
        <div className="w-1/3">
          <p className="text-lg pr-2">{label}</p>
        </div>
        <div className="dropdown-select w-2/3">
        <select name={name} value={formik.values[name]} onChange={(evt) => formik.setFieldValue(name, evt.target.value)}>
            <option value="get_started" label="Get Started" />
            <option value="create_ios_app" label="Create iOS App" />
            <option value="setup_firebase" label="Setup Firebase" />
            <option value="create_android_app" label="Create Android App" />
            <option value="meet_with_creator" label="Meet with Creator" />
            <option value="hold" label="HOLD" />
            <option value={DONE} label="DONE ✓" />
          </select>
        </div>
      </div>
    ),
    push_key_file: (
      <FileUploader
        label={label}
        name={name}
        required={required}
        errorMessage=""
        acceptedTypes="*"
        formik={formik}
        value={formik.values[name]}
      />
    ),
    json_file: (
      <FileUploader
        label={label}
        name={name}
        required={required}
        errorMessage="You can only upload a .json file"
        acceptedTypes=".json"
        fileTypes="application/json"
        formik={formik}
        value={formik.values[name]}
      />
    ),
    aab_file: (
      <FileUploader
        label={label}
        name={name}
        required={required}
        errorMessage="You can only upload a .AAB file"
        acceptedTypes=".aab"
        fileTypes="text/aab"
        formik={formik}
        value={formik.values[name]}
      />
    ),
    qrcode: (
      <div>
        <Input
          name={name}
          label={label}
          formik={formik}
          isFieldRow={isFieldRow}
          placeholder={placeholder}
          required={required}
        />

        <FileUploader
          label=""
          name="qrcode_file"
          required={required}
          errorMessage="You can only upload .jpeg, .jpg, or .png"
          acceptedTypes=".jpg,.png,.jpeg"
          fileTypes="image/jpeg, image/png, image/jpg"
          uploadType="image"
          formik={formik}
          value={formik.values.qrcode_file}
        />
      </div>
    )
  };

  return fieldMap[name] || customComponent;
};
