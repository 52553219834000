import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { GetAppResource } from "../services/api/app";
import { DeleteEvent } from "../services/api/events";

import moment from "moment";

import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import DeleteItem from "../components/modal_contents/DeleteItem";
import { CloneItem } from "../services/api/clone";

const EventCalendarList = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState({
        loading: false,
        data: [],
        count: 0,
    })

    const history = useHistory();

    const { user } = useUserProvider();
    const { showModal, closeModal } = useModalProvider();

    const loadData = async () => {
        setData({ ...data, loading: true })
        GetAppResource(user, 'events')
            .then(res => res.json())
            .then(jsonData => {
                setData({
                    loading: false,
                    data: jsonData.data,
                    count: jsonData.data.length
                })
            })
    }

    const links = [
        { name: "Home", url: "/" },
        { name: 'ActionCalendar', url: '/events' },
    ]

    const updateButtonHandler = (id) => {
        history.push(`/events/${id}`)
    }

    const deleteButtonHandler = async (id) => {
        await DeleteEvent(id, user.idToken)
        loadData()
        closeModal()
    }

    const showDeleteModal = (id) => {
        showModal(<DeleteItem itemId={id} itemLabel='ActionCalendar' onDelete={deleteButtonHandler} onClose={closeModal} />)
    }

    const config = {
        listMetaData: [
            'type',
            'name',
            'description',
            'month',
            'day_of_month',
            'expiration'
        ]
    }

    const metadata = [
        // {
        //   name: 'key',
        //   label: 'Key',
        //   parser: (data) => (data.id),
        // },
        {
            name: 'category',
            label: 'Type',
            parser: (data) => (data.category),
        },
        {
            name: 'name',
            label: 'Event Name',
            width: 150,
            parser: (data) => (data.name),
            ellipsis: true,
        },
        {
            name: 'tags',
            label: 'Tags',
            width: 220,
            parser: (data) => (data.tags.map(a => a.name).join(', ')),
            ellipsis: true,
        },
        {
            name: 'month',
            label: 'Month',
            parser: (data) => (data.month),
        },
        {
            name: 'day_of_month',
            label: 'Day of Month',
            parser: (data) => (data.day_of_month),
        },
        {
            name: 'expiration',
            label: 'Event Listing Expires (Date)',
            parser: (data) => {
                const momentDate = moment(data.expiration);
                if (momentDate.isValid()) {
                  return momentDate.format("DD-MMM-yyyy");
                }
                return "";
              },
        },
        // {
        //     name: 'time',
        //     label: 'Time',
        //     parser: (data) => (data.time),
        // },
        // {
        //     name: 'location',
        //     label: 'Location',
        //     width: 220,
        //     parser: (data) => (data.location),
        // },
        {
            name: 'actions',
            label: 'Actions',
            parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} onCloneClick={cloneButtonHandler}/>),
        },
    ]

    const handleAddButtonClick = () => {
        history.push(`/events/new`)
    }

    const cloneButtonHandler  = async (id) => {
        const res = await CloneItem(user, {
          data: {
            id,
            name: 'events'
          }
        })
        const cloneData = await res.json()
        const cloneId = cloneData.data?.id

        loadData();
        history.push(`/events/${cloneId}`)
      };

    const AddButton = () => {
        return <button className="ttnk-button add-button" onClick={handleAddButtonClick}>Add Event</button>
    }

    return (
        <Page links={links} title={'ActionCalendar'} titleComponent={AddButton()}>
            <DataTable metadata={metadata}
                dataLoader={loadData}
                data={data}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                searchKeys={['name']} />
        </Page>
    )
}

export default EventCalendarList;