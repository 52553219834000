import { useState } from "react";
import { Table } from "antd";
import cloneDeep from "lodash.clonedeep";
import TableActions from "./TableActions";
import Fuse from "fuse.js";
import { useDrawerProvider } from "../providers/DrawerProvider";
import { useModalProvider } from "../providers/ModalProvider";
import DeleteItem from "../components/modal_contents/DeleteItem";
import ContentForm from "../components/ContentForm";
import Search from "../assets/icons/search.svg";
import contentConfig from "../lib/content_config/config";
import { hash, setState, getState } from "../lib/form";

const customFieldsWidth = "50%";
const SubTable = ({
  formik,
  labels,
  initialValues,
  subContentType,
  contentType,
  searchKeys,
  disclaimer
}) => {
  const { showDrawer, closeDrawer } = useDrawerProvider();
  const { showModal, closeModal } = useModalProvider();
  const [searchValue, setSearchValue] = useState();
  const listMetaData = [
    ...contentConfig(contentType).subTables[subContentType].listMetadata,
  ];
  const isCustomFields =
    contentConfig(contentType).subTables[subContentType].customFields;
  const customWidth = isCustomFields && customFieldsWidth;

  const columns = listMetaData
    .map((item) => {
      return {
        title: item.label,
        dataIndex: item.name,
        hide: item.hide,
        ellipsis: item.ellipsis,
      };
    })
    .filter((i) => {
      if (i.dataIndex !== "key" && !i.hide) {
        return true;
      }
      return false;
    });

  columns.push({
    title: "Actions",
    align: "center",
    dataIndex: "actions",
    width: "150px",
    render: (text, record, index) => {
      return (
        <TableActions
          onUpdateClick={onUpdateClick}
          onDeleteClick={showDeleteModal}
          item_id={record.id}
          item_index={index}
        />
      );
    },
  });

  const dataParser = (data) => {
    let _data = [];
    if (_data.error) _data = [];
    for (let i = 0; i < data.length; i++) {
      _data.push(
        listMetaData.reduce((map, attr) => {
          map[attr.name] = attr.parser(data[i]);
          return map;
        }, {})
      );
    }

    if (searchKeys && searchValue) {
      const returnData = new Fuse(_data, {
        keys: searchKeys,
        threshold: 0.3,
      });
      return returnData.search(searchValue).map((d) => d.item);
    } else {
      return _data;
    }
  };

  const SearchBox = () => (
    <div className="border border-gray-200 flex h-8 rounded-full flex justify-center items-center">
      <img src={Search} className="w-4 h-4 ml-4" alt="" />
      <input
        id="search"
        name="search"
        type="text"
        className="text-sm px-2 rounded-full focus:outline-none"
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
    </div>
  );

  const deleteButtonHandler = async (id, itemIndex) => {
    let index = itemIndex;
    const updatedValues = cloneDeep(formik.values);
    if (id) {
      for (
        let i = 0, len = updatedValues[subContentType].length;
        i < len;
        i++
      ) {
        const row = updatedValues[subContentType][i];
        if (row.id === id) {
          index = i;
          break;
        }
      }
    }

    updatedValues[subContentType].splice(index, 1);
    formik.setFieldValue(subContentType, updatedValues[subContentType]);
    formik.setFieldTouched(subContentType);
    closeModal();
  };

  const showDeleteModal = (id, itemIndex) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemIndex={itemIndex}
        itemLabel={ subContentType === "items" ? "List Item" : "Section" }
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  const onSubmitClick = (payload, subContentId, subContentIndex) => {
    const updatedValues = cloneDeep(formik.values);
    if (typeof subContentIndex !== "undefined") {
      // Update request
      updatedValues[subContentType][subContentIndex] = payload;
    } else {
      // New content
      updatedValues[subContentType].push(payload);
    }

    // set reference first before validation -- validation runs upon invoking setFieldValue
    setState("payloadRef", subContentIndex);
    setState("formFieldRef", subContentType);

    formik.setFieldValue(subContentType, updatedValues[subContentType]);
    formik.setFieldTouched(subContentType, true, false);

    closeDrawer();
  };

  const onCancelClick = () => {
    closeDrawer();
  };

  const onUpdateClick = (id, index) => {
    showDrawer(
      <ContentForm
        subContentId={id}
        subContentIndex={index}
        onSubmitClick={onSubmitClick}
        onCancelClick={onCancelClick}
        initialValues={formik.values}
        subContentType={subContentType}
        contentType={contentType}
        isCustomFields={isCustomFields}
      />,
      isCustomFields ? "" : `Update ${labels.singular}`,
      customWidth
    );
  };

  const onAddClick = () => {
    showDrawer(
      <ContentForm
        newContent={true}
        onSubmitClick={onSubmitClick}
        onCancelClick={onCancelClick}
        initialValues={formik.values}
        subContentType={subContentType}
        contentType={contentType}
        isCustomFields={isCustomFields}
      />,
      isCustomFields ? "" : `Add new ${labels.singular}`,
      customWidth
    );
  };

  return (
    <>
      <div className="flex items-baseline">
        <h2 className="ttnk-page__subtitle mt-5">
          {labels.plural}{" "}
          <button
            type="button"
            onClick={onAddClick}
            className="ttnk-subtable-heading-button"
          >
            Add {labels.singular}
          </button>
        </h2>
        {
          disclaimer && (
            <p className="italic ml-2.5">{ disclaimer }</p>
          )
        }
      </div>
      <div className="bg-white ttnk-table-container ttnk-subtable">
        <div className="flex justify-between items-center">
          {searchKeys && SearchBox()}
        </div>
        <Table
          className="ttnk-table"
          dataSource={
            !!formik.values[subContentType]?.length
              ? dataParser(formik.values[subContentType])
              : []
          }
          pagination={false}
          columns={columns}
          loading={false}
          rowKey={(data, index) => {
            let id;

            if (data.id) {
              const keyOccurance =
                JSON.stringify(data)?.split(data?.id).length - 1;
              if (keyOccurance > 1) {
                id = hash(String(index));
              }
            } else id = Math.random();

            return id;
          }}
        />
        {/* <div className="ttnk-subtable-control-row">
          <Button
            className=" font-bold"
            onClick={onAddClick}
            icon={<Add />}
            type="link"
          >
            Add {labels.singular}
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default SubTable;