import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteVirtualEvent } from "../services/api/virtual_event";
import { GetAppResource } from "../services/api/app";

import DeleteItem from "../components/modal_contents/DeleteItem";

const VirtualEventList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, "virtual_events")
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "ActionEvents", url: "/virtual_events" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "sort_order",
      label: "Sort Order",
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => `${data.active}`,
    },
    {
      name: "name",
      label: "Event Name",
      parser: (data) => data.name,
    },
    {
      name: "tags",
      label: "Event Tag",
      parser: (data) => <span className="ttnk-tag">{data.tag.name}</span>,
    },
    {
      name: "notes",
      label: "Event Dates (Text)",
      parser: (data) => data.note,
      ellipsis: true,
    },
    {
      name: "start_date",
      label: "Start Date",
      parser: (data) => data.start_date,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/virtual_events/new`)
  }

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={handleAddButtonClick}
      >
        Add ActionEvent
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/virtual_events/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteVirtualEvent(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Virtual Event"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page
      links={links}
      title={"ActionEvents"}
      titleComponent={AddButton()}
    >
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "sub_line"]}
      />
    </Page>
  );
};

export default VirtualEventList;
