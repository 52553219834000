import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  EntryForm,
  Input
} from "./EntryForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useOnboardingProvider } from "../providers/OnboardingProvider";

import { GetApp, UpdateApp } from "../services/api/app";
import { ChromePicker } from "react-color";
import EditIcon from "@material-ui/icons/Edit";
import { validations } from "../lib/form";
import MediaUploader from "./MediaUploader";
import ErrorModal from "./modal_contents/ErrorModal";
import { VideoPlayer } from "./VideoPlayer";
import { LAUNCHED } from "../lib/onboarding_config/config";

const DEFAULT_FONT = "Segoe UI";

const EnterpriseOnboardingAppDesign = () => {
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { user, setUser } = useUserProvider();
  const { onboardingData, setOnboardingData } = useOnboardingProvider();

  const username = useMemo(() => user?.app_data?.username, [user?.app_data?.username]);
  const [metadata, setMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const [initialValues, setInitialValues] = useState({
    username: "",
    domains: "",
    setup: {
      body_tag: "",
      head_tag: "",
      icon: "",
      colors: {
        dark: "#333",
        medium: "#666",
        light: "#ccc",
      },
      fonts: {
        header: DEFAULT_FONT,
        body: DEFAULT_FONT,
      },
      header: {
        tagline: "",
        logo: {
          logo_url: "",
          logo_text: "",
        },
      },
    },
    // MediaUploader bug unable to handle nested object
    // Workaround on save move logo_text field to header.logo.logo_text
    logo_text: "",
    logo_url: "",
  });

  let schema = {
    username: Yup.string()
      .matches(/^\S*$/, "Should have no space")
      .max(30, "Max 30 Characters")
      .required("Required"),
    setup: Yup.object().shape({
      header: Yup.object().shape({
        tagline: validations.str_req_50
      })
    }),
    logo_text: Yup.string().max(18, "Maximum 18 Characters").required("Required"),
  };

  const handleGetData = useCallback(() => {
    setIsLoading(true);
      GetApp(user)
        .then((res) => res.json())
        .then((res) => {
          delete res.data.member_content_message;
          setInitialValues({
            ...res.data,
            logo_text: res?.data?.setup?.header?.logo?.logo_text,
            logo_url: res?.data?.setup?.header?.logo?.logo_url,
            setup: {
              ...res.data.setup,
              head_tag: res.data.setup.head_tag || '',
              body_tag: res.data.setup.body_tag || '',
            }
          });

          setMetadata(res.data.setup.media_metadata);
        })
        .finally(() => setIsLoading(false));
  }, [user, username]);

  const handleSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      const _values = { ...values };

      if (!_values?.setup) return;
      _values.setup.header.logo.logo_text = values.logo_text;
      _values.setup.header.logo.logo_url = values.logo_url;
      delete _values.logo_url;
      delete _values.logo_text;

      const payload = {
        data: {
          ..._values,
          onboarding: {
            ...onboardingData,
            progress: {
              ...onboardingData?.progress,
              design: {
                status: !status ? 'Saved' : status
              }
            }
          }
        }
      };

      // submit
      UpdateApp(user.currentApp(), user.idToken, payload)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          
          if(res?.status === 409) return showModal(
            <ErrorModal customMessage="Username has already been taken" />
          )

          showModal(<ErrorModal status={res.status} />);
        })
        .then((res) => {
          if (res) {
            formik.resetForm();

            setOnboardingData(res.data.onboarding);

            setUser({
              ...user,
              app_data: {
                ...user.app_data,
                username: res.data.username,
                setup: {
                  ...user.app_data.setup,
                  header: res.data.setup.header
                }
              },
            });

            handleGetData()
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false)
        })
        .finally(() => {
          setStatus(null);
        });
    },
    [user, setUser, onboardingData, status]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape(schema),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    let isMounted = true
    if (username && isMounted) {
      isMounted = false
      handleGetData();
    }

    return () => {
      isMounted = true
    }
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="px-5">
        <div className="w-4/6 mx-auto">
        <VideoPlayer url="https://youtu.be/kpWltEg0Kzw"/>
          </div>
        <div className="mt-10"/>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        hideSubmit={true}
        removeStyle={true}
        submitHandler={formik.handleSubmit}
      >
        <div className="flex flex-col w-full gap-4">
          <div>
            <Input
              name="username"
              type="text"
              label="My Custom URL"
              formik={formik}
              isFieldRow={true}
              required
              disabled={onboardingData?.status === LAUNCHED && !user.isSuper}
              prefix="https://web.actionera.com/"
              inputClassName="items-center"
              prefixClassName="text-lg"
            />
            <div className="flex justify-end -mt-3 mb-4">
              <span className="text-right italic">My Custom URL needs to be maximum 30 characters with no space</span>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="w-1/3">
              Logo
            </div>
            <div className="w-2/3">
              <MediaUploader
                name="logo_url"
                type="text"
                formik={formik}
                mediaType="image"
                isUploadOnly
                metadata={metadata}
                isDownloadable
              />
              <div style={{ marginTop: -12 }} className="text-right mb-4">
                <i>
                  If your logo has your brand name, place it here. Best size is
                  256w x 96h
                </i>
              </div>
            </div>
          </div>
          <Input
            name="logo_text"
            type="text"
            label="Text alternative to Logo"
            formik={formik}
            isFieldRow={true}
            placeholder=""
            required
          />
          <Input
            name="setup.header.tagline"
            type="text"
            label="Tagline"
            formik={formik}
            isFieldRow={true}
            required
          />
          <div style={{ marginTop: -12 }} className="text-right mb-4">
            <i>
              A short description of what people will find in this site
              (7-10 words)
            </i>
          </div>
          <section className="flex mt-8 mb-24">
            <div className="w-1/3">
              <header className="mb-4 text-lg">Website Color Scheme</header>
            </div>
            <div className="w-2/3">
              <p className="text-lg">
                <strong>Light Color:</strong> Used behind body text in
                headers, footers and content areas.
                <br />
                Black text must be readable on top of this color
              </p>
              <div className="mt-2">
                <ColorPicker
                  color={formik.values.setup.colors.light}
                  onChange={(color) => {
                    formik.setFieldValue("setup.colors.light", color.hex);
                  }}
                  readableIn="black"
                />
              </div>
              <p className="text-lg">
                <strong>Medium Color:</strong> Used as a background color for
                button and other accents.
                <br />
                White text must be readable on top of this color
              </p>
              <div className="mt-2">
                <ColorPicker
                  color={formik.values.setup.colors.medium}
                  onChange={(color) => {
                    formik.setFieldValue("setup.colors.medium", color.hex);
                  }}
                />
              </div>
              <p className="text-lg">
                <strong>Dark Color:</strong> Used for text/menu links and
                banner backgrounds.
                <br />
                White text must be readable on top of this color
              </p>
              <div className="mt-2">
                <ColorPicker
                  color={formik.values.setup.colors.dark}
                  onChange={(color) => {
                    formik.setFieldValue("setup.colors.dark", color.hex);
                  }}
                  pickerContainerStyle={{ marginTop: -215 }}
                />
              </div>
            </div>
          </section>

          <div className="flex justify-end items-center">
            <button className="ttnk-button" type="submit" disabled={!formik.dirty || isLoading}>
              SAVE
            </button>
          </div>
        </div>
      </EntryForm>
    </div>
  );
};

export default EnterpriseOnboardingAppDesign;

function ColorPicker({
  color,
  onChange,
  pickerContainerStyle,
  readableIn = "white",
}) {
  const containerRef = useRef(null);
  const [_color, setColor] = useState(() => color);
  const [showPicker, setShowPicker] = useState(false);

  const handleChangeComplete = useCallback(
    (color) => {
      setColor(color);
      onChange?.(color);
    },
    [onChange]
  );

  useEffect(() => {
    setColor(color);
  }, [color])

  useEffect(() => {
    function handleClickOutside(event) {
      const el = containerRef.current;
      if (!el?.contains?.(event.target)) {
        setShowPicker(false);
      }
    }

    if (showPicker) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => window.removeEventListener("click", handleClickOutside);
  }, [showPicker, containerRef]);

  return (
    <>
      <div className="relative">
        {showPicker && (
          <div
            style={pickerContainerStyle || {}}
            ref={containerRef}
            className="absolute ml-12"
          >
            <ChromePicker
              color={_color}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        )}
      </div>
      <button
        onClick={() => setShowPicker((prev) => !prev)}
        style={{ width: 28, height: 28, background: color }}
        className="rounded-full hover:opacity-60 border-gray-200 border shadow-sm"
        form="#"
      >
        <span className="opacity-80">
          <EditIcon htmlColor={readableIn} fontSize="small" />
        </span>
      </button>
    </>
  );
}


export const EOAppDesignStatus = () => {
  const { onboardingData } = useOnboardingProvider()
  const [onboardingDesignStatus, setOnboardingDesignStatus] = useState('Get Started');

  useEffect(() => {
    if (onboardingData && Object.keys(onboardingData).length > 0) {
      setOnboardingDesignStatus(onboardingData?.progress?.design?.status)
    }
  }, [onboardingData])

  return (
    <div className="flex justify-center items-center">
      <div className="ttnk-AvenirNextBold" style={{
        color: '#2F91CF'
      }}>
        {onboardingDesignStatus}
      </div>
    </div>
  )
}