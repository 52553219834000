import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { orderBy, merge, xorBy, uniqBy } from "lodash";
import { EntryForm, Column } from "../components/EntryForm";
import contentConfig from "../lib/content_config/config";
import { useFormik } from "formik";
import { getFieldComponent, validations } from "../lib/form";
import CustomFieldSelector from "./CustomFieldSelector";
import * as Yup from "yup";
import { useUserProvider } from "../providers/UserProvider";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";

export const defaultFieldValues = {
  id: "",
  title: "",
  profile: {
    id: "",
    title: "",
    name: "",
    business_name: "",
    bio: "",
    web_bio: "",
    profile_image_url: "",
    email: "",
    website_url: "",
    facebook_url: "",
    facebook_id: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    linkedin_url: "",
  },
  free_gift: {
    url: "",
    image_url: "",
    description: "",
  },
  vip_gift: {
    url: "",
    image_url: "",
    registration_url: "",
    description: "",
  },
  headline: "",
  description: "",
  sub_description: "",
  author: "",
  name: "",
  link: "",
  video_url: "",
  image_url: "",
  audio_url: "",
  text: "",
  web_text: "",
  info: "",
  answer: "",
  release_date: moment().toISOString(),
  enroll_date: moment().toISOString(),
  action_bar_text: "",
  action_bar_url: "",
  sort_order: 1,
  featured: false,
  active: "",
  send_to: "",
  question: "",
  question_1: "",
  question_2: "",
  question_3: "",
  question_4: "",
  question_5: "",
  price: "",
  review_video_url: "",
  review_image_url: "",
  review_listing_url: "",
  tags: [],
  topics: [],
  sections: [],
  url: "",
  url_tag: "",
  use_url_tag: false,
  course_name: "",
  media_metadata: {},
  journal_description: "",
  video_description: "",
  summit_tag: {
    id: "",
    code: "",
    name: "",
  },
  vip_tag: {
    id: "",
    code: "",
    name: "",
  },
  journal_list: false,
  items: [],
  custom_frequency: [],
  has_details: false,
  document_description: "",
  document_section_url: "",
  link_description: "",
  link_url: "",
};

Yup.addMethod(Yup.string, "sequence", function (funcList) {
  return this.test(async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value);
      }
    } catch ({ message }) {
      return context.createError({ message });
    }
    return true;
  });
});

const ContentForm = ({
  initialValues,
  subContentId,
  subContentIndex,
  onSubmitClick,
  onCancelClick,
  subContentType,
  contentType,
  isCustomFields,
  validate,
}) => {
  let fieldValues = {};
  const isMin8P5Version = IsWithinMinimumVersion(8.5)
  const detailsMetaData =
    contentConfig(contentType).subTables[subContentType].detailsMetadata;
  const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
  const { user } = useUserProvider();

  const [metadata, setMetadata] = useState({});
  const journalCount = fieldsToDisplay?.filter((f) =>
    f?.label?.includes("Journal")
  )?.length;

  const documentCount = fieldsToDisplay?.filter((f) =>
    f?.name?.includes("document_")
  )?.length;

  const linkCount = fieldsToDisplay?.filter((f) =>
    f?.name?.includes("link_")
  )?.length;

  detailsMetaData.forEach((meta) => {
    // set initial values for nested fieldnames
    if (meta?.name?.indexOf(".") > -1) {
      const paths = meta.name.split(".");
      if (defaultFieldValues[paths[0]]) {
        merge(fieldValues, {
          [paths[0]]: {
            [paths[1]]: defaultFieldValues[paths[0]][paths[1]],
          },
        });
      }
    } else {
      // set initial values for regular (non-nested) fieldnames
      if (typeof defaultFieldValues[meta.name] !== "undefined") {
        fieldValues[meta.name] = defaultFieldValues[meta.name];

        // add section web_text since it's being displayed along with section text and no need to add it on config
        if (meta.name === "text") {
          fieldValues["web_text"] = defaultFieldValues["web_text"];
        }
      }
    }
  });

  // Update request
  // Copy existing values from initialValues
  if (typeof subContentIndex !== "undefined") {
    let loadedValues = initialValues[subContentType][subContentIndex];
    if (loadedValues) {
      Object.keys(loadedValues).forEach((key) => {
        if (
          (typeof fieldValues[key] !== "undefined" &&
            (loadedValues[key] || loadedValues[key] === 0)) ||
          key === "document_url"
        ) {
          if (key === "document_url" && subContentType === "sections") {
            fieldValues.document_section_url = loadedValues[key];
          } else {
            fieldValues[key] = loadedValues[key];
          }
        }
      });
    }
  }

  const inMetadata = (name) => {
    const metadataNames = fieldsToDisplay.map((datum) => datum.name);
    return metadataNames.indexOf(name) > -1;
  };

  const getSubDescValidation = () => {
    return contentType === "courseLessons" || contentType === "lessons"
      ? Yup.string().sequence([
          () =>
            Yup.string().max(20, "Maximum 20 Characters").required("Required"),
          () =>
            Yup.string().test(
              "Unique",
              "Description already exists",
              (value) => {
                const currentSubContent =
                  initialValues[subContentType][subContentIndex];
                if (currentSubContent)
                  return !initialValues[subContentType].some(
                    (item) =>
                      item.sub_description.toLowerCase() ===
                        value.toLowerCase() && currentSubContent.id !== item.id
                  );
                return !initialValues[subContentType].some(
                  (item) =>
                    item.sub_description.toLowerCase() === value.toLowerCase()
                );
              }
            ),
        ])
      : validations.str_req_20;
  };

  const fullSchema = {
    title: validations.str_notreq_60,
    profile: Yup.object({
      id: validations.str_req_255,
    }),
    free_gift: Yup.object({
      url: validations.str_255,
      image_url: validations.str_255,
      description: validations.str_blob,
    }),
    vip_gift: Yup.object({
      url: validations.str_255,
      registration_url: validations.str_255,
      image_url: validations.str_255,
      description: validations.str_blob,
    }),
    headline: Yup.string(),
    description: validations.str_blob,
    sub_description: getSubDescValidation(),
    video_description: validations.str_blob,
    journal_description: validations.str_blob,
    author: Yup.string().max(30, "Maximum 30 Characters"),
    name: validations.str_req_25,
    link: validations.str_255,
    video_url: validations.str_255,
    image_url: validations.str_255,
    audio_url: validations.str_255,
    release_date: Yup.date(),
    action_bar_text: validations.str_notreq_40,
    action_bar_url: validations.str_255,
    sort_order: validations.sort_order,
    featured: Yup.boolean().nullable(),
    active: Yup.boolean().nullable(),
    send_to: Yup.string()
      .email("Invalid email")
      .max(50, "Maximum 50 Characters")
      .required("Required"),
    question: validations.str_blob,
    question_1: validations.str_blob,
    question_2: validations.str_blob,
    question_3: validations.str_blob,
    question_4: validations.str_blob,
    question_5: validations.str_blob,
    price: Yup.string().max(30, "Maximum 30 Characters"),
    review_video_url: validations.str_255,
    review_image_url: validations.str_255,
    listing_image_url: validations.str_255,
    text: validations.str_blob,
    web_text: validations.str_blob,
    answer: validations.str_blob,
    info: validations.str_255,
    url: validations.str_255,
    url_tag: validations.str_blob
      .max(20, "Maximum 20 Characters")
      .matches(/^\w+$/, { message: "Alpha-numeric characters only." }),
    use_url_tag: Yup.boolean().nullable(),
    enroll_date: Yup.date(),
    course_name: Yup.string().required("Required"),
    has_details: Yup.boolean(),
  };

  if (documentCount > 0) {
    fullSchema["document_section_url"] = validations.str_req_blob
      .trim()
      .test("pdf", "Only pdf files are allowed", (val) => {
        if (!val) return true;
        if (val.includes("media:")) return true;
        if (isMin8P5Version) return true;

        const values = val.split(".");
        return values[values.length - 1] === "pdf";
      });
    fullSchema["document_description"] = validations.str_req_25.trim();
  }
  if (linkCount > 0) {
    fullSchema["link_description"] = validations.str_req_25.trim();
    fullSchema["link_url"] = validations.str_req_blob.trim();
  }

  // Add additional validation
  fieldsToDisplay.forEach((field) => {
    if (field.validations && field?.name?.indexOf(".") === -1) {
      field.validations.forEach((validation) => {
        const { type, params } = validation;
        if (!fullSchema[field.name] || !fullSchema[field.name][type]) {
          return;
        }
        fullSchema[field.name] = fullSchema[field.name][type](...params);
      });
    }
  });

  // const schema = Object.fromEntries(Object.entries(fullSchema).filter(([key, _value]) => inMetadata(key)))
  let schema = {};
  fieldsToDisplay.forEach((field) => {
    // Get schema for dot separated fieldnames (ex. profile.name)
    // Right now this is limited to one level of nesting
    if (field?.name?.indexOf(".") > -1) {
      const paths = field.name.split(".");
      if (fullSchema[paths[0]]) {
        merge(schema, { [paths[0]]: fullSchema[paths[0]] });
      }
    } else {
      // Get schema for regular fieldnames
      if (fullSchema[field.name]) {
        schema[field.name] = fullSchema[field.name];
      }
    }
  });

  const formik = useFormik({
    initialValues: fieldValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      let finalValues = { ...values };
      const existingData =
        initialValues[subContentType][subContentIndex] || null;
      if (existingData) {
        finalValues = { ...finalValues, id: existingData?.id };
      }
      if (subContentType === "sections") {
        onSubmitClick(
          { ...finalValues, document_url: finalValues.document_section_url },
          subContentId,
          subContentIndex
        );
      } else {
        onSubmitClick(finalValues, subContentId, subContentIndex);
      }
      return;
    },
  });

  const doSelectCustomFields = (name) => {
    let fieldSelected = [];
    if (name === "question") {
      fieldSelected = detailsMetaData?.filter(
        (d) => d?.name === "answer" || d?.name === "question"
      );
    } else if (name === "document_section_url") {
      fieldSelected = detailsMetaData?.filter(
        (d) =>
          d?.name === "document_section_url" ||
          d?.name === "document_description"
      );
    } else if (name === "link_url") {
      fieldSelected = detailsMetaData?.filter(
        (d) => d?.name === "link_url" || d?.name === "link_description"
      );
    } else {
      fieldSelected = detailsMetaData?.filter((d) => d?.name === name);
    }

    const fields = orderBy(
      xorBy(fieldsToDisplay, fieldSelected, (f) => f?.name),
      ["order"],
      ["asc"]
    );
    const removed = fields?.find((f) => f?.name === name);
    if (!removed) {
      if (name === "question") {
        formik.setFieldValue("question", defaultFieldValues["question"]);
        formik.setFieldValue("answer", defaultFieldValues["answer"]);
      } else if (name === "question_1") {
        formik.setFieldValue("question_1", defaultFieldValues["question_1"]);
        formik.setFieldValue("question_2", defaultFieldValues["question_2"]);
        formik.setFieldValue("question_3", defaultFieldValues["question_3"]);
        formik.setFieldValue("question_4", defaultFieldValues["question_4"]);
        formik.setFieldValue("question_5", defaultFieldValues["question_5"]);
      } else if (name === "document_section_url") {
        formik.setFieldValue(
          "document_section_url",
          defaultFieldValues["document_section_url"]
        );
        formik.setFieldValue(
          "document_description",
          defaultFieldValues["document_description"]
        );
      } else if (name === "link_url") {
        formik.setFieldValue("link_url", defaultFieldValues["link_url"]);
        formik.setFieldValue(
          "link_description",
          defaultFieldValues["link_description"]
        );
      } else {
        formik.setFieldValue(name, defaultFieldValues[name]);
      }
    }
    setFieldsToDisplay(fields);
  };

  const doFormatFields = () => {
    let fieldsData = orderBy(detailsMetaData, ["order"], ["asc"]);

    if (isCustomFields) {
      if (typeof subContentIndex === "undefined") {
        const defaultCustomFields = detailsMetaData?.filter(
          (d) =>
            d.hasOwnProperty("validations") &&
            !(
              d?.name === "question" ||
              d?.name === "answer" ||
              d?.name.includes("document_") ||
              d?.name.includes("link_")
            )
        );
        fieldsData = defaultCustomFields;
      } else {
        let fields = [];
        if (fieldValues) {
          Object.keys(fieldValues).forEach((key) => {
            if (fieldValues[key] || fieldValues[key] === 0) {
              if (key === "question_1") {
                const journals = detailsMetaData?.filter((f) =>
                  f?.name?.includes("question_")
                );
                fields = [...fields, ...journals];
              } else {
                const field = detailsMetaData?.find((f) => f?.name === key);
                const alreadyExists = fields?.find((f) => f.name === key);
                const textAlreadyExists = fields?.find(
                  (f) => f?.name === "text"
                );

                if (field && !alreadyExists) {
                  fields.push(field);
                }

                // add section text if web text is present and not app text
                if (["web_text"].includes(key) && !textAlreadyExists) {
                  const textField = detailsMetaData?.find(
                    (f) => f?.name === "text"
                  );
                  fields.push(textField);
                }
              }
            }
          });
        }

        fieldsData = orderBy(fields, ["order"], ["asc"]);
      }
    } else {
      fieldsData = detailsMetaData;
    }

    if (!isMin8P5Version) {
      fieldsData = fieldsData.map((field) => {
        if (field.name?.includes("document_") || field.name?.includes("link_")) {
          return {
            ...field,
            hidden: true
          }
        }

        return field
      })
    }

    setFieldsToDisplay(fieldsData);
  };

  const getLabel = (name) => {
    if (inMetadata(name)) {
      return fieldsToDisplay.filter((datum) => datum.name === name)[0].label;
    } else {
      return "No Label";
    }
  };

  useEffect(() => {
    doFormatFields();
  }, []);

  useEffect(() => {
    if (fieldsToDisplay?.length && journalCount > 0) {
      const hasJournal = fieldsToDisplay?.find((f) => f?.name === "question_1");
      const hasJournals = fieldsToDisplay?.filter((f) =>
        f?.name?.includes("question_")
      );
      if (!hasJournal && hasJournals?.length) {
        setFieldsToDisplay(
          fieldsToDisplay?.filter((f) => !f?.name?.includes("question_"))
        );
      }
    }
    if (fieldsToDisplay?.length && documentCount > 0) {
      const hasDocument = fieldsToDisplay?.find(
        (f) => f?.name === "document_section_url"
      );
      const hasDocuments = fieldsToDisplay?.filter((f) =>
        f?.name?.includes("document_")
      );

      if (!hasDocument && hasDocuments?.length) {
        setFieldsToDisplay(
          fieldsToDisplay?.filter((f) => !f?.name?.includes("document_"))
        );
      }
    }

    if (fieldsToDisplay?.length && linkCount > 0) {
      const hasLink = fieldsToDisplay?.find((f) => f?.name === "link_url");
      const hasLinks = fieldsToDisplay?.filter((f) =>
        f?.name?.includes("link_")
      );

      if (!hasLink && hasLinks?.length) {
        setFieldsToDisplay(
          fieldsToDisplay?.filter((f) => !f?.name?.includes("link_"))
        );
      }
    }
  }, [fieldsToDisplay]);

  useEffect(() => {
    const loadedValues = initialValues[subContentType][subContentIndex];
    if (loadedValues?.media_metadata) {
      setMetadata(loadedValues?.media_metadata);
    }
  }, [initialValues[subContentType][subContentIndex]]);

  useEffect(() => {
    if (fieldsToDisplay?.length && journalCount === 1) {
      const journals = detailsMetaData?.filter((f) =>
        f?.name?.includes("question_")
      );
      const fields = orderBy(
        uniqBy([...fieldsToDisplay, ...journals], "name"),
        ["order"],
        ["asc"]
      );
      setFieldsToDisplay(fields);
    }
  }, [journalCount, fieldsToDisplay]);

  if (isCustomFields) {
    return (
      <div className="flex z-30 h-auto min-h-full">
        <div className="flex-2 ttnk-custom-field-selector-container">
          <CustomFieldSelector
            doSelectCustomFields={doSelectCustomFields}
            displayedFields={fieldsToDisplay}
            createScreen={typeof subContentIndex === "undefined"}
            platformVersion = {user?.app_data?.about?.platform_version}
          />
        </div>
        <div className="flex-1 m-8">
          <EntryForm
            formik={formik}
            submitHandler={formik.handleSubmit}
            cancelHandler={onCancelClick}
            removeStyle={isCustomFields}
            submitLabel="Save"
          >
            <Column width="w-full">
              {fieldsToDisplay.map((field) => {
                if (field.hidden) return false;
                return (
                  <Fragment key={field.name}>
                    {getFieldComponent(field.name, {
                      label: getLabel(field.name),
                      formik,
                      selectedValues: initialValues[subContentType],
                      formField: field.field,
                      metadata,
                      user,
                    })}
                  </Fragment>
                );
              })}
            </Column>
          </EntryForm>
        </div>
      </div>
    );
  }

  const getFieldMetadata = (name) => {
    const metaData = contentConfig(contentType).subTables[
      subContentType
    ].detailsMetadata.filter((datum) => datum.name === name);
    return metaData.length ? metaData[0] : null;
  };

  return (
    <EntryForm
      formik={formik}
      submitHandler={formik.handleSubmit}
      cancelHandler={onCancelClick}
      submitLabel="Save"
    >
      <Column>
        {fieldsToDisplay.map((field) => {
          // Check if field has conditions
          const fieldConfig = getFieldMetadata(field.name);
          const thisField = formik.getFieldProps(field.name);
          let hasFieldConditions = false;
          let isFieldVisible = true;
          hasFieldConditions = !!fieldConfig.conditions;
          if (hasFieldConditions) {
            for (let i = 0, len = fieldConfig.conditions.length; i < len; i++) {
              const cond = fieldConfig.conditions[i];
              const relativeField = formik.getFieldProps(cond.relativeField);
              isFieldVisible = cond.comparisonFunc(relativeField, thisField);
              if (isFieldVisible) {
                break;
              }
            }
          }
          // Hide field if conditions are not met
          if (!isFieldVisible) return false;

          if (field.hidden) return false;
          return (
            <Fragment key={field.name}>
              {getFieldComponent(field.name, {
                label: getLabel(field.name),
                formik,
                format: field?.format || "",
                selectedValues: initialValues[subContentType],
                formField: field.field,
                metadata,
                user,
              })}
            </Fragment>
          );
        })}
      </Column>
    </EntryForm>
  );
};

export default ContentForm;
