import { useMemo } from 'react';
import { useUserProvider } from '../providers/UserProvider';

export const IsWithinMinimumVersion = (minVersion) => {
  const { user } = useUserProvider();
  const platformVersion = useMemo(() => {
    return user?.app_data?.about?.platform_version;
  }, [user]);

  return parseFloat(platformVersion) >= parseFloat(minVersion);
};

export const IsWithinMaximumVersion = (maxVersion) => {
  const { user } = useUserProvider();
  const platformVersion = useMemo(() => {
    return user?.app_data?.about?.platform_version;
  }, [user]);

  return maxVersion
    ? parseFloat(platformVersion) <= parseFloat(maxVersion)
    : true;
};

export const IsWithinMinimumAndMaximumVersion = (minVersion, maxVersion) => {
  const { user } = useUserProvider();
  const platformVersion = useMemo(() => {
    return user?.app_data?.about?.platform_version;
  }, [user]);

  return (
    (minVersion ? parseFloat(platformVersion) >= parseFloat(minVersion) : true) &&
    (maxVersion ? parseFloat(platformVersion) <= parseFloat(maxVersion) : true)
  );
};

export const IsSameVersion = (version) => {
  const { user } = useUserProvider();
  const platformVersion = useMemo(() => {
    return user?.app_data?.about?.platform_version;
  }, [user]);

  return parseFloat(version) === parseFloat(platformVersion);
};

export const IsLowerVersion = (targetVersion) => {
  const { user } = useUserProvider();
  const platformVersion = useMemo(() => {
    return user?.app_data?.about?.platform_version;
  }, [user]);

  return parseFloat(platformVersion) < parseFloat(targetVersion);
};

export const versionChecker = (user, typeChecker, minVersion, maxVersion, targetVersion) => {
  const platformVersion = parseFloat(user?.app_data?.about?.platform_version);

  switch (typeChecker) {
    case 'isWithinMinimum': 
      return platformVersion >= parseFloat(minVersion)

    case 'isWithinMaximum': 
      return platformVersion <= parseFloat(maxVersion)

    case 'isWithinMinimumAndMaximum': 
      return platformVersion >= parseFloat(minVersion)
        && platformVersion <= parseFloat(maxVersion)

    case 'isSame': 
      return platformVersion === parseFloat(targetVersion)

    case 'isLower':
      return parseFloat(targetVersion) < platformVersion

    default:
      return false

  }
}