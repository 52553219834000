import React from "react";
import WebNotAvailable from "./WebNotAvailable";
import ComingSoon from "./ComingSoon";
import PlatformDisclaimer from "./PlatformDisclaimer";
import { IsWithinMinimumAndMaximumVersion } from "../lib/useVersionizer";
import versionizerConfig from "../lib/content_config/versionizerConfig";

const ShowWebNotAvailable = ({ component, customClasses = '' }) => component === 'not_available' ? <WebNotAvailable customClasses={customClasses} /> : <></>
const ShowComingSoon = ({ component, customClasses = '' }) => component === 'coming_soon' ? <ComingSoon customClasses={customClasses} /> : <></>
const ShowPlatformDisclaimer = ({ component, customClasses = '' }) => component === 'platform_disclaimer' ? <PlatformDisclaimer customClasses={customClasses} /> : <></>

const ContentDisclaimer = ({
  contentType,
  customClasses
}) => {
  const isDocuments = contentType === 'documents' ? 8.5 : 9; 
  const versionizerChecker = versionizerConfig?.[contentType]
    ? IsWithinMinimumAndMaximumVersion(versionizerConfig?.[contentType]?.minVersion, isDocuments)
    : false

  if (versionizerChecker) {
    return (
      <>
        <ShowWebNotAvailable component={versionizerConfig[contentType]?.component} customClasses={customClasses} />
        <ShowComingSoon component={versionizerConfig[contentType]?.component} customClasses={customClasses} />
        <ShowPlatformDisclaimer component={versionizerConfig[contentType]?.component} customClasses={customClasses} />
      </>
    )
  }

  return (
    <></>
  )
}

export default ContentDisclaimer