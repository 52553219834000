import { useState, useEffect } from 'react';
import DataTable from '../components/DataTable';
import Page from '../components/Page';
import TableActions from '../components/TableActions';
import { mergeArray } from '../lib/arrayTools';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useUserProvider } from '../providers/UserProvider';
import { useModalProvider } from '../providers/ModalProvider';

import { DeleteContent } from '../services/api/content';
import { CreateDeleteEntity } from '../services/api/entity';
import { GetAppResource } from '../services/api/app';

import DeleteItem from '../components/modal_contents/DeleteItem';

import contentConfig from '../lib/content_config/config';
import { CloneItem } from '../services/api/clone';
import ContentDisclaimer from '../components/ContentDisclaimer';

const TagList = ({ contentType }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {},
  });
  const history = useHistory();
  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();
  const config = contentConfig(contentType);
  const platform_version = user?.app_data?.about?.platform_version;
  const loadData = async () => {
    setData({ ...data, loading: true });
    // Use resourcePath for data outside 'content'
    const endpoint = config.isNotContent
      ? config.resourceEndpoint
      : `content/${config.resourceEndpoint}`;
    GetAppResource(user, endpoint)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data,
        });
      });
  };

  useEffect(() => {
    if (
      contentType === 'documents' &&
      parseFloat(platform_version) === 8.0
    ) {
      history.push('/');
    }

    loadData();
  }, [contentType]);

  const Deleter = config.isNotContent
    ? CreateDeleteEntity(contentType)
    : DeleteContent;

  const pluralize = (label) => {
    if (['Q&A', 'TrackaConnect'].includes(label)) {
      return label;
    }
    if (["Tracka'"].includes(label)) {
      return label + ' List';
    }
    if (label.slice(-1) === 'y') {
      return label.slice(0, -1) + 'ies';
    }
    if (label.slice(-1) === 's') {
      return label;
    }
    return label + 's';
  };

  const links = [
    { name: 'Home', url: '/' },
    {
      name: pluralize(contentConfig(contentType).label),
      url: `/${contentType}`,
    },
  ];

  const inMetadata = (name) => {
    const metadataNames = contentConfig(contentType).detailsMetadata.map(
      (datum) => datum.name
    );
    return (
      metadataNames.indexOf(name) > -1 &&
      contentConfig(contentType).listMetadata.indexOf(name) > -1
    );
  };

  const getLabel = (name) => {
    if (inMetadata(name)) {
      return contentConfig(contentType).detailsMetadata.filter(
        (datum) => datum.name === name
      )[0].label;
    } else {
      return 'No Label';
    }
  };

  const fullMetadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => data.id,
    },
    {
      name: 'sort_order',
      label: getLabel('sort_order'),
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
    },
    {
      name: 'title',
      label: getLabel('title'),
      parser: (data) => data.title,
      ellipsis: true,
    },
    {
      name: 'description',
      label: getLabel('description'),
      parser: (data) => data.description,
      ellipsis: true,
    },
    {
      name: 'author',
      label: getLabel('author'),
      parser: (data) => data.author,
    },
    {
      name: 'active',
      label: getLabel('active'),
      parser: (data) => (data.active ? 'Yes' : 'No'),
    },
    {
      name: 'featured',
      label: getLabel('featured'),
      parser: (data) => (data.featured ? 'Yes' : 'No'),
    },
    {
      name: 'topics',
      label: getLabel('topics'),
      parser: (data) => mergeArray(data.topics, 'name'),
    },
    {
      name: 'price',
      label: getLabel('price'),
      parser: (data) => data.price,
    },
    {
      name: 'tags',
      label: getLabel('tags'),
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>
      ),
    },
    {
      name: 'document_url',
      label: getLabel('document_url'),
      parser: (data) => {
        const isS3Link = data?.document_url?.includes('amazonaws.com');
        const apiMetaDataLink =
          data.media_metadata?.[
            String(data?.document_url?.replace('media:', ''))
          ]?.url_with_extension;

        return isS3Link ? data?.document_url : apiMetaDataLink;
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
    {
      name: 'frequency',
      label: getLabel('frequency'),
      parser: (data) => data.frequency,
    },
  ];

  const fullMetadataMap = {
    key: {
      name: 'key',
      label: 'Key',
      parser: (data) => data.id,
    },
    sort_order: {
      name: 'sort_order',
      label: getLabel('sort_order'),
      parser: (data) => data.sort_order,
      sorter: (firstData, secondData) => {
        return firstData.sort_order - secondData.sort_order;
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
    },
    title: {
      name: 'title',
      label: getLabel('title'),
      parser: (data) => data.title,
      ellipsis: true,
    },
    description: {
      name: 'description',
      label: getLabel('description'),
      parser: (data) => data.description,
      ellipsis: true,
    },
    author: {
      name: 'author',
      label: getLabel('author'),
      parser: (data) => data.author,
    },
    active: {
      name: 'active',
      label: getLabel('active'),
      parser: (data) => (data.active ? 'Yes' : 'No'),
    },
    featured: {
      name: 'featured',
      label: getLabel('featured'),
      parser: (data) => (data.featured ? 'Yes' : 'No'),
    },
    topics: {
      name: 'topics',
      label: getLabel('topics'),
      parser: (data) => mergeArray(data.topics, 'name'),
    },
    price: {
      name: 'price',
      label: getLabel('price'),
      parser: (data) => mergeArray(data.price, 'price'),
    },
    tags: {
      name: 'tags',
      label: getLabel('tags'),
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>
      ),
    },
    start_number: {
      name: 'start_number',
      label: 'Start Number',
      parser: (data) => {
        return data.start_number;
      },
    },
    end_number: {
      name: 'end_number',
      label: 'End Number',
      parser: (data) => {
        return data.end_number;
      },
    },
    message_prompt: {
      name: 'message_prompt',
      label: 'Message Prompt',
      parser: (data) => {
        return data.message_prompt;
      },
      ellipsis: true,
    },
    email: {
      name: 'email',
      label: 'Email',
      parser: (data) => {
        return data.email;
      },
    },
    vip_tag: {
      name: 'vip_tag',
      label: getLabel('vip_tag'),
      parser: (data) => (data.vip_tag ? data.vip_tag.name : ''),
    },
    host_name: {
      name: 'host_name',
      label: getLabel('host_name'),
      parser: (data) => data.host_name,
    },
    start_date: {
      name: 'start_date',
      label: getLabel('start_date'),
      parser: (data) => {
        const momentDate = moment(data.start_date);
        if (momentDate.isValid()) {
          return momentDate.format('MMMM D, YYYY');
        }
        return '';
      },
    },
    release_date: {
      name: 'release_date',
      label: getLabel('release_date'),
      parser: (data) => {
        const momentDate = moment(data.release_date);
        if (momentDate.isValid()) {
          return momentDate.format('DD-MMM-yyyy');
        }
        return '';
      },
    },
    access_url: {
      name: 'access_url',
      label: getLabel('access_url'),
      parser: (data) => data.access_url,
    },
    summit_tag: {
      name: 'summit_tag',
      label: 'Summit Tag',
      parser: (data) => data.summit_tag?.name || '',
    },
    type: {
      name: 'type',
      label: getLabel('type'),
      parser: (data) => data.type,
    },
    download_url: {
      name: 'download_url',
      label: getLabel('download_url'),
      parser: (data) => data?.download_url,
      ellipsis: true,
    },
    actions: {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onCloneClick={
            [
              'tips',
              'videos',
              'articles',
              'audios',
              'journals',
              'questions',
              'resources',
              'products',
              'podcasts',
              'documents',
            ].includes(contentType)
              ? cloneButtonHandler
              : null
          }
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
    frequency: {
      name: 'frequency',
      label: getLabel('frequency'),
      parser: (data) => data.frequency,
    },
    document_url: {
      name: 'document_url',
      label: getLabel('document_url'),
      parser: (data) => {
        const isS3Link = data?.document_url?.includes('amazonaws.com');
        const apiMetaDataLink =
          data.media_metadata?.[
            String(data?.document_url?.replace('media:', ''))
          ]?.url_with_extension;

        return isS3Link ? data?.document_url : apiMetaDataLink;
      },
    },
  };

  const metadata = contentConfig(contentType).keepListMetadataOrder
    ? contentConfig(contentType).listMetadata.map((fieldName) => {
        return fullMetadataMap[fieldName] ? fullMetadataMap[fieldName] : null;
      })
    : fullMetadata.filter((itm) => {
        return contentConfig(contentType).listMetadata.indexOf(itm.name) > -1;
      });

  const handleAddButtonClick = () => {
    history.push(`/${contentType}/new`);
  };

  const AddButton = () => {
    return (
      <button className="ttnk-button add-button" onClick={handleAddButtonClick}>
        Add{' '}
        {contentConfig(contentType).overrideTitle ||
          contentConfig(contentType).label}
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/${contentType}/${id}`);
  };

  const cloneButtonHandler = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: contentType,
      },
    });

    const cloneData = await res.json();
    const cloneId = cloneData.data?.id;

    loadData();
    history.push(`/${contentType}/${cloneId}`);
  };

  const deleteButtonHandler = async (id) => {
    await Deleter(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel={
          contentConfig(contentType).overrideTitle ||
          contentConfig(contentType).label
        }
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  const CenterComponent = () => {
    return (
      <div className="font-semibold italic text-center text-aeblue text-lg">
        COMING SOON IN WEB
      </div>
    );
  };

  return (
    <Page
      links={links}
      title={pluralize(contentConfig(contentType).label)}
      titleComponent={AddButton()}
      centerComponent={<ContentDisclaimer contentType={contentType} />}
    >
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        contentType={contentType}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={contentConfig(contentType).searchKeys}
      />
    </Page>
  );
};

export default TagList;
