import { useMemo, useState } from 'react';
import DataTable from '../components/DataTable';
import Page from '../components/Page';
import TableActions from '../components/TableActions';
import { mergeArray } from '../lib/arrayTools';
import { useHistory } from 'react-router-dom';

import { useUserProvider } from '../providers/UserProvider';
import { useModalProvider } from '../providers/ModalProvider';

import { UnlinkMember } from '../services/api/member';
import { GetAppResource } from '../services/api/app';

import { ReactComponent as AlertIcon } from '../assets/icons/alert.svg';
import AddAlertIcon from '@material-ui/icons/AddAlert';
const MemberList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { closeModal } = useModalProvider();

  const isEnterprise = useMemo(() => user?.app_data?.setup?.enterprise, [user])
  const isSubAppOwnerOrReseller = useMemo(() => user?.app_data?.from_apps?.length > 0, [user])

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, 'members')
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const links = [
    { name: 'Home', url: '/' },
    { name: 'Member List', url: '/members' },
  ];

  const blacklistedClass = (isBlacklisted) => {
    if (isBlacklisted) {
      return 'h-6 w-6 stroke-current fill-none text-red-600 trash';
    } else {
      return 'h-6 w-6 stroke-current fill-current text-transparent trash';
    }
  };

  const shouldShowPNBell = isEnterprise || isSubAppOwnerOrReseller

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => data.id,
    },
    {
      name: 'blacklisted',
      label: '',
      parser: (data) => (
        <AlertIcon className={blacklistedClass(data.blacklisted)} />
      ),
      width: '30px',
    },
    {
      name: 'email',
      label: 'Email',
      parser: (data) => data.email,
      ellipsis: true,
    },
    {
      name: 'name',
      label: 'Name',
      parser: (data) => (
        <div className="relative px-1">
          {data.has_push && !shouldShowPNBell && (
            <div className="absolute w-4 h-4 -left-5 -t-[3px]">
              <AddAlertIcon fontSize="small" />
            </div>
          )}
          <div>{data.name}</div>
        </div>
      ),
      ellipsis: true,
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => (
        <span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>
      ),
    },
    {
      name: 'courses',
      label: 'Courses',
      parser: (data) => mergeArray(data.courses, 'title'),
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (
        <TableActions onUpdateClick={updateButtonHandler} item_id={data.id} />
      ),
    },
  ];

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={() => history.push('/members/new')}
      >
        Add Member
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/members/${id}`);
  };

  const unlinkButtonHandler = async (id) => {
    await UnlinkMember(user, id);
    loadData();
    closeModal();
  };

  return (
    <Page links={links} title="Member List" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={['name', 'email']}
      />
    </Page>
  );
};

export default MemberList;
