import { useCallback, useEffect, useState } from "react";
import { Upload } from "antd";
import UploadFile from "../assets/icons/upload-file.svg";
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash.svg";

import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { DONE } from "../lib/onboarding_config/config";

const { Dragger } = Upload;

const mimeTypeToType = {
  // Image types
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'image/bmp': 'image',
  'image/webp': 'image',
  'image/svg+xml': 'image',
  // Document types
  'application/pdf': 'file',
  'application/msword': 'file',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file',
  'application/vnd.ms-excel': 'file',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file',
  'application/vnd.ms-powerpoint': 'file',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file',
  'text/plain': 'file',
  'text/csv': 'file',
  'application/rtf': 'file',

  // Archive types
  'application/zip': 'file',
  'application/x-rar-compressed': 'file',
  'application/x-tar': 'file',
  'application/gzip': 'file',
  'application/x-7z-compressed': 'file',

};


const getTypeFromMime = (mimeType) => {
  return mimeTypeToType[mimeType] || 'unknown'; // Default to 'unknown' if MIME type isn't mapped
};

export const FileUpload = ({
  fileType,
  isButton = false,
  children,
  onChange,
  acceptedTypes,
  errorMessage,
  onError,
  error,
  multiple = false,
  value,
  metaData,
  uploadType = "image",
  maximumFileLength,
  successCallback,
  onRemove,
  disabled,
  maximumFileSize= 10000000
}) => {
  const fileUploadName = "file";
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useUserProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  const handleDefaultFile = useCallback(() => {
    if (value) {
      if (multiple) {
        setFileList(value);
      } else {
        setFile(value);
      }
    }
  }, [value]);

  useEffect(() => {
    handleDefaultFile();
  }, []);

  const uploadOverride = async (options) => {
    try {
      const { onSuccess, file, onProgress } = options;

      if (
        multiple &&
        maximumFileLength &&
        fileList.length >= maximumFileLength
      ) {
        onError(`You can only upload ${maximumFileLength} files.`);
        return;
      }

      if (!fileType?.includes(file?.type) && !!fileType && fileType !== '*') {
        onError(errorMessage ?? "Invalid File Type");
        setFile(null);

        document
          .querySelectorAll('[title="Remove file"]')
          .forEach((el) => el?.click());
        return;
      }
      if (file.size > maximumFileSize) {
        onError(`File must be less than ${Math.floor(maximumFileSize / 1000000)}MB`);
        setFile(null);
        document
          .querySelectorAll('[title="Remove file"]')
          .forEach((el) => el?.click());
        return;
      }

      onError(null);

      const fmData = new FormData();

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + user.idToken,
        },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };

      const type = file.type;
      const category = getTypeFromMime(type);

      fmData.append("type", category);
      fmData.append("file", file);
      setLoading(true);

      const res = await axios.post(
        `${process.env.REACT_APP_API}/media`,
        fmData,
        config
      );

      const data = res.data.data;
      if (multiple) {
        setFileList([...fileList, { name: file.name, id: data.id }]);
        onChange?.(
          [...fileList?.map((a) => "media:" + a.id), "media:" + data.id],
          data
        );
      } else {
        onChange?.("media:" + data.id, data);
        setFile(file);
      }

      onSuccess("Ok");
      if (successCallback) successCallback("media:" + data.id);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!value) return;

    if (multiple) {
      if (value.length > 0) {
        return setFileList(
          value?.map((a) => {
            const uuid = a?.match(
              /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
            )[0];
            return {
              id: metaData[uuid]?.id,
              name: metaData[uuid]?.filename,
            };
          })
        );
      }
    }
    if (
      value &&
      /^media:[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        value
      )
    ) {
      const uuid = value?.match(
        /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      )[0];

      if (!file?.id && !!uuid) {
        if (metaData && metaData?.[uuid]) {
          setFile({
            id: metaData[uuid]?.id,
            name: metaData[uuid]?.filename,
          });
        }
      }
    }
  }, [value, metaData, multiple]);

  useEffect(() => {
    doSetShowLoadingModal(loading);
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const draggerProps = {
    accept: acceptedTypes,
    name: "file",
    multiple: false,
    customRequest: uploadOverride,
    showUploadList: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status?.toLowerCase() === DONE) {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleDownloadFile = async (id) => {
    const path = metaData[id]?.url;
    const filename = metaData[id]?.filename;
    const response = await fetch(path);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Release the reference to the blob
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      {isButton ? (
        <Upload
          fileList={fileList}
          name={fileUploadName}
          maxCount={1}
          accept={`${fileType || "text/zip"}`}
          onRemove={() => {
            setFile(null);
            if (typeof onRemove === "function") onRemove();
          }}
          disabled={disabled}
        >
          <button
            // eslint-disable-next-line no-octal-escape
            className="flex justify-center items-center border-4 border-aeblue border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue-default font-bold"
            type="button"
          >
            <p className="ml-1 file-upload-btn">Add Item</p>
          </button>
        </Upload>
      ) : (
        <Dragger // if file upload is in a form of selecting or Drag/Drop
          fileList={fileList}
          name={fileUploadName}
          disabled={disabled}
          maxCount={1}
          accept={`${fileType || "text/zip"}`}
          onRemove={() => {
            setFile(null);
            if (typeof onRemove === "function") onRemove();
          }}
          className="!p-0"
          {...draggerProps}
        >
          {children ? (
            children
          ) : (
            <>
              <p className="justify-center w-full flex">
                <img src={UploadFile} alt="Upload File" />
              </p>
              <p className="text-lg py-5">
                Drag and drop files here or click to browse your files
              </p>
            </>
          )}
        </Dragger>
      )}
      {multiple ? (
        <>
          {fileList.length > 0 && (
            <div>
              {fileList?.map((file) => (
                <li className="mt-2 flex" key={file.id}>
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => {
                      onChange?.(value.filter((a) => a !== "media:" + file.id));
                      setFileList(fileList.filter((f) => f.id !== file.id));
                    }}
                    disabled={disabled}
                  >
                    <DeleteIcon className="mr-2 h-5 w-5 fill-current text-gray-400 trash hover:text-red-600" />
                  </button>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleDownloadFile(file.id);
                    }}
                  >
                    {file?.name}
                  </div>
                </li>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          {file && (
            <li className="mt-2 flex">
              <button
                type="button"
                className="ml-2"
                onClick={() => {
                  setFile(null);
                  onChange?.("");
                  if (typeof onRemove === "function") onRemove();
                }}
                disabled={disabled}
              >
                <DeleteIcon className="mr-2 h-5 w-5 fill-current text-gray-400 trash hover:text-red-600" />
              </button>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleDownloadFile(file.id);
                }}
              >
                {file?.name}
              </div>
            </li>
          )}
        </>
      )}

      {error && <div className="text-red-600 mt-1">{error}</div>}
    </div>
  );
};
