import Description from "../../components/Description"
import { Image } from "../../components/Image"
import { VideoPlayer } from "../../components/VideoPlayer"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import moment from "moment"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import * as Yup from "yup"

const DEFAULT_VALUES = {
  isEdit: false,
  name: "",
  video_description: "",
  youtube_or_vimeo: "",
  action_bar_text: "",
  action_bar_url: "",
  actionclip: false,
  actionclip_description: "",
  featured: false,
  image_url: "",
  release_date: moment().utcOffset(0, true).format("YYYY-MM-DD"),
  sort_order: 1,
  tags: [],
  topics: [],
  web_description: "",
}

const OnboardingVideo = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: "name",
      label: "Video Title",
      placeholder: "",
      required: true,
      isFieldRow: true,
    },
    {
      component: "video_description",
      label: "Video Description",
      placeholder: "At least 1-2 sentences",
      isFieldRow: true,
      required: true,
      rows: 21
    },
    {
      component: "youtube_or_vimeo",
      label: "Youtube or Vimeo (Link)",
      placeholder: "Public/unlisted videos ONLY",
      required: true,
      isFieldRow: true,
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
      required: true,
    },
  ]

  const schema = {
    name: validations.str_req_60,
    video_description: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    youtube_or_vimeo: validations.long_url.required("Required"),
    topics: Yup.array().min(1, "Required"),
  };

  const handleFormatPayload = (values) => {
    const { isEdit, ...rest } = values

    const newPayload = {
      ...rest,
      title: values?.name,
      description: values?.video_description,
      video_url: values?.youtube_or_vimeo,
    }

    return newPayload
  }

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        id: initialValues?.id,
        name: initialValues?.title,
        video_description: initialValues?.description,
        youtube_or_vimeo: initialValues?.video_url,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Videos",
      resourceEndpoint: "videos",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=OdKlb47l9fQ" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please add 7 (you can add more later) public/unlisted YouTube/Vimeo videos ONLY (anything... long, short, doesn't matter). They can also be videos by others, if attributed properly (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src="https://ae-api.techtank.ca/media/15d77617-b7d8-4438-a471-8e59547fbc21.jpg"
            alt="Video Screenshot"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Video"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={handleFormatPayload}
          />
        ) },
      ],
    }
  }
}

export default OnboardingVideo;