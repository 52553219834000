import React, { useState } from "react";
import Page from "../components/Page";
import { Radio } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Column, CheckBox, EntryForm, Input } from "../components/EntryForm";
import { GetAppResource, UpdateBusiness } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const convertArrayToObject = (array, name) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[name]]: item.value,
    };
  }, initialValue);
};

const fields = [
  { name: "actionLists", label: "ActionLists", column: 1, value: 1 },
  { name: "courses", label: "Courses", column: 1, value: 1 },
  { name: "directories", label: "Directories", column: 1, value: 3 },
  { name: "topics", label: "Topics", column: 1, value: 8 },
  { name: "tags", label: "Tags", column: 1, value: 4 },
  { name: "actionblog", label: "ACTIONBLOG", column: 1, value: 2 },
  { name: "videos", label: "Videos", column: 1, value: 2 },
  { name: "articles", label: "Articles", column: 2, value: 2 },
  { name: "audio", label: "Audio", column: 2, value: 2 },
  { name: "lessons", label: "Lessons", column: 2, value: 2 },
  { name: "journals", label: "Journals", column: 2, value: 2 },
  { name: "q&a", label: "Q&A", column: 2, value: 2 },
  { name: "galleries", label: "Galleries", column: 2, value: 2 },
  { name: "resources", label: "Resources", column: 2, value: 2 },
  { name: "products", label: "Products", column: 3, value: 2 },
  { name: "profiles", label: "Profiles", column: 3, value: 2 },
  { name: "unlock-code", label: "Unlock Code Messages", column: 3, value: 2 },
  { name: "documents", label: "Documents", column: 3, value: 2 },
  { name: "actionForms", label: "Action Forms", column: 3, value: 1 },
];

const BusinessDetail = ({ match }) => {
  const history = useHistory();
  const uuid = match.params.uuid;
  const { user } = useUserProvider();

  const [initialValues, setinitialValues] = useState({
    ...convertArrayToObject(fields, "name"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {};
        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      UpdateBusiness(
        user.currentApp(),
        user.idToken,
        { data: { active: values?.active, app_type: values?.app_type } },
        uuid
      );
      history.push("/business");
    },
  });

  const links = [
    { name: "Home", url: "/" },
    { name: "Businesses", url: "/business" },
  ];

  const loadData = async () => {
    GetAppResource(user, `children/${uuid}`)
      .then((res) => res.json())
      .then((jsonData) => {
        setinitialValues({
          ...jsonData?.data,
          ...convertArrayToObject(fields, "name"),
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [uuid]);

  useEffect(() => {
    if (
      user?.app_data?.setup?.enterprise ||
      user?.app_data?.reseller_of?.length > 0
    )
      return;

    history.push("/");
  }, [user?.app_data?.setup?.enterprise, user?.app_data?.reseller_of?.length]);

  return (
    <Page title={`${formik.values.name ?? ""}`} links={links}>
      <EntryForm
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/business"
      >
        <Column>
          <div className="mt-5">
            <CheckBox name="active" label="Active?" formik={formik} />
          </div>
        </Column>
        <Column>
          <div className="text-lg mb-3">App Type</div>
          <Radio.Group
            onChange={(e) => {
              formik.setFieldValue("app_type", e.target.value);
            }}
            value={
              formik.values.app_type
            }
            className="flex flex-row gap-10"
            disabled={!formik.values.active}
          >
            <Radio value="FREE" disabled={!formik.values.active}>
              FREE
            </Radio>
            <Radio value="PRO">PRO</Radio>
            {user?.app_data?.reseller_of?.length === 0 && (
              <>
                <Radio value="PRO+">PRO+</Radio>
                <Radio value="Reseller">Reseller</Radio>
              </>
            )}
          </Radio.Group>
          {formik.values.app_type === "FREE" && (
            <div className="flex flex-row justify-between w-full mt-10 gap-10">
              <div className="flex-1 max-w-10">
                {fields
                  ?.filter((a) => a.column === 1)
                  ?.map((field, index) => {
                    return (
                      <div key={index} className={`flex flex-col`}>
                        <Input
                          name={field.name}
                          label={field.label}
                          formik={formik}
                          disabled
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="flex-1">
                {fields
                  ?.filter((a) => a.column === 2)
                  ?.map((field, index) => {
                    return (
                      <div key={index} className={`flex flex-col `}>
                        <Input
                          name={field.name}
                          label={field.label}
                          formik={formik}
                          disabled
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="flex-1">
                {fields
                  ?.filter((a) => a.column === 3)
                  ?.map((field, index) => {
                    return (
                      <div key={index} className={`flex flex-col`}>
                        <Input
                          name={field.name}
                          label={field.label}
                          formik={formik}
                          disabled
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Column>
      </EntryForm>
    </Page>
  );
};

export default BusinessDetail;
