import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetUser = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/users/${uuid}`, 'GET', idToken)
}

export const UpdateUser = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/users/${uuid}`, 'PATCH', idToken, payload)
}

export const ListUsers = async (user, payload) => {
  let endpoint = `${process.env.REACT_APP_API}/manage_users`;
  if (payload.query && payload.appId) { 
    endpoint += `?q=${encodeURIComponent(payload.query)}&app_id=${encodeURIComponent(payload.appId)}`;
  } else if (payload.appId) {
    endpoint += `?app_id=${encodeURIComponent(payload.appId)}`;
  } else if (payload.query) {
    endpoint += `?q=${encodeURIComponent(payload.query)}`;
  }

  return await callEndpoint(endpoint, 'GET', user.idToken)
}

export const UsersCount = async (user, payload = {}) => {
  let endpoint = `${process.env.REACT_APP_API}/manage_users?countonly=1`;
  if (payload.appId) {
    endpoint += `&app_id=${encodeURIComponent(payload.appId)}`;
  }
  return await callEndpoint(endpoint, 'GET', user.idToken);
}

export const ListSuperUsers = async (user) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/super_users`, 'GET', user.idToken)
}

export const DeleteUser = async (user, uuid) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/users/${uuid}`, 'DELETE', user.idToken)
}

