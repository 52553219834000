import { Tooltip } from "@material-ui/core";
import React from "react";
import copy from "../assets/images/copy.png";

const CopyIcon = ({ value }) => {
  return (
    <Tooltip title={<div>Copy</div>}>
      <img
        src={copy}
        className="h-5 ml-2 w-5 cursor-pointer hover:scale-125"
        alt="copy"
        onClick={() => navigator.clipboard.writeText(value ?? '')}
      />
    </Tooltip>
  );
};

export default CopyIcon;
