const DisabledTrialForm = () => {
    
	return (
		<div className="w-screen pt-12 ttnk-free-trial-bg h-screen flex items-center justify-center">
      <div className="bg-white py-8 px-12 rounded-2xl text-lg">
        This page is now disabled. Please contact support@actionera.com for any concerns. Thank you
      </div>
		</div>
	)
}

export default DisabledTrialForm
