import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { mergeArray } from "../lib/arrayTools"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { DeleteDirectory } from "../services/api/directory"
import { GetAppResource } from "../services/api/app"

import DeleteItem from "../components/modal_contents/DeleteItem"
import { CloneItem } from "../services/api/clone";

const DirectoryList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'directories')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        })
      })
  }

  const links = [
    { name: "Home", url: "/" },
    { name: 'Directory List', url: '/directories' },
  ]

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),
    },
    {
      name: 'sort_order',
      label: 'Sort Order',
      parser: (data) => (data.sort_order),
      sorter: (firstData, secondData)=>{
        return firstData.sort_order - secondData.sort_order
      },
      defaultSortOrder: "ascend",
      sortDirections:["ascend", "descend"]
    },
    {
      name: 'name',
      label: 'Directory Name',
      parser: (data) => (data.name)
    },
    {
      name: 'sub_line',
      label: 'Sub-Line',
      parser: (data) => (data.sub_line),
      ellipsis: true,
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => (<span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>),
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} onCloneClick={cloneButtonHandler} />),
    },
  ]

  const handleAddButtonClick = () => {
    history.push(`/directories/new`)
  }

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={handleAddButtonClick}>Add Directory</button>
  }

  const updateButtonHandler = (id) => {
    history.push(`/directories/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteDirectory(id, user.idToken)
    loadData()
    closeModal()
  }

  const cloneButtonHandler  = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: 'directories'
      }
    })
    const cloneData = await res.json()
    const cloneId = cloneData.data?.id

    loadData();
    history.push(`/directories/${cloneId}`)
    
  };

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel='Directory' onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  return (
    <Page links={links} title={'Directories List'} titleComponent={AddButton()}>
      <DataTable  metadata={metadata}
                  dataLoader={loadData}
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  searchKeys={['name', 'sub_line']} />
    </Page>
  )
}

export default DirectoryList;
