import { useEffect, useState } from 'react';
import { EntryForm, Column, Input } from '../components/EntryForm';
import Page from '../components/Page';

import {
  GetCourseModule,
  UpdateCourseModule,
  AddCourseModule,
} from '../services/api/course';
import { useUserProvider } from '../providers/UserProvider';
import { useModalProvider } from '../providers/ModalProvider';
import { useLoaderProvider } from '../providers/LoaderProvider';
import MediaUploader from '../components/MediaUploader';
import DataSelector from '../components/DataSelector';
import CourseLessonList from './CourseLessonList';

import { useFormik } from 'formik';

import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import { validations } from '../lib/form';
import responseProcessor from '../lib/responseProcessor';

const CourseModuleDetail = ({ newModule, match }) => {
  const vals = {
    name: '',
    image_url: '',
    sort_order: 1,
    tags: [],
    course_lessons: [],
    id: '',
    media_metadata: {},
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserProvider();

  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newModule ? user.currentApp() : match.params.uuid;
  const course_uuid = match.params.course_uuid;

  const history = useHistory();

  const loadData = () => {
    setIsLoading(true);
    GetCourseModule(course_uuid, uuid, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setInitialValues({
          name: res.data.name,
          image_url: res.data.image_url,
          sort_order: res.data.sort_order,
          tags: res.data.tags,
          course_lessons: res.data.course_lessons,
          id: '',
        });
        setMetadata(res.data.media_metadata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (newModule) {
      setInitialValues(vals);
    } else {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newModule ? 'New' : 'Edit';
  const currentUrl = newModule ? '/courses/new' : `/courses/${uuid}`;

  let links = [
    { name: 'Home', url: '/' },
    { name: 'Course List', url: '/courses' },
    { name: `Course: ${course_uuid}`, url: `/courses/${course_uuid}` },
    { name: `${currentAction} Module`, url: currentUrl },
  ];

  let schema = {
    name: validations.str_req_45,
    image_url: validations.str_notreq_255,
    sort_order: validations.sort_order,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      delete values.course_lessons;
      delete values.id;

      if (newModule) {
        AddCourseModule(course_uuid, user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateCourseModule(course_uuid, uuid, user.idToken, {
          data: values,
        }).then((response) => responseProcessorProxy(response));
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push(`/courses/${course_uuid}`),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Module`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL={`/courses/${course_uuid}`}
      >
        <Column>
          <Input name="name" type="text" label="Name" formik={formik} />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <Input
            name="sort_order"
            type="number"
            label="Sort Order"
            formik={formik}
          />
        </Column>
        <Column>
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(options) => {
              return options.filter((a) => !a.is_expired);
            }}
          />

          <div style={{ marginTop: -12 }} className="text-right">
            <i className="italic text-xs text-right font-medium">
              DO NOT PUT A TAG ON MODULE 1 and at least one course lesson. If
              you do, your course will not work.
            </i>
          </div>
        </Column>
      </EntryForm>

      {!newModule && (
        <CourseLessonList
          courseId={course_uuid}
          moduleId={uuid}
          lessonValues={initialValues.course_lessons}
          resetList={() => loadData()}
        />
      )}
    </Page>
  );
};

export default CourseModuleDetail;
