import * as Yup from "yup";

import Description from "../../components/Description"
import { VideoPlayer } from "../../components/VideoPlayer"
import ImageSlideout from '../../assets/images/gallery-slideout.jpg'
import { Image } from "../../components/Image"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { ENTERPRISE } from "./config";

const DEFAULT_VALUES = {
  title: "",
  gallery_description: "",
  photos: [],
  topics: []
}

const OnboardingGallery = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const values = initialValues ? {
    ...initialValues,
    gallery_description: initialValues.description,
    active: initialValues.active
  } : DEFAULT_VALUES

  const fields = [
    {
      component: "title",
      label: "Gallery Title",
      isFieldRow: true,
      required: true
    },
    {
      component: "gallery_description",
      label: "Gallery Description",
      isFieldRow: true,
      rows: 6,
      required: true
    },
    {
      component: "photos",
      label: "Upload at least 15 images. Each file must be less than 10MB.", 
      isFieldRow: true,
      required: true
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
      required: false,
    },
  ]

  const schema = {
    title: validations.str_req_60,
    gallery_description: Yup.string()
      .required("Required")
      .max(250, "Maximum 250 Characters"),
    photos: Yup.array().min(15, "Upload at least 15 images.").required("Required")
  };

  return {
    config: {
      label: "Onboarding - Gallery",
      resourceEndpoint: "galleries",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=llR7_CiXz2Q" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please create at least 1 image gallery (inspiring, or related to your work in some way) with at least 15 images (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Gallery"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Gallery"
            defaultValues={values}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {

              const newPayload = {
                title: values?.title,
                description: values?.gallery_description,
                photos: values?.photos,
                active: initialValues?.id ? values?.active : true,
                topics: values?.topics,
              }

              return newPayload
            }}
          />
        ) }
      ]
    }
  }
}

export default OnboardingGallery;