import { useCallback, useEffect, useState } from "react";
import { EntryForm, Column, TextArea } from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp, UpdateAppDomains } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import * as Yup from "yup";

const DomainSetup = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    domains: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const getData = useCallback(() => {
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const domains = res.data.domains?.join("\n");
        setInitialValues({
          domains: domains,
        });
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Domain Setup", url: "/app/domains" },
  ];

  let schema = {
    domains: Yup.string().nullable(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      delete values.lite_unlock_code;
      let domains = values.domains.split("\n");
      if (values.domains === "") {
        domains = [];
      }
      const payload = { domains: domains};
      UpdateAppDomains(user.currentApp(), user.idToken, { data: payload }).then(
        (response) => {
          responseProcessor({
            response,
            showModal,
            onSuccess,
          });
        }
      );
    },
  });

  const onSuccess = () => {
    setSaved(true);
    setIsLoading(true);
    getData();
  };

  const SavedLabel = () => <span className="text-green-400">Saved</span>;

  return (
    <Page links={links} title="Custom URLs">
      <div>
        <div>ID: {user.app.id}</div>
      </div>
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
        savedLabel={saved && !formik.dirty && SavedLabel()}
      >
        <Column>
        {/* <div className="text-2xl font-bold">Instructions</div> */}
        {/* <div className="py-4 text-xl">
          To enable custom domains, please make sure to do any the following:
          <ul className="py-2">
            <li>Create a CNAME record pointing to <code>something.com</code></li>
            <li>Create an A record pointing to <code>1.2.3.4</code> </li>
          </ul>
        </div> */}
        
        <TextArea
          name='domains'
          type='text'
          label='Enter one custom URL per line (e.g. https://actionera.com):'
          formik={formik}
          className="font-mono w-full"
          placeholder="https://actionera.com"
        />
        
        </Column>

      </EntryForm>
    </Page>
  );
};

export default DomainSetup;
