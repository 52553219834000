import { useState, useEffect, useCallback } from "react";
import { EntryForm } from "./EntryForm";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import { validations } from "../lib/form";
import { GetApp, UpdateApp } from "../services/api/app";
import { useDrawerProvider } from "../providers/DrawerProvider";
import { useUserProvider } from "../providers/UserProvider";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import ErrorModal from "./modal_contents/ErrorModal";
import Button from "./Button";
import SlideOut from "./SlideOut";
import Accordion from "./accordion";
import Description from "./Description";
import { VideoPlayer } from "./VideoPlayer";
import { SUBMITTED, SAVED } from "../lib/onboarding_config/config";

const OnboardingAppStore = () => {
  const { showModal } = useModalProvider();
  const { user, setUser } = useUserProvider();
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const [subAccordionList, setSubAccordionList] = useState([
    {
      id: 1,
      label: "Listing",
      has_details: false,
      is_complete: false,
      editable: false,
      body: '',
    },
    {
      id: 2,
      label: "Info",
      has_details: false,
      is_complete: false,
      editable: true,
      body: '',
    },
    {
      id: 3,
      label: "Support",
      has_details: false,
      is_complete: false,
      editable: false,
      body: '',
    }
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formCheckbox, setFormCheckbox] = useState(false);
  const [initialValues, setInitialValues] = useState({
    app_name: "",
    short_app_description: "",
    full_app_description: "",
    primary_category: "",
    secondary_category: "",
    search_keywords: "",
    in_app_name: "",
    app_icon_name: "",
    support_contact: "Use my current website",
    support_email: "",
    support_contact_page: "",
    privacy_policy: "Use my current website",
    privacy_policy_page: "",
    privacy_policy_document: "",
    i_understand: false,
  });

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    if (onboardingData?.progress?.store) {
      mapSubAccordionList(onboardingData.progress.store)
      handleGetData();
    }
  }, [onboardingData]);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let schema = {
    i_understand: validations.bool
  };

  const useCheckFieldsFilled = () => {
    const areAllFieldsFilled = () => {
      const val = formik.values;
      return Object.keys(val).every((key) => {
      if (key === 'support_contact_page' && val.support_contact !== 'Use my current website') return true;
      if (key === 'privacy_policy_document' && val.privacy_policy !== "Add this to my 'Companion Website'") return true;
      const isFieldFilled = val[key] !== '' && val[key] !== undefined && val[key] !== null;
      
      return isFieldFilled;
      });
    };
  
    return { areAllFieldsFilled };
  };

  const handleGetData = () => {
    GetApp(user)
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.about?.store) {
          mapToInitialValues(res?.data?.about?.store, res?.data?.name);
          setIsSubmitted(
            res.data.onboarding?.progress?.store?.status === SUBMITTED && !user?.isSuper
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const mapToInitialValues = (values, app_name) => {
    setInitialValues({
      app_name,
      short_app_description: values.short_app_description,
      full_app_description: values.full_app_description,
      primary_category: values.primary_category,
      secondary_category: values.secondary_category,
      search_keywords: values.search_keywords,
      in_app_name: values.in_app_name,
      app_icon_name: values.app_icon_name,
      support_contact: values.support_contact,
      support_contact_page: values.support_contact_page,
      support_email: values.support_email,
      privacy_policy: values.privacy_policy,
      privacy_policy_page: values.privacy_policy_page,
      privacy_policy_document: values.privacy_policy_document,
      i_understand: values.i_understand,
    });
  }

  const handleSubmit = (values) => {
    setIsLoading(true);

    const payload = {
      data: {
        about: {
          store: {
            ...values
          }
        },
        onboarding: {
          ...onboardingData,
          progress: {
            ...onboardingData?.progress,
            store: {
              status: 'Submitted'
            }
          }
        }
      }
    };

    // submit
    UpdateApp(user.currentApp(), user.idToken, payload)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        showModal(<ErrorModal status={res.status} />);
      })
      .then((res) => {
        setOnboardingData(res.data.onboarding);

        if (res?.data?.about?.store) {
          mapToInitialValues(res?.data?.about?.store, res?.data?.name);
        }

        if (user.app_data.username !== res.data.username) {
          setUser({
            ...user,
            app_data: {
              ...user.app_data,
              username: res.data.username,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: handleSubmit,
  });

  const isTypeSubmitted = (type) => onboardingData?.progress?.store[type]
  const { areAllFieldsFilled } = useCheckFieldsFilled();

  const OnBoardingAppStoreSubItems = ({ type, title }) => {
    const { showDrawer, closeDrawer } = useDrawerProvider();

    return (
      <div className="flex items-center gap-4">
        <Button
          type="button"
          className="flex justify-center items-center border-4 border-aeblue-default border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue-default font-bold"
          onClick={() => {
            showDrawer(
              <SlideOut
                type={type}
                showSubmitBtn={false}
                handleSubmit={closeDrawer}
              />,
              title,
              "50vw",
              "text-center w-full"
            );
          }}
        >
          {isTypeSubmitted(type)
            ? 'Manage'
            : 'Add Item'}
        </Button>
      </div>
    )
  }

  const mapSubAccordionList = (storeData) => {
    const storeListing = {
      ...subAccordionList[0],
      listing: storeData.listing,
      is_complete: storeData.listing,
      status: <OnBoardingAppStoreSubItems type={(subAccordionList[0].label).toLowerCase()} title={subAccordionList[0].label} />
    }

    const storeInfo = {
      ...subAccordionList[1],
      info: storeData.info,
      is_complete: storeData.info,
      status: <OnBoardingAppStoreSubItems type={(subAccordionList[1].label).toLowerCase()} title={subAccordionList[1].label} />
    }

    const storeSupport = {
      ...subAccordionList[2],
      support: storeData.support,
      is_complete: storeData.support,
      status: <OnBoardingAppStoreSubItems type={(subAccordionList[2].label).toLowerCase()} title={subAccordionList[2].label} />
    }

    setSubAccordionList([storeListing, storeInfo, storeSupport])
  }

  return (
    <div className="px-5">
      <div className="w-4/6 mx-auto">
        <VideoPlayer url="https://www.youtube.com/watch?v=17x6v7KuRjE"/>
      </div>
      <div className="flex justify-center items-center">
        <p className="my-10 font-medium text-xl break-words">
          Watch the video above and then fill out this form to give us what we need to create your app store listing. 
          The pop-out slider will help you to know what is needed for Listing, Info and Support. 
          After you submit your App Store Info, there will be a brief waiting period as our team processes your form. 
          This usually takes 1-3 business days.
        </p>
      </div>
      <EntryForm
        submitHandler={formik.handleSubmit}
        formik={formik}
        removeStyle={true}
        hideSubmit
        applyDefaultButtonStyle={false}
        floatSubmitBtnRight = {true}
      >
        <div className="w-full">
          {subAccordionList?.length > 0 && subAccordionList.map((item) => (
              <Accordion
                key={item.id}
                item={item}
                value={item.is_complete}
                status={item.status}
                isSubAccordion={true}
              />
            ))}
        </div>
        <div className="flex gap-3 items-baseline px-2 py-14">
          <input
            type="checkbox"
            name="i_understand"
            value={formik.values.i_understand}
            checked={formik.values.i_understand} 
            disabled={!areAllFieldsFilled() || onboardingData?.progress?.store?.status === SUBMITTED}
            onChange={(event) => {
              formik.setFieldValue('i_understand', !formik.values.i_understand);
              setFormCheckbox(event.target.checked);
            }}
          />
          <Description
            className={`font-medium text-lg ${(!areAllFieldsFilled() || onboardingData?.progress?.store?.status === SUBMITTED) ? 'text-gray-400' : 'text-black'}`}
          >
            I understand that for app or design changes it may take an additional 1-2 weeks, and cost up to $200
            to rebuild my app (billed independently), depending on if it was built in the latest app version
          </Description>
        </div>
        <div className="w-full flex justify-end">
          <button className="ttnk-button" type="submit" disabled={!areAllFieldsFilled() || !formik.values.i_understand || onboardingData?.progress?.store?.status === SUBMITTED}>
            SUBMIT
          </button>
        </div>
      </EntryForm>
    </div>
  );
};

export default OnboardingAppStore;

export const AppStoreStatus = () => {
  const { onboardingData } = useOnboardingProvider();

  return (
    <div className="ttnk-AvenirNextBold" style={{
      color: '#2F91CF'
    }}>
      {
        [SUBMITTED, SAVED].includes(onboardingData?.progress?.design?.status)
          ? onboardingData?.progress?.store?.status
          : ""
      }
    </div>
  )
}
