import { mergeArray } from "../arrayTools";

const lessonConfig = {
  // label: 'Lesson',
  // resourceEndpoint: 'lessons',
  // listMetadata: ['sort_order',
  //                'active',
  //                'title',
  //                'featured',
  //                'topics',
  //                'tags',
  //                'actions'],
  // searchKeys: ['title'],
  // detailsMetadata: [
  //   { name: 'title', label: 'Lesson Title'},
  //   { name: 'description', label: 'Description', validations:
  //     [
  //       {type: 'required', params: ['Required']}
  //     ]
  //   },
  //   { name: 'image_url', label: 'Image URL'},
  //   { name: 'release_date', label: 'Release Date'},
  //   { name: 'action_bar_text', label: 'Call to Action Text'},
  //   { name: 'action_bar_url', label: 'Call to Action URL'},
  //   { name: 'sort_order', label: 'Sort Order'},
  //   { name: 'active', label: 'Active'},
  //   { name: 'featured', label: 'Featured'},
  //   { name: 'topics', label: 'Topics'},
  //   { name: 'tags', label: 'Tags'},
  //   { name: 'sections', label: 'Sections'}
  // ],
  subTables: {
    details: {
      label: {
        plural: "Event Details",
        singular: "Event Detail",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "info",
          label: "Detail Info",
          parser: (data) => data.info,
        },
        {
          name: "description",
          label: "Description",
          parser: (data) => data.description,
          ellipsis: true,
        },
      ],
      detailsMetadata: [
        {
          name: "sort_order",
          label: "Sort Order",
        },
        {
          name: "info",
          label: "Detail Info",
          validations: [
            { type: "required", params: ["Required"] },
            {
              type: "max",
              params: [28, "Maximum 28 Characters"],
            },
          ],
        },
        {
          name: "description",
          label: "Description",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "image_url",
          label: "Image URL",
          validations: [
            { type: "max", params: [255, "Maximum 255 Characters"] },
          ],
        },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
    },
    resources: {
      label: {
        plural: "Event Resources",
        singular: "Event Resource",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "title",
          label: "Title",
          parser: (data) => data.title,
        },
        {
          name: "description",
          label: "Description",
          parser: (data) => data.description,
          ellipsis: true,
        },
        {
          name: "tags",
          label: "Tags",
          parser: (data) => mergeArray(data.tags, "name"),
        },
      ],
      detailsMetadata: [
        {
          name: "sort_order",
          label: "Sort Order",
        },
        {
          name: "title",
          label: "Title",
          validations: [
            { type: "required", params: ["Required"] },
            {
              type: "max",
              params: [60, "Maximum 60 Characters"],
            },
          ],
        },
        {
          name: "description",
          label: "Description",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "video_url",
          label: "Video URL",
          validations: [
            { type: "max", params: [255, "Maximum 255 Characters"] },
          ],
        },
        {
          name: "image_url",
          label: "Image URL",
          validations: [
            { type: "max", params: [255, "Maximum 255 Characters"] },
          ],
        },
        {
          name: "action_bar_text",
          label: "Call to Action Text",
          validations: [{ type: "max", params: [50, "Maximum 50 Characters"] }],
        },
        {
          name: "action_bar_url",
          label: "Call to Action URL",
          validations: [
            { type: "max", params: [255, "Maximum 255 Characters"] },
          ],
        },
        { name: "tags", label: "Tags" },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
    },
  },
};

export default lessonConfig;
