import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"

const DEFAULT_VALUES = {
  isEdit: false,
  qrcode: "",
  qrcode_file: "",
  play_store_link: "",
  app_store_link: "",
  media_metadata: {}
}

const onboardingFinalInfo = ({ handleSubmit, initialValues }) => {
  const fields = [
    {
      component: "qrcode",
      name: "qrcode",
      label: "QR Code",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "play_store_link",
      field: "text",
      name: "play_store_link",
      label: "Google Play Link",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "app_store_link",
      field: "text",
      name: "app_store_link",
      label: "Apple Link",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
  ]

  const schema = {
    qrcode: validations.str_255,
    play_store_link: validations.str_255,
    app_store_link: validations.str_255,
  };

  const handleFormatPayload = (values) => {
    const { isEdit, ...rest } = values

    const newPayload = {
      onboarding: {
        ...rest
      }
    }

    return newPayload
  }

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.app?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        qrcode: initialValues?.onboarding?.qrcode,
        qrcode_file: initialValues?.onboarding?.qrcode_file,
        play_store_link: initialValues?.onboarding?.play_store_link,
        app_store_link: initialValues?.onboarding?.app_store_link,
        media_metadata: initialValues?.onboarding?.media_metadata,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Final Info",
      resourceEndpoint: "apps",
      columnsMetadata: [
        { customComponent: (
          <OnboardingContent
            title="Final Info"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={handleFormatPayload}
          />
        ) },
      ],
    }
  }
}

export default onboardingFinalInfo;