import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from '../providers/LoaderProvider'
import { GetTrack, UpdateTrack } from '../services/api/tracks';
import { AddAppResource, GetAppResource } from '../services/api/app'
import { GetItem } from "../services/api/items";
import { validations } from "../lib/form";
import Page from "../components/Page";
import { CheckBox, Column, EntryForm, Input, Select, TextArea } from "../components/EntryForm";
import MediaUploader from '../components/MediaUploader'
import responseProcessor from "../lib/responseProcessor";

const TrackDetails = ({newTrack, match}) => {
  const vals = {
    item_number: 0,
    item_type: '',
    member_email: '',
    receive_messages: false,
    started: false,
    name: '',
    location: '',
    message: '',
    date_added: '',
    image_url: '',
    message_prompt: '',
    download_url: '',
    action_bar_text: '',
    action_bar_url: '',
    item_id: '',
    media_metadata: {}
  };

  const [initialValues, setInitialValues] = useState(vals)
  const [items, setItems] = useState([])
  const [minMaxItemNumber, setMinMaxItemNumber] = useState({min: 0, max: 0});
  const [, setIsItemsLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [metadata, setMetadata] = useState({})
  const { user } = useUserProvider()
  const { showModal } = useModalProvider()
  const { doSetShowLoadingModal } = useLoaderProvider()
  const uuid = newTrack ? user.currentApp() : match.params.uuid
  const history = useHistory();

  const loadData = () => {
    if (newTrack) {
      setInitialValues(vals)
    } else {
      setIsLoading(true)
      GetTrack(uuid, user.idToken).then(res => res.json()).then(res => {
        setInitialValues({
          item_number: res.data.item_number,
          item_id: res.data.item_id,
          item_type: res.data.item_type,
          member_email: res.data.member_email,
          receive_messages: res.data.receive_messages,
          started: res.data.started,
          name: res.data.name,
          location: res.data.location,
          message: res.data.message,
          date_added: res.data.date_added,
          image_url: res.data.image_url,
          message_prompt: res.data.message_prompt,
          download_url: res.data.download_url,
          action_bar_text: res.data.action_bar_text,
          action_bar_url: res.data.action_bar_url,
        })
        GetItem(res.data.item_id, user.idToken).then(res => res.json()).then(res => {
          setMinMaxItemNumber({min: res.data.start_number, max: res.data.end_number})
        })
        setMetadata(res.data.media_metadata)
        setIsLoading(false)
      })
    }
    setIsItemsLoading(false);
    GetAppResource(user, 'items').then(res => res.json()).then(res => {
      setItems(res.data.map((item) => ({ id: item.id, name: item.title, start_number: item.start_number, end_number: item.end_number })))
      setIsItemsLoading(true);
    });
  };

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    doSetShowLoadingModal(isLoading)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newTrack ? "New" : "Edit"
  const currentUrl = newTrack ? "/tracks/new" :`/tracks/${uuid}`

  let links = [
    { name: "Home", url: "/" },
    { name: "Card/Book Tracks", url: "/tracks" },
    { name: `${currentAction} Tracking`, url: currentUrl },
  ]

  let schema = {
    item_number: Yup.number().integer().min(minMaxItemNumber.min).max(minMaxItemNumber.max).required("Required"),
    item_id: Yup.string().required("Required").required("Required"),
    item_type: Yup.string().oneOf(["Card", "Book"]).required("Required"),
    member_email: Yup.string().email().required("Required"),
    receive_messages: Yup.boolean().default(false).required("Required"),
    started: Yup.boolean().default(false),
    name: validations.long_text,
    location: validations.long_text,
    message: Yup.string(),
    date_added: Yup.date(),
    image_url: Yup.string().max(255, 'Maximum 255 Characters'),
    message_prompt: Yup.string(),
    download_url: Yup.string().max(255, 'Maximum 255 Characters'),
    action_bar_text: Yup.string().max(255, 'Maximum 255 Characters'),
    action_bar_url: Yup.string().max(255, 'Maximum 255 Characters'),
  }

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push('/tracks')
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      if (newTrack){
        AddAppResource(uuid, 'tracks', user.idToken, { data: values })
          .then(response => responseProcessorProxy(response))
      } else {
        UpdateTrack(uuid, user.idToken, { data: values })
          .then(response => responseProcessorProxy(response))
      }
    }
  })

  return (
    <Page links={links} title={`${currentAction} Tracking`}>
      <EntryForm isLoading={isLoading} formik={formik} submitHandler={ formik.handleSubmit } cancelURL={`/tracks`}>
        <Column>
          <Input name='item_type' type='text' label='Item Type' formik={formik} disabled />
          <Input name='item_number' type='number' label='Item Number' formik={formik} disabled />
          <CheckBox name='started' label='Started Track?' formik={formik} disabled />
          <CheckBox name='receive_messages' label='Receive Messages?' formik={formik} />
          <Input name='name' type='text' label='Name' formik={formik} />
          <Input name='location' type='text' label='Location' formik={formik} />
          <TextArea name='message' label='Message' formik={formik} />
          <Input name='member_email' type='email' label='Member' formik={formik} disabled />
        </Column>
        <Column>
          <Input name="date_added" label="Date Added" formik={formik} disabled />
          <MediaUploader name='image_url' type='text' label="Card/ Book Image URL" formik={formik} metadata={ metadata } mediaType="image" disabled/>
          <MediaUploader name='video_url' type='text' label="Video URL" formik={formik} metadata={ metadata } mediaType="video" disabled/>
          <TextArea name='message_prompt' label='Message Prompt' formik={formik} disabled />
          <Input name='download_url' type='text' label='Download URL' formik={formik} disabled />
          <Input name='action_bar_text' type='text' label='Call to Action Text' formik={formik} disabled />
          <Input name='action_bar_url' type='text' label='Call to Action URL' formik={formik} disabled />
        </Column>
      </EntryForm>
    </Page>
  )
}

export default TrackDetails;