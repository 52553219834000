import { useCallback, useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { ListUsers, DeleteUser, UsersCount } from "../services/api/user";
// import { GetAllAvailableApps, GetAppResource } from "../services/api/app";
import { GetAllAvailableApps } from "../services/api/app";

import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { useLocalState, useLocalValue, useSetLocalState } from "../lib/useLocalStorage";

import { nanoid } from 'nanoid';


const UserList = () => {
  const [showDropdown, setShowDropdown] = useLocalState('UserList.showDropdown', false)
  const setAvailableApps = useSetLocalState('available_apps')
  const setQuery = useSetLocalState('UserList.query', '')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isButtonActive, setIsButtonActive] = useState(false)

  const [searchField, setSearchField] = useState('')
  const [searchAppId, setSearchAppId] = useState('')
  const [searchAppName, setSearchAppName] = useState('All Apps')
  const [userCount, setUserCount] = useState(null);
  
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    firstLoad: true,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = useCallback(async () => {
    setData({ ...data, loading: true });
    
    const selectedApp = searchAppId
    const searchQuery = searchField

    const payload = {
      appId: selectedApp,
      query: searchQuery
    }
    
    ListUsers(user, payload)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          firstLoad: false
        });
      });
  }, [searchAppId, searchField, data, user]);

  const links = [
    { name: "Home", url: "/" },
    { name: "Manage Users", url: "/users" },
  ];

  const deleteClass =
    "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

  const AppList = ({ apps }) => (
    <table>
      {apps.map((app) => (
        <tr>
          <td>{app.name}</td>
          <td>{app.role}</td>
        </tr>
      ))}
    </table>
  );

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => nanoid(),
    },
    {
      name: "super",
      label: "Super",
      parser: (data) => (data.is_super ? "Yes" : "No"),
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "email",
      label: "Email",
      parser: (data) => data.email,
    },
    {
      name: "app",
      label: "App/Role",
      parser: (data) => <AppList apps={data.apps} />,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          deleteIcon={TrashIcon}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
          deleteClass={deleteClass}
        />
      ),
    },
  ];

  const updateButtonHandler = (id) => {
    history.push(`/users/${id}`);
  };

  const unlinkButtonHandler = async (id) => {
    await DeleteUser(user, id);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="user"
        onDelete={unlinkButtonHandler}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  const handleClickFilterByApp = useCallback(() => {
    setShowDropdown(true)
  }, [setShowDropdown])

  useEffect(() => {
    GetAllAvailableApps(user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setAvailableApps(res.data);
      });
  }, [user.idToken, setAvailableApps])

  useEffect(() => {
    if (searchAppId === '') {
      UsersCount(user)
      .then((res) => res.json())
      .then((jsonData) => {
        setUserCount(parseInt(jsonData.data.count).toLocaleString("en-US"));
      })
    } else {
      UsersCount(user, {appId: searchAppId})
      .then((res) => res.json())
      .then((jsonData) => {
        setUserCount(parseInt(jsonData.data.count).toLocaleString("en-US"));
      })
  
    } 
  }, [user, searchAppId]);

  const handleClearButton = () => {
    // clear query
    setSearchField('')
    setQuery('')
    setData({
      loading: false,
      data: [],
      count: 0,
      firstLoad: true,
    })
    if (searchAppName !== "All Apps") {
      setIsButtonActive(true)
    } else {
      setIsButtonActive(false)
    }
  }
  const handleSearchFieldChange = (value) => {
    setQuery(value)
    setSearchField(value)
    if (searchAppName !== "All Apps") {
      setIsButtonActive(true)
    } else if ( value === '') {
      setIsButtonActive(false)
    } else {
      setIsButtonActive(true)
    }
  }

  const handleKeyEnter = () => {
    if (isButtonActive) {
        loadData()
    }
  }

  return (
    <Page links={links} title="Manage Users">
      <div className="bg-white p-5 mt-5 ttnk-table-container">
        <section className="flex justify-between items-center rounded-full mx-40 mt-8">
          <button onClick={handleClickFilterByApp} style={{maxWidth: 250}} className="ttnk-button relative">
            <div style={{textOverflow: "ellipsis"}} className="overflow-hidden whitespace-nowrap">{searchAppName || "All Apps"} &nbsp;&nbsp;▼</div>
            {showDropdown && (
              <div className="absolute left-0 bottom-0 h-0 pointer-events-none z-50">
                <Dropdown
                  selectedAppId={searchAppId}
                  setSelectedAppId={setSearchAppId}
                  setSelectedAppName={setSearchAppName}
                  setIsButtonActive={setIsButtonActive}
                />
              </div>
            )}
          </button>
          <div className="flex flex-1 rounded-full border mx-2 pl-2">
            <SearchInput searchField={searchField} handleSearchFieldChange={handleSearchFieldChange} handleKeyEnter={handleKeyEnter } />
            <button className={ 'px-4 '+ (searchField ? '' : 'text-gray-200')} disabled={ searchField ? '' : 'disabled' } onClick={handleClearButton}>✕</button>
          </div>
          
          <button onClick={loadData} className="ttnk-button tee" disabled={ isButtonActive ? "" : "disabled"}>Search</button>
          
        </section>
        <div className="text-center pt-8 pb-2">
        Use the Search bar above to search for <strong>{userCount}</strong> users by 
        { searchAppId === '' && (
          ` App Name, `
        )}
        {' '}Name or Email.
        </div>
        <div className="mt-4" />
        { (!data.firstLoad) && ( 
        <DataTable
          metadata={metadata}
          dataLoader={() => {}} // empty on first render
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={["name", "email"]}
          noContainer
        />
        ) }
      </div>
    </Page>
  );
};

// decoupled from main component to prevent uncessesary reload of the main component.
const SearchInput = ({ searchField, handleSearchFieldChange, handleKeyEnter }) => {
  const onInputChangeHandler = (e) => {
    handleSearchFieldChange(e.target.value)
  }
  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      handleKeyEnter()
    }
  }
  return (
    <input 
      id="name-email-input"
      className="w-full py-2 mx-2 px-2 outline-none"
      placeholder="Search by Name or Email"
      value={searchField}
      onChange={onInputChangeHandler}
      onKeyDown={handleKeyDown}
    />
  )
}

function Dropdown({ selectedAppId, setSelectedAppId, setSelectedAppName, setIsButtonActive }) {
  const [keyword, setKeyword] = useState('')
  const availableApps = useLocalValue('available_apps')
  const setShowDropdown = useSetLocalState('UserList.showDropdown')
  
  const handleSelect = useCallback((e, payload) => {
    e?.stopPropagation()

    // selects app id
    setSelectedAppId(payload.id)
    setSelectedAppName(payload.name)

    // close dropdown
    setShowDropdown(false)
    document.getElementById('name-email-input')?.focus()
    if (payload.name !== "All Apps") {
      setIsButtonActive(true)
    } else {
      setIsButtonActive(false)
    }
  }, [setSelectedAppId, setSelectedAppName, setShowDropdown, setIsButtonActive])

  const handleClickOutside = useCallback((e) => {
    const dropdownEl = document.getElementById('app-dropdown');
    
    if(!e?.target?.contains(dropdownEl)) {
      setShowDropdown(false)
    } 
  }, [setShowDropdown])

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [handleClickOutside])
  
  const options = [{ id: "", name: "All Apps" }, ...(availableApps || [])]
  const filteredApps = options?.filter(app => app.name.toLowerCase().includes(keyword.toLowerCase()))
  const activeClassName = "bg-gradient-to-br from-aeblue-dark to-aeblue text-white"
  
  return <div id="app-dropdown" className="w-full bg-white mt-2 shadow-sm z-10 border rounded">
    <input onClick={e => e?.stopPropagation()} value={keyword} onChange={e => setKeyword(e?.target?.value)} autoFocus className="py-2 px-4 text-gray-600 mb-2 pointer-events-auto w-full border" placeholder="Search app name" />
    <ul className="text-gray-600 text-left max-h-48 h-full overflow-y-auto pointer-events-auto">
      {filteredApps?.map(app => {
        let activeStyle = ""
        if(app.id === selectedAppId) activeStyle = activeClassName
        return (
          <li key={app.id} onClick={(e) => handleSelect(e, app)} className={`py-2 px-4 hover:bg-gray-100 pointer-events-auto ${activeStyle}`}>
            {app.name}
          </li>
        )  
      })}
    </ul>
  </div>
}

export default UserList;
