import { Link } from "react-router-dom";
import { useAuth } from "react-oauth2-pkce";
import { useUserProvider } from "../providers/UserProvider";

import Home from "../assets/icons/home.svg";
import Members from "../assets/icons/members.svg";
import Content from "../assets/icons/content.svg";
import Courses from "../assets/icons/courses.svg";
import Offers from "../assets/icons/offers.svg";
import Events from "../assets/icons/events.svg";
import CaretDown from "../assets/icons/caret-down.svg";
import Avatar from "../assets/icons/avatar.svg";
import Admin from "../assets/icons/admin.svg";
import Settings from "../assets/icons/settings.svg";
import Logout from "../assets/icons/logout.svg";
import Search from "../assets/icons/search.svg";
import SwitchApp from "../assets/icons/switch-app.svg";
import ManageAccount from "../assets/icons/manage-user.svg";

import Setup from "../assets/icons/setup.png";
import Communicate from "../assets/icons/communicate.png";
import Manage from "../assets/icons/manage.png";
import Community from "../assets/icons/community.png";

import { GetApp } from "../services/api/app";
import { useEffect, useMemo, useState } from "react";
import { useLocalState } from "../lib/useLocalStorage";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";
import { TimezoneSelect } from "./TimezoneSelect";
import { useModalProvider } from "../providers/ModalProvider";
import TimezoneModalContent from "./TimezoneModalContent";
import { UpdateMember } from "../services/api/member";
import jwtDecode from "jwt-decode";
import { Tooltip } from "@material-ui/core";

const Header = ({ children }) => {
  const { authService } = useAuth();
  const [liteUnlockCode, setLiteUnlockCode] = useLocalState("lite", null);
  const [appName, setAppName] = useState('');
  const { showModal, closeModal } = useModalProvider();

  const logout = async () => {
    sessionStorage.setItem("first_login_prompted", "false");

    const response = await fetch(
      `${process.env.REACT_APP_SSO_HOST}/admins/sign_out`,
      { credentials: "include", method: "GET" }
    );
    authService.logout();
  };

  const goToSupport = () => {
    window.open("http://actionera.freshdesk.com", "_blank").focus();
  };

  const { user, setUser } = useUserProvider();

  const isPNAllowed = useMemo(
    () =>
      user?.app_data?.from_apps?.length > 0 ||
      (user?.app_data?.fcm_key?.length > 0 &&
        user?.app_data?.fcm_project_id?.length > 0),
    [user]
  );

  const platformVersion = useMemo(
    () => Number(user?.app_data?.about?.platform_version),
    [user?.app_data?.about?.platform_version]
  );

  const checkActive = {
    pushNotification:
      !!user.app_data.about.push_notification_app_id &&
      !!user.app_data.about.push_notification_app_key &&
      !user.app_data.setup.free &&
      (user.isSuper ||
        !(
          user.app_data.setup.premium_lite && !user.app_data.setup.premium_plus
        )),
    aboutMember:
      user.isSuper ||
      !(user.app_data.setup.premium_lite && !user.app_data.setup.premium_plus),
    actionList: IsWithinMinimumVersion(8.5),
    podcasts: IsWithinMinimumVersion(9) && user?.app_data?.setup?.podcasts,
  };

  const MainMenuLink = ({ imgSrc, label }) => {
    return (
      <div className="flex justify-center items-center w-full h-full border-r-2 uppercase">
        <img src={imgSrc} className="mr-4 w-6" alt={label} /> {label}{" "}
        <img src={CaretDown} className="ml-4 w-2" alt="" />
      </div>
    );
  };

  const MenuBlock = ({ requireSuper, checkToggle, children }) => {
    return (requireSuper && !user.isSuper) ||
      (checkToggle !== undefined && !checkActive[checkToggle]) ? (
      ""
    ) : (
      <div className="relative flex flex-col w-64 py-4 h-24 border-gray-100 group">
        {children}
      </div>
    );
  };

  const SubMenuLink = ({
    label,
    url,
    additionalClasses,
    requireSuper,
    checkToggle,
    target,
  }) => {
    let classes =
      "flex items-center justify-center h-12 text-white hover:bg-aeblue hover:text-white uppercase";

    if (additionalClasses) {
      classes = classes + " " + additionalClasses;
    }

    return (requireSuper && !user.isSuper) ||
      (checkToggle !== undefined && !checkActive[checkToggle]) ? (
      ""
    ) : (
      <Link to={url} className={classes} target={target}>
        {label}
      </Link>
    );
  };

  const SubMenuContainer = ({ children }) => (
    <div
      style={{ top: "calc(6rem - 0px)" }}
      className="absolute z-10 bg-132850 text-white text-center w-64 t-18 group-hover:block hidden rounded-b-md focus:outline-none filter drop-shadow-md"
    >
      {children}
    </div>
  );

  const DirectLink = ({ url, children, hideBorder }) => {
    let linkClass =
      "flex justify-center text-aeblue-dark hover:text-aeblue-dark items-center w-full h-full";

    if (!hideBorder) {
      linkClass = linkClass + " border-r-2";
    }

    return (
      <div className="relative flex flex-col w-64 py-4 h-24 border-gray-100 group">
        <Link to={url} className={linkClass}>
          {children}
        </Link>
      </div>
    );
  };

  useEffect(() => {
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setAppName(res.data.name);
        const app_setup = res.data.setup;
        if (app_setup?.premium_lite && app_setup?.lite_unlock_code && !app_setup?.biz_plus) {
          setLiteUnlockCode(app_setup.lite_unlock_code);
        } else setLiteUnlockCode("");
      });
  }, [user]);

  const [isHovering, setIsHovering] = useState(false);

  const updateTimezone = async () => {
    await UpdateMember(user.data.sub, user, {
      data: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    const res = await authService.fetchToken(
      authService.getAuthTokens().refresh_token,
      true
    );
    const data = jwtDecode(res.id_token);

    setUser((prev) => ({
      ...prev,
      data,
    }));
  };

  useEffect(() => {
    if (!user) return;
    if (!user?.data?.timezone) {
      updateTimezone();
      showModal(
        <TimezoneModalContent
          closeModal={closeModal}
          onClickTimezone={() => {
            setIsHovering(true);
            closeModal();
          }}
        />
      );
    }

    // if pop up explainer video = true
    // show modal

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const appTitle = liteUnlockCode
    ? `${appName} (${liteUnlockCode}) - Home`
    : `${appName} - Home`;

  return (
    <div className="fixed z-10 bg-white h-30 filter drop-shadow-md">
      <div className="bg-132850 flex w-screen h-16 justify-between items-center pl-4 pr-8">
        <label className="uppercase text-white">
          <Link to="/" className="text-white">
            {appTitle}
          </Link>
        </label>
        <div className="flex text-white justify-center h-full w-72 mr-8">
          <div
            id="user-details"
            className="relative w-full h-full flex flex-col group justify-center"
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className="flex h-full items-center ml-auto mr-2">
              <img src={Avatar} className="block h-8 mr-2" />
            <Tooltip title={<div>{user.data.name}</div>}>
            <div className="truncate" style={{
                maxWidth: 180,
              }}>{user.data.name}</div>
            </Tooltip>
            </div>
            <div
              style={{
                top: "calc(4rem - 1px)",
                display: isHovering ? "block" : "none",
              }}
              className="absolute bg-132850 text-white rounded-b-lg w-full z-10"
            >
              <div className="w-100 px-4 pt-8 text-white">
                <Tooltip title={<div>{user.data.name}</div>}>
                <div className="text-bold text-lg pb-4 truncate">{user.data.name}</div>
                </Tooltip>

                <Tooltip title={<div>{user.data.email}</div>}>
                <div className="truncate">{user.data.email}</div>
                </Tooltip>
              </div>
              <div className="w-100 border-b border-gray-200 pb-8 pt-5">
                <div className="text-white text-[20px] pl-2 mb-2 ml-2">
                  Timezone
                </div>
                <TimezoneSelect />
              </div>
              <div className="w-100">
                <Link
                  className="hover:bg-aeblue hover:text-white px-4 w-100 py-4 flex cursor-pointer text-white"
                  to="/change_password"
                >
                  <img src={Settings} className="w-4 mr-2 text-white" />
                  Change Password
                </Link>
                {/* Temporarily set to hidden */}
                {/* <a
                  className="hover:bg-aeblue hover:text-white px-4 w-100 py-4 flex cursor-pointer text-white"
                  href="https://actionera.chargebeeportal.com/portal/v2/login?forward=portal_main"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ManageAccount} className="w-4 mr-2 text-white" />
                  Manage Account
                </a> */}
                <Link
                  className="hover:bg-aeblue hover:text-white px-4 w-100 py-4 flex cursor-pointer text-white"
                  to="/change_application"
                >
                  <img src={SwitchApp} className="w-4 mr-2 text-white" />
                  Change Application
                </Link>
                <div
                  className="hover:bg-aeblue px-4 w-100 py-4 flex cursor-pointer rounded-b-lg"
                  onClick={goToSupport}
                >
                  <img src={Search} className="w-4 mr-2 text-white" />
                  Support
                </div>
                <div
                  className="hover:bg-aeblue px-4 w-100 py-4 flex cursor-pointer rounded-b-lg"
                  onClick={() => logout()}
                >
                  <img src={Logout} className="w-4 mr-2 text-white" />
                  Sign out
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <nav
          id="nav-menu"
          className="flex ttnk-heading-text-color text-2xl tracking-wide"
        >
          <MenuBlock requireSuper={true}>
            <MainMenuLink imgSrc={Admin} label="Admin" />
            <SubMenuContainer>
              <SubMenuLink
                url="/app/setup"
                label="ACCOUNT SETUP"
                requireSuper={true}
              />
              <SubMenuLink
                url="/app/domains"
                label="Domain Setup"
                requireSuper={true}
              />
              <SubMenuLink
                url="/app/about"
                label="About App"
                requireSuper={true}
              />
              <SubMenuLink
                url="/whats_new"
                label="What's New"
                requireSuper={true}
              />
              <SubMenuLink
                url="/users"
                label="Manage Users"
                requireSuper={true}
              />
              <SubMenuLink
                url="/super-users"
                label="Super Users"
                requireSuper={true}
              />
              <SubMenuLink
                url="/unlink-media"
                label="Unlinked Media"
                requireSuper={true}
              />
              <SubMenuLink
                url="/removed_apps"
                label="Removed Apps"
                requireSuper={true}
              />
              <SubMenuLink
                url="/account_status"
                label="Account Status"
                requireSuper={true}
              />
              <SubMenuLink
                url="/global-fields"
                label="Global Fields"
                requireSuper={true}
              />
              {user?.app_data?.setup?.biz_plus && (
                <SubMenuLink
                  url="/onboarding_tracker"
                  label="Onboarding Tracker"
                  requireSuper={true}
                  additionalClasses="rounded-b-md"
                />
              )}
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Setup} label="Setup" />
            <SubMenuContainer>
              {user?.app_data?.setup?.web && (
                <SubMenuLink url="/webapp" label="Web Setup" />
              )}
              <SubMenuLink url="/menu-setup" label="Menu Setup" />
              <SubMenuLink url="/app-info" label="App Info" />
              <SubMenuLink
                url="/app/about_members"
                label="Member Screens"
                checkToggle="aboutMember"
              />
              <SubMenuLink
                url="/track_now_screen"
                label="Unlock/Track Screen"
              />
              <SubMenuLink
                url={{
                  pathname:
                    "https://web.actionera.com/actionera/resources/449841c5-319e-437f-919a-dd01f0c42808",
                }}
                target="_blank"
                label="Custom Domain"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Communicate} label="Communicate" />
            <SubMenuContainer>
              {isPNAllowed ? (
                platformVersion >= 9.0 ? (
                  <SubMenuLink
                    url="/push-notifications"
                    label="Push Notifications"
                    additionalClasses="rounded-b-md"
                  />
                ) : (
                  <SubMenuLink
                    url="/push_notifications/new"
                    label="Push Notifications"
                    checkToggle="pushNotification"
                  />
                )
              ) : null}
              <SubMenuLink
                url={{
                  pathname:
                    "https://web.actionera.com/actionera/resources/977ebe97-ca0a-4375-a3ce-aa931f383d96",
                }}
                target="_blank"
                label="In-App Notifications"
              />
              <SubMenuLink
                url={{
                  pathname:
                    "https://web.actionera.com/actionera/resources/6fb7f343-dead-451d-9208-a719d43c7e3c",
                }}
                target="_blank"
                label="Push Funnels"
              />
              <SubMenuLink
                url="/messages"
                label="Member Messages"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Manage} label="Manage" />
            <SubMenuContainer>
              {(user?.app_data?.setup?.enterprise ||
                user?.app_data?.reseller_of?.length > 0) && (
                <SubMenuLink url="/business" label="Businesses" />
              )}
              <SubMenuLink url="/creators" label="Creators" />
              <SubMenuLink url="/members" label="Members" />
              <SubMenuLink url="/tags" label="Tags" />
              <SubMenuLink url="/directories" label="Directories" />
              <SubMenuLink url="/topics" label="Topics/Listings" />
              <SubMenuLink url="/welcome_screens" label="Home Screens" />
              {user?.app_data?.setup?.enterprise && (
                // feature onhold; will uncomment and replace the one's below once there's a go signal from the Client to proceed
                // <SubMenuLink url="/enterprise_messages" label="Enterprise Messages" />

                <SubMenuLink
                  url="/portal_homescreen"
                  label="Portal Homescreen"
                />
              )}
              <SubMenuLink url="/import-export" label="Import/Export" />
              <SubMenuLink
                url="/action-forms"
                label="ACTIONFORMS"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Content} label="ACTIONPAGES" />
            <SubMenuContainer>
              <SubMenuLink
                url="/action-lists"
                label="ACTIONLISTS"
                checkToggle="actionList"
              />
              <SubMenuLink url="/tips" label="ACTIONBLOG" />
              <SubMenuLink url="/videos" label="Videos" />
              <SubMenuLink url="/articles" label="Articles" />
              <SubMenuLink url="/lessons" label="Lessons" />
              <SubMenuLink url="/audios" label="Audio" />
              <SubMenuLink url="/journals" label="ActionJournal" />
              <SubMenuLink url="/questions" label="Q&A" />
              <SubMenuLink url="/galleries" label="Galleries" />
              <SubMenuLink url="/resources" label="Resources" />
              <SubMenuLink url="/products" label="Products" />
              <SubMenuLink url="/offers" label="Special Offer Codes" />
              <SubMenuLink url="/profiles" label="Profiles" />
              <SubMenuLink
                url="/podcasts"
                label="Podcasts"
                checkToggle="podcasts"
              />
              {platformVersion >= 8.5 && (
                <SubMenuLink
                  url="/documents"
                  label="Documents"
                  additionalClasses="rounded-b-md"
                />
              )}
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Courses} label="ActionCourses" />
            <SubMenuContainer>
              <SubMenuLink url="/course_categories" label="Categories" />
              <SubMenuLink
                url="/courses"
                label="Create/Edit"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Community} label="Community" />
            <SubMenuContainer>
              <SubMenuLink url="/items" label="TrackaConnect" />
              <SubMenuLink url="/tracks" label="Trackings" />
              <SubMenuLink
                url={{
                  pathname:
                    "https://web.actionera.com/actionera/resources/ef22b999-5b49-413c-a5ef-202c0e3a4f6f",
                }}
                target="_blank"
                label="Comments"
              />
              <SubMenuLink
                url={{
                  pathname:
                    "https://web.actionera.com/actionera/resources/65a4d3db-b21b-48e4-a28a-617f0e8db8e1",
                }}
                target="_blank"
                label="ActionCommunity"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>

          <MenuBlock>
            <MainMenuLink imgSrc={Events} label="Events" />
            <SubMenuContainer>
              <SubMenuLink url="/events" label="ActionCalendar" />
              <SubMenuLink url="/summits" label="ActionSummits" />
              <SubMenuLink
                url="/virtual_events"
                label="ActionEvents"
                additionalClasses="rounded-b-md"
              />
            </SubMenuContainer>
          </MenuBlock>
        </nav>
      </div>
    </div>
  );
};

export default Header;
