import App from './App';
import LoginPending from './pages/LoginPending'
import Login from './pages/Login'
import { UserProvider } from './providers/UserProvider'
import { ModalProvider } from './providers/ModalProvider'
import { DrawerProvider } from './providers/DrawerProvider'
import { LoaderProvider } from './providers/LoaderProvider'

import { useAuth } from 'react-oauth2-pkce'
import FreeTrialForm from './pages/FreeTrialForm';
import DisabledTrialForm from './pages/DisabledTrialForm';
import AccountSetupForm from './pages/AccountSetupForm';
import OptInForm from './pages/OptInForm';
import { useEffect } from 'react';
import { OnboardingProvider } from './providers/OnboardingProvider';

const HIDDEN_DESKTOPAPP_URLS = [
  '/ae-free', 
  '/pp-setup', 
  '/gp-setup', 
  "/thrivecart-sign-up",
  "/bp-setup",
  "/free-trial",
  "/pr/join",
  "/prpl/join",
  "/fr/join"
]

function SecuredApp() {
  const { authService } = useAuth();

  useEffect(() => {
    if(!HIDDEN_DESKTOPAPP_URLS.includes(window.location.pathname)){
      let headHTML = document.getElementsByTagName('head')[0].innerHTML;
      headHTML += '<link rel="manifest" href="/manifest.json" />';
      document.getElementsByTagName('head')[0].innerHTML = headHTML;
    }
  }, [])

  const getAccountSetupFormType = type => {
    return (
      <ModalProvider>
        <LoaderProvider>
          <UserProvider>
            <AccountSetupForm
              seThrivecart={false}
              type={type}
            />
          </UserProvider>
        </LoaderProvider>
      </ModalProvider>
    )
  }

  const getOptInFormType = type => {
    return (
      <ModalProvider>
        <LoaderProvider>
          <UserProvider>
            <OptInForm
              seThrivecart={false}
              type={type}
            />
          </UserProvider>
        </LoaderProvider>
      </ModalProvider>
    )
  }

  if(window.location.pathname === "/free-trial") {
    return (
      <ModalProvider>
        <FreeTrialForm useThrivecart={false} />
      </ModalProvider>
    )
  }

  if(window.location.pathname === "/bp-setup") {
    return (
      <ModalProvider>
        <FreeTrialForm
          seThrivecart={false}
          type="BIZ+"
        />
      </ModalProvider>
    )
  }
  
  if(window.location.pathname === "/thrivecart-sign-up") {
    return (
      <ModalProvider>
        <FreeTrialForm useThrivecart={true} />
      </ModalProvider>
    )
  }

  if (['/gp-setup', '/pp-setup', '/ae-free'].includes(window.location.pathname)) {
    return (
      <ModalProvider>
        <DisabledTrialForm/>
      </ModalProvider>
    )
  } 

  if(window.location.pathname.includes("/pr/join")) {
    return getAccountSetupFormType('pro');
  }

  if(window.location.pathname.includes("/prpl/join")) {
    return getAccountSetupFormType('pro_plus');
  }

  if(window.location.pathname.includes("/fr/join")) {
    return getAccountSetupFormType('free_account');
  }

  // Enterprise Opt-in Forms
  if(window.location.pathname.includes("/oi-pr-setup")) {
    return getOptInFormType('proaccount');
  }

  if(window.location.pathname.includes("/oi-pp-setup")) {
    return getOptInFormType('proplusaccount');
  }

  if(window.location.pathname.includes("/oi-fr-setup")) {
    return getOptInFormType('freeaccount');
  }

  
  if (authService.isPending()) { 
    return <LoginPending />
  }

  if (!authService.isAuthenticated()) { 
    return <Login />
  }
  
  return (
    <ModalProvider>
      <LoaderProvider>
        <UserProvider>
          <OnboardingProvider>
            <DrawerProvider>
              <App />
            </DrawerProvider>
        </OnboardingProvider>
        </UserProvider>
      </LoaderProvider>
    </ModalProvider>
  )
}

export default SecuredApp;
