import React, { useState } from 'react';
import fallBackImage from '../assets/images/no-picture.png';

export const Image = ({
  src,
  className,
  alt,
}) => {
  const [showFallbackImage, setShowFallbackImage] = useState(false);

  return (
    <div
      className={`w-full flex flex-shrink-0 lg:rounded-t-xl lg:rounded-b-none rounded-xl justify-center ${
        showFallbackImage ? 'bg-[#888888]' : ''
      }`}
    >
      {showFallbackImage ? (
        <img
          src={fallBackImage}
          alt={`${String(alt)} failed to display`}
          className={`max-w-full object-scale-down rounded-lg min-h-[100px] m-auto`}
        />
      ) : (
        <img
          onError={() => setShowFallbackImage(true)}
          alt={alt ?? ''}
          src={String(src)}
          className={`m-auto ${className ?? ''}`}
        />
      )}
    </div>
  );
};
