

const AccordionBody = ({
  data,
  children,
  isExpandable,
  isSubAccordion,
  index,
  indexLength,
}) => {



  return (
    <div
      className={`
      flex flex-col justify-center px-4 pt-0 pb-6
      ${isExpandable ? "bg-lightBlue" : "bg-white"}
      ${
        isSubAccordion
          ? ""
          : `border border-t-0 border-solid border-darkGray
      ${index === indexLength - 1 ? "rounded-b-lg border-b-1" : "border-b-0"}`
      }
    `}
    >
      {children}
    </div>
  );
};

export default AccordionBody;
