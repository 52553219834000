import Description from "../../components/Description"
import { Image } from "../../components/Image"
import { VideoPlayer } from "../../components/VideoPlayer"
import UploadSummary from "../../components/UploadSummary"
import { validations } from "../form"

const DEFAULT_VALUES = {
  isEdit: false,
  name: "",
  directory_type: "experts",
  youtube_or_vimeo: ""
}

const OnboardingDirectory = () => {
  const fields = [
    {
      component: "name",
      label: "Directory Name",
      placeholder: "This is where your listings will show (Experts or Resources)",
      isFieldRow: true,
    },
    {
      component: "directory_type",
      label: "Directory Type",
      isFieldRow: true,
      rows: 4
    },
    {
      component: "youtube_or_vimeo",
      label: "Youtube or Vimeo Link",
      isFieldRow: true,
    }
  ]

  const schema = {
    name: validations.str_req_60,
    youtube_or_vimeo: validations.long_url.required(),
  };

  const handleSubmit = (payload, successCallbackFn) => {
    if (typeof successCallbackFn === 'function') successCallbackFn()
  }

  return {
    config: {
      label: "Onboarding - Video",
      resourceEndpoint: "video",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=w4cTYnOPdNk" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.
          </Description>
        ) },
        { customComponent: (
          <Image
            src="https://www.digitalcitizen.life/wp-content/uploads/2020/10/screenshots_2.jpg"
            alt="Directory Screenshot"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <UploadSummary
            title="Directory"
            fields={fields}
            schema={schema}
            defaultValues={DEFAULT_VALUES}
            handleSubmit={handleSubmit}
          />
        )
        },
      ]
    }
  }
}

export default OnboardingDirectory;