import React from "react";

const TimezoneModalContent = ({ onClickTimezone, closeModal }) => {
  return (
    <div>
      <div className="">
        We have updated your timezone to{" "}
        {Intl.DateTimeFormat().resolvedOptions().timeZone}. Click{" "}
        <strong>Timezone</strong> below if you wish to change the current
        Timezone.
      </div>
      <div className="flex flex-row gap-2 m-auto items-center justify-center mt-4">
        <button className="ttnk-button" onClick={closeModal}>
          OK
        </button>
        <button type="button" className="ttnk-button" onClick={onClickTimezone}>
          Timezone
        </button>
      </div>
    </div>
  );
};

export default TimezoneModalContent;
