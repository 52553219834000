import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetItem = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/items/${uuid}`, 'GET', idToken)
}

export const UpdateItem = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/items/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteItem = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/items/${uuid}`, 'DELETE', idToken)
}
