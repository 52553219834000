import { useAuth } from 'react-oauth2-pkce'
import Logo from '../assets/images/ae-logo-dk.png'
import landingHero from '../assets/images/landing-hero.png'
import { useModalProvider } from '../providers/ModalProvider';
import { useFreeTrialLoginRedirect } from './FreeTrialForm';

const Login = () => {
  const { authService } = useAuth();

  useFreeTrialLoginRedirect()

  const login = async () => {
    sessionStorage.setItem("first_login_prompted", "false");
    authService.authorize();
  }

  return(
    <div className="h-screen w-full bg-red-200 flex flex-col">
      <div id="Header" className="bg-132850 w-full flex-grow-0 flex py-10 items-center justify-between">
        <div id="app-info" className="mx-12 flex items-center">
          <div id="logo"><img src={ Logo } className="object-contain w-64 h-16 mr-8" alt="ACTIONERA logo"/></div>
        </div>
        <div id="action">
          <h1 className="text-white mr-12 text-2xl font-light">The Ultimate Expert App Platform</h1>
        </div>
      </div>
      <div id="content" className="ttnk-main-bg flex flex-row flex-grow">
        <div id="blurb" className="w-1/2 flex items-center">
          <div className="ml-24">
            <div className="text-6xl uppercase text-gray-800 font-header tracking-wide font-black text-headline leading-tight"><span className="text-6xl uppercase text-gray-800 font-header tracking-wide font-black text-headline leading-tight">A must-have tool for every</span>
              <div className="slidingVertical text-aeblue">
                <span className="landing-header text-aeblue">creator</span>
                <span className="landing-header text-aeblue">entrepreneur</span>
                <span className="landing-header text-aeblue">coach</span>
                <span className="landing-header text-aeblue">speaker</span>
                <span className="landing-header text-aeblue">expert</span>
              </div>
            </div>
            <h2 className="text-3xl font-header text-gray-800 tracking-tight leading-tight">
              Share your videos, articles, courses, marketing strategies and more! All in one app!
            </h2>
            <button id="login-btn" onClick={() => { login(); }} className="ttnk-button-color text-white rounded-full text-3xl px-24 py-4 mt-12">Log in to continue</button>
          </div>
        </div>
        <div id="hero" className="flex items-center justify-center w-1/2 pr-12">
          <img src={landingHero} className="object-contain" alt="Landing Hero image" />
        </div>
      </div>
    </div>
  )
}

export default Login

export function InvalidAccountTypeError() {
  const { authService } = useAuth();
  const { closeModal } = useModalProvider();

  const handleOnClick = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;

    await fetch(
      `${process.env.REACT_APP_SSO_HOST}/admins/sign_out`,
      { credentials: "include", method: "GET" }
    );

    e.target.disabled = false;

    authService.logout();
    closeModal()
  };

  //   e.target.disabled = false;
  //   authService.logout();
  //   // closeModal();
  // };

  return (
    <div>
      <div style={{ width: 400 }} className="mb-4">
        This email is not set up as a "Content Creator" email. You are not
        logged in. Please check your email and use the login that gives you
        access to your content portal or visit the website you are trying to
        access and login through the site.
      </div>
      <div className='flex justify-center'>
      <button onClick={handleOnClick} className="ttnk-button">
        Go Back
      </button>
      </div>
    </div>
  );
}

