import * as Yup from "yup";

import { validations } from "../form"
import ImageSlideout from '../../assets/images/questions-slideout.jpg'
import { VideoPlayer } from "../../components/VideoPlayer"
import { Image } from "../../components/Image"
import Description from "../../components/Description"
import OnboardingContent from "../../components/onboarding/OnboardingContent"

const DEFAULT_VALUES = {
  email: "",
  question: "",
  answer: "",
  topics: []
}

const OnboardingQuestions = ({ handleSubmit, initialValues }) => {

  const values = initialValues ? {
    ...initialValues,
    email: initialValues.send_to,
    question: initialValues.title,
    answer: initialValues.description,
    topics: initialValues?.topics
  } : DEFAULT_VALUES

  const fields = [
    {
      component: 'email',
      label: "Questions Email",
      placeholder: "What email address should they send questions to?",
      isFieldRow: true,
      required: true
    },
    {
      component: "question",
      label: "Question",
      placeholder: "ie. “When you meditate, is your breathing shallow?”",
      isFieldRow: true,
      required: true
    },
    {
      component: "answer",
      label: "Answer",
      placeholder: "Answer the above question for your App users",
      isFieldRow: true,
      rows: 4,
      required: true
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
      required: true,
    },
  ]

  const schema = {
    email: validations.str_req_255,
    question: Yup.string()
      .required('Required')
      .max(100, "Maximum 100 Characters"),
    answer: Yup.string()
      .required('Required')
      .max(65535, "Maximum 65,535 Characters"),
    topics: Yup.array().min(1, "Required"),
  };

  return {
    config: {
      label: "Q&A",
      resourceEndpoint: "questions",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=OJ1CR4OnXME" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please add 2 Q&As where you (the Expert) answer questions (avoid questions like “What does coaching cost?”) your clients or App users may have (see images below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Q&A"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Q&A"
            defaultValues={values}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {

              const newPayload = {
                send_to: values?.email,
                title: values?.question,
                description: values?.answer,
                topics: values?.topics
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingQuestions;