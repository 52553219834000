import { useFormik } from "formik";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Column, EntryForm } from "../components/EntryForm";
import Page from "../components/Page";
import actionformConfig from "../lib/actionform_config/config";
import * as Yup from "yup";
import {
  useDuplicateValidation,
  getFieldComponent,
} from "../lib/form";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useHistory, useParams } from "react-router-dom";
import SubTable from "../components/SubTable";
import { AddAppResource } from "../services/api/app";
import responseProcessor from "../lib/responseProcessor";
import {
  GetActionForm,
  GetSubmittedForms,
  UpdateActionForm,
} from "../services/api/action_forms";
import SubmittedActionForms from "./SubmittedActionForms";

const ActionFormsDetail = ({ newContent }) => {
  const history = useHistory();
  const currentAction = newContent ? "NEW" : "EDIT";

  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  let { uuid } = useParams();

  if (newContent) {
    uuid = user.currentApp();
  }

  const vals = {
    name: "",
    form_type: "",
    headline: "",
    actionform_description: "",
    email: "",
    submit_link: "",
    link_expiry_date: new Date(moment().add(90, "days")),
    active: true,
    auto_approve_new: false,
    auto_approve_edit: false,
    sub_topics: [],
    url_subtopics: [],
    tags: [],
    directories: [],
    enterprise: false
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [formType, setFormType] = useState("");
  const [subTables, setSubTables] = useState(null);
  const [submittedForms, setSubmittedForms] = useState([]);
  const [searchKeys, setSearchKeys] = useState([]);

  const currentUrl = newContent ? `/action-forms/new` : `/action-forms/${uuid}`;

  const links = [
    { name: "Home", url: "/" },
    { name: "ACTIONFORMS", url: "/action-forms" },
    {
      name: `${currentAction} ACTIONFORM`,
      url: currentUrl,
    },
  ];

  const fullSchema = {
    form_type: Yup.string().required("Required"),
    name: Yup.string().max(60, "Maximum 60 characters").required("Required"),
    headline: Yup.string()
      .max(100, "Maximum 100 characters")
      .required("Required"),
    actionform_description: Yup.string()
      .max(65535, "Maximum 65,535 Characters")
      .required("Required"),
    email: Yup.string().email("Invalid Email"),
    submit_link: Yup.string()
      .url("Please enter a valid URL")
      .max(255, "Maximum 255 characters")
      .required("Required"),
    active: Yup.bool(),
    auto_approve_new: Yup.bool(),
    auto_approve_edit: Yup.bool(),
    directories: Yup.array().nullable(),
    link_expiry_date: Yup.date(),
  };

  const inMetadata = (name) => {
    const metadataNames = actionformConfig(formType).detailsMetadata.map(
      (datum) => datum.name
    );
    return metadataNames.indexOf(name) > -1;
  };

  const getFieldMetadata = (name) => {
    const metaData = actionformConfig(formType).detailsMetadata.filter(
      (datum) => datum.name === name
    );
    return metaData.length ? metaData[0] : null;
  };

  // Add additional validation
  actionformConfig(formType).detailsMetadata.forEach((field) => {
    if (field.validations) {
      field.validations.forEach((validation) => {
        const { type, params } = validation;
        if (!fullSchema[field.name][type]) {
          return;
        }
        fullSchema[field.name] = fullSchema[field.name][type](...params);
      });
    }
  });

  const validate = useDuplicateValidation(["name"], "url_subtopics");

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/action-forms"),
    });
  };

  const schema = Object.fromEntries(
    Object.entries(fullSchema).filter(([key, _value]) => inMetadata(key))
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validate: (values) => validate(values, formik),
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      values.description = values.actionform_description;
      values.notification_email = values.email;
      delete values.actionform_description;
      delete values.email;

      if (newContent) {
        AddAppResource(uuid, "action_forms", user.idToken, {
          data: values,
        }).then((response) => responseProcessorProxy(response));
      } else {
        UpdateActionForm(uuid, user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      }
    },
  });

  const getLabel = (name) => {
    if (inMetadata(name)) {
      return actionformConfig(formType).detailsMetadata.filter((datum) => {
        if (datum.name === name) {
          return datum;
        }
        return false;
      })[0].label;
    } else {
      return "No Label";
    }
  };

  const fetchSubmittedForms = () => {
    GetSubmittedForms(uuid, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setSubmittedForms(res.data);

        switch (formType) {
          case "Profile":
            setSearchKeys(["name", "email", "title", "business_name"]);
            break;
          case "Offer":
            setSearchKeys(["title", "message"]);
            break;
          default:
            setSearchKeys(["name", "sub_line"]);
        }
      });
  };

  useEffect(() => {
    const type = formik?.values?.form_type;
    setFormType(type);

    if (type === "Topic") setSubTables(actionformConfig(type).subTables);
    else setSubTables(undefined);
  }, [formik?.values?.form_type]);

  useEffect(() => {
    if (newContent) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetActionForm(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            id: res.data.id,
            name: res.data.name,
            form_type: res.data.form_type,
            headline: res.data.headline,
            actionform_description: res.data.description,
            email: res.data.notification_email,
            submit_link: res.data.submit_link,
            link_expiry_date: res.data.link_expiry_date,
            active: res.data.active,
            auto_approve_new: res.data?.auto_approve_new,
            auto_approve_edit: res.data?.auto_approve_edit,
            sub_topics: res.data?.sub_topics?.map((st) => ({ id: st })) ?? [],
            url_subtopics: res.data.url_subtopics ?? [],
            tags: res.data.tags ?? [],
            directories: res.data.directories ?? [],
            enterprise: res.data.enterprise ?? false
          });
          setMetadata(res.data.media_metadata);
          switch (res.data.form_type) {
            case "Profile":
              setSearchKeys(["name", "email", "title", "business_name"]);
              break;
            case "Offer":
              setSearchKeys(["title", "message"]);
              break;
            default:
              setSearchKeys(["name", "sub_line"]);
          }
        })
        .then(() => {
          GetSubmittedForms(uuid, user.idToken)
            .then((res) => res.json())
            .then((res) => {
              setSubmittedForms(res.data);
            });
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderDynamicFormLayout = () => {
    const columns = [];

    actionformConfig(formType).formLayout.columns.forEach((col, colIndex) => {
      let contentURL = "";
      const web = user?.app_data?.setup?.web ?? false;

      if (colIndex === 1 && web) {
        const WEB_FQDN = process.env.REACT_APP_WEB_FQDN;
        const username = user?.app_data?.username;

        contentURL = `${WEB_FQDN}/${username}/action-forms/${uuid}/${formType.toLocaleLowerCase()}`;
      }

      columns.push(
        <Column key={colIndex}>
          {col.map((fieldName, index) => {
            const fieldConfig = getFieldMetadata(fieldName);
            const thisField = formik.getFieldProps(fieldName);
            let hasFieldConditions = false;
            let isFieldVisible = true;
            hasFieldConditions = !!fieldConfig?.conditions;
            if (hasFieldConditions) {
              for (
                let i = 0, len = fieldConfig?.conditions.length;
                i < len;
                i++
              ) {
                const cond = fieldConfig?.conditions[i];
                const relativeField = formik.getFieldProps(cond.relativeField);
                isFieldVisible = cond.comparisonFunc(relativeField, thisField);
                if (isFieldVisible) {
                  break;
                }
              }
            }

            return !hasFieldConditions ||
              (hasFieldConditions && isFieldVisible) ? (
              <Fragment key={index}>
                {getFieldComponent(
                  fieldName,
                  {
                    label: getLabel(fieldName),
                    formik,
                    metadata,
                    formType,
                    format: fieldConfig?.format,
                    width: fieldConfig?.width,
                    placeholder: fieldConfig?.placeholder,
                    contentType: "actionForm",
                    disabled:
                      (["auto_approve"].includes(fieldName)
                      ? !formik.values.active
                      : submittedForms?.length),
                    isEnterprise: formik.values.enterprise,
                    user
                  },
                  `web_${fieldName}`
                )}
                {fieldName === "tags" && formType === "Topic" ? (
                  <div
                    className="italic text-right font-medium"
                    style={{
                      marginTop: -12,
                    }}
                  >
                    Tags are applied to Listing
                  </div>
                ) : null}
              </Fragment>
            ) : null;
          })}

          {!!contentURL && !newContent && (
            <div>
              <span>
                {`Web URL: `}
                <a href={contentURL} target="_blank" rel="noopener noreferrer">
                  {contentURL}
                </a>
              </span>
            </div>
          )}
        </Column>
      );
    });

    return columns.map((col) => {
      return col;
    });
  };

  return (
    <Page
      links={links}
      title={`${currentAction} ${
        actionformConfig(formType).overrideTitle ||
        actionformConfig(formType).label
      }`}
    >
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL={`/action-forms`}
      >
        {RenderDynamicFormLayout()}

        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={actionformConfig(formType)}
                  subContentType={key}
                  labels={subTables[key].label}
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={formType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}

        {!newContent && (
          <SubmittedActionForms
            type={formType}
            submittedForms={submittedForms}
            searchKeys={searchKeys}
            fetchSubmittedForms={fetchSubmittedForms}
          />
        )}
      </EntryForm>
    </Page>
  );
};

export default ActionFormsDetail;
