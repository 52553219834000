import { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"

import { useUserProvider } from "../providers/UserProvider";
import PushNotificationModal from "../components/modal_contents/PushNotificationModal/PushNotificationModal";
import { GetPushNotificationList } from "../services/api/push_notification";
import moment from "moment";
import { Link } from "react-router-dom";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";

const DisplayCount = ({
  isLoading,
  totalCount,
  totalActiveCount
}) => {
  return (
    <>
      {
        !isLoading ? (
          <div className="flex flex-row justify-between items-center pb-1">
            <div className="flex flex-col">
              <h1 className="uppercase font-bold font-header text-6xl text-white">{totalCount}</h1>
              <div className="uppercase text-white font-semibold">total</div>
            </div>

            <div className="flex flex-col">
              <h1 className="uppercase font-bold font-header text-6xl text-white">{totalActiveCount}</h1>
              <div className="uppercase text-white font-semibold">active unique</div>
            </div>
          </div>
        ) : null
      }
    </>
  )
}

const PushNotificationList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);
  const [pnData, setCurrentPnData] = useState(null);
  const [isRefreshed, setIsRefreshed] = useState(false)

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  });

  const { user, shouldFetchList, setShouldFetchList } = useUserProvider();

  const isPNAllowed = useMemo(() => user?.app_data?.from_apps?.length > 0 || (user?.app_data?.fcm_key?.length > 0 && user?.app_data?.fcm_project_id?.length > 0), [user])

  const deleteClass =
    "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

  const loadData = useCallback(async() => {
    setData({ ...data, loading: true });
    GetPushNotificationList(user.currentApp(), user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        });
      });
  }, [data, user])

  const refreshData = useCallback(() => {
    loadData().then(() => setShouldFetchList(false)).then(() => setIsRefreshed(false))
  }, [loadData, setShouldFetchList])

  useEffect(() => {
    let isMounted = true

    if (shouldFetchList && isRefreshed === false && isMounted) {
      isMounted = false
      setIsRefreshed(true)
      refreshData()
    }

    return () => {
      isMounted = true
    }
  }, [shouldFetchList, refreshData, isRefreshed])

  const updateButtonHandler = (id) => {
    const currentData = data?.data?.find(item => item.id === id)

    setCurrentPnData({
      ...currentData,
      parent_uuid: currentData?.parent_app,
      isOwnPN: Boolean(currentData?.parent_app) === false
    })
    setShouldShow(true)
  };

  useEffect(() => {
    return () => {
      setCurrentPnData(null)
    }
  }, [])

  const links = [
    { name: "Home", url: "/" },
    { name: "Push Notifications", url: "/push-notifications" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "name",
      label: "Subject",
      parser: (data) => data.subject,
      ellipsis: true,
    },
    {
      name: "message",
      label: "Message",
      parser: (data) => data.message,
      ellipsis: true,
    },
    {
      name: "send_to",
      label: "Send To",
      parser: (data) => {
        if (data.send_to === 'everyone') return "Everyone"

        const tags = Object.values(data?.send_to_tags?.map((item) => item.name) ?? [])
        return tags?.length ? tags.join(", ") : "Tag"
      },
      ellipsis: true,
    },
    {
      name: "link_to",
      label: "Link To",
      parser: (data) => {
        if (data.form_link_to === 'home') return "Home Screen"
        if (data.form_link_to === 'section') return data.form_section_title
        if (data.form_link_to === 'url') return data.form_url

        // change to actual name of content uuid
        return data.form_content_title ?? data?.form_content_uuid ?? data?.link_to_value
      },
      ellipsis: true,
    },
    {
      name: "when",
      label: "When",
      parser: (data) => {
        return moment(data?.send_at).format("DD-MMM-YYYY hh:mm A")
      },
      ellipsis: true,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          // deleteIcon={TrashIcon}
          item_id={data.id}
          // deleteClass={deleteClass}
          // onDeleteClick={showDeleteModal}
          onUpdateClick={updateButtonHandler}
          updateBtnLabel="Resend"
        />
      ),
    },
  ];

  return (
    <>
      <Page links={links} title="SENT PUSH NOTIFICATIONS">
        <div className="w-full flex">
          <div className="w-1/2 bg-white p-12 mt-5 ttnk-table-container">
            <p className="font-bold pb-4">Instructions:</p>
            <p>Below you will find the list of push notifications you have sent.  If you would like to resend or copy that push, click the "Resend" button to the right. It will open the Push window. To create a new notification, click the "Send" button on the left middle side of the browser to open the push notification window.  For complete instructions on how to send a Push, <a className="underline" href="https://actionera.freshdesk.com/support/solutions/articles/69000840413-push-notifications-9-0-app-" rel="noopener noreferrer" target="_blank">Click Here</a> for our support article.</p>
          </div>
          <div className="w-1/4 mx-5 bg-gradient-to-br from-aeblue-dark to-aeblue-mid p-12 mt-5 ttnk-table-container">
            <h2 className="font-bold font-header text-3xl uppercase text-white">App Downloads</h2>
            <DisplayCount
              isLoading={data?.loading}
              totalCount={data?.meta_data?.devices}
              totalActiveCount={data?.meta_data?.active_devices}
            />
            <Link
              className="flex text-white uppercase items-center text-md"
              to="/app-downloads"
            >
              View App Download Details &nbsp;&#9654;
            </Link>
          </div>
          <div className="w-1/4 bg-gradient-to-br from-aeblue-dark to-aeblue-mid p-12 mt-5 ttnk-table-container">
            <h2 className="font-bold font-header text-3xl uppercase text-white">Push Subscribers</h2>
            <DisplayCount
              isLoading={data?.loading}
              totalCount={data?.meta_data?.members}
              totalActiveCount={data?.meta_data?.active_members}
            />
            <Link
              className="flex text-white uppercase items-center text-md"
              to="/push-subscribers"
            >
              View Push Subscriber Details &nbsp;&#9654;
            </Link>
          </div>
        </div>
        <DataTable
          metadata={metadata}
          dataLoader={loadData}
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={["subject", "message", "send_to", "link_to"]}
        />
      </Page>

      {
        IsWithinMinimumVersion(9.0) && isPNAllowed ? (
          <PushNotificationModal
            shouldShow={shouldShow}
            setShouldShow={setShouldShow}
            pnData={pnData}
            setCurrentPnData={setCurrentPnData}
          />
        ) : null
      }
    </>
  );
};

export default PushNotificationList;
