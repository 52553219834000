import { Link } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { CREATOR_PORTAL, IN_PROGRESS, LAUNCHED } from "../lib/onboarding_config/config";
import { UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import responseProcessor from "../lib/responseProcessor";
import { useLoaderProvider } from "../providers/LoaderProvider";

const OnboardingWizardSwitcher = ({
  className
}) => {
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const location = useLocation();
  const switchText = location.pathname.includes("onboarding") && !location.pathname?.includes("onboarding_tracker") ? CREATOR_PORTAL : "Onboarding Wizard";

  const handleOnSwitch = () => {
    doSetShowLoadingModal(true)

    if (user.isSuper) {
      return window.location.href = switchText === CREATOR_PORTAL ? "/" : "/onboarding";
    }

    if (!user.isSuper) {
      const payload = {
        onboarding: {
          ...onboardingData,
          switched: switchText === CREATOR_PORTAL ? true : false,
        }
      }

      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess: () => {
              doSetShowLoadingModal(false)
              window.location.href = switchText === CREATOR_PORTAL ? "/" : "/onboarding";
            },
            onError: () => {
              doSetShowLoadingModal(false)
            },
          })
      );
    }
  }

  if (!onboardingData?.switcher) return (<></>)

  return (
    <div className={`flex w-full justify-end ${className}`}>
      <button
        className={`text-uppercase font-bold text-xl underline hover:no-underline `}
        onClick={handleOnSwitch}
      >
        {`Switch to ${switchText} View`}
      </button>
    </div>
  )
}

export default OnboardingWizardSwitcher