import { VideoPlayer } from "./VideoPlayer";
import Accordion from "./accordion";
import Counter from "./Counter";
import Button from "./Button";
import SlideOut from "./SlideOut";
import { useDrawerProvider } from "../providers/DrawerProvider";
import OnboardingContentList from "./onboarding/OnboardingContentList";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { SAVED, SUBMITTED } from "../lib/onboarding_config/config";
import { UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import responseProcessor from "../lib/responseProcessor";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useMemo } from "react";

export const OnboardingHomeProfile = () => {
  const { onboardingData: { progress } = {}, setOnboardingData } = useOnboardingProvider()
  const { showModal } = useModalProvider()
  const { user, setUser } = useUserProvider()
  const { doSetShowLoadingModal }  = useLoaderProvider()

  const isCompleted = (type) => {
    let prevType = type

    if (prevType === 'app_design') return progress?.design?.status === SAVED

    if (type === 'welcome_screens') prevType = 'home'

    const counter = progress?.home_profile?.[prevType]
    return (counter?.current > 0 && counter?.current >= counter?.required)
  }

  const handleSubmit = () => {
    doSetShowLoadingModal(true)

    const payload = {
      onboarding: {
        progress: {
          home_profile: {
            status: SUBMITTED
          }
        }
      }
    }

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: (response) => {
            if (response?.data) {
              setOnboardingData(response?.data?.onboarding)
              setUser({
                ...user,
                app_data: response.data,
              });
            }

            doSetShowLoadingModal(false)
          },
        })
    );
  }

  const OnBoardingContentHeading = ({ type, title, prevType = "welcome_screens" }) => {
    const { showDrawer, closeDrawer } = useDrawerProvider();
    const { onboardingData } = useOnboardingProvider()

    let currentType = type
    let currentPrevType = prevType

    if (type === 'welcome_screens') currentType = 'home'

    if (prevType === 'welcome_screens') currentPrevType = 'home'

    const counter = onboardingData?.progress?.home_profile?.[currentType]
    const isMaxReached = counter?.current > 0 && counter?.current >= counter?.required

    return (
      <div className="flex items-center gap-4">
        <Counter
          className="text-center font-bold"
          currentCount={counter?.current || 0}
          totalCount={counter?.required || 0}
          isDisabled={isCompleted(currentPrevType) === false}
        />
        {
          (!isMaxReached && isCompleted(currentPrevType)) ? (
            <Button
              type="button"
              className="flex justify-center items-center border-4 border-aeblue border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue-default font-bold hover:text-white hover:bg-aeblue"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                showDrawer(
                  <SlideOut
                    type={type}
                    showSubmitBtn={false}
                    handleSubmit={closeDrawer}
                  />,
                  title,
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Add Item
            </Button>
          ) : null
        }
      </div>
    )
  }

  const subaccordionList = [
    {
      id: 1,
      label: "Home",
      status: <OnBoardingContentHeading type="welcome_screens" title="Home" prevType="app_design" />,
      is_complete: isCompleted("welcome_screens"),
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="welcome_screens"
        title="Home"
        isDisabled={![SUBMITTED, SAVED]?.includes(progress?.design?.status)}
      />,
    },
    {
      id: 2,
      label: "Profile",
      status: <OnBoardingContentHeading type="profiles" title="Profile" prevType="app_design"/>,
      is_complete: isCompleted("profiles"),
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="profiles"
        title="Profile"
        isDisabled={![SUBMITTED, SAVED]?.includes(progress?.design?.status)}
      />,
    },
  ]

  const isDisabled = useMemo(() => (!(Number(progress?.home_profile?.overall?.percentage || 0) >= 100
    && [SUBMITTED, SAVED]?.includes(progress?.design?.status))
    || progress?.home_profile?.status === SUBMITTED), [progress])

  return (
    <div>
      <div className="w-4/6 mx-auto">
        <VideoPlayer url="https://youtu.be/a4P-UsP4edw" />
      </div>

      <div>
        {subaccordionList.map((item) => (
          <Accordion
            key={item.id}
            item={item}
            value={item.is_complete ?? false}
            status={item.status}
            isSubAccordion
            onChange={() => {}}
            isDisabled={item?.is_disabled}
          />
        ))}
      </div>

      <div className="flex flex-row items-center justify-end w-full pt-8 font-bold text-darkGray text-2xl">
        <button
          className={`ttnk-button font-bold text-darkGray text-2xl w-230px`}
          type="submit"
          disabled={isDisabled}
          onClick={handleSubmit}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export const HomeProfileStatus = () => {
  const { onboardingData } = useOnboardingProvider()

  return (
    <div className="flex justify-center items-center">
      <div className="ttnk-AvenirNextBold" style={{
        color: '#2F91CF'
      }}>
        {onboardingData?.progress?.home_profile?.status}
      </div>
    </div>
  )
}