import { useMemo, useState } from "react";
import Header from "./Header";
import PushNotificationModal from "./modal_contents/PushNotificationModal/PushNotificationModal";
import { useUserProvider } from "../providers/UserProvider";
import { AddAlertOutlined } from "@material-ui/icons";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const AppLayout = ({ children }) => {
  const [shouldShow, setShouldShow] = useState(false);
  const { user } = useUserProvider();
  const location = useLocation();

  const pathname = useMemo(() => location.pathname, [location])

  const isPNAllowed = useMemo(() => user?.app_data?.from_apps?.length > 0 || (user?.app_data?.fcm_key?.length > 0 && user?.app_data?.fcm_project_id?.length > 0), [user])

  return (
    <div className="block h-screen">
      {
        pathname?.includes("onboarding") && !pathname?.includes("onboarding_tracker") ? (
          <div className="h-full">{children}</div>
        ) : (
          <>
          <Header />
          <div style={{ paddingTop: "6.6rem" }} className="h-full">
            {children}
          </div>
          </>
        )
      }

      { IsWithinMinimumVersion(9.0) && isPNAllowed && !pathname?.includes("onboarding") ? (
        <>
          <button
            className={`fixed z-90 origin-left left-5 top-1/2 left-0 push-button-color w-32 min-w-[134px] h-9 rounded-md drop-shadow-2xl flex justify-center items-center text-white text-base hover:push-button-color hover:drop-shadow-2xl gap-3
              ${shouldShow ? "invisible" : "visible"}`}
            style={{ minWidth: 134, transform: "rotate(90deg)" }}
            onClick={() => setShouldShow(!shouldShow)}
          >
            <AddAlertOutlined htmlColor="white" />
            Send
          </button>
          <PushNotificationModal
            shouldShow={shouldShow}
            setShouldShow={setShouldShow}
          />
        </>
      ) : null}
    </div>
  );
};

export default AppLayout;
