import { callEndpoint, callEndpointWithPayload } from "../baseApi"

export const GetPushNotificationList = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications`, 'GET', idToken)
}

export const AddPushNotification = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications`, 'POST', idToken, payload)
}

export const GetTagsPushNotification = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications/tags`, 'GET', idToken)
}

export const UpdatePushNotification = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications`, 'PATCH', idToken, payload)
}

export const GetPushNotification = async (uuid, idToken, pnUuid) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications/${pnUuid}`, 'GET', idToken)
}

export const GetLinkTo = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications/link_to`, 'GET', idToken)
}

export const GetSendTo = async (
  uuid,
  idToken,
  values
) => {
  const { form_link_to, form_section_value, form_content_type, form_content_uuid } = values
  const queryParams = `?form_link_to=${form_link_to}&form_section_value=${form_section_value}&form_content_type=${form_content_type}&form_content_uuid=${form_content_uuid}`
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications/send_to${queryParams}`, 'GET', idToken)
}

export const GetSendToTags = async (uuid, idToken, sendToTags) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${uuid}/push_notifications/send_to_tags?${sendToTags}`, 'GET', idToken)
}