import { useCallback, useEffect, useMemo, useState } from "react"
import OnboardingContentListItem from "./OnboardingContentListItem"
import { GetApp, GetAppResource } from "../../services/api/app"
import { useUserProvider } from "../../providers/UserProvider"
import { SUBMITTED, getContentType } from "../../lib/onboarding_config/config"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import { useLoaderProvider } from "../../providers/LoaderProvider"

const OnboardingContentList = ({
  className,
  type,
  title,
  isDisabled = false
}) => {
  const [data, setData] = useState({})

  const { user, setUser } = useUserProvider()
  const {
    refetchContent,
    setRefetchContent,
    setOnboardingData,
    refetchCounter,
    setRefetchCounter,
  } = useOnboardingProvider()
  const { doSetShowLoadingModal } = useLoaderProvider()

  const loadData = useCallback(async (callbackFn) => {
    setData({ ...data, loading: true });
    const endpoint = getContentType(type);

    GetAppResource(user, endpoint)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData?.data,
          count: jsonData?.data?.length,
          meta_data: jsonData?.meta_data,
        });
      }).then(() => {
        if (typeof callbackFn === 'function') callbackFn()
      })
  }, [type])

  const loadAppData = async (callbackFn) => {
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        let app_data = {};

        app_data = res.data;

        setUser({
          ...user,
          app_data: {
            ...user.app_data,
            setup: {
              ...user.app_data.setup,
              header: app_data.setup.header
            }
          }
        })

        if (typeof setOnboardingData === 'function') setOnboardingData(res?.data?.onboarding)
      }).then(() => {
        doSetShowLoadingModal(false);
        if (typeof callbackFn === 'function') callbackFn()
      })
  }

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      isMounted = false
      loadData()
    }

    return () => {
      isMounted = true
    }
  }, [type])

  useEffect(() => {
    if (refetchContent) {
      loadData(() => setRefetchContent(false))
    }
  }, [refetchContent])

  useEffect(() => {
    if (refetchCounter) {
      loadAppData(() => setRefetchCounter(false))
    }
  }, [refetchCounter])

  const addItemMessage = useMemo(() => {
    // @TODO: add check for onboarding type here because the message will be different
    switch (type) {
      case 'welcome_screens':
      case 'profiles':
        return 'If you do not see an "Add Item" button to the right, this means you need to complete the first section - Essentials.'
      default:
        return 'If you do not see an “Add Item" button to the right, this means you need to complete the two previous sections - Essentials and Home/Profile.'
    }
  }, [type])

  if (isDisabled) {
    return (
      <div className={`flex flex-col gap-4 mt-4 w-full ${className ?? ''}`}>
        {addItemMessage}
      </div>
    )
  }

  return (
    <div className={`flex flex-col gap-4 mt-4 w-full ${className ?? ''}`}>
      {
        data?.data?.map((item, index) => (
          <OnboardingContentListItem
            type={type}
            title={title}
            item={item}
            key={`content-item-${String(index)}`}
          />
        ))
      }
    </div>
  )
}

export default OnboardingContentList