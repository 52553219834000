import { useCallback, useMemo, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";

import { useUserProvider } from "../providers/UserProvider";
import PushNotificationModal from "../components/modal_contents/PushNotificationModal/PushNotificationModal";
import { GetMobileAppDownloads } from "../services/api/mobile_configs";
import { Select } from "antd";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";

const AppDownloadsList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);
  const [pnData, setCurrentPnData] = useState(null);
  const [fromWhichApp, setWhichApp] = useState("All");

  const [data, setData] = useState({
    loading: false,
    data: [],
    allData: [],
    count: 0,
    meta_data: {}
  });

  const { user } = useUserProvider();

  const loadData = useCallback(async() => {
    setData({ ...data, loading: true });
    GetMobileAppDownloads(user.currentApp(), user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data,
          allData: jsonData.data
        });
      });
  }, [data, user])

  const links = [
    { name: "Home", url: "/" },
    { name: "Push Notifications", url: "/push-notifications" },
    { name: "App Downloads", url: "/app-downloads" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "user",
      label: "User Email",
      parser: (data) => data?.user?.email,
      ellipsis: true,
    },
    {
      name: "token",
      label: "Token",
      parser: (data) => data?.token,
      ellipsis: true,
    },
    {
      name: "device",
      label: "Device Description",
      parser: (data) => data?.device,
      ellipsis: true,
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => data?.active === true ? "Yes" : "No",
      ellipsis: true,
    },
    {
      name: "associated",
      label: "Associated?",
      parser: (data) => data?.associated === true ? "Yes" : "No",
      ellipsis: true,
    },
  ];

  const isSubAppOwnerOrReseller = useMemo(() => user?.app_data?.from_apps?.length > 0, [user])
  const isBizPlusOwner = user?.app_data?.fcm_key?.length > 0 && user?.app_data?.fcm_project_id?.length > 0
  const isAppBizPlus = useMemo(() => user?.app_data?.setup?.biz_plus && !isSubAppOwnerOrReseller, [isSubAppOwnerOrReseller, user?.app_data?.setup?.biz_plus])
  const bizAppData = {
    id: user?.app?.id,
    name: user?.app?.name
  }

  const whichApp = useMemo(() => isBizPlusOwner ? [...user?.app_data?.from_apps, bizAppData] : user?.app_data?.from_apps ?? [], [user, isBizPlusOwner])

  const sortedWhichAppByName = useMemo(() => whichApp?.sort((a, b) => a.name.localeCompare(b.name)), [whichApp])

  const handleFilterByWhichApp = (value) => {
    setWhichApp(value)

    let newData = data.data

    if (value === "All") newData = data.allData
    else if (value === bizAppData?.id) newData = data.allData.filter((item) => item?.parent_app === null)
    else newData = data.allData.filter((item) => item?.parent_app?.id === value)

    setData({
      ...data,
      data: newData,
      count: newData.length
    });
  }

  return (
    <>
      <Page links={links} title="APP DOWNLOADS">
        {
          isSubAppOwnerOrReseller && !isAppBizPlus ? (
            <div className="flex flex-row justify-center items-center px-4">
              <label
                className={`text-lg font-bold pr-4`}
                htmlFor={"parent_uuid"}
              >
                From Which App:
              </label>
              <div className="w-2/3 bg-white rounded">
                <Select
                  name="parent_uuid"
                  className="w-full"
                  value={fromWhichApp}
                  onChange={(value) => handleFilterByWhichApp(value)}
                  placeholder="Select From Which App..."
                >
                  <option value={"All"}> All </option>
                  {sortedWhichAppByName?.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          ) : null
        }
      
        <DataTable
          metadata={metadata}
          dataLoader={loadData}
          data={data}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          searchKeys={["token", "device_description", "active", "associated", "user"]}
        />
      </Page>

      {
        IsWithinMinimumVersion(9.0) && isSubAppOwnerOrReseller ? (
          <PushNotificationModal
          shouldShow={shouldShow}
          setShouldShow={setShouldShow}
          pnData={pnData}
          setCurrentPnData={setCurrentPnData}
        />
        ) : null
      }
    </>
  );
};

export default AppDownloadsList;
