import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import * as Yup from "yup";

const DEFAULT_VALUES = {
  isEdit: false,
  app_built: "get_started",
  firebase_etc_info: "",
  app_built_notes: "",
  push_key_file: "",
  json_file: "",
  aab_file: "",
  create_push_key: false,
  setup_firebase: false,
  add_ios_admins: false,
  upload_ios_app: false,
  media_metadata: {}
}

const onboardingAppBuilt = ({ handleSubmit, initialValues }) => {
  const fields = [
    {
      component: "app_built",
      name: "app_built",
      label: "Status",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "firebase_etc_info",
      field: "textarea",
      name: "firebase_etc_info",
      label: "Firebase Key/Email, Push Key & App/Google Email/Password",
      placeholder: "",
      isFieldRow: true,
      rows: 10,
      required: true,
    },
    {
      component: "app_built_notes",
      field: "textarea",
      name: "app_built_notes",
      label: "Notes",
      placeholder: "",
      isFieldRow: true,
      rows: 10,
      required: true,
    },
    {
      component: "push_key_file",
      name: "push_key_file",
      label: "Push Key",
      placeholder: "",
      isFieldRow: true,
      required: true,
    },
    {
      component: "json_file",
      name: "json_file",
      label: ".json file",
      placeholder: "",
      isFieldRow: true,
      required: true,
    },
    {
      component: "aab_file",
      name: "aab_file",
      label: ".AAB file",
      placeholder: "",
      isFieldRow: true,
      required: true,
    },
    {
      component: "create_push_key",
      field: "tickbox",
      name: "create_push_key",
      label: "Create Push Key",
      required: false,
    },
    {
      component: "setup_firebase",
      field: "tickbox",
      name: "setup_firebase",
      label: "Setup Firebase",
      required: false,
    },
    {
      component: "add_ios_admins",
      field: "tickbox",
      name: "add_ios_admins",
      label: "Add IOS Admins",
      required: false,
    },
    {
      component: "upload_ios_app",
      field: "tickbox",
      name: "upload_ios_app",
      label: "Upload IOS Admins",
      required: false,
    },
  ]

  const schema = {
    app_built: Yup.string(),
    push_key_file: validations.long_text_required,
    json_file: validations.long_text_required,
    aab_file: validations.long_text_required,
    firebase_etc_info: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    app_built_notes: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
  };

  const handleFormatPayload = (values) => {
    const { isEdit, ...rest } = values

    const newPayload = {
      onboarding: {
        ...rest,
      }
    }

    return newPayload
  }

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.app?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        app_built: initialValues?.onboarding?.app_built,
        firebase_etc_info: initialValues?.onboarding?.firebase_etc_info,
        app_built_notes: initialValues?.onboarding?.app_built_notes,
        push_key_file: initialValues?.onboarding?.push_key_file,
        json_file: initialValues?.onboarding?.json_file,
        aab_file: initialValues?.onboarding?.aab_file,
        create_push_key: initialValues?.onboarding?.create_push_key,
        setup_firebase: initialValues?.onboarding?.setup_firebase,
        add_ios_admins: initialValues?.onboarding?.add_ios_admins,
        upload_ios_app: initialValues?.onboarding?.upload_ios_app,
        media_metadata: initialValues?.onboarding?.media_metadata,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - App Built",
      resourceEndpoint: "apps",
      columnsMetadata: [
        { customComponent: (
          <OnboardingContent
            title="App Built"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={handleFormatPayload}
          />
        ) },
      ],
    }
  }
}

export default onboardingAppBuilt;