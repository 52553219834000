const summitConfig = {
  label: "ActionSummit",
  resourceEndpoint: "summits",
  isNotContent: true,
  listMetadata: [
    "sort_order",
    "active",
    "title",
    "summit_tag",
    "vip_tag",
    "description",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "active", label: "Active" },
    {
      name: "title",
      label: "Title",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "summit_tag",
      label: "Summit Tag",
    },
    {
      name: "vip_tag",
      label: "VIP Tag",
    },
    {
      name: "description",
      label: "Description",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "notes",
      label: "Summit Dates (Text)",
      validations: [
        { type: "max", params: [30, "Maximum 30 Characters"] },
        { type: "required", params: ["Required"] },
      ],
    },
    {
      name: "host_name",
      label: "Host Name",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "host_image_url",
      label: "Host Image URL",
    },
    { name: "image_url", label: "Summit Image URL" },
    { name: "content_screen_video_url", label: "After Enrolled Video URL" },
    { name: "video_url", label: "Summit Info Video URL" },
    { name: "message", label: "After Enrolled Message" },
    {
      name: "start_date",
      label: "Start Date",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "end_date",
      label: "End Date",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "access_url",
      label: "Registration URL",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "sort_order", label: "Sort Order" },
    { name: "speakers", label: "Speakers" },
  ],
  formLayout: {
    columns: [
      [
        "title",
        "summit_tag",
        "vip_tag",
        "description",
        "notes",
        "host_name",
        "host_image_url",
      ],
      [
        "image_url",
        "video_url",
        "message",
        "content_screen_video_url",
        "start_date",
        "end_date",
        "access_url",
        "sort_order",
        "active",
      ],
    ],
  },
  subTables: {
    speakers: {
      label: {
        plural: "Virtual Summit Speaker Content",
        singular: "Virtual Summit Speaker Content",
      },
      listMetadata: [
        {
          name: "profile.id",
          label: "",
          hide: true,
          parser: (data) => data.id,
        },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "profile.name",
          label: "Speaker",
          parser: (data) => data.profile?.name,
        },
      ],
      detailsMetadata: [
        {
          name: "profile.id",
          label: "Speakers",
          validations: [{ type: "required", params: ["Required"] }],
        },
        { name: 'release_date', label: 'Release Date'},
        {
          name: "sort_order",
          label: "Sort Order",
        },
        { name: "video_url", label: "Video URL", 
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "video_description", label: "Video Description" },
        { name: "journal_description", label: "Journal Description"},
        { name: "question_1", label: "Journal 1" },
        { name: "question_2", label: "Journal 2" },
        { name: "question_3", label: "Journal 3" },
        { name: "question_4", label: "Journal 4" },
        { name: "question_5", label: "Journal 5" },
        { name: "free_gift.url", label: "Free Gift URL",
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "free_gift.image_url", label: "Free Gift Image URL",
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "free_gift.description", label: "Free Gift Description" },
        { name: "vip_gift.url", label: "VIP Gift URL",
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "vip_gift.registration_url", label: "VIP Registration URL",
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "vip_gift.image_url", label: "VIP Gift Image URL",
            validations: [ 
                { type: "max", params: [255, "Maximum 255 Characters"] }
            ]
        },
        { name: "vip_gift.description", label: "VIP Gift Description" },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
    },
  },
};

export default summitConfig;
