import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  RichText,
} from "../components/EntryForm";
import Page from "../components/Page";
import MediaUploader from "../components/MediaUploader";
import { GetContent, UpdateContent } from "../services/api/content";
import { CreateGetEntity, CreateUpdateEntity } from "../services/api/entity";
import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import DataSelector from "../components/DataSelector";
import DateSelector from "../components/DateSelector";
import SubTable from "../components/SubTable";
import {
  getFieldComponent,
  useDuplicateValidation,
  validations,
} from "../lib/form";
import contentConfig from "../lib/content_config/config";
import responseProcessor from "../lib/responseProcessor";
import BulkPhotos from "../components/BulkPhotos";
import ErrorModal from "../components/modal_contents/ErrorModal";
import ContentDisclaimer from "../components/ContentDisclaimer";

const ContentDetail = ({ newContent, contentType, isContent }) => {
  const url = window.location.href;

  const Getter = isContent
    ? GetContent
    : CreateGetEntity(contentConfig(contentType).resourceEndpoint);
  const Updater = isContent
    ? UpdateContent
    : CreateUpdateEntity(contentConfig(contentType).resourceEndpoint);
  const Adder = isContent
    ? (uuid, user, payload) => {
        return AddAppResource(
          uuid,
          `content/${contentConfig(contentType).resourceEndpoint}`,
          user.idToken,
          { data: payload }
        );
      }
    : (uuid, user, payload) => {
        return AddAppResource(
          uuid,
          `${contentConfig(contentType).resourceEndpoint}`,
          user.idToken,
          { data: payload }
        );
      };
  const [initialValues, setInitialValues] = useState({
    title: "",
    type: "",
    summit_tag: {
      id: "",
      code: "",
      name: "",
    },
    vip_tag: {
      id: "",
      code: "",
      name: "",
    },
    host_name: "",
    host_image_url: "",
    content_screen_video_url: "",
    notes: "",
    message: "",
    start_date: "",
    end_date: "",
    access_url: "",
    email: "",
    description: "",
    web_description: "",
    author: "",
    video_url: "",
    image_url: "",
    audio_url: "",
    release_date: moment().utcOffset(0, true),
    action_bar_text: "",
    action_bar_url: "",
    sort_order: 1,
    featured: false,
    active: "",
    send_to: "",
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    price: "",
    review_video_url: "",
    review_image_url: "",
    review_listing_url: "",
    download_url: "",
    message_prompt: "",
    start_number: "",
    end_number: "",
    tags: [],
    topics: [],
    sections: [],
    photos: [],
    speakers: [],
    media_metadata: {},
    cost: "",
    journal_list: false,
    items: [],
    custom_frequency: [],
    rss_url: "",
    web_url: "",
    document_url: "",
    actionclip: false,
    actionclip_description: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  let { uuid } = useParams();
  if (newContent) {
    uuid = user.currentApp();
  }
  const history = useHistory();
  const platform_version = user?.app_data?.about?.platform_version;

  useEffect(() => {
    if (
      (contentType === "action-lists" || contentType === "documents") &&
      parseFloat(platform_version) === 8.0
    ) {
      history.push("/");
    }

    if (!newContent) {
      setIsLoading(true);
      Getter(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            title: res.data.title,
            type: res.data.type,
            summit_tag: res.data.summit_tag,
            vip_tag: res.data.vip_tag,
            host_name: res.data.host_name,
            host_image_url: res.data.host_image_url,
            content_screen_video_url: res.data.content_screen_video_url,
            notes: res.data.notes,
            message: res.data.message,
            tag_message: res.data.tag_message,
            start_date: res.data.start_date,
            end_date: res.data.end_date,
            access_url: res.data.access_url,
            email: res.data.email,
            description: res.data.description,
            web_description: res.data.web_description,
            author: res.data.author,
            video_url: res.data.video_url,
            image_url: res.data.image_url,
            audio_url: res.data.audio_url,
            release_date: res.data.release_date,
            action_bar_text: res.data.action_bar_text,
            action_bar_url: res.data.action_bar_url,
            sort_order: res.data.sort_order,
            featured: res.data.featured,
            actionclip: res.data.actionclip,
            actionclip_description: res.data.actionclip_description,
            active:
              typeof res.data.active === "boolean"
                ? res.data.active
                : res.data.active === "true"
                ? true
                : false,
            send_to: res.data.send_to,
            question_1: res.data.question_1,
            question_2: res.data.question_2,
            question_3: res.data.question_3,
            question_4: res.data.question_4,
            question_5: res.data.question_5,
            price: res.data.price,
            review_video_url: res.data.review_video_url,
            download_url: res.data.download_url,
            message_prompt: res.data.message_prompt,
            review_image_url: res.data.review_image_url,
            listing_image_url: res.data.listing_image_url,
            tags: res.data.tags,
            topics: res.data.topics,
            sections: res.data.sections,
            speakers: res.data.speakers,
            photos: res.data.photos,
            start_number: res.data.start_number,
            end_number: res.data.end_number,
            cost: res.data.cost,
            res: res.data.journal_list,
            items: res.data.items,
            frequency: res.data.frequency,
            custom_frequency: res.data.custom_frequency,
            journal_list: res.data.journal_list,
            short_description: res.data.short_description,
            rss_url: res.data.rss_url,
            web_url: res.data.web_url,
            document_url: res.data.document_url,
            tagged_visible: res.data.tagged_visible,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType]);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newContent ? "New" : "Edit";
  const currentUrl = newContent
    ? `/${contentType}/new`
    : `/${contentType}/${uuid}`;
  const subTables = contentConfig(contentType).subTables;

  const pluralize = (label) => {
    if (["Q&A", "TrackaConnect"].includes(label)) {
      return label;
    }
    if (["Tracka'"].includes(label)) {
      return label + " List";
    }
    if (label.slice(-1) === "y") {
      return label.slice(0, -1) + "ies";
    }
    if (label.slice(-1) === "s") {
      return label;
    }
    return label + "s";
  };

  let links = [
    { name: "Home", url: "/" },
    {
      name: pluralize(contentConfig(contentType).label),
      url: `/${contentType}`,
    },
    {
      name: `${currentAction} ${
        contentConfig(contentType).overrideTitle ||
        contentConfig(contentType).label
      }`,
      url: currentUrl,
    },
  ];

  const inMetadata = (name) => {
    const metadataNames = contentConfig(contentType).detailsMetadata.map(
      (datum) => datum.name
    );
    return metadataNames.indexOf(name) > -1;
  };

  const getFieldMetadata = (name) => {
    const metaData = contentConfig(contentType).detailsMetadata.filter(
      (datum) => datum.name === name
    );
    return metaData.length ? metaData[0] : null;
  };

  const fullSchema = {
    title: validations.str_req_60,
    type: Yup.string().max(255, "Maximum 255 Characters"),
    email: Yup.string().max(255, "Maximum 255 Characters"),
    message_prompt: Yup.string().max(65535, "Maximum 65,535 Characters"),
    description: Yup.string().max(65535, "Maximum 65,535 Characters"),
    web_description: Yup.string().max(65535, "Maximum 65,535 Characters"),
    author: Yup.string().max(35, "Maximum 35 Characters"),
    tags: Yup.array().test("tags", "", (value) => {
      if (value?.length <= 0 && formik.values.tagged_visible) {
        formik.setFieldValue("tagged_visible", false);
        return true;
      }

      return true;
    }),
    video_url: validations.str_255.test(
      "mp4Test",
      "File type is not allowed, please upload an mp4 file",
      function mp4Test(value) {
        if (!value || !formik.values.actionclip) return true;

        if (value.includes("media:")) {
          const id = value.split(":")?.[1];

          const mediaMetaData = formik.values.media_metadata || metadata;
          const fileName = mediaMetaData?.[id]?.filename;
          const valueArr = fileName?.split?.(".");

          const extension = valueArr?.[valueArr?.length - 1].toLowerCase();
          if (extension === "mp4") {
            return true;
          }
        } else {
          const valueArr = value?.split?.(".");
          if (
            valueArr?.[valueArr?.length - 1].toLowerCase() === "mp4" ||
            !url.includes("videos")
          ) {
            return true;
          }
        }

        return false;
      }
    ),
    image_url: validations.str_255,
    audio_url: validations.str_255,
    document_url: Yup.string().required("Required"),
    content_screen_video_url: Yup.string().max(255, "Maximum 255 Characters"),
    release_date: Yup.date(),
    action_bar_text: validations.str_notreq_50,
    action_bar_url: Yup.string().max(255, "Maximum 255 Characters"),
    sort_order: validations.sort_order,
    featured: Yup.boolean().nullable(),
    active: Yup.boolean().nullable(),
    send_to: Yup.string()
      .email("Invalid email")
      .max(50, "Maximum 50 Characters")
      .required("Required"),
    question_1: Yup.string().max(65535, "Maximum 65,535 Characters"),
    question_2: Yup.string().max(65535, "Maximum 65,535 Characters"),
    question_3: Yup.string().max(65535, "Maximum 65,535 Characters"),
    question_4: Yup.string().max(65535, "Maximum 65,535 Characters"),
    question_5: Yup.string().max(65535, "Maximum 65,535 Characters"),
    price: validations.str_notreq_10,
    review_video_url: Yup.string().max(255, "Maximum 255 Characters"),
    download_url: Yup.string().max(255, "Maximum 255 Characters"),
    review_image_url: Yup.string().max(255, "Maximum 255 Characters"),
    listing_image_url: Yup.string().max(255, "Maximum 255 Characters"),
    start_number: Yup.number()
      .min(100000, "Start Number must not be less than 100 000")
      .test(
        "lessThanEqual",
        "Start Number must be less than End Number",
        function lessThanEqual(value) {
          const { end_number } = this.parent;
          if (value <= end_number) {
            return true;
          }

          return false;
        }
      ),
    end_number: Yup.number()
      .max(699999, "End Number must not be greater than 699 999")
      .test(
        "moreThanEqual",
        "End Number must be greater than Start Number",
        function morethanEqual(value) {
          const { start_number } = this.parent;
          if (value >= start_number) {
            return true;
          }

          return false;
        }
      ),
    photos: Yup.array(),
    speakers: Yup.array(),
    vip_tag: Yup.object().shape({ id: Yup.string() }),
    summit_tag: Yup.object()
      .shape({ id: Yup.string().required("Required") })
      .nullable()
      .required("Required"),
    host_name: Yup.string().max(30, "Maximum 30 Characters"),
    notes: Yup.string().max(255, "Maximum 255 Characters"),
    message: Yup.string(),
    tag_message: Yup.string(),
    start_date: Yup.date(),
    end_date: Yup.date(),
    access_url: Yup.string().max(255, "Maximum 255 Characters"),
    host_image_url: validations.str_255,
    cost: validations.str_notreq_10,
    short_description: validations.short_text,
    frequency: Yup.string().required("Required"),
    rss_url: validations.url_req,
    web_url: validations.url,
    actionclip_description: validations.str_notreq_75,
  };

  // Add additional validation
  contentConfig(contentType).detailsMetadata.forEach((field) => {
    if (field.validations) {
      field.validations.forEach((validation) => {
        const { type, params } = validation;
        if (
          contentType === "summits" &&
          field.name === "end_date" &&
          parseFloat(platform_version) === 8.0
        ) {
          return false;
        }
        if (!fullSchema[field.name][type]) {
          return;
        }
        fullSchema[field.name] = fullSchema[field.name][type](...params);
      });
    }
  });

  const schema = Object.fromEntries(
    Object.entries(fullSchema).filter(([key, _value]) => inMetadata(key))
  );
  const valuesToCheck =
    contentType === "action-lists" ? ["text"] : ["sub_description"];
  const subTablesToCheck =
    contentType === "action-lists" ? "items" : "sections";
  let validate = useDuplicateValidation(valuesToCheck, subTablesToCheck);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    validate: (values) => validate(values, formik),
    onSubmit: async (values) => {
      const payload = Object.fromEntries(
        Object.entries(values).filter(([key, _value]) => inMetadata(key))
      );
      if (newContent) {
        Adder(uuid, user, payload).then((response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess: () => history.push(`/${contentType}`),
          })
        );
      } else {
        Updater(uuid, user.idToken, { data: payload }).then((response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess: () => history.push(`/${contentType}`),
          })
        );
      }
    },
  });

  const getLabel = (name) => {
    if (inMetadata(name)) {
      return contentConfig(contentType).detailsMetadata.filter((datum) => {
        if (datum.name === name) {
          return datum;
        }
        return false;
      })[0].label;
    } else {
      return "No Label";
    }
  };

  const StaticFormLayout = (
    <>
      <Column>
        {inMetadata("title") && (
          <Input
            name="title"
            type="text"
            label={getLabel("title")}
            formik={formik}
          />
        )}
        {inMetadata("video_url") && (
          <MediaUploader
            name="video_url"
            type="text"
            label={getLabel("video_url")}
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
        )}
        {inMetadata("image_url") && (
          <MediaUploader
            name="image_url"
            type="text"
            label={getLabel("image_url")}
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
        )}
        {inMetadata("audio_url") && (
          <MediaUploader
            name="audio_url"
            type="text"
            label={getLabel("audio_url")}
            formik={formik}
            metadata={metadata}
            mediaType="audio"
          />
        )}
        {inMetadata("description") && (
          <RichText
            id="contentDescription"
            name="description"
            quillName={"web_description"}
            type="text"
            label={getLabel("description")}
            rows="21"
            formik={formik}
            contentType={contentType}
          />
        )}
        {inMetadata("review_video_url") && (
          <MediaUploader
            name="review_video_url"
            type="text"
            label={getLabel("review_video_url")}
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
        )}
        {inMetadata("review_image_url") && (
          <MediaUploader
            name="review_image_url"
            type="text"
            label={getLabel("review_image_url")}
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
        )}
        {inMetadata("listing_image_url") && (
          <MediaUploader
            name="listing_image_url"
            type="text"
            label={getLabel("listing_image_url")}
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
        )}
        {inMetadata("send_to") && (
          <Input
            name="send_to"
            type="email"
            label={getLabel("send_to")}
            formik={formik}
          />
        )}
        {inMetadata("question_1") && (
          <Input
            name="question_1"
            type="text"
            label={getLabel("question_1")}
            formik={formik}
          />
        )}
        {inMetadata("question_2") && (
          <Input
            name="question_2"
            type="text"
            label={getLabel("question_2")}
            formik={formik}
          />
        )}
        {inMetadata("question_3") && (
          <Input
            name="question_3"
            type="text"
            label={getLabel("question_3")}
            formik={formik}
          />
        )}
        {inMetadata("question_4") && (
          <Input
            name="question_4"
            type="text"
            label={getLabel("question_4")}
            formik={formik}
          />
        )}
        {inMetadata("question_5") && (
          <Input
            name="question_5"
            type="text"
            label={getLabel("question_5")}
            formik={formik}
          />
        )}
        {inMetadata("topics") && (
          <DataSelector
            name="topics"
            formik={formik}
            label="Topics/Listings"
            resourceEndpoint="topics"
          />
        )}
      </Column>
      <Column>
        {inMetadata("author") && (
          <Input
            name="author"
            type="text"
            label={getLabel("author")}
            formik={formik}
          />
        )}
        {inMetadata("price") && (
          <Input name="price" type="text" label="Price" formik={formik} />
        )}
        {inMetadata("release_date") && (
          <DateSelector
            name="release_date"
            type="text"
            label={getLabel("release_date")}
            formik={formik}
          />
        )}
        {inMetadata("action_bar_text") && (
          <Input
            name="action_bar_text"
            type="text"
            label={getLabel("action_bar_text")}
            formik={formik}
          />
        )}
        {inMetadata("action_bar_url") && (
          <Input
            name="action_bar_url"
            type="text"
            label={getLabel("action_bar_url")}
            formik={formik}
          />
        )}
        <div className="flex">
          {inMetadata("sort_order") && (
            <span className="flex w-1/3">
              <Input
                name="sort_order"
                type="number"
                label={getLabel("sort_order")}
                formik={formik}
              />
            </span>
          )}
          <span className="flex w-1/3">
            {inMetadata("active") && (
              <CheckBox
                name="active"
                label={getLabel("active")}
                formik={formik}
              />
            )}
          </span>
          <span className="flex w-1/3">
            {inMetadata("featured") && (
              <CheckBox
                name="featured"
                label={getLabel("featured")}
                formik={formik}
              />
            )}
          </span>
          <span className="flex w-1/3">
            {inMetadata("tagged_visible") && (
              <CheckBox
                name="tagged_visible"
                label={getLabel("tagged_visible")}
                formik={formik}
              />
            )}
          </span>
        </div>
        {inMetadata("tags") && (
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(options) => {
              return options.filter((a) => !a.is_expired);
            }}
          />
        )}
      </Column>
    </>
  );

  const RenderDynamicFormLayout = () => {
    const columns = [];
    contentConfig(contentType).formLayout.columns.forEach((col, colIndex) => {
      let contentURL = "";
      let mediaURL = "";
      const setup = user?.app_data?.setup;
      const web = user?.app_data?.setup?.web ?? false;

      if (colIndex === 1 && web) {
        const WEB_FQDN = process.env.REACT_APP_WEB_FQDN;
        const username = user?.app_data?.username;
        const contentType = window.location.pathname.split("/")[1];

        contentURL = `${WEB_FQDN}/${username}/${contentType}/${uuid}`;

        const isS3Link = initialValues?.document_url?.includes("amazonaws.com");
        const apiMetaDataLink =
          metadata?.[String(initialValues?.document_url?.replace("media:", ""))]
            ?.url_with_extension;

        mediaURL = isS3Link ? initialValues?.document_url : apiMetaDataLink;
      }

      columns.push(
        <Column key={colIndex}>
          {col.map((fieldName, index) => {
            const fieldConfig = getFieldMetadata(fieldName);
            const thisField = formik.getFieldProps(fieldName);
            let hasFieldConditions = false;
            let isFieldVisible = true;
            hasFieldConditions = !!fieldConfig.conditions;

            if (
              contentType === "summits" &&
              fieldName === "end_date" &&
              parseFloat(platform_version) === 8.0
            ) {
              return false;
            }

            if (hasFieldConditions) {
              for (
                let i = 0, len = fieldConfig.conditions.length;
                i < len;
                i++
              ) {
                const cond = fieldConfig.conditions[i];
                const relativeField = formik.getFieldProps(cond.relativeField);
                isFieldVisible = cond.comparisonFunc(
                  relativeField,
                  thisField,
                  setup
                );
                if (isFieldVisible) {
                  break;
                }
              }
            }

            const label = getLabel(fieldName);
            const currentLabel =
              contentType === "documents" && label === "Media URL"
                ? "Document Upload"
                : label;

            const disabled =
              fieldName === "tagged_visible" &&
              formik?.values?.tags?.length <= 0;

            return !hasFieldConditions ||
              (hasFieldConditions && isFieldVisible) ? (
              <Fragment key={index}>
                {getFieldComponent(
                  fieldName,
                  {
                    label: currentLabel,
                    formik,
                    metadata,
                    contentType,
                    format: fieldConfig.format,
                    width: fieldConfig.width,
                    placeholder: fieldConfig.placeholder,
                    user: user,
                    disabled,
                  },
                  `web_${fieldName}`
                )}
              </Fragment>
            ) : null;
          })}

          {!!contentURL && (
            <div>
              <div>
                <span>
                  {`Web URL: `}
                  <a
                    href={contentURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contentURL}
                  </a>
                </span>
              </div>
              {/* TODO add copy to cliboard functionality */}
              {/* <div className="text-green-500 mt-2">Copied!</div> */}
            </div>
          )}

          {!!mediaURL && (
            <div>
              <div>
                <span>
                  {`Media URL: `}
                  <a href={mediaURL} target="_blank" rel="noopener noreferrer">
                    {mediaURL}
                  </a>
                </span>
              </div>
            </div>
          )}
        </Column>
      );
    });

    return columns.map((col) => {
      return col;
    });
  };

  return (
    <Page
      links={links}
      centerComponent={<ContentDisclaimer contentType={contentType} />}
      title={`${currentAction} ${
        contentConfig(contentType).overrideTitle ||
        contentConfig(contentType).label
      }`}
    >
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL={`/${contentType}`}
      >
        {contentConfig(contentType).formLayout
          ? RenderDynamicFormLayout()
          : StaticFormLayout}

        {contentType === "galleries" && (
          <div className="w-full mx-5 mt-5">
            <BulkPhotos formik={formik} />
          </div>
        )}

        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={contentConfig(contentType)}
                  subContentType={key}
                  disclaimer={
                    contentType === "lessons" &&
                    "Order of Sections below will appear correctly on App and WEB"
                  }
                  labels={subTables[key].label}
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={contentType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}
      </EntryForm>
    </Page>
  );
};

export default ContentDetail;
