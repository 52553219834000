import Breadcrumb from "./BreadcrumbNavigation";
import OnboardingWizardSwitcher from "./OnboardingWizardSwitcher";

const Page = ({
  links,
  title,
  titleComponent,
  children,
  extra,
  centerComponent,
}) => {
  return (
    <div className="ttnk-main-bg w-screen pt-24">
      <div className="container mx-auto">
        <OnboardingWizardSwitcher />
        <Breadcrumb links={links} />
        <div className="grid grid-flow-col grid-cols-3 gap-4">
          <h1 className="ttnk-page__title m-0">{title}</h1>
          <div>{centerComponent}</div>
          <div className="flex justify-end">{titleComponent}</div>
        </div>
        {extra}
        {children}
      </div>
    </div>
  );
};

export default Page;
