import React, { useEffect, useState } from "react";
import { EntryForm, Input, TextArea, getNestedFieldStatus } from "./EntryForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validations } from "../lib/form";
import { Radio } from "antd";
import { FileUpload } from "./FileUpload";
import UploadIcon from "../assets/icons/upload.png";
import { GetApp, UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";

import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { SUBMITTED, GET_STARTED } from "../lib/onboarding_config/config";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { VideoPlayer } from "./VideoPlayer";

const OnboardingAppDesign = () => {
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [initialValues, setInitialValues] = useState({
    app_name: "",
    tagline: "",
    logo: [],
    logo_preference: "",
    branding_info: "",
    logo_icons: [],
    has_understand: false,
  });

  const { user } = useUserProvider();
  const [isLoading, setIsLoading] = useState(false);
  const isAppStoreInfoCompleted =
    onboardingData?.progress?.store?.info &&
    onboardingData?.progress?.store?.listing &&
    onboardingData?.progress?.store?.support;

  const schema = {
    app_name: validations.str_req_30,
    tagline: validations.str_req_50,
    branding_info: Yup.string().when("logo_preference", {
      is: "use",
      then: validations.str_req_255,
      otherwise: Yup.string(),
    }),
    has_understand: Yup.boolean().oneOf([true], "Required"),
    logo_preference: Yup.string().required(),
  };

  const resetForm = () => {
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setIsSubmitted(
          res.data.onboarding.progress.design.status === SUBMITTED &&
            !user.isSuper
        );
        
        setInitialValues({
          app_name: res.data.name,
          tagline: res?.data.about.design.loading_screen_tagline,
          brand_website: res?.data?.about?.design?.brand_website,
          branding_info: res?.data?.about?.design?.branding_info,
          media_metadata: {
            ...(res?.data?.setup?.media_metadata ?? {}),
            ...(res?.data?.about?.media_metadata ?? {}),
          },
          logo_icons: res?.data?.about?.design?.logo_icons,
          logo_preference: res?.data?.about?.design?.logo_icon || "use",
          has_understand:
            res?.data?.onboarding?.progress?.design?.status === SUBMITTED,
        });
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      setIsLoading(true);
      const _values = {
        name: values.app_name,
        setup: {
          media_metadata: values.media_metadata,
        },
        about: {
          design: {
            brand_website: values.brand_website,
            branding_info: values.branding_info,
            logo_icon: values.logo_preference,
            logo_icons: ["create", "use"]?.includes(values.logo_preference)
              ? values.logo_icons
              : [],
            loading_screen_tagline: values.tagline,
          },
        },
        onboarding: {
          progress: {
            store: {
              status: isAppStoreInfoCompleted
                ? SUBMITTED
                : onboardingData?.progress?.store?.status,
            },
            design: {
              status: SUBMITTED,
            },
          },
        },
      };

      UpdateApp(user.currentApp(), user.idToken, {
        data: {
          ..._values,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((res) => {
          setOnboardingData(res.data.onboarding);
          resetForm();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  const { error: errorLogo } = getNestedFieldStatus("logo", formik);
  const { error: errorLogoIcons } = getNestedFieldStatus("logo_icons", formik);

  useEffect(() => {
    resetForm();
  }, []);

  const { isTouched: isCheckboxTouched, error: checkboxError } =
    getNestedFieldStatus("has_understand", formik);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]);

  const isEnableSubmit = (type) => {
    const commonCondition =
      !formik?.values?.app_name ||
      !formik?.values?.tagline ||
      !formik?.values?.has_understand;
    switch (type) {
      case "design":
        return commonCondition;
      case "create":
        return (
          commonCondition ||
          formik?.values?.logo_icons.length > 5 ||
          formik.values.logo_icons.length === 0 ||
          !formik.values.logo_icons.length
        );
      case "use":
        return commonCondition || formik?.values?.logo_icons?.length === 0;
      default:
        return commonCondition || !formik?.values?.branding_info;
    }
  };

  return (
    <div className="px-5">
      <div className="w-4/6 mx-auto">
        <VideoPlayer url="https://youtu.be/9nQs3xXVCKs" />
      </div>
      <div className="my-10 font-medium text-xl">
        Watch the video above and then fill out this form to give us what we
        need to design your App. We will then send you design mockups to approve
        so we can finalize your app design. This page gives us the information
        we need to create the graphics for you to review/approve. Finishing this
        page and approving your app design completes this section.
      </div>
      <EntryForm
        submitHandler={formik.handleSubmit}
        formik={formik}
        removeStyle={true}
        hideSubmit
      >
        <div className="flex flex-col w-full gap-4">
          <Input
            name="app_name"
            type="text"
            label="App Name"
            formik={formik}
            isFieldRow={true}
            placeholder="What is the name of your app?"
            required
            disabled={isSubmitted}
          />
          <Input
            fieldDescription="* 7-10 words, goes on your App's loading screen (middle image, above)"
            placeholder="Usually about your App content (ie. 'Expert Advice & Tips')"
            name="tagline"
            type="text"
            label="Loading Screen Tagline"
            formik={formik}
            isFieldRow={true}
            required
            disabled={isSubmitted}
          />

          <div className="flex flex-row w-full justify-between">
            <div>
              Logo/Icon<span style={{ color: "red" }}>*</span>
            </div>
            <div className="w-2/3">
              <Radio.Group
                className="flex flex-col"
                onChange={(e) => {
                  formik.setFieldValue("logo_preference", e.target.value);
                  formik.setFieldValue("logo_icons", []);
                }}
                value={formik.values.logo_preference}
                disabled={isSubmitted}
              >
                <Radio value="use">
                  "Use my existing logo icon & graphics"
                </Radio>
                <Radio value="design">CUSTOM: "I need one designed"</Radio>
                <Radio value="create">
                  "I'll create my own logo icon & graphics"
                </Radio>
              </Radio.Group>
              <div className="mt-3 text-xs text-gray-400 mb-4">
                * If you would like a custom, professional App Logo/Icon
                (top-left image) designed for your app, select CUSTOM & our
                Design partner will contact you (usually cost $100 - $150 extra)
              </div>
            </div>
          </div>

          {formik.values.logo_preference === "use" ? (
            <>
              <div className="flex flex-row w-full justify-between">
                <div>
                  Upload Your Logo/Icon<span style={{ color: "red" }}>*</span>
                </div>
                <div className="w-2/3">
                  <FileUpload
                    disabled={isSubmitted}
                    value={formik.values.logo_icons}
                    multiple={true}
                    errorMessage={
                      "Invalid file type. The following file types are supported: jpg, jpeg, png, gif"
                    }
                    onError={(err) => {
                      formik.setFieldError("logo_icons", err);
                    }}
                    maximumFileSize={35000000}
                    error={errorLogoIcons}
                    isButton={false}
                    uploadType="file"
                    buttonClassName=""
                    fileType={"*"}
                    onChange={(val, metadata) => {
                      formik.setFieldTouched("logo_icons");
                      formik.setFieldValue("logo_icons", val);
                      if (metadata) {
                        formik.setFieldValue("media_metadata", {
                          ...formik.values.media_metadata,
                          [metadata.id]: metadata,
                        });
                      }
                    }}
                    metaData={formik.values.media_metadata}
                  >
                    <div
                      className={`p-6 flex flex-row justify-between items-center bg-white ${
                        isSubmitted
                          ? ""
                          : "text-aeblue-link underline hover:no-underline"
                      }`}
                    >
                      <div>Choose File(s)</div>
                      <div>
                        <div
                          className="p-4 rounded-full"
                          style={{
                            background: "#ebebeb",
                          }}
                        >
                          <img
                            src={UploadIcon}
                            alt="Upload File"
                            className="w-3 h-3"
                          />
                        </div>
                      </div>
                    </div>
                  </FileUpload>
                </div>
              </div>
              <Input
                name="brand_website"
                type="text"
                label="Brand Website"
                formik={formik}
                disabled={isSubmitted}
                isFieldRow={true}
                placeholder="Add your primary website where we can pull colors/fonts from"
              />
              <TextArea
                disabled={isSubmitted}
                name="branding_info"
                required
                placeholder="Add your Branding info & design notes"
                type="text"
                label="Add Your Branding Info"
                formik={formik}
                isFieldRow={true}
                fieldDescription={
                  "* Please add colors (Hex, Pantone, RGB or CMYK) or if colors are in the logo/icon you attach indicate that (we need a light, medium & dark color for your App, top-right image: please also include App Title & Tagline font preferences"
                }
              />
            </>
          ) : null}
          {formik.values.logo_preference === "create" && (
            <>
              <div
                className="flex flex-row items-start gap-1 p-2 mt-3 mb-5"
                style={{
                  background: "#f5f5f5",
                  border: "1px solid #cccccc",
                }}
              >
                <div className="font-bold">DESIGN YOUR APP:</div>
                <a
                  className="underline font-bold"
                  style={{
                    color: "#3a6cae",
                  }}
                  href="https://actionera.freshdesk.com/support/solutions/articles/69000838267-marketing-graphic-templates"
                  target="_blank"
                  rel="noreferrer"
                >
                  Follow this link to create your app design &gt;&gt;
                </a>
              </div>
              <div className="flex flex-row w-full justify-between mb-5">
                <div>
                  Upload Your Canva Images
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="w-2/3">
                  <FileUpload
                    disabled={isSubmitted}
                    value={formik.values.logo_icons}
                    multiple={true}
                    errorMessage={
                      "Invalid file type. The following file types are supported: jpg, jpeg, png, gif"
                    }
                    onError={(err) => {
                      formik.setFieldError("logo_icons", err);
                    }}
                    maximumFileLength={5}
                    error={errorLogoIcons}
                    isButton={false}
                    buttonClassName=""
                    fileType={[
                      "image/png",
                      "image/jpeg",
                      "image/jpg",
                      "image/gif",
                    ]}
                    onChange={(val, metadata) => {
                      formik.setFieldTouched("logo_icons");
                      formik.setFieldValue("logo_icons", val);

                      if (metadata) {
                        formik.setFieldValue("media_metadata", {
                          ...formik.values.media_metadata,
                          [metadata.id]: metadata,
                        });
                      }
                    }}
                    metaData={formik.values.media_metadata}
                  >
                    <div
                      className={`p-6 flex flex-row justify-between items-center bg-white ${
                        isSubmitted
                          ? ""
                          : "text-aeblue-link underline hover:no-underline"
                      }`}
                    >
                      <div>Choose File(s)</div>
                      <div>
                        <div
                          className="p-4 rounded-full"
                          style={{
                            background: "#ebebeb",
                          }}
                        >
                          <img
                            src={UploadIcon}
                            alt="Upload File"
                            className="w-3 h-3"
                          />
                        </div>
                      </div>
                    </div>
                  </FileUpload>
                  <div className="mt-3 text-xs text-gray-400">
                    ^Follow instructions in Canva templates to create these
                    files
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full flex flex-row gap-2 items-start mb-2">
          <input
            type="checkbox"
            name="vehicle1"
            className="mt-1"
            checked={formik.values.has_understand}
            disabled={isSubmitted}
            onChange={() =>
              formik.setFieldValue(
                "has_understand",
                !formik.values.has_understand
              )
            }
          />
          <div className="text-sm">
            I understand that making any changes to my approved design will cost
            $50-$250 (billed independently) and may take an additional 1-2
            weeks, depending on the changes and what stage my App Launch is in
            (there may also be an additional Rebuild or Resubmit cost & delay if
            app has already been built or submitted).
          </div>
        </div>
        {checkboxError && <div className="text-red-600">{checkboxError}</div>}
        <div className="flex flex-row items-center justify-between w-full">
          <div
            className="text-base font-bold underline opacity-80 cursor-pointer"
            style={{
              width: 120,
              color: isSubmitted ? "gray" : "#3091ce",
              cursor: isSubmitted ? "not-allowed" : "pointer",
            }}
            disabled={isSubmitted}
            onClick={() => {
              if (isSubmitted) return;

              setIsLoading(true);
              const _values = {
                name: formik.values.app_name,
                setup: {
                  media_metadata: formik.values.media_metadata,
                },
                about: {
                  design: {
                    brand_website: formik.values.brand_website,
                    branding_info: formik.values.branding_info,
                    logo_icons: ["create", "use"]?.includes(
                      formik.values.logo_preference
                    )
                      ? formik.values.logo_icons
                      : [],
                    logo_icon: formik.values.logo_preference,
                    loading_screen_tagline: formik.values.tagline,
                  },
                },
                onboarding: {
                  progress: {
                    design: {
                      status: "In Progress",
                    },
                  },
                },
              };
              UpdateApp(user.currentApp(), user.idToken, {
                data: {
                  ..._values,
                },
              })
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  }
                })
                .then((res) => {
                  setOnboardingData(res.data.onboarding);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            Save Progress
          </div>
          <div className="flex flex-row items-center gap-5 justify-end w-full">
            <button
              className="ttnk-button"
              type="submit"
              disabled={
                isEnableSubmit(formik.values.logo_preference) ||
                !formik.dirty ||
                isSubmitted
              }
            >
              SUBMIT
            </button>
          </div>
        </div>
      </EntryForm>
    </div>
  );
};

export default OnboardingAppDesign;

export const AppDesignStatus = () => {
  const { onboardingData } = useOnboardingProvider();

  return (
    <div
      className="ttnk-AvenirNextBold"
      style={{
        color: "#2F91CF",
      }}
    >
      {onboardingData?.progress?.design?.status}
    </div>
  );
};
