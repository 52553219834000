import Modal from 'react-modal'
import { Drawer } from 'antd'

const BaseDrawer = ({ drawerOpen, closeHandler, children, title, width = "33%", bodyStyle, titleClasses }) => {
  const baseModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)', 
    }
  }

  const onClose = ()=>{
    closeHandler();
  }

  return(
    <Drawer
      visible={drawerOpen}
      onClose={onClose}
      width={width}
      closeIcon={null}
      bodyStyle={bodyStyle}
      maskClosable={false}
      zIndex={10}
    >
      {!!title && <h1 className={`ttnk-page__title uppercase m-0 ${String(titleClasses)}`}>{title}</h1>}
      { children }
    </Drawer>
  )
}

export default BaseDrawer
