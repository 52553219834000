import * as React from 'react'
import { useContext, useState } from 'react'
import BaseModal from '../components/BaseModal'

export const ModalContext = React.createContext()

export const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    show: false,
    content: null,
    customContentStyle: {}
  })

  const showModal = (modalContent, customContentStyle = {}) => {
    setModalData({
      show: true,
      content: modalContent,
      customContentStyle
    })
  }

  const closeModal = () => {
    setModalData({
      show: false,
      content: null,
    })
  }

  return(
    <ModalContext.Provider value={ { showModal, closeModal } }>
      <BaseModal modalOpen={modalData.show} customContentStyle={modalData?.customContentStyle ?? {}}>
        { modalData.content }
      </BaseModal>
      {children}
    </ModalContext.Provider>
  )
}

export const useModalProvider = () => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error('Invalid use of ModalContext')
  }

  return context
}
