/* eslint-disable jsx-a11y/label-has-associated-control */
import { FaCheck } from 'react-icons/fa';

const ExpandableContent = ({
  id,
  isExpandable,
  isChecked,
  expandedNode,
  collapsedNode,
  isExpanded,
  setIsExpanded,
  isSubAccordion,
  setIsChecked,
  disabled,
  index,
  indexLength,
}) => {
  const colors = {
    dark: "#2650A0",
    medium: "#939799"
  }

  return (
    <div
      className={`flex flex-col w-full last:rounded-b-lg first:rounded-t-lg ${
        isExpanded ? 'last:mb-0' : ''
      } ${isSubAccordion ? '' : `${!isExpanded ? 'border-b' : ''} border-b-[#888888] last:border-b-0`}`}
    >
      <div className={`${
        isExpanded && !isSubAccordion ? 'bg-lightBlue' : ''
      } ${isSubAccordion ? '!bg-inherit p-2' : 'border border-solid border-darkGray p-6 pt-4'} flex items-center custom-checkbox w-full text-xl -mb-0.5 ${isExpandable && isExpanded ? 'border-b-0' : ''} ${index === 0 ? 'rounded-t-lg': ''} ${index === 0 ? 'rounded-t-lg': ''} ${index === (indexLength - 1) && !isExpanded ? 'rounded-b-lg': ''}`}>
        <button
          type="button"
          className={`p-2 ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${isSubAccordion ? 'border-3 rounded' : 'border-6 rounded-lg'}`}
          id={id}
          style={{
            background: isChecked ? colors.dark : '#FFFFFF',
            borderColor: disabled ? colors.medium : colors.dark,
          }}
          disabled={disabled}
        >
          <div
            className="rounded-lg"
            style={{
              borderColor: isChecked ? colors.dark : '#FFFFFF',
              backgroundColor: isChecked ? colors.dark : '#FFFFFF',
            }}
          >
            <FaCheck className={`text-white font-bold rounded-lg ${isSubAccordion ? 'h-3 w-3' : 'h-8 w-8'}`} />
          </div>
        </button>
        <span
          className={`ml-2 text-xl font-medium flex flex-row justify-between items-center w-full select-none ${
            isExpandable ? 'cursor-pointer' : 'cursor-text'
          }`}
          onClick={() => {
            if (isExpandable) setIsExpanded(!isExpanded);
          }}
          role="presentation"
        >
          {collapsedNode}
        </span>
      </div>
      <div
        className={`text-black h-auto items-center transition-all bg-white
        ${
          isExpanded
            ? 'visible max-h-fit opacity-1'
            : 'hidden max-h-0 opacity-0'
        }`}
      >
        {expandedNode}
      </div>
    </div>
  );
};

export default ExpandableContent;
