import { Radio } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, Select } from "../../EntryForm";
import ContentDropdowns from "./ContentDropdowns";
import { useUserProvider } from "../../../providers/UserProvider";

const LinkTo = ({
  formik,
  isDataLoaded,
  data,
  fetchSendTo,
  shouldShowAllFields
}) => {
  const [isSectionChanged, setIsSectionChanged] = useState(false)

  const handleFetchSendTo = useCallback(() => {
    if (formik.values.form_section_value !== '' || formik.values.form_link_to !== '') {
      fetchSendTo(formik.values)
      setIsSectionChanged(false)
    }
  }, [formik.values.form_section_value, formik.values.form_link_to])

  useEffect(() => {
    if (isSectionChanged) handleFetchSendTo()
  }, [isSectionChanged])

  if (!isDataLoaded) return null

  return (
    <>
      <div className={`flex flex-row ${formik.values.form_link_to === 'home' ? 'mb-5' : ''}`}>
        <span className="font-bold w-20">Link to:</span>

        <Radio.Group
          onChange={(e) => {
            formik.setFieldValue("form_link_to", e.target.value);
            formik.setFieldValue("form_url", "");
            formik.setFieldValue("form_section_title", "");
            formik.setFieldValue("form_section_value", "");
            formik.setFieldValue("form_content", "");
            formik.setFieldValue("form_content_type", "");
            formik.setFieldValue("form_content_uuid", "");
            formik.setFieldValue("send_to_tags", []);

            if (['home', 'section', 'url']?.includes(e.target.value)) {
              formik.setFieldValue("send_to", "everyone")
            }

            setIsSectionChanged(true)
          }}
          value={formik.values.form_link_to}
          className="flex flex-row"
        >
          <Radio value="home">Home Screen</Radio>
          {
            shouldShowAllFields ? (
              <>
                <Radio value="section">Section</Radio>
                <Radio value="content">Content</Radio>
                <Radio value="url">URL</Radio>
              </>
            ) : null
          }
        </Radio.Group>
      </div>

      {formik.values.form_link_to !== "home" ? (
        <div className="w-full flex flex-row">
          {formik.values.form_link_to === "section" ? (
            <div className="w-1/2 pr-8">
              <Select
                name="form_section_value"
                label=""
                formik={formik}
                className="w-1/2"
                onChange={() => setIsSectionChanged(true)}
              >
                <option value="">- Section -</option>
                {data?.section?.map((item, index) => (
                  <option
                    key={`section-${String(index)}`}
                    value={item.value}
                  >
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          ) : null}

          {formik.values.form_link_to === "content" ? (
            <ContentDropdowns
              formik={formik}
              isDataLoaded={isDataLoaded}
              content={data?.content}
              fetchSendTo={fetchSendTo}
            />
          ) : null}
        </div>
      ) : null}

      {formik.values.form_link_to === "url" ? (
        <Input
          name="form_url"
          type="text"
          label=""
          placeholder="https://"
          formik={formik}
        />
      ) : null}
    </>
  )
}

export default LinkTo