import { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useModalProvider } from "../providers/ModalProvider";
import { useUserProvider } from "../providers/UserProvider";
import { GetAppResource } from "../services/api/app";
import { DeleteTrack } from "../services/api/tracks";

import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import DeleteItem from "../components/modal_contents/DeleteItem";

const TrackList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  })

  const history = useHistory();

  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'tracks')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length
        })
      })
  }

  const links = [
    { name: "Home", url: "/" },
    { name: 'Trackings List', url: '/tracks' },
  ]

  const updateButtonHandler = (id) => {
    history.push(`/tracks/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteTrack(id, user.idToken)
    loadData()
    closeModal()
  }

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel='Card/Book Track' onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),
    },
    {
      name: 'item_type',
      label: 'Item Type',
      parser: (data) => (data.item_type),
    },
    {
      name: 'item_number',
      label: 'Item Number',
      parser: (data) => (data.item_number),
    },
    {
      name: 'started',
      label: 'Started Track?',
      parser: (data) => (data.started ? "Yes" : "No"),
    },
    {
      name: 'member_email',
      label: 'Member',
      parser: (data) => (data.member_email),
    },
    {
      name: 'name',
      label: 'Name',
      parser: (data) => (data.name),
      ellipsis: true,
    },
    {
      name: 'date_added',
      label: 'Date Added',
      parser: (data) => {
        const momentDate = moment(data.date_added)
        if(momentDate.isValid()){
          return momentDate.format("MMMM D, YYYY");
        }
        return ""
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id} />),
    },
  ]

  return (
    <Page links={links} title={'Trackings List'}>
        <DataTable  metadata={metadata}
                    dataLoader={loadData}
                    data={data}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    searchKeys={['item_type']} />
    </Page>
  );
}

export default TrackList;