import React, { useState } from "react";
import HomeIcon from "../assets/icons/onboarding_home.svg";
import Hero from "../assets/images/ae-logo-light-nameonly.png";
import ArrowDown from "../assets/icons/caret-down-white.svg";
import AvatarWhite from "../assets/icons/circle-user-solid-white.svg";
import { Link } from "react-router-dom";
import Settings from "../assets/icons/settings.svg";
import { useUserProvider } from "../providers/UserProvider";
import { useAuth } from "react-oauth2-pkce";
import Logout from "../assets/icons/logout.svg";
import { Tooltip } from "@material-ui/core";

const OnboardingHeader = () => {
  const { authService } = useAuth();
  
  const { user } = useUserProvider();
  const [isHovering, setIsHovering] = useState(false);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [isChangePasswordHovered, setIsChangePasswordHovered] = useState(false)
  const [isSignoutHovered, setIsSignoutHovered] = useState(false)
  const showNumber = user?.app?.plus && user?.app_data?.setup?.lite_unlock_code && !user?.app_data?.setup?.biz_plus

  const toggleSubMenu = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    setSubMenuVisible(true); // Show submenu when mouse enters menu
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const logout = async () => {
    await fetch(`${process.env.REACT_APP_SSO_HOST}/admins/sign_out`, {
      credentials: "include",
      method: "GET",
    });

    authService.logout();

    if (user.isSuper) window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/`;
  };

  return (
    <div
      className="w-full bg-black text-white flex flex-row items-center justify-between p-4"
      style={{ height: 64 }}
    >
      <div className="flex flex-row items-center gap-4">
        <img src={HomeIcon} alt="home"/>
        {user.app.name} {showNumber ? `● #${user?.app_data?.setup?.lite_unlock_code}`: ''}
      </div>
      {
        !user?.app_data?.portal?.logo_url?.length && (
          <img
            src={Hero}
            style={{ width: 275, height: 40 }}
            alt="hero" className="absolute left-0 right-0 my-0 mx-auto cursor-pointer"
            onClick={() => window.open('https://www.actionera.com/', '_blank')}
          />
        )
      }
      <div>
        <div className="flex text-white justify-center h-full w-72 mr-8">
          <div
            id="user-details"
            className="relative w-full h-full flex flex-col group justify-center"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="flex h-full items-center ml-auto mr-2 flex-row justify-between w-100"
              style={{
                backgroundColor: isSubMenuVisible && isHovering ? "#2750a0" : "inherit",
                height: 64,
                width: 200,
              }}
            >
               <Tooltip title={<div>{user.data.name}</div>}>
                <div className="truncate px-4" style={{
                  width: 150
                }}>{user.data.name}</div>
              </Tooltip>
             <div className="flex flex-row items-center gap-5 mr-2">
              <img
                  src={ArrowDown}
                  className={`rotate-45`}
                  style={{
                    transform: isSubMenuVisible && isHovering ? "rotate(180deg)" : "",
                  }}
                  onClick={toggleSubMenu}
                  alt="arrow"
                />
                <img src={AvatarWhite} className="block h-8" alt="avatar" />
             </div>
            </div>
            <div
              style={{
                top: "63px",
                display: isSubMenuVisible && isHovering ? "block" : "none",
                backgroundColor: isSubMenuVisible || isHovering ? "#2750a0" : "inherit",
                width: 200,
                right: 6,
              }}
              className="absolute bg-132850 text-white rounded-b-lg w-full z-50"
            >
              <div className="w-100 text-white bg-[#2750a0]">
              <Tooltip title={<div>{user.data.email}</div>}>
                <div className="p-4 truncate">{user.data.email}</div>
              </Tooltip>
                <Link
                onMouseEnter={() => setIsChangePasswordHovered(true)}
                onMouseLeave={() => setIsChangePasswordHovered(false)}
                  className="hover:text-white w-100 py-4 px-4 flex cursor-pointer text-white"
                  style={{
                    background: isChangePasswordHovered ? '#2F91CF': 'transparent'
                  }}
                  to="/onboarding/change_password"
                >
                  <img src={Settings} className="w-4 mr-2 text-white" alt="change-password" />
                  Change Password
                </Link>
                <div
                onMouseEnter={() => setIsSignoutHovered(true)}
                onMouseLeave={() => setIsSignoutHovered(false)}
                  className="hover:bg-aeblue w-100 px-4 py-4 flex cursor-pointer rounded-b-lg"
                  onClick={() => logout()}
                  style={{
                    background: isSignoutHovered ? '#2F91CF': 'transparent'
                  }}
                >
                  <img src={Logout} className="w-4 mr-2 text-white" alt="singout"/>
                  Sign out
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingHeader;
