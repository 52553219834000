import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, TextArea } from "../../EntryForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import MediaUploader from "../../MediaUploader";
import PushNotificationModalTab from "./PushNotificationModalTab";
import LinkTo from "./LinkTo";
import SendTo from "./SendTo";
import When from "./When";
import { AddPushNotification, GetLinkTo, GetSendTo } from "../../../services/api/push_notification";
import { useUserProvider } from "../../../providers/UserProvider";
import responseProcessor from "../../../lib/responseProcessor";
import { useModalProvider } from "../../../providers/ModalProvider";
import { sectionList } from "./sectionList";
import { contentList } from "./contentList";
import { ClipLoader } from "react-spinners";
import DataSelector from "../../DataSelector";

const defaultError = "Send To is disabled. Make sure you have at least one member/ tag who can accept push notifications"

const defaultValues = {
  subject: "",
  image_url: "",
  media_metadata: {},
  message: "",

  send_to: "everyone", // either everyone or tag
  send_to_tags: [], // tag uuids

  send_at: "now", // will be changed when we already have the scheduled PNs

  form_link_to: "section", // value of link to radio button (home, section, content, or url)

  form_url: "", // url value from link to if url is selected

  form_section_title: "", // label of section dropdown
  form_section_value: "", // value of section dropdown

  form_content: "", // label of content type dropdown
  form_content_type: "", // value of content type dropdown

  form_content_uuid: "", // (value of content title dropdown)

  parent_uuid: null,

  shouldRefetch: false
}

const defaultDataValues = {
  content: [],
  section: [],
  devices: null,
  members: null,
  tags: [],
  tagDevices: null,
  tagMembers: null,
  isEveryoneDisabled: false,
  isTagsDisabled: false,
  parent_uuid: null
}

const SavedLabel = () => (
  <span className="flex items-center justify-center text-center pt-2 text-green-600">Message Sent!</span>
);

const PushNotificationModal = ({
  pnData,
  shouldShow,
  setShouldShow,
  setCurrentPnData
}) => {
  const modalRef = useRef(null);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [metadata, setMetadata] = useState({})
  const [saved, setSaved] = useState(false);

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [data, setData] = useState(defaultDataValues)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldShowAllFields, setShouldShowAllFields] = useState(false)

  const { user, setShouldFetchList, shouldFetchPNData, setShouldFetchPNData } = useUserProvider()
  const { showModal } = useModalProvider()

  const isSubAppOwnerOrReseller = user?.app_data?.from_apps?.length > 0
  const isBizPlusOwner = user?.app_data?.fcm_key?.length > 0 && user?.app_data?.fcm_project_id?.length > 0

  const whichApp = useMemo(() => isBizPlusOwner ? [...user?.app_data?.from_apps, {
    id: user?.app?.id,
    name: user?.app?.name
  }] : user?.app_data?.from_apps ?? [], [user, isBizPlusOwner])

  const sortedWhichAppByName = useMemo(() => whichApp?.sort((a, b) => a.name.localeCompare(b.name)), [whichApp])

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => {
        setSaved(true)

        if (typeof setShouldFetchList === 'function') setShouldFetchList(true)

        setTimeout(() => {
          setShouldShow(false)
          setSaved(false)
          setInitialValues(defaultValues)
        }, [1000])
      },
      toastMessage: "Message has been queued!",
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          subject: Yup.string()
            .max(50, "Maximum 50 characters")
            .required("Subject is required"),
          message: Yup.string()
            .max(160, "Maximum 160 characters")
            .required("Message is required"),
        };

        if (values.send_to === 'tag') {
          schema.send_to_tags = Yup.array()
          .min(1, "Tag is required")
          .required("Tag is required")
          .nullable()
        }

        if (values.form_link_to === 'section') {
          schema.form_section_value = Yup.string().required("Section is required")
        }

        if (values.form_link_to === 'content') {
          schema.form_content_type = Yup.string().required("Content is required")
          schema.form_content_uuid = Yup.string().required(
            "Content Title is required"
          )
        }

        if (values.form_link_to === 'url') {
          schema.form_url = Yup.string().url("URL must begin with https:// or http:// e.g. https://actionera.com").required("URL is required")
        }

        if (isSubAppOwnerOrReseller) {
          schema.parent_uuid = Yup.object().shape({
            name: Yup.string().nullable(),
            id: Yup.string().nullable()
          }).nullable().required("This is required")
        } else {
          schema.send_to = Yup.string().required(defaultError)
        }

        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      const payload = {
        data: {
          subject: values.subject,
          image_url: values.image_url,
          message: values.message,
          for_everyone: values.send_to === 'everyone',
          send_to: isSubAppOwnerOrReseller ? "everyone" : values.send_to,
          form_link_to: values.form_link_to,
          parent_uuid: values?.parent_uuid?.id,

          // send_at: "now", // will be changed when we already have the scheduled PNs
        }
      }

      if (values.form_link_to === 'section') {
        const section = sectionList.find(item => item.value === values.form_section_value)

        payload.data.form_section_title = section.name
        payload.data.form_section_value = values.form_section_value
      }

      if (values.form_link_to === 'content') {
        const content = contentList.find(item => item.value === values.form_content_type)

        payload.data.form_content = content.name
        payload.data.form_content_type = values.form_content_type

        payload.data.form_content_uuid = values.form_content_uuid
      }

      if (values.form_link_to === 'url') {
        payload.data.form_url = values.form_url
      }

      if (values.send_to === 'tag') {
        const tags = Object.values(values.send_to_tags?.map((item) => item.id)) ?? []
        payload.data.send_to_tags = tags
      }

      AddPushNotification(user.currentApp(), user.idToken, payload)
      .then((response) => responseProcessorProxy(response))
      .then(() => formik.resetForm())
    },
  });

  const fetchSendTo = useCallback((values) => {
    setIsLoading(true)
    GetSendTo(user.currentApp(), user.idToken, values)
      .then((response) => {
        if (response.ok) return response.json()

        return {}
      }).then((response) => {
        setData((prevData) => {
          const newData = {
            ...prevData,
            ...{
              tags: response?.data?.tags,
              isEveryoneDisabled: response?.data?.for_everyone === false,
              isTagsDisabled: response?.data?.tags_enabled === false
            },
          }

          if (newData?.isEveryoneDisabled && !newData?.isTagsDisabled) {
            newData.tagDevices = response?.data?.devices
            newData.tagMembers = response?.data?.members
          }

          if (!newData?.isEveryoneDisabled
            || (newData?.isEveryoneDisabled && newData?.isTagsDisabled)) {
            newData.devices = response?.data?.devices
            newData.members = response?.data?.members
          }

          return newData
        })

        if (response?.data?.for_everyone === false) {
          formik.setFieldValue("send_to", "tag")

          if (response?.data?.tags_enabled === true) {
            formik.setFieldValue('send_to_tags', response?.data?.tags)
          }
        }

        if (response?.data?.for_everyone === true) {
          formik.setFieldValue("send_to", "everyone")
        }
      }).finally(() => setIsLoading(false))
  }, [formik.values, user])

  const populateData = useCallback((values) => {
    GetLinkTo(user.currentApp(), user.idToken)
    .then((response) => {
      if (response?.ok) return response.json()

      return {}
    }).then((response) => {
      if (response?.data) {
        setData((prevData) => {
          return {
            ...prevData,
            ...{
              content: response?.data?.content_list,
              section: response?.data?.section_list,
              tags: response?.data?.tags
            },
          }
        })
      }
    }).then(() => {
      if (isDataLoaded) fetchSendTo(values)
    })
    .finally(() => setIsDataLoaded(true))
  }, [user, formik, isDataLoaded])

  useEffect(() => {
    if (!isDataLoaded || shouldFetchPNData || formik?.values?.shouldRefetch === true) {
      if (formik?.values?.shouldRefetch === true) formik.setFieldValue("shouldRefetch", false)
      if (shouldFetchPNData) setShouldFetchPNData(false)

      populateData(formik.values)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded, shouldFetchPNData, formik?.values?.shouldRefetch])

  const resetData = useCallback(async () => {
    formik.resetForm()
    let newValues = {
      ...defaultValues,
      form_link_to: isSubAppOwnerOrReseller ? "home" : "section"
    }

    setInitialValues(newValues)
    setData(newValues)
    setSaved(false)

    if (typeof setCurrentPnData === 'function') setCurrentPnData(null)

    populateData(newValues)
  }, [formik, isSubAppOwnerOrReseller])

  const loadData = useCallback(async () => {
    if (pnData?.id) {
      setMetadata(pnData?.media_metadata)

      let newValues = {
        subject: pnData?.subject,
        image_url: pnData?.image_url,
        media_metadata: pnData?.media_metadata,
        message: pnData?.message,

        send_to: pnData?.send_to === "" ? "everyone" : pnData?.send_to, // either everyone or tag
        send_to_tags: pnData?.send_to_tags ?? [], // tag uuids

        send_at: "now", // will be changed when we already have the scheduled PNs

        form_url: pnData?.form_url, // url value from link to if url is selected

        form_section_value: pnData?.form_section_value, // value of section dropdown

        form_content: pnData?.form_content, // label value of content type dropdown
        form_content_type: pnData?.form_content_type, // value of content type dropdown
        form_content_uuid: pnData?.form_content_uuid, // (value of content title dropdown)

        form_link_to: pnData?.form_link_to,

        parent_uuid: pnData?.parent_uuid,

        shouldRefetch: true
      }

      setInitialValues(newValues);
    }
  }, [pnData, isSubAppOwnerOrReseller]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const updateLinkTo = useCallback(() => {
    setInitialValues({
      ...initialValues,
      form_link_to: isSubAppOwnerOrReseller ? "home" : "section"
    })
  }, [isSubAppOwnerOrReseller, initialValues])

  useEffect(() => {
    if (!pnData?.id) updateLinkTo()
  }, [isSubAppOwnerOrReseller, shouldShow, user, pnData])

  const isBizPlusSubApp = useMemo(() => {
    return user?.app?.id === formik?.values?.parent_uuid?.id
  }, [formik, user])

  useEffect(() => {
    setShouldShowAllFields(pnData?.isOwnPN ? true : isSubAppOwnerOrReseller ? isBizPlusSubApp : isBizPlusOwner)
  }, [isBizPlusSubApp, isSubAppOwnerOrReseller, isBizPlusOwner, pnData])

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target) && shouldShow) {
        setShouldShow(false);
        resetData().then(() => setIsDataLoaded(false))
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, shouldShow, resetData]);

  let hasNoLinkTo = (formik?.values?.form_link_to === null || formik?.values?.form_link_to === '')

  if (['section']?.includes(formik.values.form_link_to)) {
    hasNoLinkTo = formik.values.form_section_value === '' || formik.values.form_section_value === null
  }

  if (['content']?.includes(formik.values.form_link_to)) {
    hasNoLinkTo = (formik.values.form_content_uuid === '' || formik.values.form_link_to === '')
  }

  if (['url']?.includes(formik.values.form_link_to)) {
    hasNoLinkTo = (formik.values.form_url === '' || formik.values.form_url === null)
  }

  const isSendToDisabled = useMemo(() => {
    if (!shouldShowAllFields) return false
    if (hasNoLinkTo) return true

    return (Number(data?.devices) === 0 && Number(data?.members) === 0 && Number(data?.tagDevices) === 0 && Number(data?.tagMembers) === 0)
      || (data?.isEveryoneDisabled && data?.isTagsDisabled)

  }, [
    shouldShowAllFields,
    hasNoLinkTo,
    data?.devices,
    data?.members,
    data?.tagDevices,
    data?.tagMembers,
    data?.isEveryoneDisabled,
    data?.isTagsDisabled
  ])

  return (
    <>
      <div className={`${shouldShow ? "visible" : "invisible"}`}>
        <div
          className="fixed z-10 inset-0 w-full h-full duration-300 ease-out transition-all"
          style={{ backgroundColor: "#00000080" }}
        />

        <div
          className="fixed inset-0 z-10 left-0 duration-300 ease-out transition-all"
          style={{
            transform: shouldShow ? "translateX(0%)" : "translateX(-100%)",
          }}
        >
          <div className="flex items-start min-h-screen px-4 py-8">
            <div className="relative w-full max-w-5xl mr-auto big-desktop:pt-24">
              <div
                className="text-white flex justify-end items-center text-2xl cursor-pointer"
                onClick={() => {
                  setShouldShow(false)
                  resetData()
                }}
              >
                X
              </div>

              <div
                className="sm:flex w-full flex-col bg-white rounded-3xl"
                ref={modalRef}
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px ",
                }}
              >
                <div className="bg-lighterBlue rounded-tr-3xl rounded-tl-3xl">
                  <p className="text-center text-white font-figtree py-6 text-2xl font-bold uppercase">
                    <span style={{ fontSize: 24 }}>Send Notification</span>
                  </p>
                </div>

                <PushNotificationModalTab />

                <div className="mb-4 overflow-y-auto h-55vh md:h-70vh lg:h-65vh big-desktop:h-full">
                  <div className="p-8 pt-0">
                    <div className="pb-2 font-bold">
                      Subject of Your Push Notification
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                      <Input
                        name="subject"
                        type="text"
                        label=""
                        placeholder="Subject *"
                        formik={formik}
                      />

                      <MediaUploader
                        name="image_url"
                        type="text"
                        label="Image:"
                        formik={formik}
                        metadata={metadata}
                        mediaType="image"
                        isFromPNWidget
                        labelClassName={"font-bold text-sm"}
                        urlPlaceholder="https://"
                      />

                      <TextArea
                        name="message"
                        type="text"
                        placeholder="Message"
                        formik={formik}
                        rows={15}
                      />

                      <LinkTo
                        formik={formik}
                        isDataLoaded={isDataLoaded}
                        data={data}
                        fetchSendTo={fetchSendTo}
                        shouldShowAllFields={shouldShowAllFields}
                      />

                      {
                        shouldShow && isSubAppOwnerOrReseller && !pnData?.isOwnPN ? (
                          <div className="flex flex-row">
                            <DataSelector
                              name="parent_uuid"
                              formik={formik}
                              label="From Which App?"
                              dataSet={sortedWhichAppByName}
                              makeNullOnRemove
                              placeholder="Choose through which app you will be sending to"
                              singleSelectOnly
                              single
                              withSearch
                              labelClassName="font-bold"
                            />
                          </div>
                        ) : null
                      }

                      {
                        shouldShowAllFields ? (
                          <>
                            <SendTo
                              formik={formik}
                              data={data}
                              setData={setData}
                              hasNoLinkTo={hasNoLinkTo}
                              isSendToDisabled={isSendToDisabled}
                            />

                            <When formik={formik} hasNoLinkTo={hasNoLinkTo} />
                          </>
                        ) : null
                      }

                      <div className="w-full flex justify-center items-center pt-8">
                        <button
                          className="ttnk-button font-bold"
                          type="submit"
                          disabled={isSendToDisabled || isLoading}
                        >
                          {
                            isLoading ? (
                              <ClipLoader
                                color="#2F91CF"
                                loading={true}
                                size={15}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            ) : "[SEND]"
                          }
                        </button>
                      </div>

                      {hasNoLinkTo ? false : isSendToDisabled
                        ? <div className="text-red-600 text-center pt-2">{defaultError}</div>
                        : null
                      }
                      { saved ? <SavedLabel /> : null }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PushNotificationModal;
