import React from 'react';

const Counter = ({
  className,
  currentCount,
  totalCount,
  isDisabled = false
}) => {
  return (
    <div className={className ?? ''}>
      <p className={`ttnk-AvenirNextBold text-15 ${isDisabled ? "text-darkGray" : ""}`}>{currentCount}/{totalCount}</p>
    </div>
  );
};

export default Counter;