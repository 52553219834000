const lessonConfig = {
  label: "Lesson",
  resourceEndpoint: "lessons?hide_sub_data=true",
  listMetadata: [
    "sort_order",
    "title",
    "featured",
    "topics",
    "release_date",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "title", label: "Title" },
    {
      name: "description",
      label: "Description",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "image_url", label: "Image URL" },
    { name: "release_date", label: "Release Date", format: "DD-MMM-yyyy" },
    { name: "action_bar_text", label: "Call to Action Text" },
    { name: "action_bar_url", label: "Call to Action URL" },
    {
      name: "sort_order",
      label: "Sort Order",
    },
    { name: "active", label: "Active" },
    { name: "featured", label: "Featured?" },
    { name: "topics", label: "Topics/Listings" },
    { name: "tags", label: "Tags" },
    { name: "sections", label: "Sections" },
  ],
  formLayout: {
    columns: [
      ["title", "description", "image_url", "release_date", "action_bar_text"],
      ["action_bar_url", "featured", "topics", "sort_order", "tags", "active"],
    ],
  },
  subTables: {
    sections: {
      label: {
        plural: "sections",
        singular: "section",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        {
          name: "sub_description",
          label: "Description",
          parser: (data) => data.sub_description,
        },
        {
          name: "headline",
          label: "Headline",
          parser: (data) => data.headline,
        },
        { name: "title", label: "Section Title", parser: (data) => data.title },
      ],
      detailsMetadata: [
        {
          name: "sort_order",
          label: "Sort Order",
        },
        {
          name: "sub_description",
          label: "Description",
          validations: [
            { type: "required", params: ["Required"] },
            { type: "max", params: [20, "Maximum 20 Characters"] },
          ],
          field: "text",
        },
        {
          name: "headline",
          label: "Headline",
          validations: [
            {
              type: "max",
              params: [40, "Maximum 40 Characters"],
            },
          ],
        },
        {
          name: "title",
          label: "Section Title",
          validations: [
            {
              type: "max",
              params: [40, "Maximum 40 Characters"],
            },
            {
              type: "notRequired",
              params: [],
            },
          ],
        },
        { name: "image_url", label: "Image URL" },
        { name: "text", label: "Section Text", field: "richtext", quillName: 'web_text' },
        { name: "video_url", label: "Video URL" },
        { name: "audio_url", label: "Audio URL" },
        { name: "question_1", label: "Journal 1" },
        { name: "question_2", label: "Journal 2" },
        { name: "question_3", label: "Journal 3" },
        { name: "question_4", label: "Journal 4" },
        { name: "question_5", label: "Journal 5" },
        { name: "question", label: "Question" },
        { name: "answer", label: "Answer" },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
    },
  },
};

export default lessonConfig;
