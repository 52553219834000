export const contentList = [
  {
    name: 'Individual Topics',
    value: 'topic',
    resourceEndpoint: '/topics?push=true'
  },
  {
    name: 'Individiual Directories',
    value: 'directorytopic',
    resourceEndpoint: '/directories?push=true'
  },
  {
    name: 'ActionList',
    value: 'actionlist',
    resourceEndpoint: '/content/action_lists?push=true'
  },
  {
    name: 'Article',
    value: 'article',
    resourceEndpoint: '/content/articles?push=true'
  },
  {
    name: 'Audio',
    value: 'audio',
    resourceEndpoint: '/content/audios?push=true'
  },
  {
    name: 'Blog (ActionBlog)',
    value: 'tip',
    resourceEndpoint: '/content/tips?push=true'
  },
  {
    name: 'Course',
    value: 'course',
    resourceEndpoint: '/courses?push=true'
  },
  // {
  //   name: 'Document',
  //   value: 'Document',
  // resourceEndpoint: '/content/Documents'
  // },
  {
    name: 'Gallery',
    value: 'gallery',
    resourceEndpoint: '/content/galleries?push=true'
  },
  {
    name: 'Journal',
    value: 'journal',
    resourceEndpoint: '/content/journals?push=true'
  },
  {
    name: 'Lesson',
    value: 'lesson',
    resourceEndpoint: '/content/lessons?hide_sub_data=true&push=true'
  },
  {
    name: 'Podcast',
    value: 'podcast',
    resourceEndpoint: '/content/podcasts?push=true'
  },
  {
    name: 'Product',
    value: 'product',
    resourceEndpoint: '/content/products?push=true'
  },
  {
    name: 'Q&A',
    value: 'question',
    resourceEndpoint: '/content/questions?push=true'
  },
  {
    name: 'Resource',
    value: 'resource',
    resourceEndpoint: '/content/resources?push=true'
  },
  {
    name: 'Summit',
    value: 'summit',
    resourceEndpoint: '/summits?push=true'
  },
  {
    name: 'Video',
    value: 'video',
    resourceEndpoint: '/content/videos?push=true'
  },
  {
    name: 'Virtual Event',
    value: 'virtualevent',
    resourceEndpoint: 'virtual_events'
  },
  {
    name: 'Message',
    value: 'message',
    resourceEndpoint: '/content/messages?push=true'
  }
]