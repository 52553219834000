import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetWhatsNewList = async (user) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/whats_new`, 'GET', user.idToken)
}

export const GetWhatsNew = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/whats_new/${uuid}`, 'GET', idToken)
}

export const UpdateWhatsNew = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/whats_new/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteWhatsNew = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/whats_new/${uuid}`, 'DELETE', idToken)
}

export const AddWhatsNew = async (idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/whats_new`, 'POST', idToken, payload)
}
