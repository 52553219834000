import Description from "../../components/Description"
import { Image } from "../../components/Image"
import { VideoPlayer } from "../../components/VideoPlayer"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import * as Yup from "yup"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import { ENTERPRISE } from "./config"

const DEFAULT_VALUES = {
  isEdit: false,
  title: "",
  author: "",
  video_description: "",
  image_url: "",
  topics: []
}

const OnboardingTip = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: "title",
      label: "Blog Title",
      isFieldRow: true,
      required: true
    },
    {
      component: "video_description",
      label: "Blog Content",
      isFieldRow: true,
      rows: 21,
      required: true
    },
    {
      component: "image_url",
      label: "Blog Post Image (square image recommended)",
      isFieldRow: true,
      required: true,
      maxSize: 10000000
    },
    {
      component: "topics",
      label: "Topics/Listings",
      isFieldRow: true,
    },
  ]

  const schema = {
    title: validations.str_req_60,
    video_description: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    image_url: Yup.string().required("Required")
  };

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        video_description: initialValues?.description,
        active: initialValues?.active
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Blogs",
      resourceEndpoint: "tips",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=-ueiFVc4Iyc" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            Please add 1 short tip, post, or inspirational message for your App users (see images below). Try Pixabay.com or Unsplash.com for free commercial images.
          </Description>
        ) },
        { customComponent: (
          <Image
            src="https://ae-api.techtank.ca/media/3211b5c1-f988-4c8b-b8af-43631b4724f7"
            alt="Video Screenshot"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <Image
            src="https://ae-api.techtank.ca/media/65fa48d7-99cd-442d-b1b5-cf94c24030ef"
            alt="Video Screenshot"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Blog"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              const { isEdit, ...rest } = values

              const newPayload = {
                ...rest,
                description: values?.video_description,
                active: initialValues?.id ? values?.active : true,
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingTip;