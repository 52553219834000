import React from "react";

const ComingSoon = ({ customClasses = '' }) => {
  return (
    <div className={`font-semibold italic text-center text-aeblue text-lg ${String(customClasses)}`}>
      COMING SOON IN WEB
    </div>
  );
};

export default ComingSoon