import * as React from 'react'
import { useContext, useState } from 'react'
import BaseModal from '../components/BaseModal'
import LoadingAELogo from '../assets/images/ae-icon-loading.png'

export const LoaderProviderContext = React.createContext()

export const LoaderProvider = ({children}) => {
  const [showLoadingModal, setShowLoadingModal] = useState(false)

  const doSetShowLoadingModal = (isLoading) => {
    setShowLoadingModal(isLoading)
  };

  return(
    <LoaderProviderContext.Provider value={ { doSetShowLoadingModal } }>
      <BaseModal modalOpen={showLoadingModal} customContentStyle={{
         border: 'none',
         borderRadius: 0,
         backgroundColor: 'transparent',
         overflow: 'hidden'
      }}>
        <div className="flex flex-col w-full justify-center items-center overflow-hidden">
          <div className="relative w-52 h-52 mb-12">
            <div className="w-full h-full flex items-center justify-center">
              <img src={LoadingAELogo} className="object-contain w-20" alt="ACTIONERA logo"/>
            </div>
            <div className="absolute loader ease-linear rounded-full border-t-8 border-gray-200 h-52 w-52 top-0" />
          </div>
        </div>
      </BaseModal>
      {children}
    </LoaderProviderContext.Provider>
  )
}

export const useLoaderProvider = () => {
  const context = useContext(LoaderProviderContext)

  if (context === undefined) {
    throw new Error('Invalid use of LoaderProviderContext')
  }

  return context
}
