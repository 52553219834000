import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetCourseLesson = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/course_lessons/${uuid}`, 'GET', idToken)
}

export const UpdateCourseLesson = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/course_lessons/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteCourseLesson = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/course_lessons/${uuid}`, 'DELETE', idToken)
}

export const AddCourseLesson = async (course_uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/courses/${course_uuid}/course_lessons`, 'POST', idToken, payload)
}
