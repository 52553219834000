import { Radio } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataSelector from "../../DataSelector";
import { GetSendToTags } from "../../../services/api/push_notification";
import { useUserProvider } from "../../../providers/UserProvider";

const SendTo = ({
  formik,
  isSendToDisabled,
  hasNoLinkTo,
  data,
  setData
}) => {
  const [currentSelected, setCurrentSelected] = useState([])

  const { user } = useUserProvider()
  const {
    tags,
    members,
    devices,
    isEveryoneDisabled,
    isTagsDisabled,
    tagDevices,
    tagMembers,
  } = data

  const currentTags = useMemo(() => currentSelected?.map((item) => `send_to_tags[]=${item?.id}`)?.join("&") ?? [], [currentSelected])

  // should not be fetched on load
  const fetchUpdatedDevicesAndMembers = useCallback(() => {
    GetSendToTags(user.currentApp(), user.idToken, currentTags)
    .then((response) => {
      if (response.ok) return response.json()

      return {}
    }).then((response) => {
      setData((prevData) => {
        return {
          ...prevData,
          tagMembers: response?.data?.members,
          tagDevices: response?.data?.devices,
        }
      })
    })
  }, [currentTags, user])

  useEffect(() => {
    fetchUpdatedDevicesAndMembers()
  }, [currentTags])

  const pluralization = (prefix, suffix, count) => {
    if (Number(count) <= 1) return `${count} ${prefix}`

    return `${count} ${prefix}${suffix}`
  }

  return (
    <div>
      <div className={`flex flex-row items-center`}>
        <div className={`flex flex-row items-center w-1/3`}>
          <span className="font-bold w-20">Send to:</span>

          <Radio.Group
            onChange={(e) => {
              formik.setFieldValue("send_to", e.target.value);
            }}
            value={formik.values.send_to}
            className="flex flex-row"
            disabled={isSendToDisabled}
          >
            <Radio value="everyone" disabled={isEveryoneDisabled}>Everyone</Radio>
            <Radio value="tag" disabled={isTagsDisabled}>Tag</Radio>
          </Radio.Group>
        </div>

        <div
          className={`w-2/3 ${
            formik.values.send_to === "tag"
              ? "visible"
              : "hidden"
          }`}
        >
          <DataSelector
            name="send_to_tags"
            formik={formik}
            dataSet={tags}
            noMarginBottom
            setCurrentSelected={setCurrentSelected}
            disabled={isSendToDisabled || (isEveryoneDisabled && isTagsDisabled)}
          />
        </div>
      </div>
      {
        (!hasNoLinkTo) ? (
          (formik.values.send_to === 'everyone') ? (
            <>
              {
                isEveryoneDisabled ? (<div className="mb-5" />) : (
                  <span className="pl-28 italic">
                    {`Sending to ${pluralization('device', 's', Number(devices))} (${pluralization('member', 's', Number(members))})`}
                  </span>
                )
              }
            </>
          ) : (
            <>
              {
                isTagsDisabled ? (<div className="mb-5" />) : (
                  <div className="ml-auto text-right italic leading-8">
                    {`Sending to ${pluralization('device', 's', tagDevices ?? 0)} (${pluralization('member', 's', tagMembers ?? 0)})`}
                  </div>
                )
              }
            </>
          )
        ) : <div className="mb-5" />
      }
    </div>
  )
}

export default SendTo