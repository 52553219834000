import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetDirectory = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/directories/${uuid}`, 'GET', idToken)
}

export const UpdateDirectory = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/directories/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteDirectory = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/directories/${uuid}`, 'DELETE', idToken)
}
