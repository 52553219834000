const actionListConfig = {
  label: "ActionList",
  resourceEndpoint: "action_lists",
  listMetadata: [
    "sort_order",
    "title",
    "frequency",
    "featured",
    "active",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "title", label: "Title" },
    {
      name: "description",
      label: "Description",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "short_description",
      label: "Short Description",
    },
    { name: "video_url", label: "Video URL" },
    { name: "image_url", label: "Image URL" },
    {
      name: "action_bar_text",
      label: "Call to Action Text",
      validations: [
        {
          type: "max",
          params: [40, "Maximum 40 Characters"],
        },
      ],
    },
    { name: "action_bar_url", label: "Call to Action URL" },
    {
      name: "cost",
      label: "Action List Cost",
      validations: [
        {
          type: "max",
          params: [20, "Maximum 20 Characters"],
        },
      ],
    },
    {
      name: "frequency",
      label: "Frequency",
      validations: [{ type: "required", params: ["Required"] }],
    },
    {
      name: "tagged_visible",
      label: "If tagged, allow all users to register/ purchase this List",
    },
    {
      name: "custom_frequency",
      label: "Custom Frequency",
      conditions: [
        {
          relativeField: "frequency",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === "Custom";
          },
        },
      ],
    },
    {
      name: "sort_order",
      label: "Sort Order",
    },
    {
      name: "active",
      label: "Active",
      width: "1/2",
    },
    {
      name: "featured",
      label: "Featured?",
      width: "1/2",
    },
    {
      name: "journal_list",
      label: "Add Journal to List",
    },
    {
      name: "question_1",
      label: "Journal 1",
      conditions: [
        {
          relativeField: "journal_list",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === true;
          },
        },
      ],
    },
    {
      name: "question_2",
      label: "Journal 2",
      conditions: [
        {
          relativeField: "journal_list",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === true;
          },
        },
      ],
    },
    {
      name: "question_3",
      label: "Journal 3",
      conditions: [
        {
          relativeField: "journal_list",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === true;
          },
        },
      ],
    },
    {
      name: "question_4",
      label: "Journal 4",
      conditions: [
        {
          relativeField: "journal_list",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === true;
          },
        },
      ],
    },
    {
      name: "question_5",
      label: "Journal 5",
      conditions: [
        {
          relativeField: "journal_list",
          comparisonFunc: (relativeField, thisField) => {
            return relativeField.value === true;
          },
        },
      ],
    },
    { name: "tags", label: "Tags" },
    { name: "topics", label: "Topics/Listings" },
    { name: "items", label: "Items" },
  ],
  formLayout: {
    columns: [
      [
        "title",
        "description",
        "short_description",
        "video_url",
        "image_url",

        "journal_list",
        "question_1",
        "question_2",
        "question_3",
        "question_4",
        "question_5",
      ],
      [
        "frequency",
        "custom_frequency",
        "sort_order",
        "tags",
        "tagged_visible",
        "action_bar_text",
        "action_bar_url",
        "cost",
        "topics",
        "active",
        "featured",
      ],
    ],
  },
  subTables: {
    items: {
      label: {
        plural: "list items",
        singular: "list item",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "sort_order",
          label: "Sort Order",
          parser: (data) => data.sort_order,
        },
        { name: "text", label: "Item Text", parser: (data) => data.text },
        {
          name: "description",
          label: "Description",
          parser: (data) => data.description,
          ellipsis: true,
        },
      ],
      detailsMetadata: [
        {
          name: "sort_order",
          label: "Sort Order",
        },
        {
          name: "text",
          label: "Item Text",
          validations: [
            {
              type: "required",
              params: ["Required"],
            },
            {
              type: "max",
              params: [100, "Maximum 100 Characters"],
            },
          ],
        },
        {
          name: "has_details",
          label: "Explain List Item",
        },
        {
          name: "description",
          label: "Item Description",
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        {
          name: "image_url",
          label: "Image URL",
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        {
          name: "video_url",
          label: "Video URL",
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        {
          name: "audio_url",
          label: "Audio URL",
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        {
          name: "action_bar_text",
          label: "Call to Action Text",
          validations: [
            {
              type: "max",
              params: [40, "Maximum 40 Characters"],
            },
          ],
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        {
          name: "action_bar_url",
          label: "Call to Action URL",
          conditions: [
            {
              relativeField: "has_details",
              comparisonFunc: (relativeField, thisField) => {
                return relativeField.value === true;
              },
            },
          ],
        },
        { name: "media_metadata", label: "media_metadata", hidden: true },
      ],
    },
  },
};

export default actionListConfig;
