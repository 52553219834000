import { Fragment } from 'react'

const DeleteModal = ({ itemId, itemLabel, onDelete, onClose, deleteAction, delBtnLabel, itemIndex }) => {

  const label = deleteAction || 'delete'
  const btnLabel = delBtnLabel || 'Delete'

  return(
    <Fragment>
      <div>Are you sure you want to {label} this {itemLabel}?</div>
      <div className="flex mt-6">
        <div className="ml-auto mr-4">
          <button className="ttnk-button add-button" onClick={() => onDelete(itemId, itemIndex)}>{ btnLabel }</button>
        </div>
        <div className="mr-auto">
          <button className="ttnk-button add-button" onClick={() => onClose()}>Cancel</button>
        </div>
      </div>
    </Fragment>
  )
}

export default DeleteModal
