import React from "react";
import { useDrawerProvider } from "../../providers/DrawerProvider";
import { useUserProvider } from "../../providers/UserProvider";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import SlideOut from "../SlideOut";

const BuildAndSubmitSlideOutButton = ({ type, title, btnLabel }) => {
  const { showDrawer, closeDrawer } = useDrawerProvider();
  const { user } = useUserProvider();
  const { onboardingData } = useOnboardingProvider()

  return (
    <button
      className={`ttnk-button text-white bg-darkGray text-2xl`}
      style={{ backgroundImage: "none", width: 128 }}
      type="submit"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        showDrawer(
          <SlideOut
            type={type}
            showSubmitBtn={false}
            handleSubmit={closeDrawer}
            data={{
              ...user,
              onboarding: onboardingData
            }}
          />,
          type,
          "50vw",
          "text-center w-full"
        );
      }}
    >
      {btnLabel}
    </button>
  )
}

export default BuildAndSubmitSlideOutButton;