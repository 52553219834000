import React from "react";
import OnboardingWrapper from "../components/OnboardingWrapper";
import Accordion from "../components/accordion";
import {
  onboardingAccordionConfig,
  enterpriseOnboardingAccordionConfig,
  ENTERPRISE,
  UPLOAD_CONTENT,
  APP_DESIGN,
  APP_STORE,
  SUBMITTED,
  SAVED,
  IN_PROGRESS,
  superAdminOnboardingAccordionConfig,
  DEVELOPER_ACCOUNTS,
  HOME_PROFILE,
  BUILD_AND_SUBMIT,
  LAUNCHED
} from "../lib/onboarding_config/config";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { useUserProvider } from "../providers/UserProvider";

const Onboarding = () => {
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const config = [
    ...(user?.isSuper && onboardingData?.type !== ENTERPRISE ? superAdminOnboardingAccordionConfig : []),
    ...(onboardingData?.type === ENTERPRISE
      ? enterpriseOnboardingAccordionConfig
      : onboardingAccordionConfig),
  ];

  return (
    <div>
      <OnboardingWrapper>
        <div className="mt-10">
          {config.map((section, index) => {
            const sectionConfig = { ...section, is_complete: false };

            if (sectionConfig?.id === BUILD_AND_SUBMIT
              && [SUBMITTED, LAUNCHED].includes(onboardingData?.progress?.build_and_submit?.status)) sectionConfig.is_complete = true

            if (
              sectionConfig?.id === UPLOAD_CONTENT &&
              onboardingData?.progress?.content?.overall?.percentage >= 100
            )
              sectionConfig.is_complete = true;

            if (sectionConfig?.id === HOME_PROFILE
              && [SUBMITTED].includes(onboardingData?.progress?.home_profile?.status)) sectionConfig.is_complete = true

            if (sectionConfig?.id === APP_DESIGN
              && [SUBMITTED, SAVED].includes(onboardingData?.progress?.design?.status)) sectionConfig.is_complete = true

            if (sectionConfig?.id === APP_STORE) {
              sectionConfig.is_complete = [SUBMITTED, SAVED].includes(
                onboardingData?.progress?.store?.status
              );
              sectionConfig.hasEditLabel =
                onboardingData?.progress?.store?.status === IN_PROGRESS;
              sectionConfig.is_disabled = ![SUBMITTED, SAVED].includes(
                onboardingData?.progress?.design?.status
              );
            }

            if (section?.id === DEVELOPER_ACCOUNTS) {
              sectionConfig.is_disabled =
                ![SUBMITTED, SAVED].includes(
                  onboardingData?.progress?.design?.status
                ) ||
                !(
                  onboardingData?.progress?.content?.overall?.percentage >= 100
                ) ||
                ![SUBMITTED, SAVED].includes(
                  onboardingData?.progress?.store?.status
                );

              if (onboardingData?.progress?.developer?.status === SUBMITTED) {
                sectionConfig.is_complete = true;
              }
            }

            return (
              <Accordion
                item={sectionConfig}
                key={sectionConfig.id}
                value={sectionConfig.is_complete}
                index={index}
                indexLength={config?.length}
              />
            );
          })}
        </div>
      </OnboardingWrapper>
    </div>
  );
};

export default Onboarding;
