import { Fragment, useEffect, useState } from "react";
import { EntryForm } from "../EntryForm"
import { useDrawerProvider } from "../../providers/DrawerProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFieldComponent } from "../../lib/form";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { useLoaderProvider } from "../../providers/LoaderProvider";

const OnboardingContent = ({ title, fields, isReadOnly, defaultValues, handleSubmit, schema, handleFormatPayload }) => {
  const [initialValues, setInitialValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { closeDrawer } = useDrawerProvider();
  const { setRefetchContent, setRefetchCounter } = useOnboardingProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  useEffect(() => {
    setInitialValues(defaultValues);
  }, [defaultValues]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: () => {
      return Yup.lazy((values) => {
        let currentSchema = schema

        // additional schema validation for home screen
        if (title.toLowerCase() === 'home') {
          if (values?.welcome_media_type === "image") {
            const { video_url, ...rest } = currentSchema;

            currentSchema = {
              ...rest,
              image_url: Yup.string().required("Required")
            }
          } else {
            const { image_url, ...rest } = currentSchema;

            currentSchema = {
              ...rest,
              video_url: Yup.string().required("Required"),
            }
          }
        }

        return Yup.object().shape(currentSchema);
      });
    },
    onSubmit: async (values) => {
      if (typeof handleSubmit === 'function') {
        setIsLoading(true)
        const newPayload = handleFormatPayload(values)

        handleSubmit({
          payload: newPayload,
          successCallbackFn: () => {
            formik.resetForm();
            setInitialValues(defaultValues);
            doSetShowLoadingModal(true);
            setRefetchContent(true);
            setRefetchCounter(true)
            setIsLoading(false);
            closeDrawer();
          },
          errorCallBackFn: () => {
            setIsLoading(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.will_use_business_name && title.toLowerCase() === 'profile') {
      formik.setFieldValue('profile_name', formik.values.business_name)
    }
  }, [formik.values])

  return (
    <div>
      <div className="w-full py-4">
        <div className="py-4 w-full">
          <EntryForm
            isLoading={isLoading}
            submitHandler={formik.handleSubmit}
            formik={formik}
            isReadOnly={isReadOnly}
            isFullRow
            floatSubmitBtnRight
            submitLabel="Save"
            cancelHandler={() => {
              formik.resetForm()
              closeDrawer()
            }}
          >
            {
              fields?.map((field, index) => {
                return (
                  <Fragment key={String(index)}>
                    {
                      getFieldComponent(field?.component, {
                        formField: field?.field,
                        label: field?.label,
                        formik,
                        ...field
                      })
                    }
                  </Fragment>
                )
              })
            }
          </EntryForm>
        </div>
      </div>
    </div>
  )
}

export default OnboardingContent