export const sectionList = [
  {
    name: 'Account Profile Screen',
    value: 'profile'
  },
  {
    name: 'ActionBlogs List',
    value: 'tips'
  },
  {
    name: 'ActionClips List',
    value: 'actionclips'
  },
  {
    name: 'ActionLists List',
    value: 'actionlists'
  },
  {
    name: 'Calendar - In-Person',
    value: 'personevent'
  },
  {
    name: 'Calendar - Online',
    value: 'onlineevent'
  },
  {
    name: 'Directories List',
    value: 'directories'
  },
  {
    name: 'Courses List',
    value: 'courses'
  },
  {
    name: 'Galleries List',
    value: 'galleries'
  },
  {
    name: 'Member Content Screen',
    value: 'membercontent'
  },
  {
    name: 'Member Only ActionLists Screen',
    value: 'member_actionlists'
  },
  {
    name: 'Messages / Updates Screen',
    value: 'messages'
  },
  {
    name: 'Podcasts List',
    value: 'podcasts'
  },
  {
    name: 'Register Screen',
    value: 'register'
  },
  {
    name: 'Topics List',
    value: 'topics'
  },
  {
    name: 'Track Now Screen',
    value: 'track'
  },
  {
    name: 'Summits List',
    value: 'summits'
  },
  {
    name: 'Virtual Events List',
    value: 'virtualevents'
  },
]
