import React, { useCallback } from 'react';

const Button = ({
  className,
  children,
  link,
  onClick,
  isDisabled = false,
  type,
  isOpenNewTab
}) => {
  const handleClick = useCallback(() => {
    if(link)
      if (isOpenNewTab) window.open(`${link}`, '_blank');
      else window.location.href = link || '';
  }, [link, isOpenNewTab]);

  return (
    <button
      disabled={isDisabled}
      className={`${className ?? 'ttnk-button uppercase text-[22px] tracking-wide'}`}
      type={type || 'submit'}
      onClick={onClick || handleClick}
    >
      <div className="ml-1 ttnk-AvenirNextBold">{children !== undefined && children !== null ? children : null}</div>
    </button>
  );
};

export default Button;