import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "../FileUploader";
import { GetApp, UpdateApp } from "../../services/api/app";
import { useUserProvider } from "../../providers/UserProvider";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import responseProcessor from "../../lib/responseProcessor";
import { useModalProvider } from "../../providers/ModalProvider";
import { useLoaderProvider } from "../../providers/LoaderProvider";
import { colors, SCREENS_CREATED } from "./OnboardingBuildAndSubmit";
import { FaCheck } from "react-icons/fa";
import { EntryForm } from "../EntryForm";

const ScreensCreated = ({ getIsCompleted }) => {
  const [initialValues, setInitialValues] = useState({
    screens_file: null,
    media_metadata: null,
  })

  const { user } = useUserProvider()
  const { onboardingData, setOnboardingData } = useOnboardingProvider()
  const { showModal } = useModalProvider()
  const { doSetShowLoadingModal } = useLoaderProvider()

  const resetForm = useCallback(() => {
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setInitialValues({
          screens_file: res?.data?.onboarding?.screens_file,
          media_metadata: res?.data?.onboarding?.media_metadata,
        });
      });
  }, [])

  useEffect(() => {
    resetForm();
  }, [])

  const handleSubmit = (value) => {
    const payload = {
      onboarding: {
        ...onboardingData,
        screens_file: value
      }
    }

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: (response) => {
            if (value === null) {
              setOnboardingData(response?.data?.onboarding);
              formik.setFieldError("screens_file", "Required");
            }
            else {
              setOnboardingData(response?.data?.onboarding);
              setInitialValues({
                screens_file: response?.data?.onboarding?.screens_file,
                media_metadata: response?.data?.onboarding?.media_metadata,
              });
            }
            doSetShowLoadingModal(false);
          }
        })
    );
  }

  const schema = {
    screens_file: Yup.string().transform((value) => (value ? value.trim() : value)).min(1, "Required").required("Required"),
  }

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues,
    validationSchema: Yup.object().shape(schema),
    onSubmit: handleSubmit,
  });

  return (
    <EntryForm
      formik={formik}
      hideSubmit={true}
      removeStyle={true}
      submitHandler={formik.handleSubmit}
    >
      <div className="flex flex-row justify-between items-center p-2 w-full">
        <div className="w-full flex flex-row justify-start items-center">
          <button
            type="button"
            className={`p-2 cursor-not-allowed border-3 rounded`}
            style={{
              background: getIsCompleted({ id: SCREENS_CREATED })
                ? colors.dark
                : "#FFFFFF",
              borderColor: colors.dark,
            }}
          >
            <div
              className="rounded-lg"
              style={{
                borderColor: getIsCompleted({ id: SCREENS_CREATED })
                  ? colors.dark
                  : "#FFFFFF",
                backgroundColor: getIsCompleted({ id: SCREENS_CREATED })
                  ? colors.dark
                  : "#FFFFFF",
              }}
            >
              <FaCheck className={`text-white font-bold rounded-lg h-3 w-3`} />
            </div>
          </button>

          <span className={`line-clamp-2 text-xl font-bold ml-2 text-2xl`}>
            {SCREENS_CREATED}
          </span>
        </div>

        <FileUploader
          key={`screens_file`}
          showLabel={false}
          name="screens_file"
          required={true}
          errorMessage="You can only upload a .zip file"
          acceptedTypes=".zip"
          fileTypes="application/zip, application/x-zip-compressed, application/octet-stream"
          formik={formik}
          value={formik.values.screens_file}
          isButton
          showFilePicker={!formik.values.screens_file}
          successCallback={handleSubmit}
          shouldShowErrorMessages={false}
          onRemove={() => handleSubmit(null)}
          showButton={formik.values.screens_file?.length === 0}
        />

      </div>

      { (formik.errors.screens_file) && <p className="w-full text-red-600 mt-1 text-sm flex justify-end">{formik.errors.screens_file}</p> }
    </EntryForm>
  )
}

export default ScreensCreated;