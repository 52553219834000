import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetProfile = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/profiles/${uuid}`, 'GET', idToken)
}

export const UpdateProfile = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/profiles/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteProfile = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/profiles/${uuid}`, 'DELETE', idToken)
}
