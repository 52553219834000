import { useEffect, useState } from "react"
import { useUserProvider } from "../../providers/UserProvider"
import { GetApp } from "../../services/api/app"
import Description from "../../components/Description"
import { VideoPlayer } from "../../components/VideoPlayer"
import ImageSlideout from '../../assets/images/onboarding-listing.jpeg'
import { Image } from "../../components/Image"
import { validations } from "../form"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { SUBMITTED } from "./config"

const OnboardingListing = ({ handleSubmit }) => {
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [initialValues, setInitialValues] = useState({
    app_name: "",
    short_app_description: "",
    full_app_description: "",
    primary_category: "",
    secondary_category: "",
    search_keywords: "",
    in_app_name: "",
    app_icon_name: "",
    support_contact: "Use my current website",
    support_contact_page: "",
    support_email: "",
    privacy_policy: "Use my current website",
    privacy_policy_page: "",
    privacy_policy_document: "",
    i_understand: false,
  });

  useEffect(() => {
    GetApp(user, user.idToken).then((res) => res.json()).then((res) => {
      const app_store = res?.data?.about?.store;
      setIsReadOnly(res?.data?.onboarding?.progress?.store?.status === SUBMITTED && !user?.isSuper);
      setInitialValues({
        app_name: res?.data?.name,
        short_app_description: app_store?.short_app_description,
        full_app_description: app_store?.full_app_description,
        primary_category: app_store?.primary_category,
        secondary_category: app_store?.secondary_category,
        search_keywords: app_store?.search_keywords,
        in_app_name: app_store?.in_app_name,
        app_icon_name: app_store?.app_icon_name,
        support_contact: app_store?.support_contact ?? 'Use my current website',
        support_contact_page: app_store?.support_contact_page,
        support_email: app_store?.support_email,
        privacy_policy: app_store?.privacy_policy ?? 'Use my current website',
        privacy_policy_page: app_store?.privacy_policy_page,
        privacy_policy_document: app_store?.privacy_policy_document,
        i_understand: app_store?.i_understand
      });
    })
  }, [])
  
  const fields = [
    {
      component: "app_name",
      label: "App Name",
      isFieldRow: true,
    },
    {
      component: "short_app_description",
      label: "Short App Description",
      isFieldRow: true,
      required: true,
      placeholder: "The first text users see when looking at your App\’s details page",
      disabled: isReadOnly
    },
    {
      component: "full_app_description",
      label: "Full App Description",
      isFieldRow: true,
      rows: 3,
      required: true,
      placeholder: "Your App Description (focus on your users and what they get from your app)",
      disabled: isReadOnly
    },
    {
      component: "primary_category",
      label: "Primary Category",
      isFieldRow: true,
      required: true,
      disabled: isReadOnly
    },
    {
      component: "secondary_category",
      label: "Secondary Category",
      isFieldRow: true,
      required: true,
      disabled: isReadOnly
    },
    {
      component: "search_keywords",
      isFieldRow: false,
      required: true,
      placeholder: "Add 5-7 Keywords or key phrases, and separate each with a comma",
      disabled: isReadOnly
    },
    {
      component: "search_keywords_note",
    }
  ]

  const schema = {
    short_app_description: validations.str_req_30,
    full_app_description: validations.str_req_blob,
    search_keywords: validations.str_req_100,
    primary_category: validations.str_req_blob,
    secondary_category: validations.str_req_blob
  };

  return {
    config: {
      label: "Onboarding App Store - Info",
      columnsMetadata: [
        { customComponent: (
          <div className="w-4/5 mx-auto mt-5">
            <VideoPlayer url="https://www.youtube.com/watch?v=JP-8T6rBg2s" />
          </div>
        ) },
        { customComponent: (
          <Description wrapperClass="py-4 mt-5" className="text-lg">
            This is the info that will display in your Android/Apple App Store listing (see image below) 
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Listing"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Listing"
            defaultValues={initialValues}
            schema={schema}
            fields={fields}
            isReadOnly={isReadOnly}
            handleSubmit={(handleSubmit)}
            handleFormatPayload={(values) => {
              const newPayload = {
                about: {
                  store: {
                    short_app_description: values?.short_app_description,
                    full_app_description: values?.full_app_description,
                    primary_category: values?.primary_category,
                    secondary_category: values?.secondary_category,
                    search_keywords: values?.search_keywords,
                    in_app_name: values?.in_app_name,
                    app_icon_name: values?.app_icon_name,
                    support_contact: values?.support_contact,
                    support_contact_page: values?.support_contact_page,
                    support_email: values?.support_email,
                    privacy_policy: values?.privacy_policy,
                    privacy_policy_page: values?.privacy_policy_page,
                    privacy_policy_document: values?.privacy_policy_document
                  }
                },
                onboarding: {
                  ...onboardingData,
                  progress: {
                    ...onboardingData?.progress,
                    store: {
                      status: 'In Progress'
                    }
                  }
                }
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingListing;