import React, { useMemo } from "react";
import { useUserProvider } from "../../providers/UserProvider";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { UpdateApp } from "../../services/api/app";
import {
  BOTH,
  DONE,
  ENTERPRISE,
  LAUNCHED,
  READY,
} from "../../lib/onboarding_config/config";
import responseProcessor from "../../lib/responseProcessor";
import { useModalProvider } from "../../providers/ModalProvider";
import AdminLaunchAppConfirmationModal from "../modal_contents/AdminLaunchAppConfirmationModal/AdminLaunchAppConfirmationModal";
import ScreensCreated from "./ScreensCreated";
import ContentReviewed from "./ContentReviewed";
import AppDesigned from "./AppDesigned";
import AppBuilt from "./AppBuilt";
import DeveloperAccountSetup from "./DeveloperAccountSetup";
import StoreListingSetup from "./StoreListingSetup";
import Submitted from "./Submitted";
import Approved from "./Approved";
import FinalInfo from "./FinalInfo";
import { useLoaderProvider } from "../../providers/LoaderProvider";

export const APP_DESIGNED = "App Designed";
export const CONTENT_REVIEWED = "Content Reviewed";
export const APP_BUILT = "App Built";
export const SCREENS_CREATED = "Screens Created";
export const QR_CREATED = "Developer Account Setup";
export const STORE_LISTING_SETUP = "Store Listing Setup";
export const SUBMITTED = "Submitted";
export const APPROVED = "Approved";
export const FINAL_INFO = "Final Info";

export const formatDataByKey = {
  [APP_DESIGNED]: "app_designed",
  [APP_BUILT]: "app_built",
  [CONTENT_REVIEWED]: "content_reviewed",
  [SCREENS_CREATED]: "screens_created",
  [QR_CREATED]: "qr_created",
  [STORE_LISTING_SETUP]: "store_listing_setup",
  [SUBMITTED]: "submitted",
  [APPROVED]: "approved",
  [FINAL_INFO]: "final_info",
};

export const statusDropdownOptionsByType = {
  [CONTENT_REVIEWED]: {
    options: [
      {
        value: "Get Started",
        label: "Get Started",
      },
      {
        value: "In Progress",
        label: "In Progress",
      },
      {
        value: "Done",
        label: "Done",
      },
    ],
  },
  [STORE_LISTING_SETUP]: {
    options: [
      {
        value: "Get Started",
        label: "Get Started",
      },
      {
        value: "In Progress",
        label: "In Progress",
      },
      {
        value: "Done",
        label: "Done",
      },
    ],
  },
  [SUBMITTED]: {
    options: [
      {
        value: null,
        label: "-select-",
      },
      {
        value: "apple",
        label: "Apple",
      },
      {
        value: "android",
        label: "Android",
      },
      {
        value: "both",
        label: "BOTH",
      },
    ],
  },
  [APPROVED]: {
    options: [
      {
        value: null,
        label: "-select-",
      },
      {
        value: "apple",
        label: "Apple",
      },
      {
        value: "android",
        label: "Android",
      },
      {
        value: "both",
        label: "BOTH",
      },
    ],
  },
};

export const colors = {
  dark: "#2650A0",
  medium: "#939799",
};

const OnboardingBuildAndSubmit = () => {
  const { user } = useUserProvider();
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { showModal, closeModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  const handleOnConfirm = () => {
    doSetShowLoadingModal(true);

    const payload = {
      onboarding: {
        ...onboardingData,
        status: LAUNCHED,
        ...(onboardingData?.type !== ENTERPRISE && { confetti: true }),
        progress: {
          ...onboardingData?.progress,
          build_and_submit: {
            ...onboardingData?.progress?.build_and_submit,
            status: LAUNCHED,
          },
        },
      },
    };

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: (response) => {
            if (response?.data?.onboarding) setOnboardingData(response?.data?.onboarding);
            closeModal();
            doSetShowLoadingModal(false);
          },
        }),
    );
  };

  const handleSubmit = () => {
    showModal(<AdminLaunchAppConfirmationModal onConfirm={handleOnConfirm} />);
  };

  const getIsCompleted = (item) => {
    const key = formatDataByKey[item.id];

    if ([FINAL_INFO, QR_CREATED, SCREENS_CREATED]?.includes(item.id)) {
      return onboardingData?.[key];
    }

    if ([SUBMITTED, APPROVED]?.includes(item.id)) {
      return (onboardingData?.[key] || "").toLowerCase() === BOTH;
    }

    if (key) {
      return (onboardingData?.[key] || "").toLowerCase() === DONE;
    }

    return item?.is_complete ?? false;
  };

  const isEnabled = useMemo(() => ![LAUNCHED].includes(onboardingData?.progress?.build_and_submit?.status)
    && onboardingData?.build_and_submit === READY, [onboardingData]);

  return (
    <div className="px-5">
      <div>
        <AppDesigned getIsCompleted={getIsCompleted} />
        <ContentReviewed getIsCompleted={getIsCompleted} />
        <AppBuilt getIsCompleted={getIsCompleted} />
        <ScreensCreated getIsCompleted={getIsCompleted} />
        <DeveloperAccountSetup getIsCompleted={getIsCompleted} />
        <StoreListingSetup getIsCompleted={getIsCompleted} />
        <Submitted getIsCompleted={getIsCompleted} />
        <Approved getIsCompleted={getIsCompleted} />
        <FinalInfo getIsCompleted={getIsCompleted} />
      </div>


      <div className="flex flex-row items-center justify-end w-full pt-8 font-bold text-darkGray text-2xl">
        <button
          className={`ttnk-button font-bold text-darkGray text-2xl w-160px`}
          type="submit"
          onClick={handleSubmit}
          disabled={!isEnabled}
        >
          LAUNCH
        </button>
      </div>
    </div>
  );
};

export default OnboardingBuildAndSubmit;
