import React from 'react'
import AnswerIcon from '../assets/icons/Answer_Icon.svg'
import AudioIcon from '../assets/icons/Audio_Icon.svg'
import HeadlineIcon from '../assets/icons/Headline_Icon.svg'
import ImageIcon from '../assets/icons/Image_Icon.svg'
import JournalIcon from '../assets/icons/Journal_Icon.svg'
import TextIcon from '../assets/icons/Text_Icon.svg'
import TitleIcon from '../assets/icons/Title_Icon.svg'
import QuestionIcon from '../assets/icons/QA_Icon.svg'
import VideoIcon from '../assets/icons/Video_Icon.svg'
import DocumentIcon from '../assets/icons/attached.svg'
import LinkIcon from '../assets/icons/connection.svg'
import { IsWithinMinimumVersion, IsSameVersion } from "../lib/useVersionizer";

const fields = [
	{name: 'headline', label: "Headline", icon: HeadlineIcon},
	{name: 'title', label: "Title", icon: TitleIcon, size: 24},
	{name: 'image_url', label: "Image", icon: ImageIcon},
	{name: 'text', label: "Text", icon: TextIcon},
	{name: 'video_url', label: "Video", icon: VideoIcon},
	{name: 'audio_url', label: "Audio", icon: AudioIcon},
	{name: 'question_1', label: "Journal", icon: JournalIcon},
	{name: 'document_section_url', label: "Document", icon: DocumentIcon},
	{name: 'link_url', label: "Link", icon: LinkIcon},
	{name: 'question', label: "Q&A", icon: QuestionIcon},
]

const IconLabel = ({icon, label, size = 32, active, name, doSelectCustomFields}) => (
	<div className={`ttnk-field-selector ${active ? 'ttnk-field-selector-active' : ''}`} onClick={() => {
		doSelectCustomFields(name)
	}}>
		<img className="mb-4" width={size} height={size} src={icon} />
		<span className="text-sm">{label}</span>
	</div>
)

const CustomFieldSelector = ({displayedFields, doSelectCustomFields, createScreen}) => {
	const isMin8P5Version = IsWithinMinimumVersion(8.5)
	const is8P5Version = IsSameVersion(8.5)

	const displayed = displayedFields?.filter(d => d?.name === 'question' || d?.name === 'answer' || !d.hasOwnProperty('validations') || d?.name.includes('document_') || d?.name.includes('link_'));
	let customFields = fields;

	if (displayed?.length) {
		customFields = fields?.map(f => ({...f, active: Boolean(displayed?.find(d => d?.name === f?.name))}))
	}

	return (
		<div className="p-10">
			<h1 className="text-4xl uppercase m-0 text-white text-center font-black mt-10">{createScreen ? 'Add' : 'Update'} Section</h1>
			<p className="p-0 text-sm text-justify">Click the icons below to add new content segments for this new section. To remove a segment, simply click the icon again.</p>
			<div className="flex flex-wrap gap-3 my-10 mx-6 justify-start">
				{customFields.map(field => {
					if(
						!isMin8P5Version &&
						(field.name === "document_section_url" || field.name === "link_url"))
					{
						return false;
					}

					return (
						<IconLabel
						key={`${field.name}-${field?.label}`}
						icon={field?.icon}
						label={field?.label}
						size={field?.size}
						active={field?.active}
						name={field?.name}
						doSelectCustomFields={doSelectCustomFields}
					/>
					)		
				})}
			</div>
			{ is8P5Version && <p className="text-white text-sm italic mt-3">Document and Link available in app Platform Version 9.0 & up and WEB</p> }
		</div>
	)
}

export default CustomFieldSelector