import { useEffect, useState } from 'react';
import { Table } from 'antd';
import Fuse from 'fuse.js';

import Search from '../assets/icons/search.svg';
import CrossIcon from '../assets/icons/cross-sign.svg';

const DataTable = ({
  removeContainerStyle,
  metadata,
  dataLoader,
  data,
  contentType,
  selectedRowKeys,
  setSelectedRowKeys,
  searchKeys,
  threshold = 0.3,
}) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dataLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSearchValue('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const columns = metadata
    .map((item) => {
      return {
        title: item.label,
        dataIndex: item.name,
        ellipsis: item.ellipsis,
        sorter: item.sorter,
        sortDirections: item.sortDirections,
        defaultSortOrder: item.defaultSortOrder,
        align: item.align,
        width: item.width,
        render(text, record) {
          if (
            item.name === 'event_sales_headline' ||
            item.name === 'registration_url' ||
            item.name === 'event_enrolled_headline'
          ) {
            return {
              children: (
                <div style={{ maxWidth: 150, whiteSpace: 'pre-wrap' }}>
                  {text}
                </div>
              ),
            };
          }

          return text;
        },
      };
    })
    .filter((i) => i.dataIndex !== 'key');

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      //{
      //  key: 'odd',
      //  text: 'Select Odd Row',
      //  onSelect: changableRowKeys => {
      //    let newSelectedRowKeys = [];
      //    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
      //      if (index % 2 !== 0) {
      //        return false;
      //      }
      //      return true;
      //    });
      //    setSelectedRowKeys(newSelectedRowKeys);
      //  },
      //},
      //{
      //  key: 'even',
      //  text: 'Select Even Row',
      //  onSelect: changableRowKeys => {
      //    let newSelectedRowKeys = [];
      //    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
      //      if (index % 2 !== 0) {
      //        return true;
      //      }
      //      return false;
      //    });
      //    setSelectedRowKeys(newSelectedRowKeys);
      //  },
      //},
    ],
  };

  const dataParser = (data) => {
    let _data = [];
    if (_data.error) _data = [];
    for (let i = 0; i < data?.length; i++) {
      _data.push(
        metadata.reduce((map, attr) => {
          map[attr.name] = attr.parser(data[i]);
          return map;
        }, {})
      );
    }

    if (searchKeys && searchValue) {
      const returnData = new Fuse(_data, {
        keys: searchKeys,
        threshold,
      });
      return returnData.search(searchValue).map((d) => d.item);
    } else {
      return _data;
    }
  };

  const SearchBox = () => (
    <div className="border border-gray-200 flex h-8 rounded-full flex justify-center items-center">
      <img src={Search} className="w-4 h-4 ml-4" alt="" />
      <input
        id="search"
        name="search"
        type="text"
        value={searchValue}
        className="text-sm px-2 rounded-full focus:outline-none"
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      {searchValue && (
        <img
          src={CrossIcon}
          className="w-3 h-4 mr-4 opacity-30"
          alt="clear search input"
          onClick={() => setSearchValue('')}
        />
      )}
    </div>
  );

  return (
    <div
      className={
        removeContainerStyle ? '' : 'bg-white p-5 mt-5 ttnk-table-container'
      }
    >
      <div className="flex justify-between items-center">
        <label className="m-3 font-semibold text-gray-400">
          Showing {dataParser(data.data).length} result
          {dataParser(data.data).length > 1 ? 's' : ''}
        </label>
        {data.count > 0 && searchKeys && SearchBox()}
      </div>
      <Table
        className="ttnk-table"
        pagination={false}
        columns={columns}
        loading={data.loading}
        dataSource={dataParser(data.data)}
      />
    </div>
  );
};

export default DataTable;
