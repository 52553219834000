import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetOffer = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/offers/${uuid}`, 'GET', idToken)
}

export const UpdateOffer = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/offers/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteOffer = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/offers/${uuid}`, 'DELETE', idToken)
}
