import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
} from "../components/EntryForm";
import Page from "../components/Page";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";
import { AddGlobalField, GetGlobalField, UpdateGlobalField } from "../services/api/globalFeilds";

const GlobalFieldsDetail = ({ newFields, match }) => {

  const [initialValues, setInitialValues] = useState({
    field_name: "",
    android_value: "",
    ios_value: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const history = useHistory();

  const uuid = newFields ? user.currentApp() : match?.params?.uuid;

  useEffect(() => {
    if (newFields) {
      setInitialValues({
        field_name: "",
        android_value: "",
        ios_value: ""
      });
    } else {
      setIsLoading(true);
      GetGlobalField(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            field_name: res?.data?.field_name ?? "",
            android_value: res?.data?.android_value ?? "",
            ios_value: res?.data?.ios_value ?? ""
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newFields ? "New" : "Edit";
  const currentUrl = newFields ? "/global-fields/new" : `/global-fields/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Global Fields List", url: "/global-fields" },
    { name: `${currentAction} Global Field`, url: currentUrl },
  ];

  let schema = {
    field_name: Yup.string().max(60, "Maximum 60 Characters").required("Required"),
    android_value: Yup.string().max(65535, "Maximum 65,535 Characters"),
    ios_value: Yup.string().max(65535, "Maximum 65,535 Characters"),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {

      if (newFields) {
        AddGlobalField(uuid, user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateGlobalField(uuid, user.idToken, { data: values }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/global-fields"),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Global Fields`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/global-fields"
      >
        <Column>
          <Input name="field_name" type="input" label="Name" formik={formik} />
          <Input name="android_value" type="input" label="Android" formik={formik} />
        </Column>
        <Column>
          <div className="mb-5" style={{ height: 69.47}} />
          <Input name="ios_value" type="input" label="iOS" formik={formik} />
        </Column>
      </EntryForm>
    </Page>
  );
};

export default GlobalFieldsDetail;
