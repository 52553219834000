import React from "react";
import {
  colors,
  QR_CREATED,
} from "./OnboardingBuildAndSubmit";
import { FaCheck } from "react-icons/fa";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { useLoaderProvider } from "../../providers/LoaderProvider";
import { useUserProvider } from "../../providers/UserProvider";
import { useModalProvider } from "../../providers/ModalProvider";
import { UpdateApp } from "../../services/api/app";
import responseProcessor from "../../lib/responseProcessor";

const DeveloperAccountSetup = ({
  getIsCompleted,
}) => {
  const { onboardingData, setOnboardingData } = useOnboardingProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();

  const handleOnChange = (evt) => {
    const { checked } = evt.target

    const payload = {
      onboarding: {
        ...onboardingData,
        qr_created: checked
      }
    }

    doSetShowLoadingModal(true);

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => {
            setOnboardingData(payload?.onboarding);
            doSetShowLoadingModal(false);
          }
        })
    );
  }

  return (
    <div className="flex flex-row justify-between items-center p-2">
      <div className="w-full flex flex-row justify-start items-center">
        <button
          type="button"
          className={`p-2 cursor-not-allowed border-3 rounded`}
          style={{
            background:
              getIsCompleted({ id: QR_CREATED })
                ? colors.dark
                : "#FFFFFF",
            borderColor: colors.dark,
          }}
        >
          <div
            className="rounded-lg"
            style={{
              borderColor:
                getIsCompleted({ id: QR_CREATED })
                  ? colors.dark
                  : "#FFFFFF",
              backgroundColor:
                getIsCompleted({ id: QR_CREATED })
                  ? colors.dark
                  : "#FFFFFF",
            }}
          >
            <FaCheck className={`text-white font-bold rounded-lg h-3 w-3`} />
          </div>
        </button>

        <span className={`line-clamp-2 text-xl font-bold ml-2 text-2xl`}>
          {QR_CREATED}
        </span>
      </div>

      <div className="flex items-center w-full justify-end">
        <input
          type="checkbox"
          name="qr_created"
          value={onboardingData?.qr_created || false}
          checked={onboardingData?.qr_created || false}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default DeveloperAccountSetup;
