import DataTable from "./DataTable";
import { useState } from "react";

const SimpleTable = ({ savedData, handleEdit, handleDelete }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.key,
    },
    {
      name: "name",
      label: "Video Name",
      parser: (data) => data?.name,
      ellipsis: true,
    },
    {
      name: 'actions',
      label: '',
      parser: (data) => (
        <div className="flex flex-row gap-4 justify-end">
          <button className='ttnk-button mt-2' onClick={() => handleEdit({...data, isEdit: true })}>
            Edit
          </button>

          <button className='ttnk-button mt-2 bg-red-700' style={{ backgroundImage: 'none' }} onClick={() => handleDelete(data.key)}>
            Delete
          </button>
        </div>
      ),
    },
  ];

  const loadData = () => {
    console.log('load data')
  }

  return (
    <DataTable
      data={savedData}
      metadata={columns}
      dataLoader={loadData}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
    />
  );
};

export default SimpleTable;
