import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  Select,
  RichText,
} from "../components/EntryForm";
import Page from "../components/Page";
import MediaUploader from "../components/MediaUploader";

import { GetProfile, UpdateProfile } from "../services/api/profile";
import { GetAppResource, AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import { validations } from "../lib/form";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";
import { nanoid } from "nanoid";

const ProfileDetail = ({ newProfile, match }) => {
  const vals = {
    name: "",
    title: "",
    business_name: "",
    profile_image_url: "",
    email: "",
    bio: "",
    web_bio: "",
    website_url: "",
    facebook_url: "",
    facebook_id: "",
    twitter_url: "",
    instagram_url: "",
    youtube_url: "",
    linkedin_url: "",
    action_bar_text: "",
    action_bar_url: "",
    directory: null,
    directory_id: null,
    app_profile: false,
    media_metadata: {},
    active:false
  };

  const [initialValues, setInitialValues] = useState(vals);
  const [topicValues, setTopicValues] = useState([]);
  const [_setIsTopicLoading, setIsTopicLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newProfile ? user.currentApp() : match.params.uuid;

  const history = useHistory();

  useEffect(() => {
    if (newProfile) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetProfile(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            active: res.data.active,
            name: res.data.name,
            title: res.data.title,
            business_name: res.data.business_name,
            profile_image_url: res.data.profile_image_url,
            email: res.data.email,
            bio: res.data.bio,
            web_bio: res.data.web_bio,
            website_url: res.data.website_url,
            facebook_url: res.data.facebook_url,
            facebook_id: res.data.facebook_id,
            twitter_url: res.data.twitter_url,
            instagram_url: res.data.instagram_url,
            youtube_url: res.data.youtube_url,
            linkedin_url: res.data.linkedin_url,
            action_bar_text: res.data.action_bar_text,
            action_bar_url: res.data.action_bar_url,
            app_profile: res.data.app_profile,
            directory: res.data.directory,
            directory_id: res?.data?.directory?.id,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }

    setIsTopicLoading(true);
    GetAppResource(user, "topics")
      .then((res) => res.json())
      .then((res) => {
        setTopicValues(
          res.data.map((topic) => ({ id: topic.id, name: topic.name }))
        );
        setIsTopicLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newProfile ? "New" : "Edit";
  const currentUrl = newProfile ? "/profiles/new" : `/profiles/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Profiles", url: "/profiles" },
    { name: `${currentAction} Profile`, url: currentUrl },
  ];

  let schema = {
    name: validations.str_req_30,
    title: validations.str_notreq_35,
    business_name: validations.str_notreq_35,
    profile_image_url: Yup.string()
      .nullable()
      .max(255, "Maximum 255 Characters"),
    email: Yup.string()
      .email("Invalid email")
      .max(255, "Maximum 255 Characters"),
    bio: Yup.string().nullable().max(65535, "Maximum 65,535 Characters"),
    web_bio: Yup.string().nullable().max(65535, "Maximum 65,535 Characters"),
    website_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    facebook_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    facebook_id: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    twitter_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    instagram_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    youtube_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    linkedin_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    action_bar_text: Yup.string().nullable().max(50, "Maximum 50 Characters"),
    action_bar_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
    directory_id: Yup.string().nullable(),
    app_profile: Yup.boolean().nullable(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      let payload = {
        ...values,
        directory: { id: values.directory_id },
      };

      delete payload.directory_id;

      if (newProfile) {
        AddAppResource(uuid, "profiles", user.idToken, { data: payload }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateProfile(uuid, user.idToken, { data: payload }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/profiles"),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Profile`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/profiles"
      >
        <Column>
          <Input name="name" type="input" label="Name" formik={formik} />
          <Input name="title" type="input" label="Title" formik={formik} />
          <Input
            name="business_name"
            type="input"
            label="Business Name"
            formik={formik}
          />
          <MediaUploader
            name="profile_image_url"
            type="text"
            label="Profile Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <Input name="email" type="email" label="Email" formik={formik} />
          <RichText
            id="profilesBioEditor"
            name='bio'
            quillName='web_bio'
            type='text'
            label='Bio'
            rows='21'
            formik={formik}
            contentType="profileBio"
          />
          <Input
            name="website_url"
            type="text"
            label="Website URL"
            formik={formik}
          />
          <Input
            name="facebook_url"
            type="text"
            label="Facebook URL"
            formik={formik}
          />
          <Input
            name="facebook_id"
            type="text"
            label="Facebook ID (required for iOS apps)"
            formik={formik}
          />
        </Column>
        <Column>
          <Input
            name="twitter_url"
            type="text"
            label="Twitter URL"
            formik={formik}
          />
          <Input
            name="instagram_url"
            type="text"
            label="Instagram URL"
            formik={formik}
          />
          <Input
            name="youtube_url"
            type="text"
            label="YouTube URL"
            formik={formik}
          />
          <Input
            name="linkedin_url"
            type="text"
            label="LinkedIn URL"
            formik={formik}
          />
          <Input
            name="action_bar_text"
            type="text"
            label="Call to Action Text"
            formik={formik}
          />
          <Input
            name="action_bar_url"
            type="text"
            label="Call to Action URL"
            formik={formik}
          />
          <div className="flex">
            <span className="flex w-1/3">
              <CheckBox
                name="app_profile"
                label="App Profile?"
                formik={formik}
              />
            </span>
          </div>
          <Select
            name="directory_id"
            type="text"
            label="Topic/ Directory Listing"
            formik={formik}
          >
            <option value={null} label="Select Topic..." />
            {topicValues.map((topic) => (
              <option
                value={topic.id}
                label={topic.name}
                selected={
                  formik.values.directory &&
                  topic.id === formik.values.directory.id
                }
                key={`option-${nanoid()}`}
              />
            ))}
          </Select>
          <CheckBox name="active" label="Active" formik={formik} />

        </Column>
      </EntryForm>
    </Page>
  );
};

export default ProfileDetail;
