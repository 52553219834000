import { useCallback, useEffect, useState } from "react";
import { EntryForm, Column, Input, CheckBox } from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp, UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import * as Yup from "yup";
import { useSetLocalState } from "../lib/useLocalStorage";
import {
  IsWithinMaximumVersion,
  IsWithinMinimumVersion,
} from "../lib/useVersionizer";
import VersionizerWrapper from "../components/Versionizer";
import AvailableInVersion from "../components/AvailableInVersion";

const MenuSetup = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    url_tags: false,
    directories: false,
    directories_label: "",
    offers: false,
    offers_label: "",
    galleries: false,
    galleries_label: "",
    tips: false,
    tips_label: "",
    courses: false,
    courses_label: "",
    events: false,
    events_label: "",
    virtual_events: false,
    virtual_events_label: "",
    summits: false,
    summits_label: "",
    messages: false,
    messages_label: "",
    journal_entries_label: "",
    track_now_label: "",
    topics_label: "",
    premium_lite: false,
    premium_plus: false,
    lite_unlock_code: "",
    web: false,
    topics: false,
    actionclips: false,
    actionclips_label: "",
    enterprise_label: "",
  });

  const setLiteUnlockCode = useSetLocalState("lite");

  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const { user, setUser, setShouldFetchPNData } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();
  const disableTopics = IsWithinMaximumVersion(8);

  const getData = useCallback(() => {
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const app_setup = res.data.setup;
        let app_data = res.data;

        delete app_data.member_content_message;
        delete app_data.id;
        delete app_data.name;

        setLiteUnlockCode(app_setup.lite_unlock_code);
        let tempUser = user;
        tempUser.app_data.setup = res.data.setup;
        setUser(tempUser);
        setInitialValues({
          url_tags: app_setup.url_tags,
          directories: app_setup.directories,
          directories_label: app_setup.directories_label,
          offers: app_setup.offers,
          offers_label: app_setup.offers_label,
          galleries: app_setup.galleries,
          galleries_label: app_setup.galleries_label,
          tips: app_setup.tips,
          tips_label: app_setup.tips_label,
          podcasts: app_setup.podcasts || false,
          podcasts_label: app_setup.podcasts_label,
          courses: app_setup.courses,
          courses_label: app_setup.courses_label,
          events: app_setup.events,
          events_label: app_setup.events_label,
          virtual_events: app_setup.virtual_events,
          virtual_events_label: app_setup.virtual_events_label,
          messages: app_setup.messages,
          messages_label: app_setup.messages_label,
          summits: app_setup.summits,
          summits_label: app_setup.summits_label,
          action_lists: app_setup.action_lists,
          action_lists_label: app_setup.action_lists_label,
          journal_entries_label: app_setup.journal_entries_label,
          track_now_label: app_setup.track_now_label,
          enterprise_label: app_setup.enterprise_label,
          topics_label: app_setup.topics_label,
          premium_lite: app_setup.premium_lite,
          premium_plus: app_setup.premium_plus,
          lite_unlock_code: app_setup.lite_unlock_code,
          web: app_setup.web,
          topics: app_setup?.topics,
          actionclips: app_setup?.actionclips,
          actionclips_label:
            app_setup?.actionclips_label === ""
              ? "ACTIONCLIPS"
              : app_setup?.actionclips_label,
        });
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Menu Setup", url: "/menu-setup" },
  ];

  let schema = {
    url_tags: Yup.boolean().nullable(),
    directories: Yup.boolean().nullable(),
    directories_label: validations.str_notreq_20,
    offers: Yup.boolean().nullable(),
    offers_label: validations.str_notreq_20,
    galleries: Yup.boolean().nullable(),
    galleries_label: validations.str_notreq_20,
    tips: Yup.boolean().nullable(),
    tips_label: validations.str_notreq_20,
    courses: Yup.boolean().nullable(),
    courses_label: validations.str_notreq_20,
    events: Yup.boolean().nullable(),
    events_label: validations.str_notreq_20,
    virtual_events: Yup.boolean().nullable(),
    virtual_events_label: validations.str_notreq_20,
    summits: Yup.boolean().nullable(),
    summits_label: validations.str_notreq_20,
    messages: Yup.boolean().nullable(),
    messages_label: validations.str_notreq_20,
    journal_entries_label: validations.str_notreq_20,
    track_now_label: validations.str_notreq_20,
    topics_label: validations.str_notreq_20,
    premium_lite: Yup.boolean().nullable(),
    premium_plus: Yup.boolean().nullable(),
    web: Yup.boolean().nullable(),
    action_lists_label: validations.str_notreq_20,
    actionclips: Yup.boolean().nullable(),
    actionclips_label: validations.str_notreq_20,
    podcasts_label: validations.str_notreq_20,
    enterprise_label: validations.str_notreq_20,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      delete values.lite_unlock_code;

      const payload = { setup: values };
      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) => {
          responseProcessor({
            response,
            showModal,
            onSuccess,
          });
          setShouldFetchPNData(true);
        }
      );
    },
  });

  const onSuccess = () => {
    setSaved(true);
    setIsLoading(true);
    getData();
  };

  const SavedLabel = () => <span className="text-green-400">Saved</span>;

  return (
    <Page links={links} title="Menu Setup">
      <div>
        <div>ID: {user.app.id}</div>
      </div>
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
        savedLabel={saved && !formik.dirty && SavedLabel()}
      >
        <Column>
          <div className="w-full">
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="topics"
                  label="Topics"
                  formik={formik}
                  disabled={disableTopics}
                />
              </span>
              <div>
                <Input
                  disabled={!formik.values.topics}
                  name="topics_label"
                  type="text"
                  label="Topics Label"
                  formik={formik}
                />
                <VersionizerWrapper maxVersion={8} minVersion={8}>
                  <AvailableInVersion minVersion={8.5} />
                </VersionizerWrapper>
              </div>
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="directories"
                  label="Directories"
                  formik={formik}
                />
              </span>
              <Input
                name="directories_label"
                type="text"
                label="Directories Label"
                disabled={!formik.values.directories}
                formik={formik}
              />
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="offers"
                  label="Featured Resource"
                  formik={formik}
                />
              </span>
              <Input
                name="offers_label"
                type="text"
                label="Featured Resource Label"
                disabled={!formik.values.offers}
                formik={formik}
              />
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox name="galleries" label="Galleries" formik={formik} />
              </span>
              <Input
                name="galleries_label"
                type="text"
                label="Galleries Label"
                disabled={!formik.values.galleries}
                formik={formik}
              />
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox name="tips" label="ACTIONBLOG" formik={formik} />
              </span>
              <Input
                name="tips_label"
                type="text"
                label="ACTIONBLOG Label"
                disabled={!formik.values.tips}
                formik={formik}
              />
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox name="courses" label="Courses" formik={formik} />
              </span>
              <Input
                name="courses_label"
                type="text"
                label="Courses Label"
                disabled={!formik.values.courses}
                formik={formik}
              />
            </div>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="events"
                  label="ACTIONCALENDAR"
                  formik={formik}
                />
              </span>
              <Input
                name="events_label"
                type="text"
                label="ACTIONCALENDAR Label"
                disabled={!formik.values.events}
                formik={formik}
              />
            </div>
            {IsWithinMinimumVersion(8.5) && (
              <div className="flex">
                <span className="w-44 mr-8">
                  <CheckBox
                    name="action_lists"
                    label="ACTIONLISTS"
                    formik={formik}
                  />
                </span>
                <div>
                  <Input
                    name="action_lists_label"
                    type="text"
                    label="ACTIONLISTS Label"
                    disabled={!formik.values.action_lists}
                    formik={formik}
                  />
                </div>
              </div>
            )}
          </div>
          <VersionizerWrapper minVersion={9}>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="actionclips"
                  label="ACTIONCLIPS"
                  formik={formik}
                />
              </span>
              <Input
                name="actionclips_label"
                type="text"
                label="ACTIONCLIPS Label"
                disabled={!formik.values.actionclips}
                formik={formik}
              />
            </div>
          </VersionizerWrapper>
          <div className="flex">
            <span className="w-44 mr-8">
              <CheckBox
                name="messages"
                label="Messages"
                formik={formik}
                disabled={formik.values.free}
              />
            </span>
            <Input
              name="messages_label"
              type="text"
              label="MESSAGES/ UPDATES Label (Coming Soon)"
              disabled={!formik.values.messages}
              formik={formik}
            />
          </div>
          <div className="flex">
            <span className="w-44 mr-8">
              <CheckBox
                name="virtual_events"
                label="Virtual Events"
                formik={formik}
                disabled={formik.values.free}
              />
            </span>
            <Input
              name="virtual_events_label"
              type="text"
              label="Virtual Events Label"
              disabled={!formik.values.virtual_events}
              formik={formik}
            />
          </div>
          <div className="flex">
            <span className="w-44 mr-8">
              <CheckBox
                name="summits"
                label="Summits"
                formik={formik}
                disabled={formik.values.free}
              />
            </span>
            <Input
              name="summits_label"
              type="text"
              label="Summits Label"
              disabled={!formik.values.summits}
              formik={formik}
            />
          </div>
          <VersionizerWrapper minVersion={9}>
            <div className="flex">
              <span className="w-44 mr-8">
                <CheckBox
                  name="podcasts"
                  label="Podcasts"
                  formik={formik}
                  disabled={formik.values.free}
                />
              </span>
              <Input
                name="podcasts_label"
                type="text"
                label="Podcasts Label"
                disabled={formik.values.free || !formik.values.podcasts}
                formik={formik}
              />
            </div>
          </VersionizerWrapper>
        </Column>
        <Column>
          <div className="w-1/2">
            <div>
              <Input
                name="journal_entries_label"
                type="text"
                label="Journal Entries Label"
                formik={formik}
              />
            </div>
            <div>
              <Input
                name="track_now_label"
                type="text"
                label="Track Now Label"
                formik={formik}
              />
            </div>
            {(user?.app_data?.setup?.enterprise || user?.app_data?.reseller_of?.length > 0) && (
              <div>
                <Input
                  name="enterprise_label"
                  type="text"
                  label={user?.app_data?.reseller_of?.length > 0? "Reseller Label" :"Enterprise Directory Label"}
                  formik={formik}
                />
              </div>
            )}
          </div>
        </Column>
      </EntryForm>
    </Page>
  );
};

export default MenuSetup;
