import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetWelcomeScreen = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/welcome_screens/${uuid}`, 'GET', idToken)
}

export const UpdateWelcomeScreen = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/welcome_screens/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteWelcomeScreen = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/welcome_screens/${uuid}`, 'DELETE', idToken)
}
