import articleConfig from "./articles"
import itemConfig from "./items"
import audioConfig from "./audios"
import galleryConfig from "./galleries"
import journalConfig from "./journals"
import lessonConfig from "./lessons"
import memberConfig from "./members"
import productConfig from "./products"
import resourceConfig from "./resources"
import summitConfig from "./summits"
import questionConfig from "./questions"
import tipConfig from "./tips"
import videoConfig from "./videos"
import tagConfig from "./tags"
import virtualEventConfig from "./virtualEvents";
import topicConfig from "./topics"
import courseLessonConfig from "./courseLessons"
import messagesConfig from "./messages"
import actionListConfig from "./action-lists"
import podcastsConfig from "./podcasts"
import documentsConfig from "./documents"
import actionFormListingConfig from "../actionform_config/listing"

const contentConfig = (contentType) => {
  switch(contentType) {
    case 'articles':
      return articleConfig
    case 'audios':
      return audioConfig
    case 'items':
      return itemConfig
    case 'galleries':
      return galleryConfig
    case 'journals':
      return journalConfig
    case 'lessons':
      return lessonConfig
    case 'members':
      return memberConfig
    case 'products':
      return productConfig
    case 'questions':
      return questionConfig
    case 'resources':
      return resourceConfig
    case 'summits':
      return summitConfig
    case 'tips':
      return tipConfig
    case 'tags':
      return tagConfig
    case 'topics':
      return topicConfig
    case 'videos':
      return videoConfig
    case 'virtualEvents':
      return virtualEventConfig
    case 'courseLessons':
      return courseLessonConfig
    case 'messages':
      return messagesConfig
    case 'action-lists':
      return actionListConfig
    case 'podcasts':
      return podcastsConfig
    case 'documents':
      return documentsConfig
    case 'Topic':
      return actionFormListingConfig
    default:
      return videoConfig
  }
}

export default contentConfig
