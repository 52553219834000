import { useEffect } from "react";
import { useUserProvider } from "../providers/UserProvider";
import { GetAppById } from "../services/api/app";
import useLocalStorage, { useSetLocalState } from "../lib/useLocalStorage";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useOnboardingProvider } from "../providers/OnboardingProvider";

const GoToOnboarding = ({match}) => {
  const uuid = match.params.uuid;
  const [currentApp, setCurrentApp] = useLocalStorage("currentApp", null);
  const setLiteUnlockCode = useSetLocalState("lite");
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { user, setUser } = useUserProvider();
  const { setOnboardingData } = useOnboardingProvider();

  useEffect(() => {
    GetAppById(uuid, user)
    .then((res) => res.json())
    .then((res) => {
      if (res.data) {
        const selectedApp = {
          id: res.data.id,
          name: res.data.name,
          lite: res.data.setup.premium_lite,
          plus: res.data.setup.premium_plus,
          isPending: res.data.is_pending,
          initials: res.data.name
            .split(" ")
            .map((n) => n[0])
            .join(""),
          }
        setCurrentApp(selectedApp);
        setTimeout(()=> {
          window.location.href = '/onboarding'
        }, 2000)
      } else {
        window.location.href = '/'
      }

    })

  }, [doSetShowLoadingModal, setCurrentApp, setLiteUnlockCode, setOnboardingData, setUser, user, uuid])

  return (
    <>Redirecting to Onboarding…</>
  )
}

export default GoToOnboarding;