import moment from "moment";

const lessonConfig = {
  // label: 'Lesson',
  // resourceEndpoint: 'lessons',
  // listMetadata: ['sort_order',
  //                'active',
  //                'title',
  //                'featured',
  //                'topics',
  //                'tags',
  //                'actions'],
  // searchKeys: ['title'],
  // detailsMetadata: [
  //   { name: 'title', label: 'Lesson Title'},
  //   { name: 'description', label: 'Description', validations:
  //     [
  //       {type: 'required', params: ['Required']}
  //     ]
  //   },
  //   { name: 'image_url', label: 'Image URL'},
  //   { name: 'release_date', label: 'Release Date'},
  //   { name: 'action_bar_text', label: 'Call to Action Text'},
  //   { name: 'action_bar_url', label: 'Call to Action URL'},
  //   { name: 'sort_order', label: 'Sort Order'},
  //   { name: 'active', label: 'Active'},
  //   { name: 'featured', label: 'Featured'},
  //   { name: 'topics', label: 'Topics'},
  //   { name: 'tags', label: 'Tags'},
  //   { name: 'sections', label: 'Sections'}
  // ],
  subTables: {
    courses: {
      label: {
        plural: "courses",
        singular: "course",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.id },
        {
          name: "course_name",
          label: "Course Name",
          parser: (data) => data.course_name,
        },
        {
          name: "enroll_date",
          label: "Enrolled Date",
          parser: (data) => moment(data.enroll_date).format("DD-MMM-yyyy"),
        },
      ],
      detailsMetadata: [
        {
          name: "course_name",
          label: "Course Name",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "enroll_date",
          label: "Enrolled Date",
          format: "DD-MMM-yyyy",
          validations: [{ type: "required", params: ["Required"] }],
        },
      ],
    },
    url_tags: {
      label: {
        plural: "URL Tags",
        singular: "URL Tag",
      },
      listMetadata: [
        { name: "id", label: "", hide: true, parser: (data) => data.url_tag },
        {
          name: "url_tag",
          label: "Tag",
          parser: (data) => data.url_tag,
          ellipsis: true,
        },
        { name: "link", label: "URL", parser: (data) => data.link },
      ],
      detailsMetadata: [
        {
          name: "url_tag",
          label: "Tag",
          validations: [{ type: "required", params: ["Required"] }],
        },
        {
          name: "link",
          label: "URL",
          validations: [{ type: "required", params: ["Required"] }],
        },
      ],
    },
  },
};

export default lessonConfig;
