import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import moment from "moment"

const DEFAULT_VALUES = {
  isEdit: false,
  app_designed: "get_started",
  app_designed_due_date: moment().toISOString(),
  app_designed_notes: "",
  app_name: "",
  tagline: "",
  app_designed_fonts: "",
  app_designed_colors: "",
  app_designed_graphics_file: "",
  media_metadata: {}
}

const onboardingAppDesigned = ({ handleSubmit, initialValues }) => {
  const fields = [
    {
      component: "app_designed",
      name: "app_designed",
      label: "Status",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "app_designed_due_date",
      name: "app_designed_due_date",
      label: "Due Date",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "app_designed_notes",
      field: "textarea",
      name: "app_designed_notes",
      label: "Notes",
      placeholder: "",
      isFieldRow: true,
      rows: 10,
      required: true,
    },
    {
      component: "app_name",
      field: "text",
      name: "app_name",
      label: "App Name",
      placeholder: "",
      isFieldRow: true,
      required: false,
    },
    {
      component: "tagline",
      field: "text",
      label: "App Tagline",
      placeholder: "",
      required: false,
      isFieldRow: true,
    },
    {
      component: "app_designed_fonts",
      field: "textarea",
      name: "app_designed_fonts",
      label: "Fonts",
      placeholder: "",
      isFieldRow: true,
      rows: 10,
      required: true,
    },
    {
      component: "app_designed_colors",
      field: "textarea",
      name: "app_designed_colors",
      label: "Colors",
      placeholder: "",
      isFieldRow: true,
      rows: 10,
      required: true,
    },
    {
      component: "app_designed_graphics_file",
      name: "app_designed_graphics_file",
      label: "Graphics",
      placeholder: "",
      isFieldRow: true,
      required: true,
    },
  ]

  const schema = {
    app_designed_notes: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    app_name: validations.str_notreq_30,
    tagline: validations.str_notreq_50,
    app_designed_fonts: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    app_designed_colors: validations.long_text_required.max(65535, "Maximum 65535 Characters"),
    app_designed_graphics_file: validations.long_text_required.nullable(),
  };

  const handleFormatPayload = (values) => {
    const { isEdit, app_name, tagline, ...rest } = values

    const newPayload = {
      name: app_name,
      setup: {
        header: {
          tagline: tagline,
        }
      },
      onboarding: {
        ...rest,
      }
    }

    return newPayload
  }

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.app?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        app_designed: initialValues?.onboarding?.app_designed,
        app_designed_due_date: initialValues?.onboarding?.app_designed_due_date,
        app_designed_notes: initialValues?.onboarding?.app_designed_notes,
        app_name: initialValues?.app?.name,
        tagline: initialValues?.app_data?.setup?.header?.tagline,
        app_designed_fonts: initialValues?.onboarding?.app_designed_fonts,
        app_designed_colors: initialValues?.onboarding?.app_designed_colors,
        app_designed_graphics_file: initialValues?.onboarding?.app_designed_graphics_file,
        media_metadata: initialValues?.onboarding?.media_metadata,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Assets",
      resourceEndpoint: "apps",
      columnsMetadata: [
        { customComponent: (
          <OnboardingContent
            title="Assets"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={handleFormatPayload}
          />
        ) },
      ],
    }
  }
}

export default onboardingAppDesigned;