import { Fragment } from 'react'
import { useModalProvider } from '../../providers/ModalProvider'

const ErrorModal = ({ status, data, customMessage }) => {

  const { closeModal } = useModalProvider()

  const errorDescriptions = {
    409: 'There was a conflict in the submitted data.',
    500: 'Something went wrong.',
    INVALID_FILE_SIZE: "Invalid file size",
    INVALID_FILE_TYPE: "Invalid file type",
    DUPLICATE: "Please make sure you did not enter duplicate data",
    INVALID_FILE_NAME: "File name invalid. Please use the same or original file name when you exported the file",
    INVALID_TABLE: "Invalid Import. Table name not found. Filename should match the target table",
    INVALID_HASH: "The Provided Thrivecart Data is missing or invalid.",
  }

  const humanizeField = (field) => {
    switch (field) {
      case 'custom_frequency':
        return "Custom Frequency"
      default:
        return field
    }
  }

  const errorDetails = {
    422: <div className="mt-5">
      <div className="mb-2">The following issues were found:</div>
      <ul>
      { Object.keys(data?.error?.detail?.data || {}).map((fieldName) => (
           data?.error?.detail?.data[fieldName].map((fieldError) => (
            <li key={fieldName + fieldError}>{humanizeField(fieldName)} {fieldError}</li>
          ))
        ))}
      </ul>
    </div>,
  }

  return (
    <Fragment>
      <div>There was an error processing your request. {errorDescriptions[status]}</div>
      { errorDetails[status] }
      { customMessage && <div className="mt-5">{customMessage}</div>}
      <div className="flex mt-6">
        <div className="mr-auto w-full flex items-center justify-center">
          <button className="ttnk-button add-button" onClick={() => closeModal()}>Close</button>
        </div>
      </div>
    </Fragment>
  )
}

export default ErrorModal
