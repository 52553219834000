import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  TextArea,
} from "../components/EntryForm";
import {
  GetCourseLesson,
  AddCourseLesson,
  UpdateCourseLesson,
} from "../services/api/course_lesson";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useDuplicateValidation, validations } from "../lib/form";
import DataSelector from "../components/DataSelector";
import DateSelector from "../components/DateSelector";
import MediaUploader from "../components/MediaUploader";
import Page from "../components/Page";
import responseProcessor from "../lib/responseProcessor";
import SubTable from "../components/SubTable";
import contentConfig from "../lib/content_config/config";
import ErrorModal from "../components/modal_contents/ErrorModal";

const CourseLessonDetail = ({ newCourseLesson, match }) => {
  const contentType = "courseLessons";
  const subTables = contentConfig(contentType).subTables;
  const vals = {
    title: "",
    description: "",
    image_url: "",
    active: false,
    sort_order: 1,
    release_date: moment().utcOffset(0, true),
    drip_sequence: 0,
    action_bar_text: "",
    action_bar_url: "",
    tags: [],
    sections: [],
    media_metadata: {},
  };
  const [initialValues, setInitialValues] = useState(vals);
  const [isLoading, setIsLoading] = useState(false);
  const [_isCourseCategoryLoading, setIsCourseCategoryLoading] =
    useState(false);
  const [metadata, setMetadata] = useState({});
  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const course_uuid = match.params.course_uuid;
  const module_uuid = match.params.module_uuid;
  const uuid = newCourseLesson ? module_uuid : match.params.uuid;
  const history = useHistory();
  const loadData = () => {
    setIsLoading(true);
    GetCourseLesson(uuid, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        setInitialValues({
          title: res.data.title,
          description: res.data.description,
          image_url: res.data.image_url,
          active: res.data.active,
          sort_order: res.data.sort_order,
          release_date: res.data.release_date,
          drip_sequence: res.data.drip_sequence,
          action_bar_text: res.data.action_bar_text,
          action_bar_url: res.data.action_bar_url,
          course_id: res.data.course_id,
          tags: res.data.tags,
          sections: res.data.sections,
        });
        setMetadata(res.data.media_metadata);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (newCourseLesson) {
      setInitialValues(vals);
    } else {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newCourseLesson ? "New" : "Edit";
  const currentUrl = newCourseLesson
    ? "/course_lessons/new"
    : `/course_lessons/${uuid}`;

  let links = [
    { name: "Home", url: "#" },
    { name: "Course List", url: "/courses" },
    { name: `Course: ${course_uuid}`, url: `/courses/${course_uuid}` },
    {
      name: `Module: ${course_uuid}`,
      url: `/courses/${course_uuid}/modules/${module_uuid}`,
    },
    { name: `${currentAction} Course Lesson`, url: currentUrl },
  ];

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  let schema = {
    title: validations.str_req_60,
    description: Yup.string().required("Required"),
    image_url: validations.long_text,
    active: validations.bool,
    sort_order: validations.sort_order,
    release_date: Yup.date().required("Required"),
    drip_sequence: validations.drip_sequence,
    action_bar_text: validations.str_notreq_40,
    action_bar_url: Yup.string()
      .url("URL must begin with https:// or http:// e.g. https://actionera.com")
      .max(255, "Maximum 255 Characters"),
  };

  const validate = useDuplicateValidation(
    ["sub_description"],
    "sections",
    false
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    validate: (values) => validate(values, formik),
    onSubmit: async (values) => {
      let payload = {
        ...values,
        course_module: { id: module_uuid },
        drip_sequence: values?.drip_sequence || 0,
      };
      if (newCourseLesson) {
        AddCourseLesson(course_uuid, user.idToken, { data: payload }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateCourseLesson(uuid, user.idToken, { data: payload }).then(
          (response) => responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () =>
        history.push(`/courses/${course_uuid}/modules/${module_uuid}`),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Course Lesson`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL={`/courses/${course_uuid}/modules/${module_uuid}`}
      >
        <Column>
          <Input name="title" type="text" label="Title" formik={formik} />
          <TextArea
            name="description"
            type="text"
            label="Description"
            formik={formik}
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <DateSelector
            name="release_date"
            type="text"
            label="Release Date"
            formik={formik}
          />
          <Input
            name="action_bar_text"
            type="text"
            label="Call To Action Text"
            formik={formik}
          />
        </Column>
        <Column>
          <Input
            name="action_bar_url"
            type="text"
            label="Call To Action URL"
            formik={formik}
          />
          <Input
            name="drip_sequence"
            type="text"
            label="Drip Sequence"
            formik={formik}
          />
          <Input
            name="sort_order"
            type="number"
            label="Sort Order"
            formik={formik}
          />
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(options) => {
              return options.filter((a) => !a.is_expired);
            }}
          />
          <CheckBox name="active" label="Active" formik={formik} />
        </Column>

        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={contentConfig(contentType)}
                  subContentType={key}
                  labels={subTables[key].label}
                  disclaimer="Order of Sections below will appear correctly on App and WEB"
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={contentType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}
      </EntryForm>
    </Page>
  );
};

export default CourseLessonDetail;
