import React from "react"
import { useModalProvider } from "../../../providers/ModalProvider";

const LaunchAppConfirmationModal = () => {
  const { closeModal } = useModalProvider();

  const handleOnClick = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;

    closeModal();
    window.location.href = "/";
  };

  return (
    <div>
      <div style={{ width: 400 }} className="mb-4 text-center">
        Thank you, your app is being reviewed and you'll be notified to access it inside the app you are a part of. You will now be redirected to the full creator portal
      </div>
      <div className='flex justify-center'>
      <button onClick={handleOnClick} className="ttnk-button">
        OK
      </button>
      </div>
    </div>
  );
}

export default LaunchAppConfirmationModal