import Description from "../../components/Description"
import ImageSlideout from '../../assets/images/welcome_screens-slideout.jpg'
import { VideoPlayer } from "../../components/VideoPlayer"
import { Image } from "../../components/Image"
import { validations } from "../form"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import * as Yup from "yup";
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import { ENTERPRISE } from "./config"

const DEFAULT_VALUES = {
  isEdit: false,
  title: '',
  welcome_media_type: 'image',
  image_url: '',
  video_url: '',
  welcome_headline: '',
  welcome_message: ''
}

const OnboardingWelcomeScreen = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: "title",
      label: "Welcome Screen Title",
      isFieldRow: true,
      placeholder: 'Short sentence introducing the app',
      required: true
    },
    {
      component: "welcome_media_type",
      label: "Welcome Image or Video",
      isFieldRow: true,
      required: true
    },
    {
      component: "welcome_headline",
      label: "Welcome Headline",
      isFieldRow: true,
      placeholder: 'Defaults to “What’s New” but you can change it to anything',
      required: true
    },
    {
      component: "welcome_message",
      label: "Welcome Message",
      placeholder: "App usage instructions, description, description, list of what’s new, etc.",
      isFieldRow: true,
      rows: 21,
      required: true
    },
  ]

  const schema = {
    title: validations.str_req_50,
    welcome_media_type: validations.str_notreq_20,
    welcome_headline: validations.str_notreq_30,
    welcome_message: Yup.string().max(65535, "Maximum 65,535 Characters").required('Required'),
  };

  const formatDefaultValues = () => {
    if (initialValues?.id) {

      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        welcome_headline: initialValues?.whats_new_headline,
        welcome_message: initialValues?.whats_new_description,
        welcome_media_type: initialValues?.video_url ? 'video' : 'image',
        active: initialValues?.active
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Onboarding - Home",
      resourceEndpoint: "welcome_screens",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=Vg45biqoEv8" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            When someone opens the App, they go right to this Home/Welcome Screen (see image below). You can update this screen often with new videos, featured content, livestream feeds, launches, etc.
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Welcome Screen"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Home"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              if (values?.welcome_media_type === 'image') values.video_url = ""
              if (values?.welcome_media_type === 'video') values.image_url = ""

              const newPayload = {
                title: values?.title,
                image_url: values?.image_url,
                video_url: values?.video_url,
                whats_new_headline: values?.welcome_headline,
                whats_new_description: values?.welcome_message,
                active: initialValues?.id ? values?.active : true,
              }

              return newPayload
            }}
          />
        ) },
        {
          customComponent: (
            <div>
              NOTE: ALL your submitted content can be modified by you after your app launches.
            </div>
          )
        }
      ]
    }
  }
}

export default OnboardingWelcomeScreen;