import BatteryGauge from "react-battery-gauge";
import { VideoPlayer } from "./VideoPlayer";
import Accordion from "./accordion";
import Counter from "./Counter";
import Button from "./Button";
import SlideOut from "./SlideOut";
import { useDrawerProvider } from "../providers/DrawerProvider";
import OnboardingContentList from "./onboarding/OnboardingContentList";
import { useOnboardingProvider } from "../providers/OnboardingProvider";
import { useModalProvider } from "../providers/ModalProvider";
import LaunchAppConfirmationModal from "./modal_contents/LaunchAppConfirmationModal/LaunchAppConfirmationModal";
import { ENTERPRISE, LAUNCHED, SAVED, SUBMITTED } from "../lib/onboarding_config/config";
import { UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import responseProcessor from "../lib/responseProcessor";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useMemo } from "react";

export const UploadContent = () => {
  const { onboardingData: { type, progress, status } = {}, onboardingData, setOnboardingData } = useOnboardingProvider()
  const { showModal } = useModalProvider()
  const { user } = useUserProvider()
  const { doSetShowLoadingModal }  = useLoaderProvider();

  const isCompleted = (type) => {
    let currentType = type
    if (type === 'tips') currentType = 'blogs'
    if (type === 'welcome_screens') currentType = 'home'

    if (currentType === 'home_profile') return progress?.home_profile?.status === SUBMITTED

    const key = ["home", "profiles"].includes(currentType) && onboardingData?.type === ENTERPRISE ? "home_profile" : "content"

    const counter = progress?.[key]?.[currentType]
    return (counter?.current > 0 && counter?.current >= counter?.required)
  }

  const onSuccess = () => {
    if (type === ENTERPRISE) {
      showModal(<LaunchAppConfirmationModal />)
    }
  }

  const handleSubmit = () => {
    doSetShowLoadingModal(true)

    const payload = {
      onboarding: {
        ...onboardingData,
        ...(type === ENTERPRISE ? { status: LAUNCHED } : {}),
        confetti: type === ENTERPRISE ? true : false,
        progress: {
          ...onboardingData?.progress,
          content: {
            ...onboardingData?.progress?.content,
            status: type === ENTERPRISE ? LAUNCHED : SUBMITTED
          }
        }
      }
    }

    UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
      (response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => {
            setOnboardingData(payload?.onboarding)
            onSuccess()
            doSetShowLoadingModal(false)
          },
        })
    );
  }

  const OnBoardingContentHeading = ({ type, title, prevType = "welcome_screens" }) => {
    const { showDrawer, closeDrawer } = useDrawerProvider();
    const { onboardingData } = useOnboardingProvider()

    let currentType = type
    let currentPrevType = prevType

    if (type === 'tips') currentType = 'blogs'
    if (type === 'welcome_screens') currentType = 'home'

    if (prevType === 'tips') currentPrevType = 'blogs'
    if (prevType === 'welcome_screens') currentPrevType = 'home'

    const counter = onboardingData?.progress?.content?.[currentType]
    const isMaxReached = counter?.current > 0 && counter?.current >= counter?.required

    return (
      <div className="flex items-center gap-4">
        <Counter
          className="text-center font-bold"
          currentCount={counter?.current || 0}
          totalCount={counter?.required || 0}
          isDisabled={currentPrevType === null ? false : isCompleted(currentPrevType) === false}
        />
        {
          (!isMaxReached && (currentPrevType === null || isCompleted(currentPrevType))) ? (
            <Button
              type="button"
              className="flex justify-center items-center border-4 border-aeblue border-solid gap-0.5 text-sm py-1.5 px-5 !bg-none rounded-lg uppercase text-aeblue-default font-bold hover:text-white hover:bg-aeblue"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                showDrawer(
                  <SlideOut
                    type={type}
                    showSubmitBtn={type === 'directories'}
                    handleSubmit={closeDrawer}
                  />,
                  title,
                  "50vw",
                  "text-center w-full"
                );
              }}
            >
              Add Item
            </Button>
          ) : null
        }
      </div>
    )
  }

  const enterpriseUploadContent = [
    {
      id: 2,
      label: "Topics",
      status: <OnBoardingContentHeading type="topics" title="Topics" prevType="home_profile"/>,
      is_complete: isCompleted("topics"),
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="topics"
        title="Topics"
        isDisabled={![SUBMITTED]?.includes(progress?.home_profile?.status)}
      />,
    },
  ]

  const bizPlusUploadContent = [
    {
      id: 1,
      label: "Home",
      status: <OnBoardingContentHeading type="welcome_screens" title="Home" prevType={null} />,
      is_complete: isCompleted("welcome_screens"),
      is_disabled: false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="welcome_screens"
        title="Home"
      />,
    },
    {
      id: 2,
      label: "Topics",
      status: <OnBoardingContentHeading type="topics" title="Topics" prevType="welcome_screens"/>,
      is_complete: isCompleted("topics"),
      is_disabled: isCompleted("home") === false,
      has_details: true,
      editable: true,
      body: <OnboardingContentList
        type="topics"
        title="Topics"
      />,
    },
    {
      id: 3,
      label: "Profile",
      status: <OnBoardingContentHeading type="profiles" title="Profile" prevType="topics"/>,
      is_complete: isCompleted("profiles"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="profiles"
        title="Profile"
      />,
    },
    {
      id: 5,
      label: "Gallery",
      status: <OnBoardingContentHeading type="galleries" title="Gallery" prevType="topics"/>,
      is_complete: isCompleted("galleries"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList type="galleries" title="Gallery" />,
    }
  ]

  const subaccordionList = [
    ...(type === ENTERPRISE ? enterpriseUploadContent : bizPlusUploadContent),
    {
      id: 6,
      label: "Blog",
      status: <OnBoardingContentHeading type="tips" title="Blog" prevType="topics"/>,
      is_complete: isCompleted("tips"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="tips"
        title="Blog"
        isDisabled={type === ENTERPRISE ? ![SUBMITTED]?.includes(progress?.home_profile?.status) : undefined}
      />,
    },
    {
      id: 7,
      label: "Videos",
      status: <OnBoardingContentHeading type="videos" title="Videos" prevType="topics"/>,
      is_complete: isCompleted("videos"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="videos"
        title="Videos"
        isDisabled={type === ENTERPRISE ? ![SUBMITTED]?.includes(progress?.home_profile?.status) : undefined}
      />,
    },
    {
      id: 8,
      label: "Articles",
      status: <OnBoardingContentHeading type="articles" title="Articles" prevType="topics"/>,
      is_complete: isCompleted("articles"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="articles"
        title="Articles"
        className="mt-5"
        isDisabled={type === ENTERPRISE ? ![SUBMITTED]?.includes(progress?.home_profile?.status) : undefined}
      />,
    },
    {
      id: 9,
      label: "Journals",
      status: <OnBoardingContentHeading type="journals" title="Journals" prevType="topics"/>,
      is_complete: isCompleted("journals"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="journals"
        title="Journals"
        isDisabled={type === ENTERPRISE ? ![SUBMITTED]?.includes(progress?.home_profile?.status) : undefined}
      />,
    },
    {
      id: 10,
      label: "Q&As",
      status: <OnBoardingContentHeading type="questions" title="Q&A" prevType="topics"/>,
      is_complete: isCompleted("questions"),
      is_disabled: isCompleted("topics") === false,
      has_details: true,
      editable: false,
      body: <OnboardingContentList
        type="questions"
        title="Q&A"
        isDisabled={type === ENTERPRISE ? ![SUBMITTED]?.includes(progress?.home_profile?.status) : undefined}
      />,
    },
  ]

  const isContentCompleted = useMemo(() => Number(progress?.content?.overall?.percentage || 0) >= 100, [progress?.content?.overall?.percentage])
  const isAppDesignCompleted = useMemo(() => [SUBMITTED, SAVED]?.includes(progress?.design?.status), [progress?.design?.status])
  const isHomeProfileCompleted = useMemo(() => [SUBMITTED]?.includes(progress?.home_profile?.status), [progress?.home_profile?.status])
  const isUploadContentSubmitted = useMemo(() => [SUBMITTED]?.includes(progress?.content?.status), [progress?.content?.status])

  return (
    <div className="px-5">
      <div className="w-4/6 mx-auto">
        <VideoPlayer url={type === ENTERPRISE ? "https://youtu.be/NxuFUI9OZCQ" : "https://youtu.be/vvS1LIv_gsg"} />
      </div>

      {
        type !== ENTERPRISE ? (
          <div className="my-10 font-medium text-xl">
            Watch the video above and then fill out this form to give us your minimum launch content. We need this content to get your app approved by Apple and Google. You can source content you’ve already created, create the content brand new, and/or share (with permission*) other people’s content. The only requirement is that the content you provide us with is on-theme for your app brand and message. Additionally, your content should not attempt to sell or promote any products or services. Any such intended monetization of the app must come after your app is launched.

            <br /><br />

            If you get stuck, the pop-out slider is tremendously helpful in this section for context, clarity and resources.

            <br /><br />

            After you finish uploading and submitting your content our team will review it. This may take 3-5 business days. 

            <br /><br />

            *Content is free to share so long as the images/videos are attribution-free. Most YouTube/Vimeo videos are free to use (we will check for you when the form is submitted). You can use ChatGPT to create content so long as the final draft has been translated into your own words. The user agreement for ChatGPT requires you give authorship to ChatGPT if you did not make changes to the content it wrote.
          </div>
        ) : null
      }

      <div>
        {subaccordionList.map((item) => (
          <Accordion
            key={item.id}
            item={item}
            value={item.is_complete ?? false}
            status={item.status}
            isSubAccordion
            onChange={() => {}}
            isDisabled={item?.is_disabled}
          />
        ))}
      </div>

      <div className="text-sm pt-6 pl-12">
        NOTE: Once you click Submit, you will not be able to edit the content until you have launched your app. If you urgently need to edit the content before your app launches, please email ACTIONERA Support at <a href="mailto:support@actionera.com" className="text-aeblue font-bold text-sm hover:no-underline">support@actionera.com</a>
      </div>

      {
        status !== LAUNCHED && (
          <div className="flex flex-row items-center justify-end w-full pt-8 font-bold text-darkGray text-2xl">
            <button
              className={`ttnk-button font-bold text-darkGray text-2xl ${type === ENTERPRISE ? "w-230px" : "w-160px"}`}
              type="submit"
              disabled={
                isUploadContentSubmitted ||
                !(isContentCompleted
                  && (type === ENTERPRISE
                    ? isHomeProfileCompleted
                    : isAppDesignCompleted)
                )
              }
              onClick={handleSubmit}
            >
              {
                type === ENTERPRISE ? "Launch My App" : "SUBMIT"
              }
            </button>
          </div>
        )
      }
    </div>
  );
}

export const UploadContentStatus = () => {
  const { onboardingData } = useOnboardingProvider()

  return (
    <div className="flex justify-center items-center">
      <BatteryGauge
        value={onboardingData?.progress?.content?.overall?.percentage || 0}
        size={100}
        customization={{
          batteryBody: {
            strokeWidth: 2,
            cornerRadius: 0,
            strokeColor: "#2650A0",
          },
          batteryMeter: {
            lowBatteryFill: '#2F91CF',
            fill: "#2F91CF",
            outerGap: 0,
            interCellsGap: 0,
            gradFill: [
              {
                color: "rgba(42,80,159,1)",
                offset: 30
              },
              {
                color: "#2F91CF",
                offset: 80
              },
              {
                color: "rgba(47,145,206,1)",
                offset: 90
              },
            ]
          },
          batteryCap: {
            strokeWidth: 0,
          },
          readingText: {
            lightContrastColor: '#2650A0',
            darkContrastColor: '#FFFFFF',
            lowBatteryColor: '#2F91CF',
            className: 'text-3xl ttnk-AvenirNextBold'
          },
        }}
      />
    </div>
  )
}