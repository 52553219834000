import React, { useEffect, useState } from "react";
import UploadFile from "../assets/icons/upload-file.svg";
import { Upload, message } from "antd";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { useUserProvider } from "../providers/UserProvider";
import axios from "axios";
import { isEmpty } from "lodash";
import { useLoaderProvider } from "../providers/LoaderProvider";

const DocumentUploader = ({
    name,
    label,
    formik,
    metadata,
    acceptedTypes,
    disabled,
    labelClassName
}) => {
  const { Dragger } = Upload;
  const { user } = useUserProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();

  let value = formik?.values[name];

  const defaultFileData = {
    id: null,
    name: null,
  };

  const [isFileSizeExceed, setIsFileSizeExceed] = useState(false);
  const [fileData, setFileData] = useState(defaultFileData);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false)

  const newMetadata =
    (isEmpty(metadata) ? formik?.values?.media_metadata : metadata) || {};

  const resetFileData = () => {
    formik.setFieldValue(name, "");
    formik.setFieldTouched(name);
    setFileData(defaultFileData);
  };

  const uploadOverride = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + user.idToken,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append("type", "file");
    fmData.append("file", file);
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/media`,
        fmData,
        config
      );

      const data = res.data.data;

      formik.setFieldValue(name, "media:" + data.id);
      formik.setFieldTouched(name);

      const fd = { id: data.id, name: data.filename, filename: data.filename };
      setFileData(fd);

      if (!newMetadata[data?.id]) {
        const newMeta = { ...newMetadata, [data?.id]: fd };
        formik.setFieldValue("media_metadata", newMeta);
      }
      onSuccess("Ok");
      setLoading(false);
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
      setLoading(false);
    }
  };

  const draggerProps = {
    accept: acceptedTypes,
    name: "file",
    multiple: false,
    customRequest: uploadOverride,
    showUploadList: false,
    maxCount: 1,
    disabled,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    beforeUpload(file) {
      if (!isFileSizeExceed) {
        if (file.size > 1_073_741_824) {
          setIsFileSizeExceed(true);
          return false;
        }
      }

      setIsFileSizeExceed(false);
      return true;
    },
  };

  const getNestedFieldValue = (errors, arrayFields) => {
    if (arrayFields.length > 1) {
      const arrayFieldsCopy = arrayFields;
      const field = arrayFieldsCopy[0];
      arrayFieldsCopy.splice(0, 1);
      return getNestedFieldValue(errors[field], arrayFieldsCopy);
    }
    return errors && errors[arrayFields[0]];
  };

  useEffect(() => {
    if (
      value &&
      /^media:[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        value
      )
    ) {
      const uuid = value.match(
        /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      )[0];

      if (!fileData.id) {
        if (newMetadata && newMetadata[uuid]) {
          setFileData({
            id: newMetadata[uuid].id,
            name: newMetadata[uuid].filename,
          });
        } else {
          setFileData(defaultFileData);
        }
      }
    }
  }, [value, newMetadata]);


  useEffect(() => {
    doSetShowLoadingModal(loading);
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col gap-1.5 pb-4">
      <label
      className={`text-lg mb-3 ${labelClassName ?? ""}`}
      htmlFor={name}
      >
      {label}
      </label>
      {
        !fileData?.id && (
          <Dragger {...draggerProps}>
            <p className="justify-center w-full flex">
            <img src={UploadFile} alt="Upload File" />
            </p>
            <p className="text-lg py-5"> Drag and drop your file here or click to browse your files</p>
        </Dragger>
        )
      }
      {!!fileData.id && (
        <div className="flex mt-2 w-full">
          <input
            id={name}
            name={name}
            type="hidden"
            value={formik.values[name]}
          />
          <span className="w-full text-lg">Filename: {fileData.name}</span>
          <button
            className="ml-2"
            onClick={() => resetFileData()}
            disabled={disabled}
          >
            <TrashIcon
              className={`h-5 w-5 fill-current text-gray-400 trash ${
                !disabled && "hover:text-red-600"
              }`}
            />
          </button>
        </div>
      )}
      {getNestedFieldValue(formik.touched, name.split(".")) &&
        getNestedFieldValue(formik.errors, name.split(".")) && (
          <div className="text-red-600">
            {getNestedFieldValue(formik.errors, name.split("."))}
          </div>
        )}
      {isFileSizeExceed && (
        <div className="text-red-600">
          Maximum file size exceeded. Video must be less than 1 GB
        </div>
      )}
    </div>
  )
}

export default DocumentUploader