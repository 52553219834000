import { useCallback, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { ResendCreatorSetupPassword, UnlinkCreator } from "../services/api/creator"
import { GetAppResource } from "../services/api/app";

import DeleteItem from "../components/modal_contents/DeleteItem"
import { ReactComponent as UnlinkIcon } from "../assets/icons/unlink.svg";
import moment from "moment";

const CreatorList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'creators')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const onResendClick = useCallback(
    (userUuid) => (callback) => {
      ResendCreatorSetupPassword(userUuid, user.idToken, user.currentApp())
        .then((res) => {
          callback(null, res);
        })
        .catch((err) => {
          callback(err, null);
        });
    },
    [user]
  );

  const deleteClass = "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600"

  const metadata = [
    {
      name: 'key',
      label: 'Key',
      parser: (data) => (data.id),
    },
    {
      name: 'name',
      label: 'Name',
      parser: (data) => (data.name),
    },
    {
      name: 'email',
      label: 'Email',
      parser: (data) => (data.email),
    },
    {
      name: 'last_login',
      label: 'Last Login',
      parser: (data) => {
        if (!data.last_login) return "-";
        const utc = moment.utc(data.last_login).toDate();
        return moment(utc).local().format("DD-MMM-YYYY hh:mm:ss A");
      },
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onDeleteClick={showUnlinkModal}
          item_id={data.id}
          deleteIcon={UnlinkIcon}
          onResendClick={onResendClick(data.id)}
          deleteClass={deleteClass}
        />
      ),
    },
  ];

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={() => history.push('/creators/new') }>Create New Creator</button>
  }

  
  const unlinkButtonHandler = async (id) => {
    await UnlinkCreator(user, id)
    loadData()
    closeModal()
  }

  const showUnlinkModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel="creator" onDelete={unlinkButtonHandler} onClose={closeModal} deleteAction="unlink" />)
  }

  const links = [
    { name: "Home", url: "/" },
    { name: "Creators", url: "/creators" },
  ];

  return (
    <Page links={links} title="CREATORS" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={['name', 'email']}
      />
    </Page>
  )
}

export default CreatorList;
