import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import { DeleteTag } from "../services/api/tag";
import { GetAppResource } from "../services/api/app";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { CloneItem } from "../services/api/clone";
import { Tooltip } from "@material-ui/core";
import PriorityHighIcon from "../assets/images/expired.png";

const TagList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    metadata: {},
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, "tags")
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Tags", url: "/tags" },
  ];

  //Unlock Code (formerly Tagging ID) , Notification Email (formerly Your Email) , Tagging Name ,Tag ,Tag Created for
  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "category",
      label: "Type",
      parser: (data) => <div>{data.category}</div>,
    },
    {
      name: "unlock_code",
      label: "Unlock Code",
      parser: (data) => data.unlock_code,
    },
    {
      name: "name",
      label: "Tag Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "tag",
      label: "Tag",
      parser: (data) => (
        <div className="relative">
          {data.is_expired ? (
            <Tooltip title={<div>Expired</div>}>
              <div>
                <img
                  src={PriorityHighIcon}
                  alt="expired"
                  className="absolute top-1 -left-5 h-4 w-4"
                />
                {data.code}
              </div>
            </Tooltip>
          ) : (
            <div>{data.code}</div>
          )}
        </div>
      ),
    },
    {
      name: "email",
      label: "Notification Email",
      parser: (data) => data.email,
    },
    {
      name: "send_email",
      label: "Send Email",
      parser: (data) => `${data.send_email}`,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          onCloneClick={ data.category === 'TagBundle' ? cloneBundleHandler: cloneButtonHandler}
          item_id={data.id}
          noActions={data.name?.toLowerCase?.() === "free"}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/tags/new`);
  };

  const AddButton = () => {
    return (
      <button className="ttnk-button add-button" onClick={handleAddButtonClick}>
        Add Tag
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/tags/${id}`);
  };

  const cloneButtonHandler = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: "tags",
      },
    });
    const cloneData = await res.json();
    const cloneId = cloneData.data?.id;

    loadData();
    history.push(`/tags/${cloneId}`);
  };

  const cloneBundleHandler = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: "tag_bundles",
      },
    });
    const cloneData = await res.json();
    const cloneId = cloneData.data?.id;

    loadData();
    history.push(`/tags/${cloneId}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteTag(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Tag"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page links={links} title="Tags" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "tag", "email", "notes"]}
      />
    </Page>
  );
};

export default TagList;
