import actionFormListingConfig from "./listing";
import actionFormProfileConfig from "./profile";

const actionformConfig = (type) => {
    switch (type) {
      case 'Topic':
        return actionFormListingConfig
      default: 
        return actionFormProfileConfig
    }
}

export default actionformConfig