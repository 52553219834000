const Heading = ({ className, children }) => {
  return (
    <div className="flex justify-center items-center">
      <div>
        <div className="text-center">
          <h1 className={className ?? ''}>{children ?? '' }</h1>
        </div>
        {/* <p className="text-gray-400 max-w-xs text-center mt-4 mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
      </div>
    </div>
  );
};

export default Heading;
