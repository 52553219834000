import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../assets/images/ae-icon-loading.png'
import { Link } from 'react-router-dom'

const AppBuilding = props => {
    return (
        <div className="absolute top-0 left-0 w-full h-full flex flex-col space-y-5 items-center justify-center">
            <img src={Logo} className="w-48"/>
            <p className="text-xl">We are now building your app. Pls. check the <Link to="/change_application" className="text-xl">App List</Link> after a few moments</p>
        </div>
    )
}

AppBuilding.propTypes = {

}

export default AppBuilding
