const podcastsConfig = {
  label: "Podcast",
  resourceEndpoint: "podcasts",
  listMetadata: [
    "sort_order",
    "title",
    "topics",
    "tags",
    "actions",
  ],
  keepListMetadataOrder: true,
  searchKeys: ["title"],
  detailsMetadata: [
    { name: "title", label: "Title"},
    { name: "description", label: "Description" },
    { name: "image_url", label: "Image URL"},
    { name: "release_date", label: "Release Date", format: "DD-MMM-YYYY"},
    { name: "sort_order", label: "Sort Order"},
    { name: "topics", label: "Topics/Listings"},
    { name: "tags", label: "Tags"},
    { name: "action_bar_text", label: "Call to Action Text"},
    { name: "action_bar_url", label: "Call to Action URL"},
    { name: "rss_url", label: "Podcast RSS Feed URL", placeholder: "https://feeds.simplecast.com/xl36XBC2"},
    { name: "web_url", label: "Podcast Web URL", placeholder: "https://serialpodcast.org/"},

  ],
  formLayout: {
    columns: [
      [
        "title",
        "description",
        "image_url",
        "release_date",
        "topics",
        "sort_order",
      ],
      [
        "tags",
        "action_bar_text",
        "action_bar_url",
        "rss_url",
        "web_url",
      ],
    ],
  }
}

export default podcastsConfig;
