import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  TextArea,
  Select,
} from "../components/EntryForm";
import DataSelector from "../components/DataSelector";
import Page from "../components/Page";

import { GetAppResource, AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { Radio, Space } from "antd";

import { useFormik } from "formik";

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { IsLowerVersion, IsWithinMinimumVersion } from "../lib/useVersionizer";

const PushNotification = ({ newUser, match }) => {
  const [initialValues, setInitialValues] = useState({
    segmentsList: [],
    title: "",
    sub_title: "",
    description: "",
    launch_url: "",
    send_to: "",
    app_destination: "app",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [appDestination, setAppDestination] = useState("app");
  const [segments, setSegments] = useState([]);
  const [saved, setSaved] = useState(false);
  const history = useHistory();
  const [recipient, setRecipient] = useState("all");

  const [userCount, setUserCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  useEffect(() => {
    setIsLoading(true);
    GetAppResource(user, "push_notifications").then((response) =>
      responseProcessor({
        response,
        showModal,
        onSuccess: () => {
          successHandler(response);
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (user.app_data.setup.free) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.app_data?.setup?.free]);

  const successHandler = (res) => {
    res.json().then((res) => {
      const about = res.data;
      setUserCount(res.data.total_users);
      setSubscriberCount(res.data.current_users);
      setSegments(res.data.segments);
      setDestinations(res.data.destinations);
      formik.setFieldValue(
        "segmentsList",
        segments.map((st) => ({ id: st }))
      );
    });
  };

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "Push Notifications", url: "/push_notification" },
  ];

  let schema = {
    title: validations.short_text_required,
    sub_title: validations.short_text,
    description: validations.long_text_required,
    launch_url: validations.long_text.when("app_destination", {
      is: "external",
      then: (schema) => schema.required("Required"),
    }),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values, { resetForm }) => {
      let segs = [];

      if (recipient === "all") {
        segs = segments;
      } else {
        segs = values.segmentsList.map((st) => st.id);
      }

      let payload = {
        ...values,
        segments: segs,
      };

      delete payload.segmentsList;
      delete payload.app_destination;

      AddAppResource(user.currentApp(), "push_notifications", user.idToken, {
        data: payload,
      }).then((response) =>
        responseProcessor({
          response,
          showModal,
          onSuccess: () => {
            setSaved(true);
            setIsLoading(true);
            setInitialValues({
              segmentsList: [],
              title: "",
              sub_title: "",
              description: "",
              launch_url: "",
              send_to: "",
              app_destination: "app",
            });
            setRecipient("all");
            resetForm();
            setIsLoading(false);
          },
          toastMessage: "Message Sent!",
        })
      );
    },
  });

  const SegmentSelector = () => (
    <div className="mt-5">
      <span>Who should receive this message?</span>
      <DataSelector
        name="segmentsList"
        formik={formik}
        label=""
        dataSet={segments.map((st) => ({ name: st, id: st }))}
      />
    </div>
  );

  const radioChangeHandler = (val) => {
    if (val === "all") {
      formik.setFieldValue(
        "segmentsList",
        segments.map((st) => ({ id: st }))
      );
    } else {
      formik.setFieldValue("segmentsList", []);
    }

    setRecipient(val);
  };

  const sendValues = [
    { id: "video", name: "Video" },
    { id: "audio", name: "Audio" },
    { id: "article", name: "Article" },
    { id: "journal", name: "Journal" },
    { id: "question", name: "Question" },
    { id: "product", name: "Product" },
    { id: "offer", name: "Offer" },
    { id: "tip", name: "Tip" },
    { id: "tips", name: "Tips" },
    { id: "track", name: "Track" },
    { id: "gallery", name: "Gallery" },
    { id: "course", name: "Course" },
    { id: "topic", name: "Topic" },
    { id: "resource", name: "Resource" },
    { id: "directory", name: "Directory" },
    { id: "onlineevent", name: "Online Event" },
    { id: "personevent", name: "Person Event" },
    { id: "virtualevent", name: "Virtual Event" },
    { id: "lesson", name: "Lesson" },
    { id: "message", name: "Message" },
    { id: "summit", name: "Summit" },
  ];

  const personEventIndex = sendValues.findIndex(
    (value) => value.id === "personevent"
  );

  const actionListValue = user.app_data.setup.action_lists;

  if (actionListValue && IsWithinMinimumVersion(8.5))
    sendValues.push({ id: "actionlist", name: "Action Lists" });
  if (IsLowerVersion(8.5))
    sendValues[personEventIndex] = {
      id: "personevent",
      name: "In-Person Event",
    };

  const SavedLabel = () => (
    <span className="text-green-600">Message Sent!</span>
  );

  const handleChangeAppDestination = (dest) => {
    const fieldToReset = dest == "app" ? "launch_url" : "send_to";
    const fieldValue = dest == "app" ? "" : null;

    formik.setFieldValue("app_destination", dest);
    formik.setFieldValue(fieldToReset, fieldValue);
  };

  return (
    <Page links={links} title="Push Notifications">
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
        savedLabel={saved && !formik.dirty && SavedLabel()}
        removeStyle={true}
      >
        <div className="w-full mb-8">
          <div className="w-full flex">
            <div className="w-1/2 bg-white p-12 mt-5 ttnk-table-container">
              <h1 className="uppercase font-bold font-header text-xl">
                I. Audience
              </h1>
              <h2 className="font-bold font-header text-lg">
                Who will receive this message?
              </h2>
              <Radio.Group
                onChange={(e) => {
                  radioChangeHandler(e.target.value);
                  formik.validateFied("launch_url");
                }}
                value={recipient}
              >
                <Space direction="vertical">
                  <Radio value="all">Send to all segments</Radio>
                  <Radio value="segments">
                    Send to a particular segment
                    {recipient === "segments" && <SegmentSelector />}
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="w-1/4 mx-5 bg-gradient-to-br from-aeblue-dark to-aeblue-mid p-12 mt-5 ttnk-table-container">
              <h2 className="font-bold font-header text-3xl uppercase text-white">
                App Users
              </h2>
              <h1 className="uppercase font-bold font-header text-6xl text-white">
                {userCount}
              </h1>
              <a
                className="flex text-white uppercase items-center text-md"
                href="https://app.onesignal.com/"
              >
                View Stats and other info on OneSignal &nbsp;&#9654;
              </a>
            </div>
            <div className="w-1/4 bg-gradient-to-br from-aeblue-dark to-aeblue-mid p-12 mt-5 ttnk-table-container">
              <h2 className="font-bold font-header text-3xl uppercase text-white">
                Subscribers
              </h2>
              <h1 className="uppercase font-bold font-header text-6xl text-white">
                {subscriberCount}
              </h1>
              <a
                className="flex text-white uppercase items-center text-md"
                href="https://app.onesignal.com/"
              >
                View Stats and other info on OneSignal &nbsp;&#9654;
              </a>
            </div>
          </div>
          <div className="bg-white p-5 mt-5 ttnk-table-container">
            <h1 className="uppercase font-bold font-header text-xl mx-5 mt-12">
              II. Message
            </h1>
            <div className="flex">
              <Column>
                <Input name="title" type="text" label="Title" formik={formik} />
                <Input
                  name="sub_title"
                  type="text"
                  label="Sub-Title"
                  formik={formik}
                />
                <TextArea
                  name="description"
                  type="text"
                  label="Push Message"
                  formik={formik}
                />
              </Column>
              <Column>
                <div className="mb-5">
                  <h2 className="font-bold font-header text-lg">Send To:</h2>
                  <Radio.Group
                    onChange={(e) => {
                      handleChangeAppDestination(e.target.value);
                    }}
                    value={formik.values.app_destination}
                  >
                    <Space direction="vertical">
                      <Radio value="app">Within the App</Radio>
                      <Radio value="external">Outside the App</Radio>
                    </Space>
                  </Radio.Group>
                </div>
                {formik.values.app_destination === "app" ? (
                  <>
                    <Select
                      name="send_to"
                      type="text"
                      label="App Location"
                      formik={formik}
                    >
                      <option value={null} label="Just open the app" />
                      {sendValues
                        .filter((val) => destinations?.includes(val.id))
                        .map((s) => (
                          <option value={s.id} label={s.name} key={s.id} />
                        ))}
                    </Select>
                  </>
                ) : (
                  <Input
                    name="launch_url"
                    type="text"
                    label="Launch URL"
                    formik={formik}
                  />
                )}
              </Column>
            </div>
          </div>
        </div>
      </EntryForm>
    </Page>
  );
};

export default PushNotification;
