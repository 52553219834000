/* eslint-disable jsx-a11y/anchor-is-valid */
import { Switch, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash.svg";
import { useRef, useState } from "react";
import EyeIcon from "../assets/icons/eye.svg";
import CopyIcon from "../assets/images/copy.png";
import EyeClosed from "../assets/icons/eye-close.svg";
import get from "lodash.get";
import { useUserProvider } from "../providers/UserProvider";
import { Editor } from "@tinymce/tinymce-react";
import VersionizerWrapper from "./Versionizer";
import Button from "./Button";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { Tooltip } from "@material-ui/core";

export const EntryForm = ({
  isLoading,
  submitHandler,
  cancelHandler,
  cancelURL,
  formik,
  savedLabel,
  additionalActions,
  children,
  removeStyle,
  submitLabel,
  deleteHandler,
  deleteLabel,
  hideSubmit,
  deleteBtnType,
  buttonModifier,
  isReadOnly = false,
  isFullRow = false,
  floatSubmitBtnRight = false,
  applyDefaultButtonStyle = true,
}) => {
  const style = removeStyle ? "" : "bg-white p-5 mt-5 ttnk-table-container";
  const deleteBtnStyle =
    deleteBtnType === "alert" ? "ttnk-danger-button" : "ttnk-button";
  const deleteBtnIconColor =
    deleteBtnType === "alert"
      ? "text-white"
      : "text-gray-400 hover:text-red-600";
  const history = useHistory();
  const hasError = Object.keys(formik.errors).length > 0;

  return (
    <form onSubmit={submitHandler}>
      <div className={style}>
        <div
          className={
            isFullRow ? "flex flex-col w-full" : "flex flex-row flex-wrap"
          }
        >
          {children}
        </div>
        <div
          className={`flex items-center space-x-5 px-5 ${
            floatSubmitBtnRight ? "justify-end" : ""
          } ${buttonModifier ?? ""}`}
        >
          {!hideSubmit &&
            (applyDefaultButtonStyle ? (
              <button
                disabled={isLoading || !formik.dirty}
                className="ttnk-button"
                type="submit"
              >
                <svg
                  height="14px"
                  width="14px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 -46 417.81333 417"
                  className="mr-2"
                >
                  <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
                </svg>{" "}
                {/* Submit */}
                {submitLabel || "Submit"}
              </button>
            ) : (
              <Button isDisabled={isLoading || !formik.dirty || hasError}>
                {submitLabel || "Submit"}
              </Button>
            ))}{" "}
          {!!deleteHandler && (
            <>
              <button
                disabled={isLoading}
                onClick={deleteHandler}
                className={deleteBtnStyle}
                type="button"
              >
                <DeleteIcon
                  className={`mr-2 h-5 w-5 fill-current trash ${deleteBtnIconColor}`}
                />{" "}
                {/* Submit */}
                {deleteLabel || "Delete"}
              </button>{" "}
            </>
          )}
          {(!!cancelHandler || cancelURL) && (
            <>
              <button
                className="ttnk-button ttnk-button-outlined"
                onClick={
                  cancelHandler
                    ? cancelHandler
                    : () => {
                        history.push(cancelURL);
                      }
                }
                type="button"
              >
                <svg
                  height="12px"
                  width="12px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 426.667 426.667"
                  className="mr-2"
                >
                  <path d="M213.333,0C95.467,0,0,95.467,0,213.333s95.467,213.333,213.333,213.333S426.667,331.2,426.667,213.333S331.2,0,213.333,0z M42.667,213.333c0-94.293,76.373-170.667,170.667-170.667c39.467,0,75.627,13.547,104.533,35.947L78.613,317.867C56.213,288.96,42.667,252.8,42.667,213.333z M213.333,384c-39.467,0-75.627-13.547-104.533-35.947L348.053,108.8C370.453,137.707,384,173.867,384,213.333C384,307.627,307.627,384,213.333,384z" />
                </svg>{" "}
                {isReadOnly ? 'Close' : 'Cancel'}
              </button>{" "}
              {additionalActions}
            </>
          )}
          {savedLabel}
        </div>
      </div>
    </form>
  );
};

export const getNestedFieldStatus = (name, formik) => {
  const isNested = name?.indexOf(".") > -1 || name?.indexOf("[") > -1;
  const isTouched = get(formik?.touched, name) ?? false;
  const error = get(formik?.errors, name) ?? null;

  return { isNested, isTouched, error };
};

const getNestedFieldValue = (name, formValues) => {
  const paths = name?.split(".");
  let value = formValues;
  paths.forEach((path) => {
    if (typeof value[path] !== "undefined") {
      value = value[path];
    }
  });
  return value;
};

export const Column = ({ children, width = "w-1/2" }) => (
  <div className={`${width} px-5 ttnk-column`}>{children}</div>
);

export const InputContainer = ({ target, label, children }) => (
  <div className="flex flex-row">
    <label className="flex-item" htmlFor={target}>
      {label}
    </label>
    {children}
  </div>
);

export const Input = (props) => {
  const [showPasswordValue, setShowPasswordValue] = useState(false);

  const {
    name,
    type,
    label,
    formik,
    prefix,
    min,
    max,
    disabled,
    placeholder,
    isFieldRow = false,
    fieldDescription = "",
    required = false,
    withCopyButton = false,
    fieldDescriptionClassName = "",
    prefixClassName
  } = props;
  const { isNested, isTouched, error } = getNestedFieldStatus(name, formik);
  const value = get(formik.values, name);
  const fallbackVal = type === "number" ? 0 : "";

  return (
    <div
      className={`flex ${
        isFieldRow ? "flex-row" : "flex-col"
      } mb-3 ttnk-form-input ttnk-form-input-${type} relative ${String(
        props?.wrapperClassName
      )}`}
    >
      <label
        className={`text-lg mb-3 ${props.labelClassName ?? ""} ${
          isFieldRow ? "w-1/3" : ""
        }`}
        htmlFor={name}
      >
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
        {withCopyButton && (
          <Tooltip title={<div>Copy</div>}>
            <img
              src={CopyIcon}
              className="h-5 ml-2 w-5 cursor-pointer hover:scale-125"
              alt="copy"
              onClick={() => navigator.clipboard.writeText(value)}
            />
          </Tooltip>
        )}
      </label>
      <div className={`flex ${isFieldRow ? "w-2/3" : ""} ${props.inputClassName ?? ""}`}>
        {!!prefix && <span className={`ttnk-form-input-prefix ${prefixClassName}`}>{prefix}</span>}
        <div className="w-full">
          <input
            id={name}
            name={name}
            type={showPasswordValue ? "text" : type}
            disabled={disabled}
            readOnly={disabled}
            className={`border border-gray-300 text-lg p-2 rounded-lg w-full ${
              disabled ? "opacity-60" : ""
            }`}
            onChange={formik.handleChange}
            value={isNested ? value : formik.values[name] || fallbackVal}
            min={min}
            max={max}
            maxLength={type === "text" && name !== "fcm_key" ? 255 : null}
            placeholder={placeholder}
          />
          <div className="">
            <div className="flex-1">
              {isTouched && !!error && (
                <div className="text-red-600">{error}</div>
              )}
            </div>
            <div className="flex-1">
              {fieldDescription && (
                <div className={`${fieldDescriptionClassName || "mt-3 text-xs text-gray-400"}`}>
                  {fieldDescription}
                </div>
              )}
            </div>
          </div>
        </div>
        {type === "password" && (
          <div className="absolute right-0 my-auto self-center flex px-3">
            <button
              onClick={() => setShowPasswordValue((prevState) => !prevState)}
              form="#"
              className="border-0 bg-trasparent"
            >
              <img src={showPasswordValue ? EyeClosed : EyeIcon} alt="Eye" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const InputReadonly = ({ name, label, formik, tooltip = null }) => {
  const { isNested } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);

  const inputSelect = (event) => {
    event.target.select();
  };

  return (
    <div className="flex flex-col mb-5 ttnk-form-input ttnk-form-input-text relative">
      <label className="text-lg mb-3 ">
        {label}
        {tooltip && <span className="text-sm">&nbsp;({tooltip})</span>}
      </label>
      <div className="flex">
        <input
          id={name}
          name={name}
          type="text"
          className="font-mono border border-gray-200 bg-gray-100 text-lg p-2 rounded-lg"
          readOnly={true}
          value={isNested ? value : formik.values[name] || ""}
          onFocus={inputSelect}
          onClick={inputSelect}
        />
      </div>
    </div>
  );
};

export const TextArea = ({
  name,
  label,
  rows,
  formik,
  children,
  disabled,
  className,
  placeholder,
  isFieldRow = false,
  fieldDescription,
  required = false,
  withCopyButton = false,
  labelClassName = ''
}) => {
  const { isNested, isTouched, error } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);
  return (
    <div className={`flex mb-5 ${isFieldRow ? "flex-row" : "flex-col"}`}>
      {label && (
        <label
          className={`text-lg mb-3 ${isFieldRow ? "w-1/3 pr-2" : ""} ${labelClassName}`}
          htmlFor={name}
        >
          {label}
          {withCopyButton && (
            <Tooltip title={<div>Copy</div>}>
              <img
                src={CopyIcon}
                className="h-5 ml-2 w-5 cursor-pointer hover:scale-125"
                alt="copy"
                onClick={() => navigator.clipboard.writeText(value)}
              />
            </Tooltip>
          )}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}
      <div className={`${isFieldRow ? "w-2/3" : ""}`}>
        <textarea
          id={name}
          name={name}
          className={`border border-gray-300 text-lg p-2 rounded-lg ${
            className ?? "w-full"
          } ${disabled ? "opacity-60" : ""}`}
          onChange={formik.handleChange}
          rows={rows}
          value={isNested ? value : formik.values[name] || ""}
          disabled={disabled}
          readOnly={disabled}
          placeholder={placeholder}
        />

        <div className="">
          {isTouched && !!error && <div className="text-red-600">{error}</div>}
          {fieldDescription && (
            <div className="mt-3 text-xs text-gray-400">{fieldDescription}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const CheckBox = ({
  name,
  label,
  subLabel = <></>,
  formik,
  children,
  disabled,
  width,
  onChange = undefined,
  isInline = false,
  isInlineNotReverse = false,
}) => {
  // added width for custom widths so they can appear beside each other,
  // use tailwind style values
  // for e.g. if you want to set w-1/2 use width = "1/2"

  const { isNested, isTouched, error } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);
  return (
    <div
      className={`${
        width
          ? "inline-block w-" + width + " mb-5"
          : `${
              isInline
                ? "flex-row-reverse gap-2"
                : isInlineNotReverse
                ? "gap-2"
                : "flex-col"
            } flex mb-5`
      }`}
    >
      <label className={`${width && "flex"} text-lg mb-3`} htmlFor={name}>
        {label} {subLabel}
      </label>
      <div>
        <Switch
          id={name}
          name={name}
          checked={isNested ? value : formik.values[name]}
          disabled={disabled}
          onChange={(checked) => {
            formik.setFieldValue(name, checked);
            onChange && onChange(checked);
          }}
        />
      </div>
      {isTouched && !!error && <div className="text-red-600">{error}</div>}
    </div>
  );
};

export const Tickbox = ({ name, label, formik, disabled, required }) => {
  return (
    <div className="flex flex-row items-center mb-3 w-full gap-2">
      <input
        type="checkbox"
        name={name}
        value={formik.values[name] || false}
        checked={formik.values[name] || false}
        onChange={(evt) => formik.setFieldValue(name, evt.target.checked)}
        disabled={disabled}
        required={required}
      />

      <label className={`text-lg`} htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export const CheckboxGroup = ({ name, label, formik, children, disabled }) => {
  const { isNested, isTouched, error } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);
  return (
    <div className="flex flex-col mb-5">
      <label className="text-lg mb-3" htmlFor={name}>
        {label}
      </label>
      <div>
        <Checkbox.Group
          options={children}
          name={name}
          defaultValue={formik.values[name]}
          onChange={(checked) => {
            formik.setFieldValue(name, checked);
          }}
        />
      </div>
      {isTouched && !!error && <div className="text-red-600">{error}</div>}
    </div>
  );
};

export const Select = ({
  name,
  label,
  formik,
  children,
  onChange,
  isFieldRow = false,
  required = false,
  disabled = false,
}) => {
  const { isNested, isTouched, error } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);
  return (
    <div className={`flex  ${isFieldRow ? "flex-row" : "flex-col"} mb-5`}>
      <label
        className={`text-lg mb-3 ${isFieldRow ? "w-1/3" : ""}`}
        htmlFor={name}
      >
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </label>
      <div className={`${isFieldRow ? "w-2/3" : ""}`}>
        <select
          disabled={disabled}
          id={name}
          name={name}
          className="w-full border border-gray-300 text-lg p-2 rounded-lg"
          onChange={(e) => {
            formik.handleChange(e);
            onChange?.(e);
          }}
          onBlur={formik.handleBlur}
          value={isNested ? value : formik.values[name] || ""}
        >
          {children}
        </select>
        {(required || isTouched) && !!error && (
          <div className="text-red-600">{error}</div>
        )}
      </div>
    </div>
  );
};

export const RichText = ({
  id,
  name,
  quillName,
  placeholder,
  label,
  rows,
  formik,
  children,
  disabled,
  contentType,
  isFieldRow,
}) => {
  const { user } = useUserProvider();
  const editorRef = useRef(null);
  const [currentTab, setCurrentTab] = useState("app");
  const { doSetShowLoadingModal } = useLoaderProvider();
  const [hasLoaded, setHasLoaded] = useState(false);

  const currentName =
    currentTab === "app"
      ? name
      : typeof quillName === "undefined"
      ? `web_${name}`
      : quillName;

  const { isTouched, error } = getNestedFieldStatus(currentName, formik);

  const value = getNestedFieldValue(currentName, formik.values);
  const setup = user.app_data.setup;
  const url = window.location.href;

  const changeHandler = (content) => {
    if (currentName?.includes("web"))
      formik.setFieldValue(currentName, content);
  };

  // list of excluded for RTE
  if (
    !user.app_data.setup.web ||
    [
      "journals",
      "lessons",
      "galleries",
      "summits",
      "action-lists",
      "podcasts",
      "documents",
    ].includes(contentType) ||
    (typeof contentType === "undefined" &&
      !["web_text", "text"].includes(currentName))
  ) {
    return (
      <div className="flex flex-col mb-5">
        <label className="text-lg mb-3" htmlFor={name}>
          {label}
        </label>

        <TextArea
          name={currentName}
          type="text"
          formik={formik}
          rows={rows || 2}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {setup?.actionclips &&
        url.includes("videos") &&
        name === "description" &&
        formik.values.actionclip && (
          <VersionizerWrapper minVersion={9}>
            <div className="font-bold mb-4">
              Fill-up column below if ActionClip is also being used as a regular
              video
            </div>
          </VersionizerWrapper>
        )}
      <div className="flex justify-between items-center">
        <label className="text-lg mb-3" htmlFor={name}>
          {label}
        </label>

        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li>
            <button
              type="button"
              className={`h-full inline-block p-4 rounded-t-lg ${
                currentTab === "app"
                  ? "active text-white bg-aeblue dark:bg-aeblue dark:text-white"
                  : "border border-aeblue text-aeblue hover:text-gray-600 hover:bg-gray-50 dark:blue-800 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }`}
              onClick={() => setCurrentTab("app")}
            >
              App
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`h-full inline-block p-4 rounded-t-lg ${
                currentTab === "web"
                  ? "active text-white bg-aeblue dark:bg-aeblue dark:text-white"
                  : "border border-aeblue text-aeblue hover:text-gray-600 hover:bg-gray-50 dark:blue-800 dark:hover:bg-gray-800 dark:hover:text-gray-300"
              }`}
              onClick={() => setCurrentTab("web")}
            >
              Web
            </button>
          </li>
        </ul>
      </div>

      <div className={currentTab === "app" ? "block" : "hidden"}>
        <TextArea
          name={currentName}
          type="text"
          formik={formik}
          rows={contentType === "videos" ? 10 : rows}
        />
      </div>
      <div
        className={`${
          currentTab === "web" && hasLoaded ? "block" : "hidden"
        } mb-5`}
      >
        <Editor
          tinymceScriptSrc={`${process.env.REACT_APP_TINYMCE_PUBLIC_URL}/tinymce/tinymce.min.js`}
          onInit={(evt, editor) => {
            if (!editor) doSetShowLoadingModal(true);
            if (editor) {
              setHasLoaded(true);
              doSetShowLoadingModal(false);
            }
          }}
          scriptLoading={{ async: true }}
          value={value}
          onEditorChange={changeHandler}
          init={{
            license_key: "gpl",
            verify_html: false,
            custom_elements: "style",
            valid_children: "+body[style]",
            min_height: contentType === "videos" ? 245 : 490,
            menubar: false,
            table_toolbar: "",
            elementpath: false,
            branding: false,
            link_default_protocol: "https",
            link_title: false,
            link_default_target: "_blank",
            table_advtab: false,
            table_row_advtab: false,
            contextmenu_never_use_native: false,
            contextmenu: false,
            format_empty_lines: true,
            resize: true,
            formats: {
              alignleft: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "left",
              },
              aligncenter: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "center",
              },
              alignright: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "right",
              },
              alignjustify: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "full",
              },
              bold: [
                { inline: "span", classes: "bold" },
                { inline: "p", classes: "bold" },
              ],
              italic: [
                { inline: "span", classes: "italic" },
                { inline: "p", classes: "italic" },
              ],
              underline: [
                { inline: "span", classes: "underline" },
                { inline: "p", classes: "underline" },
              ],
              strikethrough: [
                { inline: "span", deep: true, classes: "strikethrough" },
                { inline: "p", deep: true, classes: "strikethrough" },
              ],
              forecolor: [
                {
                  inline: "span",
                  classes: "forecolor",
                  styles: { color: "%value" },
                },
                {
                  inline: "p",
                  classes: "forecolor",
                  styles: { color: "%value" },
                },
              ],
              // h1: {
              //   block: 'div',
              //   classes: 'h1'
              // },
              // h2: {
              //   block: 'div',
              //   classes: 'h2'
              // },
              // h3: {
              //   block: 'div',
              //   classes: 'h3'
              // },
              // h4: {
              //   block: 'div',
              //   classes: 'h4'
              // },
              // h5: {
              //   block: 'div',
              //   classes: 'h5'
              // },
              // h6: {
              //   block: 'div',
              //   classes: 'h6'
              // },
              // pre: {
              //   block: 'div',
              //   classes: 'pre'
              // },
            },
            plugins: [
              "autolink",
              "lists",
              "link",
              "image",
              "anchor",
              "searchreplace",
              "code",
              "fullscreen",
              "media",
              "table",
              "code",
            ],
            toolbar:
              "blocks fontfamily fontsize | " +
              "bold italic underline strikethrough forecolor | " +
              "alignleft aligncenter alignright alignjustify" +
              "| bullist numlist | image link table code | removeformat",
            toolbar_mode: "wrap",
            font_size_formats:
              "8px 9px 10px 11px 12px 14px 18px 22px 24px 30px 36px 48px 60px 72px 96px",
            extended_valid_elements:
              "script[language|type|src],div[*],a[*],style[*]",
            valid_elements:
              "@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|" +
              "onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|" +
              "onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|" +
              "name|href|target|title|class|onfocus|onblur],strong/b,em/i,strike,u," +
              "#p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|" +
              "src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup," +
              "-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|" +
              "height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|" +
              "height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot," +
              "#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor" +
              "|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,-div," +
              "-span,-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face" +
              "|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite]," +
              "object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width" +
              "|height|src|*],script[src|type],map[name],area[shape|coords|href|alt|target],bdo," +
              "button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|" +
              "valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method]," +
              "input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value]," +
              "kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value]," +
              "q[cite],samp,select[disabled|multiple|name|size],small," +
              "textarea[cols|rows|disabled|name|readonly],tt,var,big," +
              "iframe[width|height|src|title|frameborder|allow|allowfullscreen|srcdoc|loading|name|referrerpolicy|sandbox|" +
              "allowpaymentrequest]",
            font_family_formats: `
              Open Sans=Open Sans, sans-serif;
              Montserrat=Montserrat;
              Aleo=Aleo;
              Delius=Delius;
              Great Vibes=Great Vibes, GreatVibes;
              Merriweather=Merriweather;
              Nunito=Nunito;
              Oswald=Oswald;
              Playfair Display=Playfair Display, PlayfairDisplay;
              SegoeUI=SegoeUI;
              Verdana=Verdana;
              Raleway=Raleway
            `,
            content_style: `
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Aleo&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Delius&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Merriweather&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Nunito&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Oswald&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Raleway&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
  
              @font-face {
                font-family: "SegoeUI";
                src: local("SegoeUI"),
                url("/fonts/SegoeUI/SegoeUI-Regular.ttf") format("truetype");
                font-weight: normal;
              }
  
              @font-face {
                font-family: "Verdana";
                src: local("Verdana"),
                url("/fonts/Verdana/Verdana-Regular.ttf") format("truetype");
                font-weight: normal;
              }
  
              body { font-family:Open Sans, sans-serif; font-size:14px; min-height: 330px; }
  
              .center { display: block; text-align: center; margin: 0px auto; }
  
              .right { display: block; text-align: right; margin-left: auto; }
  
              .full { text-align: justify; }
  
              table.center { display: table !important }
  
              table.right { display: table !important }
  
              img.right { display: block }
  
              img.mce-object.mce-object-script { display: none; }
  
              li.center, li.right { display: list-item !important; list-style-position: inside }
  
              .underline { text-decoration: underline }
  
              .strikethrough { text-decoration: line-through }
  
              .strikethrough.underline { text-decoration: line-through underline }
              
              .bold { font-weight: bold }

              .italic { font-style: italic }

              // .h1 {
              //   font-weight: bold;
              //   font-size: 2em;
              //   display: block;
              // }

              // .h2 {
              //   font-weight: bold;
              //   font-size: 1.5em;
              //   display: block;
              // }

              // .h3 {
              //   font-weight: bold;
              //   font-size: 1.17em;
              //   display: block;
              // }

              // .h4 {
              //   font-weight: bold;
              //   display: block;
              // }

              // .h5 {
              //   font-weight: bold;
              //   font-size: 0.833em;
              //   display: block;
              // }
              // .h6 {
              //   font-weight: bold;
              //   font-size: 0.67em;
              //   display: block;
              // }
              // .pre {
              //   font-family: monospace;
              //   white-space: pre;
              //   font-weight: bold;
              //   display: block;
              // }
            `,
          }}
        />
      </div>

      {currentTab === "web" && isTouched && !!error && (
        <div className="text-red-600">{error}</div>
      )}
    </div>
  );
};

export const TinyEditor = ({
  id,
  name,
  label,
  rows,
  formik,
  children,
  disabled,
  contentType,
}) => {
  const editorRef = useRef(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { doSetShowLoadingModal } = useLoaderProvider();

  const { isTouched, error } = getNestedFieldStatus(name, formik);
  const value = getNestedFieldValue(name, formik.values);

  const changeHandler = (content) => {
    formik.setFieldValue(name, content);
  };

  const stripAttributes = function (html) {
    const tags = html.match(/(<\/?[\S][^>]*>)/gi);

    tags.forEach(function (tag) {
      // Remove "class" attributes
      const noClass = tag.replace(
        /(class=".*?")|(class='.*?')|(class=[^\s>]*)/gi,
        ""
      );

      // Remove "id" attributes
      const noID = noClass.replace(/(id=".*?")|(id='.*?')|(id=[^\s>]*)/gi, "");

      // Remove "id" attributes
      const noStyle = noID.replace(
        /(style=".*?")|(style='.*?')|(style=[^\s>]*)/gi,
        ""
      );

      // Remove "data-" attributes
      const noData = noStyle.replace(
        /(data-.+?=".*?")|(data-.+?='.*?')|(data-[a-zA-Z0-9-]+)/gi,
        ""
      );

      // Update html string
      html = html.replace(tag, noData);
    });

    return html;
  };

  return (
    <div className="flex flex-col mb-5">
      <div className="flex justify-between items-center">
        <label className="text-lg mb-3" htmlFor={name}>
          {label}
        </label>
      </div>

      <div className={hasLoaded ? "block" : "hidden"}>
        <Editor
          tinymceScriptSrc={`${process.env.REACT_APP_TINYMCE_PUBLIC_URL}/tinymce/tinymce.min.js`}
          onInit={(evt, editor) => {
            if (!editor) doSetShowLoadingModal(true);
            if (editor) {
              setHasLoaded(true);
              doSetShowLoadingModal(false);
            }
          }}
          scriptLoading={{ async: true }}
          value={value}
          onEditorChange={changeHandler}
          init={{
            license_key: "gpl",
            verify_html: false,
            custom_elements: "style",
            valid_children: "+body[style]",
            min_height: 490,
            menubar: false,
            table_toolbar: "",
            elementpath: false,
            branding: false,
            link_default_protocol: "https",
            link_title: false,
            link_default_target: "_blank",
            table_advtab: false,
            table_row_advtab: false,
            contextmenu_never_use_native: false,
            contextmenu: false,
            format_empty_lines: true,
            resize: true,
            paste_preprocess: (editor, args) => {
              args.content = stripAttributes(args.content);
            },
            formats: {
              alignleft: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "left",
              },
              aligncenter: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "center",
              },
              alignright: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "right",
              },
              alignjustify: {
                selector:
                  "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img,audio,video,pre",
                classes: "full",
              },
              bold: [
                { inline: "span", classes: "bold" },
                { inline: "p", classes: "bold" },
              ],
              italic: [
                { inline: "span", classes: "italic" },
                { inline: "p", classes: "italic" },
              ],
              underline: [
                { inline: "span", classes: "underline" },
                { inline: "p", classes: "underline" },
              ],
              strikethrough: [
                { inline: "span", deep: true, classes: "strikethrough" },
                { inline: "p", deep: true, classes: "strikethrough" },
              ],
              forecolor: [
                {
                  inline: "span",
                  classes: "forecolor",
                  styles: { color: "%value" },
                },
                {
                  inline: "p",
                  classes: "forecolor",
                  styles: { color: "%value" },
                },
              ],
              // h1: {
              //   block: 'div',
              //   classes: 'h1'
              // },
              // h2: {
              //   block: 'div',
              //   classes: 'h2'
              // },
              // h3: {
              //   block: 'div',
              //   classes: 'h3'
              // },
              // h4: {
              //   block: 'div',
              //   classes: 'h4'
              // },
              // h5: {
              //   block: 'div',
              //   classes: 'h5'
              // },
              // h6: {
              //   block: 'div',
              //   classes: 'h6'
              // },
              // pre: {
              //   block: 'div',
              //   classes: 'pre'
              // },
            },
            plugins: [
              "autolink",
              "lists",
              "link",
              "image",
              "anchor",
              "searchreplace",
              "code",
              "fullscreen",
              "media",
              "table",
              "code",
            ],
            toolbar:
              "blocks fontfamily fontsize | " +
              "bold italic underline strikethrough forecolor | " +
              "alignleft aligncenter alignright alignjustify" +
              "| bullist numlist | image link table code | removeformat",
            toolbar_mode: "wrap",
            font_size_formats:
              "8px 9px 10px 11px 12px 14px 18px 22px 24px 30px 36px 48px 60px 72px 96px",
            extended_valid_elements:
              "script[language|type|src],div[*],a[*],style[*]",
            valid_elements:
              "@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|" +
              "onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|" +
              "onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|" +
              "name|href|target|title|class|onfocus|onblur],strong/b,em/i,strike,u," +
              "#p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|" +
              "src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup," +
              "-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|" +
              "height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|" +
              "height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot," +
              "#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor" +
              "|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,-div," +
              "-span,-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face" +
              "|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite]," +
              "object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width" +
              "|height|src|*],script[src|type],map[name],area[shape|coords|href|alt|target],bdo," +
              "button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|" +
              "valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method]," +
              "input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value]," +
              "kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value]," +
              "q[cite],samp,select[disabled|multiple|name|size],small," +
              "textarea[cols|rows|disabled|name|readonly],tt,var,big," +
              "iframe[width|height|src|title|frameborder|allow|allowfullscreen|srcdoc|loading|name|referrerpolicy|sandbox|" +
              "allowpaymentrequest]",
            font_family_formats: `
              Open Sans=Open Sans, sans-serif;
              Montserrat=Montserrat;
              Aleo=Aleo;
              Delius=Delius;
              Great Vibes=Great Vibes, GreatVibes;
              Merriweather=Merriweather;
              Nunito=Nunito;
              Oswald=Oswald;
              Playfair Display=Playfair Display, PlayfairDisplay;
              SegoeUI=SegoeUI;
              Verdana=Verdana;
              Raleway=Raleway
            `,
            content_style: `
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Aleo&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Delius&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Merriweather&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Nunito&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Oswald&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              @import url('https://fonts.googleapis.com/css2?family=Raleway&display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

              @font-face {
                font-family: "SegoeUI";
                src: local("SegoeUI"),
                url("/fonts/SegoeUI/SegoeUI-Regular.ttf") format("truetype");
                font-weight: normal;
              }

              @font-face {
                font-family: "Verdana";
                src: local("Verdana"),
                url("/fonts/Verdana/Verdana-Regular.ttf") format("truetype");
                font-weight: normal;
              }

              body { font-family:Open Sans, sans-serif; font-size:14px; min-height: 330px; }

              .center { display: block; text-align: center; margin: 0px auto; }

              .right { display: block; text-align: right; margin-left: auto; }

              .full { text-align: justify; }

              table.center { display: table !important }

              table.right { display: table !important }

              img.right { display: block }

              img.mce-object.mce-object-script { display: none; }

              li.center, li.right { display: list-item !important; list-style-position: inside }

              .underline { text-decoration: underline }

              .strikethrough { text-decoration: line-through }

              .strikethrough.underline { text-decoration: line-through underline }
              
              .bold { font-weight: bold }

              .italic { font-style: italic }

              // .h1 {
              //   font-weight: bold;
              //   font-size: 2em;
              //   display: block;
              // }

              // .h2 {
              //   font-weight: bold;
              //   font-size: 1.5em;
              //   display: block;
              // }

              // .h3 {
              //   font-weight: bold;
              //   font-size: 1.17em;
              //   display: block;
              // }

              // .h4 {
              //   font-weight: bold;
              //   display: block;
              // }

              // .h5 {
              //   font-weight: bold;
              //   font-size: 0.833em;
              //   display: block;
              // }
              // .h6 {
              //   font-weight: bold;
              //   font-size: 0.67em;
              //   display: block;
              // }
              // .pre {
              //   font-family: monospace;
              //   white-space: pre;
              //   font-weight: bold;
              //   display: block;
              // }
            `,
          }}
        />
      </div>

      {isTouched && !!error && <div className="text-red-600">{error}</div>}
    </div>
  );
};
