import React from "react";
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { APP_BUILT, colors } from "./OnboardingBuildAndSubmit";
import { FaCheck } from "react-icons/fa";
import BuildAndSubmitSlideOutButton from "./BuildAndSubmitSlideOut";

const AppBuilt = ({
  getIsCompleted
}) => {

  return (
    <div className="flex flex-row justify-between items-center p-2">
      <div className="w-full flex flex-row justify-start items-center">
        <button
          type="button"
          className={`p-2 cursor-not-allowed border-3 rounded`}
          style={{
            background: getIsCompleted({ id: APP_BUILT })
              ? colors.dark
              : "#FFFFFF",
            borderColor: colors.dark,
          }}
        >
          <div
            className="rounded-lg"
            style={{
              borderColor: getIsCompleted({ id: APP_BUILT })
                ? colors.dark
                : "#FFFFFF",
              backgroundColor: getIsCompleted({ id: APP_BUILT })
                ? colors.dark
                : "#FFFFFF",
            }}
          >
            <FaCheck className={`text-white font-bold rounded-lg h-3 w-3`} />
          </div>
        </button>

        <span className={`line-clamp-2 text-xl font-bold ml-2 text-2xl`}>
          {APP_BUILT}
        </span>
      </div>

      <BuildAndSubmitSlideOutButton
        type={APP_BUILT}
        title={APP_BUILT}
        btnLabel={"BUILD"}
      />
    </div>
  );
};

export default AppBuilt