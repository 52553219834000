import { useCallback, useEffect, useRef, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  TextArea,
  CheckBox,
  Select,
  RichText,
} from "../components/EntryForm";
import Page from "../components/Page";
import MediaUploader from "../components/MediaUploader";

import {
  GetWelcomeScreen,
  UpdateWelcomeScreen,
} from "../services/api/welcome_screen";
import { AddAppResource, GetApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { validations } from "../lib/form";
import DataSelector from "../components/DataSelector";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";
import Multiselect from "multiselect-react-dropdown";
import { IsWithinMinimumVersion } from "../lib/useVersionizer";
import VersionizerWrapper from "../components/Versionizer";

const platformTarget = [
  { id: 0, name: "App Only", value: "app" },
  { id: 1, name: "Web Only", value: "web" },
  { id: 2, name: "Both App and Web", value: "both" },
];

const CUSTOM_URL_LABEL = "Custom URL";

const WelcomeScreenDetail = ({ newWelcomeScreen, match }) => {
  const vals = {
    title: "",
    video_url: "",
    image_url: "",
    welcome_icon_url: "",
    whats_new_headline: "",
    whats_new_description: "",
    whats_new_web_description: "",
    action_bar_text: "",
    action_bar_url: "",
    active: false,
    use_url_tag: false,
    tags: [],
    media_metadata: {},
    target_platform: "both",
    featured_buttons: {
      left: {
        target_label: "",
        target: "",
      },
      right: {
        target_label: "",
        target: "",
      },
    },
    web: {
      headline: "",
      video_url: "",
      image_url: "",
      stretch_image: false,
    },
  };

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [initialValues, setInitialValues] = useState(vals);
  const lastSelectRef = useRef(null);
  const [showFiller1, setShowFiller] = useState(false);
  const [showFiller0, setToggleFiller] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const { user, setUser } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newWelcomeScreen ? user.currentApp() : match.params.uuid;

  const history = useHistory();
  useEffect(() => {
    setIsLoadingOptions(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const app_setup = res.data.setup;

        setUser((prev) => ({
          ...prev,
          app_data: {
            ...prev.app_data,
            setup: {
              ...prev.app_data.setup,
              ...app_setup,
            },
          },
        }));
      })
      .finally(() => {
        setIsLoadingOptions(false);
      });
  }, []);

  useEffect(() => {
    if (newWelcomeScreen) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetWelcomeScreen(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            title: res.data.title,
            video_url: res.data.video_url,
            image_url: res.data.image_url,
            welcome_icon_url: res.data.welcome_icon_url,
            whats_new_headline: res.data.whats_new_headline,
            whats_new_description: res.data.whats_new_description,
            whats_new_web_description: res.data.whats_new_web_description,
            action_bar_text: res.data.action_bar_text,
            action_bar_url: res.data.action_bar_url,
            active: res.data.active,
            use_url_tag: res.data.use_url_tag,
            tags: res.data.tags,
            web: res.data.web,
            target_platform: res.data.target_platform || "both",
            featured_buttons: res.data.featured_buttons,
          });
          setMetadata(res.data.media_metadata);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newWelcomeScreen ? "New" : "Edit";
  const currentUrl = newWelcomeScreen
    ? "/welcome_screens/new"
    : `/welcome_screens/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Home Screen", url: "/welcome_screens" },
    { name: `${currentAction} Home Screen`, url: currentUrl },
  ];

  let schema = () =>
    Yup.lazy((value) => {
      let featuredButton = Yup.object().shape({
        target_label: Yup.string().nullable().max(32, "Maximum 32 Characters"),
        target_url: Yup.lazy((_, options) => {
          if (options.parent.target === CUSTOM_URL_LABEL) {
            return Yup.string()
              .nullable()
              .required("Add a valid URL")
              .matches(
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                "Invalid URL format"
              );
          }

          return Yup.string()
            .nullable()
            .default(null)
            .matches(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              "Invalid URL format"
            );
        }),
      });

      const _schema = {
        title: validations.str_req_50,
        video_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
        image_url: Yup.string().nullable().max(255, "Maximum 255 Characters"),
        whats_new_headline: validations.str_req_30,
        whats_new_description: Yup.string(),
        whats_new_web_description: Yup.string().max(
          65535,
          "Maximum 65,535 Characters"
        ),
        action_bar_text: validations.str_notreq_50,
        action_bar_url: Yup.string()
          .nullable()
          .max(255, "Maximum 255 Characters"),
        active: Yup.boolean().nullable(),
        featured_buttons: Yup.object().shape({
          left: featuredButton,
          right: featuredButton,
        }),
        web: Yup.object().shape({
          headline: Yup.string().nullable().max(50, "Maximum 50 Characters"),
        }),
      };

      if (value.target_platform === "web") {
        _schema.title = Yup.string()
          .nullable()
          .max(50, "Maximum 50 Characters");
      }

      return Yup.object().shape(_schema);
    });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      values.target_platform = values.target_platform || "both";
      values.title = values.title?.replace(/\s+/g, " ").trim();

      doSetShowLoadingModal(true);

      switch (values.target_platform) {
        case "both":
          values.web.headline = values.title;
          values.web.image_url = values.image_url;
          values.web.video_url = values.video_url;
          break;

        // remove app fields if web only
        case "web":
          values.title = "";
          values.title = values.web.headline;
          values.image_url = "";
          values.video_url = "";
          values.welcome_icon_url = "";
          break;

        // remove web fields if app only
        case "app":
          values.web.headline = "";
          values.web.image_url = "";
          values.web.video_url = "";
          break;

        default:
          break;
      }

      if (newWelcomeScreen) {
        AddAppResource(uuid, "welcome_screens", user.idToken, { data: values })
          .then((response) => responseProcessorProxy(response))
          .finally(() => doSetShowLoadingModal(false));
      } else {
        UpdateWelcomeScreen(uuid, user.idToken, { data: values })
          .then((response) => responseProcessorProxy(response))
          .finally(() => doSetShowLoadingModal(false));
      }
    },
  });

  const featuredButtons = useFeaturedButtons(formik.values.target_platform);

  // clear featured button if target platform is changed
  const handleChangePlatform = (e) => {
    const target_platform = e.target.value;
    if (target_platform === "web") {
      formik.setFieldValue("web.headline", formik.values.title);
      formik.setFieldValue("web.video_url", formik.values.video_url);
      formik.setFieldValue("web.image_url", formik.values.image_url);
    }

    if (target_platform === "app") {
      formik.setFieldValue("title", formik.values.web.headline);
      formik.setFieldValue("video_url", formik.values.web.video_url);
      formik.setFieldValue("image_url", formik.values.web.image_url);
      formik.setFieldValue(
        "welcome_icon_url",
        formik.values.web.welcome_icon_url
      );
    }

    if (target_platform === "both") {
      if (!formik.values.title)
        formik.setFieldValue("title", formik.values.web.headline);
      if (!formik.values.video_url)
        formik.setFieldValue("video_url", formik.values.web.video_url);
      if (!formik.values.image_url)
        formik.setFieldValue("image_url", formik.values.web.image_url);
    }

    if (target_platform !== "app") {
      const allowedOptions = ["Topics", "Directories", "Tips & Posts"];

      Object.keys(formik.values.featured_buttons).forEach((key) => {
        const button = formik.values.featured_buttons[key];

        if (!allowedOptions.includes(button.target)) {
          formik.setFieldValue(`featured_buttons[${key}].target_label`, "");
          formik.setFieldValue(`featured_buttons[${key}].target`, "");
        }
      });
    }
  };

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/welcome_screens"),
    });
  };

  const handleSelectFeaturedButton = useCallback(
    ({ selected, key }) => {
      formik.setFieldValue(
        `featured_buttons[${key}].target`,
        selected?.[0]?.name || ""
      );

      formik.setFieldValue(
        `featured_buttons[${key}].target_label`,
        selected?.[0]?.name || ""
      );

      if (selected?.[0]?.value !== CUSTOM_URL_LABEL) {
        // remove custom label values
        formik.setFieldValue(`featured_buttons[${key}].target_url`, "");

        formik.setFieldValue(
          `featured_buttons[${key}].target_label`,
          selected?.[0]?.name || ""
        );

        formik.setFieldValue(
          `featured_buttons[${key}].target`,
          selected?.[0]?.value || ""
        );
      }

      if (!selected?.[0]?.value) {
        formik.setFieldValue(`featured_buttons[${key}].target`, "");
        formik.setFieldValue(`featured_buttons[${key}].target_label`, "");
        formik.setFieldValue(`featured_buttons[${key}].target_url`, "");
      }
    },
    [formik]
  );

  const { target_platform } = formik.values;

  const handleChangePlaceholder = (key) => {
    if (formik.values?.featured_buttons?.[key]?.target_label) return "";
    if (formik.values?.featured_buttons?.[key]?.target) return "";
    return "Select Featured";
  };

  const handleGetSelectedValues = (key) => {
    if (formik.values.featured_buttons?.[key]?.target === CUSTOM_URL_LABEL) 
      return [{ name: CUSTOM_URL_LABEL }]; // prettier-ignore

    const featuredButton = featuredButtons.find(
      (button) =>
        button.target === formik.values.featured_buttons?.[key]?.target
    );

    if (
      formik.values.featured_buttons?.[key]?.target_label ||
      formik.values.featured_buttons?.[key]?.target
    ) {
      // detect if selected button exists in featuredButtons
      if (featuredButton) {
        return [
          {
            name: featuredButton.target_label,
          },
        ];
      } else {
        // clear selected featured button if it is disabled / not found
        // in featuredButtons
        const deselectedButton = formik.values.featured_buttons?.[key]?.target;
        console.log(
          "ALERT: Deselected " +
            deselectedButton +
            " as Featured " +
            key +
            " button since it has been disabled on setup"
        );
        formik.setFieldValue(`featured_buttons[${key}].target`, "");
        formik.setFieldValue(`featured_buttons[${key}].target_label`, "");
        formik.setFieldValue(`featured_buttons[${key}].target_url`, "");
        return [];
      }
    }

    return [];
  };

  return (
    <Page links={links} title={`${currentAction} Home Screen`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/welcome_screens"
      >
        <Column>
          <label className="text-lg block">Target Platform</label>
          <Select
            onChange={handleChangePlatform}
            formik={formik}
            name="target_platform"
          >
            {platformTarget?.map((platform) => (
              <option
                key={platform.id}
                value={platform.value}
                label={platform.name}
              >
                {platform.name}
              </option>
            ))}
          </Select>
          <div className="mt-4" />

          <div>
            <Input
              name={target_platform === "web" ? "web.headline" : "title"}
              type="input"
              label="Title"
              formik={formik}
            />
            <div className="italic mt-1 mb-5 -mt-3.5 text-right">
              Needs to be unique
            </div>

            <MediaUploader
              name={target_platform === "web" ? "web.video_url" : "video_url"}
              type="text"
              label="Video URL"
              formik={formik}
              metadata={metadata}
              mediaType="video"
              description="Video must be less than 3 GB and horizontal/ widescreen in orientation."
            />
            <MediaUploader
              name={target_platform === "web" ? "web.image_url" : "image_url"}
              type="text"
              label="Image if No Video"
              formik={formik}
              metadata={metadata}
              mediaType="image"
            />
            {(target_platform === "web" || target_platform === "both") && (
              <div className="flex items-center justify-end gap-2">
                <input
                  type="checkbox"
                  name="web.stretch_image"
                  onChange={formik.handleChange}
                  checked={formik.values.web.stretch_image}
                  // value={}
                />
                <span>
                  Click this box to make image full-width in the Home Screen for
                  Web
                </span>
              </div>
            )}
            <VersionizerWrapper minVersion={8.5}>
              {(target_platform === "app" || target_platform === "both") && (
                <div>
                  <div className="mt-8" />
                  <MediaUploader
                    name={
                      target_platform === "web"
                        ? "web.welcome_icon_url"
                        : "welcome_icon_url"
                    }
                    type="text"
                    label="Welcome Screen Icon"
                    className="mb-0"
                    formik={formik}
                    metadata={metadata}
                    mediaType="image"
                  />
                  <p className="italic text-right -mt-3 text-base">
                    Ideal max. size is 512 x 512 px
                  </p>
                </div>
              )}
            </VersionizerWrapper>
          </div>
        </Column>
        <Column>
          <Input
            name="whats_new_headline"
            type="text"
            label="What's New Headline"
            formik={formik}
          />
          <RichText
            id="whatsNewDescription"
            name="whats_new_description"
            quillName="whats_new_web_description"
            type="text"
            label={"What's New Description"}
            rows="21"
            formik={formik}
            contentType="whatsNew"
          />
          <VersionizerWrapper minVersion={8.5}>
            <section>
              <header className="my-8 text-lg">Featured buttons:</header>
              <label className="text-lg mb-3 block">Left button</label>
              <div onClick={() => setToggleFiller((prev) => !prev)}>
                <Multiselect
                  // singleSelect
                  avoidHighlightFirstOption={true}
                  selectionLimit={1}
                  displayValue="name"
                  selectedValues={handleGetSelectedValues("left")}
                  placeholder={handleChangePlaceholder("left")}
                  onSelect={(selected) =>
                    handleSelectFeaturedButton({ selected, key: "left" })
                  }
                  onRemove={(selected) =>
                    handleSelectFeaturedButton({ selected, key: "left" })
                  }
                  options={[
                    ...(featuredButtons || []).map((option) => ({
                      name: option.target_label,
                      id: option.target,
                      value: option.target,
                    })),
                  ]}
                />
              </div>

              {["Podcasts", "ActionClips"].includes(
                formik?.values?.featured_buttons?.left?.target
              ) ? (
                <div className="italic mt-1 mb-5 text-right uppercase">
                  Podcasts and ActionClips not available in WEB
                </div>
              ) : (
                <></>
              )}

              {formik.values.featured_buttons?.left?.target ===
                CUSTOM_URL_LABEL && (
                <>
                  <div className="mt-3" />
                  <Input
                    name="featured_buttons.left.target_label"
                    label="Custom Label"
                    formik={formik}
                  />
                  <Input
                    name="featured_buttons.left.target_url"
                    label={CUSTOM_URL_LABEL}
                    formik={formik}
                  />
                </>
              )}
              <label className="text-lg my-3 block">Right Button</label>
              <div
                onClick={() =>
                  setShowFiller(
                    !lastSelectRef?.current?.state?.toggleOptionsList
                  )
                }
              >
                <Multiselect
                  ref={lastSelectRef}
                  // singleSelect
                  avoidHighlightFirstOption={true}
                  selectionLimit={1}
                  displayValue="name"
                  selectedValues={handleGetSelectedValues("right")}
                  placeholder={handleChangePlaceholder("right")}
                  onSelect={(selected) =>
                    handleSelectFeaturedButton({ selected, key: "right" })
                  }
                  onRemove={(selected) =>
                    handleSelectFeaturedButton({ selected, key: "right" })
                  }
                  options={[
                    ...(featuredButtons || []).map((option) => ({
                      name: option.target_label,
                      id: option.target,
                      value: option.target,
                    })),
                  ]}
                />
              </div>

              {["Podcasts", "ActionClips"].includes(
                formik?.values?.featured_buttons?.right?.target
              ) ? (
                <div className="italic mt-1 mb-5 text-right uppercase">
                  Podcasts and ActionClips not available in WEB
                </div>
              ) : (
                <></>
              )}

              {formik.values.featured_buttons?.right?.target ===
                CUSTOM_URL_LABEL && (
                <>
                  <div className="mt-3" />
                  <Input
                    name="featured_buttons.right.target_label"
                    label="Custom Label"
                    formik={formik}
                  />
                  <Input
                    name="featured_buttons.right.target_url"
                    label={CUSTOM_URL_LABEL}
                    formik={formik}
                  />
                </>
              )}
            </section>
          </VersionizerWrapper>
          <div className="mt-4" />
          <DataSelector
            name="tags"
            formik={formik}
            label="Tags"
            resourceEndpoint="tags?category=Content"
            filterOptions={(values) => values.filter((a) => !a.is_expired)}
          />
          <Input
            name="action_bar_text"
            type="text"
            label="Call to Action Text"
            formik={formik}
          />
          <Input
            name="action_bar_url"
            type="text"
            label="Call to Action URL"
            formik={formik}
          />

          <CheckBox name="active" label="Active" formik={formik} />
          {user.app_data.setup.url_tags && (
            <CheckBox name="use_url_tag" label="URL Tags" formik={formik} />
          )}
        </Column>
      </EntryForm>
      {showFiller0 && <div className="h-36" />}
      {showFiller1 && <div className="h-48 mt-12" />}
    </Page>
  );
};

export default WelcomeScreenDetail;

const useFeaturedButtons = (target_platform) => {
  const { user } = useUserProvider();
  const setup = user?.app_data?.setup ?? {};

  const option = useCallback((target, target_label) => ({ target, target_label: target_label || target }), []) // prettier-ignore

  const appOptions = [];
  //V8
  // Removed Track now on App AE-2585
  // appOptions.push(option('Track Now', setup.track_now_label));
  // AE-3416 show enterprise label if reseller
  if (setup.enterprise || user?.app_data?.reseller_of.length > 0) appOptions.push(option("Businesses", setup.enterprise_label)); // prettier-ignore
  if (setup.topics) appOptions.push(option("Topics", setup.topics_label));
  if (setup.directories) appOptions.push(option("Directories", setup.directories_label)); // prettier-ignore

  //V8.5
  if (IsWithinMinimumVersion(8.5)) {
    if (setup.tips)
      appOptions.push(
        option(
          "Tips & Posts",
          setup.tips_label ? setup.tips_label : "ACTIONBLOG"
        )
      );
    if (setup.courses) appOptions.push(option("Courses", setup.courses_label));
    if (setup.action_lists)
      appOptions.push(
        option(
          "Action Lists",
          setup.action_lists_label ? setup.action_lists_label : "ACTIONLISTS"
        )
      );
    if (setup.summits) appOptions.push(option("Summits", setup.summits_label));
    if (setup.virtual_events) appOptions.push(option("Virtual Events", setup.virtual_events_label)); // prettier-ignore
  }

  //V9
  if (IsWithinMinimumVersion(9)) {
    if (setup.podcasts) {
      appOptions.push(option("Podcasts", setup.podcasts_label));
    }
    if (setup.actionclips && IsWithinMinimumVersion(9)) {
      appOptions.push(option("ActionClips", setup.actionclips_label));
    }
  }
  appOptions.push(
    option("Track Now", setup.track_now_label || "Unlock/ Track Screen")
  );

  if (IsWithinMinimumVersion(8.5)) {
    // CUSTOM_URL_LABEL should always be at the end
    appOptions.push(option(CUSTOM_URL_LABEL));
  }

  // web
  const webOptions = [];

  if (setup.topics) webOptions.push(option("Topics", setup.topics_label));
  if (setup.directories) webOptions.push(option("Directories", setup.directories_label)); // prettier-ignore
  if (setup.tips)
    webOptions.push(
      option("Tips & Posts", setup.tips_label ? setup.tips_label : "ACTIONBLOG")
    );
  if (setup.courses) webOptions.push(option("Courses", setup.courses_label));
  webOptions.push(
    option("Track Now", setup.track_now_label || "Unlock/ Track Screen")
  );
  // CUSTOM_URL_LABEL should always be at the end
  webOptions.push(option(CUSTOM_URL_LABEL));

  const bothOptions = [];
  //V8
  if (setup.topics) bothOptions.push(option("Topics", setup.topics_label));
  if (setup.directories) bothOptions.push(option("Directories", setup.directories_label)); // prettier-ignore

  if (IsWithinMinimumVersion(8.5)) {
    if (setup.tips)
      bothOptions.push(
        option(
          "Tips & Posts",
          setup.tips_label ? setup.tips_label : "ACTIONBLOG"
        )
      );
    if (setup.courses) bothOptions.push(option("Courses", setup.courses_label));
  }
  bothOptions.push(
    option("Track Now", setup.track_now_label || "Unlock/ Track Screen")
  );
  // CUSTOM_URL_LABEL should always be at the end
  if (IsWithinMinimumVersion(8.5)) {
    bothOptions.push(option(CUSTOM_URL_LABEL));
  }

  if (setup.action_lists)
    bothOptions.push(
      option(
        "Action Lists",
        setup.action_lists_label ? setup.action_lists_label : "ACTIONLISTS"
      )
    );

  if (target_platform === "app") return appOptions;
  if (target_platform === "web") return webOptions;
  return bothOptions;
};
