import OnboardingVideo from "./video"
import OnboardingQuestions from "./questions"
import OnboardingGallery from "./gallery"
import OnboardingProfile from "./profile"
import OnboardingArticle from "./article"
import OnboardingTip from "./tip"
import OnboardingDirectory from "./directory"
import OnboardingWelcomeScreen from "./welcome_screen"
import OnboardingTopic from "./topic"
import OnboardingAppDesign, { AppDesignStatus } from "../../components/OnboardingAppDesign"
import OnboardingAppStore, { AppStoreStatus } from "../../components/OnboardingAppStore"
import EnterpriseOnboardingAppDesign from "../../components/EnterpriseOnboardingAppDesign";
import { UploadContent, UploadContentStatus } from '../../components/OnboardingUploadContent';
import OnboardingJournal from "./journal"
import onboardingListing from "./listing";
import onboardingInfo from "./info";
import onboardingSupport from "./support";
import OnboardingDeveloperAccounts, { DeveloperAccountsStatus } from "../../components/OnboardingDeveloperAccounts"

import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { useEffect, useState } from "react"
import OnboardingBuildAndSubmit, { APP_BUILT, APP_DESIGNED, FINAL_INFO } from "../../components/OnboardingBuildAndSubmit/OnboardingBuildAndSubmit"
import onboardingAppDesigned from "./app_designed"
import onboardingAppBuilt from "./app_built"
import onboardingFinalInfo from "./final_info"
import { OnboardingHomeProfile, HomeProfileStatus } from "../../components/OnboardingHomeProfile"

export const ENTERPRISE = "Enterprise"
export const LAUNCHED = "Launched"
export const IN_PROGRESS = "In Progress"
export const CREATOR_PORTAL = "Creator Portal"
export const SUBMITTED = "Submitted"
export const SAVED = "Saved"
export const READY = "Ready"
export const GET_STARTED = "Get Started"
export const DONE = "done"
export const BOTH = "both"
export const APP_DESIGN = "app_design"
export const UPLOAD_CONTENT = "upload_content"
export const APP_STORE = "app_store"
export const DEVELOPER_ACCOUNTS = "developer_accounts"
export const BUILD_AND_SUBMIT = "build_and_submit"
export const HOME_PROFILE = "home_profile"

const onboardingConfig = (type, props) => {
    switch (type) {
      case 'questions':
        return OnboardingQuestions(props).config
      case 'galleries':
        return OnboardingGallery(props).config
      case 'profiles':
        return OnboardingProfile(props).config
      case 'videos':
        return OnboardingVideo(props).config
      case 'articles':
        return OnboardingArticle(props).config
      case 'welcome_screens':
        return OnboardingWelcomeScreen(props).config
      case 'tips':
          return OnboardingTip(props).config
      case 'topics':
        return OnboardingTopic(props).config
      case 'journals':
        return OnboardingJournal(props).config
      case 'listing':
        return onboardingListing(props).config
      case 'info':
        return onboardingInfo(props).config
      case 'support':
        return onboardingSupport(props).config
      case APP_DESIGNED:
        return onboardingAppDesigned(props).config
      case APP_BUILT:
        return onboardingAppBuilt(props).config
      case FINAL_INFO:
        return onboardingFinalInfo(props).config
      default: 
        return OnboardingDirectory(props).config
    }
}

export const EOAppDesignStatus = () => {
  const { onboardingData } = useOnboardingProvider()
  const [onboardingDesignStatus, setOnboardingDesignStatus] = useState('Get Started');

  useEffect(() => {
    if (onboardingData && Object.keys(onboardingData).length > 0) {
      setOnboardingDesignStatus(onboardingData?.progress?.design?.status)
    }
  }, [onboardingData])

  return (
    <div className="flex justify-center items-center">
      <div className="ttnk-AvenirNextBold" style={{
        color: '#2F91CF'
      }}>
        {onboardingDesignStatus}
      </div>
    </div>
  )
}

export const EOAppDesignStatusType = () => {
  const { onboardingData } = useOnboardingProvider()
  const [onboardingDesignStatus, setOnboardingDesignStatus] = useState('Get Started');

  useEffect(() => {
    if (onboardingData && Object.keys(onboardingData).length > 0) {
      setOnboardingDesignStatus(onboardingData?.progress?.design?.status)
    }
  }, [onboardingData])

  return onboardingDesignStatus
}

export const superAdminOnboardingAccordionConfig = [
  {
    id: BUILD_AND_SUBMIT,
    has_details: true,
    label: "Build and Submit (admin)",
    body: <OnboardingBuildAndSubmit />,
    status: null,
    is_complete: false,
    editable: true,
    hasEditLabel: false
  }
]

export const onboardingAccordionConfig = [
  {
    id: APP_DESIGN,
    has_details: true,
    label: "App Design",
    body: <OnboardingAppDesign />,
    status: <AppDesignStatus />,
    is_complete: false,
    editable: true,
    hasEditLabel: true
  },
  {
    id: UPLOAD_CONTENT,
    label: "Upload Content",
    status: <UploadContentStatus />,
    has_details: true,
    is_complete: false,
    editable: true,
    hasEditLabel: true,
    body: <UploadContent />,
  },
  {
    id: 'app_store',
    label: "App Store Info",
    status: <AppStoreStatus />,
    has_details: true,
    is_complete: true,
    is_disabled: true,
    editable: true,
    body: <OnboardingAppStore />,
    hasEditLabel: true,
  },
  {
    id: DEVELOPER_ACCOUNTS,
    label: "Developer Accounts",
    has_details: true,
    is_complete: false,
    editable: true,
    body: <OnboardingDeveloperAccounts />,
    status: <DeveloperAccountsStatus />,
    hasEditLabel: false
  }
]

export const enterpriseOnboardingAccordionConfig = [
  {
    id: APP_DESIGN,
    has_details: true,
    label: "Essentials",
    body: <EnterpriseOnboardingAppDesign />,
    status: <EOAppDesignStatus />,
    is_complete: false,
    editable: true,
    hasEditLabel: true
  },
  {
    id: HOME_PROFILE,
    label: "Home/Profile",
    status: <HomeProfileStatus />,
    has_details: true,
    is_complete: false,
    editable: true,
    body: <OnboardingHomeProfile />,
    hasEditLabel: true
  },
  {
    id: UPLOAD_CONTENT,
    label: "Upload Content",
    status: <UploadContentStatus />,
    has_details: true,
    is_complete: false,
    editable: true,
    body: <UploadContent />,
    hasEditLabel: true
  },
]

export const nonContentList = ['profiles', 'welcome_screens', 'topics', 'directories']
export const getContentType = (type) => {
  const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)
  if (nonContentList.includes(type)) return `${type}`
  return `content/${type}?timezone=${timezone}${!nonContentList?.includes(type) ? '&addsubtopics=true' : ''}`
}

export default onboardingConfig