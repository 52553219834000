import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import { useHistory } from "react-router-dom";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";

import DeleteItem from "../components/modal_contents/DeleteItem";
import { DeleteGlobalField, GetGlobalFieldsList } from "../services/api/globalFeilds";
import TableActions from "../components/TableActions";

const GlobalFieldsList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();
  const { user } = useUserProvider();
  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetGlobalFieldsList(user)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData?.data,
          count: jsonData?.data.length
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Global Fields", url: "/global-fields" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.field_name,
      ellipsis: true,
    },
    {
      name: "android",
      label: "Android",
      parser: (data) => data.android_value,
      ellipsis: true,
    },
    {
      name: "ios",
      label: "iOS",
      parser: (data) => data.ios_value,
      ellipsis: true,
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          onDeleteClick={showDeleteModal}
          item_id={data.id}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/global-fields/new`)
  }

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={handleAddButtonClick}
      >
        Add Global Field
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/global-fields/${id}`);
  };

  const deleteButtonHandler = async (id) => {
    await DeleteGlobalField(id, user.idToken);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="Global Field"
        onDelete={deleteButtonHandler}
        onClose={closeModal}
      />
    );
  };

  return (
    <Page links={links} title="Global Fields" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "android", "ios"]}
      />
    </Page>
  );
};

export default GlobalFieldsList;
