import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import useLocalStorage from "../lib/useLocalStorage";

import { useUserProvider } from "../providers/UserProvider";
import { DeleteActionForm, GetActionFormList } from "../services/api/action_forms";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { useHistory } from "react-router-dom";
import { useModalProvider } from "../providers/ModalProvider";
import DeleteItem from "../components/modal_contents/DeleteItem";

const ActionFormList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { showModal, closeModal } = useModalProvider();

  const history = useHistory();
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
    meta_data: {}
  });

  const { user } = useUserProvider();

  const deleteClass =
    "h-6 w-6 stroke-current fill-none text-gray-600 trash hover:text-red-600";

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetActionFormList(user.currentApp(), user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
          meta_data: jsonData?.meta_data
        });
      });
  };

  const handleDelete = async (id) => {
    await DeleteActionForm(user, id);
    loadData();
    closeModal();
  };

  const showDeleteModal = (id) => {
    showModal(
      <DeleteItem
        itemId={id}
        itemLabel="ActionForm"
        onDelete={handleDelete}
        onClose={closeModal}
        deleteAction="delete"
      />
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/action-forms/${id}`);
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "ActionForms", url: "/action-forms" },
  ];

  const metadata = [
   
    {
      name: "name",
      label: "Form Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "type",
      label: "Form Type",
      parser: (data) => {
        const formType = data.form_type;
        if(formType === 'Topic') return 'Listing'
        if(formType === 'Offer') return 'Special Offer'
        return formType
      },
    },
    {
      name: "web_url",
      label: "WEB URL",
      parser: (data) => {
        const webURL = `${process.env.REACT_APP_WEB_FQDN}/${user?.app_data?.username}/action-forms/${data.id}/${data.form_type.toLocaleLowerCase()}`
        return <a href={webURL} target="_blank" rel="noreferrer">{webURL}</a>
      },
    },
    {
      name: "active",
      label: "Active",
      parser: (data) => data.active? 'Yes': 'No',
    },
    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          deleteIcon={TrashIcon}
          item_id={data.id}
          deleteClass={deleteClass}
          onDeleteClick={showDeleteModal}
          onUpdateClick={updateButtonHandler}
        />
      ),
    },
  ];

  const handleAddButtonClick = () => {
    history.push(`/action-forms/new`)
  }

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={handleAddButtonClick}
      >
        Add Action Form
      </button>
    );
  };


  return (
    <Page links={links} title="ACTIONFORMS" titleComponent={AddButton()}>
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name"]}
      />
    </Page>
  );
};

export default ActionFormList;
