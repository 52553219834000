import * as React from 'react'
import { useContext, useState } from 'react'
import BaseDrawer from '../components/BaseDrawer'

export const DrawerContext = React.createContext()

export const DrawerProvider = ({ children }) => {
  const [drawerData, setDrawerData] = useState({
    show: false,
    title: null,
    content: null,
    width: '',
  })

  const showDrawer = (drawerContent, title, width, titleClasses ) => {
    setDrawerData({
      show: true,
      title: title,
      content: drawerContent,
      width,
      titleClasses
    })
  }

  const closeDrawer = () => {
    setDrawerData({
      show: false,
      title: null,
      content: null
    });
  }

  let commonProps = {
    title: drawerData?.title,
    drawerOpen: drawerData?.show,
    closeHandler: closeDrawer,
    titleClasses: drawerData?.titleClasses
  }

  if (drawerData.width) {
    commonProps = {
      ...commonProps,
      width: drawerData?.width,
      bodyStyle: {padding: '0'}
    }
  }

  return(
    <DrawerContext.Provider value={ { showDrawer, closeDrawer } }>
      <BaseDrawer {...commonProps}>
        { drawerData.content }
      </BaseDrawer>
      {children}
    </DrawerContext.Provider>
  )
}

export const useDrawerProvider = () => {
  const context = useContext(DrawerContext)

  if (context === undefined) {
    throw new Error('Invalid use of DrawerContext')
  }

  return context
}
