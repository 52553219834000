import { DatePicker } from 'antd'
import moment from 'moment'

const DateSelector = ({ name, label, formik, disabledDate, format = "DD-MMM-yyyy", onChange, isFieldRow = false }) => {
  const changeHandler = (selectedDate) => {
    if(onChange) return onChange(selectedDate)
    formik.setFieldValue(name, selectedDate)
  }

  return(<div className={`flex mb-5 ${isFieldRow ? "flex-row" : "flex-col"}`}>
    <label className={`text-lg mb-3 ${isFieldRow ? "w-1/3" : ""}`} htmlFor={ name }>{ label }</label>
    <DatePicker
      format={format}
      disabledDate={ disabledDate }
      id={ name }
      name={ name }
      className={`border border-gray-300 text-lg p-2 rounded-lg ${isFieldRow ? "w-2/3" : ""}`}
      onChange={ changeHandler }
      onBlur={ formik.handleBlur }
      value={formik.values[name] ? moment(formik.values[name]).utcOffset(0, true) : null} />
    {formik.touched[name] && formik.errors[name] && <div className="text-red-600">{formik.errors[name]}</div>}
  </div>)
}

export default DateSelector
