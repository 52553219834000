import React from 'react';

const AvailableInVersion = ({ minVersion }) => {
  return (
    <div
      className="italic text-sm text-right font-medium"
      style={{ marginTop: -12, marginBottom: 12 }}
    >
      Available in app Platform Version {minVersion} & up and WEB
    </div>
  );
};

export default AvailableInVersion;
