import { useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions";
import { useHistory } from "react-router-dom";
import { useUserProvider } from "../providers/UserProvider";
import copy from "../assets/images/copy.png";

import { DisableApp, GetAppResource } from "../services/api/app";
import { Tooltip } from "@material-ui/core";
import { useModalProvider } from "../providers/ModalProvider";

const BusinessList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { showModal, closeModal } = useModalProvider();
  const businessLinks = [
    {
      url: "pr/join",
      type: "PRO",
    },
    {
      url: "prpl/join",
      type: "PRO+",
    },
    {
      url: "fr/join",
      type: "FREE",
    },
  ];
  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const history = useHistory();

  const { user } = useUserProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetAppResource(user, "children")
      .then((res) => res.json())
      .then((jsonData) => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Businesses", url: "/business" },
  ];

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "name",
      label: "Name of sub-app",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "app_type",
      label: "App Type",
      parser: (data) => data.app_type,
      ellipsis: true,
    },
    {
      name: "active",
      label: "Active?",
      parser: (data) => (data.active ? "Yes" : "No"),
      ellipsis: true,
    },

    {
      name: "actions",
      label: "Actions",
      parser: (data) => (
        <TableActions
          onUpdateClick={updateButtonHandler}
          item_id={data.id}
          onDeleteClick={() => {
            showModal(
              <div>
                <div>Are you sure you to delete this Business?</div>
                <div className="flex flex-row items-center gap-3 mx-auto justify-center">
                  <button
                    className="ttnk-button mt-2"
                    onClick={() => {
                      DisableApp(data.id, user.idToken).then(() => {
                        closeModal();
                        loadData();
                      });
                    }}
                  >
                    Yes
                  </button>
                  <button className="ttnk-button mt-2" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            );
          }}
        />
      ),
    },
  ];

  const AddButton = () => {
    return (
      <button
        className="ttnk-button add-button"
        onClick={() => history.push("/business/new")}
      >
        Add Business
      </button>
    );
  };

  const updateButtonHandler = (id) => {
    history.push(`/business/${id}`);
  };

  useEffect(() => {
    if (
      user?.app_data?.setup?.enterprise ||
      user?.app_data?.reseller_of?.length > 0
    )
      return;

    history.push("/");
  }, [user?.app_data?.setup?.enterprise, user?.app_data?.reseller_of?.length]);

  return (
    <Page
      links={links}
      title="Business List"
      // titleComponent={AddButton()}
      extra={
        <div className="mb-5">
          {businessLinks.map((link, index) => {
            if (
              link.type === "PRO+" &&
              user?.app_data?.reseller_of?.length > 0 &&
              !user?.app_data?.setup?.enterprise
            )
              return null;
            return (
              <div
                key={index}
                className="flex flex-row items-center text-gray-700 font-medium"
              >
                Your {link.type} Account Setup WEB URL:{" "}
                <span
                  className="underline ml-1 cursor-pointer"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/${user?.app?.id}/${link.url}`,
                      "_blank"
                    );
                  }}
                >
                  {window.location.origin}/{user?.app?.id}/{link.url}
                </span>
                <Tooltip title={<div>Copy</div>}>
                  <img
                    src={copy}
                    className="h-5 ml-2 w-5 cursor-pointer hover:scale-125"
                    alt="copy"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.origin}/${user?.app?.id}/${link.url}`
                      )
                    }
                  />
                </Tooltip>
              </div>
            );
          })}
        </div>
      }
    >
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "email"]}
      />
    </Page>
  );
};

export default BusinessList;
