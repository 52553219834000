import { useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  Select,
  TextArea,
  CheckBox,
  RichText,
} from "../components/EntryForm";
import Page from "../components/Page";

import { GetTag, UpdateTag } from "../services/api/tag";
import { AddAppResource } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import { useFormik } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import responseProcessor from "../lib/responseProcessor";
import { validations } from "../lib/form";
import MediaUploader from "../components/MediaUploader";
import DataSelector from "../components/DataSelector";
import DateSelector from "../components/DateSelector";
import VersionizerWrapper from "../components/Versionizer";

const TagDetail = ({ newTag, match }) => {
  const [metadata, setMetadata] = useState({});

  const [initialValues, setInitialValues] = useState({
    name: "",
    unlock_code: "",
    code: "",
    email: "",
    notes: "",
    category: "",
    send_email: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserProvider();

  const { showModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const uuid = newTag ? user.currentApp() : match.params.uuid;

  const history = useHistory();

  useEffect(() => {
    if (newTag) {
      setInitialValues({
        name: "",
        code: "",
        email: "",
        notes: "",
        category: "",
        send_email: false,
        customized_confirmation_screen: false,
        confirmation: {
          headline: "",
          image_url: "",
          media_metadata: {},
          message: "",
          web_message: "",
          video_url: "",
        },
        tags: [],
        courses: [],
        expiry_date: "",
      });
    } else {
      setIsLoading(true);
      GetTag(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            name: res.data.name,
            unlock_code: res.data.unlock_code,
            code: res.data.code,
            email: res.data.email,
            notes: res.data.notes,
            category: res.data.category,
            send_email: res.data.send_email,
            customized_confirmation_screen:
              res.data.customized_confirmation_screen,
            confirmation: res.data.confirmation,
            tags: res?.data?.tags,
            courses: res?.data?.courses,
            expiry_date: res?.data?.expiry_date,
          });
          setMetadata(res?.data?.confirmation?.media_metadata ?? {});
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newTag ? "New" : "Edit";
  const currentUrl = newTag ? "/tags/new" : `/tags/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "Tag List", url: "/tags" },
    { name: `${currentAction} Tag`, url: currentUrl },
  ];

  let schema = {
    email: Yup.string()
      .email("Invalid email")
      .max(255, "Maximum 255 Characters")
      .required("Required"),
    name: Yup.string().max(20, "Maximum 20 Characters").required("Required"),
    code: Yup.string().max(20, "Maximum 20 Characters").required("Required"),
    category: Yup.string().required("Required"),
    notes: Yup.string(),
    tags: Yup.array().notRequired(),
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: () =>
      Yup.lazy((values) => {
        let schema = {
          email: Yup.string()
            .email("Invalid email")
            .max(255, "Maximum 255 Characters"),
          name: validations.str_req_20,
          code: validations.str_req_20,
          category: Yup.string().required("Required"),
          notes: Yup.string(),
          tags: Yup.array().test(
            "required",
            "Tag Bundle fields need at least 2 tags combined",
            function () {
              const tagsLength = this.parent?.tags?.length || 0;
              const coursesLength = this.parent?.courses?.length || 0;

              return formik.values.category === "TagBundle"
                ? tagsLength + coursesLength > 1
                : true;
            }
          ),
        };
        if (values?.send_email) {
          schema.email = Yup.string()
            .email("Invalid email")
            .max(255, "Maximum 255 Characters")
            .required("Required");
        }

        if (!!values?.customized_confirmation_screen) {
          let confirmationSchema = {
            headline: Yup.string().max(255, "Maximum 255 Characters"),
            message: Yup.string().max(65535, "Maximum 65,535 Characters"),
            web_message: Yup.string().max(65535, "Maximum 65,535 Characters"),
          };

          if (
            !values?.confirmation?.image_url &&
            !values?.confirmation?.video_url
          ) {
            confirmationSchema.video_url = Yup.string();
            confirmationSchema.image_url = Yup.string();
          }

          schema.confirmation = Yup.object().shape(confirmationSchema);
        }
        return Yup.object().shape(schema);
      }),
    onSubmit: async (values) => {
      delete values.unlock_code;

      if (newTag) {
        AddAppResource(uuid, "tags", user.idToken, { data: values }).then(
          (response) => responseProcessorProxy(response)
        );
      } else {
        UpdateTag(uuid, user.idToken, { data: values }).then((response) =>
          responseProcessorProxy(response)
        );
      }
    },
  });

  const responseProcessorProxy = (response) => {
    responseProcessor({
      response,
      showModal,
      onSuccess: () => history.push("/tags"),
    });
  };

  return (
    <Page links={links} title={`${currentAction} Tag`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/tags"
      >
        <Column>
          <Select
            name="category"
            type="text"
            label="Type"
            formik={formik}
            disabled={!newTag && formik.values.category === "TagBundle"}
          >
            <option value={null} label="Select Tag Type..." />
            <option value="Content" label="Content" />
            <option value="VirtualEvent" label="Virtual Event" />
            <option value="Summit" label="Summit" />
            <option value="TagBundle" label="Tag Bundle" />
          </Select>
          {!newTag && (
            <Input
              name="unlock_code"
              type="input"
              label="Unlock Code"
              formik={formik}
              disabled
            />
          )}
          <Input name="name" type="input" label="Tag Name" formik={formik} />
          <Input name="code" type="input" label="Tag" formik={formik} />
          {formik.values.category === "TagBundle" && (
            <>
              <DataSelector
                name="tags"
                formik={formik}
                label="Content Tags"
                resourceEndpoint="tags"
                filterOptions={(options) => {
                  return options.filter(
                    (a) => a.category !== "TagBundle" && !a.is_expired
                  );
                }}
              />
              <DataSelector
                name="courses"
                formik={formik}
                label="Courses Tags"
                resourceEndpoint="courses"
                filterOptions={(options) => {
                  return options.filter((a) => a.active);
                }}
              />
            </>
          )}
        </Column>
        <Column>
          <TextArea
            name="notes"
            type="text"
            label="Tag Created For"
            formik={formik}
          />
          <Input
            name="email"
            type="email"
            label="Notification Email"
            formik={formik}
          />
          <CheckBox name="send_email" label="Send Email" formik={formik} />
          <DateSelector
            name="expiry_date"
            type="text"
            label="Unlock Code Expiry Date"
            formik={formik}
          />
          <div>
            <CheckBox
              formik={formik}
              name="customized_confirmation_screen"
              label="Customize Confirmation Screen"
            />
            <VersionizerWrapper maxVersion={8} minVersion={8}>
              <p className="italic" style={{ marginTop: -37, marginBottom: 20, marginLeft: 60 }}>
                Available in app Platform Version 8.5 & up and WEB 
              </p>
            </VersionizerWrapper>
          </div>        
          {!!formik.values.customized_confirmation_screen && (
            <>
              <Input
                name="confirmation.headline"
                type="text"
                label="Headline"
                formik={formik}
              />
              <MediaUploader
                name="confirmation.video_url"
                type="text"
                label="Video"
                formik={formik}
                metadata={metadata}
                mediaType="video"
              />
              <MediaUploader
                name="confirmation.image_url"
                type="text"
                label="Image"
                formik={formik}
                metadata={metadata}
                mediaType="image"
              />
              <RichText
                id="tagDetail"
                name="confirmation.message"
                quillName="confirmation.web_message"
                type="text"
                label="Message"
                rows="21"
                formik={formik}
                contentType="tagDetail"
              />
            </>
          )}
        </Column>
      </EntryForm>
    </Page>
  );
};

export default TagDetail;
