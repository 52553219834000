const actionFormListingConfig = {
    label: "ACTIONFORM",
    resourceEndpoint: "actionform",
    listMetadata: [
    ],
    keepListMetadataOrder: true,
    searchKeys: ["name"],
    detailsMetadata: [
      { name: "form_type", label: "Form Type"},
      { name: "name", label: "Form Name"},
      { name: "headline", label: "Headline"},
      { name: "actionform_description", label: "Description"},
      { name: "email", label: "Notification Email"},
      { name: "submit_link", label: "Submit Link"},
      { name: "active", label: "Active"},
      { name: "auto_approve", label: "Auto-Appoval of Submitted Forms" },
      { name: "link_expiry_date", label: "Link Expiry Date"},
    ],
    formLayout: {
      columns: [
        [
          "form_type",
          "name",
          "headline",
          "actionform_description"
        ],
        [
          "email",
          "submit_link",
          "active",
          "auto_approve",
          "link_expiry_date",
        ],
      ],
    }
  }
  
  export default actionFormListingConfig;
  