import React from "react";
import {
  colors,
  statusDropdownOptionsByType,
  APPROVED,
} from "./OnboardingBuildAndSubmit";
import { FaCheck } from "react-icons/fa";
import BuildAndSubmitStatusDropdown from "./BuildAndSubmitStatusDropdown";

const Approved = ({
  getIsCompleted,
}) => {

  return (
    <div className="flex flex-row justify-between items-center p-2">
      <div className="w-full flex flex-row justify-start items-center">
        <button
          type="button"
          className={`p-2 cursor-not-allowed border-3 rounded`}
          style={{
            background:
              getIsCompleted({ id: APPROVED })
                ? colors.dark
                : "#FFFFFF",
            borderColor: colors.dark,
          }}
        >
          <div
            className="rounded-lg"
            style={{
              borderColor:
                getIsCompleted({ id: APPROVED })
                  ? colors.dark
                  : "#FFFFFF",
              backgroundColor:
                getIsCompleted({ id: APPROVED })
                  ? colors.dark
                  : "#FFFFFF",
            }}
          >
            <FaCheck className={`text-white font-bold rounded-lg h-3 w-3`} />
          </div>
        </button>

        <span className={`line-clamp-2 text-xl font-bold ml-2 text-2xl`}>
          {APPROVED}
        </span>
      </div>

      <div className="flex items-center w-full justify-end -mb-5">
        <BuildAndSubmitStatusDropdown
          name={APPROVED}
          options={statusDropdownOptionsByType?.[APPROVED]?.options}
          style={{ width: 130 }}
        />
      </div>
    </div>
  );
};

export default Approved;
