import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";
import TableActions from "../components/TableActions"
import { mergeArray } from "../lib/arrayTools"
import { useHistory } from 'react-router-dom'

import { useUserProvider } from "../providers/UserProvider"
import { useModalProvider } from "../providers/ModalProvider"

import { DeleteCourseCategory } from "../services/api/courseCategory"
import { GetAppResource } from "../services/api/app"

import DeleteItem from "../components/modal_contents/DeleteItem"
import { CloneItem } from "../services/api/clone";

const CourseCategoriesList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  })

  const history = useHistory()

  const { user } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true })
    GetAppResource(user, 'course_categories')
      .then(res => res.json())
      .then(jsonData => {
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length
        })
      })
  }

  const links = [
    { name: "Home", url: "/" },
    { name: 'Course Categories List', url: '/course_categories' },
  ]

  const metadata = [
    // {
    //   name: 'key',
    //   label: 'Key',
    //   parser: (data) => (data.id),
    // },
    {
      name: 'sort_order',
      label: 'Sort Order',
      parser: (data) => (data.sort_order),
      sorter: (firstData, secondData)=>{
        return firstData.sort_order - secondData.sort_order
      },
      defaultSortOrder: "ascend",
      sortDirections:["ascend", "descend"]
    },
    {
      name: 'name',
      label: 'Category Name',
      parser: (data) => (data.name)
    },
    {
      name: 'sub_line',
      label: 'Sub-Line',
      parser: (data) => (data.sub_line)
    },
    {
      name: 'tags',
      label: 'Tags',
      parser: (data) => (<span className="ttnk-tag">{mergeArray(data.tags, 'name')}</span>)
    },
    // {
    //   name: 'image_url',
    //   label: 'Image URL',
    //   parser: (data) => (data.image_url),
    //   ellipsis: true,
    // },
    {
      name: 'actions',
      label: 'Actions',
      parser: (data) => (<TableActions onUpdateClick={updateButtonHandler} onDeleteClick={showDeleteModal} item_id={data.id}  onCloneClick={cloneButtonHandler}/>),
    },
  ]

  const AddButton = () => {
    return <button className="ttnk-button add-button" onClick={() => history.push('/course_categories/new') }>Add Category</button>
  }

  const updateButtonHandler = (id) => {
    history.push(`/course_categories/${id}`)
  }

  const deleteButtonHandler = async (id) => {
    await DeleteCourseCategory(id, user.idToken);
    loadData()
    closeModal()
  }

  const cloneButtonHandler  = async (id) => {
    const res = await CloneItem(user, {
      data: {
        id,
        name: 'course_categories'
      }
    })
    const cloneData = await res.json()
    const cloneId = cloneData.data?.id

    loadData();
    history.push(`/course_categories/${cloneId}`)


  };

  const showDeleteModal = (id) => {
    showModal(<DeleteItem itemId={id} itemLabel='Course Categories' onDelete={deleteButtonHandler} onClose={closeModal}/>)
  }

  return (
    <Page links={links} title={'Course Categories List'} titleComponent={AddButton()}>
      <DataTable  metadata={metadata}
                  dataLoader={loadData}
                  data={data}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  searchKeys={['name', 'sub_line']} />
    </Page>
  )
}

export default CourseCategoriesList;
