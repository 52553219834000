import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  Select,
  TextArea,
} from "../components/EntryForm";
import DateSelector from "../components/DateSelector";
import DataSelector from "../components/DataSelector";
import Page from "../components/Page";
import {
  GetVirtualEvent,
  UpdateVirtualEvent,
} from "../services/api/virtual_event";
import { GetAppResource, AddAppResource } from "../services/api/app";
import { UpdateContent, DeleteContent } from "../services/api/content";
import MediaUploader from "../components/MediaUploader";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import SubTable from "../components/SubTable";
import contentConfig from "../lib/content_config/config";
import ErrorModal from "../components/modal_contents/ErrorModal";
import responseProcessor from "../lib/responseProcessor";
import { useDuplicateValidation, validations } from "../lib/form";

const VirtualEventDetail = ({ newVirtualEvent, match }) => {
  const contentType = "virtualEvents";
  const subTables = contentConfig(contentType).subTables;
  const vals = {
    name: "",
    tag_id: "",
    note: "",
    image_url: "",
    sales_video_url: "",
    sales_headline: "",
    sales_description: "",
    enrolled_video_url: "",
    enrolled_headline: "",
    enrolled_description: "",
    start_date: moment().utcOffset(0, true),
    registration_url: "",
    active: true,
    sort_order: 1,
    details: [],
    resources: [],
    media_metadata: {},
  };
  const [initialValues, setInitialValues] = useState(vals);
  const [tagValues, setTagValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [_isTagLoading, setIsTagLoading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const { user } = useUserProvider();
  const { showModal } = useModalProvider();
  const { doSetShowLoadingModal } = useLoaderProvider();
  const uuid = newVirtualEvent ? user.currentApp() : match.params.uuid;
  const history = useHistory();

  useEffect(() => {
    if (newVirtualEvent) {
      setInitialValues(vals);
    } else {
      setIsLoading(true);
      GetVirtualEvent(uuid, user.idToken)
        .then((res) => res.json())
        .then((res) => {
          setInitialValues({
            name: res.data.name,
            tag_id: res.data.tag.id,
            note: res.data.note,
            image_url: res.data.image_url,
            sales_video_url: res.data.sales.video_url
              ? res.data.sales.video_url
              : "",
            sales_headline: res.data.sales.headline
              ? res.data.sales.headline
              : "",
            sales_description: res.data.sales.description
              ? res.data.sales.description
              : "",
            enrolled_video_url: res.data.enrolled.video_url
              ? res.data.enrolled.video_url
              : "",
            enrolled_headline: res.data.enrolled.headline
              ? res.data.enrolled.headline
              : "",
            enrolled_description: res.data.enrolled.description
              ? res.data.enrolled.description
              : "",
            start_date: res.data.start_date,
            registration_url: res.data.registration_url,
            active: true,
            sort_order: res.data.sort_order,
            details: res.data.details,
            speakers: res.data.speakers,
            resources: res.data.resources || [],
          });
          setIsLoading(false);
          setMetadata(res.data.media_metadata);
        });
    }

    setIsTagLoading(true);
    GetAppResource(user, "tags?category=VirtualEvent")
      .then((res) => res.json())
      .then((res) => {
        setTagValues(
          res.data
            .filter((a) => !a.is_expired)
            .map((tag) => ({ id: tag.id, name: tag.name }))
        );
        setIsTagLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAction = newVirtualEvent ? "New" : "Edit";
  const currentUrl = newVirtualEvent
    ? "/virtual_events/new"
    : `/virtual_events/${uuid}`;

  let links = [
    { name: "Home", url: "/" },
    { name: "ActionEvents", url: "/virtual_events" },
    { name: `${currentAction} ActionEvent`, url: currentUrl },
  ];

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  let schema = {
    name: validations.str_req_255,
    note: validations.str_notreq_50,
    tag_id: validations.long_text_required,
    start_date: Yup.date().required("Required"),
    registration_url: validations.str_req_255,
    details: Yup.array(),
    resources: Yup.array(),
    image_url: validations.str_255,
    sales_video_url: validations.str_255,
    sales_headline: validations.str_notreq_50,
    sales_description: Yup.string(),
    enrolled_video_url: validations.str_255,
    enrolled_headline: validations.str_notreq_50,
    enrolled_description: Yup.string(),
    sort_order: validations.sort_order,
  };

  // this only works with string values
  const _checkDuplicate = (arrayValues, fieldsToCheck) => {
    const tempObject = {};
    let hasDuplicate = false;
    for (let index = 0; index < arrayValues.length; index++) {
      const currentObjectInArray = arrayValues[index];

      for (
        let fieldIndex = 0;
        fieldIndex < fieldsToCheck.length;
        fieldIndex++
      ) {
        const currentFieldToCheck = fieldsToCheck[fieldIndex];
        const currentFieldValue = currentObjectInArray[currentFieldToCheck]
          .trim()
          .toLowerCase();
        if (
          Array.isArray(tempObject[currentFieldToCheck]) &&
          tempObject[currentFieldToCheck].includes(currentFieldValue)
        ) {
          hasDuplicate = true;
          break;
        }

        tempObject[currentFieldToCheck] = Array.isArray(
          tempObject[currentFieldToCheck]
        )
          ? [...tempObject[currentFieldToCheck], currentFieldValue]
          : [currentFieldValue];
      }

      if (hasDuplicate) {
        break;
      }
    }

    return hasDuplicate;
  };

  const checkResourcesDuplicate = useDuplicateValidation(
    ["title", "description"],
    "resources"
  );
  const checkDetailDuplicate = useDuplicateValidation(["info"], "details");

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    validate: (values) => {
      checkResourcesDuplicate(values, formik);
      checkDetailDuplicate(values, formik);
    },
    onSubmit: async (values) => {
      const newDetails = values?.details?.map((d) => ({
        ...d,
        id: Number(d?.id),
      }));

      // checks if there is a duplicate in resources entries
      if (_checkDuplicate(values?.resources, ["title", "description"])) {
        showModal(<ErrorModal status={409} />);
        throw new Error("409");
      }

      const newResources = values?.resources?.map((r) => {
        const val = {
          ...r,
          release_date: moment().toISOString(),
          featured: false,
          virtual_event: { id: uuid },
        };

        val.tags = r.tags || [];
        val.topics = r.topics || [];
        return val;
      });

      let payload = {
        ...values,
        tag: { id: values.tag_id },
        sales: {
          video_url: values.sales_video_url,
          headline: values.sales_headline,
          description: values.sales_description,
        },
        enrolled: {
          video_url: values.enrolled_video_url,
          headline: values.enrolled_headline,
          description: values.enrolled_description,
        },
        details: newDetails,
        resources: newResources,
      };

      delete payload.tag_id;
      delete payload.sales_video_url;
      delete payload.sales_headline;
      delete payload.sales_description;
      delete payload.enrolled_video_url;
      delete payload.enrolled_headline;
      delete payload.enrolled_description;

      if (newVirtualEvent) {
        AddAppResource(uuid, "virtual_events", user.idToken, { data: payload })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData?.data?.id) {
              if (payload?.resources?.length) {
                const res =
                  payload?.resources?.map((r) => {
                    r.virtual_event = { id: jsonData.data.id };
                    return r;
                  }) || [];
                doSaveResources(res, "add");
              } else {
                responseProcessor({
                  jsonData,
                  response: {
                    ok: true,
                  },
                  showModal,
                  onSuccess: () => history.push("/virtual_events"),
                });
              }
            } else {
              showModal(
                <ErrorModal
                  status={jsonData?.error?.status}
                  response={jsonData}
                />
              );
            }
          })
          .catch((error) => {
            showModal(<ErrorModal response={{ status: 500 }} />);
          });
      } else {
        UpdateVirtualEvent(uuid, user.idToken, { data: payload }).then(
          (response) => {
            doSaveResources(payload?.resources);
            responseProcessor({
              response,
              showModal,
              onSuccess: () => history.push("/virtual_events"),
            });
          }
        );
      }
    },
  });

  const doProcessResourcesResponse = (actions) => {
    Promise.all(actions).then((response) => {
      const hasError = response?.find((r) => !r?.ok);
      if (hasError) {
        return showModal(<ErrorModal response={{ status: 500 }} />);
      }
      history.push("/virtual_events");
    });
  };

  const doFormatResources = (res) => {
    return res?.map((n) => ({
      action_bar_text: n?.action_bar_text,
      action_bar_url: n?.action_bar_url,
      description: n?.description,
      video_url: n?.video_url,
      image_url: n?.image_url,
      title: n?.title,
      sort_order: n?.sort_order,
      tags: n?.tags,
      id: n?.id,
    }));
  };

  const doTagVirtualEvent = (d, form) => {
    return d?.map((d) => ({
      ...d,
      release_date: moment().toISOString(),
      featured: false,
      virtual_event: { id: form === "add" ? d?.virtual_event?.id : uuid },
      topics: [],
    }));
  };

  const doSaveResources = (data, form) => {
    const { resources } = initialValues;
    const newData = doFormatResources(data);
    const newResources = doFormatResources(resources);
    const addResources = _.filter(newData, (d) => !d?.id);
    const updatedResources = _.differenceWith(
      newData?.filter((n) => n?.id),
      newResources?.filter((n) => n?.id),
      _.isEqual
    );
    const removedResources = resources?.filter(
      (r) => !data?.find((ri) => ri?.id === r?.id)
    );

    if (addResources?.length) {
      const newResourceData =
        form === "add" ? _.filter(data, (d) => !d?.id) : addResources;
      const finalData = doTagVirtualEvent(newResourceData, form);
      const actions = finalData?.map((d) =>
        AddAppResource(user.currentApp(), "content/resources", user?.idToken, {
          data: d,
        })
      );
      doProcessResourcesResponse(actions);
    }

    if (updatedResources?.length) {
      const finalData = doTagVirtualEvent(updatedResources);
      const actions = finalData?.map((d) =>
        UpdateContent(d?.id, user?.idToken, { data: d })
      );
      doProcessResourcesResponse(actions);
    }

    if (removedResources?.length) {
      const actions = removedResources?.map((d) =>
        DeleteContent(d?.id, user?.idToken)
      );
      doProcessResourcesResponse(actions);
    }
  };

  return (
    <Page links={links} title={`${currentAction} ActionEvent`}>
      <EntryForm
        isLoading={isLoading}
        formik={formik}
        submitHandler={formik.handleSubmit}
        cancelURL="/virtual_events"
      >
        <Column>
          <Input name="name" type="text" label="Event Name" formik={formik} />
          <Select name="tag_id" type="text" label="Event Tag" formik={formik}>
            <option value={null} label="Select Tag..." />
            {tagValues.map((tag) => (
              <option key={tag.id} value={tag.id} label={tag.name} />
            ))}
          </Select>
          <TextArea
            name="note"
            type="text"
            label="Event Dates (Text)"
            formik={formik}
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <MediaUploader
            name="sales_video_url"
            type="text"
            label="Sales Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <Input
            name="sales_headline"
            type="text"
            label="Sales Headline"
            formik={formik}
          />
          <TextArea
            name="sales_description"
            type="text"
            label="Sales Description"
            formik={formik}
          />
        </Column>
        <Column>
          <MediaUploader
            name="enrolled_video_url"
            type="text"
            label="Enrolled Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <Input
            name="enrolled_headline"
            type="text"
            label="Enrolled Headline"
            formik={formik}
          />
          <TextArea
            name="enrolled_description"
            type="text"
            label="Enrolled Description"
            formik={formik}
          />
          <DateSelector
            name="start_date"
            type="text"
            label="Start Date"
            formik={formik}
            format="DD-MM-YYYY"
          />
          <Input
            name="registration_url"
            type="text"
            label="Registration URL"
            formik={formik}
          />
          <div className="flex">
            <span className="flex w-1/3">
              <Input
                name="sort_order"
                type="number"
                label="Sort Order"
                formik={formik}
              />
            </span>

            <span className="flex w-1/3"></span>

            <span className="flex w-1/3">
              <CheckBox name="active" label="Active?" formik={formik} />
            </span>
          </div>
          <DataSelector
            name="speakers"
            formik={formik}
            label="Speakers"
            resourceEndpoint="profiles"
          />
        </Column>
        {subTables &&
          Object.keys(subTables).map((key) => {
            return (
              <div className="w-full mb-5 px-5" key={key}>
                <SubTable
                  formik={formik}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  contentConfig={contentConfig(contentType)}
                  subContentType={key}
                  labels={subTables[key].label}
                  data={{
                    loading: false,
                    count: initialValues[key] ? initialValues[key].length : 0,
                    data: initialValues[key] ? initialValues[key] : [],
                  }}
                  contentType={contentType}
                  onAddClick={() => {}}
                  loading={false}
                />
              </div>
            );
          })}
      </EntryForm>
    </Page>
  );
};

export default VirtualEventDetail;
