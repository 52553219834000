import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const CreateGetEntity = (entityType)=>{
  return async (uuid, idToken) => {
    return await callEndpoint(`${process.env.REACT_APP_API}/${entityType}/${uuid}`, 'GET', idToken)
  }
}

export const CreateUpdateEntity = (entityType)=>{
  return async (uuid, idToken, payload) => {
    return await callEndpointWithPayload(`${process.env.REACT_APP_API}/${entityType}/${uuid}`, 'PATCH', idToken, payload)
  }
}

export const CreateDeleteEntity = (entityType)=>{
  return async (uuid, idToken) => {
    return await callEndpoint(`${process.env.REACT_APP_API}/${entityType}/${uuid}`, 'DELETE', idToken)
  }
}