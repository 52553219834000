import * as Yup from "yup";

import { validations } from "../form"
import ImageSlideout from '../../assets/images/profile-slideout.jpg'
import { VideoPlayer } from "../../components/VideoPlayer"
import { Image } from "../../components/Image"
import Description from "../../components/Description"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { useOnboardingProvider } from "../../providers/OnboardingProvider";
import { ENTERPRISE } from "./config";

const DEFAULT_VALUES = {
  profile_name: "",
  title: "",
  business_name: "",
  onboarding_profile_image_url: "",
  will_use_business_name: false,
  email: "",
  bio: "",
  web_url: "",
  facebook_url: "",
  facebook_id: "",
  twitter_url: "",
  instagram_url: "",
  youtube_url: "",
  linkedin_url: ""
}

const OnboardingProfile = ({ handleSubmit, initialValues }) => {
  const { onboardingData } = useOnboardingProvider();

  const fields = [
    {
      component: 'title',
      label: "Title",
      placeholder: "What is your Title in your business?",
      isFieldRow: true
    },
    {
      component: "profile_name",
      label: "Your Profile Name",
      placeholder: "First and Last Name",
      isFieldRow: true,
      required: true
    },
    {
      component: "business_name",
      label: "Business Name",
      placeholder: "The name of your business",
      isFieldRow: true
    },
    {
      component: "will_use_business_name",
      label: "Use my Business Name in place of First/Last Name",
      isFieldRow: true,
    },
    {
      component: "onboarding_profile_image_url",
      label: "Profile Image",
      isFieldRow: true,
      required: true
    },
    {
      component: "bio",
      label: "Bio",
      placeholder: "Your Bio or business description",
      rows: 4,
      isFieldRow: true,
      required: true
    },
    {
      component: "email",
      label: "Business Email",
      isFieldRow: true
    },
    {
      component: "web_url",
      label: "Website Link",
      placeholder: "Website URL",
      isFieldRow: true
    },
    {
      component: "facebook_url",
      label: "Facebook Link",
      placeholder: "Facebook URL",
      isFieldRow: true
    },
    {
      component: "twitter_url",
      label: "Twitter Link",
      placeholder: "Twitter URL",
      isFieldRow: true
    },
    {
      component: "instagram_url",
      label: "Instagram Link",
      placeholder: "Instagram URL",
      isFieldRow: true
    },
    {
      component: "youtube_url",
      label: "Youtube Link",
      placeholder: "Youtube URL",
      isFieldRow: true
    },
    {
      component: "linkedin_url",
      label: "Linkedin Link",
      placeholder: "Linkedin URL",
      isFieldRow: true
    },
  ]

  const schema = {
    title: validations.str_notreq_35,
    profile_name: validations.str_req_30,
    business_name: validations.str_notreq_35,
    onboarding_profile_image_url: Yup.string()
      .required('Required'),
    bio: Yup.string().required('Required').max(65535, "Maximum 65,535 Characters"),
    email: Yup.string()
      .email("Invalid email")
      .max(255, "Maximum 255 Characters"),
    web_url: validations.long_url,
    facebook_url: validations.long_url,
    twitter_url: validations.long_url,
    instagram_url: validations.long_url,
    youtube_url: validations.long_url,
    linkedin_url: validations.long_url
  };

  // reformat data if components used vs payload data is different
  // if you didnt change payload response, skip this and just pass values to defaultValues props
  const formatDefaultValues = () => {
    if (initialValues?.id) {
      const newPayload = {
        ...DEFAULT_VALUES,
        ...initialValues,
        onboarding_profile_image_url: initialValues?.profile_image_url,
        web_url: initialValues?.website_url ?? "",
        profile_name: initialValues?.name ?? "",
        will_use_business_name: initialValues?.will_use_business_name,
        active: initialValues?.active,
      }

      return newPayload
    }

    return DEFAULT_VALUES
  }

  return {
    config: {
      label: "Profile",
      resourceEndpoint: "profiles",
      columnsMetadata: [
        { customComponent: <VideoPlayer url="https://www.youtube.com/watch?v=hH3WgX-IAX8" wrapperClass="py-4" /> },
        { customComponent: (
          <Description wrapperClass="py-4">
            This is the info that displays on the About Us screen in your App (see image below)
          </Description>
        ) },
        { customComponent: (
          <Image
            src={ImageSlideout}
            alt="Profile"
            className="w-4/12 py-4"
          />
        ) },
        { customComponent: (
          <OnboardingContent
            title="Profile"
            defaultValues={formatDefaultValues()}
            schema={schema}
            fields={fields}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {

              const newPayload = {
                title: values?.title,
                name: values?.will_use_business_name ? values?.business_name : values?.profile_name,
                business_name: values?.business_name,
                profile_image_url: values?.onboarding_profile_image_url,
                bio: values?.bio,
                email: values?.email,
                website_url: values?.web_url,
                facebook_url: values?.facebook_url,
                twitter_url: values?.twitter_url,
                instagram_url: values?.instagram_url,
                youtube_url: values?.youtube_url,
                linkedin_url: values?.linkedin_url,
                will_use_business_name: values?.will_use_business_name,
                active: initialValues?.id ? values?.active : true,
              }

              return newPayload
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingProfile;