import * as React from 'react'
import { useContext, useState } from 'react'

export const OnboardingContext = React.createContext()

export const OnboardingProvider = ({ children }) => {
  const [onboardingData, setOnboardingData] = useState({})
  const [refetchContent, setRefetchContent] = useState(false)
  const [refetchCounter, setRefetchCounter] = useState(false)

  return(
    <OnboardingContext.Provider value={ {
      onboardingData,
      setOnboardingData,
      refetchContent,
      setRefetchContent,
      refetchCounter,
      setRefetchCounter
    } }>
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboardingProvider = () => {
  const context = useContext(OnboardingContext)

  if (context === undefined) {
    throw new Error('Invalid use of useDataContext')
  }

  return context
}
