import { useCallback, useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  TextArea,
  InputReadonly,
} from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp, UpdateApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";

import MediaUploader from "../components/MediaUploader";
import { validations } from "../lib/form";

import responseProcessor from "../lib/responseProcessor";

import { useFormik } from "formik";

import * as Yup from "yup";
import useLocalStorage from "../lib/useLocalStorage";
import { useHistory } from "react-router-dom";
import VersionizerWrapper from "../components/Versionizer";

const AboutApp = ({ newUser, match }) => {
  const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    title: "",
    video_url: "",
    image_url: "",
    description: "",
    action_bar_text: "",
    action_bar_url: "",
    media_metadata: {},
    privacy_policy_url: "",
    platform_version: "",
  });

  const [metadata, setMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [platformVersion, setPlatformVersion] = useState(0);

  const { user, setUser } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();
  const [currentSelectedApp, setCurrentSelectedApp] = useLocalStorage(
    "currentApp",
    null
  );

  useEffect(() => {
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const { about, setup } = res.data;
        formik.resetForm();
        setInitialValues({
          title: about.title,
          video_url: about.video_url,
          image_url: about.image_url,
          description: about.description,
          action_bar_text: about.action_bar_text,
          action_bar_url: about.action_bar_url,
          privacy_policy_url: about.privacy_policy_url,
          platform_version: about.platform_version,
        });
        setPlatformVersion(parseFloat(about?.platform_version));
        setMetadata(about.media_metadata);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "About App", url: "/app/about" },
  ];

  let schema = {
    title: validations.str_req_50,
    video_url: validations.long_text,
    image_url: validations.long_text,
    description: validations.long_text_required,
    action_bar_text: validations.str_notreq_50,
    action_bar_url: validations.long_text,
    privacy_policy_url: validations.long_url,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values) => {
      const { name, ...rest } = values;
      const payload = { name, about: rest };

      UpdateApp(user.currentApp(), user.idToken, { data: payload }).then(
        (response) =>
          responseProcessor({
            response,
            showModal,
            onSuccess,
          })
      );
    },
  });

  const onSuccess = () => {
    setSaved(true);
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const { about } = res.data;
        setCurrentSelectedApp(res.data);
        setUser((prev) => ({ ...prev, app: res.data }));
        formik.resetForm();
        setInitialValues({
          title: about.title,
          video_url: about.video_url,
          image_url: about.image_url,
          description: about.description,
          action_bar_text: about.action_bar_text,
          action_bar_url: about.action_bar_url,
          privacy_policy_url: about.privacy_policy_url,
        });
        setMetadata(about.media_metadata);
        setIsLoading(false);
      });
  };

  const SavedLabel = () => <span className="text-green-400">Saved</span>;

  return (
    <Page links={links} title="About App">
      <EntryForm
        isLoading={isLoading}
        submitHandler={formik.handleSubmit}
        formik={formik}
        savedLabel={saved && !formik.dirty && SavedLabel()}
      >
        <Column>
          <Input name="title" type="text" label="Title" formik={formik} />
          <MediaUploader
            name="video_url"
            type="text"
            label="Video URL"
            formik={formik}
            metadata={metadata}
            mediaType="video"
          />
          <MediaUploader
            name="image_url"
            type="text"
            label="Image URL"
            formik={formik}
            metadata={metadata}
            mediaType="image"
          />
          <TextArea
            name="description"
            type="text"
            label="About App Text"
            formik={formik}
          />
        </Column>
        <Column>
          <Input
            name="action_bar_text"
            type="text"
            label="Call to Action Text"
            formik={formik}
          />
          <Input
            name="action_bar_url"
            type="text"
            label="Call to Action URL"
            formik={formik}
          />
          <div>
            <Input
              name="privacy_policy_url"
              type="text"
              label="Privacy Policy"
              formik={formik}
            />
            <VersionizerWrapper maxVersion={8.5} minVersion={8}>
              <p className="italic text-right" style={{ marginTop: -12 }}>
                Available in app Platform Version 9.0 & up and WEB
              </p>
            </VersionizerWrapper>
          </div>
        </Column>
      </EntryForm>
    </Page>
  );
};

export default AboutApp;
