import { callEndpoint, callEndpointWithPayload } from "../baseApi";

export const GetMember = async (uuid, user) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/apps/${user.currentApp()}/members/${uuid}`, 'GET', user.idToken)
}

export const UpdateMember = async (uuid, user, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/apps/${user.currentApp()}/members/${uuid}`, 'PATCH', user.idToken, payload)
}

export const DeleteMember = async (uuid, idToken) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/members/${uuid}`,
    "DELETE",
    idToken
  );
};

export const UnlinkMember = async (user, uuid) => {
  return await callEndpoint(
    `${process.env.REACT_APP_API}/apps/${user.currentApp()}/members/${uuid}`,
    "DELETE",
    user.idToken
  );
};
