import Modal from 'react-modal'

const BaseModal = ({ modalOpen, children, customContentStyle}) => {
  const baseModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '0.76rem',
      transform: 'translate(-50%, -50%)',
      zIndex: 11,
      ...customContentStyle
    }
  }

  return(<Modal
      isOpen={modalOpen}
      style={baseModalStyle}
      appElement={document.getElementById('root')}
      contentLabel="Modal"
    >
    { children }
  </Modal>)
}

export default BaseModal
