import { useCallback, useEffect, useState } from "react";
import {
  EntryForm,
  Column,
  Input,
  CheckBox,
  TextArea,
} from "../components/EntryForm";
import Page from "../components/Page";

import { GetApp } from "../services/api/app";
import { useUserProvider } from "../providers/UserProvider";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { useFormik } from "formik";
import CopyIcon from "../components/CopyIcon";
import { Tooltip } from "@material-ui/core";

const AppInfo = () => {
  const [initialValues, setInitialValues] = useState({
    username: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUserProvider();

  const { doSetShowLoadingModal } = useLoaderProvider();

  const getData = useCallback(() => {
    setIsLoading(true);
    GetApp(user, user.idToken)
      .then((res) => res.json())
      .then((res) => {
        const setup = res.data.setup;
        const onboarding = res.data.onboarding;
        setInitialValues({
          app_name: res.data.name,
          tagline: setup?.header?.tagline,
          app_designed_colors: onboarding.app_designed_colors,
          setup: setup,
          app_designed_fonts: onboarding.app_designed_fonts,
          app_designed_graphics_download_url:
            onboarding.app_designed_graphics_download_url,
          qrcode_download_url: onboarding.qrcode_download_url,
          screens_download_url: onboarding.screens_download_url,
          play_store_link: onboarding.play_store_link,
          app_store_link: onboarding.app_store_link,
          qr_code: onboarding.qrcode,
          username: res.data.username,
        });
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    doSetShowLoadingModal(isLoading);
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  let links = [
    { name: "Home", url: "/" },
    { name: "App Info", url: "/app-info" },
  ];

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  return (
    <Page links={links} title="App Info">
      <div>
        <div>ID: {user.app.id}</div>
      </div>
      <EntryForm isLoading={isLoading} formik={formik} hideSubmit={true}>
        <Column>
          <div className="w-full">
            <div className="flex flex-col">
              <Input
                withCopyButton
                wrapperClassName="w-full"
                className="w-full"
                name="app_name"
                type="text"
                label="App Name"
                disabled
                formik={formik}
                labelClassName="flex flex-row items-center"
              />
              <Input
                withCopyButton
                wrapperClassName="w-full"
                className="w-full"
                name="tagline"
                type="text"
                label="Tagline"
                disabled
                formik={formik}
                labelClassName="flex flex-row items-center"
              />
              <TextArea
                withCopyButton
                wrapperClassName="w-full"
                className="w-full"
                name="app_designed_fonts"
                labelClassName="flex flex-row items-center"
                type="text"
                label="Fonts"
                disabled
                formik={formik}
              />
              <TextArea
                withCopyButton
                wrapperClassName="w-full"
                className="w-full"
                labelClassName="flex flex-row items-center"
                name="app_designed_colors"
                type="text"
                label="Colors"
                disabled
                formik={formik}
              />
              <div className="text-lg mb-2 mt-5">Marketing Graphics</div>
              {formik.values.app_designed_graphics_download_url && (
                <a
                  className="underline"
                  style={{
                    color: "#1890ff",
                  }}
                  href={formik.values.app_designed_graphics_download_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zip File
                </a>
              )}
            </div>
          </div>
        </Column>
        <Column>
          <div className="w-full">
            <div className="flex flex-col">
              <div className="text-lg mb-2 mt-5">QR Code</div>
              {formik.values.qrcode_download_url && (
                <div
                  className="underline"
                  style={{
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    const response = await fetch(
                      formik.values.qrcode_download_url
                    );
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = formik.values.app_name + "_qr_code.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    // Release the reference to the blob
                    URL.revokeObjectURL(url);
                  }}
                >
                  <img
                    src={formik.values.qrcode_download_url}
                    alt="qr"
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              {formik.values.qr_code && (
                <div className="flex flex-row items-center gap-2">
                  <a
                    className="underline"
                    style={{
                      color: "#1890ff",
                    }}
                    href={formik.values.qr_code}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formik.values.qr_code}
                  </a>
                  <CopyIcon value={formik.values.qr_code} />
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-lg mb-2 mt-5">Screenshots (Zip)</div>
              {formik.values.screens_download_url && (
                <a
                  className="underline"
                  style={{
                    color: "#1890ff",
                  }}
                  href={formik.values.screens_download_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Zip File
                </a>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-lg mb-2 mt-5">Google Play Link</div>
              {formik.values.play_store_link && (
                <div className="flex flex-row items-center gap-2">
                  <a
                    className="underline"
                    style={{
                      color: "#1890ff",
                      maxWidth: "300px",
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    href={formik.values.play_store_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formik.values.play_store_link}
                  </a>
                  <CopyIcon value={formik.values.play_store_link} />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-lg mb-2 mt-5">Apple Link</div>
            {formik.values.app_store_link && (
              <div className="flex flex-row items-center gap-2">
                <Tooltip title={formik.values.app_store_link}>
                  <a
                    className="underline"
                    style={{
                      color: "#1890ff",
                      maxWidth: "300px",
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    href={formik.values.app_store_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formik.values.app_store_link}
                  </a>
                </Tooltip>
                <CopyIcon value={formik.values.app_store_link} />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <div className="text-lg mb-2 mt-5">WEB URL</div>
            <div className="flex flex-row items-center gap-2">
              <a
                className="underline"
                style={{
                  color: "#1890ff",
                }}
                href={`${process.env.REACT_APP_WEB_FQDN}/${formik.values.username}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_WEB_FQDN}/{formik.values.username}
              </a>
              <CopyIcon
                value={`${process.env.REACT_APP_WEB_FQDN}/${formik.values.username}`}
              />
            </div>
          </div>
        </Column>
      </EntryForm>
    </Page>
  );
};

export default AppInfo;
