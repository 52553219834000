import { useState, useEffect } from "react"
import { useUserProvider } from "../../providers/UserProvider"
import { GetApp } from "../../services/api/app"
import Description from "../../components/Description"
import { VideoPlayer } from "../../components/VideoPlayer"
import ImageSlideout from '../../assets/images/onboarding-info-1.png'
import Image2Slideout from '../../assets/images/onboarding-info-2.jpeg'
import { Image } from "../../components/Image"
import { validations } from "../form"
import { useOnboardingProvider } from "../../providers/OnboardingProvider"
import OnboardingContent from "../../components/onboarding/OnboardingContent"
import { SUBMITTED } from "./config"

const OnboardingInfo = ({ handleSubmit }) => {
  const { onboardingData } = useOnboardingProvider();
  const { user } = useUserProvider();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [initialValues, setInitialValues] = useState({
    app_name: "",
    short_app_description: "",
    full_app_description: "",
    primary_category: "",
    secondary_category: "",
    search_keywords: "",
    in_app_name: "",
    app_icon_name: "",
    support_contact: "Use my current website",
    support_contact_page: "",
    support_email: "",
    privacy_policy: "Use my current website",
    privacy_policy_page: "",
    privacy_policy_document: "",
    i_understand: false,
  });

  useEffect(() => {
    GetApp(user, user.idToken).then((res) => res.json()).then((res) => {
      const app_store = res?.data?.about?.store;
      setIsReadOnly(res?.data?.onboarding?.progress?.store?.status === SUBMITTED && !user?.isSuper);
      setInitialValues({
        short_app_description: app_store?.short_app_description,
        full_app_description: app_store?.full_app_description,
        primary_category: app_store?.primary_category,
        secondary_category: app_store?.secondary_category,
        search_keywords: app_store?.search_keywords,
        in_app_name: app_store?.in_app_name,
        app_icon_name: app_store?.app_icon_name,
        support_contact: app_store?.support_contact,
        support_contact_page: app_store?.support_contact_page,
        support_email: app_store?.support_email,
        privacy_policy: app_store?.privacy_policy,
        privacy_policy_page: app_store?.privacy_policy_page,
        privacy_policy_document: app_store?.privacy_policy_document,
        i_understand: app_store?.i_understand
      });
    })
  }, [])

  const fields = [
    {
      component: "in_app_name",
      isFieldRow: false,
      required: true,
      placeholder: "This shows at the top of the screen when you open your app",
      disabled: isReadOnly
    },
    {
      component: "app_icon_name",
      isFieldRow: false,
      required: true,
      placeholder: "Usually the same as the App Store name (left image, above)",
      disabled: isReadOnly
    }
  ]

  const schema = {
    in_app_name: validations.str_req_30,
    app_icon_name: validations.str_req_11
  };

  return {
    config: {
      label: "Onboarding App Store - Info",
      columnsMetadata: [
        { customComponent: (
          <div className="w-4/5 mx-auto mt-5">
            <VideoPlayer url="https://www.youtube.com/watch?v=srS0vj0tq-8" />
          </div>
        ) },
        { customComponent: (
          <Description wrapperClass="py-4 mt-5" className="text-lg">
            This is information we need from you to build your "test" App (see images below)
          </Description>
        ) },
        { customComponent: (
          <div className="flex gap-4 justify-center items-center">
            <div className="w-4/12 py-4">
              <Image
                src={ImageSlideout}
                alt="Listing"
              />
            </div>
            <div className="w-4/12 py-4">
              <Image
                src={Image2Slideout}
                alt="Listing"
              />
            </div>
          </div>
        ) },
        { customComponent: (
          <OnboardingContent
            title="Info"
            defaultValues={initialValues}
            schema={schema}
            fields={fields}
            isReadOnly={isReadOnly}
            handleSubmit={handleSubmit}
            handleFormatPayload={(values) => {
              const newPayload = {
                about: {
                  store: {
                    short_app_description: values?.short_app_description,
                    full_app_description: values?.full_app_description,
                    primary_category: values?.primary_category,
                    secondary_category: values?.secondary_category,
                    search_keywords: values?.search_keywords,
                    in_app_name: values?.in_app_name,
                    app_icon_name: values?.app_icon_name,
                    support_contact: values?.support_contact ?? 'Use my current website',
                    support_contact_page: values?.support_contact_page,
                    support_email: values?.support_email,
                    privacy_policy: values?.privacy_policy ?? 'Use my current website',
                    privacy_policy_page: values?.privacy_policy_page,
                    privacy_policy_document: values?.privacy_policy_document
                  }
                },
                onboarding: {
                  ...onboardingData,
                  progress: {
                    ...onboardingData?.progress,
                    store: {
                      status: 'In Progress'
                    }
                  }
                }
              }

              return newPayload;
            }}
          />
        ) },
      ]
    }
  }
}

export default OnboardingInfo;