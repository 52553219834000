import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetCourse = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/courses/${uuid}`, 'GET', idToken)
}

export const UpdateCourse = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/courses/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteCourse = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/courses/${uuid}`, 'DELETE', idToken)
}

export const GetCourseModule = async (course_uuid, module_uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/courses/${course_uuid}/modules/${module_uuid}`, 'GET', idToken)
}

export const UpdateCourseModule = async (course_uuid, module_uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/courses/${course_uuid}/modules/${module_uuid}`, 'PATCH', idToken, payload)
}

export const AddCourseModule = async (course_uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/courses/${course_uuid}/modules/`, 'POST', idToken, payload)
}

export const DeleteCourseModule = async (course_uuid, module_uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/courses/${course_uuid}/modules/${module_uuid}`, 'DELETE', idToken)
}
