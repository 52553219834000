import { callEndpoint, callEndpointWithPayload } from '../baseApi'

export const GetTag = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/tags/${uuid}`, 'GET', idToken)
}

export const UpdateTag = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/tags/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteTag = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/tags/${uuid}`, 'DELETE', idToken)
}
