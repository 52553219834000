import { useState } from "react";
import DataTable from "../components/DataTable";
import Page from "../components/Page";

import { useUserProvider } from "../providers/UserProvider";
import { useModalProvider } from "../providers/ModalProvider";
import { useOnboardingProvider } from "../providers/OnboardingProvider";

import { GetApp, GetDisabledApps, EnableApp } from "../services/api/app";

import ConfirmModal from "../components/modal_contents/ConfirmModal";
import useLocalStorage, { useSetLocalState } from "../lib/useLocalStorage";
import { useLoaderProvider } from "../providers/LoaderProvider";
import { setAppData } from "../App";

const RemovedApps = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentApp, setCurrentApp] = useLocalStorage('currentApp', null);
  const { doSetShowLoadingModal } = useLoaderProvider();
  const { setOnboardingData } = useOnboardingProvider();

  const setLiteUnlockCode = useSetLocalState('lite');
  const [isAppChanged, setIsAppChanged] = useState(false);

  const [data, setData] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const { user, setUser } = useUserProvider();

  const { showModal, closeModal } = useModalProvider();

  const loadData = async () => {
    setData({ ...data, loading: true });
    GetDisabledApps(user.idToken)
      .then((res) => res.json())
      .then((jsonData) => {
        console.log("jsonData", jsonData)
        setData({
          loading: false,
          data: jsonData.data,
          count: jsonData.data.length,
        });
      });
  };

  const links = [
    { name: "Home", url: "/" },
    { name: "Removed Apps", url: "/removed_apps" },
  ];
  
  const handleGoToApp = async (selectedApp) => {
    setCurrentApp(selectedApp);

    if (selectedApp.id !== user.currentApp()) {
      const app = selectedApp;
      const currentApp = () => app.id;

      const _user = { ...user, app, currentApp };

      try {
        if (app.lite) {
          const res = await GetApp(_user, _user.idToken);
          const jsonRes = await res.json();
          const app_setup = jsonRes.data.setup;
          if (app_setup?.premium_lite && app_setup?.lite_unlock_code) {
            setLiteUnlockCode(app_setup.lite_unlock_code);
          }
        } else setLiteUnlockCode('');
      } catch {
        setLiteUnlockCode('');
      }

      setUser({ ...user, app, currentApp });

      setAppData({
        idToken: user.idToken,
        data: user.data,
        isSuper: user.isSuper,
        currentApp,
        app,
        setUser,
        postCallback: () => {
          doSetShowLoadingModal(false);
        },
        setIsAppChanged,
        isAppChanged,
        setOnboardingData
      });
    }
  };

  const metadata = [
    {
      name: "key",
      label: "Key",
      parser: (data) => data.id,
    },
    {
      name: "name",
      label: "Name",
      parser: (data) => data.name,
      ellipsis: true,
    },
    {
      name: "actions",
      label: "Actions",
      width: "200px",
      parser: (data) => (
        <button
          onClick={() => showConfirmModal(data)}
          className="flex items-center justify-center h-8 border border-black rounded-full"
          style={{
            width: 100,
          }}
        >
          Enable App
        </button>
      ),
    },
  ];

  const confirmActionHandler = async (selectedApp) => {
    doSetShowLoadingModal(true);
    closeModal();

    await EnableApp(selectedApp.id, user.idToken)
    handleGoToApp(selectedApp);

    loadData();
    doSetShowLoadingModal(false);
  };

  const showConfirmModal = (app) => {
    showModal(
      <ConfirmModal
        itemId={app}
        itemLabel="app"
        onConfirm={confirmActionHandler}
        onClose={closeModal}
        confirmAction="enable"
        confirmBtnLabel="Enable"
      />
    );
  };

  return (
    <Page links={links} title="Removed Apps">
      <DataTable
        metadata={metadata}
        dataLoader={loadData}
        data={data}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        searchKeys={["name", "email"]}
      />
    </Page>
  );
};

export default RemovedApps;
