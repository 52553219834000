import { createBrowserHistory } from 'history'

export const callEndpoint = async (path, method, idToken) => {
  const response = await fetch(path, {
    method: method, 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
    },
  })

  if (response.status === 401) {
    createBrowserHistory().push('/')
    window.location.reload()
  }

  return response
}

export const callEndpointWithPayload = async (path, method, idToken, payload) => {
  const data = await fetch(path, {
    method: method, 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
    },
    body: JSON.stringify(payload)
  })

  return data
}

export const callPublicEndpoint = async (path, method, payload) => {
  const data = await fetch(path, {
    method: method, 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  })

  if(!data.ok) return Promise.reject(data)

  return data
}

export const callEndpointWithFormDataPayload = async (path, method, idToken, payload) => {
  
  const headers = new Headers()
  headers.append("Authorization", `Bearer ${idToken}`)
  
  const body = new FormData()

  Object.keys(payload)
    .forEach(key => {
      body.append(key, payload[key])
    })

  const data = await fetch(path, {
    method, 
    body,
    headers
  })

  return data
}

