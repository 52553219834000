import { useFormik } from "formik";
import { Column, EntryForm, Input } from "../components/EntryForm";
import Logo from '../assets/images/ae-lt.png'
import * as Yup from 'yup'
import { validations } from "../lib/form";
import { useCallback, useEffect, useState, Fragment } from "react";
import { useModalProvider } from "../providers/ModalProvider";
import Image from '../assets/images/Creator_Portal.png'
import LoadingLogo from '../assets/images/ae-icon-loading.png'
import { FreeTrial } from "../services/api/app";
import qs from 'qs';
import ErrorModal from "../components/modal_contents/ErrorModal";
import { useLocalState, useSetLocalState } from "../lib/useLocalStorage";

const schema = {
	app_name: Yup.string().required("Required"),
	name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').max(50, 'Maximum 50 Characters').required('Required'),
	password: validations.password
}

const FreeTrialForm = ({ useThrivecart, type = null }) => {
	const { showModal } = useModalProvider()
	const setIsFreeTrialCreated = useSetLocalState('isFreeTrialCreated')


	const [isCreated, setIsCreated] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	
	const handleVisitWebsite = useCallback(() => {
		window.open('https://www.actionera.com/homepage')
	}, [])

	const queryParameters = qs.parse(window.location.search.substring(1))
	
	const onSubmit = async (values) => {
		if(!queryParameters.thrivecart_hash && useThrivecart) return showModal(
			<ErrorModal status="INVALID_HASH"  />
		)

		let data = {
			name: values.app_name,
			creator: {
				email: values.email,
				name: values.name,
				password: values.password,
			},
			skip_thrivecart: !!!useThrivecart
		}

		if (useThrivecart) {
			data['thrivecart_hash'] = queryParameters.thrivecart_hash
			data['thrivecart'] = queryParameters?.thrivecart
		}

		if (typeof type !== 'undefined' || type !== null) {
			data['type'] = type
		}

		setIsSaving(true) 
		FreeTrial(data)
			.then(() => {
				if(type==="free_account"){
					window.location.href ='https://actionera.com/upgrade'
				}else{
					setIsCreated(true)
				}
			})
			.catch(async (err) => {
				const res = await err.json()
				const error = res?.error || {};

				if(error?.status === 409) return showModal(
					<ErrorModal status={error?.status} customMessage={error?.detail}  />
				)
				
				showModal(
					<ErrorModal  />
				)
			})
			.finally(() => {
				setTimeout(() => {
					setIsSaving(false)
				}, 500)
			})
	}

	const handleLogin = useCallback(() => {
		setIsFreeTrialCreated(1)

		// redirect to /
		window.location.href = '/';
	}, [])

	const formik = useFormik({
		onSubmit,
		initialValues: {
			app_name: '',
			name: '',
			email: '',
			password: ''
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape(schema)
	});
    
	return (
		<div className="w-screen pt-12 ttnk-free-trial-bg">
			<div className="px-12 md:px-20 container mx-auto">
				<div className="mb-12 md:mb-20 flex justify-between flex-col items-center md:flex-row gap-12">
					<div id="logo"><img src={ Logo } className="object-contain w-64 h-16" alt="ACTIONERA logo"/></div>
					<button onClick={handleVisitWebsite} className="border-none bg-transparent p-0 m-0 outline-none w-full md:w-auto max-w-xl md:max-w-none">
						<div className="text-white uppercase font-bold py-3 px-8 border rounded-full">Visit our website</div>
					</button>
				</div>
				<h1 className="font-bold text-xl md:text-6xl leading-tight max-w-auto xl:max-w-3xl text-white mx-auto md:mx-0 text-center md:text-left">The ULTIMATE Content, Membership, Event, and Course Platform</h1>
				<div className="relative flex flex-col-reverse xl:flex-row items-center justify-center md:justify-between mt-11">
					<div className="xl:max-w-xl mt-8 shrink-0 z-10 xl:w-screen">
						{isCreated ? (
							<div style={{height: 468}} className={`bg-white p-5 mt-5 px-12 md:px-20 text-center w-full rounded-xl flex flex-col items-center justify-center gap-4 ${type === 'free_account' ? 'hidden': ''}`}>
								<div id="logo"><img src={ LoadingLogo } className="object-contain w-48 h-48" alt="ACTIONERA logo"/></div>
								<div>
									<div className="text-2xl">Your app has been created.</div>
									<div className="text-2xl">Click <a onClick={handleLogin} className="underline decoration-solid text-blue-500 text-2xl">here</a> to login.</div>
								</div>
							</div>
						): (
              <Fragment>
                <form className="rounded-xl bg-white" onSubmit={formik.handleSubmit}>
                  <Column width="full">
                    <div className="px-6 py-10">
                      <header className="font-bold text-3xl mb-4">Account Set-up Form</header>
                      <p className="text-2xl mb-8">Sign up and get in on the Action!</p>
                      <Input disabled={isSaving} name="app_name" type="text" label="App Name" formik={formik} />
                      <Input disabled={isSaving} name="name" type="text" label="Your Name" formik={formik} />
                      <Input disabled={isSaving} name="email" type="email" label="E-mail Address" formik={formik} />
                      <Input disabled={isSaving} name="password" type="password" label="Password" formik={formik} />
                      <button
                        disabled={isSaving || !formik.dirty}
                        className="drop-shadow-lg text-lg rounded-full py-3 disabled:cursor-not-allowed text-white bg-gradient-to-b disabled:from-gray-100 disabled:to-gray-100 hover:from-aeblue-dark hover:to-aeblue-mid from-aeblue-mid to-aeblue-dark disabled:text-gray-400 w-full mt-4 mb-8"
                        type="submit"
                      >
                        Submit
                      </button>
                      <div className="mb-8">
                        By continuing, you agree to our <a target="_blank" href="https://www.actionera.com/policy" className="underline decoration-solid text-blue-500" rel="noreferrer">Privacy Policy</a> and <a target="_blank" href="https://www.actionera.com/terms" className="underline decoration-solid text-primary-500 text-blue-500" rel="noreferrer">Terms {'&'} Conditions</a>
                      </div>
                    </div>
                  </Column>
                </form>
              </Fragment>
						)}
					</div>
					<div className="grow max-w-5xl relative right-0"><img src={ Image } className="object-contain  mr-8" alt="Trial"/></div>
				</div>
			</div>
			<div className="text-center text-white py-8 mt-12 md:mt-40 px-20">
				<div className="container mx-auto">
					<div className="uppercase text-2xl font-bold">Need assistance?
						<a className="text-aeblue text-2xl" href="https://www.actionera.com/contact" target="_blank" rel="noreferrer" > Email us</a>
					</div>
					<div className="mt-8 pt-8">
						<a className="text-aeblue underline" href="https://www.actionera.com/contact" target="_blank" rel="noreferrer" >ACTIONERA</a>
						&nbsp;© {new Date().getFullYear()} All Rights Reserved
					</div>
				</div>
			</div>
		</div>
	)
}

export default FreeTrialForm

export const useFreeTrialLoginRedirect = () => {
	const [isFreeTrialCreated, setIsFreeTrialCreated] = useLocalState('isFreeTrialCreated')
	
	useEffect(() => {
		if(isFreeTrialCreated === 1) {
			// clean up
			setIsFreeTrialCreated(0)

			// auto redirect
			document.getElementById('login-btn')?.click()
		}
	}, [])
}