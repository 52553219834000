import React from "react";
import { FaCheck } from 'react-icons/fa';

const CustomCheckbox = ({ id, label, disabled, isChecked, ...rest }) => {
  const colors = {
    dark: "#2650A0",
    medium: "#939799"
  }

  return (
    <div className="!bg-inherit p-2 flex items-center custom-checkbox w-full text-xl mb-2">
      <button
        type="button"
        className={`border-4 rounded ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        id={id}
        style={{
          background: isChecked ? colors.dark : '#FFFFFF',
          borderColor: colors.dark,
        }}
        onClick={rest.onClick}
        disabled={disabled}
      >
        <div
          className="rounded-lg"
          style={{
            borderColor: isChecked ? colors.dark : '#FFFFFF',
            backgroundColor: isChecked ? colors.dark : '#FFFFFF',
          }}
        >
          <FaCheck className="text-white font-bold rounded-lg h-5 w-5" />
        </div>
      </button>
      <span className="ml-2.5 text-xl font-medium flex flex-row justify-between items-center w-full select-none">
        {label}
      </span>
    </div>
  );
};

export default CustomCheckbox