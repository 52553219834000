import React from "react"
import { useModalProvider } from "../../../providers/ModalProvider";

const AdminLaunchAppConfirmationModal = ({
  onConfirm,
}) => {
  const { closeModal } = useModalProvider();

  const handleOnClick = async (e) => {
    e?.preventDefault?.();
    e.target.disabled = true;

    if (onConfirm) onConfirm();
  };

  return (
    <div>
      <div style={{ width: 400 }} className="mb-4 text-center">
        Are you sure you want to launch this app? The creator will have access to the full creator portal the next time he logs in
      </div>
      <div className='flex flex-row gap-8 justify-center'>
        <button onClick={handleOnClick} className="ttnk-button">
          OK
        </button>
        <button onClick={closeModal} className="ttnk-button">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default AdminLaunchAppConfirmationModal