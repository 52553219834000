const videoConfig = {
  label: "Video",
  resourceEndpoint: "videos",
  listMetadata: [
    "sort_order",
    "title",
    "featured",
    "topics",
    "release_date",
    "tags",
    "actions",
  ],
  searchKeys: ["title"],
  keepListMetadataOrder: true,
  detailsMetadata: [
    {
      name: "actionclip",
      label: "ActionClip",
      conditions: [
        {
          relativeField: "title",
          comparisonFunc: (_relativeField, _thisField, setup) => {
            return setup?.actionclips;
          },
        },
      ],
    },
    {
      name: "actionclip_description",
      label: "ActionClip Description",
      conditions: [
        {
          relativeField: "actionclip",
          comparisonFunc: (relativeField, thisField, setup) => {
            return relativeField.value === true && setup?.actionclips;
          },
        },
      ],
      validations: [{ type: "max", params: [75, "Maximum 75 Characters"] }],
    },
    {
      name: "title",
      label: "Video Name",
      validations: [
        { type: "required", params: ["Required"] },
        { type: "max", params: [60, "Maximum 60 Characters"] },
      ],
    },
    { name: "description", label: "Description" },
    { name: "web_description", label: "Description" },
    {
      name: "video_url",
      label: "Video URL",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "image_url", label: "Image URL" },
    {
      name: "release_date",
      label: "Release Date",
      validations: [{ type: "required", params: ["Required"] }],
    },
    { name: "action_bar_text", label: "Call to Action Text" },
    { 
      name: "action_bar_text_actionclip",       
      conditions: [
        {
          relativeField: "actionclip",
          comparisonFunc: (relativeField, thisField, setup) => {
            return relativeField.value === true && setup?.actionclips;
          },
        },
      ], 
    },
    { name: "action_bar_url", label: "Call to Action URL" },
    { name: "sort_order", label: "Sort Order" },
    { name: "featured", label: "Featured ?" },
    { name: "topics", label: "Topics/Listings" },
    { name: "sort_order", label: "Sort Order" },
    { name: "tags", label: "Tags" },
  ],
  formLayout: {
    columns: [
      [
        "actionclip",
        "actionclip_description",
        "title",
        "video_url",    
        "action_bar_text",
        "action_bar_text_actionclip",
        "action_bar_url",
        "sort_order",
      ],
      [
        "description",
        "image_url",
        "featured",
        "topics",    
        "tags",
        "release_date",
      ],
    ],
  },
};

export default videoConfig;
