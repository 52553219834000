import { callEndpoint, callEndpointWithPayload } from "../baseApi"

export const GetGlobalFieldsList = async (user) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/global_fields`, 'GET', user.idToken)
}

export const GetGlobalField = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/global_fields/${uuid}`, 'GET', idToken)
}

export const AddGlobalField = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/global_fields`, 'POST', idToken, payload)
}

export const UpdateGlobalField = async (uuid, idToken, payload) => {
  return await callEndpointWithPayload(`${process.env.REACT_APP_API}/global_fields/${uuid}`, 'PATCH', idToken, payload)
}

export const DeleteGlobalField = async (uuid, idToken) => {
  return await callEndpoint(`${process.env.REACT_APP_API}/global_fields/${uuid}`, 'DELETE', idToken)
}
